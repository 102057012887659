import axios from "axios";
import getToken from "../../utils/getToken.utils";
import { API_URL } from "../../../constants/config/env.config";

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// defining a custom error handler for all APIs
const errorHandler = (error: any) => {
  const statusCode = error.response?.status;

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the "api" axios instance
api.interceptors.response.use(undefined, errorHandler);

// adding accessToken to headers via interceptor
api.interceptors.request.use((value) => {
  return new Promise((resolve) => {
    getToken().then((accessToken) => {
      if (!accessToken) {
        resolve(value);
      }

      // @ts-ignore
      value.headers = {
        ...value.headers,
        Authorization: "Bearer " + accessToken,
      };
      resolve(value);
    });
  });
});
