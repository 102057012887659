import React from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";

interface Props {
  videoId: string;
  visible?: boolean;
}

const YoutubeMediaComponent = ({ videoId, visible }: Props) => {
  return (
    <>
      <iframe
        title={"Youtube"}
        width={200}
        height={100}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder={"0"}
        allowFullScreen
      />
      {visible && (
        <div>
          <PaperClipOutlined />
          <Link
            href={`https://www.youtube.com/watch?v=${videoId}`}
            target={"_blank"}
          >
            {"Youtube"}
          </Link>
        </div>
      )}
    </>
  );
};

YoutubeMediaComponent.defaultProps = {
  visible: true,
};

export default YoutubeMediaComponent;
