import { Dispatch } from "redux";
import { SelectedPlaybookUidsType } from "../../views/components/forms/select/playbooks-select.component";
import UserGroupAction from "../redux/stores/user-group/user-group.action";
import ContentAction from "../redux/stores/content/content.action";
import ContentTypesEnum from "../enums/content-types.enum";
import PlaybookModel from "../models/content/playbook.model";

export default class UserGroupService {
  constructor(private dispatch: Dispatch<any>) {}

  addPlaybooksToUserGroup(
    allPlaybooks: PlaybookModel[],
    userGroupName: string,
    playbookUids: SelectedPlaybookUidsType[],
  ) {
    if (playbookUids.length === 0) return;

    playbookUids.forEach((item) => {
      const playbook = allPlaybooks.find(
        (p) => p.playbookUid === item.playbookUid,
      );

      if (!playbook) return;

      if (item.isShared && playbook.apiKeyClientUid) {
        // Case: Shared playbook
        this.dispatch(
          UserGroupAction.updateUserGroup(
            playbook.apiKeyClientUid,
            userGroupName,
            {
              playbookUid: item.playbookUid,
              userGroupAcl: [...playbook.userGroupAcl, userGroupName],
            },
          ),
        );
        return;
      }

      // Case: Local playbook
      this.dispatch(
        ContentAction.updateContent(ContentTypesEnum.PLAYBOOK, playbook, {
          userGroupAcl: [...playbook.userGroupAcl, userGroupName],
        }),
      );
    });
  }

  removePlaybooksFromUserGroup(
    allPlaybooks: PlaybookModel[],
    userGroupName: string,
    playbookUids: SelectedPlaybookUidsType[],
  ) {
    if (playbookUids.length === 0) return;

    playbookUids.forEach((item) => {
      const playbook = allPlaybooks.find(
        (p) => p.playbookUid === item.playbookUid,
      );

      if (!playbook) return;

      const index = playbook.userGroupAcl.findIndex(
        (userGroup) => userGroup === userGroupName,
      );

      if (index === -1) return;

      playbook.userGroupAcl.splice(index, 1);

      if (item.isShared && playbook.apiKeyClientUid) {
        // Case: Shared playbook
        this.dispatch(
          UserGroupAction.updateUserGroup(
            playbook.apiKeyClientUid,
            userGroupName,
            {
              playbookUid: item.playbookUid,
              userGroupAcl: playbook.userGroupAcl,
            },
          ),
        );
        return;
      }

      // Case: Local playbook
      this.dispatch(
        ContentAction.updateContent(ContentTypesEnum.PLAYBOOK, playbook, {
          userGroupAcl: playbook.userGroupAcl,
        }),
      );
    });
  }

  addUsersToUserGroup(userGroupName: string, usernames: string[]) {
    if (usernames.length === 0) return;

    this.dispatch(
      UserGroupAction.addUserToUserGroup(userGroupName, { usernames }),
    );
  }

  removeUsersFromUserGroup(userGroupName: string, usernames: string[]) {
    if (usernames.length === 0) return;

    this.dispatch(
      UserGroupAction.removeUserFromUserGroup(userGroupName, { usernames }),
    );
  }
}
