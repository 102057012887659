import React, { CSSProperties, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface Props {
  onSearch?: (searchTerm) => void;
}

const PageHeaderSearchComponent = (props: Props) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const inputRef = React.useRef<typeof Input | any>(null);

  const style: CSSProperties = {
    position: showSearch ? "relative" : "absolute",
    opacity: showSearch ? 1 : 0,
    pointerEvents: showSearch ? "auto" : "none",
  };

  return (
    <>
      {!showSearch && (
        <SearchOutlined
          onClick={() => {
            setShowSearch(true);
            inputRef.current?.focus({ cursor: "all" });
          }}
        />
      )}

      <Input.Search
        ref={inputRef}
        style={style}
        onBlur={() => setShowSearch(false)}
        onSearch={(e) => (props.onSearch ? props.onSearch(e) : null)}
      />
    </>
  );
};

export default PageHeaderSearchComponent;
