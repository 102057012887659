import React from "react";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { connect, useSelector } from "react-redux";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { selectUserGroups } from "../../../core/redux/selectors/user-group/user-group.selector";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import EmptyStateScreen from "../../screens/empty-state.screen";
import UserGroupsOverviewScreen from "../../screens/user-groups/user-groups-overview.screen";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";

interface StateProps {
  groups: UserGroupModel[] | undefined;
  requesting: boolean;
  error: HttpErrorResponseModel;
}

type Props = StateProps;

const UserGroupsContainer = ({ groups }: Props) => {
  const userRole: UserRoleEnum = useSelector(selectCurrentUserRole);
  const { openModal } = useModal();

  return (
    <PageLayoutComponent
      menuItems={[]}
      showSider={false}
      title={"user-groups"}
      onPress={
        userRole !== UserRoleEnum.GROUP_ADMIN
          ? () => onShowCreateModal()
          : undefined
      }
    >
      {!groups || groups.length === 0 ? (
        <EmptyStateScreen
          route={"user-groups"}
          onPress={() => onShowCreateModal()}
        />
      ) : (
        <UserGroupsOverviewScreen
          groups={groups}
          onEdit={onShowCreateModal}
          onSelectNotification={(notifiable) =>
            openModal(ModalNames.CREATE_NOTIFICATION, { notifiable })
          }
        />
      )}
    </PageLayoutComponent>
  );

  function onShowCreateModal(group?: UserGroupModel) {
    openModal(ModalNames.CREATE_USER_GROUP, { group });
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  groups: selectUserGroups(state),
  requesting: selectRequesting(state, [UserGroupAction.REQUEST_USER_GROUPS]),
  error: selectRawErrors(state, [UserGroupAction.REQUEST_USER_GROUPS_FINISHED]),
});

export default connect(mapStateToProps)(UserGroupsContainer);
