import { CardTypesEnum } from "../../../../enums/card-types.enum";
import BaseCardModel, { BaseCardResponse } from "../../base-card.model";
import MultipleChoiceCardDataModel, {
  MultipleChoiceCardDataResponse,
} from "./multiple-choice-card-data.model";

export interface MultipleChoiceCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.MULTIPLE_CHOICE;
  data: MultipleChoiceCardDataResponse;
}

export default class MultipleChoiceCardModel extends BaseCardModel {
  cardType: CardTypesEnum.MULTIPLE_CHOICE;
  data: MultipleChoiceCardDataModel;

  constructor(multipleChoiceCard: MultipleChoiceCardResponse) {
    super(multipleChoiceCard);
    this.cardType = multipleChoiceCard.cardType;
    this.data = new MultipleChoiceCardDataModel(multipleChoiceCard.data);
  }
}
