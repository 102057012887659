import React from "react";
import CardDetailOverviewQuizScreen from "./card-detail-overview/card-detail-overview-quiz.screen";
import { useUserProgressCardDetail } from "../../../core/hooks/user-progress/user-progress.hook";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import EmptyStateScreen from "../empty-state.screen";
import CardDetailOverviewSliderScreen from "./card-detail-overview/card-detail-overview-slider.screen";
import CardDetailOverviewQuestionScreen from "./card-detail-overview/card-detail-overview-question.screen";
import { Empty, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import {
  selectUsers,
  selectUsersInUserGroup,
} from "../../../core/redux/selectors/user/user.selector";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { connect, useSelector } from "react-redux";
import UserModel from "../../../core/models/user/user.model";
import CardTypes from "../../../core/types/card.types";
import CardDetailOverviewPictureQuizScreen from "./card-detail-overview/card-detail-overview-picture-quiz.screen";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import { InteractiveCard } from "../../../core/models/user-progress/interactive-card.model";

interface StateProps {
  users: UserModel[];
}

interface OwnProps {
  card: CardTypes;
}

type Props = StateProps & OwnProps;

const CardDetailOverviewScreen = (props: Props) => {
  const { card, users } = props;
  const { data } = useUserProgressCardDetail(card.cardUid);
  const { t } = useTranslation();
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );
  const usersInUserGroup: UserModel[] = useSelector((state) =>
    selectUsersInUserGroup(state, currentlySelectedUserGroup),
  );
  const filteredInteractiveCards = getInteractiveCardsFromUserGroupUsers();

  return (
    <div>
      {renderTitle()}
      {renderTableOverview()}
    </div>
  );

  function renderTableOverview() {
    if (!data) {
      return <EmptyStateScreen />;
    }

    const dataWithUserExist =
      filteredInteractiveCards.length > 0
        ? filteredInteractiveCards
        : data.data.filter((u) => users.some((_u) => _u.sub === u.userUid));

    switch (card.cardType) {
      case CardTypesEnum.QUIZ:
        return (
          <CardDetailOverviewQuizScreen card={card} data={dataWithUserExist} />
        );
      case CardTypesEnum.PICTURE_QUIZ:
        return (
          <CardDetailOverviewPictureQuizScreen
            card={card}
            data={dataWithUserExist}
          />
        );
      case CardTypesEnum.SLIDER:
        return (
          <CardDetailOverviewSliderScreen
            card={card}
            data={dataWithUserExist}
          />
        );
      case CardTypesEnum.QUESTION:
        return <CardDetailOverviewQuestionScreen data={dataWithUserExist} />;
      default:
        return (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={Capitalize(
              t("content.card-types.card-type-no-answers"),
            )}
          />
        );
    }
  }

  function renderTitle() {
    return (
      <div style={{ margin: "4rem 0" }}>
        <Typography.Title level={2}>
          {card.data && card.cardType !== CardTypesEnum.KNOWLEDGE_CARD
            ? `${Capitalize(t("common.answers"))} : ${card.data.question}`
            : card.title}
        </Typography.Title>
      </div>
    );
  }

  function getInteractiveCardsFromUserGroupUsers() {
    const newInteractiveCards: InteractiveCard[] = [];
    usersInUserGroup.map((user) => {
      return data?.data.filter((u) => {
        if (u.userUid === user.sub) {
          newInteractiveCards.push(u);
        }
      });
    });
    return newInteractiveCards;
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
});

export default connect(mapStateToProps)(CardDetailOverviewScreen);
