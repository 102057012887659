import { StoreInterface } from "../../stores/root.reducer";
import InvitationModel from "../../../models/invitation/invitation.model";
import {
  selectCurrentUser,
  selectCurrentUserRole,
} from "../user/user.selector";
import UserRoleEnum from "../../../enums/user-role.enum";

export const selectInvitations = (state: StoreInterface): InvitationModel[] => {
  const user = selectCurrentUser(state);
  const userRole = selectCurrentUserRole(state);

  if (!user || !userRole) {
    return [] as InvitationModel[];
  }

  switch (userRole) {
    case UserRoleEnum.SYS_ADMIN:
    case UserRoleEnum.PRIMIO_SUPPORT:
    case UserRoleEnum.ADMIN:
      return state.invitation.invitations;
    case UserRoleEnum.GROUP_ADMIN:
      return state.invitation.invitations.filter((invitation) =>
        user.userGroups.some((userGroup) =>
          invitation.userGroups.includes(userGroup),
        ),
      );
  }
};
