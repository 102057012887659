import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useGetLearningPath } from "../../../core/api/primio/primioComponents";
import { Layout } from "antd";
import styles from "../../components/learning-path/learning-path.module.css";
import LearningPathIntroComponent from "../../components/learning-path/learning-path-intro.component";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import LearningPathContextMenu from "../../components/learning-path/learning-path-context-menu";
import { LearningPathProvider } from "../../../core/providers/learning-path.provider";
import LearningPathContentList from "../../components/learning-path/learning-path-content-list";
import LearningPathAddContent from "../../components/learning-path/learning-path-add-content";

const LearningPathDetailContainer = () => {
  const { params } = useRouteMatch<{ uid: string }>();
  const { data: learningPath, isFetching } = useGetLearningPath({
    pathParams: { learningPathUid: params.uid },
  });

  if (isFetching && !learningPath) return <p>Loading...</p>;

  if (!learningPath) {
    return <p>Not found</p>;
  }

  if (learningPath.learningPathType !== null) {
    return <p>This type of learning can not be viewed here</p>;
  }

  return (
    <LearningPathProvider learningPath={learningPath}>
      <Layout.Content className={styles.learning_container}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <LearningPathContextMenu
            learningPath={learningPath as unknown as LearningPathModel}
          />
        </div>
        <LearningPathIntroComponent
          learningPath={learningPath as unknown as LearningPathModel}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "10px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: 400,
            }}
          >
            <LearningPathContentList content={learningPath.content} />
            <LearningPathAddContent />
          </div>
        </div>
      </Layout.Content>
    </LearningPathProvider>
  );
};

export default LearningPathDetailContainer;
