import MediaEndpoints from "../../api/media.endpoints";
import EffectUtility from "../../utils/effect.utils";
import MediaModel, { MediaResponse } from "../../models/media/media.model";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import HttpUtility from "../../utils/http.utils";

export async function getMedia(mediaUid: string) {
  const endpoint = MediaEndpoints.detail(mediaUid);
  const response = await EffectUtility.getToModel<MediaModel>(
    MediaModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function createMedia(data: Partial<MediaResponse>) {
  const endpoint = MediaEndpoints.create;
  const response = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
