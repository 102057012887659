import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import { CardTypesEnum } from "../../../enums/card-types.enum";

export interface CreatePictureQuizCardDataInterface {
  question: string;
  options: CreatePictureQuizCardOptionInterface[];
}

export interface CreatePictureQuizCardOptionInterface {
  sort: number;
  mediaUid: string;
  isCorrect: boolean;
}

export interface CreatePictureQuizCardInterface extends CreateCardInterface {
  data: CreatePictureQuizCardDataInterface;
}

export default class CreatePictureQuizCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.PICTURE_QUIZ;
  data: CreatePictureQuizCardDataInterface;

  constructor(createPictureCard: CreatePictureQuizCardInterface) {
    super(createPictureCard);
    this.cardType = CardTypesEnum.PICTURE_QUIZ;
    this.data = createPictureCard.data;
  }
}
