import QrCodeModel from "../models/qr-code/qr-code.model";

export default function exportQrFileUtils(qrCode: QrCodeModel) {
  const canvas = document.getElementById(qrCode.qrCodeUid) as HTMLCanvasElement;
  if (!canvas) {
    return;
  }

  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");

  const downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = `QR_${qrCode.description}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
}
