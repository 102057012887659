import React from "react";
import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../../../core/enums/learning-path-state.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Card, Descriptions, Dropdown, Menu, Tag, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import LearningPathTypeIconComponent from "../icons/learning-path-type-icon.component";
import minutesToDaysUtils from "../../../core/utils/minutes-to-days.utils";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import ContentIconComponent from "../icons/content-icon.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import styles from "./learning-path.module.css";
import { NavLink } from "react-router-dom";
import ProgressMetaIconComponent from "../icons/progress-meta-icon.component";

type Props = {
  learningPath: LearningPathModel;
};

const LearningPathIntroComponent = ({ learningPath }: Props) => {
  const { t } = useTranslation();

  return (
    <Card
      actions={renderCardActions()}
      style={{
        border:
          learningPath.state === LearningPathStateEnum.CREATED
            ? ".2rem solid rgba(0,0,0,.7)"
            : "none",
        paddingBottom: ".3rem",
      }}
    >
      {learningPath.state === LearningPathStateEnum.CREATED && (
        <Tag color={"rgba(0,0,0,.7)"} className={styles.intro_card_tag}>
          {Capitalize(t(`screens.learning-path.state.CREATED`)).toUpperCase()}
        </Tag>
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        <Descriptions title="Training Info">
          <Descriptions.Item
            label={Capitalize(t("form.card.title.label"))}
            span={100}
          >
            {learningPath.title}
          </Descriptions.Item>
          <Descriptions.Item
            label={Capitalize(t("form.card.description.label"))}
            span={100}
          >
            {learningPath.description}
          </Descriptions.Item>
        </Descriptions>

        <img
          style={{
            width: 200,
            height: 200,
            objectFit: "contain",
            borderRadius: 6,
            aspectRatio: 1,
            background: learningPath.color || "var(--primary-color)",
            boxShadow:
              "0px 2px 4px rgba(0,0,0,0.05),0px 4px 20px rgba(0,0,0,0.1)",
          }}
          src={
            learningPath.media?.[0]
              ? learningPath.media[0].uri
              : `${process.env.PUBLIC_URL}/assets/images/default_training_image.png`
          }
          alt="media"
        />
      </div>
    </Card>
  );

  function renderCardActions() {
    let totalMinutesAfterStarted = 0;
    learningPath.learningPathType &&
      learningPath.content.forEach((c) => {
        if (
          learningPath.learningPathType !==
            LearningPathTypeEnum.PROGRESS_BASED &&
          c.learningPathType !== LearningPathTypeEnum.PROGRESS_BASED
        ) {
          totalMinutesAfterStarted += c.data.minutesAfterStarted;
        }
      });

    const userGroupTitle =
      learningPath.userGroups?.length === 1
        ? "containers.user-groups.key"
        : "containers.user-groups.key_plural";

    const dropdown = () => {
      return (
        <Menu>
          {learningPath.userGroups?.map((group) => (
            <Menu.Item key={group}>
              <NavLink to={`/user-groups/${group}`}>{group}</NavLink>
            </Menu.Item>
          ))}
        </Menu>
      );
    };

    return [
      <div key={0} className={styles.intro_card_action}>
        <LearningPathTypeIconComponent
          type={learningPath.learningPathType}
          style={{ paddingRight: "1rem" }}
        />
        <Typography.Text>
          {Capitalize(
            t(
              `screens.learning-path.type.${learningPath.learningPathType}.label`,
            ),
          )}
        </Typography.Text>
      </div>,
      <div key={1} className={styles.intro_card_action}>
        <ContentIconComponent
          contentType={ContentTypesEnum.COLLECTION}
          style={{ paddingRight: "1rem" }}
        />
        <Typography.Text>
          {t("content.playbook.keyWithCount_plural", {
            count: learningPath.content.length,
          })}
        </Typography.Text>
      </div>,

      <div key={2} className={styles.intro_card_action}>
        {learningPath.learningPathType && (
          <>
            <ClockCircleOutlined style={{ paddingRight: "1rem" }} />
            <Typography.Text>
              {learningPath.learningPathType !==
              LearningPathTypeEnum.PROGRESS_BASED
                ? minutesToDaysUtils(totalMinutesAfterStarted) +
                  " " +
                  t("common.days")
                : "-"}
            </Typography.Text>
          </>
        )}
      </div>,
      <div key={3}>
        {learningPath.userGroups && learningPath.userGroups.length > 0 ? (
          <Dropdown overlay={dropdown} arrow>
            <div>
              <ProgressMetaIconComponent icon={"assigned"} />
              <Typography.Text style={{ fontSize: "1.3rem" }}>
                {`${
                  learningPath.userGroups.length +
                  " " +
                  Capitalize(t(userGroupTitle))
                }`}
              </Typography.Text>
            </div>
          </Dropdown>
        ) : null}
      </div>,
    ];
  }
};

export default LearningPathIntroComponent;
