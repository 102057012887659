import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row, Steps, Typography } from "antd";
import { grey } from "@ant-design/colors";
import CreateApiKeyForm, {
  CreateApiKeyFormRef,
} from "../forms/create-api-key.form";
import { useCreateApiKeyMutation } from "../../../core/hooks/api-key/api-key.hook";
import { CreateApiKeyInterface } from "../../../core/interfaces/create-api-key.interface";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Capitalize } from "../../../core/utils/helper.utils";
import colors from "../../styles/colors";
import { CheckOutlined } from "@ant-design/icons";
import { queryClient } from "../../../core/providers/app.provider";
import encryptCipher from "../../../core/utils/cipher-text";

const { Text, Paragraph } = Typography;

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps;

const CreateApiKeyModal = (props: Props) => {
  const [t] = useTranslation();
  const { Step } = Steps;
  const { visible, onCancel } = props;
  const createApiKeyFormRef = useRef<CreateApiKeyFormRef>(null);
  const [okText, setOkText] = useState<string>(Capitalize(t("common.next")));
  const [step, setStep] = useState<number>(0);
  const [cipherText, setCipherText] = useState<string>();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { mutate } = useCreateApiKeyMutation();

  const onChangeStep = (value: number) => {
    setStep(value);
  };

  return (
    <Modal
      open={visible}
      title={renderModalTitle()}
      width={"80rem"}
      onCancel={handleOnCancel}
      cancelText={<>{Capitalize(t("translations:common.cancel"))}</>}
      onOk={handleOnOk}
      okText={okText}
      closable={false}
    >
      {step === 0 && (
        <div>
          <Typography.Text
            style={{
              color: grey[6],
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {Capitalize(
              t("common.share-your-x", { item: t("form.items.content.label") }),
            )}
          </Typography.Text>

          <Paragraph style={{ fontSize: 14 }}>
            {Capitalize(t("screens.shared-content.modal.about-to-share"))}
          </Paragraph>

          <CreateApiKeyForm ref={createApiKeyFormRef} />

          <Text style={{ color: colors.secondary }}>{errorMessage}</Text>
        </div>
      )}

      {step === 1 && (
        <div>
          <Typography.Text
            style={{
              color: grey[6],
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {Capitalize(t("common.share-your-x", { item: t("common.link") }))}
          </Typography.Text>

          <Paragraph style={{ fontSize: 14 }}>
            {Capitalize(t("screens.shared-content.modal.find-your-code"))}
          </Paragraph>

          <Row>
            <Col
              flex="80%"
              style={{
                background: "#F5F5F5",
                borderRadius: "6px",
                padding: "8px 16px",
                wordBreak: "break-all",
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              <Text>{cipherText}</Text>
            </Col>
            <Col
              flex="20%"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CopyToClipboard
                text={cipherText}
                onCopy={() => setIsCopied(true)}
              >
                {isCopied ? (
                  <Text style={{ color: colors.primary }}>
                    <CheckOutlined /> {Capitalize(t("common.copied"))}
                  </Text>
                ) : (
                  <Text>{Capitalize(t("common.copy_x"))}</Text>
                )}
              </CopyToClipboard>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );

  function handleOnCancel() {
    onCancel();
    if (createApiKeyFormRef.current) {
      createApiKeyFormRef.current.form.resetFields();
    }

    resetDefaultState();
  }

  function handleOnOk() {
    if (createApiKeyFormRef.current && step === 0) {
      createApiKeyFormRef.current.form.validateFields().then((res) => {
        const dataCreateApiKey: CreateApiKeyInterface = {
          description: res.description,
          playbookUids: res.content,
          active: true,
        };

        mutate(dataCreateApiKey, {
          onSuccess: (data) => {
            queryClient.invalidateQueries(["apiKey"]);
            setCipherText(
              encryptCipher(data.data.apiKey, dataCreateApiKey.description),
            );
            setOkText(Capitalize(t("common.done")));
            setStep(1);
          },
          onError: (error) => {
            setErrorMessage(error.message);
          },
        });
      });
    }

    if (step === 1) {
      onCancel();
      resetDefaultState();
    }
  }

  function renderModalTitle() {
    return (
      <Steps
        current={step}
        progressDot
        onChange={onChangeStep}
        style={{ marginTop: "3rem" }}
      >
        <Step
          title={`${Capitalize(t("common.create"))} ${t(
            "common.api",
          ).toUpperCase()} ${Capitalize(t("common.key"))}`}
        />
        <Step
          title={Capitalize(
            t("common.share-your-x", { item: t("form.items.content.label") }),
          )}
        />
      </Steps>
    );
  }

  function resetDefaultState() {
    setOkText(Capitalize(t("common.next")));
    setStep(0);
    setIsCopied(false);
  }
};

export default CreateApiKeyModal;
