import React, { CSSProperties } from "react";
import {
  AppstoreOutlined,
  BookOutlined,
  FileOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface OwnProps {
  contentType: ContentTypesEnum;
}

export interface AntdIconProps {
  className?: string;
  rotate?: number;
  spin?: boolean;
  style?: CSSProperties;
  iconcolor?: string;
}

type Props = OwnProps & AntdIconProps;

const ContentIconComponent = (props: Props) => {
  const { contentType, ...iconProps } = props;

  const style = {
    ...iconProps.style,
    color: iconProps.iconcolor,
  };

  switch (contentType) {
    case ContentTypesEnum.PLAYBOOK:
      return <AppstoreOutlined style={style} {...iconProps} />;
    case ContentTypesEnum.CHAPTER:
      return <BookOutlined style={style} {...iconProps} />;
    case ContentTypesEnum.COLLECTION:
      return <FolderOutlined style={style} {...iconProps} />;
    case ContentTypesEnum.CARD:
      return <FileOutlined style={style} {...iconProps} />;
  }
};

export default ContentIconComponent;
