import { useMutation } from "@tanstack/react-query";
import {
  copyCardToCollection,
  copyChapterToPlaybook,
  copyCollectionToChapter,
} from "./copy-content.api";
import { useDispatch } from "react-redux";
import ContentTypesEnum from "../../../enums/content-types.enum";
import ContentAction from "../../../redux/stores/content/content.action";

interface ICopyChapterToPlaybook {
  chapterUid: string;
  playbookUids: string[];
}

export const useCopyChapterToPlaybook = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data: ICopyChapterToPlaybook) =>
      Promise.all(
        data.playbookUids.map((playbookUid) =>
          copyChapterToPlaybook({
            playbookUid,
            chapterUid: data.chapterUid,
          }),
        ),
      ),
    onSuccess: (_, variables: ICopyChapterToPlaybook) => {
      variables.playbookUids.forEach((playbookUid) => {
        dispatch(
          ContentAction.getContentDetail(
            ContentTypesEnum.PLAYBOOK,
            playbookUid,
          ),
        );
      });
    },
  });
};

interface ICopyCollectionToChapter {
  collectionUid: string;
  chapterUids: string[];
}

export const useCopyCollectionToChapter = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data: ICopyCollectionToChapter) =>
      Promise.all(
        data.chapterUids.map((chapterUid) =>
          copyCollectionToChapter({
            collectionUid: data.collectionUid,
            chapterUid,
          }),
        ),
      ),
    onSuccess: (_, variables: ICopyCollectionToChapter) => {
      variables.chapterUids.forEach((chapterUid) => {
        dispatch(
          ContentAction.getContentDetail(ContentTypesEnum.CHAPTER, chapterUid),
        );
      });
    },
  });
};

interface ICopyCardToCollection {
  cardUid: string;
  collectionUids: string[];
}

export const useCopyCardToCollection = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data: ICopyCardToCollection) =>
      Promise.all(
        data.collectionUids.map((collectionUid) =>
          copyCardToCollection({
            cardUid: data.cardUid,
            collectionUid,
          }),
        ),
      ),
    onSuccess: (_, variables: ICopyCardToCollection) => {
      variables.collectionUids.forEach((collectionUid) => {
        dispatch(
          ContentAction.getContentDetail(
            ContentTypesEnum.COLLECTION,
            collectionUid,
          ),
        );
      });
    },
  });
};
