import React, { useState } from "react";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useTranslation } from "react-i18next";
import { Alert, Checkbox, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import colors from "../../styles/colors";

interface StateProps {
  groups: string[];
  setGroupsToCreate: (data: string[]) => void;
}

type Props = StateProps;

const UserGroupsNotExistComponent = (props: Props) => {
  const { t } = useTranslation();
  const { groups, setGroupsToCreate } = props;
  const [selectedGroups, setSelectedGroups] =
    useState<CheckboxValueType[]>(groups);

  const handleOnChange = (checkedValues: CheckboxValueType[]) => {
    setGroupsToCreate(checkedValues as string[]);
    setSelectedGroups(checkedValues);
  };

  return (
    <>
      <div style={{ paddingBottom: "1rem" }}>
        <Typography.Text strong>
          <InfoCircleOutlined
            style={{
              color: colors.primary,
              fontSize: "1.5rem",
              marginRight: "0.5rem",
            }}
          />
          {t("screens.user-groups.new-groups-found")}
        </Typography.Text>
        <Typography.Paragraph>
          {t("screens.user-groups.new-groups-found-description")}
        </Typography.Paragraph>
      </div>

      <Checkbox.Group
        defaultValue={groups}
        options={groups}
        onChange={handleOnChange}
      />

      {selectedGroups.length < groups.length && (
        <Alert
          type={"warning"}
          description={t("screens.user-groups.alert-description")}
          style={{ marginTop: "2rem" }}
          message={""}
          showIcon
          closable
        />
      )}
    </>
  );
};

export default UserGroupsNotExistComponent;
