import EventEmitter from "events";

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload),
};

export default Emitter;
