import { useQuery } from "@tanstack/react-query";
import { getFavouriteCards } from "./favourite.api";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import UsersFavouriteCardModel from "../../models/favourite/users-favourite-card.model";

/**
 * GET list of cards from a collection where users has bookmarked cards
 * @param collectionUid string
 * @param cardUid string | undefined
 * @returns Promise<UsersFavouriteCardModel[] | UsersFavouriteCardModel>
 */
export const useFavouriteCards = (collectionUid: string, cardUid?: string) => {
  return useQuery<
    any,
    HttpErrorResponseModel,
    UsersFavouriteCardModel[] | UsersFavouriteCardModel
  >(["favouriteCards", collectionUid, cardUid], async () => {
    const favouriteCards = await getFavouriteCards(collectionUid);

    if (cardUid) {
      return favouriteCards.find((c) => c.cardUid === cardUid);
    }

    return favouriteCards;
  });
};
