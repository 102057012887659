import React, { PropsWithChildren } from "react";
import { ButtonType } from "antd/lib/button/button";
import { CheckCircleFilled, RocketFilled } from "@ant-design/icons";
import { Button, Progress } from "antd";
import useLongPress, { LongPressProps } from "../../../core/hooks/useLongPress";

interface OwnProps {
  icon?: React.ReactNode;
  loading?: boolean;
  type?: ButtonType;
  disabled?: boolean;
}

type Props = OwnProps & LongPressProps;

const LongPressButtonComponent = ({
  callback,
  disabled,
  icon,
  labels,
  loading,
  ms,
  type,
}: PropsWithChildren<Props>) => {
  const { progressNormalized, label, ...actions } = useLongPress({
    ms,
    callback,
    labels,
  });

  return (
    <Button
      {...actions}
      type={type}
      loading={loading}
      disabled={disabled}
      icon={getIcon()}
    >
      {label}
    </Button>
  );

  function getIcon() {
    if (progressNormalized === 0) {
      return icon;
    }

    if (progressNormalized === 1) {
      return (
        <CheckCircleFilled style={{ color: "var(--white-color)" }} size={15} />
      );
    }

    return (
      <Progress
        type={"circle"}
        percent={progressNormalized * 100}
        status={progressNormalized === 1 ? "success" : "active"}
        strokeColor={"var(--white-color)"}
        trailColor={"var(--primary-color)"}
        size={"default"}
        showInfo={false}
        strokeWidth={15}
        width={14}
        style={{
          fontSize: 11,
          marginRight: ".8rem",
          verticalAlign: "text-bottom",
        }}
      />
    );
  }
};

LongPressButtonComponent.defaultProps = {
  icon: <RocketFilled />,
  loading: false,
  type: "primary",
  disabled: false,
};

export default LongPressButtonComponent;
