import React from "react";
import {
  GradientColors_ProgressCompleted,
  GradientColors_ProgressPending,
  GradientColors_ProgressStarted,
} from "../../styles/colors";
import { Col, Row, Skeleton } from "antd";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";
import ContentUserProgressModel from "../../../core/models/user-progress/user-progress/user-progress-types/content-user-progress.model";
import GradientProgressBarComponent from "../graphs/gradient-progress-bar.component";

interface ContentUserProgressBarProps {
  isLoading: boolean;
  userProgress: ContentUserProgressModel[];
  onClick: (contentProgress: ContentProgressEnum) => void;
}

const ContentUserProgressBar = (props: ContentUserProgressBarProps) => {
  const { isLoading, userProgress } = props;

  const notStarted: number = userProgress?.filter(
    (u) => u.state === ContentProgressEnum.PENDING,
  ).length;
  const started: number = userProgress.filter(
    (u) => u.state === ContentProgressEnum.STARTED,
  ).length;
  const completed: number = userProgress.filter(
    (u) => u.state === ContentProgressEnum.COMPLETED,
  ).length;

  const total = userProgress.length;

  if (isLoading) {
    return (
      <div style={{ margin: "2rem 0 0 0" }}>
        <Skeleton paragraph={false} round={true} />
      </div>
    );
  }

  return (
    <div style={{ margin: "2rem 0" }}>
      <UserProgressProgressBar
        onClick={props.onClick}
        completed={completed}
        started={started}
        notStarted={notStarted}
        total={total}
      />
    </div>
  );
};

interface ProgressBarProps {
  onClick?: (contentProgress: ContentProgressEnum) => void;
  completed: number;
  started: number;
  notStarted: number;
  total: number;
  showValue?: boolean;
  size?: number;
}

export function UserProgressProgressBar({
  onClick,
  completed,
  started,
  notStarted,
  total,
  showValue = true,
  size = 11,
}: ProgressBarProps) {
  return (
    <Row align={"middle"}>
      {completed > 0 && (
        <>
          <Col flex={completed / total} style={{ minWidth: size }}>
            <a onClick={() => onClick?.(ContentProgressEnum.COMPLETED)}>
              <GradientProgressBarComponent
                amount={completed}
                color={GradientColors_ProgressCompleted}
                showValue={showValue}
                size={size}
              />
            </a>
          </Col>
          <Col style={{ width: ".5rem" }} />
        </>
      )}

      {started > 0 && (
        <>
          <Col flex={started / total} style={{ minWidth: size }}>
            <a onClick={() => onClick?.(ContentProgressEnum.STARTED)}>
              <GradientProgressBarComponent
                amount={started}
                color={GradientColors_ProgressStarted}
                showValue={showValue}
                size={size}
              />
            </a>
          </Col>
          <Col style={{ width: ".5rem" }} />
        </>
      )}

      {(isNaN(notStarted / total) || notStarted > 0) && (
        <Col
          flex={isNaN(notStarted / total) ? 1 : notStarted / total}
          style={{ minWidth: size }}
        >
          <a onClick={() => onClick?.(ContentProgressEnum.PENDING)}>
            <GradientProgressBarComponent
              amount={notStarted}
              color={GradientColors_ProgressPending}
              showValue={showValue}
              size={size}
            />
          </a>
        </Col>
      )}
    </Row>
  );
}

export default ContentUserProgressBar;
