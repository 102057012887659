import React from "react";
import { Col, Divider, Row } from "antd";
import UserAppCompletedComponent from "../../components/user-detail/user-app-completed.component";
import UserContentCompletionComponent from "../../components/user-detail/user-content-completion/user-content-completion.component";
import UserContentCompletionDetailsComponent from "../../components/user-detail/user-content-completion-details/user-content-completion-details.component";
import UserInfoComponent from "../../components/user-detail/user-info.component";
import UserModel from "../../../core/models/user/user.model";
import styles from "../../components/user-detail/user-detail.module.css";
import UserTrainingProgress from "../../components/user-training-progress/user-training-progress";

interface OwnProps {
  user: UserModel;
}

type Props = OwnProps;

const UserDetailOverviewScreen = ({ user }: Props) => {
  return (
    <>
      <Row className={styles.wrapper} style={{ height: "35.4rem" }}>
        <Col style={{ minWidth: "45rem" }}>
          <UserInfoComponent user={user} />
        </Col>

        <Col>
          <UserAppCompletedComponent user={user} />
        </Col>
      </Row>

      <Divider style={{ margin: "5.6rem 0" }} />

      <UserTrainingProgress user={user} />

      <Divider style={{ margin: "5.6rem 0" }} />

      <Row className={styles.wrapper}>
        <Col style={{ minWidth: "45rem" }}>
          <UserContentCompletionComponent user={user} />
        </Col>
        <Col flex={1} style={{ minWidth: "52rem" }}>
          <UserContentCompletionDetailsComponent user={user} />
        </Col>
      </Row>
    </>
  );
};

export default UserDetailOverviewScreen;
