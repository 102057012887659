import React from "react";
import { Typography } from "antd";
import { grey } from "@ant-design/colors";
import animation from "../../styles/animations.module.css";

interface EmptyProps {
  message: string;
  image: "none_found" | "404" | "chill" | "wow" | "cloud" | "open_box";
  textColor?: string;
}

const Empty = ({ message, image, textColor }: EmptyProps) => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        className={animation.vibrate}
        alt={"empty state image"}
        style={{ marginBottom: 8 }}
        src={`${process.env.PUBLIC_URL}/assets/images/${image}.svg`}
        height={108}
        width={128}
      />
      <Typography.Paragraph style={{ color: textColor ? textColor : grey[2] }}>
        {message}
      </Typography.Paragraph>
    </div>
  );
};

export default Empty;
