import React from "react";
import { Form, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import SourceModel from "../../../core/models/source/source.model";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";

interface OwnProps {
  sources: SourceModel[];
}

type Props = OwnProps;

const SourcePreviewComponent = (props: Props) => {
  const [t] = useTranslation();
  const { sources } = props;

  return (
    <Form.Item
      label={
        <Typography.Text strong>
          {Capitalize(t("form.card.sources.label"))}
        </Typography.Text>
      }
    >
      {sources.map((source: SourceModel, index: number) => {
        return (
          <Form.Item key={index}>
            <div style={{ paddingLeft: ".5rem" }}>
              <Typography.Text>{`${index + 1}.`}</Typography.Text>
              <Typography.Text style={{ paddingLeft: "1rem" }}>
                {TranslateDefaults(source.text)}
              </Typography.Text>
            </div>
            <Typography.Text italic style={{ paddingLeft: "2.5rem" }}>
              {TranslateDefaults(source.url)}
            </Typography.Text>
          </Form.Item>
        );
      })}
    </Form.Item>
  );
};

export default SourcePreviewComponent;
