import React, { FC, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { Modal, Space, Typography } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import DraggableTableRowComponent from "../../components/draggable/draggable-table-row.component";
import ContentActionComponent from "../../components/content-action/content-action.component";
import colors from "../../styles/colors";
import Moment from "moment";
import useSortCategories from "../../../core/hooks/use-sort-categories";
import { Category } from "../../../core/api/primio/primioSchemas";
import SelectionContextMenu from "../../components/selection-context-menu/selection-context-menu";
import { useDeleteCategory } from "../../../core/api/primio/primioComponents";
import { useQueryClient } from "@tanstack/react-query";

interface OwnProps {
  categories: Category[];
  onEdit?: (data: Category) => void;
}

type Props = OwnProps;

const CategoriesOverviewScreen: FC<OwnProps> = ({
  categories,
  onEdit,
}: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");
  const { mutate: deleteCategory } = useDeleteCategory({
    onSuccess: () => queryClient.invalidateQueries(["categories"]),
  });
  const { mutateAsync: sortCategories } = useSortCategories();

  const columns: ColumnsType<Category> = [
    {
      key: "title",
      title: Capitalize(t("form.card.title.label")),
      dataIndex: "title",
      width: 140,
      ...GetColumnSearchProps({ dataIndex: "title" }),
      render: function renderText(text) {
        return (
          <Typography.Text ellipsis style={{ width: 140 }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      key: "playbooks",
      title: Capitalize(t("containers.modules.key_plural")),
      dataIndex: "playbooks",
      width: 220,
      render: function renderText(_, data: Category) {
        const text = data.playbooks
          .map((playbook) => playbook.title)
          .join(", ");
        return <Typography.Text>{text}</Typography.Text>;
      },
    },
    {
      key: "createdAt",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("form.items.created_at.label"))}
        </Typography.Text>
      ),
      dataIndex: "createdAt",
      width: 120,
      sorter: (a: Category, b: Category) =>
        +new Date(a.createdAt) - +new Date(b.createdAt),
      render: function renderText(_, data: Category) {
        return (
          <Typography.Text>
            {Moment(data.createdAt).format("DD/MM/YYYY")}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      render: function showActions(_, data: Category) {
        return <Space size={"middle"}>{handleShowActions(data)}</Space>;
      },
    },
  ];

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    const sortedCategories = [...categories];
    const [newOrder] = sortedCategories.splice(dragIndex, 1);
    sortedCategories.splice(hoverIndex, 0, newOrder);

    await sortCategories(sortedCategories);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <SelectionContextMenu
        selectedRowKeys={selectedRowKeys}
        actions={[
          {
            key: "delete",
            label: Capitalize(t("common.delete")),
            icon: (
              <ContextActionIconComponent
                action={"delete"}
                style={{ color: "var(--error-color)" }}
              />
            ),
            color: "var(--error-color)",
            onClick: (selectedRowKeys) => {
              const selectedCategories = categories.filter((category) => {
                return selectedRowKeys.includes(category.categoryUid);
              });
              deleteCategoryModal(selectedCategories);
            },
          },
        ]}
        onClearSelection={() => setSelectedRowKeys([])}
      />
      <Table<Category>
        dataSource={categories}
        columns={columns}
        onRow={(_, index) => {
          const attr = { index, moveRow };
          return attr as React.HTMLAttributes<any>;
        }}
        rowKey={(data) => data.categoryUid}
        rowSelection={{
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
          ],
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        pagination={{ totalBoundaryShowSizeChanger: 10 }}
        size={"middle"}
        components={{ body: { row: DraggableTableRowComponent } }}
      />
    </DndProvider>
  );

  function handleShowActions(data: Category) {
    return (
      <>
        <Link to={{ pathname: `/categories/${data.categoryUid as string}` }}>
          <ContentActionComponent
            icon={<ContextActionIconComponent action={"open"} />}
            label={Capitalize(t("common.open"))}
            hideLabel={true}
          />
        </Link>
        <ContentActionComponent
          icon={
            <ContextActionIconComponent
              action={"edit"}
              style={{ color: "var(--primary-color)" }}
            />
          }
          label={Capitalize(t("common.edit"))}
          hideLabel={true}
          onClick={() => {
            if (onEdit) onEdit(data);
          }}
        />
      </>
    );
  }

  function deleteCategoryModal(data: Category[]) {
    const categoryNames: any = [];
    for (let i = 0; i < data.length; i++) {
      const title =
        data[i] === data[data.length - 1]
          ? data[i].title
          : data[i].title + ", ";
      categoryNames.push(title);
    }

    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete", { field: t("containers.categories.key") }),
      ),
      content: (
        <Typography.Text>
          {Capitalize(t("containers.categories.key"))}: {categoryNames}
        </Typography.Text>
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.delete")),
      onOk() {
        data.forEach((category: Category) => {
          deleteCategory({ pathParams: { categoryUid: category.categoryUid } });
        });
      },
    });
  }
};

export default CategoriesOverviewScreen;
