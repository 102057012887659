import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import { RoutesEnum } from "../../../constants/routes/app.route";
import LoginScreen from "../../screens/auth/login.screen";
import NewPasswordChallengeScreen from "../../screens/auth/new-password-challenge.screen";
import ForgetPasswordScreen from "../../screens/auth/forget-password.screen";
import ChangePasswordScreen from "../../screens/auth/change-password.screen";
import {
  IS_PULL_REQUEST,
  RENDER_GIT_COMMIT,
} from "../../../constants/config/env.config";
import PrPreviewToolbar from "../pr-preview-toolbar/pr-preview-toolbar";

const UnauthenticatedBody = () => {
  return (
    <Layout className={"App"}>
      {IS_PULL_REQUEST === "true" && <PrPreviewToolbar />}
      <Layout.Content className={"Auth_container"}>
        <Layout.Content className={"Auth_wrapper"}>
          <Switch>
            <Route path={RoutesEnum.LOGIN} component={LoginScreen} />
            <Route
              exact
              path={RoutesEnum.NEW_PASSWORD_CHALLENGE}
              component={NewPasswordChallengeScreen}
            />
            <Route
              exact
              path={RoutesEnum.FORGET_PASSWORD}
              component={ForgetPasswordScreen}
            />
            <Route
              exact
              path={RoutesEnum.CHANGE_PASSWORD}
              component={ChangePasswordScreen}
            />
          </Switch>
        </Layout.Content>
      </Layout.Content>
      <Layout.Footer className={"Footer"}>
        <span title={RENDER_GIT_COMMIT}>&copy; Primio</span>
      </Layout.Footer>
    </Layout>
  );
};

export default UnauthenticatedBody;
