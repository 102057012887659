import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { CheckOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import CharacterCounter from "../form-builder/components/character-counter";
import SourceModel from "../../../core/models/source/source.model";
import AppConfig from "../../../constants/config/app.config";
import colors from "../../styles/colors";

export interface CreateSourceInterface {
  text: string;
  url: string;
}

export interface CreateSourceFormRef {
  form: FormInstance<CreateSourceInterface>;
}

type Props = {
  source: SourceModel | undefined;
  onAdd: () => void;
  onEdit: (source: SourceModel) => void;
  onDelete: (source: SourceModel) => void;
};

const CreateSourceForm = forwardRef(
  (props: Props, ref: Ref<CreateSourceFormRef>) => {
    const [t] = useTranslation();
    const { source, onAdd, onEdit, onDelete } = props;
    const [form] = useForm<CreateSourceInterface>();
    const [text, setText] = useState<string>(source ? source.text : "");
    const [url, setUrl] = useState<string>(source ? source.url : "");
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const textMaxLength = AppConfig.formSourcesMaxLength;

    useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
      form.setFields([
        { name: "text", value: text },
        { name: "url", value: url },
      ]);
    });

    useEffect(() => {
      setText(source ? source.text : "");
      setUrl(source ? source.url : "");
    }, [source]);

    return (
      <div style={{ flex: 1, display: "flex", marginBottom: "2rem" }}>
        <Form form={form} component={false}>
          <div style={{ width: "80%" }}>
            <Form.Item
              initialValue={text}
              name={"text"}
              rules={getRules("text")}
            >
              <Input
                value={text}
                type={"text"}
                suffix={
                  <CharacterCounter
                    currentLength={text.length}
                    maxLength={textMaxLength}
                  />
                }
                maxLength={textMaxLength}
                placeholder={t("form.card.sources.placeholder-text")}
                onChange={(e) => {
                  if (!isChanged) setIsChanged(true);
                  setText(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              style={{ marginTop: "-1rem" }}
              initialValue={url}
              name={"url"}
              rules={getRules("url")}
            >
              <Input.TextArea
                value={url}
                placeholder={t("form.card.sources.placeholder-url")}
                onChange={(e) => {
                  if (!isChanged) setIsChanged(true);
                  setUrl(e.target.value);
                }}
              />
            </Form.Item>

            {isChanged && (
              <Form.Item style={{ float: "right", marginTop: "-1rem" }}>
                <Button
                  type={"primary"}
                  icon={<CheckOutlined />}
                  style={{ marginLeft: "1rem" }}
                  disabled={text.length === 0 || url.length === 0}
                  onClick={() => {
                    if (source) {
                      onEdit(source);
                    } else {
                      onAdd();
                    }
                    setIsChanged(false);
                  }}
                >
                  {source
                    ? Capitalize(t("common.edit"))
                    : Capitalize(t("common.add"))}
                </Button>
              </Form.Item>
            )}
          </div>

          {source && (
            <Form.Item>
              <Button
                type={"text"}
                style={{ marginLeft: "1rem" }}
                icon={
                  <ContextActionIconComponent
                    style={{ fontSize: "20px", color: colors.secondary }}
                    action={"delete"}
                  />
                }
                onClick={() => {
                  if (source) onDelete(source);
                  setIsChanged(false);
                }}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    );

    function getRules(field: string) {
      const message =
        field === "url"
          ? "content.media-types.EMBED.LINK"
          : "form.card.sources.label";
      return [
        {
          required: true,
          message: t("errors.required", { item: t(message) }),
        },
      ];
    }
  },
);

CreateSourceForm.displayName = "CreateSourceForm";

export default CreateSourceForm;
