export interface TextAnswerResponse {
  text: string;
  amount: number;
}

export default class TextAnswerModel {
  text: string;
  amount: number;

  constructor(response: TextAnswerResponse) {
    this.text = response.text;
    this.amount = response.amount;
  }
}
