import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Form, Input } from "antd";
import CharacterCounter from "../form-builder/components/character-counter";
import AppConfig from "../../../constants/config/app.config";
import UserGroupSelectFormItem from "../form-builder/components/user-group-select.form-item";

interface Props {
  onFinish?: () => void;
}

interface CreatePlaybookFormFields {
  title: string;
  description: string;
  userGroupAcl: string[];
}

export interface CreatePlaybookFormRef {
  form: FormInstance<CreatePlaybookFormFields>;
  focusTitle: () => void;
}

const CreatePlaybookForm = forwardRef(
  (props: Props, ref: Ref<CreatePlaybookFormRef>) => {
    const [t] = useTranslation();
    const [form] = useForm<CreatePlaybookFormFields>();
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const titleInput = useRef<typeof Input | any>(null);
    const [userGroups, setUserGroups] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({ form, focusTitle }));

    const titleMaxLength = AppConfig.contentTitleMaxLength;
    const descriptionMaxLength = AppConfig.contentDescriptionMaxLength;

    function focusTitle() {
      if (titleInput.current) {
        titleInput.current.focus();
      }
    }

    useEffect(() => {
      form.setFields([{ name: "userGroupAcl", value: userGroups }]);
    }, [userGroups]);

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          name={"title"}
          label={Capitalize(t("form.card.title.label"))}
          rules={getRules("title")}
        >
          <Input
            ref={titleInput}
            value={title}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={title.length}
                maxLength={titleMaxLength}
              />
            }
            maxLength={titleMaxLength}
            style={{ width: "90%" }}
            placeholder={t("form.placeholders.type_x", {
              item: t("form.card.title.label"),
            })}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name={"description"}
          label={Capitalize(t("form.card.description.label"))}
          rules={getRules("description")}
        >
          <Input
            value={description}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={description.length}
                maxLength={descriptionMaxLength}
              />
            }
            maxLength={descriptionMaxLength}
            style={{ width: "90%" }}
            placeholder={t("form.placeholders.type_x", {
              item: t("common.short") + " " + t("form.card.description.label"),
            })}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>

        <UserGroupSelectFormItem
          initialValue={userGroups}
          onChange={setUserGroups}
          excludeAdminGroups
        />
      </Form>
    );

    function getRules(field: string) {
      const maxLength =
        field === "title" ? titleMaxLength : descriptionMaxLength;

      return [
        {
          max: maxLength,
          message: Capitalize(
            t("errors.max-length", {
              field: t(`form.card.${field}.label`),
              amount: maxLength,
            }),
          ),
        },
        {
          required: field === "title",
          message: Capitalize(
            t("errors.required", {
              item: t(`form.card.${field}.label`),
            }),
          ),
        },
      ];
    }
  },
);

CreatePlaybookForm.displayName = "CreatePlaybookForm";

export default CreatePlaybookForm;
