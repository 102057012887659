import React, { useEffect, useState } from "react";
import { useUserContentCompletion } from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import UserCollectionCompletionModel from "../../../../core/models/user-progress/user-content-completion/user-collection-completion.model";
import graphStyles from "../../graphs/graph-container/graph-container.module.css";
import CardTypes from "../../../../core/types/card.types";
import CardUserAnswerSuffixComponent from "../../card-answer/card-user-answer-suffix.component";
import FilterCardTypesComponent from "../../actions/filter-card-types.component";
import { CardAnswerCollapseHeader } from "../../card-answer/card-answer.boilerplate";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import CardTag from "../../card-answer/tags/card-tag";
import Empty from "../../empty/empty";
import styles from "../user-detail.module.css";
import { Card } from "antd";
import { useCollectionDetail } from "../../../../core/hooks/content/content.hook";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";

interface Props {
  collectionUid: string;
  userUid: string;
}

const CardOverviewComponent = (props: Props) => {
  const { t } = useTranslation();
  const { collectionUid, userUid } = props;
  const [cards, setCards] = useState<CardTypes[]>([]);
  const [filteredCards, setFilteredCards] = useState<CardTypes[]>(cards);
  const [cardTypes, setCardTypes] = useState<CardTypesEnum[]>([]);
  const { data } = useUserContentCompletion(userUid, collectionUid);
  const collectionCompletion = data as
    | UserCollectionCompletionModel
    | undefined;

  const { data: collection } = useCollectionDetail(collectionUid);

  useEffect(() => {
    if (!collection) {
      setCards([]);
      return;
    }

    setCards(
      collection.cards
        .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED)
        .sort((a, b) => a.sort - b.sort),
    );
  }, [collection]);

  useEffect(() => {
    if (cardTypes.length === 0) {
      setFilteredCards(cards);
    } else {
      setFilteredCards(
        cards.filter((c) => cardTypes.some((type) => c.cardType === type)),
      );
    }
  }, [cards, cardTypes]);

  return (
    <div className={styles.overview_wrapper}>
      <FilterCardTypesComponent cards={cards} filterCardTypes={setCardTypes} />

      {renderEmptyState()}

      {filteredCards.map((card, index) => {
        const title =
          card.cardType === CardTypesEnum.KNOWLEDGE_CARD
            ? card.title
            : card.data.question;
        const opacity = renderFilteredCards().includes(card) ? 1 : 0.5;
        return (
          <div
            key={`${card.cardUid}_${card.version}_${index}`}
            className={styles.card_overview_wrapper}
            style={{ opacity }}
          >
            <Card style={{ border: "none" }} bodyStyle={{ paddingBottom: 0 }}>
              <CardAnswerCollapseHeader
                title={TranslateDefaults(title)}
                cardType={card.cardType}
                suffix={renderSuffix(card)}
                showExpanded={false}
                isExpanded={false}
              />
            </Card>
          </div>
        );
      })}
    </div>
  );

  function renderFilteredCards() {
    if (!collectionCompletion) {
      return [];
    }

    const filtered: CardTypes[] = [];
    collectionCompletion.cards.filter(({ cardUid }) => {
      cards.forEach((card) => {
        if (card.cardUid === cardUid) {
          filtered.push(card);
        }
      });
    });

    return filtered;
  }

  function renderSuffix(card: CardTypes) {
    if (renderFilteredCards().includes(card)) {
      return (
        <CardUserAnswerSuffixComponent
          card={card}
          userUid={userUid}
          collectionUid={collectionUid}
        />
      );
    } else {
      return (
        <CardTag
          type={"not-seen"}
          label={t("screens.user-detail.tags.not-seen")}
        />
      );
    }
  }

  function renderEmptyState() {
    if (filteredCards.length > 0 || cardTypes.length !== 1) {
      return;
    }

    const message = t(`screens.user-detail.card-overview.empty-state`, {
      contentType: t(`content.card-types.${cardTypes[0]}`),
    });

    return (
      <div className={graphStyles.container} style={{ height: "17.5rem" }}>
        <div className={graphStyles.container_spinner}>
          <div className={graphStyles.container_spinner_item}>
            <Empty image={"cloud"} message={message} />
          </div>
        </div>
      </div>
    );
  }
};

export default CardOverviewComponent;
