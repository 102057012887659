import React, { CSSProperties, useEffect, useState } from "react";
import ProgressMetaIconComponent from "../icons/progress-meta-icon.component";
import StatisticsBlockComponent from "./statistics-block.component";
import { useSelector } from "react-redux";
import { selectPlaybookViaUid } from "../../../core/redux/selectors/content/content.selector";
import PlaybookModel from "../../../core/models/content/playbook.model";
import { useApiKeyClient } from "../../../core/hooks/api-key/api-key.hook";
import { useTranslation } from "react-i18next";

interface Props {
  playbookUid: string;
  style?: CSSProperties;
}

const SharedContentStatisticBlockComponent = ({
  playbookUid,
  style,
}: Props) => {
  const playbook: PlaybookModel | undefined = useSelector((state) =>
    selectPlaybookViaUid(state, playbookUid),
  );

  useEffect(() => {
    if (!playbook) return;
  }, [playbook]);

  if (!playbook || !playbook.apiKeyClientUid) return null;

  return (
    <div>
      <SharedContentStatistic
        apiKeyClientUid={playbook.apiKeyClientUid}
        style={style}
      />
    </div>
  );
};

const SharedContentStatistic = ({
  apiKeyClientUid,
  style,
}: {
  apiKeyClientUid: string;
  style?: CSSProperties;
}) => {
  const { t } = useTranslation();
  const { data } = useApiKeyClient(apiKeyClientUid);
  const [server, setServer] = useState<string>();

  useEffect(() => {
    if (!data) return;

    setServer(data.server);
  }, [data]);

  if (!server) {
    return null;
  }

  return (
    <StatisticsBlockComponent
      icon={<ProgressMetaIconComponent icon={"shared"} style={style} />}
      text={t("translations:screens.shared-content.statistics-block.label")}
      title={server}
    />
  );
};

export default SharedContentStatisticBlockComponent;
