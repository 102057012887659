import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { EmbedProvidersEnum } from "../../../../core/enums/media-providers.enum";
import MediaModel from "../../../../core/models/media/media.model";
import TextArea from "antd/lib/input/TextArea";
import styles from "../../media-types/media.module.css";

type Props = {
  media: MediaModel | undefined;
  onSubmit: (
    videoId: string,
    provider: EmbedProvidersEnum,
    isValid: boolean,
  ) => void;
};

const EmbedCodeUploadComponent = (props: Props) => {
  const [t] = useTranslation();
  const { media, onSubmit } = props;
  const [form] = useForm();
  const [embed, setEmbed] = useState<string>(media?.mediaUid ? media.uri : "");

  useEffect(() => {
    form.setFields([
      {
        name: "embed",
        value: embed,
      },
    ]);

    validateAndSubmit();
  }, [embed]);

  async function validateAndSubmit() {
    const isValid = await form.validateFields();
    onSubmit(embed, EmbedProvidersEnum.CODE, isValid);
  }

  return (
    <Form form={form} className={styles.upload_wrapper_vimeo} component={false}>
      <Form.Item
        name={"embed"}
        style={{ paddingTop: "1.5rem" }}
        label={"Embedded"}
        rules={getRules()}
      >
        <TextArea
          value={embed}
          rows={4}
          style={{ width: "80%" }}
          placeholder={
            '<iframe src="https://www.youtube.com/embed/sGF6bOi1NfA" width="200" height="100" allowfullscreen></iframe>'
          }
          onChange={(e) => setEmbed(e.target.value)}
        />
      </Form.Item>
    </Form>
  );

  function getRules() {
    return [
      {
        required: true,
        message: Capitalize(t("errors.required", { item: "embedded code" })),
      },
    ];
  }
};

export default EmbedCodeUploadComponent;
