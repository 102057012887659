import React from "react";
import {
  AudioProvidersEnum,
  EmbedProvidersEnum,
  ImageProvidersEnum,
  MediaTypesEnum,
  VideoProvidersEnum,
} from "../../../core/enums/media-providers.enum";
import ImageUploadComponent from "./image-upload.component";
import GiphyUploadComponent from "./giphy/giphy-upload.component";
import UnsplashUploadComponent from "./unsplash/unsplash-upload.component";
import VideoUploadComponent from "./video-upload.component";
import VimeoUploadComponent from "./vimeo-upload.component";
import YoutubeUploadComponent from "./youtube-upload.component";
import EmbedLinkUploadComponent from "./embed/embed-link-upload.component";
import EmbedCodeUploadComponent from "./embed/embed-code-upload.component";
import MediaModel from "../../../core/models/media/media.model";
import AudioUploadComponent from "./audio-upload.component";
import MediaProvidersTypes from "../../../core/types/media-providers.types";

type Props = {
  media: MediaModel;
  onUpload: (mediaUid: string, mediaOrientation?: string) => void;
  onSubmit: (videoId: string, provider: MediaProvidersTypes, isValid) => void;
};

const MediaUploadComponent = ({ media, onUpload, onSubmit }: Props) => {
  switch (media.mediaType) {
    case MediaTypesEnum.IMAGE:
      switch (media.provider) {
        case ImageProvidersEnum.S3:
          return (
            <div style={{ paddingTop: "1rem" }}>
              <ImageUploadComponent
                showCropSelectPresetOptions
                media={media}
                onUpload={onUpload}
                aspectRatio={16 / 9}
              />
            </div>
          );
        case ImageProvidersEnum.UNSPLASH:
          return <UnsplashUploadComponent media={media} onSubmit={onSubmit} />;
        case ImageProvidersEnum.GIPHY:
          return <GiphyUploadComponent media={media} onSubmit={onSubmit} />;
      }
      return null;
    case MediaTypesEnum.VIDEO:
      switch (media.provider) {
        case VideoProvidersEnum.S3:
          return <VideoUploadComponent media={media} onUpload={onUpload} />;
        case VideoProvidersEnum.VIMEO:
          return <VimeoUploadComponent media={media} onSubmit={onSubmit} />;
        case VideoProvidersEnum.YOUTUBE:
          return <YoutubeUploadComponent media={media} onSubmit={onSubmit} />;
      }
      return null;
    case MediaTypesEnum.AUDIO:
      switch (media.provider) {
        case AudioProvidersEnum.S3:
          return <AudioUploadComponent media={media} onUpload={onUpload} />;
      }
      return null;
    case MediaTypesEnum.EMBED:
      switch (media.provider) {
        case EmbedProvidersEnum.LINK:
          return <EmbedLinkUploadComponent media={media} onSubmit={onSubmit} />;
        case EmbedProvidersEnum.CODE:
          return <EmbedCodeUploadComponent media={media} onSubmit={onSubmit} />;
      }
      return null;
    default:
      return null;
  }
};

export default MediaUploadComponent;
