import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import PlaybookModel, { PlaybookResponse } from "../content/playbook.model";

export interface CategoryResponse {
  categoryUid: string;
  title: string;
  sort?: number;
  createdAt: Date;
  playbooks?: PlaybookResponse[];
  playbookOrder?: string[];
}

export default class CategoryModel {
  categoryUid: string;
  title: string;
  sort?: number;
  createdAt: Date;
  playbooks: PlaybookModel[];
  playbookOrder?: string[];

  constructor(category: CategoryResponse) {
    this.categoryUid = category.categoryUid;
    this.title = category.title;
    this.createdAt = category.createdAt;
    this.sort = category.sort;

    if (category.playbooks && category.playbooks.length > 0) {
      this.playbooks = MapResponseToModel(category.playbooks, PlaybookModel);
      this.playbookOrder = category.playbookOrder;
    } else {
      this.playbooks = [];
    }
  }
}
