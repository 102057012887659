import BaseCompletedUserProgressModel, {
  BaseCompletedUserProgressResponse,
} from "./base-completed-user-progress.model";
import ChapterCompletedUserProgressModel from "./chapter-completed-user-progress.model";

export interface PlaybookCompletedUserProgressResponse
  extends BaseCompletedUserProgressResponse {
  chapters: ChapterCompletedUserProgressModel[];
}

export default class PlaybookCompletedUserProgressModel extends BaseCompletedUserProgressModel {
  playbookUid: string;
  chapters: ChapterCompletedUserProgressModel[];

  constructor(
    playbookUid: string,
    response: PlaybookCompletedUserProgressResponse,
  ) {
    super(response);
    this.playbookUid = playbookUid;

    if (response.chapters) {
      this.chapters = Object.entries(response.chapters).map(
        ([chapterUid, value]) => {
          return new ChapterCompletedUserProgressModel(chapterUid, value);
        },
      );
    } else {
      this.chapters = [];
    }
  }
}
