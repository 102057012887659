import React from "react";
import { useSelector } from "react-redux";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { PaperClipOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import Link from "antd/lib/typography/Link";
import MediaAction from "../../../core/redux/stores/media/media.action";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import styles from "./media.module.css";

interface Props {
  videoId: string;
  visible?: boolean;
  width?: number | string;
  height?: number | string;
}

const GiphyMediaComponent = (props: Props) => {
  const { videoId, visible, width, height } = props;
  const requesting: boolean = useSelector((state) =>
    selectRequesting(state, [MediaAction.REQUEST_MEDIA]),
  );
  const error: HttpErrorResponseModel = useSelector((state) =>
    selectRawErrors(state, [MediaAction.REQUEST_MEDIA_FINISHED]),
  );

  if (requesting || error.errors) {
    return <Skeleton.Image style={{ width: width, height: height }} />;
  }

  return (
    <>
      <iframe
        title={"Giphy"}
        width={width}
        height={height}
        className={styles.giphy_media}
        src={`https://giphy.com/embed/${videoId}`}
        frameBorder={"0"}
        allowFullScreen
      />
      {visible && (
        <div>
          <PaperClipOutlined />
          <Link
            href={`https://media.giphy.com/media/${videoId}/giphy.gif`}
            target={"_blank"}
          >
            {"Giphy"}
          </Link>
        </div>
      )}
    </>
  );
};

GiphyMediaComponent.defaultProps = {
  visible: true,
  width: 150,
  height: 100,
};

export default GiphyMediaComponent;
