import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, Result, Steps, Typography } from "antd";
import CreateApiKeyClientForm, {
  CreateApiKeyClientFormRef,
} from "../forms/create-api-key-client.form";
import { grey } from "@ant-design/colors";
import { useCreateApiKeyClientMutation } from "../../../core/hooks/api-key/api-key.hook";
import { CreateApiKeyClientInterface } from "../../../core/interfaces/create-api-key.interface";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../core/providers/app.provider";
import { Capitalize } from "../../../core/utils/helper.utils";
import CreateApiKeyClientResponse from "../../../core/models/api-key/create-api-key-client.response";
import { useDispatch, useSelector } from "react-redux";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import { selectPlaybookByApikeyClientUid } from "../../../core/redux/selectors/content/content.selector";
import Tag from "../tag/tag";
import { decryptCipher } from "../../../core/utils/cipher-text";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const CreateApiKeyClientModel = (props: Props) => {
  const [t] = useTranslation();
  const createApiKeyClientFormRef = useRef<CreateApiKeyClientFormRef>(null);
  const { mutate } = useCreateApiKeyClientMutation();
  const [errors, setErrors] = useState<string>();
  const [okText, setOkText] = useState<string>(Capitalize(t("common.next")));
  const [step, setStep] = useState(0);
  const [newApikeyClientUid, setNewApikeyClientUid] = useState<string>();

  const onCancel = () => {
    createApiKeyClientFormRef.current?.reset();
    setErrors(undefined);
    props.onCancel();
  };

  const handleOnOk = () => {
    switch (step) {
      case 0:
        if (!createApiKeyClientFormRef.current) return;
        createApiKeyClientFormRef.current.form
          .validateFields()
          .then(({ cipher }) => {
            try {
              const apiHashContent = decryptCipher(cipher);

              if (!apiHashContent) {
                setErrors(
                  t("errors.validation.invalid", {
                    item: t("common.shared-content-code"),
                  }),
                );
                return;
              }

              const { apiKeyUid, server } = apiHashContent;
              const dataCreateApiKeyClient: CreateApiKeyClientInterface = {
                server: server.replace("https://", "").replace("http://", ""),
                apiKey: apiKeyUid,
              };

              mutate(dataCreateApiKeyClient, {
                onSuccess: (data: CreateApiKeyClientResponse) => {
                  queryClient.invalidateQueries(["apiKeyClient"]);
                  setNewApikeyClientUid(data.apiKeyClientUid);
                  setStep(1);
                  setOkText(Capitalize(t("common.done")));
                },
                onError: (error: any) => {
                  setErrors(error.message);
                },
              });
            } catch (e: any) {
              setErrors(e.message);
            }
          });
        break;
      case 1:
        onCancel();
        break;
    }
  };

  function renderModalTitle() {
    return (
      <Steps current={step} progressDot style={{ marginTop: "3rem" }}>
        <Steps.Step title={t("screens.shared-content-client.step-0.label")} />
        <Steps.Step title={t("screens.shared-content-client.step-1.label")} />
      </Steps>
    );
  }

  return (
    <Modal
      title={renderModalTitle()}
      width={"80rem"}
      open={props.visible}
      onCancel={onCancel}
      cancelButtonProps={{
        hidden: step === 1,
      }}
      cancelText={Capitalize(t("common.cancel"))}
      onOk={handleOnOk}
      okText={okText}
    >
      {step === 0 && (
        <>
          <Typography.Paragraph
            style={{ color: grey[6], fontWeight: "bold", fontSize: 16 }}
          >
            {t("screens.shared-content-client.step-0.title")}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t("screens.shared-content-client.step-0.description")}
          </Typography.Paragraph>
          <CreateApiKeyClientForm ref={createApiKeyClientFormRef} />
          <Form.ErrorList errors={[errors]} />
        </>
      )}
      {step === 1 && newApikeyClientUid && (
        <>
          <FinalScreen apikeyClientUid={newApikeyClientUid} />
        </>
      )}
    </Modal>
  );
};

const FinalScreen = ({ apikeyClientUid }: { apikeyClientUid: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const importedPlaybooks = useSelector((state) =>
    selectPlaybookByApikeyClientUid(state, apikeyClientUid),
  );

  useEffect(() => {
    dispatch(ContentAction.getContent(ContentTypesEnum.PLAYBOOK));
  }, []);

  return (
    <>
      <Result
        status="success"
        title={Capitalize(t("translations:common.success"))}
        style={{ padding: "0 0 32px 0" }}
      />
      {importedPlaybooks.length > 0 && (
        <>
          <Typography.Title
            style={{ color: grey[6], fontWeight: "bold", fontSize: 16 }}
          >
            {t("screens.shared-content-client.step-1.title")}
          </Typography.Title>
          <Typography.Paragraph>
            {t("screens.shared-content-client.step-1.description")}
          </Typography.Paragraph>
          {importedPlaybooks.map((playbook, index) => (
            <Tag key={index}>{playbook.title}</Tag>
          ))}
        </>
      )}
    </>
  );
};

export default CreateApiKeyClientModel;
