import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import CreatePlaybookModal from "../../views/components/modals/create-playbook.modal";
import CreateCategoryModal from "../../views/components/modals/create-category.modal";
import CreateLearningPathModal from "../../views/components/modals/create-learning-path.modal";
import CreateQrCodeModal from "../../views/components/modals/create-qr-code.modal";
import CreateNotificationModal from "../../views/components/modals/create-notification.modal";
import CreateUserGroupModal from "../../views/components/modals/create-user-group.modal";
import CreateInvitationModal from "../../views/components/modals/create-invitation.modal";
import CreateImportInvitationModal from "../../views/components/modals/create-import-invitation.modal";
import CommandBarModal from "../../views/components/command-bar/command-bar-modal";
import CreateLearningPathViaCsvModal from "../../views/components/modals/create-learning-path-via-csv-modal";

export enum ModalNames {
  CREATE_PLAYBOOK,
  CREATE_CATEGORY,
  CREATE_LEARNING_PATH,
  CREATE_QR,
  CREATE_NOTIFICATION,
  CREATE_USER_GROUP,
  CREATE_INVITATION,
  IMPORT_INVITATIONS,
  SEARCH_AND_COMMANDS,
  CREATE_LEARNING_PATH_VIA_CSV,
}

interface ModalContextType {
  openModal: (modalName: ModalNames, params?: any) => void;
}

const ModalContext = createContext({} as ModalContextType);

const ModalProvider = ({ children }: PropsWithChildren) => {
  const [activeModal, setActiveModal] = useState<ModalNames>();
  const [params, setParams] = useState<any>();
  const openModal = (modalName: ModalNames, params?: any) => {
    if (activeModal === modalName) {
      return;
    }

    setActiveModal(modalName);
    if (params) setParams(params);
  };

  const handleOnClose = () => {
    setActiveModal(undefined);
    setParams(undefined);
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      <CreatePlaybookModal
        visible={activeModal === ModalNames.CREATE_PLAYBOOK}
        onCancel={handleOnClose}
      />
      <CreateCategoryModal
        visible={activeModal === ModalNames.CREATE_CATEGORY}
        onCancel={handleOnClose}
        {...params}
      />
      <CreateLearningPathModal
        visible={activeModal === ModalNames.CREATE_LEARNING_PATH}
        onCancel={handleOnClose}
        {...params}
      />
      <CreateQrCodeModal
        visible={activeModal === ModalNames.CREATE_QR}
        onCancel={handleOnClose}
        {...params}
      />
      <CreateNotificationModal
        visible={activeModal === ModalNames.CREATE_NOTIFICATION}
        onCancel={handleOnClose}
        {...params}
      />
      <CreateUserGroupModal
        visible={activeModal === ModalNames.CREATE_USER_GROUP}
        onCancel={handleOnClose}
        {...params}
      />
      <CreateInvitationModal
        visible={activeModal === ModalNames.CREATE_INVITATION}
        onCancel={handleOnClose}
        {...params}
      />
      <CreateImportInvitationModal
        visible={activeModal === ModalNames.IMPORT_INVITATIONS}
        onCancel={handleOnClose}
      />
      <CommandBarModal
        open={activeModal === ModalNames.SEARCH_AND_COMMANDS}
        onClose={handleOnClose}
      />
      <CreateLearningPathViaCsvModal
        open={activeModal === ModalNames.CREATE_LEARNING_PATH_VIA_CSV}
        onClose={handleOnClose}
        {...params}
      />
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
