import React from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import DecodeMediaUri from "../../../core/utils/decode-media-uri.utils";

interface Props {
  path: string;
  visible?: boolean;
}

const VideoMediaComponent = ({ path, visible }: Props) => {
  return (
    <>
      <video width={200} height={100} controls>
        <source src={path} />
      </video>
      {visible && (
        <div>
          <PaperClipOutlined />
          <Link href={path} target={"_blank"}>
            {DecodeMediaUri(path)}
          </Link>
        </div>
      )}
    </>
  );
};

VideoMediaComponent.defaultProps = {
  visible: true,
};

export default VideoMediaComponent;
