import { API_URL } from "../../constants/config/env.config";

const ApiKeyClientEndpoints = {
  /**
   * GET all Api Keys Clients endpoint.
   * @returns API_URL + /api-keys/clients/
   */
  overview: `${API_URL}/api-keys/clients/`,

  /**
   * GET one Api Key Client endpoint.
   * @param apiKeyClientUid string
   * @returns API_URL + /api-keys/clients/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
   */
  detail(apiKeyClientUid: string) {
    return `${API_URL}/api-keys/clients/${apiKeyClientUid}`;
  },

  /**
   * POST create Api Key Client endpoint.
   * @returns API_URL + /api-keys/clients/
   */
  create: `${API_URL}/api-keys/clients/`,

  delete(apiKeyClientUid: string) {
    return `${API_URL}/api-keys/clients/${apiKeyClientUid}`;
  },
};

export default ApiKeyClientEndpoints;
