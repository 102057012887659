import { Rule } from "antd/lib/form";
import { Capitalize } from "./helper.utils";
import { useTranslation } from "react-i18next";
import AppConfig from "../../constants/config/app.config";

export function getRules(
  field: string,
  optional: boolean | undefined,
  overrideMaxLength?: number,
): Rule[] {
  const [t] = useTranslation();
  let maxLength;

  switch (field) {
    case "title":
      maxLength = overrideMaxLength
        ? AppConfig.knowledgeTitleMaxLength
        : AppConfig.formTitleMaxLength;
      break;
    case "description":
      maxLength = overrideMaxLength
        ? overrideMaxLength
        : AppConfig.formDescriptionMaxLength;
      break;
    case "question":
      maxLength = AppConfig.formQuestionMaxLength;
      break;
  }

  return [
    {
      max: maxLength,
      message: Capitalize(
        t("errors.max-length", {
          field: t(`form.card.${field}.label`),
          amount: maxLength,
        }),
      ),
    },
    {
      required: !optional,
      message: Capitalize(
        t("errors.required", { item: t(`form.card.${field}.label`) }),
      ),
    },
  ];
}
