import React, { useEffect, useRef, useState } from "react";
import { Input, InputRef, Tooltip } from "antd";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import useCreateCommands from "./hooks/use-create-commands";
import useClampedNumber from "../../../core/hooks/use-clamped-value";
import { Command } from "./types/command.type";
import styles from "./command-bar-modal.module.css";
import { EnterOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";

const CommandBarModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const commands = useCreateCommands();
  const { selectedIndex, setSelectedIndex } = useClampedNumber(
    0,
    0,
    commands.length - 1,
  );
  const inputRef = useRef<InputRef>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [query, setQuery] = useState<string>("");
  const [filteredCommands, setFilteredCommands] = useState<Command[]>([]);

  useHotkeys("ArrowUp", () => handleKeyboardNavigation(selectedIndex - 1), {
    enabled: open,
    ignoredElementWhitelist: ["INPUT"],
  });
  useHotkeys("ArrowDown", () => handleKeyboardNavigation(selectedIndex + 1), {
    enabled: open,
    ignoredElementWhitelist: ["INPUT"],
  });
  useHotkeys("Enter", () => performAction(), {
    enabled: open,
    ignoredElementWhitelist: ["INPUT"],
  });
  useHotkeys("Escape", () => handleOnClose(), {
    enabled: open,
    ignoredElementWhitelist: ["INPUT"],
  });

  useEffect(() => {
    setFilteredCommands(
      commands.filter((c) => {
        if (query.trim() === "") {
          return true;
        }

        const words = query.split(" ");
        return words.some((query) =>
          c.title.toLowerCase().includes(query.toLowerCase()),
        );
      }),
    );
  }, [commands, query]);

  useEffect(() => {
    if (selectedIndex > filteredCommands.length - 1) {
      setSelectedIndex(0);
    }
  }, [selectedIndex, filteredCommands]);

  const handleKeyboardNavigation = (index: number) => {
    setSelectedIndex(index);

    if (index === -1 || listRef.current?.children[index] === undefined) {
      return;
    }
    listRef.current?.children[index].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const performAction = (index?: number) => {
    handleOnClose();
    filteredCommands[index ? index : selectedIndex].action();
  };

  const handleOnClose = () => {
    setSelectedIndex(0);
    setQuery("");
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <div title={"test"} className={styles.CommandBarModal_Background}>
      <div className={styles.CloseLayer} onClick={handleOnClose} />
      <div className={styles.CommandBarModal}>
        <Input
          className={styles.CommandBarModal_SearchBar}
          ref={inputRef}
          autoFocus
          size={"large"}
          type={"text"}
          placeholder={Capitalize(
            t("translations:screens.command-bar.placeholder"),
          )}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        />
        <div className={styles.CommandBarModal_CommandList_Container}>
          <ul ref={listRef} style={{ margin: 0, padding: 0 }}>
            {filteredCommands.map((command, index) => (
              <li
                key={index}
                className={styles.CommandBarModal_CommandList_Item}
                style={{
                  background:
                    index === selectedIndex
                      ? "var(--grey200-color)"
                      : undefined,
                }}
                onClick={() => performAction(index)}
                onMouseEnter={() => setSelectedIndex(index)}
              >
                <div className={styles.CommandBarModal_CommandList_Flexbox}>
                  <div style={{ paddingRight: 12 }}>{command.icon}</div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <span className={styles.Title}>{command.title}</span>
                    <span className={styles.Tag}>
                      {t(
                        `translations:screens.command-bar.command-types.${command.type}`,
                      )}
                    </span>
                  </div>
                  {index === selectedIndex && (
                    <Tooltip
                      title={Capitalize(
                        t("translations:screens.command-bar.tooptip"),
                      )}
                    >
                      <div className={styles.ReturnIcon}>
                        <EnterOutlined style={{ color: "white" }} />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CommandBarModal;
