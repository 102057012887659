import React, { useState } from "react";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";
import { useDispatch } from "react-redux";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import { disableButton } from "../../../../core/utils/media-disable-button.utils";
import { MediaTypesEnum } from "../../../../core/enums/media-providers.enum";
import KnowledgeCardModel from "../../../../core/models/content/card-types/knowledge/knowledge-card.model";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import CreateKnowledgeCardModel, {
  CreateKnowledgeCardInterface,
} from "../../../../core/models/create-content/card-types/create-knowledge-card.model";
import MediaUploadSelectComponent from "../../media-upload/media-upload-select.component";
import SourceContainerComponent from "../../source/source-container.component";
import MediaModel from "../../../../core/models/media/media.model";
import AppConfig from "../../../../constants/config/app.config";
import CleanHTML from "../../../../core/utils/clean-html";

type CreateProps = {
  cardContent: undefined;
  collectionUid: string;
};

type EditProps = {
  cardContent: KnowledgeCardModel;
};

type Props = CreateProps | EditProps;

const CreateKnowledgeCardForm = (props: Props) => {
  const { cardContent } = props;
  const [media, setMedia] = useState<MediaModel | undefined>(
    cardContent?.media ? cardContent.media[0] : undefined,
  );
  const dispatch = useDispatch();

  const descriptionMaxLength =
    !media || media?.mediaType === MediaTypesEnum.NO_MEDIA
      ? AppConfig.knowledgeDescriptionMaxLength
      : AppConfig.knowledgeDescriptionTrimmedLength;

  const isCreating = props.cardContent === undefined;
  const isEditing = !isCreating;

  function handleOnFinish(
    data: CreateCardInterface,
    isPressedButton?: ContentButtonStatesEnum,
  ) {
    const createKnowledgeCard: CreateKnowledgeCardInterface = {
      ...data,
      description: CleanHTML(data.description),
      collectionUid: props.cardContent
        ? props.cardContent.collectionUid
        : props.collectionUid,
      cardType: CardTypesEnum.KNOWLEDGE_CARD,
      mediaUids: media?.mediaUid ? [media.mediaUid] : data.mediaUids,
      data: {
        text: media?.mediaUid ? media.mediaUid : " ",
      },
    };

    const CreateKnowledgeCard = new CreateKnowledgeCardModel(
      createKnowledgeCard,
    );

    if (isCreating) {
      dispatch(
        ContentAction.createContent(ContentTypesEnum.CARD, CreateKnowledgeCard),
      );
    }

    if (isEditing && cardContent) {
      if (
        isPressedButton &&
        cardContent.contentState === ContentStatesEnum.PUBLISHED
      ) {
        switch (isPressedButton) {
          case ContentButtonStatesEnum.CREATE:
            dispatch(
              ContentAction.updateContent(
                ContentTypesEnum.CARD,
                cardContent,
                CreateKnowledgeCard,
              ),
            );
            break;
          case ContentButtonStatesEnum.UPDATE:
            cardContent.version++;
            dispatch(
              ContentAction.updateAndPublishCard(
                cardContent,
                CreateKnowledgeCard,
              ),
            );
            break;
        }
      } else {
        dispatch(
          ContentAction.updateContent(
            ContentTypesEnum.CARD,
            cardContent,
            CreateKnowledgeCard,
          ),
        );
      }
    }
  }

  return (
    <CreateCardBaseForm
      cardContent={cardContent}
      disabledButton={disableButton(media)}
      maxLength={descriptionMaxLength}
      richText={true}
      isOptional={false}
      onSubmit={handleOnFinish}
    >
      <>
        <MediaUploadSelectComponent
          media={media}
          onChange={setMedia}
          contentType={ContentTypesEnum.CARD}
        />

        {cardContent && <SourceContainerComponent cardContent={cardContent} />}
      </>
    </CreateCardBaseForm>
  );
};

export default CreateKnowledgeCardForm;
