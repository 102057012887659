import { RoutesEnum } from "../../constants/routes/app.route";
import UserRoleEnum from "../enums/user-role.enum";

type RoutesPermissionsMapInterface = {
  [route in keyof typeof RoutesEnum]: UserRoleEnum[];
};

/**
 * For each Route this will return the array of UserRoleEnum that are WhiteListed to have permission to access.
 * If the array is empty, the route is open for all Users
 */
const RoutesPermissionsMap: RoutesPermissionsMapInterface = {
  // Non-auth
  LOGIN: [],
  NEW_PASSWORD_CHALLENGE: [],
  CHANGE_PASSWORD: [],
  FORGET_PASSWORD: [],

  // Auth
  HOME: [],
  PLAYBOOKS: [],

  // Todo: Use entitlements instead of roles
  ACTIVITY: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  CATEGORIES: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  CATEGORIES_DETAIL: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],

  USER_ACTIVITY: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  MODULE_DETAIL: [],
  CHAPTER_DETAIL: [],
  COLLECTION_DETAIL: [],
  CARD_DETAIL: [],
  PUBLISHED_CONTENT: [],
  PUBLISHED_CONTENT_USER_PROGRESS: [],
  PUBLISHED_CONTENT_USER_PROGRESS_PLAYBOOK: [],
  PUBLISHED_CONTENT_USER_PROGRESS_CHAPTER: [],
  PUBLISHED_CONTENT_USER_PROGRESS_COLLECTION: [],

  EDIT_MODE: [],
  EDIT_MODE_PLAYBOOK: [],
  EDIT_MODE_CHAPTER: [],
  EDIT_MODE_COLLECTION: [],
  EDIT_MODE_CARD: [],

  EXPORT_PLAYBOOKS: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  IMPORT_PLAYBOOKS: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],

  INVITATIONS: [],

  LEARNING_PATH: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  LEARNING_PATH_DETAIL_LEGACY: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  LEARNING_PATH_DETAIL: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  LEARNING_PATH_DETAIL_CONTENT: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  LEARNING_PATH_DETAIL_REPORT: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],

  NOTIFICATIONS: [],
  NOTIFICATIONS_DETAIL: [],

  QR_CODE: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  QR_CODE_DETAIL: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],

  SHARED_CONTENT: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  SHARED_CONTENT_CLIENT: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  SHARED_CONTENT_DETAIL: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],

  USERS: [
    UserRoleEnum.SYS_ADMIN,
    UserRoleEnum.PRIMIO_SUPPORT,
    UserRoleEnum.ADMIN,
  ],
  USER_DETAIL: [],

  USER_GROUPS: [],
  USER_GROUP_DETAILS: [],

  FEATURE_FLAGS: [UserRoleEnum.SYS_ADMIN, UserRoleEnum.PRIMIO_SUPPORT],
};

export default RoutesPermissionsMap;
