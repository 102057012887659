import { ContentApi } from "../../api/content/content.api";
import ContentTypesEnum from "../../enums/content-types.enum";
import { AxiosResponse } from "axios";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import HttpUtility from "../../utils/http.utils";

export async function importPlaybook(playbook: any) {
  const endpoints = new ContentApi(ContentTypesEnum.PLAYBOOK).endpoints;
  if (endpoints.contentType !== ContentTypesEnum.PLAYBOOK) {
    return;
  }

  const endpoint = endpoints.import();
  const response: AxiosResponse<string> | HttpErrorResponseModel =
    await HttpUtility.post(endpoint, playbook);

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
