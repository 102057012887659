import ContentEndpoints from "./content.endpoints";
import ContentTypesEnum from "../../../enums/content-types.enum";

export default class PlaybookEndpoints extends ContentEndpoints {
  contentType: ContentTypesEnum.PLAYBOOK;

  constructor() {
    super(ContentTypesEnum.PLAYBOOK);
    this.contentType = ContentTypesEnum.PLAYBOOK;
  }

  export(playbookUid: string) {
    return `${this.getContentPath()}/${playbookUid}/export`;
  }

  import() {
    return `${this.getContentPath()}/imports`;
  }

  /**
   * Used for getting the entire playbook hierarchy
   * @param id string
   * @returns API_URL + /playbooks/1b21d7d3-6788-4383-aa6e-0a9163e462c3/detail
   */
  details(id: string) {
    return `${this.getContentPath()}/${id}/details`;
  }
}
