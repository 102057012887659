import React, { CSSProperties } from "react";
import { Card, Col, Divider, Row, Typography } from "antd";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import styles from "../user-progress/user-progress.module.css";

type Props = {
  size?: "small" | "middle" | "large";
  style?: CSSProperties;
  cardStyle?: CSSProperties;
  //
  title: string;
  description: string;
  action?: JSX.Element;
  progressBar?: JSX.Element;
  suffix?: JSX.Element;
  suffixAction?: JSX.Element;
};

const ContentUserProgressBoilerplate = (props: Props) => {
  const {
    size,
    style,
    title,
    description,
    action,
    progressBar,
    suffix,
    suffixAction,
  } = props;

  const renderCardTitle = () => {
    let level: 1 | 3 | 2 | 4 | 5 | undefined = 4;
    switch (size) {
      case "small":
        level = 4;
        break;
      case "middle":
        level = 3;
        break;
      case "large":
        level = 2;
        break;
    }

    return (
      <Row>
        <Col flex={1}>
          <Typography.Title level={level} style={{ margin: 0 }}>
            {TranslateDefaults(title)}
          </Typography.Title>
        </Col>
        <Col>{action && action}</Col>
      </Row>
    );
  };

  const renderCardDescription = () => {
    let style: CSSProperties = { fontSize: "14px" };

    switch (size) {
      case "small":
        style = { fontSize: "14px" };
        break;
      case "middle":
        style = { fontSize: "15px" };
        break;
      case "large":
        style = { fontSize: "16px" };
        break;
    }

    return (
      <Typography.Text type={"secondary"} style={style}>
        {TranslateDefaults(description)}
      </Typography.Text>
    );
  };

  const renderCardSuffix = () => {
    return (
      <div className={styles.pcup_card_suffix}>
        {suffix && (
          <>
            <Divider style={{ margin: "0rem 0rem 1.2rem 0rem" }} />
            <Row align={"middle"} style={{ padding: "0 2.4rem 1.2rem 2rem" }}>
              <Col style={{ flex: 1 }}>
                <Row align={"middle"}>{suffix && suffix}</Row>
              </Col>

              <Col>{suffixAction && suffixAction}</Col>
            </Row>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={styles.pcup_card_wrapper} style={style}>
      <Card className={styles.pcup_card} bodyStyle={props.cardStyle}>
        <Card.Meta
          title={renderCardTitle()}
          description={renderCardDescription()}
          style={{ flex: 1 }}
        />
        {progressBar && progressBar}
      </Card>
      {renderCardSuffix()}
    </div>
  );
};

ContentUserProgressBoilerplate.defaultProps = {
  size: "small",
};

export default ContentUserProgressBoilerplate;
