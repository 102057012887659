import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import StoreUtility from "../../../../core/utils/store.utils";
import UserGroupModel from "../../../../core/models/user-group/user-group.model";
import { useSelector } from "react-redux";
import { selectUserGroups } from "../../../../core/redux/selectors/user-group/user-group.selector";
import { filterRoleGroups } from "../../../../core/utils/filter-role-groups";

type Props = {
  groupNames: string[];
  onChange?: (value: string[]) => void;
  mode?: string;
  excludeAdminGroups?: boolean;
  disabled?: boolean;
};

function UserGroupSelectComponent({
  groupNames,
  onChange,
  mode,
  excludeAdminGroups,
  disabled: overrideDisabled,
}: Props) {
  const [t] = useTranslation();
  const [deselect] = useState<string[]>([]);
  const groups: UserGroupModel[] = useSelector(selectUserGroups);
  const [disabled, setDisabled] = useState(false);
  const filteredAdminGroups = filterRoleGroups(groups);
  const formattedGroups = excludeAdminGroups ? filteredAdminGroups : groups;

  useEffect(() => {
    if (overrideDisabled) {
      setDisabled(true);
      return;
    }

    if (groups.length !== 1) {
      return;
    }

    if (onChange) onChange([groups[0].title]);

    setDisabled(groups.length === 1);
  }, [groups, overrideDisabled]);

  return (
    <Select
      value={groupNames}
      placeholder={Capitalize(
        t("form.placeholders.select-en_x", {
          x: t("containers.user-groups.key"),
        }),
      )}
      mode={mode && mode === "single" ? undefined : "multiple"}
      onChange={onChange}
      onDeselect={handleOnDeselect}
      onSelect={handleOnSelect}
      listHeight={250}
      style={{ flex: "1" }}
      maxTagCount={1}
      allowClear
      showArrow
      showSearch
      disabled={disabled}
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {formattedGroups.map((group: UserGroupModel, index: number) => (
        <Select.Option key={index} value={group.title}>
          {group.title}
        </Select.Option>
      ))}
    </Select>
  );

  function handleOnDeselect(_, option) {
    if (deselect.includes(option.value)) {
      return;
    }

    deselect.push(option.value);
    StoreUtility.setItem("deselect group", JSON.stringify(deselect));
  }

  function handleOnSelect(_, option) {
    if (!deselect.includes(option.value)) {
      return;
    }

    const index = deselect.indexOf(option.value);
    if (index === -1) {
      return;
    }
    if (index > -1) {
      deselect.splice(index, 1);
    }

    StoreUtility.removeItem("deselect group");
    StoreUtility.setItem("deselect group", JSON.stringify(deselect));
  }
}

export default UserGroupSelectComponent;
