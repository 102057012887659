import React, { useState } from "react";
import { Button, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import CategoriesDetailOverviewScreen from "../../screens/categories/categories-detail-overview.screen";
import CreateCategoryModal from "../../components/modals/create-category.modal";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useGetCategory } from "../../../core/api/primio/primioComponents";

const CategoriesDetailContainer = (props: any) => {
  const { t } = useTranslation();
  const [isModalCreateVisible, setIsModalCreateVisible] =
    useState<boolean>(false);
  const history = useHistory();
  const { data: category, isLoading } = useGetCategory({
    pathParams: { categoryUid: props.match.params.uid },
  });

  if (isLoading && !category) {
    return (
      <PageLayoutDetailComponent
        title={Capitalize(t("translations:common.loading"))}
        goBack={() => history.push(RoutesEnum.CATEGORIES)}
        container={"categories"}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={Capitalize(t("translations:common.loading"))}
        />
      </PageLayoutDetailComponent>
    );
  }

  return (
    <PageLayoutDetailComponent
      title={category ? category.title : "Oops.."}
      goBack={() => history.push(RoutesEnum.CATEGORIES)}
      container={"categories"}
      extra={renderButton()}
    >
      {category ? (
        <CategoriesDetailOverviewScreen category={category} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("errors.general")}
        />
      )}

      <CreateCategoryModal
        category={category}
        visible={isModalCreateVisible}
        onCancel={onHideCreateModal}
      />
    </PageLayoutDetailComponent>
  );

  function onShowCreateModal() {
    setIsModalCreateVisible(true);
  }

  function onHideCreateModal() {
    setIsModalCreateVisible(false);
  }

  function renderButton() {
    return (
      <Button
        type={"primary"}
        onClick={onShowCreateModal}
        icon={<ContainerIconComponent screen={"categories"} />}
      >
        {t("containers.categories.button.detail")}
      </Button>
    );
  }
};

export default CategoriesDetailContainer;
