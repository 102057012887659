import React, { CSSProperties } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useContentCompletionProvider } from "../../../../core/providers/content-completion.provider";
import { useTranslation } from "react-i18next";
import SectionTitleComponent from "../../section-title/section-title.component";
import CollectionStatisticsComponent from "./collection-statistics.component";
import CardOverviewComponent from "./card-overview.component";
import UserModel from "../../../../core/models/user/user.model";
import styles from "../user-detail.module.css";

interface OwnProps {
  user: UserModel;
}

type Props = OwnProps;

const UserContentCompletionDetailsComponent = ({ user }: Props) => {
  const { t } = useTranslation();
  const { selectedCollectionUid } = useContentCompletionProvider();

  const style: CSSProperties = {
    opacity: !selectedCollectionUid ? 0.5 : 1,
  };

  return (
    <div className={styles.completion_details_container}>
      <div style={style} className={styles.completion_details_wrapper}>
        <SectionTitleComponent
          title={`${t("content.collection.key")} ${t(
            "screens.activity.statistics",
          )}`}
        />
        <CollectionStatisticsComponent
          collectionUid={selectedCollectionUid}
          userUid={user.sub}
        />
      </div>

      <div style={style} className={styles.completion_details_wrapper}>
        <SectionTitleComponent
          title={`${t("content.card.key")} ${t("common.overview")}`}
        />
        {selectedCollectionUid && (
          <CardOverviewComponent
            collectionUid={selectedCollectionUid}
            userUid={user.sub}
          />
        )}
      </div>

      {!selectedCollectionUid && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowLeftOutlined style={{ paddingRight: "1rem" }} />
          <Typography.Text>
            {t("screens.user-detail.collection-statistics.empty-state")}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default UserContentCompletionDetailsComponent;
