import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import { CardTypesEnum } from "../../../enums/card-types.enum";
import SliderCardDataModel, {
  SliderCardDataResponse,
} from "../../content/card-types/slider/slider-card-data.model";

export interface CreateSliderCardResponse extends CreateCardInterface {
  data: SliderCardDataResponse;
}

export default class CreateSliderCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.SLIDER;
  data: SliderCardDataModel;

  constructor(createSliderCard: CreateSliderCardResponse) {
    super(createSliderCard);
    this.cardType = CardTypesEnum.SLIDER;
    this.data = new SliderCardDataModel(createSliderCard.data);
  }
}
