import { useQuery } from "@tanstack/react-query";
import { getInvitations } from "./invitation.api";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import InvitationModel from "../../models/invitation/invitation.model";

/**
 * Get user invitations
 * @returns Promise<InvitationModel[]>
 */
export function useInvitations() {
  return useQuery<any, HttpErrorResponseModel, InvitationModel[]>(
    ["invitations"],
    getInvitations,
  );
}
