import React, { useEffect, useState } from "react";

interface LearningPathColorPickerProps {
  value?: string;
  onChange: (color: string) => void;
}

const colorOptions = [
  "#E24333",
  "#F78248",
  "#FFB052",
  "#EA8181",
  "#407A87",
  "#6C938F",
  "#6AADDB",
  "#6D89CF",
  "#875BB2",
];

function LearningPathColorPicker({
  value,
  onChange,
}: LearningPathColorPickerProps) {
  const [color, setColor] = useState("");

  useEffect(() => {
    if (!value) {
      return;
    }
    setColor(value);
  }, [value]);

  const handleOnChange = (color: string) => {
    setColor(color);
    onChange(color);
  };

  return (
    <div style={{ display: "flex" }}>
      {colorOptions.map((colorValue, index) => {
        const isSelected = color === colorValue;
        return (
          <div
            key={index}
            style={{
              cursor: "pointer",
              padding: 2,
              borderRadius: 8,
              marginLeft: index === 0 ? 0 : 8,
              borderColor: isSelected ? "var(--primary-color)" : "transparent",
              borderWidth: 2,
              borderStyle: "solid",
              transition: "all 150ms ease",
            }}
          >
            <div
              onClick={() => handleOnChange(colorValue)}
              style={{
                height: 30,
                width: 30,
                background: colorValue,
                borderRadius: 6,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default LearningPathColorPicker;
