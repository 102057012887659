import React from "react";
import { Divider } from "antd";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import AppMetricsComponent from "../../components/activity/app-metrics.component";
import TopUsersComponent from "../../components/activity/top-users.component";

const ActivityContainer = () => {
  return (
    <PageLayoutComponent showSider={false} menuItems={[]} title={"activity"}>
      <div>
        <AppMetricsComponent />
        <Divider />
        <TopUsersComponent />
      </div>
    </PageLayoutComponent>
  );
};

export default ActivityContainer;
