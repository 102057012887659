import ContentEffect from "./content.effect";
import ContentTypesEnum from "../../../enums/content-types.enum";
import ActionUtility from "../../../utils/action.utils";
import CreateContentType from "../../../types/create-content.type";
import ContentType from "../../../types/content.type";
import { ThunkType } from "../../../types/redux.type";
import { BulkPublishCardsInterface } from "../../../interfaces/bulk-publish-cards.interface";
import { SortContentInterface } from "../../../interfaces/sort-content.interface";
import ContentQueryOptions from "../../../interfaces/content-query.options";
import { ContentStatesEnum } from "../../../enums/content-states.enum";

export default class ContentAction {
  // Get
  static REQUEST_PLAYBOOKS = "ContentAction.REQUEST_PLAYBOOKS";
  static REQUEST_PLAYBOOKS_FINISHED =
    "ContentAction.REQUEST_PLAYBOOKS_FINISHED";

  static REQUEST_CHAPTERS = "ContentAction.REQUEST_CHAPTERS";
  static REQUEST_CHAPTERS_FINISHED = "ContentAction.REQUEST_CHAPTERS_FINISHED";

  static REQUEST_COLLECTIONS = "ContentAction.REQUEST_COLLECTIONS";
  static REQUEST_COLLECTIONS_FINISHED =
    "ContentAction.REQUEST_COLLECTIONS_FINISHED";

  static REQUEST_CARDS = "ContentAction.REQUEST_CARDS";
  static REQUEST_CARDS_FINISHED = "ContentAction.REQUEST_CARDS_FINISHED";

  // Get Detail
  static REQUEST_DETAIL_PLAYBOOKS = "ContentAction.REQUEST_DETAIL_PLAYBOOKS";
  static REQUEST_DETAIL_PLAYBOOKS_FINISHED =
    "ContentAction.REQUEST_DETAIL_PLAYBOOKS_FINISHED";

  static REQUEST_DETAIL_CHAPTERS = "ContentAction.REQUEST_DETAIL_CHAPTERS";
  static REQUEST_DETAIL_CHAPTERS_FINISHED =
    "ContentAction.REQUEST_DETAIL_CHAPTERS_FINISHED";

  static REQUEST_DETAIL_COLLECTIONS =
    "ContentAction.REQUEST_DETAIL_COLLECTIONS";
  static REQUEST_DETAIL_COLLECTIONS_FINISHED =
    "ContentAction.REQUEST_DETAIL_COLLECTIONS_FINISHED";

  static REQUEST_DETAIL_CARDS = "ContentAction.REQUEST_DETAIL_CARDS";
  static REQUEST_DETAIL_CARDS_FINISHED =
    "ContentAction.REQUEST_DETAIL_CARDS_FINISHED";

  // get playbook hierarchy
  static REQUEST_PLAYBOOK_HIERARCHY =
    "ContentAction.REQUEST_PLAYBOOK_HIERARCHY";
  static REQUEST_PLAYBOOK_HIERARCHY_FINISHED =
    "ContentAction.REQUEST_PLAYBOOK_HIERARCHY_FINISHED";

  // Create
  static REQUEST_CREATE_PLAYBOOKS = "ContentAction.REQUEST_CREATE_PLAYBOOKS";
  static REQUEST_CREATE_PLAYBOOKS_FINISHED =
    "ContentAction.REQUEST_CREATE_PLAYBOOKS_FINISHED";

  static REQUEST_CREATE_CHAPTERS = "ContentAction.REQUEST_CREATE_CHAPTERS";
  static REQUEST_CREATE_CHAPTERS_FINISHED =
    "ContentAction.REQUEST_CREATE_CHAPTERS_FINISHED";

  static REQUEST_CREATE_COLLECTIONS =
    "ContentAction.REQUEST_CREATE_COLLECTIONS";
  static REQUEST_CREATE_COLLECTIONS_FINISHED =
    "ContentAction.REQUEST_CREATE_COLLECTIONS_FINISHED";

  static REQUEST_CREATE_CARDS = "ContentAction.REQUEST_CREATE_CARDS";
  static REQUEST_CREATE_CARDS_FINISHED =
    "ContentAction.REQUEST_CREATE_CARDS_FINISHED";

  // Update
  static REQUEST_UPDATE_CONTENT = "ContentAction.REQUEST_UPDATE_CONTENT";
  static REQUEST_UPDATE_CONTENT_FINISHED =
    "ContentAction.REQUEST_UPDATE_CONTENT_FINISHED";

  // Update Bulk Publish Cards
  static REQUEST_BULK_PUBLISH_CARDS =
    "ContentAction.REQUEST_BULK_PUBLISH_CARDS";
  static REQUEST_BULK_PUBLISH_CARDS_FINISHED =
    "ContentAction.REQUEST_BULK_PUBLISH_CARDS_FINISHED";

  // Sort
  static REQUEST_SORT_CONTENT = "ContentAction.REQUEST_SORT_CONTENT";
  static REQUEST_SORT_CONTENT_FINISHED =
    "ContentAction.REQUEST_SORT_CONTENT_FINISHED";
  static SORT_CONTENT = "ContentAction.SORT_CONTENT";

  // Delete
  static REQUEST_DELETE_PLAYBOOKS = "ContentAction.REQUEST_DELETE_PLAYBOOKS";
  static REQUEST_DELETE_PLAYBOOKS_FINISHED =
    "ContentAction.REQUEST_DELETE_PLAYBOOKS_FINISHED";
  static REQUEST_DELETE_CHAPTERS = "ContentAction.REQUEST_DELETE_CHAPTERS";
  static REQUEST_DELETE_CHAPTERS_FINISHED =
    "ContentAction.REQUEST_DELETE_CHAPTERS_FINISHED";
  static REQUEST_DELETE_COLLECTIONS =
    "ContentAction.REQUEST_DELETE_COLLECTIONS";
  static REQUEST_DELETE_COLLECTIONS_FINISHED =
    "ContentAction.REQUEST_DELETE_COLLECTIONS_FINISHED";
  static REQUEST_DELETE_CARDS = "ContentAction.REQUEST_DELETE_CARDS";
  static REQUEST_DELETE_CARDS_FINISHED =
    "ContentAction.REQUEST_DELETE_CARDS_FINISHED";

  // publish
  static REQUEST_PUBLISH_PLAYBOOKS = "ContentAction.REQUEST_PUBLISH_PLAYBOOKS";
  static REQUEST_PUBLISH_PLAYBOOKS_FINISHED =
    "ContentAction.REQUEST_PUBLISH_PLAYBOOKS_FINISHED";
  static REQUEST_PUBLISH_CHAPTERS = "ContentAction.REQUEST_PUBLISH_CHAPTERS";
  static REQUEST_PUBLISH_CHAPTERS_FINISHED =
    "ContentAction.REQUEST_PUBLISH_CHAPTERS_FINISHED";
  static REQUEST_PUBLISH_COLLECTIONS =
    "ContentAction.REQUEST_PUBLISH_COLLECTIONS";
  static REQUEST_PUBLISH_COLLECTIONS_FINISHED =
    "ContentAction.REQUEST_PUBLISH_COLLECTIONS_FINISHED";
  static REQUEST_PUBLISH_CARDS = "ContentAction.REQUEST_PUBLISH_CARDS";
  static REQUEST_PUBLISH_CARDS_FINISHED =
    "ContentAction.REQUEST_PUBLISH_CARDS_FINISHED";

  // Draft
  static REQUEST_DRAFT_PLAYBOOKS = "ContentAction.REQUEST_DRAFT_PLAYBOOKS";
  static REQUEST_DRAFT_PLAYBOOKS_FINISHED =
    "ContentAction.REQUEST_DRAFT_PLAYBOOKS_FINISHED";
  static REQUEST_DRAFT_CHAPTERS = "ContentAction.REQUEST_DRAFT_CHAPTERS";
  static REQUEST_DRAFT_CHAPTERS_FINISHED =
    "ContentAction.REQUEST_DRAFT_CHAPTERS_FINISHED";
  static REQUEST_DRAFT_COLLECTIONS = "ContentAction.REQUEST_DRAFT_COLLECTIONS";
  static REQUEST_DRAFT_COLLECTIONS_FINISHED =
    "ContentAction.REQUEST_DRAFT_COLLECTIONS_FINISHED";
  static REQUEST_DRAFT_CARDS = "ContentAction.REQUEST_DRAFT_CARDS";
  static REQUEST_DRAFT_CARDS_FINISHED =
    "ContentAction.REQUEST_DRAFT_CARDS_FINISHED";

  // Get content via parent
  static REQUEST_CHAPTERS_VIA_PLAYBOOK =
    "ContentAction.REQUEST_CHAPTERS_VIA_PLAYBOOK";
  static REQUEST_CHAPTERS_VIA_PLAYBOOK_FINISHED =
    "ContentAction.REQUEST_CHAPTERS_VIA_PLAYBOOK_FINISHED";
  static REQUEST_COLLECTIONS_VIA_CHAPTER =
    "ContentAction.REQUEST_COLLECTIONS_VIA_CHAPTER";
  static REQUEST_COLLECTIONS_VIA_CHAPTER_FINISHED =
    "ContentAction.REQUEST_COLLECTIONS_VIA_CHAPTER_FINISHED";
  static REQUEST_CARDS_VIA_COLLECTION =
    "ContentAction.REQUEST_CARDS_VIA_COLLECTION";
  static REQUEST_CARDS_VIA_COLLECTION_FINISHED =
    "ContentAction.REQUEST_CARDS_VIA_COLLECTION_FINISHED";

  /**
   * Get all Content
   * @returns Dispatch ContentAction.getContent
   */
  static getAllContent() {
    return (dispatch) => {
      for (const contentTypesEnumKey in ContentTypesEnum) {
        dispatch(
          ContentAction.getContent(ContentTypesEnum[contentTypesEnumKey], {
            excludeMedia: true,
          }),
        );
      }
    };
  }

  static getContent(
    contentType: ContentTypesEnum,
    options?: ContentQueryOptions,
  ): ThunkType<ContentType> {
    return ActionUtility.createThunk(
      ContentAction.getContentActionType(contentType),
      ContentEffect.getContent,
      contentType,
      options,
    );
  }

  /**
   * Get Content Detail
   * @param contentType ContentTypesEnum
   * @param contentUid string
   * @returns Dispatch ContentEffect.getContentDetail
   */
  static getContentDetail(
    contentType: ContentTypesEnum,
    contentUid: string,
  ): ThunkType<ContentType> {
    return ActionUtility.createThunk(
      ContentAction.getContentActionType(contentType, "DETAIL_"),
      ContentEffect.getContentDetail,
      contentType,
      contentUid,
    );
  }

  static getPlaybookHierarchy(playbookUid: string) {
    return ActionUtility.createThunk(
      ContentAction.REQUEST_PLAYBOOK_HIERARCHY,
      ContentEffect.getPlaybookHierarchy,
      playbookUid,
    );
  }

  /**
   * Create Content
   * @param contentType ContentTypesEnum
   * @param data CreateContentType
   * @returns Dispatch ContentEffect.createContent
   */
  static createContent(contentType: ContentTypesEnum, data: CreateContentType) {
    return async (dispatch) => {
      const response = await dispatch(
        ActionUtility.createThunk(
          ContentAction.getContentActionType(contentType, "CREATE_"),
          ContentEffect.createContent,
          contentType,
          data,
        ),
      );

      if (contentType === ContentTypesEnum.PLAYBOOK) {
        dispatch(ContentAction.getPlaybookHierarchy(response.data));
      }

      if (contentType === ContentTypesEnum.CARD) {
        dispatch(ContentAction.getContentDetail(contentType, response.data));
      }
    };
  }

  /**
   * Update Content
   * @param contentType ContentTypesEnum
   * @param previousData ContentType
   * @param data Partial<ContentType>
   * @returns Dispatch ContentEffect.updateContent
   */
  static updateContent(
    contentType: ContentTypesEnum,
    previousData: ContentType,
    data: Partial<ContentType>,
  ) {
    let contentUid: string;

    switch (previousData.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        contentUid = previousData.playbookUid;
        break;
      case ContentTypesEnum.CHAPTER:
        contentUid = previousData.chapterUid;
        break;
      case ContentTypesEnum.COLLECTION:
        contentUid = previousData.collectionUid;
        break;
      case ContentTypesEnum.CARD:
        contentUid = previousData.cardUid;
        break;
    }

    return async (dispatch) => {
      await dispatch(
        ActionUtility.createThunk(
          ContentAction.REQUEST_UPDATE_CONTENT,
          ContentEffect.updateContent,
          contentType,
          contentUid,
          data,
          previousData,
        ),
      );

      if (
        contentType === ContentTypesEnum.CARD &&
        previousData.contentState === ContentStatesEnum.PUBLISHED
      ) {
        // this will create a new card with a new version
        await ContentAction.getSmartContent(dispatch, previousData);
        return;
      }

      // TODO: When we update some specific fields we want to fetch the contentDetail again.
      // e.g.mediaUids, because we actually use the media field but this one can only be properly filled by the API.
      if (
        "mediaUids" in data ||
        "data" in data ||
        contentType === ContentTypesEnum.COLLECTION
      ) {
        dispatch(ContentAction.getContentDetail(contentType, contentUid));
      }
    };
  }

  /**
   * Update AND publish Card
   * @param previousData ContentType
   * @param data Partial<ContentType>
   * @returns Dispatch ContentEffect.updateContent
   */
  static updateAndPublishCard(
    previousData: ContentType,
    data: Partial<ContentType>,
  ) {
    if (previousData.contentType !== ContentTypesEnum.CARD) {
      return;
    }

    return async (dispatch) => {
      await dispatch(
        ActionUtility.createThunk(
          ContentAction.getContentActionType(ContentTypesEnum.CARD, "UPDATE_"),
          ContentEffect.updateCardAndKeepProgress,
          previousData,
          data,
        ),
      );

      dispatch(
        ContentAction.getContentViaParent(
          ContentTypesEnum.CARD,
          previousData.collectionUid,
        ),
      );
    };
  }

  /**
   * Duplicate Content
   * @param content ContentType
   * @returns Dispatch ContentEffect.duplicateContent
   */
  static duplicateContent(content: ContentType) {
    return async (dispatch) => {
      await dispatch(
        ActionUtility.createThunk(
          ContentAction.getContentActionType(content.contentType, "DUPLICATE_"),
          ContentEffect.duplicateContent,
          content,
        ),
      );

      await dispatch(ContentAction.getAllContent());
    };
  }

  /**
   * Delete Content
   * @param content ContentType
   * @returns Dispatch ContentEffect.deleteContent
   */
  static deleteContent(content: ContentType) {
    return async (dispatch) => {
      const version =
        content.contentType === ContentTypesEnum.CARD
          ? content.version
          : undefined;

      await dispatch(
        ActionUtility.createThunk(
          ContentAction.getContentActionType(content.contentType, "DELETE_"),
          ContentEffect.deleteContent,
          content,
          version,
        ),
      );
    };
  }

  /**
   * Publish Content
   * @param content ContentType
   * @returns Dispatch ContentEffect.publishContent
   */
  static publishContent(content: ContentType) {
    return async (dispatch) => {
      await dispatch(
        ActionUtility.createThunk(
          ContentAction.getContentActionType(content.contentType, "PUBLISH_"),
          ContentEffect.publishContent,
          content,
        ),
      );

      await ContentAction.getSmartContent(dispatch, content);
    };
  }

  /**
   * Bulk Publish Cards + Publish Parents (Collection, Chapter and Playbook)
   * @param parentKeys string[]
   * @param cards BulkPublishCardsInterface[]
   * @returns Dispatch ContentEffect.publishContentViaUid + ContentEffect.bulkPublishCards
   */
  static bulkPublishContent(
    parentKeys: string[],
    cards: BulkPublishCardsInterface[],
  ) {
    return async (dispatch) => {
      for (const key of parentKeys) {
        const contentType: ContentTypesEnum = key
          .split("_")[0]
          .toUpperCase() as ContentTypesEnum;
        const contentUid = key.split("_")[1];

        await dispatch(
          ActionUtility.createThunk(
            ContentAction.getContentActionType(contentType, "PUBLISH_"),
            ContentEffect.publishContentViaUid,
            contentType,
            contentUid,
          ),
        );
      }

      if (cards.length > 0) {
        await dispatch(
          ActionUtility.createThunk(
            ContentAction.REQUEST_BULK_PUBLISH_CARDS,
            ContentEffect.bulkPublishCards,
            ContentTypesEnum.CARD,
            cards,
          ),
        );
      }
    };
  }

  /**
   * Draft Content
   * @param content ContentType
   * @returns Dispatch ContentEffect.draftContent
   */
  static draftContent(content: ContentType) {
    return async (dispatch) => {
      await dispatch(
        ActionUtility.createThunk(
          ContentAction.getContentActionType(content.contentType, "DRAFT_"),
          ContentEffect.draftContent,
          content,
        ),
      );
    };
  }

  /**
   * Sort Content
   * @param input
   * @param parentUid
   * @returns Dispatch ContentEffect.sortContent
   */
  static sortContent(
    input: { content: ContentType; data: SortContentInterface }[],
    parentUid: string,
  ) {
    return async (dispatch) => {
      dispatch(ActionUtility.createAction(ContentAction.SORT_CONTENT, input));

      await dispatch(
        ActionUtility.createThunk(
          ContentAction.REQUEST_SORT_CONTENT,
          ContentEffect.sortContent,
          input,
        ),
      );

      const contentType = input[0].content.contentType;
      switch (contentType) {
        case ContentTypesEnum.PLAYBOOK:
          dispatch(ContentAction.getContent(contentType));
          break;
        case ContentTypesEnum.CHAPTER:
        case ContentTypesEnum.COLLECTION:
        case ContentTypesEnum.CARD:
          await dispatch(
            ContentAction.getContentViaParent(contentType, parentUid),
          );
          break;
      }
    };
  }

  private static getContentViaParent(
    contentType:
      | ContentTypesEnum.CHAPTER
      | ContentTypesEnum.COLLECTION
      | ContentTypesEnum.CARD,
    parentUid: string,
  ) {
    let actionType = "";
    let parentContentType = ContentTypesEnum.PLAYBOOK;
    switch (contentType) {
      case ContentTypesEnum.CHAPTER:
        actionType = ContentAction.REQUEST_CHAPTERS_VIA_PLAYBOOK;
        break;
      case ContentTypesEnum.COLLECTION:
        actionType = ContentAction.REQUEST_COLLECTIONS_VIA_CHAPTER;
        parentContentType = ContentTypesEnum.CHAPTER;
        break;
      case ContentTypesEnum.CARD:
        actionType = ContentAction.REQUEST_CARDS_VIA_COLLECTION;
        parentContentType = ContentTypesEnum.COLLECTION;
        break;
    }

    return async (dispatch) => {
      await dispatch(
        ActionUtility.createThunk(
          actionType,
          ContentEffect.getContentDetail,
          parentContentType,
          parentUid,
        ),
      );
    };
  }

  /**
   * Get Content ActionType
   * @param contentType ContentTypesEnum
   * @param actionType string
   * @returns {string} `ContentAction.REQUEST_${actionType}${CONTENT_TYPE()}`
   */
  private static getContentActionType(
    contentType: ContentTypesEnum,
    actionType:
      | ""
      | "DETAIL_"
      | "CREATE_"
      | "UPDATE_"
      | "DUPLICATE_"
      | "DELETE_"
      | "PUBLISH_"
      | "DRAFT_"
      | "SORT_" = "",
  ): string {
    const CONTENT_TYPE = () => {
      switch (contentType) {
        case ContentTypesEnum.PLAYBOOK:
          return "PLAYBOOKS";
        case ContentTypesEnum.CHAPTER:
          return "CHAPTERS";
        case ContentTypesEnum.COLLECTION:
          return "COLLECTIONS";
        case ContentTypesEnum.CARD:
          return "CARDS";
      }
    };

    return `ContentAction.REQUEST_${actionType}${CONTENT_TYPE()}`;
  }

  private static async getSmartContent(dispatch, content: ContentType) {
    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        await dispatch(ContentAction.getContent(content.contentType));
        break;
      case ContentTypesEnum.CHAPTER:
        await dispatch(
          ContentAction.getContentViaParent(
            content.contentType,
            content.playbookUid,
          ),
        );
        break;
      case ContentTypesEnum.COLLECTION:
        await dispatch(
          ContentAction.getContentViaParent(
            content.contentType,
            content.chapterUid,
          ),
        );
        break;
      case ContentTypesEnum.CARD:
        await dispatch(
          ContentAction.getContentViaParent(
            content.contentType,
            content.collectionUid,
          ),
        );
        break;
    }
  }
}
