import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Space, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useAuth } from "../../../core/providers/auth.provider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { Capitalize } from "../../../core/utils/helper.utils";

const LoginFormComponent = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(true);
  const { login, loading } = useAuth();

  const onFinish = () => {
    const _email = email.trim();
    const _password = password.trim();

    login(_email, _password, remember, {
      onFailure: (err) => {
        switch (err.code) {
          case "UserNotConfirmedException":
            // TODO: RN: We need to implement this flow, but it needs investigation on what's the problem and what's needed to solve it. I dont know what UserNotConfirmedException means.
            break;
          case "NotAuthorizedException":
            // Incorrect username or password
            form.setFields([
              {
                name: "email",
                errors: [""],
              },
              {
                name: "password",
                errors: [
                  t("errors.notAuthorizedException", {
                    field: Capitalize(t("form.items.username.label")),
                    item: t("form.items.password.label"),
                  }),
                ],
              },
            ]);
            break;
          case "PasswordResetRequiredException":
            // TODO: Implement reset password flow
            break;
          case "UserNonAdminException":
            form.setFields([
              {
                name: "email",
                errors: [""],
              },
              {
                name: "password",
                errors: [t("errors.status.403")],
              },
            ]);
            break;
        }
      },
    });
  };

  return (
    <Form form={form} initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item name={"email"} rules={getRules("email")} hasFeedback={true}>
        <Input
          value={email}
          type={"text"}
          prefix={<UserOutlined />}
          suffix={
            <Tooltip title={t("form.items.email.tooltip")}>
              <InfoCircleOutlined />
            </Tooltip>
          }
          placeholder={Capitalize(t("form.items.email.label"))}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>

      <Space size={"middle"} direction={"vertical"} style={{ width: "100%" }}>
        <Form.Item
          name={"password"}
          rules={getRules("password")}
          hasFeedback={true}
        >
          <Input.Password
            value={password}
            type={"password"}
            prefix={<LockOutlined />}
            placeholder={Capitalize(t("form.items.password.label"))}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item className={"Space_between"}>
          <Form.Item name={"remember"} valuePropName={"checked"} noStyle>
            <Checkbox
              value={remember}
              className={"Checkbox"}
              onChange={() => setRemember(!remember)}
            >
              {t("form.items.remember-me.label")}
            </Checkbox>
          </Form.Item>
          <Link to={{ pathname: RoutesEnum.FORGET_PASSWORD }}>
            {t("screens.auth.forget-password.title")}
          </Link>
        </Form.Item>
      </Space>

      <Form.Item>
        <Button
          className={"Button"}
          type={"primary"}
          htmlType={"submit"}
          loading={loading}
        >
          {Capitalize(t("common.login"))}
        </Button>
      </Form.Item>
    </Form>
  );

  function getRules(field: string) {
    return [
      {
        required: true,
        message: Capitalize(
          t("errors.required", { item: t(`form.items.${field}.label`) }),
        ),
      },
    ];
  }
};

export default LoginFormComponent;
