import { ContentStatesEnum } from "../../enums/content-states.enum";
import LearningPathModel, {
  LearningPathResponse,
} from "../learning-path/learning-path.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";

export interface BaseContentResponse {
  title: string;
  description: string;
  contentState: ContentStatesEnum;
  sort: number;
  createdAt: string;
  publishedAt?: string;
  apiKeyClientUid?: string;
  learningPaths?: LearningPathResponse[];
}

export default class BaseContentModel {
  title: string;
  description: string;
  contentState: ContentStatesEnum;
  sort: number;
  createdAt: Date;
  publishedAt?: Date;
  apiKeyClientUid?: string;
  learningPaths: LearningPathModel[];

  constructor(content: BaseContentResponse) {
    this.title = content.title;
    this.description = content.description;
    this.contentState = content.contentState;
    this.sort = content.sort;
    this.createdAt = new Date(content.createdAt);
    if (content.learningPaths && content.learningPaths.length !== 0) {
      this.learningPaths = MapResponseToModel(
        content.learningPaths,
        LearningPathModel,
      );
    } else {
      this.learningPaths = [];
    }
    if (content.publishedAt) {
      this.publishedAt = new Date(content.publishedAt);
    }

    this.apiKeyClientUid = content.apiKeyClientUid;
  }
}
