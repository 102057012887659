import { ContentProgressEnum } from "../../../../enums/content-progress.enum";

export interface ContentCompletedUserProgressResponse {
  contentProgress: ContentProgressEnum;
}

export default class ContentCompletedUserProgressModel {
  contentProgress: ContentProgressEnum;
  version: string;

  constructor(version: string, response: ContentCompletedUserProgressResponse) {
    this.contentProgress = response.contentProgress;
    this.version = version;
  }
}
