import BaseCardModel, { BaseCardResponse } from "../../base-card.model";
import { CardTypesEnum } from "../../../../enums/card-types.enum";
import QuizCardDataModel, {
  QuizCardDataResponse,
} from "./quiz-card-data.model";

export interface QuizCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.QUIZ;
  data: QuizCardDataResponse;
}

export default class QuizCardModel extends BaseCardModel {
  cardType: CardTypesEnum.QUIZ;
  data: QuizCardDataModel;

  constructor(quizCard: QuizCardResponse) {
    super(quizCard);
    this.cardType = quizCard.cardType;
    this.data = new QuizCardDataModel(quizCard.data);
  }
}
