import { useQuery } from "@tanstack/react-query";
import {
  getCollectionDetail,
  getPlaybookDetails,
  getPlaybooks,
} from "./content.api";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import PlaybookHierarchyModel from "../../models/content/playbook-hierarchy.model";
import PlaybookModel from "../../models/content/playbook.model";
import { useEffect, useState } from "react";
import CardTypes from "../../types/card.types";
import CollectionHierarchyModel from "../../models/content/collection-hierarchy.model";

export function usePlaybookDetails(playbookUid?: string) {
  return useQuery<any, HttpErrorResponseModel, PlaybookHierarchyModel>(
    ["playbook-details", playbookUid],
    () => {
      if (!playbookUid) {
        return;
      }
      return getPlaybookDetails(playbookUid);
    },
    {
      enabled: !!playbookUid,
    },
  );
}

export function usePlaybooks() {
  return useQuery<any, HttpErrorResponseModel, PlaybookModel[]>(
    ["playbooks"],
    getPlaybooks,
  );
}

export function useCollectionFromPlaybookDetail(
  playbookUid: string,
  collectionUid: string,
) {
  const { data: playbookDetail } = usePlaybookDetails(playbookUid);
  const [collection, setCollection] = useState<CollectionHierarchyModel>();

  useEffect(() => {
    if (!playbookDetail) {
      return setCollection(undefined);
    }

    playbookDetail.chapters.forEach((chapter) => {
      const foundCollection = chapter.collections.find(
        (c) => c.collectionUid === collectionUid,
      );
      if (foundCollection) {
        setCollection(foundCollection);
      }
    });
  }, [playbookDetail, collectionUid]);

  return { data: collection };
}

export function useCardsFromPlaybookDetail(
  playbookUid: string,
  cardUid: string,
) {
  const { data: playbookDetail } = usePlaybookDetails(playbookUid);
  const [cards, setCards] = useState<CardTypes[]>();

  useEffect(() => {
    if (!playbookDetail) {
      return setCards(undefined);
    }

    playbookDetail.chapters.forEach((chapter) => {
      chapter.collections.forEach((collection) => {
        const foundCards = collection.cards.filter(
          (c) => c.cardUid === cardUid,
        );
        if (foundCards.length > 0) {
          setCards(foundCards);
        }
      });
    });
  }, [playbookDetail, cardUid]);

  return { data: cards };
}

export function useCollectionDetail(collectionDetail?: string) {
  return useQuery(
    ["collections", collectionDetail],
    () => getCollectionDetail(collectionDetail ?? ""),
    { enabled: !!collectionDetail },
  );
}
