import React from "react";
import { Form, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import CollectionSummaryModel from "../../../../core/models/content/collection-summary.model";
import TextEditorPreviewComponent from "../../form-builder/components/text-editor-preview.component";
import CardTypes from "../../../../core/types/card.types";

interface Props {
  cardContent: CardTypes | CollectionSummaryModel;
}

const CardBasePreviewComponent = (props: Props) => {
  const [t] = useTranslation();
  const { cardContent } = props;

  return (
    <div className={"Form_item_card"}>
      <Form.Item
        label={
          <Typography.Text strong>
            {Capitalize(t(`form.card.title.label`))}
          </Typography.Text>
        }
      >
        {cardContent.title && (
          <TextEditorPreviewComponent value={cardContent.title} />
        )}
      </Form.Item>

      <Form.Item
        label={
          <Typography.Text strong>
            {Capitalize(t(`form.card.description.label`))}
          </Typography.Text>
        }
      >
        {cardContent.description && (
          <TextEditorPreviewComponent value={cardContent.description} />
        )}
      </Form.Item>
    </div>
  );
};

export default CardBasePreviewComponent;
