import React from "react";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import { useTranslation } from "react-i18next";
import SectionTitleComponent from "../section-title/section-title.component";
import { Card, Col, Row, Tooltip, Typography } from "antd";
import styles from "../content/content.module.css";
import { Capitalize } from "../../../core/utils/helper.utils";
import Moment from "moment";
import encryptCipher from "../../../core/utils/cipher-text";
import { InfoCircleOutlined } from "@ant-design/icons";
import colors from "../../styles/colors";

interface OwnProps {
  apiKey: ApiKeyModel;
}

const SharedContentGeneralInfoComponent = (props: OwnProps) => {
  const [t] = useTranslation();
  const { apiKey } = props;
  return (
    <>
      <SectionTitleComponent title={Capitalize(t("common.general-info"))} />

      <Card className={styles.content} style={{ marginBottom: "2rem" }}>
        <Row style={{ marginBottom: "2rem" }}>
          <Col span={6}>{renderLabel("form.items.created_at.label")}</Col>
          <Col span={18}>
            <Typography.Text>
              {Moment(apiKey.createdAt).format("ddd. D MMM yyyy (LT)")}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={6}>{renderLabel(Capitalize(t("common.updated-at")))}</Col>
          <Col span={18}>
            <Typography.Text>
              {Moment(apiKey.updatedAt).format("ddd. D MMM yyyy (LT)")}
            </Typography.Text>
          </Col>
        </Row>
      </Card>

      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Row>
          <Col span={6}>{renderLabel("Code")}</Col>
          <Col span={18}>
            <Typography.Text>
              {encryptCipher(apiKey.key, apiKey.description)}
            </Typography.Text>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={6}>
            {renderLabel(Capitalize(t("content-state.state")))}
            <Tooltip title={Capitalize(t(renderTooltip()))}>
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
          <Col span={18}>
            <Typography.Text>
              {apiKey.active ? (
                <Typography.Text>
                  {Capitalize(t("common.enabled"))}
                </Typography.Text>
              ) : (
                <Typography.Text style={{ color: colors.secondary }}>
                  {Capitalize(t("common.disabled"))}
                </Typography.Text>
              )}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </>
  );

  function renderLabel(text: string) {
    return (
      <Typography.Text strong style={{ marginRight: "0.8rem" }}>
        {Capitalize(t(text))}
      </Typography.Text>
    );
  }

  function renderTooltip() {
    if (!apiKey.active) {
      return "containers.shared-content.inactive-status-tool-tip";
    }
    return "containers.shared-content.active-status-tool-tip";
  }
};

export default SharedContentGeneralInfoComponent;
