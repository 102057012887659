import * as React from "react";
import { Provider } from "react-redux";
import { ErrorBoundary } from "@sentry/react";
import { Router } from "react-router-dom";
import { AuthProvider } from "./auth.provider";
import { ContentSidebarProvider } from "./content-sidebar.provider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "../redux/stores/root.store";
import { createBrowserHistory } from "history";
import ResetSelectedUserGroup from "../../views/components/utils/reset-selected-user-group";
import { ModalProvider } from "./modal.provider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const history = createBrowserHistory();

function AppProviders(props: { children: JSX.Element }) {
  const onError = (error) => {
    throw new Error(error.message);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store.store}>
        <ErrorBoundary onError={onError}>
          <Router history={history}>
            <AuthProvider>
              <ConfigProvider>
                <ContentSidebarProvider>
                  <ModalProvider>
                    <>
                      {props.children}
                      <ResetSelectedUserGroup />
                    </>
                  </ModalProvider>
                </ContentSidebarProvider>
              </ConfigProvider>
            </AuthProvider>
          </Router>
        </ErrorBoundary>
      </Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}

export default AppProviders;
