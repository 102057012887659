import React from "react";
import { Empty } from "antd";
import { SortContentInterface } from "../../../../core/interfaces/sort-content.interface";
import { useDispatch } from "react-redux";
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import PlaybookDetailModel from "../../../../core/models/content/playbook-detail.model";
import ChapterDetailModel from "../../../../core/models/content/chapter-detail.model";
import CollectionDetailModel from "../../../../core/models/content/collection-detail.model";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import CardTypes from "../../../../core/types/card.types";
import ContentType from "../../../../core/types/content.type";

/* eslint-disable-next-line */
const getListStyle = (isDraggingOver) => ({
  cursor: "move",
  width: 275,
  padding: "2rem 2rem 0 2rem",
  overflow: "hidden",
});

interface Props {
  contentDetails: PlaybookDetailModel[] | CollectionDetailModel[] | undefined;
  children: JSX.Element;
}

const DragDropContextComponent = (props: Props) => {
  const { contentDetails } = props;
  const dispatch = useDispatch();

  if (!contentDetails) {
    return <Empty />;
  }

  const sortContent = (content, dragIndex: number, hoverIndex: number) => {
    const result = [...content];
    const [newOrder] = result.splice(dragIndex, 1);
    result.splice(hoverIndex, 0, newOrder);

    return result;
  };

  function handleSortContent(
    items:
      | PlaybookDetailModel[]
      | ChapterDetailModel[]
      | CollectionDetailModel[]
      | CardTypes[],
  ) {
    const sortingOrder: number[] = items.map((content) => content.sort);

    const input: { content: ContentType; data: SortContentInterface }[] =
      items.map((content, index) => {
        return {
          content,
          data: {
            sort:
              content.contentType === ContentTypesEnum.PLAYBOOK
                ? Math.min(...sortingOrder) + (items.length - index)
                : Math.min(...sortingOrder) + index,
          },
        };
      });

    let parentUid = "";
    switch (items[0].contentType) {
      case ContentTypesEnum.CHAPTER:
        parentUid = items[0].playbookUid;
        break;
      case ContentTypesEnum.COLLECTION:
        parentUid = items[0].chapterUid;
        break;
      case ContentTypesEnum.CARD:
        parentUid = items[0].collectionUid;
        break;
    }

    dispatch(ContentAction.sortContent(input, parentUid));
  }

  function handleOnDragEnd(
    result: DropResult,
    content?: PlaybookDetailModel[] | CollectionDetailModel[],
  ) {
    if (!result.destination || !content) {
      return;
    }

    const dragIndex: number = result.source.index;
    const hoverIndex: number = result.destination.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    if (result.type === "droppableItem") {
      handleSortContent(sortContent(content, dragIndex, hoverIndex));
    } else if (result.type.includes("droppableSubItem")) {
      const parentId: string = result.type.split("droppableSubItem-")[1];

      // @ts-ignore
      const itemSubItemMap = content.reduce((acc, item) => {
        acc[item.getId] = item.children;
        return acc;
      }, {});

      handleSortContent(
        sortContent(itemSubItemMap[parentId], dragIndex, hoverIndex),
      );
    }
  }

  return (
    <DragDropContext onDragEnd={(e) => handleOnDragEnd(e, contentDetails)}>
      <Droppable droppableId={"droppable_parent"} type={"droppableItem"}>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropContextComponent;
