import React, { useEffect, useState } from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";

interface Props {
  videoId: string;
  visible?: boolean;
}

const VimeoMediaComponent = ({ videoId, visible }: Props) => {
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    getThumbnail().then();
  }, [getThumbnail]);

  return (
    <>
      <iframe
        title={title}
        width={180}
        height={100}
        src={`https://player.vimeo.com/video/${videoId}`}
        frameBorder={"0"}
        allowFullScreen
      />
      {visible && title && (
        <div>
          <PaperClipOutlined />
          <Link href={`https://vimeo.com/${videoId}`} target={"_blank"}>
            {title}
          </Link>
        </div>
      )}
    </>
  );

  async function getThumbnail() {
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`,
    );
    const json = await response.json();
    setTitle(json.title);
  }
};

VimeoMediaComponent.defaultProps = {
  visible: true,
};

export default VimeoMediaComponent;
