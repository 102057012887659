import ThunkInterface from "../../../interfaces/thunk.interface";
import ActionInterface from "../../../interfaces/action.interface";
import BaseReducer from "../base/base.reducer";
import { LearningPathTypeEnum } from "../../../enums/learning-path-state.enum";
import LearningPathAction from "./learning-path.action";
import LearningPathModel from "../../../models/learning-path/learning-path.model";
import TimeBasedContentModel from "../../../models/learning-path/time-based-content.model";
import ProgressBasedContentModel from "../../../models/learning-path/progress-based-content.model";
import SortLearningPathInterface from "../../../interfaces/sort-learning-path.interface";

export interface LearningPathReducerInterface {
  learningPaths: LearningPathModel[];
}

export default class LearningPathReducer extends BaseReducer<LearningPathReducerInterface> {
  initialState: LearningPathReducerInterface = {
    learningPaths: [],
  };

  [LearningPathAction.REQUEST_LEARNING_PATHS_FINISHED](
    state: LearningPathReducerInterface,
    action: ThunkInterface<LearningPathModel[]>,
  ) {
    return {
      ...state,
      learningPaths: action.payload,
    };
  }

  [LearningPathAction.REQUEST_LEARNING_PATH_FINISHED](
    state: LearningPathReducerInterface,
    action: ThunkInterface<LearningPathModel>,
  ) {
    action.payload.content.map((content, index) => {
      if (content.learningPathType === LearningPathTypeEnum.TIME_BASED) {
        if (index === 0) content.data.minutesAfterStarted = 0;
        content.sort = index + 1;
      }
    });

    return {
      ...state,
      learningPaths: this.addSingleToArray<LearningPathModel>(
        action.payload,
        [...(state.learningPaths as LearningPathModel[])],
        "learningPathUid",
      ),
    };
  }

  [LearningPathAction.SORT_LEARNING_PATH_CONTENT](
    state: LearningPathReducerInterface,
    action: ActionInterface<{
      content: TimeBasedContentModel[] | ProgressBasedContentModel[];
    }>,
  ) {
    if (!action.payload) {
      return {
        ...state,
      };
    }

    const { content } = action.payload;
    if (!content) {
      return {
        ...state,
      };
    }

    const index = state.learningPaths.findIndex((l) =>
      content.some((c) => l.learningPathUid === c.learningPathUid),
    );
    if (index === -1) {
      // if learningPath does not exist in array
      return {
        ...state,
      };
    }

    const updatedState = state.learningPaths.map((l, key) => {
      if (index !== key) {
        return l;
      }
      return {
        ...l,
        content,
      };
    });

    return {
      ...state,
      learningPaths: updatedState,
    };
  }

  [LearningPathAction.SORT_LEARNING_PATH](
    state: LearningPathReducerInterface,
    action: ThunkInterface<SortLearningPathInterface>,
  ) {
    const newSort = action.payload;

    const learningPaths = [...state.learningPaths];
    learningPaths.forEach((learningPath) => {
      const sort = newSort.learningPaths.find(
        (s) => s.learningPathUid === learningPath.learningPathUid,
      );

      if (!sort) {
        return;
      }

      learningPath.sort = sort.sort;
    });

    learningPaths.sort((a, b) => {
      if (!b.sort || !a.sort) {
        return 0;
      }
      return a.sort - b.sort;
    });

    return {
      ...state,
      learningPaths,
    };
  }
}
