import React, { HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Row, Tag, Typography } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { ContentActionProps } from "../content-action/content-action.component";
import EditableText, {
  EditableTextProps,
} from "../form-builder/components/editable-text";
import ContentExclusiveComponent from "./content-exclusive/content-exclusive.component";
import ContentActionsComponent from "./content-actions.component";
import ContentAction from "../../../core/redux/stores/content/content.action";
import formStyle from "../form-builder/form-builder.module.css";
import styles from "./content.module.css";
import { CalendarOutlined, RocketOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import Moment from "moment";

type Props = {
  title: string;
  description: string;
  titleProps?: Partial<EditableTextProps>;
  descriptionProps?: Partial<EditableTextProps> | any;
  isPublished: boolean;
  categoryLabels?: string[];
  userGroupAcl?: string[];
  createdAt: Date;
  publishedAt?: Date;
  preview?: JSX.Element;
  suffix?: JSX.Element;
  actions?: ContentActionProps[];
} & HTMLAttributes<HTMLDivElement>;

const ContentBoilerplate = (props: Props) => {
  const {
    title,
    description,
    titleProps,
    descriptionProps,
    isPublished,
    categoryLabels,
    userGroupAcl,
    preview,
    suffix,
    actions,
    createdAt,
    publishedAt,
    ...attributes
  } = props;

  const { t } = useTranslation();
  const requesting = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_PLAYBOOKS,
      ContentAction.REQUEST_CHAPTERS,
      ContentAction.REQUEST_COLLECTIONS,
      ContentAction.REQUEST_CARDS,
    ]),
  );

  return (
    <div className={styles.content_container} {...attributes}>
      <Card className={styles.content}>
        <Row align={"middle"}>
          {preview && (
            <>
              <Col>{preview}</Col>
              <Col flex="3rem" />
            </>
          )}
          <Col flex={"auto"}>
            <>
              <EditableText
                {...titleProps}
                isPublished={isPublished}
                value={title}
              >
                {(value) => (
                  <Typography.Title
                    level={3}
                    className={formStyle.editable_title}
                  >
                    {value}
                  </Typography.Title>
                )}
              </EditableText>

              <EditableText
                {...descriptionProps}
                isPublished={isPublished}
                value={description}
              >
                {(value) => (
                  <Typography.Paragraph {...descriptionProps}>
                    {value}
                  </Typography.Paragraph>
                )}
              </EditableText>

              {categoryLabels &&
                categoryLabels.map((label: string, index: number) => (
                  <Tag key={index}>{label}</Tag>
                ))}

              <div style={{ marginTop: 4, opacity: 0.5 }}>
                <div>
                  <CalendarOutlined style={{ marginRight: 4 }} />
                  <Typography.Text>
                    {`${Capitalize(t("common.created-at"))} ${Moment(
                      createdAt,
                    ).format("D MMMM YYYY")}`}
                  </Typography.Text>
                </div>
                {publishedAt && (
                  <div style={{ marginTop: 8 }}>
                    <RocketOutlined style={{ marginRight: 4 }} />
                    <Typography.Text>
                      {`${Capitalize(t("common.published-at"))} ${Moment(
                        publishedAt,
                      ).format("D MMMM YYYY")}`}
                    </Typography.Text>
                  </div>
                )}
              </div>
            </>
          </Col>

          {!requesting && actions && (
            <ContentActionsComponent actions={actions} />
          )}
        </Row>
        <Row>
          <Col>
            {userGroupAcl && userGroupAcl.length > 0 && (
              <ContentExclusiveComponent userGroupAcl={userGroupAcl} />
            )}
          </Col>
        </Row>
      </Card>

      {suffix && suffix}
    </div>
  );
};

export default ContentBoilerplate;
