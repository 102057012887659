import React, { useEffect, useState } from "react";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import { useTranslation } from "react-i18next";
import SectionTitleComponent from "../section-title/section-title.component";
import { ColumnsType } from "antd/es/table";
import { Button, Table, Typography } from "antd";
import PlaybookModel from "../../../core/models/content/playbook.model";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { playbooksSelector } from "../../../core/redux/selectors/content/content.selector";
import Moment from "moment";
import { Capitalize } from "../../../core/utils/helper.utils";
import styles from "../activity/activity.module.css";
import AddContentToLinkModal from "../modals/add-content-to-link.modal";

interface OwnProps {
  apiKey: ApiKeyModel;
}

interface ContentLinkInterface {
  key: string;
  title: string;
  addedAt: Date;
}

const SharedContentContentLinksComponent = (props: OwnProps) => {
  const [t] = useTranslation();
  const allPlaybooks: PlaybookModel[] = useSelector((state: StoreInterface) =>
    playbooksSelector(state),
  );
  const { apiKey } = props;
  const [contentLinks, setContentLinks] = useState<ContentLinkInterface[]>([]);
  const [isAddContentToLinkModalVisible, setIsAddContentToLinkModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const tempContentLinks: ContentLinkInterface[] = [];
    apiKey.content.forEach((c) => {
      tempContentLinks.push({
        key: c.playbookUid,
        title: playbookTitle(c.playbookUid),
        addedAt: c.createdAt,
      });
    });
    setContentLinks(tempContentLinks);
  }, [apiKey, allPlaybooks]);

  const columns: ColumnsType<ContentLinkInterface> = [
    {
      title: Capitalize(t("form.items.content.label")),
      dataIndex: "title",
      key: "title",
      width: "40%",
    },
    {
      title: Capitalize(t("containers.shared-content.added-to-link")),
      dataIndex: "addedAt",
      key: "addedAt",
      width: "30%",
      sorter: (a: ContentLinkInterface, b: ContentLinkInterface) =>
        +new Date(a.addedAt) - +new Date(b.addedAt),
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.addedAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
  ];

  return (
    <div>
      <div className={styles.header}>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <SectionTitleComponent
            title={t("containers.shared-content.content-in-this-link")}
          />
        </div>

        <Button
          style={{ marginTop: "-15px" }}
          type="primary"
          onClick={() => setIsAddContentToLinkModalVisible(true)}
        >
          {t("translations:containers.modules.button.edit-mode")}
        </Button>
      </div>

      <Table<ContentLinkInterface>
        columns={columns}
        dataSource={contentLinks}
        style={{ width: "100%" }}
        pagination={{ pageSize: 5 }}
      />

      <AddContentToLinkModal
        preselectedContent={contentLinks.map((c) => c.key)}
        apiKey={apiKey}
        visible={isAddContentToLinkModalVisible}
        onCancel={onHideAddContentToLinkModal}
      />
    </div>
  );

  function onHideAddContentToLinkModal() {
    setIsAddContentToLinkModalVisible(false);
  }

  function playbookTitle(playbookUid: string) {
    const playbook = allPlaybooks.find((p) => p.playbookUid === playbookUid);

    if (!playbook) return "-";

    return playbook.title;
  }
};

export default SharedContentContentLinksComponent;
