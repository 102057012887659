export const extractUrlKeyValue = (url: string) => {
  const newUrl = url.replace("/edit", "");
  const keyValuePairs: {
    playbook: string | undefined;
    collection: string | undefined;
    chapter: string | undefined;
    card: string | undefined;
  } = {
    playbook: undefined,
    collection: undefined,
    chapter: undefined,
    card: undefined,
  };
  const segments = newUrl.split("/"); // Split URL by '/'

  for (let i = 0; i < segments.length; i += 2) {
    const key = segments[i + 1]; // Key is the segment after '/'
    if (key === undefined) continue;
    // Value is the segment after the key
    keyValuePairs[key] = segments[i + 2];
  }
  return keyValuePairs;
};
