import { combineReducers } from "redux";
import ErrorReducer from "./error/error.reducer";
import RequestingReducer from "./requesting/requesting.reducer";
import ContentReducer, {
  ContentReducerInterface,
} from "./content/content.reducer";
import UserReducer, { UserReducerInterface } from "./user/user.reducer";
import UserGroupReducer, {
  UserGroupReducerInterface,
} from "./user-group/user-group.reducer";
import MediaReducer, { MediaReducerInterface } from "./media/media.reducer";
import SourceReducer, { SourceReducerInterface } from "./source/source.reducer";
import CategoryReducer, {
  CategoryReducerInterface,
} from "./category/category.reducer";
import LearningPathReducer, {
  LearningPathReducerInterface,
} from "./learning-path/learning-path.reducer";
import NotificationReducer, {
  NotificationReducerInterface,
} from "./notification/notification.reducer";
import InvitationReducer, {
  InvitationReducerInterface,
} from "./invitation/invitation.reducer";

export interface StoreInterface {
  error: any;
  requesting: any;
  content: ContentReducerInterface;
  user: UserReducerInterface;
  group: UserGroupReducerInterface;
  media: MediaReducerInterface;
  sources: SourceReducerInterface;
  categories: CategoryReducerInterface;
  learningPath: LearningPathReducerInterface;
  notification: NotificationReducerInterface;
  invitation: InvitationReducerInterface;
}

export default () => {
  const reducerMap = {
    error: ErrorReducer.reducer,
    requesting: RequestingReducer.reducer,
    content: new ContentReducer().reducer,
    user: new UserReducer().reducer,
    group: new UserGroupReducer().reducer,
    media: new MediaReducer().reducer,
    sources: new SourceReducer().reducer,
    categories: new CategoryReducer().reducer,
    learningPath: new LearningPathReducer().reducer,
    notification: new NotificationReducer().reducer,
    invitation: new InvitationReducer().reducer,
  };

  return combineReducers(reducerMap);
};
