import React from "react";
import { useTranslation } from "react-i18next";
import ContentStatisticsComponent from "./app-metrics/content-statistics.component";
import SectionTitleComponent from "../section-title/section-title.component";
import UserStatisticsComponent from "./app-metrics/user-statistics.component";
import styles from "./activity.module.css";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const AppMetricsComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <SectionTitleComponent title={t("common.app-metrics")} />
        <Tooltip title={t("translations:screens.activity.app-metrics.tooltip")}>
          <ExclamationCircleOutlined style={{ marginLeft: 10, marginTop: 5 }} />
        </Tooltip>
      </div>

      <div className={styles.container}>
        <ContentStatisticsComponent />
        <UserStatisticsComponent />
      </div>
    </div>
  );
};

export default AppMetricsComponent;
