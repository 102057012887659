import BaseCardModel, { BaseCardResponse } from "../../base-card.model";
import { CardTypesEnum } from "../../../../enums/card-types.enum";
import SliderCardDataModel, {
  SliderCardDataResponse,
} from "./slider-card-data.model";

export interface SliderCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.SLIDER;
  data: SliderCardDataResponse;
}

export default class SliderCardModel extends BaseCardModel {
  cardType: CardTypesEnum.SLIDER;
  data: SliderCardDataModel;

  constructor(sliderCard: SliderCardResponse) {
    super(sliderCard);
    this.cardType = sliderCard.cardType;
    this.data = new SliderCardDataModel(sliderCard.data);
  }
}
