export default function DecodeMediaUri(path: string) {
  if (path.includes("---")) {
    return decodeURI(path.split("---")[1].split("?")[0]);
  }

  if (path.includes("_%28%E3%83%84%29_")) {
    const fileName = path.split("_%28%E3%83%84%29_")[1].split("?")[0];
    return decodeURI(fileName);
  } else if (path.includes("s3.eu-central-1.amazonaws.com/")) {
    // WN: make sure we include all domain names such as '/dio.content/' or '/thiememeulenhoff.content/' or ...
    const fileName = path
      .split("s3.eu-central-1.amazonaws.com/")[1]
      .split("/")[1]
      .split("?")[0];
    return decodeURI(fileName);
  } else if (path.includes("s3.eu-central-1.amazonaws.com/dio.content/")) {
    const fileName = path
      .split("s3.eu-central-1.amazonaws.com/dio.content/")[1]
      .split("?")[0];
    return decodeURI(fileName);
  } else {
    return decodeURI(path);
  }
}
