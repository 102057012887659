import React from "react";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import CardAnswerSuffixComponent from "./card-answer-suffix.component";
import CardAnswerDetailComponent from "./card-answer-detail.component";
import CardAnswerBoilerplate from "./card-answer.boilerplate";
import CardTypes from "../../../core/types/card.types";

interface OwnProps {
  card: CardTypes;
  expandAll?: string | string[];
  setExpandAll?: (key: string | string[]) => void;
}

type Props = OwnProps;

const CardAnswerComponent = ({ card, expandAll, setExpandAll }: Props) => {
  const title =
    card.cardType === CardTypesEnum.KNOWLEDGE_CARD
      ? card.title
      : card.data.question;

  return (
    <div style={{ paddingBottom: "1.5rem" }}>
      <CardAnswerBoilerplate
        cardUid={card.cardUid}
        title={TranslateDefaults(title)}
        cardType={card.cardType}
        expandAll={expandAll}
        setExpandAll={setExpandAll}
        detail={renderDetail()}
        suffix={<CardAnswerSuffixComponent card={card} />}
      />
    </div>
  );

  function renderDetail() {
    if (
      card.cardType === CardTypesEnum.MULTIPLE_CHOICE ||
      card.cardType === CardTypesEnum.KNOWLEDGE_CARD ||
      card.cardType === CardTypesEnum.QUESTION
    ) {
      return;
    }

    return <CardAnswerDetailComponent card={card} />;
  }
};

export default CardAnswerComponent;
