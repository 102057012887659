import React, { useEffect, useState } from "react";
import { useContentInteractions } from "../../../core/hooks/user-progress/user-progress.hook";
import { selectUserByUids } from "../../../core/redux/selectors/user/user.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Menu, Table, Typography } from "antd";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";
import { TableRowSelection } from "antd/lib/table/interface";
import { useSelector, useStore } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import ContentUserProgressModel from "../../../core/models/user-progress/user-progress/user-progress-types/content-user-progress.model";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import UserModel from "../../../core/models/user/user.model";
import ContentType from "../../../core/types/content.type";
import Moment from "moment";
import ActionButtonComponent from "../../components/actions/action-button.component";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import { useParams } from "react-router-dom";
import { usePlaybookDetails } from "../../../core/hooks/content/content.hook";
import { getAllUserGroupsPerUser } from "../../../core/redux/selectors/user-group/user-group.selector";
import { GroupTags } from "../../components/group-tag";

interface UserContentInteractionInterface {
  userUid: string;
  //
  name: string;
  email: string;
  userGroups: string[];
  firstActiveOn?: Date;
  lastActiveOn?: Date;
}

interface Props {
  userProgress: ContentUserProgressModel[];
  onSelectNotification?: (data: UserModel[]) => void;
  contentProgressState: ContentProgressEnum;
  contentUid: string;
  contentType: ContentTypesEnum;
}

const useContent = (playbookUid: string, contentUid: string) => {
  const { data } = usePlaybookDetails(playbookUid);
  const [content, setContent] = useState<ContentType>();

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.playbookUid === contentUid) {
      setContent(data);
      return;
    }

    data.chapters.forEach((chapter) => {
      if (chapter.chapterUid === contentUid) {
        setContent(chapter);
      }

      chapter.collections.forEach((collection) => {
        if (collection.collectionUid === contentUid) {
          setContent(collection);
        }

        collection.cards.forEach((card) => {
          if (card.cardUid === contentUid) {
            setContent(card);
          }
        });
      });
    });
  }, [data, contentUid]);

  return content;
};

const UserProgressOverviewScreen = (props: Props) => {
  const { t } = useTranslation();
  const { userProgress, onSelectNotification, contentUid } = props;
  const { data: contentInteractions, isLoading } = useContentInteractions(
    props.contentUid,
    props.contentType,
  );
  const state = useStore().getState();
  const params = useParams<{
    playbookUid: string;
    chapterUid?: string;
    collectionUid?: string;
    cardUid?: string;
  }>();
  const content = useContent(params.playbookUid, contentUid);
  //
  const [userModels, setUserModels] = useState<UserModel[]>(
    selectUserByUids(
      state,
      userProgress.map((u) => u.userUid),
    ),
  );

  const usersWithUserGroups: UserModel[] = useSelector(getAllUserGroupsPerUser);

  const [usersContentInteraction, setUsersContentInteraction] = useState<
    UserContentInteractionInterface[]
  >([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState<
    UserContentInteractionInterface[]
  >([]);

  useEffect(() => {
    setSelectedRowKeys("");
    setSelectedRows([]);
    setUserModels(
      selectUserByUids(
        state,
        userProgress.map((u) => u.userUid),
      ),
    );
  }, [userProgress]);

  useEffect(() => {
    if (!userModels || !contentInteractions) {
      return;
    }

    const _usersContentInteraction: UserContentInteractionInterface[] = [];
    userProgress.map((u) => {
      const userModel = usersWithUserGroups.find(
        (user) => user.sub === u.userUid,
      );
      if (userModel) {
        // ContentInteraction can defer from userProgress, (f.e. when user had progress on content before the content was published again)
        // that's why we need to look at the userProgress.
        _usersContentInteraction.push({
          userUid: u.userUid,
          name: userModel.name,
          email: userModel.email,
          userGroups: userModel.userGroups,
          firstActiveOn:
            u.state !== ContentProgressEnum.PENDING
              ? contentInteractions.find((c) => c.userUid === u.userUid)
                  ?.firstActiveOn
              : undefined,
          lastActiveOn:
            u.state === ContentProgressEnum.COMPLETED
              ? contentInteractions.find((c) => c.userUid === u.userUid)
                  ?.lastActiveOn
              : undefined,
        });
      }
    });

    setUsersContentInteraction(_usersContentInteraction);
  }, [userModels, contentInteractions]);

  const columns: ColumnsType<UserContentInteractionInterface> = [
    {
      key: "name",
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "name",
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "name" }),
    },
    {
      key: "email",
      title: Capitalize(t("form.items.email.label")),
      dataIndex: "email",
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "email" }),
    },
    {
      key: "userGroups",
      title: Capitalize(t("containers.user-groups.key_plural")),
      dataIndex: "userGroups",
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "userGroups" }),
      render: (_, data) => <GroupTags groupNames={data.userGroups} />,
    },
    {
      key: "firstActiveOn",
      title: Capitalize(t("common.started-on")),
      dataIndex: "firstActiveOn",
      width: 120,
      sorter: (a, b) => {
        if (!a || !a.firstActiveOn || !b || !b.firstActiveOn) {
          return 0;
        }
        return +new Date(a.firstActiveOn) - +new Date(b.firstActiveOn);
      },
      render: function renderText(_, data) {
        if (!data.firstActiveOn) {
          return <Typography.Text>-</Typography.Text>;
        }
        return (
          <Typography.Text>
            {Moment(data.firstActiveOn).format("YYYY-MM-DD (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      key: "lastActiveOn",
      title: Capitalize(t("form.items.completed-at.label")),
      dataIndex: "lastActiveOn",
      width: 120,
      sorter: (a, b) => {
        if (!a || !a.lastActiveOn || !b || !b.lastActiveOn) {
          return 0;
        }
        return +new Date(a.lastActiveOn) - +new Date(b.lastActiveOn);
      },
      render: function renderText(_, data) {
        if (!data.lastActiveOn) {
          return <Typography.Text>-</Typography.Text>;
        }
        return (
          <Typography.Text>
            {Moment(data.lastActiveOn).format("YYYY-MM-DD (LT)")}
          </Typography.Text>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<UserContentInteractionInterface> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (_r, _s, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const menu = (
    <Menu>
      <Menu.Item
        key={"0"}
        icon={<ContainerIconComponent screen={"notifications"} />}
        onClick={() => handleOnSelectNotification(selectedRows)}
        disabled={selectedRows.length === 0}
      >
        {Capitalize(
          t("screens.notifications.send-to", { value: selectedRows.length }),
        )}
      </Menu.Item>
      <Menu.Item
        key={"1"}
        icon={<ContainerIconComponent screen={"notifications"} />}
        onClick={() => handleOnSelectNotification(usersContentInteraction)}
      >
        {Capitalize(t("screens.notifications.send-to-all")) +
          ` (${usersContentInteraction.length})`}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {content && (
        <Typography.Title level={2}>
          {`${content.title} - ${Capitalize(
            t(`screens.user-progress.state.${props.contentProgressState}`),
          )}`}
        </Typography.Title>
      )}

      <ActionButtonComponent selection={selectedRowKeys} overlay={menu} />

      <Table<UserContentInteractionInterface>
        dataSource={usersContentInteraction}
        columns={columns}
        rowKey={(data) => data.userUid}
        pagination={false}
        loading={isLoading}
        rowSelection={rowSelection}
        tableLayout={"fixed"}
        scroll={{ x: true, y: "50vh" }}
        size={"middle"}
      />
    </>
  );

  function handleOnSelectNotification(data: UserContentInteractionInterface[]) {
    const users: UserModel[] = [];

    data.forEach((u) => {
      const model = userModels.find((user) => user.sub === u.userUid);
      if (model) users.push(model);
    });

    if (onSelectNotification) {
      onSelectNotification(users);
    }
  }
};

export default UserProgressOverviewScreen;
