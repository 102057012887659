import React from "react";
import UserModel from "../../../../core/models/user/user.model";
import { InteractiveCard } from "../../../../core/models/user-progress/interactive-card.model";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { selectUsers } from "../../../../core/redux/selectors/user/user.selector";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PictureQuizCardModel from "../../../../core/models/content/card-types/picture-quiz/picture-quiz-card.model";
import { ColumnsType } from "antd/es/table";
import { Capitalize } from "../../../../core/utils/helper.utils";
import Moment from "moment";
import { Table, Typography } from "antd";
import CardTag from "../../../components/card-answer/tags/card-tag";
import PopoverImage from "../../../components/popover-image/popover-image";

interface StateProps {
  users: UserModel[];
}

interface OwnProps {
  data: InteractiveCard[];
  card: PictureQuizCardModel;
}

type Props = OwnProps & StateProps;

const CardDetailOverviewPictureQuizScreen = ({ data, card, users }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnsType<InteractiveCard> = [
    {
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "userUid",
      render: function renderText(_, data) {
        const user = users.find((u) => u.sub === data.userUid);
        return user ? user.name : "-";
      },
    },
    {
      title: Capitalize(t("common.time-completed")),
      dataIndex: "completedAt",
      sorter: (a, b) => {
        if (!a.completedAt || !b.completedAt) {
          return 0;
        }
        return +new Date(a.completedAt) - +new Date(b.completedAt);
      },
      render: function renderText(_, data) {
        return data.completedAt
          ? Moment(data.completedAt).format("DD/MM/YYYY (hh:mm:ss A)")
          : "-";
      },
    },
    {
      title: Capitalize(t("common.answer")),
      dataIndex: "answer",
      sorter: (a, b) => a.answer - b.answer,
      render: function renderText(_, data) {
        if (data.answer === undefined) {
          return null;
        }
        return (
          <PopoverImage src={card.data.options[data.answer].media?.uri ?? ""}>
            <Typography.Paragraph style={{ margin: 0 }}>{`${Capitalize(
              t("translations:common.picture"),
            )} ${
              card.data.options[data.answer].sort + 1
            }`}</Typography.Paragraph>
          </PopoverImage>
        );
      },
    },
    {
      title: Capitalize(t("common.correct-or-incorrect-label")),
      dataIndex: "answer",
      sorter: (a, b) => a.answer - b.answer,
      render: function renderText(_, data) {
        if (data.answer === undefined) {
          return null;
        }
        return renderAnswerStatus(card.data.options[data.answer].isCorrect);
      },
    },
  ];

  return (
    <Table<InteractiveCard>
      columns={columns}
      dataSource={data}
      rowKey={(data) => data.userUid}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"middle"}
    />
  );

  function renderAnswerStatus(isCorrect: boolean) {
    if (isCorrect) {
      return (
        <CardTag type={"correct"} label={Capitalize(t("common.correct"))} />
      );
    } else {
      return (
        <CardTag type={"incorrect"} label={Capitalize(t("common.incorrect"))} />
      );
    }
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
});

export default connect(mapStateToProps)(CardDetailOverviewPictureQuizScreen);
