import React from "react";
import { Button, Empty, Layout } from "antd";
import { ContentStatesEnum } from "../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import ContainerIconComponent from "../components/icons/container-icon.component";

interface Props {
  contentState?: ContentStatesEnum;
  route?: string;
  onPress?: () => void;
}

const EmptyStateScreen = (props: Props) => {
  const { t } = useTranslation();
  const { contentState, onPress, route } = props;

  return (
    <Layout.Content data-testid="empty-screen" className={"Empty_wrapper"}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={getDescription()}
      >
        {route && onPress && (
          <Button
            ghost
            type={"primary"}
            onClick={onPress}
            icon={<ContainerIconComponent screen={route} />}
          >
            {t(`containers.${route}.button.empty-state`)}
          </Button>
        )}
      </Empty>
    </Layout.Content>
  );

  function getDescription() {
    if (!route) {
      return "Oops..";
    }
    return t(
      `screens.empty-state.${contentState ? contentState : "OVERVIEW"}`,
      { container: t(`containers.${route}.key_plural`) },
    );
  }
};

export default EmptyStateScreen;
