export default function csvFileToObject(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Remove empty rows
  const cleanRows = rows.filter(function (el) {
    return el.length > 0;
  });
  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = cleanRows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      if (header && header !== undefined) {
        if (values[index] && values[index].length > 0) {
          object[header.trim()] = values[index].trim();
          return object;
        } else {
          object[header.trim()] = "";
          return object;
        }
      }
    }, {});
    return el;
  });

  // return the array
  return arr;
}
