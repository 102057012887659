import React, { useEffect, useState } from "react";
import { ContentActionProps } from "../content-action/content-action.component";
import { FileGifOutlined, FileImageOutlined } from "@ant-design/icons";
import { Card, Dropdown, Modal, Typography } from "antd";
import {
  ImageProvidersEnum,
  MediaTypesEnum,
} from "../../../core/enums/media-providers.enum";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styles from "./playbook-preview.module.css";
import colors from "../../styles/colors";
import CreatePlaybookModel, {
  CreatePlaybookResponse,
} from "../../../core/models/create-content/create-playbook.model";
import MediaModel, {
  MediaResponse,
} from "../../../core/models/media/media.model";
import MediaTypesDropdownComponent from "../media-types/media-types-dropdown.component";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import ImageMediaComponent from "../media-types/image-media.component";
import ImageUploadComponent from "../media-upload/image-upload.component";
import PlaybookModel from "../../../core/models/content/playbook.model";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import MediaAction from "../../../core/redux/stores/media/media.action";
import GiphyMediaComponent from "../media-types/giphy-media.component";
import UnsplashSearchModal from "../media-upload/unsplash/unsplash-search.modal";
import GiphySearchModal from "../media-upload/giphy/giphy-search.modal";
import ImportedMedia from "../media-types/imported-media";

interface Props {
  playbook: PlaybookModel;
  hoverable?: boolean;
}

const PlaybookPreviewComponent = ({ playbook, hoverable = true }: Props) => {
  const [t] = useTranslation();
  const { description, title } = playbook;
  const [media, setMedia] = useState<MediaModel | undefined>(
    playbook.media ? playbook.media[0] : undefined,
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!media?.mediaUid) {
      return;
    }
    dispatch(MediaAction.getMedia(media.mediaUid));
  }, []);

  const actions: ContentActionProps[] = [
    {
      icon: <FileImageOutlined />,
      label: Capitalize(t(`content.media-types.IMAGE.S3`)),
      onClick: () => handleSelectMedia(ImageProvidersEnum.S3),
    },
    {
      icon: <FileImageOutlined />,
      label: Capitalize(t(`content.media-types.IMAGE.UNSPLASH`)),
      onClick: () => handleSelectMedia(ImageProvidersEnum.UNSPLASH),
    },
    {
      icon: <FileGifOutlined />,
      label: Capitalize(t(`content.media-types.IMAGE.GIPHY`)),
      onClick: () => handleSelectMedia(ImageProvidersEnum.GIPHY),
    },
    {
      icon: <ContextActionIconComponent action={"delete"} />,
      label: Capitalize(t(`content.media-types.NO_MEDIA.label`)),
      onClick: handleDeleteMedia,
    },
  ];

  const dropdown = (
    <MediaTypesDropdownComponent actions={actions}>
      <ImageUploadComponent
        media={playbook.media ? playbook.media[0] : undefined}
        onUpload={handleCreatePlaybook}
        type={"playbook"}
        aspectRatio={16 / 9}
      />
    </MediaTypesDropdownComponent>
  );

  return (
    <>
      <Card
        className={styles.playbook_card}
        hoverable={hoverable}
        bodyStyle={{ padding: "0", margin: "0" }}
        cover={
          <Dropdown arrow disabled={!hoverable} overlay={dropdown}>
            <div className={styles.playbook_card_wrapper}>
              <div className={styles.playbook_card_image}>
                {playbook.media && (
                  <div style={{ background: "white" }}>
                    {renderMediaComponent(playbook.media[0])}
                  </div>
                )}
              </div>
              <div className={styles.playbook_card_meta}>
                <Typography.Title
                  level={5}
                  style={{ color: "white", margin: 0 }}
                >
                  {TranslateDefaults(title)}
                </Typography.Title>
              </div>
            </div>
          </Dropdown>
        }
      />

      {media && renderMediaUpload(media)}
    </>
  );

  function renderMediaComponent(media: MediaModel) {
    if (!media || media.mediaType !== MediaTypesEnum.IMAGE) {
      return;
    }

    switch (media.provider) {
      case ImageProvidersEnum.S3:
        return (
          <ImageMediaComponent
            path={media.uri}
            visible={false}
            width={"100%"}
            height={"100%"}
          />
        );
      case ImageProvidersEnum.S3X:
        return (
          <ImportedMedia path={media.uri}>
            {(path) => (
              <ImageMediaComponent
                path={path}
                visible={false}
                width={"100%"}
                height={"100%"}
              />
            )}
          </ImportedMedia>
        );
      case ImageProvidersEnum.UNSPLASH:
        return (
          <ImageMediaComponent
            path={`https://images.unsplash.com/${media.uri}`}
            visible={false}
            width={"100%"}
            height={"100%"}
          />
        );
      case ImageProvidersEnum.GIPHY:
        return (
          <GiphyMediaComponent
            videoId={media.uri}
            width={"100%"}
            height={"100%"}
          />
        );
    }
  }

  function renderMediaUpload(media: MediaModel) {
    if (!media || media.provider === ImageProvidersEnum.S3) {
      return null;
    }

    switch (media.provider) {
      case ImageProvidersEnum.UNSPLASH:
        return (
          <UnsplashSearchModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onSubmit={(value) => {
              const urlParts = value.split("/");
              const videoId = urlParts[urlParts.length - 1];
              handleUploadMedia(videoId, ImageProvidersEnum.UNSPLASH);
            }}
          />
        );
      case ImageProvidersEnum.GIPHY:
        return (
          <GiphySearchModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onSubmit={(value) => {
              const urlParts = value.split("/");
              const videoId = urlParts[urlParts.length - 1];
              handleUploadMedia(videoId, ImageProvidersEnum.GIPHY);
            }}
          />
        );
    }
  }

  function handleSelectMedia(provider: ImageProvidersEnum) {
    setIsModalVisible(true);

    setMedia({
      ...media,
      contentType: ContentTypesEnum.PLAYBOOK,
      mediaUid: playbook?.media ? playbook.media[0].mediaUid : undefined,
      provider: provider,
      mediaType: MediaTypesEnum.IMAGE,
      uri: "",
    });
  }

  function handleUploadMedia(uri: string, provider: ImageProvidersEnum) {
    const createMedia: Partial<MediaResponse> = {
      provider: provider,
      mediaType: MediaTypesEnum.IMAGE,
      uri: uri,
    };

    dispatch(MediaAction.createMedia(createMedia)).then((res) =>
      handleCreatePlaybook(res.data),
    );
  }

  function handleCreatePlaybook(mediaUid: string) {
    const createPlaybookImage: CreatePlaybookResponse = {
      title,
      description,
      mediaUids: [mediaUid],
    };

    const CreatePlaybookImage = new CreatePlaybookModel(createPlaybookImage);

    dispatch(
      ContentAction.updateContent(
        ContentTypesEnum.PLAYBOOK,
        playbook,
        CreatePlaybookImage,
      ),
    );
  }

  function handleDeleteMedia() {
    if (!playbook.media) {
      return null;
    }

    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete-from", {
          field: t(`content.media-types.IMAGE.label`),
          item: `“${t(playbook.title)}”`,
        }),
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      okText: Capitalize(t("common.delete")),
      onOk() {
        const removePlaybookImage: CreatePlaybookResponse = {
          title,
          description,
          mediaUids: [],
        };

        const RemovePlaybookImage = new CreatePlaybookModel(
          removePlaybookImage,
        );

        dispatch(
          ContentAction.updateContent(
            ContentTypesEnum.PLAYBOOK,
            playbook,
            RemovePlaybookImage,
          ),
        );
      },
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
    });
  }
};

export default PlaybookPreviewComponent;
