import { CognitoUser, PrimioEntitlement } from "../api/primio/primioSchemas";

export default class EntitlementsService {
  public isEntitled(
    user: CognitoUser,
    requiredEntitlement: PrimioEntitlement,
  ): boolean {
    if (!user.entitlements) return false;

    const applicableEntitlements = user.entitlements.filter(
      (entitlement) => entitlement.subject === requiredEntitlement.subject,
    );

    let isEntitled = false;

    applicableEntitlements.forEach((entitlement) => {
      if (
        entitlement.scope >= requiredEntitlement.scope &&
        entitlement.permission >= requiredEntitlement.permission
      ) {
        isEntitled = true;
      }
    });

    return isEntitled;
  }
}
