import PlaybookModel, { PlaybookResponse } from "./playbook.model";
import ChapterHierarchyModel, {
  ChapterHierarchyResponse,
} from "./chapter-hierarchy.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";

export interface PlaybookHierarchyResponse extends PlaybookResponse {
  chapters: ChapterHierarchyResponse[];
}

export default class PlaybookHierarchyModel extends PlaybookModel {
  chapters: ChapterHierarchyModel[];

  constructor(response: PlaybookHierarchyResponse) {
    super(response);
    this.chapters = MapResponseToModel(
      response.chapters,
      ChapterHierarchyModel,
    );
  }
}
