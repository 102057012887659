import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import { CardTypesEnum } from "../../../enums/card-types.enum";
import { KnowledgeCardData } from "../../content/card-types/knowledge/knowledge-card.model";

export interface CreateKnowledgeCardInterface extends CreateCardInterface {
  data: KnowledgeCardData;
}

export default class CreateKnowledgeCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.KNOWLEDGE_CARD;
  data: KnowledgeCardData;

  constructor(createKnowledgeCard: CreateKnowledgeCardInterface) {
    super(createKnowledgeCard);
    this.cardType = CardTypesEnum.KNOWLEDGE_CARD;
    this.data = createKnowledgeCard.data;
  }
}
