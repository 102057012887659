import React, { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { Capitalize } from "./helper.utils";
import { useTranslation } from "react-i18next";
import TranslateDefaults from "./translate-defaults.utils";
import Highlighter from "react-highlight-words";

type Props = {
  dataIndex: string;
  dataIndexes?: string;
  enableSearch?: boolean;
};

export default function GetColumnSearchProps(props: Props) {
  const { t } = useTranslation();
  const { dataIndex, dataIndexes, enableSearch = true } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const searchInput = useRef<typeof Input | any>(null);

  if (!enableSearch) return null;

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  return {
    filterDropdown: function renderFilterDropDown({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`${Capitalize(t("common.search"))} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              size={"small"}
              type={"primary"}
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              style={{ width: 90 }}
            >
              {Capitalize(t("common.search"))}
            </Button>
            <Button
              size={"small"}
              style={{ width: 90 }}
              onClick={() => {
                handleReset(clearFilters);
              }}
            >
              {Capitalize(t("common.reset"))}
            </Button>
            <Button
              size={"small"}
              type={"link"}
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              {Capitalize(t("common.filter"))}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: function renderFilterIcon(filtered) {
      return (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      );
    },
    onFilter: function renderOnFilter(value, record) {
      if (dataIndexes) {
        const translatedRecord = TranslateDefaults(
          record[dataIndex][dataIndexes],
        );
        return translatedRecord
          ? translatedRecord
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "";
      }
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: function renderOnFilterDropdownVisibleChange(
      visible,
    ) {
      return visible && setTimeout(() => searchInput.current?.select(), 100);
    },
    render: function renderText(text) {
      return searchedColumn === dataIndex ? (
        <Highlighter
          autoEscape
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  };
}
