import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  AudioProvidersEnum,
  EmbedProvidersEnum,
  ImageProvidersEnum,
  MediaTypesEnum,
  VideoProvidersEnum,
} from "../../../core/enums/media-providers.enum";
import ImageMediaComponent from "./image-media.component";
import GiphyMediaComponent from "./giphy-media.component";
import VideoMediaComponent from "./video-media.component";
import VimeoMediaComponent from "./vimeo-media.component";
import YoutubeMediaComponent from "./youtube-media.component";
import EmbedLinkMediaComponent from "./embed/embed-link-media.component";
import EmbedCodeMediaComponent from "./embed/embed-code-media.component";
import MediaAction from "../../../core/redux/stores/media/media.action";
import MediaModel from "../../../core/models/media/media.model";
import AudioMediaComponent from "./audio-media.component";
import ImportedMedia from "./imported-media";

interface Props {
  media: MediaModel;
}

const MediaPreviewComponent = ({ media }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!media.mediaUid) {
      return;
    }

    dispatch(MediaAction.getMedia(media.mediaUid));
  }, []);

  if (media.mediaType === MediaTypesEnum.NO_MEDIA) {
    return null;
  }

  switch (media.mediaType) {
    case MediaTypesEnum.IMAGE:
      switch (media.provider) {
        case ImageProvidersEnum.S3:
          return (
            <ImageMediaComponent
              path={media.uri}
              displayMode={media.displayMode}
            />
          );
        case ImageProvidersEnum.S3X:
          return (
            <ImportedMedia path={media.uri}>
              {(path) => (
                <ImageMediaComponent
                  path={path}
                  displayMode={media.displayMode}
                />
              )}
            </ImportedMedia>
          );
        case ImageProvidersEnum.UNSPLASH:
          return (
            <ImageMediaComponent
              path={`https://images.unsplash.com/${media.uri}`}
              visible={false}
            />
          );
        case ImageProvidersEnum.GIPHY:
          return <GiphyMediaComponent videoId={media.uri} />;
      }
      return null;
    case MediaTypesEnum.VIDEO:
      switch (media.provider) {
        case VideoProvidersEnum.S3:
        case ImageProvidersEnum.S3X:
          return <VideoMediaComponent path={media.uri} />;
        case VideoProvidersEnum.S3X:
          return (
            <ImportedMedia path={media.uri}>
              {(path) => <VideoMediaComponent path={path} />}
            </ImportedMedia>
          );
        case VideoProvidersEnum.VIMEO:
          return <VimeoMediaComponent videoId={media.uri} />;
        case VideoProvidersEnum.YOUTUBE:
          return <YoutubeMediaComponent videoId={media.uri} />;
      }
      return null;
    case MediaTypesEnum.AUDIO:
      switch (media.provider) {
        case AudioProvidersEnum.S3:
          return <AudioMediaComponent path={media.uri} />;
        case AudioProvidersEnum.S3X:
          return (
            <ImportedMedia path={media.uri}>
              {(path) => <AudioMediaComponent path={path} />}
            </ImportedMedia>
          );
      }
      return null;
    case MediaTypesEnum.EMBED:
      switch (media.provider) {
        case EmbedProvidersEnum.LINK:
          return <EmbedLinkMediaComponent link={media.uri} />;
        case EmbedProvidersEnum.CODE:
          return <EmbedCodeMediaComponent link={media.uri} />;
      }
      return null;
  }
};

export default MediaPreviewComponent;
