import React from "react";
import { Typography } from "antd";
import { grey } from "@ant-design/colors";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";

interface OwnProps {
  expand: boolean;
  setExpand: () => void;
  setCollapse: () => void;
}

type Props = OwnProps;

const CollapseButtonComponent = (props: Props) => {
  const { t } = useTranslation();
  const { expand, setExpand, setCollapse } = props;

  return expand ? (
    <Typography.Link style={{ color: grey[2] }} onClick={() => setExpand()}>
      {Capitalize(t("common.expand-all"))}
    </Typography.Link>
  ) : (
    <Typography.Link style={{ color: grey[2] }} onClick={() => setCollapse()}>
      {Capitalize(t("common.collapse-all"))}
    </Typography.Link>
  );
};

export default CollapseButtonComponent;
