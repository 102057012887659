import React from "react";
import { Empty, Image } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import SearchMediaModal from "../../modals/search-media.modal";
import styles from "../../media-types/media.module.css";
import { useMutation } from "@tanstack/react-query";

type Props = {
  visible: boolean;
  onSubmit: (url: string) => void;
  onCancel: () => void;
};

interface UnsplashImage {
  id: string;
  description: string;
  urls: {
    full: string;
    raw: string;
    regular: string;
    small: string;
    small_s3: string;
    thumb: string;
  };
  alt_description: string;
  blur_hash: string;
}

const fetchUnsplash = async (query: string) => {
  const clientId = "gTCLmfXUMiqm4HDS-_ToCVTcF3sSneXp1WRwv7lci6A";
  const url = `https://api.unsplash.com/search/photos?page=1&per_page=5&query=${query}&client_id=${clientId}`;

  const e = await fetch(url);
  const data = await e.json();
  return data.results as UnsplashImage[];
};
const useUnsplash = () => useMutation(["unsplash"], fetchUnsplash);

const UnsplashSearchModal = ({ visible, onSubmit, onCancel }: Props) => {
  const [t] = useTranslation();
  const { mutateAsync: searchUnsplashAsync, data } = useUnsplash();

  return (
    <SearchMediaModal
      title={t("common.picture")}
      visible={visible}
      onSearch={(query: string) => searchUnsplashAsync(query)}
      onCancel={onCancel}
    >
      <div className={styles.search_modal_wrapper}>
        {data && data.length > 0 ? (
          data.map((image, index) => (
            <div key={index} className={styles.search_modal_data_wrapper}>
              <Image
                key={image.id}
                alt={image.alt_description}
                className={styles.search_modal_data}
                width={"100%"}
                src={image.urls.small}
                preview={false}
                onClick={() => {
                  onSubmit(image.urls.regular);
                  onCancel();
                }}
              />
            </div>
          ))
        ) : (
          <div className={styles.unsplash_modal_empty}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={Capitalize(
                t("form.placeholders.use-keywords_x", {
                  item: t("common.picture"),
                }),
              )}
            />
          </div>
        )}
      </div>
    </SearchMediaModal>
  );
};

export default UnsplashSearchModal;
