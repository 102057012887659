import React, { useEffect, useRef, useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { MediaTypesEnum } from "../../../core/enums/media-providers.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Button, message, Modal, Upload } from "antd";
import MediaUploadUtils from "../../../core/utils/media-upload.utils";
import MediaModel from "../../../core/models/media/media.model";
import "@primio-app/pintura/pintura.css";
import locale_nl from "@primio-app/pintura/locale/nl_NL/core/nl_NL";
import plugin_crop_locale_nl from "@primio-app/pintura/locale/nl_NL/crop";
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  CropPresetOption,
  locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  setPlugins,
} from "@primio-app/pintura";
import { PinturaEditor } from "@primio-app/react-pintura";
import { DisplayModeEnum } from "../../../core/enums/display-mode.enum";

setPlugins(plugin_crop);

const CropPresets: CropPresetOption[] = [
  [undefined, "Custom"],
  [16 / 9, "Landscape"],
  [3 / 4, "Portrait"],
];

interface Props {
  media: MediaModel | undefined;
  onUpload: (mediaUid: string, mediaOrientation: string) => void;
  type?: string;
  aspectRatio?: number;
  showCropSelectPresetOptions?: boolean;
}

const ImageUploadComponent = ({
  onUpload,
  type,
  media,
  aspectRatio: predefinedAspectRatio,
  showCropSelectPresetOptions,
}: Props) => {
  const { t, i18n } = useTranslation();

  const [fileList, setFileList] = useState<UploadFile[]>();
  const [aspectRatio, setAspectRatio] = useState<number>();
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState("");

  const editorRef = useRef<PinturaEditor>(null);

  useEffect(() => {
    if (predefinedAspectRatio === undefined) return;
    setAspectRatio(predefinedAspectRatio);
  }, [predefinedAspectRatio]);

  useEffect(() => {
    if (!media) return;

    setResult(media.uri);
    setFileList([
      {
        uid: media.mediaUid ? media.mediaUid : media.uri,
        name: media.uri,
        url: media.uri,
      },
    ]);
  }, [media]);

  const activeLanguage =
    i18n.language === "nl"
      ? {
          ...locale_nl,
          ...plugin_crop_locale_nl,
        }
      : { ...locale_en_gb, ...plugin_crop_locale_en_gb };

  const handleEditorProcess = (res) => {
    const {
      dest,
      imageState: { cropAspectRatio },
    } = res;

    let displayMode = DisplayModeEnum.LANDSCAPE;

    if (cropAspectRatio === undefined) displayMode = DisplayModeEnum.COVER;
    if (cropAspectRatio < 1) displayMode = DisplayModeEnum.PORTRAIT;

    const customFile: RcCustomRequestOptions = {
      file: dest,
      action: "",
      method: "post",
    };

    handleBeforeUpload(dest);
    handleOnUpload(customFile, displayMode);
    setResult(URL.createObjectURL(dest));
  };

  return (
    <>
      <Upload
        className={type === "playbook" ? "Upload" : undefined}
        accept={"image/jpeg, image/jpg, image/png, image/gif"}
        defaultFileList={fileList}
        maxCount={1}
        onChange={handleOnChange}
        showUploadList={false}
        customRequest={handleGIFUpload}
        beforeUpload={handleBeforeUpload}
      >
        {type === "playbook" ? ( // PRIM-441 Render an invisible div that will act as the clickable element
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
        ) : (
          <>
            <Button loading={progress > 0 && progress !== 100}>
              {Capitalize(
                t("common.upload_x", {
                  item: t("common.image"),
                }),
              )}
            </Button>
          </>
        )}
      </Upload>
      {visible && (
        <Modal
          visible={visible}
          width={"80rem"}
          onCancel={() => setVisible(false)}
          onOk={() =>
            editorRef.current?.editor
              .processImage()
              .then(() => setVisible(false))
          }
          okText={"Ok"}
          closable={false}
        >
          <div className="App" style={{ height: "500px", minHeight: "75vh" }}>
            <PinturaEditor
              ref={editorRef}
              utils={["crop"]}
              imageReader={createDefaultImageReader()}
              imageWriter={createDefaultImageWriter()}
              shapePreprocessor={createDefaultShapePreprocessor()}
              locale={activeLanguage}
              src={fileList && fileList[0].originFileObj}
              onProcess={handleEditorProcess}
              enableButtonExport={false}
              cropAutoCenterImageSelectionTimeout={1000}
              imageCropAspectRatio={aspectRatio}
              cropSelectPresetOptions={
                showCropSelectPresetOptions ? CropPresets : undefined
              }
            />
          </div>
        </Modal>
      )}
      {!!result.length && (
        <div style={{ width: "100%", marginTop: "2em" }}>
          <img style={{ width: "50%" }} src={result} alt="result" />
        </div>
      )}
    </>
  );

  function handleOnUpload(
    options: RcCustomRequestOptions,
    displayMode: DisplayModeEnum,
  ) {
    return MediaUploadUtils({
      setProgress,
      options,
      mediaType: MediaTypesEnum.IMAGE,
      displayMode,
    }).then((e: any) => {
      if (e.status === 201) {
        onUpload(e.data, displayMode);
        message.success(Capitalize(t("errors.upload.success")));
      }
    });
  }

  function handleOnChange(info) {
    const { fileList, file } = info;

    if (file.type === "image/gif") return;

    setFileList(fileList);
    setVisible(true);
  }

  function handleGIFUpload(data) {
    const { file } = data;

    if (file.type !== "image/gif") return;

    const customFile: RcCustomRequestOptions = {
      file,
      action: "",
      method: "post",
    };

    setFileList(fileList);
    setResult(URL.createObjectURL(file));
    return handleOnUpload(customFile, DisplayModeEnum.LANDSCAPE);
  }

  function handleBeforeUpload(file) {
    const isAllowedExtention =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isAllowedExtention) {
      message.error(
        Capitalize(t("errors.upload.format", { field: "JPG, PNG, GIF" })),
      );
    }

    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error(
        Capitalize(
          t("errors.upload.size", {
            type: Capitalize(t("content.media-types.IMAGE.label")),
            size: "50MB",
          }),
        ),
      );
    }

    return isAllowedExtention && isLt2M;
  }
};

export default ImageUploadComponent;
