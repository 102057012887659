import React from "react";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { grey } from "@ant-design/colors";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Emitter from "../../../core/utils/emitter.utils";
import styles from "../content-sidebar/content-sidebar.module.css";

interface Props {
  className?: string;
}

const CreateCardButtonComponent = ({ className }: Props) => {
  const [t] = useTranslation();

  const handleShowForm = () => {
    Emitter.emit("handleShowCardTypes", true);
  };

  return className ? (
    <Button
      className={className}
      block
      type={"dashed"}
      onClick={handleShowForm}
    >
      <PlusOutlined />
      {`${Capitalize(t("common.new"))} ${t(`content.card.key`)}`}
    </Button>
  ) : (
    <Button
      block
      type={"text"}
      onClick={handleShowForm}
      className={styles.button_sidebar_wrapper}
      style={{ color: grey[2] }}
    >
      <>
        <PlusOutlined />
        {` ${Capitalize(t("common.new"))} ${t(`content.card.key`)}`}
      </>
    </Button>
  );
};

export default CreateCardButtonComponent;
