import React from "react";
import { Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";

const RowItem = ({
  index,
  name,
  value,
}: {
  index: number;
  name: string;
  value: string | any;
}) => {
  return (
    <tr>
      <td
        style={{
          paddingTop: index !== 0 ? "2rem" : undefined,
          verticalAlign: "top",
        }}
      >
        <Typography.Text strong style={{ paddingRight: "3rem" }}>
          {name}
        </Typography.Text>
      </td>
      <td
        style={{
          paddingTop: index !== 0 ? "2rem" : undefined,
          verticalAlign: "top",
        }}
      >
        {typeof value === "string" ? (
          <Typography.Text>{value}</Typography.Text>
        ) : (
          value
        )}
      </td>
    </tr>
  );
};

const CardDetailBoilerplate = ({ properties }: { properties: any }) => {
  return (
    <table>
      <tbody>
        {Object.entries(properties).map(
          (entry: [string, unknown], index: number) => {
            const key = Capitalize(entry[0]);
            const value = entry[1];

            switch (typeof value) {
              case "boolean":
              case "number":
              case "string":
                return (
                  <RowItem
                    key={index}
                    index={index}
                    name={key}
                    value={value.toString()}
                  />
                );
              case "object":
                return (
                  <RowItem key={index} index={index} name={key} value={value} />
                );
              default:
                return null;
            }
          },
        )}
      </tbody>
    </table>
  );
};

export default CardDetailBoilerplate;
