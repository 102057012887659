import { PlaybooksInCategoryEditInterface } from "../interfaces/category-create.interface";
import CategoryModel from "../models/category/category.model";
import { API_URL } from "../../constants/config/env.config";

const CategoryEndpoints = {
  /**
   * GET all Categories endpoint.
   * @returns API_URL + /categories/
   */
  overview: `${API_URL}/categories/`,

  /**
   * GET one Category endpoint.
   * @param categoryUid string
   * @returns API_URL + /categories/cdb4311c-4fb3-4107-9fb1-30179abcf9f6
   */
  detail(categoryUid: string) {
    return `${API_URL}/categories/${categoryUid}`;
  },

  /**
   * POST Category endpoint.
   * @returns API_URL + /categories/
   */
  create: `${API_URL}/categories/`,

  /**
   * POST Category endpoint.
   * @param category CategoryModel
   * @returns API_URL + /categories/cdb4311c-4fb3-4107-9fb1-30179abcf9f6
   */
  edit(category: CategoryModel) {
    return `${API_URL}/categories/${category.categoryUid}`;
  },

  /**
   * POST Category endpoint.
   * @param data PlaybooksInCategoryEditInterface
   * @returns API_URL + /categories/cdb4311c-4fb3-4107-9fb1-30179abcf9f6/sort-playbooks
   */
  sortPlaybooks(data: PlaybooksInCategoryEditInterface) {
    return `${API_URL}/categories/${data.categoryUid}/sort-playbooks`;
  },

  /**
   * POST Category endpoint.
   * @param data PlaybooksInCategoryEditInterface
   * @returns API_URL + /categories/cdb4311c-4fb3-4107-9fb1-30179abcf9f6/playbooks/add
   */
  addPlaybook(data: PlaybooksInCategoryEditInterface) {
    return `${API_URL}/categories/${data.categoryUid}/playbooks/add`;
  },

  /**
   * POST Category endpoint.
   * @param data PlaybooksInCategoryEditInterface
   * @returns API_URL + /categories/cdb4311c-4fb3-4107-9fb1-30179abcf9f6/playbooks/remove
   */
  removePlaybook(data: PlaybooksInCategoryEditInterface) {
    return `${API_URL}/categories/${data.categoryUid}/playbooks/remove`;
  },

  /**
   * DELETE Category endpoint.
   * @param category CategoryModel
   * @returns API_URL + /categories/cdb4311c-4fb3-4107-9fb1-30179abcf9f6
   */
  delete(category: CategoryModel) {
    return `${API_URL}/categories/${category.categoryUid}`;
  },
};

export default CategoryEndpoints;
