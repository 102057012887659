export interface BaseCompletedUserProgressResponse {
  totalCards: number;
  cardsCompleted: number;
}

export default class BaseCompletedUserProgressModel {
  totalCards: number;
  cardsCompleted: number;

  constructor(response: BaseCompletedUserProgressResponse) {
    this.totalCards = response.totalCards;
    this.cardsCompleted = response.cardsCompleted;
  }
}
