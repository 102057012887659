export interface BaseNotificationCountResponse {
  completed: number;
  scheduled: number;
}

export default class BaseNotificationCountModel {
  completed: number;
  scheduled: number;
  total: number;

  constructor(count: BaseNotificationCountResponse) {
    this.completed = count.completed;
    this.scheduled = count.scheduled;
    this.total = count.completed + count.scheduled;
  }
}
