import { useGetFeatureFlags } from "../api/primio/primioComponents";
import { FeatureFlagModel } from "../api/primio/primioSchemas";

export type FeatureFlag = FeatureFlagModel["name"];

export default function useActiveFeatureFlags() {
  const { data: featureFlags = [] } = useGetFeatureFlags({});
  const enabledFeatures: FeatureFlag[] = [];
  featureFlags.forEach((flag) => {
    if (flag.cms) enabledFeatures.push(flag.name);
  });
  return enabledFeatures;
}
