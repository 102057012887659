import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { ContentProgressEnum } from "../../../../core/enums/content-progress.enum";
import { selectContentACLs } from "../../../../core/redux/selectors/content/content.selector";
import {
  useUserProgress,
  useUsersProgressCollectionDetail,
} from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AssignedStatisticBlockComponent from "../../statistics/assigned-statistic-block.component";
import ProgressMetaIconComponent from "../../icons/progress-meta-icon.component";
import StatisticsBlockComponent from "../../statistics/statistics-block.component";
import SectionTitleComponent from "../../section-title/section-title.component";
import millisToMinutesUtils from "../../../../core/utils/millis-to-minutes.utils";
import UserProgressCollectionDetailModel from "../../../../core/models/user-progress/collection-detail/user-progress-collection-detail.model";
import CollectionModel from "../../../../core/models/content/collection.model";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import Moment from "moment";
import SharedContentStatisticBlockComponent from "../../statistics/shared-content-statistic-block.component";
import { selectUserProgressFromUserGroupUsers } from "../../../../core/redux/selectors/user-progress/user-progress";

interface StateProps {
  userGroupAcl: string[];
}

interface OwnProps {
  collection: CollectionModel;
}

type Props = OwnProps & StateProps;

const CollectionDetailInfoComponent = (props: Props) => {
  const { t } = useTranslation();
  const { collection, userGroupAcl } = props;
  const params = useParams<{
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
  }>();
  const { data: collectionProgress, isFetching: isFetchCollection } =
    useUserProgress(
      params.playbookUid,
      params.chapterUid,
      params.collectionUid,
    );
  const { data } = useUsersProgressCollectionDetail(collection.collectionUid);
  const col = data as UserProgressCollectionDetailModel | undefined;

  const userProgressFromUserGroupUsers =
    selectUserProgressFromUserGroupUsers(collectionProgress);

  const averageTimeSpend = (): number => {
    let spent = 0;

    if (!col) {
      return spent;
    }

    col.cards.forEach((c) => (spent = spent + c.timeSpent));

    const handleUsers =
      userProgressFromUserGroupUsers.length > 0
        ? userProgressFromUserGroupUsers
        : collectionProgress
          ? collectionProgress?.users
          : [];

    const totalUsers = handleUsers?.filter(
      (u) => u.state !== ContentProgressEnum.PENDING,
    ).length;

    return millisToMinutesUtils(spent / totalUsers);
  };

  const style: CSSProperties = {
    color: "var(--primary-color)",
  };

  return (
    <>
      <SectionTitleComponent title={t("common.general-info")} />

      <div style={{ display: "flex" }}>
        <StatisticsBlockComponent
          icon={<ProgressMetaIconComponent icon={"publish"} style={style} />}
          text={"common.published-at"}
          title={Moment(
            col ? col.firstPublishedAt : collection.publishedAt,
          ).format("DD MMM YYYY")}
        />
        <StatisticsBlockComponent
          icon={<ProgressMetaIconComponent icon={"user"} style={style} />}
          text={`${t("common.total") + " " + t("containers.users.key_plural")}`}
          title={(collectionProgress
            ? userProgressFromUserGroupUsers.length > 0
              ? userProgressFromUserGroupUsers.length
              : ~~collectionProgress?.users.length
            : 0
          ).toString()}
          isLoading={isFetchCollection}
        />
        <StatisticsBlockComponent
          icon={<ProgressMetaIconComponent icon={"timeSpent"} style={style} />}
          text={"graphs.users-active.average-timespent"}
          title={`${averageTimeSpend()} min`}
          isLoading={isFetchCollection}
        />
        <AssignedStatisticBlockComponent
          userGroupAcl={userGroupAcl}
          style={style}
        />
        <SharedContentStatisticBlockComponent
          playbookUid={params.playbookUid}
          style={style}
        />
      </div>
    </>
  );
};

const mapStateToProps = (
  state: StoreInterface,
  ownProps: OwnProps,
): StateProps => ({
  userGroupAcl: selectContentACLs(
    state,
    ContentTypesEnum.COLLECTION,
    ownProps.collection.collectionUid,
  ),
});

export default connect(mapStateToProps)(CollectionDetailInfoComponent);
