import React from "react";
import { Divider } from "antd";

const CardDividerComponent = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", zIndex: -1 }}>
      <Divider
        type={"vertical"}
        dashed
        style={{ height: "4rem", borderColor: "black" }}
      />
    </div>
  );
};

export default CardDividerComponent;
