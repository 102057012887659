import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";

interface OwnProps {
  password: string;
  onChange?: (valid: boolean) => void;
}

const PasswordValidationForm = ({ password, onChange }: OwnProps) => {
  const [t] = useTranslation();
  const [passwordUppercase, setPasswordUppercase] = useState<boolean>(false);
  const [passwordLowercase, setPasswordLowercase] = useState<boolean>(false);
  const [passwordNumber, setPasswordNumber] = useState<boolean>(false);
  const [passwordSpecial, setPasswordSpecial] = useState<boolean>(false);
  const [passwordLength, setPasswordLength] = useState<boolean>(false);
  const passwordMinLength = 8;

  useEffect(() => {
    const validUppercase = /[A-Z]/.test(password);
    const validLowercase = /[a-z]/.test(password);
    const validNumber = /[0-9]/.test(password);
    const validSpecial = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}°~]/.test(password);
    const validLength = password.length >= passwordMinLength;

    setPasswordUppercase(validUppercase);
    setPasswordLowercase(validLowercase);
    setPasswordNumber(validNumber);
    setPasswordSpecial(validSpecial);
    setPasswordLength(validLength);

    if (onChange) {
      onChange(
        validUppercase &&
          validLowercase &&
          validNumber &&
          validSpecial &&
          validLength,
      );
    }
  }, [password]);

  const checks = [
    {
      label: t("errors.validation.password.number"),
      value: passwordNumber,
    },
    {
      label: t("errors.validation.password.length", {
        amount: passwordMinLength,
      }),
      value: passwordLength,
    },
    {
      label: t("errors.validation.password.lowercase"),
      value: passwordLowercase,
    },
    {
      label: t("errors.validation.password.special"),
      value: passwordSpecial,
    },
    {
      label: t("errors.validation.password.uppercase"),
      value: passwordUppercase,
    },
  ];

  const leftValidation = checks.filter((p, i) => i % 2 === 0);
  const rightValidation = checks.filter((p, i) => i % 2 !== 0);

  return (
    <Row wrap={false} style={{ paddingBottom: "1rem" }}>
      <Col style={{ width: "50%" }}>
        {leftValidation.map(
          (check: { label: string; value: boolean }, index: number) =>
            renderPasswordValidation(check.label, check.value, index),
        )}
      </Col>
      <Col style={{ width: "50%" }}>
        {rightValidation.map(
          (check: { label: string; value: boolean }, index: number) =>
            renderPasswordValidation(check.label, check.value, index),
        )}
      </Col>
    </Row>
  );

  function renderPasswordValidation(
    label: string,
    value: boolean,
    index: number,
  ) {
    return (
      <div
        key={index}
        style={{ width: "98%", display: "flex", alignItems: "center" }}
      >
        {value ? (
          <CheckCircleFilled
            style={{ color: "var(--disabled-color)" }}
            size={2}
          />
        ) : (
          <CheckCircleOutlined style={{ color: "var(--disabled-color)" }} />
        )}

        <Typography.Text
          ellipsis
          style={{ color: value ? "grey" : "black", marginLeft: "0.3rem" }}
        >
          {label}
        </Typography.Text>
      </div>
    );
  }
};

export default PasswordValidationForm;
