import { ContentProgressEnum } from "../../../../enums/content-progress.enum";
import CollectionUserProgressModel, {
  CollectionUserProgressResponse,
} from "./collection-user-progress.model";
import ContentUserProgressModel from "./content-user-progress.model";

export interface ChapterUserProgressResponse {
  [collectionUid: string]: CollectionUserProgressResponse;
}

export default class ChapterUserProgressModel {
  chapterUid: string;
  collections: CollectionUserProgressModel[];
  users: ContentUserProgressModel[];

  constructor(response: ChapterUserProgressResponse, chapterUid: string) {
    this.chapterUid = chapterUid;
    this.collections = Object.keys(response).map((collectionUid) => {
      return new CollectionUserProgressModel(
        response[collectionUid],
        chapterUid,
        collectionUid,
      );
    });

    this.users = [];

    /* eslint-disable-next-line */
    Object.entries(response).map(([_, collectionUserProgress]) => {
      Object.entries(collectionUserProgress).map(([userUid, state]) => {
        const index = this.users.findIndex((u) => u.userUid === userUid);
        if (index === -1) {
          this.users.push(new ContentUserProgressModel({ userUid, state }));
        } else if (
          this.users[index].state === ContentProgressEnum.COMPLETED &&
          this.users[index].state !== state
        ) {
          this.users[index] = new ContentUserProgressModel({
            userUid,
            state: ContentProgressEnum.STARTED,
          });
        }
      });
    });
  }
}
