import React from "react";
import { AntdIconProps } from "./content-icon.component";
import { RoutesEnum } from "../../../constants/routes/app.route";
import {
  AppstoreOutlined,
  BellOutlined,
  BranchesOutlined,
  QrcodeOutlined,
  RiseOutlined,
  TagsOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";

interface OwnProps {
  screen: string;
}

type Props = OwnProps & AntdIconProps;

const ContainerIconComponent = (props: Props) => {
  const { screen, ...iconProps } = props;

  const style = {
    ...iconProps.style,
    color: iconProps.iconcolor,
  };

  switch (`/${screen}`) {
    case RoutesEnum.PLAYBOOKS:
      return <AppstoreOutlined style={style} {...iconProps} />;
    case RoutesEnum.QR_CODE:
      return <QrcodeOutlined style={style} {...iconProps} />;
    case RoutesEnum.ACTIVITY:
      return <RiseOutlined style={style} {...iconProps} />;
    case RoutesEnum.NOTIFICATIONS:
      return <BellOutlined style={style} {...iconProps} />;
    case RoutesEnum.USERS:
      return <UserOutlined style={style} {...iconProps} />;
    case RoutesEnum.USER_GROUPS:
      return <UsergroupAddOutlined style={style} {...iconProps} />;
    case RoutesEnum.CATEGORIES:
      return <TagsOutlined style={style} {...iconProps} />;
    case RoutesEnum.LEARNING_PATH:
      return <BranchesOutlined style={style} {...iconProps} />;
    case RoutesEnum.INVITATIONS:
      return <UserAddOutlined style={style} {...iconProps} />;
    default:
      return null;
  }
};

export default ContainerIconComponent;
