import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./selection-context-menu.module.css";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";

interface Action {
  key: string;
  label: string | ((selectedRowKeys: React.Key[]) => string);
  icon?: React.ReactNode;
  disabled?: boolean | ((selectedRowKeys: React.Key[]) => boolean);
  onClick: (selectedRowKeys: React.Key[]) => void;
  color?: string;
}

interface SelectionContextMenuProps {
  selectedRowKeys: React.Key[];
  actions: Action[];
  onClearSelection: () => void;
}

const SelectionContextMenu = ({
  selectedRowKeys,
  actions,
  onClearSelection,
}: SelectionContextMenuProps) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(selectedRowKeys.length > 0);
  }, [selectedRowKeys]);

  const closeMenu = async () => {
    setIsVisible(false);
    await new Promise((resolve) => setTimeout(resolve, 250));
    onClearSelection();
  };

  const isDisabled = (action: Action) => {
    if (typeof action.disabled === "function") {
      return action.disabled(selectedRowKeys);
    } else {
      return action.disabled;
    }
  };

  return (
    <div
      style={{
        position: "relative",
        pointerEvents: "none",
        userSelect: "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <div
          className={styles.contentMenuContainer}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? "translateY(-110%)" : "translateY(-200%)",
          }}
        >
          <div
            className={styles.clearSelection}
            style={{ marginRight: "2rem" }}
            onClick={closeMenu}
          >
            <span style={{ marginRight: "0.5rem" }}>
              {Capitalize(
                t("translations:common.selected-items-x", {
                  count: selectedRowKeys.length,
                }),
              )}
            </span>
            <CloseOutlined style={{ fontSize: 12 }} />
          </div>

          {actions.map((action) => (
            <div
              key={action.key}
              onClick={() => {
                action.onClick(selectedRowKeys);
                void closeMenu();
              }}
              className={styles.action}
              style={{
                opacity: isDisabled(action) ? 0.5 : 1,
                pointerEvents: isDisabled(action) ? "none" : "auto",
              }}
            >
              {action.icon}
              <span style={{ marginLeft: "0.5rem", color: action.color }}>
                {action.label instanceof Function
                  ? action.label(selectedRowKeys)
                  : action.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectionContextMenu;
