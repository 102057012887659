import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import { Divider } from "antd";
import FilterCardTypesComponent from "../../actions/filter-card-types.component";
import SectionTitleComponent from "../../section-title/section-title.component";
import CollapseButtonComponent from "../../actions/collapse-button.component";
import CardAnswerComponent from "../../card-answer/card-answer.component";
import CardTypes from "../../../../core/types/card.types";
import styles from "../../activity/activity.module.css";
import CollectionHierarchyModel from "../../../../core/models/content/collection-hierarchy.model";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";

interface Props {
  collection: CollectionHierarchyModel;
}

const CollectionDetailAnswersComponent = ({ collection }: Props) => {
  const { t } = useTranslation();
  const [cards, setCards] = useState(getLatestCards(collection));
  const [filteredCards, setFilteredCards] = useState<CardTypes[]>(cards);
  const [cardTypes, setCardTypes] = useState<CardTypesEnum[]>([]);
  const [expandAll, setExpandAll] = useState<string | string[]>([]);
  const interactiveCards = filteredCards.filter(
    (c) =>
      c.cardType !== CardTypesEnum.MULTIPLE_CHOICE &&
      c.cardType !== CardTypesEnum.KNOWLEDGE_CARD &&
      c.cardType !== CardTypesEnum.QUESTION,
  );

  useEffect(() => {
    setCards(getLatestCards(collection));
  }, [collection]);

  useEffect(() => {
    if (cardTypes.length === 0) {
      setFilteredCards(cards);
    } else {
      setFilteredCards(
        cards.filter((c) => cardTypes.some((type) => c.cardType === type)),
      );
    }
  }, [cardTypes, cards]);

  return (
    <>
      <Divider />

      <SectionTitleComponent
        title={`${t("common.all")} ${t("form.items.content.label")}`}
      />

      <div style={{ width: "75rem" }}>
        <div className={styles.header}>
          <FilterCardTypesComponent
            cards={cards}
            filterCardTypes={setCardTypes}
          />
          <CollapseButtonComponent
            expand={expandAll.length === 0}
            setExpand={() =>
              setExpandAll(interactiveCards.map((c) => c.cardUid))
            }
            setCollapse={() => setExpandAll([])}
          />
        </div>

        {filteredCards.map((card, index) => (
          <CardAnswerComponent
            key={`${card.cardUid}_${index}`}
            card={card}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
          />
        ))}
      </div>
    </>
  );
};

function getLatestCards(collection: CollectionHierarchyModel) {
  const cards = collection.cards;
  if (cards.length === 0) {
    return [];
  }

  const uniqueCards: CardTypes[] = [];
  cards.forEach((card) => {
    const index = uniqueCards.findIndex((c) => c.cardUid === card.cardUid);

    if (index === -1) {
      uniqueCards.push(card);
      return;
    }

    const version = uniqueCards[index].version;
    if (version < card.version) {
      uniqueCards[index] = card;
    }
  });

  return uniqueCards
    .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED)
    .sort((a, b) => a.sort - b.sort);
}

export default CollectionDetailAnswersComponent;
