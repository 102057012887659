import React, { PropsWithChildren } from "react";
import { Breadcrumb, Layout, PageHeader, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";

interface Props {
  title: string;
  container?: string;
  extra?: React.ReactNode;
  goBack?: () => void;
  showSider?: boolean;
}

const PageLayoutDetailComponent = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { container, title, children, extra, goBack, showSider } = props;
  const history = useHistory();

  return (
    <>
      {showSider && (
        <Layout.Sider theme={"light"} width={200} className={"Sider"} />
      )}
      <Layout.Content
        className={
          showSider ? "Content_wrapper" : "Content_wrapper_without_sider"
        }
      >
        <PageHeader
          title={
            <Typography.Title level={2}>
              {TextDecodeUtils(Capitalize(title))}
            </Typography.Title>
          }
          breadcrumb={renderNavigateBack()}
          extra={extra ? extra : null}
        />
        <Layout.Content className={"Content"}>{children}</Layout.Content>
      </Layout.Content>
    </>
  );

  function renderNavigateBack() {
    let text: string;
    if (container) {
      text = Capitalize(
        t("common.back_to", { item: t(`containers.${container}.key_plural`) }),
      );
    } else {
      text = Capitalize(t("common.back"));
    }

    return (
      <Breadcrumb className={"Breadcrumb_detail"}>
        <Breadcrumb.Item>
          <a onClick={goBack ? goBack : () => history.goBack()}>
            <ArrowLeftOutlined /> {text}
          </a>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }
};

export default PageLayoutDetailComponent;
