import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Form, Typography } from "antd";
import { grey } from "@ant-design/colors";
import PlaybooksSelectComponent, {
  SelectedPlaybookUidsType,
} from "./select/playbooks-select.component";

const { Paragraph } = Typography;

export interface AddContentToLinkFormFields {
  content: string[];
  description: string;
}

export interface AddContentToLinkFormRef {
  form: FormInstance<AddContentToLinkFormFields>;
}

interface OwnProps {
  preselectedContent: string[];
}

type Props = OwnProps;

const AddContentToLinkForm = forwardRef(
  (props: Props, ref: Ref<AddContentToLinkFormRef>) => {
    const [t] = useTranslation();
    const { preselectedContent } = props;
    const [form] = useForm<AddContentToLinkFormFields>();
    const [content, setContent] = useState<string[]>(preselectedContent);

    useImperativeHandle(ref, () => ({ form }));

    const handleChange = (value: SelectedPlaybookUidsType[]) => {
      const playbookUids = value.map((p) => p.playbookUid);
      setContent(playbookUids);
      form.setFields([{ name: "content", value: playbookUids }]);
    };

    return (
      <Form form={form} layout="vertical">
        <Paragraph style={{ fontSize: 14 }}>
          {t("containers.shared-content.about-to-add-content")}
        </Paragraph>
        <Form.Item
          initialValue={content}
          name="content"
          rules={getRules()}
          label={
            <Typography.Text
              style={{
                color: grey[6],
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {t("common.pick-your-modules")}
            </Typography.Text>
          }
        >
          <PlaybooksSelectComponent
            playbookUids={content}
            onChange={handleChange}
            filter={(playbook) => playbook.contentState === "PUBLISHED"}
          />
        </Form.Item>
      </Form>
    );

    // Rules
    function getRules() {
      return [
        {
          required: true,
          message: "required",
        },
      ];
    }
  },
);

AddContentToLinkForm.displayName = "AddContentToLinkForm";

export default AddContentToLinkForm;
