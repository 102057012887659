import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetAuthenticatedUser } from "../../../../core/api/primio/primioComponents";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Command } from "../types/command.type";
import {
  AppstoreOutlined,
  BellOutlined,
  BranchesOutlined,
  QrcodeOutlined,
  RiseOutlined,
  TagsOutlined,
  UploadOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import {
  ModalNames,
  useModal,
} from "../../../../core/providers/modal.provider";
import { useAuth } from "../../../../core/providers/auth.provider";

const useCreateCommands = () => {
  const [commands, setCommands] = useState<Command[]>([]);
  const { t } = useTranslation();
  const { push } = useHistory();
  const { openModal } = useModal();
  const { isAuth } = useAuth();
  const { data: user } = useGetAuthenticatedUser({}, { enabled: isAuth });

  useEffect(() => {
    const commands: Command[] = [];

    const navigationCommands: Command[] = [
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.modules.title")),
          }),
        ),
        icon: <AppstoreOutlined />,
        action: () => push(RoutesEnum.PLAYBOOKS),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.learning-path.title")),
          }),
        ),
        icon: <BranchesOutlined />,
        action: () => push(RoutesEnum.LEARNING_PATH),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.categories.title")),
          }),
        ),
        icon: <TagsOutlined />,
        action: () => push(RoutesEnum.CATEGORIES),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.user-groups.title")),
          }),
        ),
        icon: <UsergroupAddOutlined />,
        action: () => push(RoutesEnum.USER_GROUPS),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.users.title")),
          }),
        ),
        icon: <UserOutlined />,
        action: () => push(RoutesEnum.USERS),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.qr.title")),
          }),
        ),
        icon: <QrcodeOutlined />,
        action: () => push(RoutesEnum.QR_CODE),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.notifications.title")),
          }),
        ),
        icon: <BellOutlined />,
        action: () => push(RoutesEnum.NOTIFICATIONS),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.activity.title")),
          }),
        ),
        icon: <RiseOutlined />,
        action: () => push(RoutesEnum.ACTIVITY),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("containers.invitations.title")),
          }),
        ),
        icon: <UserAddOutlined />,
        action: () => push(RoutesEnum.INVITATIONS),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize("edit mode"),
          }),
        ),
        icon: <EditOutlined />,
        action: () => push(RoutesEnum.EDIT_MODE),
        type: "command",
      },
    ];

    if (user) {
      navigationCommands.push({
        title: Capitalize(
          t("translations:common.go-to-x", {
            item: Capitalize(t("translations:screens.user-detail.title")),
          }),
        ),
        icon: <UserOutlined />,
        action: () =>
          push(RoutesEnum.USER_DETAIL.replace(":username", user.username)),
        type: "command",
      });
    }
    commands.push(...navigationCommands);

    const modalCommands: Command[] = [
      {
        title: Capitalize(
          t("translations:common-x.create-new-x", {
            item: t("translations:content.playbook.key"),
          }),
        ),
        icon: <AppstoreOutlined />,
        action: () => openModal(ModalNames.CREATE_PLAYBOOK),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common-x.create-new-x", {
            item: t("translations:containers.categories.key"),
          }),
        ),
        icon: <TagsOutlined />,
        action: () => openModal(ModalNames.CREATE_CATEGORY),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common-x.create-new-x_alt", {
            item: t("translations:containers.learning-path.key"),
          }),
        ),
        icon: <BranchesOutlined />,
        action: () => openModal(ModalNames.CREATE_LEARNING_PATH),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common-x.create-new-x_alt", {
            item: t("translations:containers.qr.key"),
          }),
        ),
        icon: <QrcodeOutlined />,
        action: () => openModal(ModalNames.CREATE_QR),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common-x.create-new-x", {
            item: t("translations:containers.notifications.key"),
          }),
        ),
        icon: <BellOutlined />,
        action: () => openModal(ModalNames.CREATE_NOTIFICATION),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common-x.create-new-x", {
            item: t("translations:containers.user-groups.key"),
          }),
        ),
        icon: <UsergroupAddOutlined />,
        action: () => openModal(ModalNames.CREATE_USER_GROUP),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:common-x.create-new-x", {
            item: t("translations:containers.invitations.key"),
          }),
        ),
        icon: <UserAddOutlined />,
        action: () => openModal(ModalNames.CREATE_INVITATION),
        type: "command",
      },
      {
        title: Capitalize(
          t("translations:containers.invitations.button.import-invitations"),
        ),
        icon: <UploadOutlined />,
        action: () => openModal(ModalNames.IMPORT_INVITATIONS),
        type: "command",
      },
    ];

    commands.push(...modalCommands);

    commands.sort((a, b) => {
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
      return 0;
    });

    setCommands(commands);
  }, [t, user]);

  return commands;
};
export default useCreateCommands;
