import UserGroupModel from "../../../models/user-group/user-group.model";
import UserRoleEnum from "../../../enums/user-role.enum";
import {
  selectCurrentUser,
  selectCurrentUserRole,
} from "../user/user.selector";
import { StoreInterface } from "../../stores/root.reducer";
import { createSelector } from "reselect";
import { ADMIN_GROUP } from "../../../../constants";
import { selectState } from "../core/core.selector";

/**
 * Note: Implicitly implemented this selector differently then the playbooksSelector
 * using createSelector caused a ReferenceError
 * @param state
 */
export const selectUserGroups = (state: StoreInterface) => {
  const user = selectCurrentUser(state);
  const userRole = selectCurrentUserRole(state);

  if (!user || !userRole) {
    return [] as UserGroupModel[];
  }

  switch (userRole) {
    case UserRoleEnum.SYS_ADMIN:
    case UserRoleEnum.PRIMIO_SUPPORT:
    case UserRoleEnum.ADMIN:
      return state.group.groups;
    case UserRoleEnum.GROUP_ADMIN:
      return state.group.groups
        .filter((userGroup) => userGroup.title !== ADMIN_GROUP)
        .filter((userGroup) => user.userGroups.includes(userGroup.title))
        .map((userGroup) => ({
          ...userGroup,
          users: userGroup.users.filter((_user) => _user.sub !== user.sub),
        }));
  }
};

export const selectUserGroupByGroupTitle = createSelector(
  selectUserGroups,
  (state, groupTitle: string | undefined) => groupTitle,
  (userGroups, groupTitle) =>
    userGroups.find((group) => group.title === groupTitle),
);

export const getAllUserGroupsPerUser = createSelector(selectState, (state) => {
  const users = state.user.users;
  const userGroups = state.group.groups;
  for (const user of users) {
    const groupNames: string[] = [];
    userGroups.forEach((group) => {
      group.users.forEach((userItem) => {
        if (userItem.sub === user.sub) groupNames.push(group.title);
      });
    });
    user.userGroups = groupNames;
  }
  return users;
});

export const getAllUserGroupsPerSelectedUser = (
  state: StoreInterface,
  user: any | undefined,
) => {
  const userGroups = state.group.groups;
  const groupNames: string[] = [];
  if (userGroups.length === 0 || !user) return;
  userGroups.forEach((group) => {
    group.users.forEach((userItem) => {
      if (userItem.sub === user.sub) groupNames.push(group.title);
    });
  });
  return { ...user, userGroups: groupNames };
};

export const selectCurrentlySelectedUserGroup = createSelector(
  selectState,
  (state) => state.group.selectedGroup,
);

export const selectUserGroupFromLearningPath = (
  contentUserGroups: [] | undefined,
) => {
  const newArr: string[] = [];
  contentUserGroups?.map((group: { playbookUid: string; userGroupAcl: [] }) => {
    const { userGroupAcl } = group;
    if (group.userGroupAcl.length === 0) return;
    userGroupAcl.map((item) => {
      if (newArr.includes(item)) return;
      newArr.push(item);
    });
  });
  return newArr;
};
