import ContentTypesEnum from "../enums/content-types.enum";

const getChildContentType = (parentContentType: ContentTypesEnum) => {
  switch (parentContentType) {
    case ContentTypesEnum.PLAYBOOK:
      return ContentTypesEnum.CHAPTER;
    case ContentTypesEnum.CHAPTER:
      return ContentTypesEnum.COLLECTION;
    case ContentTypesEnum.COLLECTION:
      return ContentTypesEnum.CARD;
    case ContentTypesEnum.CARD:
      return;
  }
};
export default getChildContentType;
