import React, { useCallback, useState } from "react";
import { useLearningPath } from "../../../core/providers/learning-path.provider";
import PlaybooksSelectComponent, {
  SelectedPlaybookUidsType,
} from "../forms/select/playbooks-select.component";
import { Button } from "antd";
import { Playbook } from "../../../core/api/primio/primioSchemas";

const LearningPathAddContent = () => {
  const [showAddContentForm, setShowAddContentForm] = useState(false);
  const { addContent, learningPath } = useLearningPath();

  const canAddModuleToPath = (moduleGroups: string[], pathGroups: string[]) => {
    const isSubset = moduleGroups.every((group) => pathGroups.includes(group));
    if (pathGroups.length === 0) return true;
    if (isSubset)
      return (
        moduleGroups.length < pathGroups.length ||
        moduleGroups.length === pathGroups.length
      );
    return false;
  };

  const filterPlaybooks = useCallback(
    (playbook: Playbook) => {
      // Filter out playbooks that are added to this LearningPath already
      const index = learningPath.content.findIndex(
        (c) => c.contentUid === playbook.playbookUid,
      );
      if (index !== -1) {
        return false;
      }

      // Filter out deleted and draft playbooks
      if (playbook.contentState !== "PUBLISHED") {
        return false;
      }

      // Filter out playbooks that do not include the learningPaths userGroup
      if (learningPath.userGroups && learningPath.userGroups.length > 0) {
        return canAddModuleToPath(
          learningPath.userGroups,
          playbook.userGroupAcl,
        );
      }

      // Filter out all playbooks that have userGroups
      return playbook.userGroupAcl.length === 0;
    },
    [learningPath],
  );

  async function handleSubmit(value: SelectedPlaybookUidsType[]) {
    await addContent(value.map((p) => p.playbookUid));

    setShowAddContentForm(false);
  }

  if (learningPath.state === "POSTED") {
    return null;
  }

  return (
    <>
      <Button type="primary" onClick={() => setShowAddContentForm(true)}>
        Add content
      </Button>
      {showAddContentForm && (
        <div
          style={{ pointerEvents: "none", position: "absolute", opacity: 0 }}
        >
          <PlaybooksSelectComponent
            playbookUids={[]}
            onChange={handleSubmit}
            filter={filterPlaybooks}
            openDrawerImmediately
            onClose={() => setShowAddContentForm(false)}
          />
        </div>
      )}
    </>
  );
};

export default LearningPathAddContent;
