import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getTrackPlaybooks,
  getTrackPlaybooksMutation,
} from "./track-playbook.api";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import TrackPlaybookModel from "../../models/track-playbook/track-playbook.model";

/**
 * Get tracked playbooks
 * @returns Promise<TrackPlaybookModel>
 */
export function useTrackPlaybooks() {
  return useQuery<any, HttpErrorResponseModel, TrackPlaybookModel>(
    ["trackPlaybooks"],
    getTrackPlaybooks,
  );
}

/**
 * Post, update and remove playbookUids
 * @returns -
 */
export function useTrackPlaybooksMutation() {
  return useMutation<any, HttpErrorResponseModel, any>(
    ["trackPlaybooksMutation"],
    (playbookUids: string[]) => getTrackPlaybooksMutation(playbookUids),
  );
}
