import React from "react";
import { Form, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import CardBasePreviewComponent from "../base-card/card-base-preview.component";
import KnowledgeCardModel from "../../../../core/models/content/card-types/knowledge/knowledge-card.model";
import MediaPreviewComponent from "../../media-types/media-preview.component";
import SourcePreviewComponent from "../../source/source-preview.component";
import styles from "../card-types.module.css";

interface OwnProps {
  cardContent: KnowledgeCardModel;
}

type Props = OwnProps;

const KnowledgeCardPreviewComponent = (props: Props) => {
  const [t] = useTranslation();
  const { cardContent } = props;

  return (
    <Form
      labelCol={{ span: 6 }}
      layout={"horizontal"}
      labelAlign={"left"}
      className={styles.form_wrapper_horizontal}
    >
      <CardBasePreviewComponent cardContent={cardContent} />

      <div className={"Form_item_card"}>
        {cardContent.media && cardContent.media[0] && (
          <Form.Item
            label={
              <Typography.Text strong>
                {Capitalize(t(`form.card.media.label`))}
              </Typography.Text>
            }
          >
            <MediaPreviewComponent media={cardContent.media[0]} />
          </Form.Item>
        )}

        {cardContent.sources && (
          <SourcePreviewComponent sources={cardContent.sources} />
        )}
      </div>
    </Form>
  );
};

export default KnowledgeCardPreviewComponent;
