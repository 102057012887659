import { Layout } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutesEnum } from "../../../constants/routes/app.route";
import PlaybooksContainer from "../../containers/playbooks/playbooks.container";
import CategoriesContainer from "../../containers/categories/categories.container";
import CategoriesDetailContainer from "../../containers/categories/categories-detail.container";
import EditModeContainer from "../../containers/edit-mode.container";
import LearningPathContainer from "../../containers/learning-path/learning-path.container";
import LearningPathDetailLegacyContainer from "../../containers/learning-path/learning-path-detail-legacy.container";
import QrCodeContainer from "../../containers/qr-code/qr-code.container";
import QrCodeDetailContainer from "../../containers/qr-code/qr-code-detail.container";
import ActivityContainer from "../../containers/activity/activity.container";
import PublishedContentContainer from "../../containers/user-progress/published-content.container";
import UserProgressContainer from "../../containers/user-progress/user-progress.container";
import UserActivityContainer from "../../containers/user-progress/user-activity.container";
import ModuleDetailContainer from "../../containers/user-progress/module-detail.container";
import CollectionDetailContainer from "../../containers/user-progress/collection-detail.container";
import CardDetailContainer from "../../containers/user-progress/card-detail.container";
import UsersContainer from "../../containers/users/users.container";
import UserDetailContainer from "../../containers/users/user-detail.container";
import UserGroupsContainer from "../../containers/user-group/user-groups.container";
import UserGroupDetailContainer from "../../containers/user-group/user-group-detail.container";
import InvitationsContainer from "../../containers/invitations/invitations.container";
import NotificationsContainer from "../../containers/notifications/notifications.container";
import NotificationsDetailContainer from "../../containers/notifications/notifications-detail.container";
import SharedContentContainer from "../../containers/shared-content/shared-content.container";
import SharedContentClientContainer from "../../containers/shared-content-client/shared-content-client.container";
import SharedContentDetailContainer from "../../containers/shared-content/shared-content-detail.container";
import ImportContainer from "../../containers/import-export/import.container";
import ExportContainer from "../../containers/import-export/export.container";
import NotFoundScreen from "../../screens/not-found.screen";
import React from "react";
import GuardedRoute from "../guarded-route/guarded-route";
import FeatureFlagsContainer from "../../containers/feature-flags/feature-flags.container";
import LearningPathDetailContainer from "../../containers/learning-path/learning-path-detail.container";
import LearningPathDetailReportContainer from "../../containers/learning-path/learning-path-detail-report.container";

const Body = () => {
  return (
    <Layout.Content style={{ marginTop: 64 }}>
      <Switch>
        <GuardedRoute path={RoutesEnum.HOME} exact>
          <Redirect to={RoutesEnum.PLAYBOOKS} />
        </GuardedRoute>
        <GuardedRoute
          path={RoutesEnum.PLAYBOOKS}
          component={PlaybooksContainer}
          exact
        />

        <GuardedRoute
          exact
          path={RoutesEnum.CATEGORIES}
          component={CategoriesContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.CATEGORIES_DETAIL}
          component={CategoriesDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.EDIT_MODE}
          component={EditModeContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.EDIT_MODE_PLAYBOOK}
          component={EditModeContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.EDIT_MODE_CHAPTER}
          component={EditModeContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.EDIT_MODE_COLLECTION}
          component={EditModeContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.EDIT_MODE_CARD}
          component={EditModeContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.LEARNING_PATH}
          component={LearningPathContainer}
          featureFlag={"learning-path"}
        />
        <GuardedRoute
          path={RoutesEnum.LEARNING_PATH_DETAIL_REPORT}
          component={LearningPathDetailReportContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.LEARNING_PATH_DETAIL_LEGACY}
          component={LearningPathDetailLegacyContainer}
        />
        <GuardedRoute
          path={RoutesEnum.LEARNING_PATH_DETAIL}
          component={LearningPathDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.QR_CODE}
          component={QrCodeContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.QR_CODE_DETAIL}
          component={QrCodeDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.ACTIVITY}
          component={ActivityContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.PUBLISHED_CONTENT}
          component={PublishedContentContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS}
          component={PublishedContentContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_PLAYBOOK}
          component={UserProgressContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_CHAPTER}
          component={UserProgressContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_COLLECTION}
          component={UserProgressContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.USER_ACTIVITY}
          component={UserActivityContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.MODULE_DETAIL}
          component={ModuleDetailContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.CHAPTER_DETAIL}
          component={ModuleDetailContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.COLLECTION_DETAIL}
          component={CollectionDetailContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.CARD_DETAIL}
          component={CardDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.USERS}
          component={UsersContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.USER_DETAIL}
          component={UserDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.USER_GROUPS}
          component={UserGroupsContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.USER_GROUP_DETAILS}
          component={UserGroupDetailContainer}
        />

        <GuardedRoute
          path={RoutesEnum.INVITATIONS}
          component={InvitationsContainer}
          featureFlag={"invitation"}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.NOTIFICATIONS}
          component={NotificationsContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.NOTIFICATIONS_DETAIL}
          component={NotificationsDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.SHARED_CONTENT}
          component={SharedContentContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.SHARED_CONTENT_CLIENT}
          component={SharedContentClientContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.SHARED_CONTENT_DETAIL}
          component={SharedContentDetailContainer}
        />

        <GuardedRoute
          exact
          path={RoutesEnum.IMPORT_PLAYBOOKS}
          component={ImportContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.EXPORT_PLAYBOOKS}
          component={ExportContainer}
        />
        <GuardedRoute
          exact
          path={RoutesEnum.FEATURE_FLAGS}
          component={FeatureFlagsContainer}
        />

        <Route path={"*"}>
          <NotFoundScreen />
        </Route>
      </Switch>
    </Layout.Content>
  );
};

export default Body;
