import MultipleChoiceOptionModel, {
  MultipleChoiceOptionResponse,
} from "./multiple-choice-option.model";
import { MapResponseToModel } from "../../../../utils/map-response-to-model.utils";

export interface MultipleChoiceCardDataResponse {
  options: MultipleChoiceOptionResponse[];
  question: string;
}

export default class MultipleChoiceCardDataModel {
  options: MultipleChoiceOptionModel[];
  question: string;

  constructor(multipleChoiceCard: MultipleChoiceCardDataResponse) {
    this.options = MapResponseToModel(
      multipleChoiceCard.options,
      MultipleChoiceOptionModel,
    );
    this.question = multipleChoiceCard.question;
  }
}
