export interface UserResponse {
  username: string;
  createdAt: string;
  sub: string;
  email: string;
  name?: string;
  expoTokens: string[];
  emailVerified: boolean;
  status: string;
  invitee: boolean;
  lastModifiedAt: string;
  lastActiveAt?: string;
}

export default class UserModel {
  username: string;
  created_at: Date;
  sub: string;
  email: string;
  name: string;
  expo_tokens: string[];
  emailVerified: boolean;
  user_status: string;
  invitee: boolean;
  last_modified_at: Date;
  last_active_at?: Date;
  userGroups: string[] = [];

  constructor(user: UserResponse) {
    this.username = user.username;
    this.created_at = new Date(user.createdAt);
    this.sub = user.sub;
    this.email = user.email;
    this.name = user.name ? user.name : user.username;
    this.expo_tokens = user.expoTokens;
    this.emailVerified = user.emailVerified;
    this.user_status = user.status;
    this.invitee = user.invitee;
    this.last_modified_at = new Date(user.lastModifiedAt);
    if (user.lastActiveAt) {
      this.last_active_at = new Date(user.lastActiveAt);
    }
  }
}
