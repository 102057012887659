import React, { useEffect, useState } from "react";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";
import { CreateOptionInterface } from "../option-card/create-option.form";
import { useDispatch } from "react-redux";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import { MediaTypesEnum } from "../../../../core/enums/media-providers.enum";
import { disableButton } from "../../../../core/utils/media-disable-button.utils";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import CreateQuizCardModel, {
  CreateQuizCardResponse,
} from "../../../../core/models/create-content/card-types/create-quiz-card.model";
import QuizCardModel from "../../../../core/models/content/card-types/quiz/quiz-card.model";
import CreateOptionContainerComponent from "../option-card/create-option-container.component";
import QuizOptionModel from "../../../../core/models/content/card-types/quiz/quiz-option.model";
import FormQuestionField from "../../form-builder/components/form-question.field";
import MediaModel from "../../../../core/models/media/media.model";
import MediaUploadSelectComponent from "../../media-upload/media-upload-select.component";
import appConfig from "../../../../constants/config/app.config";

type CreateProps = {
  cardContent: undefined;
  collectionUid: string;
};

type EditProps = {
  cardContent: QuizCardModel;
};

type Props = CreateProps | EditProps;

const CreateQuizCardForm = (props: Props) => {
  const { cardContent } = props;
  const [media, setMedia] = useState<MediaModel | undefined>(
    cardContent?.media ? cardContent.media[0] : undefined,
  );
  const [question, setQuestion] = useState<string>(
    cardContent ? TranslateDefaults(cardContent.data.question) : "",
  );
  const [options, setOptions] = useState<QuizOptionModel[]>(
    cardContent ? cardContent.data.options : [],
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const isCreating = props.cardContent === undefined;
  const isEditing = !isCreating;

  const maxOptions =
    options.length >=
    (media?.mediaUid && media.mediaUid.length > 0
      ? appConfig.quizWithMediaMaxOptions
      : appConfig.quizMaxOptions);
  const minOptions = options.length > 2;

  const handleSetMedia = (media: MediaModel) => {
    if (
      options.length > appConfig.quizWithMediaMaxOptions &&
      media?.mediaUid &&
      media?.mediaUid.length > 0
    ) {
      const newOptions = cardContent?.data.options ?? [];
      if (newOptions && newOptions?.length > appConfig.quizWithMediaMaxOptions)
        newOptions?.pop();
      setOptions(newOptions);
    }
    setMedia(media);
  };

  useEffect(() => {
    const isValid = options.map((item) => item.content.length === 0);
    setIsDisabled(isValid.includes(true));
  }, [options]);

  function handleOnFinish(
    data: CreateCardInterface,
    isPressedButton?: ContentButtonStatesEnum,
  ) {
    const createQuizCard: CreateQuizCardResponse = {
      ...data,
      title: question,
      description:
        media && media.mediaType !== MediaTypesEnum.NO_MEDIA
          ? ""
          : data.description,
      collectionUid: props.cardContent
        ? props.cardContent.collectionUid
        : props.collectionUid,
      cardType: CardTypesEnum.QUIZ,
      mediaUids: media?.mediaUid ? [media.mediaUid] : data.mediaUids,
      data: {
        question,
        options,
      },
    };

    const CreateQuizCard = new CreateQuizCardModel(createQuizCard);

    if (isCreating) {
      dispatch(
        ContentAction.createContent(ContentTypesEnum.CARD, CreateQuizCard),
      );
    }

    if (isEditing && cardContent) {
      if (
        isPressedButton &&
        cardContent.contentState === ContentStatesEnum.PUBLISHED
      ) {
        switch (isPressedButton) {
          case ContentButtonStatesEnum.CREATE:
            dispatch(
              ContentAction.updateContent(
                ContentTypesEnum.CARD,
                cardContent,
                CreateQuizCard,
              ),
            );
            break;
          case ContentButtonStatesEnum.UPDATE:
            cardContent.version++;
            dispatch(
              ContentAction.updateAndPublishCard(cardContent, CreateQuizCard),
            );
            break;
        }
      } else {
        dispatch(
          ContentAction.updateContent(
            ContentTypesEnum.CARD,
            cardContent,
            CreateQuizCard,
          ),
        );
      }
    }
  }

  return (
    <CreateCardBaseForm
      cardContent={cardContent}
      disabledButton={!question || isDisabled || disableButton(media)}
      disabledFields={!!(media && media.mediaUid)}
      hideFields={true}
      onSubmit={handleOnFinish}
    >
      <>
        <MediaUploadSelectComponent
          media={media}
          onChange={handleSetMedia}
          contentType={ContentTypesEnum.CARD}
        />

        <FormQuestionField question={question} setQuestion={setQuestion} />

        {options && (
          <CreateOptionContainerComponent
            options={options as QuizOptionModel[]}
            maxOptions={maxOptions}
            minOptions={minOptions}
            editOption={handleOnChangeOption}
            addOption={handleOnAddOption}
            deleteOption={() => {
              setOptions([...options] as QuizOptionModel[]);
            }}
          />
        )}
      </>
    </CreateCardBaseForm>
  );

  function handleOnAddOption() {
    if (maxOptions) {
      return;
    }

    const newOption = new QuizOptionModel({
      content: "",
      isCorrect: false,
      sort: 0,
    });

    setOptions([...options, newOption] as QuizOptionModel[]);
  }

  function handleOnChangeOption(index: number, value: CreateOptionInterface) {
    const newOptions: QuizOptionModel[] = [...options] as QuizOptionModel[];

    newOptions[index].content = value.content;
    if (value.isCorrect !== undefined) {
      newOptions[index].isCorrect = value.isCorrect;
    }

    setOptions([...newOptions] as QuizOptionModel[]);
  }
};

export default CreateQuizCardForm;
