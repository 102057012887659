import { Dispatch } from "redux";
import { ThunkType } from "../../../types/redux.type";
import { CreateLearningPathInterface } from "../../../interfaces/create-learning-path.interface";
import LearningPathModel, {
  LearningPathResponse,
} from "../../../models/learning-path/learning-path.model";
import ActionUtility from "../../../utils/action.utils";
import LearningPathEffect from "./learning-path.effect";
import SortLearningPathInterface from "../../../interfaces/sort-learning-path.interface";

export default class LearningPathAction {
  // Get all Learning Paths
  static REQUEST_LEARNING_PATHS = "LearningPathAction.REQUEST_LEARNING_PATHS";
  static REQUEST_LEARNING_PATHS_FINISHED =
    "LearningPathAction.REQUEST_LEARNING_PATHS_FINISHED";

  // Get one Learning Path
  static REQUEST_LEARNING_PATH = "LearningPathAction.REQUEST_LEARNING_PATH";
  static REQUEST_LEARNING_PATH_FINISHED =
    "LearningPathAction.REQUEST_LEARNING_PATH_FINISHED";

  // Create one Learning Path
  static REQUEST_CREATE_LEARNING_PATH =
    "LearningPathAction.REQUEST_CREATE_LEARNING_PATH";

  // Edit one Learning Path
  static REQUEST_EDIT_LEARNING_PATH =
    "LearningPathAction.REQUEST_EDIT_LEARNING_PATH";

  // Sort Learning Paths
  static SORT_LEARNING_PATH = "LearningPathAction.SORT_LEARNING_PATH";
  static REQUEST_SORT_LEARNING_PATH =
    "LearningPathAction.REQUEST_SORT_LEARNING_PATH";
  static REQUEST_SORT_LEARNING_PATH_FINISHED =
    "LearningPathAction.REQUEST_SORT_LEARNING_PATH_FINISHED";

  // Update the LearningPath content sort
  static SORT_LEARNING_PATH_CONTENT =
    "LearningPathAction.SORT_LEARNING_PATH_CONTENT";

  // Post one Learning Path
  static REQUEST_POST_LEARNING_PATH =
    "LearningPathAction.REQUEST_POST_LEARNING_PATH";

  // Revoke one Learning Path
  static REQUEST_REVOKE_LEARNING_PATH =
    "LearningPathAction.REQUEST_REVOKE_LEARNING_PATH";

  // Delete one Learning Path
  static REQUEST_DELETE_LEARNING_PATH =
    "LearningPathAction.REQUEST_DELETE_LEARNING_PATH";

  // Get Content Learning Path
  static REQUEST_CONTENT_LEARNING_PATH =
    "LearningPathAction.REQUEST_CONTENT_LEARNING_PATH";

  /**
   * Get all Learning Paths
   * @returns Dispatch LearningPathEffect.getAllLearningPaths
   */
  static getAllLearningPaths(): ThunkType<LearningPathModel[]> {
    return ActionUtility.createThunk<LearningPathModel[]>(
      LearningPathAction.REQUEST_LEARNING_PATHS,
      LearningPathEffect.getAllLearningPaths,
    );
  }

  /**
   * Get one Learning Path
   * @param learningPathUid string
   * @returns Dispatch LearningPathEffect.getLearningPath
   */
  static getLearningPath(
    learningPathUid: string,
  ): ThunkType<LearningPathModel> {
    return ActionUtility.createThunk<LearningPathModel>(
      LearningPathAction.REQUEST_LEARNING_PATH,
      LearningPathEffect.getLearningPath,
      learningPathUid,
    );
  }

  /**
   * Create Learning Path
   * @param data CreateLearningPathInterface
   * @returns Dispatch LearningPathEffect.createLearningPath
   */
  static createLearningPath(data: CreateLearningPathInterface) {
    return async (dispatch) => {
      const response = await dispatch(
        ActionUtility.createThunk(
          LearningPathAction.REQUEST_CREATE_LEARNING_PATH,
          LearningPathEffect.createLearningPath,
          data,
        ),
      );

      if (!response || !response.data) {
        throw new Error(`${response.status} ${response.message}`);
      }

      await dispatch(LearningPathAction.getLearningPath(response.data));
    };
  }

  /**
   * Edit Learning Path
   * @param learningPath LearningPathModel
   * @param data Partial<LearningPathResponse>
   * @returns Dispatch LearningPathEffect.editLearningPath
   */
  static editLearningPath(
    learningPath: LearningPathModel,
    data: Partial<LearningPathResponse>,
  ) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<LearningPathModel>(
          LearningPathAction.REQUEST_EDIT_LEARNING_PATH,
          LearningPathEffect.editLearningPath,
          learningPath,
          data,
        ),
      );

      await dispatch(
        LearningPathAction.getLearningPath(learningPath.learningPathUid),
      );
    };
  }

  static sortLearningPath(data: SortLearningPathInterface) {
    return async (dispatch: Dispatch<any>) => {
      dispatch(
        ActionUtility.createAction(LearningPathAction.SORT_LEARNING_PATH, data),
      );

      await dispatch(
        ActionUtility.createThunk(
          LearningPathAction.REQUEST_SORT_LEARNING_PATH,
          LearningPathEffect.sortLearningPath,
          data,
        ),
      );

      await dispatch(LearningPathAction.getAllLearningPaths());
    };
  }

  /**
   * Sort inside a Learning Path
   * @param learningPath LearningPathModel
   * @param data Partial<LearningPathResponse>
   * @returns Dispatch LearningPathEffect.editLearningPath
   */
  static sortLearningPathContent(
    learningPath: LearningPathModel,
    data: Partial<LearningPathResponse>,
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch(
        ActionUtility.createAction(
          LearningPathAction.SORT_LEARNING_PATH_CONTENT,
          {
            content: data.content,
          },
        ),
      );

      await dispatch(
        ActionUtility.createThunk<LearningPathModel>(
          LearningPathAction.REQUEST_EDIT_LEARNING_PATH,
          LearningPathEffect.editLearningPath,
          learningPath,
          data,
        ),
      );

      await dispatch(
        LearningPathAction.getLearningPath(learningPath.learningPathUid),
      );
    };
  }

  /**
   * Post Learning Path
   * @param learningPath LearningPathModel
   * @returns Dispatch LearningPathEffect.postLearningPath
   */
  static postLearningPath(learningPath: LearningPathModel) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<LearningPathModel>(
          LearningPathAction.REQUEST_POST_LEARNING_PATH,
          LearningPathEffect.postLearningPath,
          learningPath,
        ),
      );

      await dispatch(
        LearningPathAction.getLearningPath(learningPath.learningPathUid),
      );
    };
  }

  /**
   * Revoke Learning Path
   * @param learningPath LearningPathModel
   * @returns Dispatch LearningPathEffect.revokeLearningPath
   */
  static revokeLearningPath(learningPath: LearningPathModel) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<LearningPathModel>(
          LearningPathAction.REQUEST_REVOKE_LEARNING_PATH,
          LearningPathEffect.revokeLearningPath,
          learningPath,
        ),
      );

      await dispatch(LearningPathAction.getAllLearningPaths());
    };
  }

  /**
   * Delete Learning Path
   * @param learningPath
   */
  static deleteLearningPath(learningPath: LearningPathModel) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk(
          LearningPathAction.REQUEST_DELETE_LEARNING_PATH,
          LearningPathEffect.deleteLearningPath,
          learningPath,
        ),
      );

      await dispatch(LearningPathAction.getAllLearningPaths());
    };
  }
}
