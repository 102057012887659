import BaseCompletedUserProgressModel, {
  BaseCompletedUserProgressResponse,
} from "./base-completed-user-progress.model";
import CollectionCompletedUserProgressModel from "./collection-completed-user-progress.model";

export interface ChapterCompletedUserProgressResponse
  extends BaseCompletedUserProgressResponse {
  collections: CollectionCompletedUserProgressModel[];
}

export default class ChapterCompletedUserProgressModel extends BaseCompletedUserProgressModel {
  chapterUid: string;
  collections: CollectionCompletedUserProgressModel[];

  constructor(
    chapterUid: string,
    response: ChapterCompletedUserProgressResponse,
  ) {
    super(response);
    this.chapterUid = chapterUid;

    if (response.collections) {
      this.collections = Object.entries(response.collections).map(
        ([collectionUid, value]) => {
          return new CollectionCompletedUserProgressModel(collectionUid, value);
        },
      );
    } else {
      this.collections = [];
    }
  }
}
