import React from "react";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Avatar, Col, Image, Progress, Row, Typography } from "antd";
import { GradientColors_GraphPink } from "../../styles/colors";
import {
  CardTypesColorEnum,
  CardTypesEnum,
} from "../../../core/enums/card-types.enum";
import { useUsersProgressCollectionDetail } from "../../../core/hooks/user-progress/user-progress.hook";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import CardTag from "./tags/card-tag";
import IndexAnswerModel from "../../../core/models/user-progress/collection-detail/answer-types/index-answer.model";
import UserProgressCardDetailModel from "../../../core/models/user-progress/collection-detail/user-progress-card-detail.model";
import CardTypes from "../../../core/types/card.types";
import graphStyles from "../graphs/graph-container/graph-container.module.css";
import styles from "./card-answer.module.css";
import { CheckOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import { selectUserProgressFromUserGroupUsers } from "../../../core/redux/selectors/user-progress/user-progress";

interface OwnProps {
  card: CardTypes;
}

type Props = OwnProps;

const CardAnswerDetailComponent = ({ card }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
    cardUid: string;
  }>();

  const { data } = useUsersProgressCollectionDetail(
    card.collectionUid,
    card.cardUid,
  );
  const collectionDetail = data as UserProgressCardDetailModel | undefined;

  const color = CardTypesColorEnum[card.cardType][0].color;
  const backgroundColor = CardTypesColorEnum[card.cardType][1].background;

  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );

  const filteredUserAnswer = selectUserProgressFromUserGroupUsers(data);

  const renderOptionText = (index: number) => {
    return (
      <Typography.Text type={"secondary"} className={styles.option_text}>
        {`${t("common.option")} ${index}`}
      </Typography.Text>
    );
  };

  const getAnswerAmount = (index: number) => {
    let answerAmount = 0;

    if (filteredUserAnswer && currentlySelectedUserGroup != "") {
      filteredUserAnswer
        .map((user) => user.answers)
        .map((value) => {
          value.map((item) => {
            if (item.answer === index) {
              answerAmount = filteredUserAnswer.map(
                (user) => user.answers,
              ).length;
            }
          });
        });
      return answerAmount;
    }

    collectionDetail?.answers.find((answer) => {
      if (answer instanceof IndexAnswerModel) {
        if (answer.index === index) {
          answerAmount = answer.amount;
        }
      }
    });

    return answerAmount;
  };

  switch (card.cardType) {
    case CardTypesEnum.QUIZ:
      return (
        <>
          {card.data.options.map((option, index) => (
            <Row key={index} align={"middle"}>
              <Typography.Paragraph key={index} style={{ flex: 1 }}>
                <div>
                  {renderOptionText(index + 1)}
                  {option.isCorrect && (
                    <CardTag
                      type={"correct"}
                      label={Capitalize(t("form.card.answers.correct-answer"))}
                    />
                  )}
                </div>
                <Typography.Text style={{ fontSize: "1.6rem" }}>
                  {option.content}
                </Typography.Text>
              </Typography.Paragraph>

              <a
                style={{
                  cursor: !getAnswerAmount(index) ? "default" : "pointer",
                }}
                onClick={() => {
                  if (!getAnswerAmount(index)) return;
                  history.push(
                    `${RoutesEnum.CARD_DETAIL.replace(
                      ":playbookUid",
                      params.playbookUid,
                    )
                      .replace(":chapterUid", params.chapterUid)
                      .replace(":collectionUid", params.collectionUid)
                      .replace(":cardUid", card.cardUid)}`,
                  );
                }}
              >
                <Avatar
                  size={"large"}
                  className={getAnswerAmount(index) ? styles.avatar : ""}
                  style={{
                    fontWeight: !getAnswerAmount(index) ? "normal" : "bold",
                    backgroundColor: option.isCorrect
                      ? !getAnswerAmount(index)
                        ? backgroundColor
                        : color
                      : "#8C8C8C",
                    opacity:
                      !option.isCorrect && !getAnswerAmount(index) ? 0.3 : 1,
                    fontSize: "16px",
                  }}
                >
                  {getAnswerAmount(index)}
                </Avatar>
              </a>
            </Row>
          ))}
        </>
      );
    case CardTypesEnum.SLIDER:
      return (
        <>
          {card.data.options.map((option, index) => {
            const percent = Math.floor(
              collectionDetail
                ? (getAnswerAmount(index) / collectionDetail.usersCompleted) *
                    100
                : 0,
            );
            return (
              <Typography.Paragraph key={index}>
                {renderOptionText(index + 1)}
                <Typography.Text style={{ fontSize: "1.6rem" }}>
                  {option.label}
                </Typography.Text>

                <a
                  style={{ cursor: !percent ? "default" : "pointer" }}
                  onClick={() => {
                    if (!percent) return;
                    history.push(
                      `${RoutesEnum.CARD_DETAIL.replace(
                        ":playbookUid",
                        params.playbookUid,
                      )
                        .replace(":chapterUid", params.chapterUid)
                        .replace(":collectionUid", params.collectionUid)
                        .replace(":cardUid", card.cardUid)}`,
                    );
                  }}
                >
                  <Progress
                    percent={percent}
                    strokeWidth={11}
                    trailColor={backgroundColor}
                    format={(percent) => (
                      <span style={{ color: "var(--black-color)" }}>
                        {percent}%
                      </span>
                    )}
                    strokeColor={{
                      "0%": GradientColors_GraphPink.to,
                      "100%": GradientColors_GraphPink.from,
                    }}
                    className={graphStyles.boxShadow_pink}
                  />
                </a>
              </Typography.Paragraph>
            );
          })}
        </>
      );
    case CardTypesEnum.PICTURE_QUIZ:
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {card.data.options.map((option, index) => (
              <div
                key={index}
                style={{
                  marginLeft: !index ? undefined : 12,
                }}
              >
                <Row align={"middle"} style={{ marginBottom: "1rem" }}>
                  <Col flex={"auto"}>
                    <Typography.Text
                      type={"secondary"}
                      className={styles.option_text}
                    >
                      {`${Capitalize(t("translations:common.picture"))} ${
                        index + 1
                      }`}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <div
                      style={{
                        borderRadius: 1000,
                        background: option.isCorrect
                          ? "#D9F7BE"
                          : "var(--grey200-color)",
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                        fontWeight: "bold",
                        fontSize: 11,
                      }}
                    >
                      <Typography.Text
                        style={{
                          color: option.isCorrect ? "#237804" : "#434343",
                        }}
                      >
                        {getAnswerAmount(index)}
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
                <a
                  style={{
                    cursor: !getAnswerAmount(index) ? "default" : "pointer",
                  }}
                  onClick={() => {
                    if (!getAnswerAmount(index)) return;
                    history.push(
                      `${RoutesEnum.CARD_DETAIL.replace(
                        ":playbookUid",
                        params.playbookUid,
                      )
                        .replace(":chapterUid", params.chapterUid)
                        .replace(":collectionUid", params.collectionUid)
                        .replace(":cardUid", card.cardUid)}`,
                    );
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: 120,
                      boxShadow: "0px 2px 10px rgba(115,115,115,0.1)",
                      borderRadius: 6,
                      overflow: "hidden",
                      border: option.isCorrect
                        ? "3px solid #52C41A"
                        : undefined,
                    }}
                  >
                    {option.isCorrect && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          backgroundColor: "#52C41A",
                          padding: "1px 7px 3px",
                          zIndex: 1,
                          borderBottomRightRadius: 6,
                        }}
                      >
                        <CheckOutlined style={{ color: "white" }} />
                      </div>
                    )}
                    <Image
                      height={120}
                      width={120}
                      preview={false}
                      src={option.media?.uri}
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </>
      );
    case CardTypesEnum.MULTIPLE_CHOICE:
    case CardTypesEnum.KNOWLEDGE_CARD:
    case CardTypesEnum.QUESTION:
    default:
      return null;
  }
};

export default CardAnswerDetailComponent;
