import { api } from "../../api/config/axiosConfigs";
import QrCodeModel, {
  QrCodeResponse,
} from "../../models/qr-code/qr-code.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import { ContentStatesEnum } from "../../enums/content-states.enum";

const qrCodeApi = {
  getAll: async () => {
    const { data } = await api.request<QrCodeModel[]>({
      url: "/qr-codes/",
      method: "GET",
      transformResponse: (data: string) =>
        MapResponseToModel(JSON.parse(data), QrCodeModel).filter(
          (q) => q.contentState !== ContentStatesEnum.DELETED,
        ),
    });
    return data;
  },
  get: async (qrCodeUid: string) => {
    const { data } = await api.request<QrCodeModel>({
      url: `/qr-codes/${qrCodeUid}/`,
      method: "GET",
      transformResponse: (data: string) => new QrCodeModel(JSON.parse(data)),
    });
    return data;
  },
  create: async (qrCode: Partial<QrCodeResponse>) => {
    const { data } = await api.request<string>({
      url: "/qr-codes/",
      method: "POST",
      data: qrCode,
    });
    return data;
  },
  edit: ({
    qrCodeUid,
    qrCode,
  }: {
    qrCodeUid: string;
    qrCode: Partial<QrCodeResponse>;
  }) =>
    api.request<void>({
      url: `/qr-codes/${qrCodeUid}`,
      method: "POST",
      data: qrCode,
    }),
  delete: (qrCodeUid: string) =>
    api.request<void>({
      url: `/qr-codes/${qrCodeUid}`,
      method: "DELETE",
    }),
  publish: (qrCodeUid: string) =>
    api.request<void>({
      url: `/qr-codes/${qrCodeUid}/publish`,
      method: "POST",
    }),
  draft: (qrCodeUid: string) =>
    api.request<void>({
      url: `/qr-codes/${qrCodeUid}/draft`,
      method: "POST",
    }),
};

export default qrCodeApi;
