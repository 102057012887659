import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Button, Modal, Space, Table, Typography } from "antd";
import Moment from "moment";
import ApiKeyClientModel from "../../../core/models/api-key/api-key-client.model";
import {
  useApiKeyClients,
  useDeleteApiKeyClientMutation,
} from "../../../core/hooks/api-key/api-key.hook";
import { useDispatch, useSelector } from "react-redux";
import { selectPlaybookByApikeyClientUid } from "../../../core/redux/selectors/content/content.selector";
import PlaybookModel from "../../../core/models/content/playbook.model";
import Tag from "../../components/tag/tag";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import colors from "../../styles/colors";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

const SharedContentClientOverviewScreen = () => {
  const { data } = useApiKeyClients();
  const { t } = useTranslation();
  const { mutate } = useDeleteApiKeyClientMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ContentAction.getContent(ContentTypesEnum.PLAYBOOK, {
        excludeMedia: true,
      }),
    );
  }, []);

  function deleteApiKeyClient(data: ApiKeyClientModel) {
    Modal.confirm({
      title: t("translations:screens.shared-content-client.delete.title"),
      content: <Typography.Text>{Capitalize(data.server)}</Typography.Text>,
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
      onOk() {
        mutate(data.apiKeyClientUid);
      },
    });
  }

  const columns: ColumnsType<ApiKeyClientModel> = [
    {
      key: "server",
      title: Capitalize(t("translations:common.provider")),
      dataIndex: "server",
      render: function render(_, data) {
        return Capitalize(data.server);
      },
    },
    {
      key: "content",
      title: Capitalize(t("form.items.content.label")),
      render: function render(_, data) {
        return <Playbooks apiKeyClientUid={data.apiKeyClientUid} />;
      },
    },
    {
      key: "createdAt",
      title: Capitalize(t("common.date-created")),
      dataIndex: "createdAt",
      sorter: (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt),
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.createdAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 50,
      fixed: "right" as const,
      render: function showActions(_, data) {
        return (
          <Space size={"middle"}>
            <Button
              size={"small"}
              type={"text"}
              icon={
                <ContextActionIconComponent
                  action={"delete"}
                  style={{ color: colors.secondary }}
                />
              }
              onClick={() => deleteApiKeyClient(data)}
            />
          </Space>
        );
      },
    },
  ];

  if (!data) {
    return null;
  }

  return (
    <Table<ApiKeyClientModel>
      dataSource={data}
      columns={columns}
      rowKey={(apiKeys) => apiKeys.apiKey}
      tableLayout={"fixed"}
      scroll={{ x: true }}
    />
  );
};

const Playbooks = ({ apiKeyClientUid }: { apiKeyClientUid: string }) => {
  const playbooks: PlaybookModel[] = useSelector((state) =>
    selectPlaybookByApikeyClientUid(state, apiKeyClientUid),
  );

  return (
    <>
      {playbooks.map((playbook, index) => (
        <Tag key={index}>{playbook.title}</Tag>
      ))}
    </>
  );
};

export default SharedContentClientOverviewScreen;
