import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { getRules } from "../../../../core/utils/get-rules.utils";
import CharacterCounter from "./character-counter";
import AppConfig from "../../../../constants/config/app.config";
import TextArea from "antd/lib/input/TextArea";
import styles from "../../card-types/card-types.module.css";

interface OwnProps {
  question: string;
  setQuestion: (value: string) => void;
}

type Props = OwnProps;

const FormQuestionField = (props: Props) => {
  const [t] = useTranslation();
  const { question, setQuestion } = props;

  const questionMaxLength = AppConfig.formQuestionMaxLength;
  return (
    <Form.Item
      initialValue={question}
      name={"question"}
      label={Capitalize(t("form.card.question.label"))}
      rules={getRules("question", false)}
    >
      <div>
        <TextArea
          value={question}
          rows={4}
          style={{ width: "80%" }}
          maxLength={questionMaxLength}
          placeholder={t("form.card.question.placeholder")}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <CharacterCounter
          currentLength={question.length}
          maxLength={questionMaxLength}
          className={styles.character_counter}
        />
      </div>
    </Form.Item>
  );
};

export default FormQuestionField;
