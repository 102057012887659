import React from "react";
import { Typography } from "antd";

type Props = {
  currentLength: number;
  maxLength: number;
  counterStyle?: React.CSSProperties;
  className?: string;
};

const CharacterCounter = (props: Props) => {
  const { currentLength, maxLength, counterStyle, className } = props;
  return (
    <Typography.Text className={className} style={counterStyle}>
      <span>
        <span style={{ color: currentLength >= maxLength ? "red" : "" }}>
          {currentLength}
        </span>
        /{maxLength}
      </span>
    </Typography.Text>
  );
};

export default CharacterCounter;
