import React from "react";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import EmptyStateScreen from "../../screens/empty-state.screen";
import CategoriesOverviewScreen from "../../screens/categories/categories-overview.screen";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";
import { useGetCategories } from "../../../core/api/primio/primioComponents";
import { Category } from "../../../core/api/primio/primioSchemas";

const CategoriesContainer = () => {
  const { data: categories = [] } = useGetCategories({});
  const { openModal } = useModal();

  return (
    <PageLayoutComponent
      menuItems={[]}
      showSider={false}
      title={"categories"}
      onPress={() => onShowModal()}
    >
      {categories.length === 0 ? (
        <EmptyStateScreen route={"categories"} onPress={() => onShowModal()} />
      ) : (
        <CategoriesOverviewScreen
          categories={categories}
          onEdit={onShowModal}
        />
      )}
    </PageLayoutComponent>
  );

  function onShowModal(category?: Category) {
    openModal(ModalNames.CREATE_CATEGORY, { category });
  }
};

export default CategoriesContainer;
