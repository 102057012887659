import React from "react";
import { Button, Dropdown, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";

type Props = {
  overlay: React.ReactElement;
  selection?: any;
};

const ActionButtonComponent = (props: Props) => {
  const { t } = useTranslation();
  const { selection, overlay } = props;

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <Dropdown overlay={overlay} trigger={["click"]}>
        <Button>
          {Capitalize(t("common.pick-an-action"))}
          <DownOutlined />
        </Button>
      </Dropdown>
      {selection && (
        <Typography.Text
          style={{
            color: selection.length === 0 ? "var(--disabled-color)" : "",
          }}
        >
          {Capitalize(
            t("common.selected-items-x", { count: selection.length }),
          )}
        </Typography.Text>
      )}
    </div>
  );
};

export default ActionButtonComponent;
