import { useEffect, useState } from "react";
import ContentTypesEnum from "../enums/content-types.enum";

export function getContentTypeFromParamsUtils(params: {
  playbookUid: string;
  chapterUid?: string;
  collectionUid?: string;
}) {
  const { playbookUid, chapterUid, collectionUid } = params;
  const [contentType, setContentType] = useState(ContentTypesEnum.PLAYBOOK);

  useEffect(() => {
    setContentType(ContentTypesEnum.PLAYBOOK);

    if (chapterUid) {
      setContentType(ContentTypesEnum.CHAPTER);
    }

    if (collectionUid) {
      setContentType(ContentTypesEnum.COLLECTION);
    }
  }, [playbookUid, chapterUid, collectionUid]);

  return contentType;
}
