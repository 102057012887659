import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import styles from "./group-tag.module.css";
import { RoutesEnum } from "../../../constants/routes/app.route";

interface GroupTagProps {
  groupName: string;
}

export function GroupTag({ groupName }: GroupTagProps) {
  return (
    <Link
      to={RoutesEnum.USER_GROUP_DETAILS.replace(":title", groupName)}
      className={styles.group_tag}
    >
      <Typography.Text className={styles.group_tag_label}>
        {groupName}
      </Typography.Text>
    </Link>
  );
}

export function GroupTags({ groupNames }: { groupNames: string[] }) {
  return (
    <>
      {groupNames
        .filter((userGroup) => userGroup !== "")
        .map((groupName, index) => (
          <div
            key={index}
            style={{
              display: "inline-block",
              marginLeft: index === 0 ? undefined : 8,
            }}
          >
            <GroupTag groupName={groupName} />
          </div>
        ))}
    </>
  );
}
