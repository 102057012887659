import ThunkInterface from "../../../interfaces/thunk.interface";
import BaseReducer from "../base/base.reducer";
import CategoryModel from "../../../models/category/category.model";
import CategoryAction from "./category.action";

export interface CategoryReducerInterface {
  categories: CategoryModel[];
}

export default class CategoryReducer extends BaseReducer<CategoryReducerInterface> {
  initialState: CategoryReducerInterface = {
    categories: [],
  };

  [CategoryAction.REQUEST_CATEGORIES_FINISHED](
    state: CategoryReducerInterface,
    action: ThunkInterface<CategoryModel[]>,
  ) {
    return {
      ...state,
      categories: action.payload,
    };
  }

  [CategoryAction.REQUEST_CATEGORY_FINISHED](
    state: CategoryReducerInterface,
    action: ThunkInterface<CategoryModel>,
  ) {
    return {
      ...state,
      categories: this.addSingleToArray<CategoryModel>(
        action.payload,
        [...(state.categories as CategoryModel[])],
        "categoryUid",
      ),
    };
  }
}
