import PlaybookDetailModel from "../models/content/playbook-detail.model";
import ContentSortEnum from "../enums/content-sort.enum";
import Moment from "moment/moment";
import sortPlaybooks from "./sort-playbooks.utils";

function isAlphabeticalChar(char: string): boolean {
  return /^[A-Za-z]/.test(char);
}

interface Config {
  labels: {
    thisWeek: string;
    lastMonth: string;
    lastHalfYear: string;
    other: string;
  };
}

function createPlaybookEditModeSections(
  playbooks: PlaybookDetailModel[],
  sortOption: ContentSortEnum,
  config: Config,
) {
  if (sortOption === ContentSortEnum.CUSTOM) {
    return;
  }

  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
  const sections: { title: string; data: PlaybookDetailModel[] }[] = [];

  if (sortOption === ContentSortEnum.ALPHABETICAL) {
    alphabet.forEach((letter) => {
      const results = playbooks.filter((p) =>
        p.title.trim().toLowerCase().startsWith(letter),
      );

      if (results.length > 0) {
        sections.push({
          title: letter.toUpperCase(),
          data: results,
        });
      }
    });

    const specialResults = playbooks.filter(
      (p) => !isAlphabeticalChar(p.title.trim().charAt(0)),
    );
    sections.unshift({
      title: "0-9",
      data: specialResults,
    });

    return sections;
  } else if (
    sortOption === ContentSortEnum.CREATED_AT_DESC ||
    sortOption === ContentSortEnum.CREATED_AT_ASC
  ) {
    const thisWeekResults = playbooks.filter((p) =>
      Moment(p.createdAt).isAfter(Moment().subtract(1, "week")),
    );
    const lastMonthResults = playbooks.filter((p) =>
      Moment(p.createdAt).isBetween(
        Moment().subtract(1, "month"),
        Moment().subtract(1, "week"),
      ),
    );
    const lastHalfYearResults = playbooks.filter((p) =>
      Moment(p.createdAt).isBetween(
        Moment().subtract(6, "month"),
        Moment().subtract(1, "month"),
      ),
    );
    const olderThenHalfYear = playbooks.filter((p) =>
      Moment(p.createdAt).isBefore(Moment().subtract(6, "month")),
    );

    if (thisWeekResults.length > 0) {
      sections.push({
        title: `${config.labels.thisWeek} (${thisWeekResults.length})`,
        data: thisWeekResults,
      });
    }

    if (lastMonthResults.length > 0) {
      sections.push({
        title: `${config.labels.lastMonth} (${lastMonthResults.length})`,
        data: lastMonthResults,
      });
    }

    if (lastHalfYearResults.length > 0) {
      sections.push({
        title: `${config.labels.lastHalfYear} (${lastHalfYearResults.length})`,
        data: lastHalfYearResults,
      });
    }

    if (olderThenHalfYear.length > 0) {
      sections.push({
        title: `${config.labels.other} (${olderThenHalfYear.length})`,
        data: olderThenHalfYear,
      });
    }

    sections.forEach((s) => {
      s.data.sort((a, b) => sortPlaybooks(a, b, sortOption));
    });

    if (sortOption === ContentSortEnum.CREATED_AT_ASC) {
      sections.reverse();
    }

    return sections;
  } else if (
    sortOption === ContentSortEnum.PUBLISHED_AT_DESC ||
    sortOption === ContentSortEnum.PUBLISHED_AT_ASC
  ) {
    const draftResults = playbooks.filter((p) => !p.publishedAt);
    const publishedResults = playbooks.filter((p) => p.publishedAt);

    const thisWeekResults = publishedResults.filter((p) =>
      Moment(p.publishedAt).isAfter(Moment().subtract(1, "week")),
    );
    const lastMonthResults = publishedResults.filter((p) =>
      Moment(p.publishedAt).isBetween(
        Moment().subtract(1, "month"),
        Moment().subtract(1, "week"),
      ),
    );
    const lastHalfYearResults = publishedResults.filter((p) =>
      Moment(p.publishedAt).isBetween(
        Moment().subtract(6, "month"),
        Moment().subtract(1, "month"),
      ),
    );
    const olderThenHalfYear = publishedResults.filter((p) =>
      Moment(p.publishedAt).isBefore(Moment().subtract(6, "month")),
    );

    if (thisWeekResults.length > 0) {
      sections.push({
        title: `${config.labels.thisWeek} (${thisWeekResults.length})`,
        data: thisWeekResults,
      });
    }

    if (lastMonthResults.length > 0) {
      sections.push({
        title: `${config.labels.lastMonth} (${lastMonthResults.length})`,
        data: lastMonthResults,
      });
    }

    if (lastHalfYearResults.length > 0) {
      sections.push({
        title: `${config.labels.lastHalfYear} (${lastHalfYearResults.length})`,
        data: lastHalfYearResults,
      });
    }

    if (olderThenHalfYear.length > 0) {
      sections.push({
        title: `${config.labels.other} (${olderThenHalfYear.length})`,
        data: olderThenHalfYear,
      });
    }

    sections.forEach((s) => {
      s.data.sort((a, b) => sortPlaybooks(a, b, sortOption));
    });

    if (sortOption === ContentSortEnum.PUBLISHED_AT_ASC) {
      sections.reverse();
    }

    if (draftResults.length > 0) {
      sections.push({
        title: "Draft",
        data: draftResults.sort((a, b) =>
          sortPlaybooks(a, b, ContentSortEnum.CUSTOM),
        ),
      });
    }

    return sections;
  }
}

export default createPlaybookEditModeSections;
