export interface QuizOptionResponse {
  sort: number;
  content: string;
  isCorrect: boolean;
}

export default class QuizOptionModel {
  sort: number;
  content: string;
  isCorrect: boolean;

  constructor(quizCard: QuizOptionResponse) {
    this.sort = quizCard.sort;
    this.content = quizCard.content;
    this.isCorrect = quizCard.isCorrect;
  }
}
