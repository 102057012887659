import ApiKeyEndpoints from "../../api/api-key.endpoints";
import ApiKeyClientEndpoints from "../../api/api-key-client.endpoints";
import apiKeyClientEndpoints from "../../api/api-key-client.endpoints";
import {
  CreateApiKeyClientInterface,
  CreateApiKeyInterface,
  EditApiKeyInterface,
  UpdateApiKeyState,
} from "../../interfaces/create-api-key.interface";
import HttpUtility from "../../utils/http.utils";
import EffectUtility from "../../utils/effect.utils";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import ApiKeyModel from "../../models/api-key/api-key.model";
import ApiKeyClientModel from "../../models/api-key/api-key-client.model";
import CreateApiKeyClientResponse from "../../models/api-key/create-api-key-client.response";
import { AxiosResponse } from "axios";
import { API_URL } from "../../../constants/config/env.config";

export async function getApiKeys() {
  const endpoint = ApiKeyEndpoints.overview;
  const response = await EffectUtility.getToModel<ApiKeyModel[]>(
    ApiKeyModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getApiKey(key: string) {
  const endpoint = ApiKeyEndpoints.detail(key);
  const response = await EffectUtility.getToModel<ApiKeyModel>(
    ApiKeyModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function createApiKeyMutation(data: CreateApiKeyInterface) {
  const endpoint = ApiKeyEndpoints.create;
  const response = await HttpUtility.post(endpoint, data);
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function updateApiKeyMutation(
  key: string,
  data: EditApiKeyInterface,
) {
  const endpoint = ApiKeyEndpoints.edit(key);
  const response = await HttpUtility.post(endpoint, data);
  if (response instanceof HttpErrorResponseModel) {
    if (response.status === 422) {
      // Note: If playbooks are published before userProgress v3,
      // you need to republish them in order for this to work (W.N.)
      Promise.all([
        data.playbookUids.forEach((uid) =>
          HttpUtility.post(`${API_URL}/playbooks/${uid}/publish`, null),
        ),
      ]).then(() => updateApiKeyMutation(key, data));
    } else {
      throw response;
    }
  }

  return response;
}

export async function deleteApiKeyMutation(apiKeyUid: string) {
  const endpoint = ApiKeyEndpoints.delete(apiKeyUid);
  const response = await HttpUtility.delete(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getApiKeyClients() {
  const endpoint = ApiKeyClientEndpoints.overview;
  const response = await EffectUtility.getToModel<ApiKeyClientModel[]>(
    ApiKeyClientModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getApiKeyClient(key: string) {
  const endpoint = ApiKeyClientEndpoints.detail(key);
  const response = await EffectUtility.getToModel<ApiKeyClientModel>(
    ApiKeyClientModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function createApiKeyClientMutation(
  data: CreateApiKeyClientInterface,
) {
  const endpoint = ApiKeyClientEndpoints.create;
  const response:
    | AxiosResponse<CreateApiKeyClientResponse>
    | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response.data;
}

export async function updateApiKeyStateMutation(data: UpdateApiKeyState) {
  const endpoint = ApiKeyEndpoints.updatedApiKeyState(data);
  const response = await HttpUtility.post(endpoint, []);
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function deleteApiKeyClientMutation(apiKeyClientUid: string) {
  const endpoint = apiKeyClientEndpoints.delete(apiKeyClientUid);
  const response = await HttpUtility.delete(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
