import ContentTypesEnum from "../enums/content-types.enum";
import AppConfig from "../../constants/config/app.config";

export function Capitalize(text: string): string {
  if (text.length === 1) {
    return text.toUpperCase();
  }

  const firstChar = text.substring(0, 1).toUpperCase();
  return firstChar + text.substring(1, text.length);
}

export function isPlaybook(contentType: ContentTypesEnum) {
  return contentType === ContentTypesEnum.PLAYBOOK;
}

export function isChapter(contentType: ContentTypesEnum) {
  return contentType === ContentTypesEnum.CHAPTER;
}

export function isCollection(contentType: ContentTypesEnum) {
  return contentType === ContentTypesEnum.COLLECTION;
}

export function Trim(text: string) {
  const maxTitleLength = AppConfig.contentTitleTrimmedLength;
  let trimmed = text;
  if (trimmed.length > maxTitleLength + 3) {
    trimmed = text.substr(0, maxTitleLength) + "...";
  }
  return trimmed;
}
