import React from "react";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { ExpandOutlined, RocketOutlined } from "@ant-design/icons";

const ContentState = ({
  contentState,
}: {
  contentState: ContentStatesEnum;
}) => {
  const { t } = useTranslation();
  return (
    <Row
      align={"middle"}
      style={{
        opacity: contentState === ContentStatesEnum.DRAFT ? 0.6 : 1,
      }}
    >
      <Col
        style={{
          marginRight: "0.5em",
          fontSize: 16,
        }}
      >
        {contentState === ContentStatesEnum.PUBLISHED && <RocketOutlined />}
        {contentState === ContentStatesEnum.DRAFT && <ExpandOutlined />}
      </Col>

      <Col>
        <Typography.Text>
          {Capitalize(t(`translations:content-state.${contentState}`))}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default ContentState;
