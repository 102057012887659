import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Empty, Menu, message, Modal } from "antd";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useSelector } from "react-redux";
import { ContentCompletionProvider } from "../../../core/providers/content-completion.provider";
import {
  selectCurrentUserRole,
  selectUserByUsername,
} from "../../../core/redux/selectors/user/user.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import { getAllUserGroupsPerSelectedUser } from "../../../core/redux/selectors/user-group/user-group.selector";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import ActionButtonComponent from "../../components/actions/action-button.component";
import UserDetailOverviewScreen from "../../screens/users/user-detail-overview.screen";
import CreateNotificationModal from "../../components/modals/create-notification.modal";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import UserModel from "../../../core/models/user/user.model";
import colors from "../../styles/colors";
import AppLoader from "../../components/auth/app-loader";
import { useDisableUser } from "../../../core/api/primio/primioComponents";
import UserRoleEnum from "../../../core/enums/user-role.enum";

const UserDetailContainer = (props: any) => {
  const user = useSelector((state) =>
    selectUserByUsername(state, props.match.params.username),
  );
  const currentUserRole: UserRoleEnum | undefined = useSelector(
    selectCurrentUserRole,
  );

  const { t } = useTranslation();
  const [isModalNotificationVisible, setIsModalNotificationVisible] =
    useState<boolean>(false);
  const history = useHistory();
  const userWithUserGroups: UserModel | undefined = useSelector((state) =>
    getAllUserGroupsPerSelectedUser(state, user),
  );
  const { mutateAsync: disableUserAsync } = useDisableUser({
    onSuccess: () => {
      message.success(
        Capitalize(
          t("errors.warnings.succes_delete-x", {
            container: t("containers.users.key"),
          }),
        ),
      );

      switch (currentUserRole) {
        case UserRoleEnum.SYS_ADMIN:
        case UserRoleEnum.PRIMIO_SUPPORT:
        case UserRoleEnum.ADMIN:
          history.push(RoutesEnum.USERS);
          break;
        case UserRoleEnum.GROUP_ADMIN:
          history.push(RoutesEnum.USER_GROUPS);
          break;
      }
    },
    onError: () => {
      message.error(Capitalize(t("errors.http-general")));
    },
  });

  return (
    <PageLayoutDetailComponent
      title={user ? user.name : "Oops.."}
      extra={renderButton()}
    >
      {!userWithUserGroups && <AppLoader />}
      {!user ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("errors.general")}
        />
      ) : (
        <ContentCompletionProvider>
          {userWithUserGroups && (
            <UserDetailOverviewScreen user={userWithUserGroups} />
          )}
          <CreateNotificationModal
            notification={undefined}
            notifiable={[user]}
            visible={isModalNotificationVisible}
            onCancel={() => setIsModalNotificationVisible(false)}
          />
        </ContentCompletionProvider>
      )}
    </PageLayoutDetailComponent>
  );

  function renderButton() {
    if (!user) {
      return null;
    }

    const menu = (
      <Menu>
        <Menu.Item
          key={"2"}
          icon={<ContainerIconComponent screen={"notifications"} />}
          onClick={() => setIsModalNotificationVisible(true)}
        >
          {Capitalize(
            t("common.send_x", { item: t("containers.notifications.key") }),
          )}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key={"3"}
          icon={<DeleteOutlined />}
          style={{ color: colors.secondary }}
          onClick={() => handleOnDeleteUser(user)}
        >
          {Capitalize(
            t("errors.delete_x", { item: t("containers.users.key") }),
          )}
        </Menu.Item>
      </Menu>
    );

    return <ActionButtonComponent overlay={menu} />;
  }

  function handleOnDeleteUser(user: UserModel) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete", {
          field: t("containers.users.key"),
          item: user.name,
        }),
      ),
      content: Capitalize(t("errors.user.confirmation.delete.content")),
      okText:
        Capitalize(t("common.yes")) +
        ", " +
        t("errors.delete_x", { item: t("containers.users.key") }),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      onOk() {
        disableUserAsync({
          pathParams: {
            identifier: user.username,
          },
        });
      },
    });
  }
};

export default UserDetailContainer;
