import React from "react";
import { Col } from "antd";
import ContentActionComponent, {
  ContentActionProps,
} from "../content-action/content-action.component";
import styles from "./content.module.css";

type Props = {
  actions: ContentActionProps[];
};

const ContentActionsComponent = ({ actions }: Props) => {
  return (
    <>
      {actions.map((actionProps, index) => (
        <div key={index}>
          {index !== 0 && <Col flex={"3rem"} />}
          <Col key={index} className={styles.content_action}>
            <ContentActionComponent {...actionProps} />
          </Col>
        </div>
      ))}
    </>
  );
};

export default ContentActionsComponent;
