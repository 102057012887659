export interface MultipleChoiceOptionResponse {
  sort: number;
  content: string;
  isCorrect: boolean;
  explanation: string;
}

export default class MultipleChoiceOptionModel {
  sort: number;
  content: string;
  isCorrect: boolean;
  explanation: string;

  constructor(multipleChoiceCard: MultipleChoiceOptionResponse) {
    this.sort = multipleChoiceCard.sort;
    this.content = multipleChoiceCard.content;
    this.isCorrect = multipleChoiceCard.isCorrect;
    this.explanation = multipleChoiceCard.explanation;
  }
}
