import { ContentApi } from "../../api/content/content.api";
import ContentTypesEnum from "../../enums/content-types.enum";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import PlaybookHierarchyModel from "../../models/content/playbook-hierarchy.model";
import EffectUtility from "../../utils/effect.utils";
import PlaybookModel from "../../models/content/playbook.model";
import CollectionHierarchyModel from "../../models/content/collection-hierarchy.model";

export async function getPlaybookDetails(playbookUid: string) {
  const api = new ContentApi(ContentTypesEnum.PLAYBOOK);
  if (api.endpoints.contentType !== ContentTypesEnum.PLAYBOOK) {
    return;
  }

  const endpoint = api.endpoints.details(playbookUid);
  const response = await EffectUtility.getToModel<PlaybookHierarchyModel>(
    PlaybookHierarchyModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getPlaybooks() {
  const api = new ContentApi(ContentTypesEnum.PLAYBOOK);
  if (api.endpoints.contentType !== ContentTypesEnum.PLAYBOOK) {
    return;
  }

  const endpoint = api.endpoints.index();
  const response = await EffectUtility.getToModel<PlaybookModel[]>(
    PlaybookModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getCollectionDetail(collectionUid: string) {
  const api = new ContentApi(ContentTypesEnum.COLLECTION);
  if (api.endpoints.contentType !== ContentTypesEnum.COLLECTION) {
    return;
  }

  const endpoint = api.endpoints.detail(collectionUid);
  const response = await EffectUtility.getToModel<CollectionHierarchyModel>(
    CollectionHierarchyModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
