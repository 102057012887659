import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Button, Modal } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import CreateInvitationForm, {
  CreateInvitationFormFields,
  CreateInvitationFormRef,
} from "../forms/create-invitation.form";
import { CreateInvitationInterface } from "../../../core/interfaces/create-invitation.interface";
import { connect, useDispatch } from "react-redux";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import InvitationAction from "../../../core/redux/stores/invitation/invitation.action";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import getErrorMessageInvitation from "../../../core/hooks/error-message-invitations";

interface StateProps {
  requesting: boolean;
  error: HttpErrorResponseModel;
}

interface OwnProps {
  invitation?: InvitationModel;
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps & StateProps;

const CreateInvitationModal = (props: Props) => {
  const [t] = useTranslation();
  const { invitation, visible, onCancel, requesting, error } = props;
  const createInvitationFormRef = useRef<CreateInvitationFormRef>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { errorMessage } = getErrorMessageInvitation(error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage && isSubmitting) {
      setIsSubmitting(false);
      createInvitationFormRef.current?.form.setFields([
        { name: "email", errors: [errorMessage] },
      ]);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (Object.keys(error).length > 0 && error.status !== 200) {
        let errorMessage: string = Capitalize(
          t("errors.validation.invalid", { item: t("form.items.email.label") }),
        );
        switch (error.status) {
          case 422:
            errorMessage = Capitalize(
              t("errors.warnings.in-use", {
                item: t("form.items.email.label"),
              }),
            );
            break;
        }

        createInvitationFormRef.current?.form.setFields([
          { name: "email", errors: [errorMessage] },
        ]);
      } else if (isSubmitting) {
        handleOnCancel();
      }
    }
  }, [requesting]);

  return (
    <Modal
      open={visible}
      title={renderModalTitle()}
      confirmLoading={requesting}
      width={"80rem"}
      onCancel={handleOnCancel}
      destroyOnClose
      footer={[
        <Button key={0} type={"text"} onClick={handleOnCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <Button
          key={1}
          type={"primary"}
          onClick={handleOnOk}
          loading={isSubmitting}
        >
          {renderOnOkLabel()}
        </Button>,
      ]}
    >
      <CreateInvitationForm
        ref={createInvitationFormRef}
        invitation={invitation}
      />
    </Modal>
  );

  function handleOnOk() {
    if (createInvitationFormRef.current) {
      createInvitationFormRef.current.form
        .validateFields()
        .then((res: CreateInvitationFormFields) => {
          if (invitation && invitation.invitationCode) {
            const data: Partial<CreateInvitationInterface> = {
              name: res.name,
              email: res.email.trim().toLowerCase(),
              userGroups: res.userGroups ? res.userGroups : [],
            };

            dispatch(
              InvitationAction.editInvitation(invitation, data, res.sendAt),
            );
          } else {
            const data: CreateInvitationInterface = {
              name: res.name,
              email: res.email.trim().toLowerCase(),
              userGroups: res.userGroups ? res.userGroups : [],
            };

            dispatch(InvitationAction.createInvitations([data], res.sendAt));
          }

          setIsSubmitting(true);
        });
    }
  }

  function handleOnCancel() {
    onCancel();
    if (createInvitationFormRef.current) {
      createInvitationFormRef.current.form.resetFields();
    }
  }

  function renderModalTitle() {
    if (invitation && invitation.invitationUid) {
      return Capitalize(
        t("common.edit_x", { name: t("containers.invitations.key") }),
      );
    }
    return (
      Capitalize(t("common.new-plural")) +
      " " +
      Capitalize(t("containers.invitations.key"))
    );
  }

  function renderOnOkLabel() {
    if (invitation && invitation.invitationUid) {
      return Capitalize(t("common.edit"));
    }
    return Capitalize(t("common.create"));
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  requesting: selectRequesting(state, [
    InvitationAction.REQUEST_INVITATIONS,
    InvitationAction.REQUEST_CREATE_INVITATION,
    InvitationAction.REQUEST_EDIT_INVITATION,
  ]),
  error: selectRawErrors(state, [
    InvitationAction.REQUEST_INVITATIONS_FINISHED,
    InvitationAction.REQUEST_CREATE_INVITATION_FINISHED,
    InvitationAction.REQUEST_EDIT_INVITATION_FINISHED,
  ]),
});

export default connect(mapStateToProps)(CreateInvitationModal);
