import { EffectType } from "../../../types/redux.type";
import { CreateLearningPathInterface } from "../../../interfaces/create-learning-path.interface";
import LearningPathEndpoints from "../../../api/learning-path.endpoints";
import EffectUtility from "../../../utils/effect.utils";
import HttpUtility from "../../../utils/http.utils";
import LearningPathModel, {
  LearningPathResponse,
} from "../../../models/learning-path/learning-path.model";
import SortLearningPathInterface from "../../../interfaces/sort-learning-path.interface";

export default class LearningPathEffect {
  /**
   * Get all Learning Paths
   * @returns Promise LearningPathModel[]
   */
  static getAllLearningPaths(): EffectType<LearningPathModel[]> {
    const endpoint = LearningPathEndpoints.overview;
    return EffectUtility.getToModel<LearningPathModel[]>(
      LearningPathModel,
      endpoint,
    );
  }

  /**
   * Get one Learning Path
   * @param learningPathUid string
   * @returns Promise LearningPathModel
   */
  static getLearningPath(
    learningPathUid: string,
  ): EffectType<LearningPathModel> {
    const endpoint = LearningPathEndpoints.detail(learningPathUid);
    return EffectUtility.getToModel<LearningPathModel>(
      LearningPathModel,
      endpoint,
    );
  }

  /**
   * Create Learning Path via POST Request
   * @param data CreateLearningPathInterface
   * @returns Promise LearningPathModel
   */
  static createLearningPath(
    data: CreateLearningPathInterface,
  ): EffectType<LearningPathModel> {
    const endpoint = LearningPathEndpoints.create;
    return HttpUtility.post(endpoint, data);
  }

  /**
   * Edit Learning Path via POST Request
   * @param learningPath LearningPathModel
   * @param data Partial<LearningPathResponse>
   * @returns Promise LearningPathModel
   */
  static editLearningPath(
    learningPath: LearningPathModel,
    data: Partial<LearningPathResponse>,
  ): EffectType<LearningPathModel> {
    const endpoint = LearningPathEndpoints.edit(learningPath.learningPathUid);
    return HttpUtility.post(endpoint, data);
  }

  /**
   * Post Learning Path via POST Request
   * @param learningPath LearningPathModel
   * @returns Promise HttpRequest
   */
  static postLearningPath(learningPath: LearningPathModel) {
    const endpoint = LearningPathEndpoints.post(learningPath.learningPathUid);
    return HttpUtility.post(endpoint, null);
  }

  /**
   * Revoke Learning Path via POST Request
   * @param learningPath LearningPathModel
   * @returns Promise HttpRequest
   */
  static revokeLearningPath(learningPath: LearningPathModel) {
    const endpoint = LearningPathEndpoints.revoke(learningPath.learningPathUid);
    return HttpUtility.post(endpoint, null);
  }

  /**
   * Delete Learning Path via DELETE Request
   * @param learningPath
   */
  static deleteLearningPath(learningPath: LearningPathModel) {
    const endpoint = LearningPathEndpoints.detail(learningPath.learningPathUid);
    return HttpUtility.delete(endpoint);
  }

  /**
   * Sort LearningPaths relative from each other
   * @param data
   */
  static sortLearningPath(data: SortLearningPathInterface) {
    const endpoint = LearningPathEndpoints.sort;
    return HttpUtility.post(endpoint, data);
  }
}
