export interface LearningPathUserGroupModelResponse {
  learningPathUid: string;
  contentUserGroups: [];
}

export default class LearningPathUserGroupModel {
  learningPathUid: string;
  contentUserGroups: [];

  constructor(response: LearningPathUserGroupModelResponse) {
    this.learningPathUid = response.learningPathUid;
    this.contentUserGroups = response.contentUserGroups;
  }
}
