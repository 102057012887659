import React, { useEffect, useState } from "react";
import useDownloadCertificates from "../../../core/hooks/use-download-certificates";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";

const DownloadCertificateButton = ({
  userName,
  userUid,
  learningPathTitle,
  learningPathUid,
  showLabel = true,
}: {
  userName: string;
  userUid: string;
  learningPathTitle: string;
  learningPathUid: string;
  showLabel?: boolean;
}) => {
  const { downloadCertificates, isLoading, isSuccess, isError } =
    useDownloadCertificates();
  const { t } = useTranslation();
  const [showResultState, setShowResultState] = useState(false);

  const filename = `${userName}-${learningPathTitle}-certificate.pdf`
    .replace(/[/\\:*?!"<>|]/g, "")
    .replace(/\s/g, "_")
    .toLowerCase();

  useEffect(() => {
    if (isSuccess || isError) {
      setShowResultState(true);
      setTimeout(() => {
        setShowResultState(false);
      }, 3000);
    }
  }, [isSuccess, isError]);

  return (
    <a
      onClick={() =>
        downloadCertificates([{ userUid, learningPathUid }], filename)
      }
      title={Capitalize(
        t("translations:common.download_x", {
          item: t("translations:common.certificate"),
        }),
      )}
    >
      {showResultState && (
        <>
          {isSuccess && <CheckCircleOutlined style={{ color: "green" }} />}
          {isError && <ExclamationCircleOutlined style={{ color: "red" }} />}
        </>
      )}
      {isLoading && <LoadingOutlined />}
      {!showResultState && !isLoading && <DownloadOutlined />}{" "}
      {showLabel && Capitalize(t("translations:common.download"))}
    </a>
  );
};

export default DownloadCertificateButton;
