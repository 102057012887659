import React from "react";
import { selectRequesting } from "../../../../core/redux/selectors/requesting/requesting.selector";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { useSelector } from "react-redux";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import ProgressBasedContentModel from "../../../../core/models/learning-path/progress-based-content.model";
import TimeBasedContentModel from "../../../../core/models/learning-path/time-based-content.model";
import LearningPathAction from "../../../../core/redux/stores/learning-path/learning-path.action";

const getItemStyle = (isDragging, draggableStyle) => ({
  cursor: "pointer",
  ...draggableStyle,
});

interface Props {
  index: number;
  content: TimeBasedContentModel | ProgressBasedContentModel;
  children: JSX.Element;
}

const DraggableComponent = (props: Props) => {
  const { index, content } = props;
  const isSorting = useSelector((state: StoreInterface) =>
    selectRequesting(state, [LearningPathAction.REQUEST_EDIT_LEARNING_PATH]),
  );

  return (
    <Draggable
      key={`${content.contentUid}_${index + 1}`}
      isDragDisabled={isSorting}
      draggableId={`${content.contentUid}_${index + 1}`}
      index={index}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          {props.children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableComponent;
