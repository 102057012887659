import { API_URL } from "../../../constants/config/env.config";

const UserProgressEndpoints = {
  /**
   * GET user progress for a playbook endpoint.
   * @returns API_URL + /user-progress/playbook/a3ef9d13-e0de-48aa-9429-128b9c7ae0b6
   */
  overview(playbookUid: string) {
    return `${API_URL}/user-progress/playbook/${playbookUid}`;
  },

  /**
   * Get popular users
   * @returns API_URL + /user-progress/popular-users
   */
  popularUsers: `${API_URL}/user-progress/popular-users`,

  /**
   * GET tracked playbooks for admin user endpoint.
   * @returns API_URL + /user-progress/track-playbooks
   */
  trackedPlaybooks: `${API_URL}/user-progress/track-playbooks`,

  /**
   * GET users activity
   * @returns API_URL + /user-progress/activity/users
   */
  usersActivity: `${API_URL}/user-progress/activity/users`,

  /**
   * GET completed cards
   * @returns API_URL + /user-progress/activity/cards/users
   */
  completedCards: `${API_URL}/user-progress/activity/cards/users`,

  /**
   * GET user progress latest activity
   * @returns API_URL + /user-progress/latest-activity
   */
  latestActivity: `${API_URL}/user-progress/latest-activity`,

  /** // TODO: the published parameter is hardcoded to true
   * GET content directory
   * @returns API_URL + /user-progress/content-directory
   */
  contentDirectory: `${API_URL}/user-progress/content-directory?published=true`,

  /**
   * GET interactions on content
   * @param contentUid string
   * @returns API_URL + /user-progress/interactions/5ad96bc8-cbc1-4444-84c8-35b689332cf8
   */
  interactions(contentUid: string) {
    return `${API_URL}/user-progress/interactions/${contentUid}`;
  },

  /**
   * GET completed user progress
   * @returns API_URL + /user-progress/users/:username
   */
  userCompletedUserProgress(username: string) {
    return `${API_URL}/user-progress/users/${username}`;
  },

  /**
   * GET users progress results for collection details for ALL users endpoint.
   * @param collectionUid string
   * @returns API_URL + /user-progress/collections/5ad96bc8-cbc1-4444-84c8-35b689332cf8
   */
  usersProgressCollectionDetail(collectionUid: string) {
    return `${API_URL}/user-progress/collections/${collectionUid}`;
  },

  /**
   * GET user progress answers for cards details for all users endpoint.
   * @param cardUid string
   * @returns API_URL + /user-progress/cards/b42bbca4-b3dc-4f01-b77f-56f859a78237
   */
  userProgressCardDetail(cardUid: string) {
    return `${API_URL}/user-progress/cards/${cardUid}`;
  },

  /**
   * GET user progress results for collection details for ONE specific user endpoint.
   * @param collectionUid string
   * @param userUid string
   * @returns API_URL + /user-progress/collections/5ad96bc8-cbc1-4444-84c8-35b689332cf8/users/5ad96bc8-cbc1-4444-84c8-35b689332cf8
   */
  userCollectionCompletion(collectionUid: string, userUid: string) {
    return `${API_URL}/user-progress/collections/${collectionUid}/users/${userUid}`;
  },
};

export default UserProgressEndpoints;
