export enum LearningPathStateEnum {
  CREATED = "CREATED",
  POSTED = "POSTED",
  REVOKED = "REVOKED",
}

export enum LearningPathTypeEnum {
  OPEN = "OPEN",
  TIME_BASED = "TIME_BASED",
  PROGRESS_BASED = "PROGRESS_BASED",
  SCHEDULE_BASED = "SCHEDULE_BASED",
}
