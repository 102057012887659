import { ThunkType } from "../../../types/redux.type";
import ActionUtility from "../../../utils/action.utils";
import MediaEffect from "./media.effect";
import MediaModel, { MediaResponse } from "../../../models/media/media.model";

export default class MediaAction {
  // Get Media
  static REQUEST_MEDIA = "MediaAction.REQUEST_MEDIA";
  static REQUEST_MEDIA_FINISHED = "MediaAction.REQUEST_MEDIA_FINISHED";

  // Post Media
  static REQUEST_CREATE_MEDIA = "MediaAction.REQUEST_CREATE_MEDIA";
  static REQUEST_CREATE_MEDIA_FINISHED =
    "MediaAction.REQUEST_CREATE_MEDIA_FINISHED";

  /**
   * Get Media
   * @param mediaUid string
   * @returns Dispatch MediaEffect.getMedia
   */
  static getMedia(mediaUid: string): ThunkType<MediaModel> {
    return ActionUtility.createThunk<MediaModel>(
      MediaAction.REQUEST_MEDIA,
      MediaEffect.getMedia,
      mediaUid,
    );
  }

  /**
   * Create Media
   * @param data Partial<MediaResponse>
   * @returns Dispatch MediaEffect.createMedia
   */
  static createMedia(data: Partial<MediaResponse>): ThunkType<MediaModel> {
    return ActionUtility.createThunk<MediaModel>(
      MediaAction.REQUEST_CREATE_MEDIA,
      MediaEffect.createMedia,
      data,
    );
  }
}
