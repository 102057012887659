import React, { useEffect, useState } from "react";
import { DispatchProps } from "../content-create-button/create-content-button.connected";
import { useSelector } from "react-redux";
import { Card, Col, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import {
  CardTypesColorEnum,
  CardTypesEnum,
} from "../../../core/enums/card-types.enum";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import CreateCardButtonComponent from "../content-create-button/create-card-button.component";
import isInViewport from "../../../core/utils/is-in-viewport.utils";
import ContentAction from "../../../core/redux/stores/content/content.action";
import CardTypeIconComponent from "../icons/card-type-icon.component";
import Emitter from "../../../core/utils/emitter.utils";
import styles from "../content/content.module.css";

interface MergeProps {
  onSubmit?: (cardType: CardTypesEnum, props: OwnProps) => void;
}

export interface OwnProps {
  collectionUid: string;
  cardIndex: number;
  sortIndex?: number;
}

type Props = OwnProps & MergeProps & DispatchProps;

const CardTypesComponent = (props: Props) => {
  const [t] = useTranslation();
  const { onSubmit, collectionUid, cardIndex, sortIndex } = props;
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const requesting = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_CREATE_CARDS,
      ContentAction.REQUEST_CARDS,
    ]),
  );

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);
      handleHideForm();
    }
  }, [requesting]);

  const handleHideForm = () => {
    setShowForm(false);
  };

  const handleShowForm = (e: boolean) => {
    setShowForm(e);
  };

  useEffect(() => {
    Emitter.on("handleShowCardTypes", handleShowForm);
  });

  useEffect(() => {
    // Scroll to '+ New Card' when it's not in viewport
    if (!showForm) return;

    if (containerRef.current && !isInViewport(containerRef)) {
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [showForm]);

  useEffect(() => {
    // Hide element on clicked outside
    const checkIfClickedOutside = (e) => {
      if (
        showForm &&
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        setShowForm(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showForm]);

  return !showForm ? (
    <CreateCardButtonComponent className={styles.card_types_dashed_btn_large} />
  ) : (
    <div ref={containerRef} className={styles.content_container_selected}>
      <Card className={styles.content}>
        <CardCollapseHeader
          title={Capitalize(t("content.card-types.header"))}
          icon={<PlusOutlined />}
        />
        <div className={styles.card_types_wrapper}>{cardTypeComponent()}</div>
      </Card>
    </div>
  );

  function cardTypeComponent() {
    return Object.values(CardTypesEnum).map(
      (cardType: CardTypesEnum, index: number) => {
        const cardTypeColor = {
          "--card-type-color": CardTypesColorEnum[cardType][0].color,
        } as React.CSSProperties;
        const backgroundColor = {
          "--hover-card-type-background-color":
            CardTypesColorEnum[cardType][1].background,
        } as React.CSSProperties;

        return (
          <Row
            key={index}
            align={"middle"}
            justify={"start"}
            className={styles.card_types_row}
            style={backgroundColor}
            onClick={() => {
              if (onSubmit) {
                setIsSubmitting(true);
                onSubmit(cardType, { collectionUid, cardIndex, sortIndex });
              }
            }}
          >
            <Col>
              <CardTypeIconComponent
                cardType={cardType}
                style={cardTypeColor}
              />
            </Col>
            <Col flex={"1.5rem"} />
            <Col flex={"none"}>
              {Capitalize(t(`content.card-types.${cardType}`))}
            </Col>
          </Row>
        );
      },
    );
  }
};

interface CardCollapseHeaderProps {
  title: string;
  icon: JSX.Element;
}

const CardCollapseHeader = ({ title, icon }: CardCollapseHeaderProps) => {
  return (
    <Row align={"middle"}>
      <Col>{icon}</Col>
      <Col flex={"2rem"} />
      <Col>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default CardTypesComponent;
