import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Typography } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import PlaybookUserProgressComponent from "../content-user-progress/user-progress-types/playbook-user-progress.component";
import styles from "./user-progress.module.css";
import PlaybooksSelectComponent from "../forms/select/playbooks-select.component";
import { useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import { Playbook } from "../../../core/api/primio/primioSchemas";

const ViewModulesComponent = () => {
  const { t } = useTranslation();
  const [viewPlaybooks, setViewPlaybooks] = useState<string[]>([]);
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );

  const filterPlaybooks = useCallback(
    (playbook: Playbook) => {
      if (currentlySelectedUserGroup === "") {
        return playbook.contentState === "PUBLISHED";
      }

      return (
        playbook.userGroupAcl.includes(currentlySelectedUserGroup) &&
        playbook.contentState === "PUBLISHED"
      );
    },
    [currentlySelectedUserGroup],
  );

  return (
    <div className={styles.pcup_container}>
      <Typography.Text className={styles.pcup_heading}>
        {Capitalize(t("common.view")) +
          " " +
          t("containers.modules.key_plural")}
      </Typography.Text>

      <div className={styles.pcup_wrapper} style={{ flexWrap: "wrap" }}>
        {viewPlaybooks.map((uid) => (
          <div className={styles.pcup_card_wrapper} key={uid}>
            <CloseCircleFilled
              onClick={() =>
                setViewPlaybooks(viewPlaybooks.filter((i) => i !== uid))
              }
              className={styles.remove_module}
            />
            <PlaybookUserProgressComponent playbookUid={uid} />
          </div>
        ))}

        <div className={styles.pcup_card_empty}>
          <Typography.Text
            type={"secondary"}
            className={styles.pcup_card_empty_text}
          >
            {t("screens.user-progress.view.empty-state")}
          </Typography.Text>
          <div
            style={{
              width: "100%",
            }}
          >
            <PlaybooksSelectComponent
              playbookUids={viewPlaybooks}
              onChange={(value) =>
                setViewPlaybooks(value.map((v) => v.playbookUid))
              }
              filter={filterPlaybooks}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModulesComponent;
