import { useTranslation } from "react-i18next";
import UserRoleEnum from "../../../../core/enums/user-role.enum";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../../core/redux/selectors/user/user.selector";
import { Form } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import UserGroupSelectComponent from "../../forms/select/user-groups-select.component";
import React from "react";

interface Props {
  initialValue: string[];
  onChange: (value: string[]) => void;
  name?: string;
  mode?: string;
  excludeAdminGroups?: boolean;
  disabled?: boolean;
}

const UserGroupSelectFormItem = ({
  initialValue,
  onChange,
  name = "userGroupAcl",
  mode,
  excludeAdminGroups,
  disabled,
}: Props) => {
  const [t] = useTranslation();
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);

  function getUserGroupAclRules() {
    if (!userRole) {
      return [];
    }

    switch (userRole) {
      case UserRoleEnum.SYS_ADMIN:
      case UserRoleEnum.PRIMIO_SUPPORT:
      case UserRoleEnum.ADMIN:
        return [];
      case UserRoleEnum.GROUP_ADMIN:
        return [
          {
            required: true,
            message: t("translations:errors.required-usergroups"),
          },
        ];
    }
  }

  return (
    <Form.Item
      initialValue={initialValue}
      name={name}
      label={Capitalize(t("containers.user-groups.key_plural"))}
      rules={getUserGroupAclRules()}
    >
      <div style={{ width: "90%" }}>
        <UserGroupSelectComponent
          groupNames={initialValue}
          onChange={onChange}
          mode={mode}
          excludeAdminGroups={excludeAdminGroups}
          disabled={disabled}
        />
      </div>
    </Form.Item>
  );
};

export default UserGroupSelectFormItem;
