import React from "react";
import UserModel from "../../../core/models/user/user.model";
import SectionTitleComponent from "../section-title/section-title.component";
import UserLearningPathReportTable from "../table/user-learning-path-report-table";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";

const UserTrainingProgress = ({ user }: { user: UserModel }) => {
  const { t } = useTranslation();
  return (
    <div>
      <SectionTitleComponent
        title={Capitalize(
          t("translations:containers.learning-path.key_plural"),
        )}
      />
      <UserLearningPathReportTable user={user} />
    </div>
  );
};

export default UserTrainingProgress;
