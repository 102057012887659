export interface LatestActivityResponse {
  userUid: string;
  date: Date;
}

export default class LatestActivityModel {
  userUid: string;
  date: Date;

  constructor(response: LatestActivityResponse) {
    this.userUid = response.userUid;
    this.date = new Date(response.date);
  }
}
