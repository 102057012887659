import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { RoutesLearningPathEnum } from "../../../constants/routes/learning-path.route";
import { LearningPathStateEnum } from "../../../core/enums/learning-path-state.enum";
import { selectLearningPaths } from "../../../core/redux/selectors/learning-path/learning-path.selector";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Capitalize } from "../../../core/utils/helper.utils";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import EmptyStateScreen from "../../screens/empty-state.screen";
import LearningPathAction from "../../../core/redux/stores/learning-path/learning-path.action";
import LearningPathOverviewScreen from "../../screens/learning-path/learning-path-overview.screen";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import useQuery from "../../../core/hooks/useQuery";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";

interface StateProps {
  learningPaths: LearningPathModel[];
  requesting: boolean;
  error: HttpErrorResponseModel;
}

type Props = StateProps;

const LearningPathContainer = ({ learningPaths }: Props) => {
  const { t } = useTranslation();
  const [learningPathStateFilter, setLearningPathStateFilter] = useState<
    LearningPathStateEnum | undefined
  >(LearningPathStateEnum.CREATED);
  const activeLearningPaths: any = [...learningPaths].filter(
    (learningPath) => learningPath.state !== "REVOKED",
  );

  const query = useQuery();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  useEffect(() => {
    dispatch(LearningPathAction.getAllLearningPaths());
  }, []);

  const state: LearningPathStateEnum | null = query.get(
    "state",
  ) as LearningPathStateEnum | null;
  if (state) {
    if (state !== learningPathStateFilter) {
      setLearningPathStateFilter(state);
    }
  } else {
    if (learningPathStateFilter !== undefined) {
      setLearningPathStateFilter(undefined);
    }
  }

  const menuItems = () => {
    const count =
      activeLearningPaths.length > 0 ? activeLearningPaths.length : 0;
    const menuItems: MenuItemInterface[] = [
      {
        title: `${t("containers.learning-path.sidebar")} (${count})`,
        route: RoutesEnum.LEARNING_PATH,
      },
    ];

    for (const key in LearningPathStateEnum) {
      if (key === "REVOKED") continue;
      const count =
        activeLearningPaths.length > 0
          ? activeLearningPaths.filter((l) => l.state === key).length
          : 0;
      menuItems.push({
        title: `${Capitalize(
          t(`screens.learning-path.state.${key}`),
        )} (${count})`,
        route: RoutesLearningPathEnum[key],
      });
    }

    return menuItems;
  };

  const filteredLearningPaths = (): LearningPathModel[] => {
    if (!activeLearningPaths) {
      return [];
    }

    if (!learningPathStateFilter) {
      return activeLearningPaths;
    }

    return activeLearningPaths.filter(
      (l) => l.state === learningPathStateFilter,
    );
  };

  return (
    <PageLayoutComponent
      menuItems={menuItems()}
      title={"learning-path"}
      onPress={() => onShowModal()}
      singular
    >
      {!learningPaths || filteredLearningPaths().length === 0 ? (
        <EmptyStateScreen
          route={"learning-path"}
          onPress={() => onShowModal()}
        />
      ) : (
        <LearningPathOverviewScreen
          learningPaths={filteredLearningPaths()}
          onEdit={onShowModal}
        />
      )}
    </PageLayoutComponent>
  );

  function onShowModal(learningPath?: LearningPathModel) {
    openModal(ModalNames.CREATE_LEARNING_PATH, { learningPath });
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  learningPaths: selectLearningPaths(state),
  requesting: selectRequesting(state, [
    LearningPathAction.REQUEST_LEARNING_PATHS,
  ]),
  error: selectRawErrors(state, [
    LearningPathAction.REQUEST_LEARNING_PATHS_FINISHED,
  ]),
});

export default connect(mapStateToProps)(LearningPathContainer);
