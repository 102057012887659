import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import exportQrFileUtils from "../../../core/utils/export-qr-file.utils";
import QrCodeModel from "../../../core/models/qr-code/qr-code.model";
import styles from "./qr-code.module.css";
import QRCode from "qrcode.react";
import Link from "antd/lib/typography/Link";
import useEnvironment from "../../../core/hooks/useEnvironment";

interface OwnProps {
  qrCode: QrCodeModel;
}

const QrCodeComponent = ({ qrCode }: OwnProps) => {
  const [t] = useTranslation();
  const [url, setUrl] = useState("");
  const { environment, appScheme } = useEnvironment();

  useEffect(() => {
    if (qrCode.openInApp) {
      setUrl(`${appScheme}://?action=open-qr&qrCodeUid=${qrCode.qrCodeUid}`);
    } else {
      setUrl(`https://qr.primio.app/?q=${qrCode.qrCodeUid}&e=${environment}`);
    }
  }, [qrCode, environment, appScheme]);

  return (
    <div className={styles.qr_code_container}>
      <div className={styles.qr_code_wrapper}>
        <QRCode
          id={qrCode.qrCodeUid}
          value={url}
          includeMargin={true}
          size={600}
          style={{
            width: "20rem",
            height: "20rem",
          }}
        />
        <Button
          type={"primary"}
          download
          icon={<DownloadOutlined />}
          onClick={() => exportQrFileUtils(qrCode)}
        >
          {Capitalize(
            t("common.download_x", {
              item: Capitalize(t("content.media-types.IMAGE.label")),
            }),
          )}
        </Button>
      </div>
      <Link
        style={{ margin: "0.5rem 1rem" }}
        copyable={{
          text: url,
          tooltips: [
            Capitalize(
              t("common.copy_x", {
                item: Capitalize(t("content.media-types.EMBED.LINK")),
              }),
            ),
            Capitalize(
              t("common.copied_x", {
                item: Capitalize(t("content.media-types.EMBED.LINK")),
              }),
            ) + "!",
          ],
        }}
      >
        {""}
      </Link>
    </div>
  );
};

export default QrCodeComponent;
