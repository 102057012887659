import ContentType from "../types/content.type";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { StoreInterface } from "../redux/stores/root.reducer";
import {
  selectCollectionViaUid,
  selectContentViaContentId,
  selectParentContentTree,
} from "../redux/selectors/content/content.selector";
import CollectionModel from "../models/content/collection.model";
import PlaybookModel from "../models/content/playbook.model";
import ChapterModel from "../models/content/chapter.model";
import ChapterDetailModel from "../models/content/chapter-detail.model";
import ContentTypesEnum from "../enums/content-types.enum";
import CollectionDetailModel from "../models/content/collection-detail.model";
import { extractUrlKeyValue } from "../utils/extractUrlKeyValue";

interface ContentSidebarContextType {
  selectedContent: ContentType | undefined;
  selectedChapter: ChapterDetailModel | undefined;
  selectedCollection: CollectionDetailModel | undefined;
  selectedContentId: string | undefined;
  onClick: (contentId: string) => void;
  getSelectedContentParentTree: () =>
    | (PlaybookModel | ChapterModel | CollectionModel)[]
    | undefined;
  reset: () => void;
  isSelected: (contentId: string) => boolean;
}

const ContentSidebarContext = createContext<ContentSidebarContextType>(
  {} as ContentSidebarContextType,
);

const ContentSidebarProvider = (props: { children: JSX.Element }) => {
  const [selectedContent, setSelectedContent] = useState<
    ContentType | undefined
  >(undefined);
  const [selectedChapter, setSelectedChapter] = useState<
    ChapterDetailModel | undefined
  >(undefined);
  const [selectedCollection, setSelectedCollection] = useState<
    CollectionDetailModel | undefined
  >(undefined);
  const [selectedContentId, setSelectedContentId] = useState<string>();

  const { getState } = useStore();

  useEffect(() => {
    const urlParams = extractUrlKeyValue(window.location.pathname);
    const state: StoreInterface = getState();

    if (urlParams.playbook) {
      onClick(urlParams.playbook);
    }

    if (urlParams.chapter) {
      const content = selectContentViaContentId(state, urlParams.chapter);
      if (content?.contentType === ContentTypesEnum.CHAPTER) {
        setSelectedContent(content);
        setSelectedChapter(content);
      }
    }

    if (urlParams.collection) {
      const content = selectContentViaContentId(state, urlParams.collection);
      if (content?.contentType === ContentTypesEnum.COLLECTION) {
        setSelectedContent(content);
      }
    }
  }, []);

  useEffect(() => {
    const parentContent = getSelectedContentParentTree();
    parentContent?.forEach((content) => {
      if (content.contentType === ContentTypesEnum.CHAPTER)
        setSelectedChapter(content);
      if (content.contentType === ContentTypesEnum.COLLECTION)
        setSelectedCollection(content);
    });
  }, [selectedContent]);

  const onClick = (contentId: string) => {
    setSelectedContentId(contentId);
    const state: StoreInterface = getState();
    const content = selectContentViaContentId(state, contentId);

    if (!content) {
      return;
    }

    setSelectedContent(content);

    let parentCollection;

    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        setSelectedChapter(undefined);
        break;
      case ContentTypesEnum.CHAPTER:
        setSelectedChapter(content);
        setSelectedCollection(undefined);
        break;
      case ContentTypesEnum.COLLECTION:
        setSelectedCollection(content);
        break;
      case ContentTypesEnum.CARD:
        parentCollection = selectCollectionViaUid(state, content.collectionUid);
        setSelectedCollection(parentCollection);
        break;
    }
  };

  const getSelectedContentParentTree = () => {
    if (!selectedContent) {
      return;
    }
    const state: StoreInterface = getState();
    return selectParentContentTree(state, selectedContent);
  };

  const reset = () => {
    setSelectedContent(undefined);
    setSelectedChapter(undefined);
  };

  const isSelected = (contentId: string) => {
    return selectedContentId === contentId;
  };

  const memoedValue = useMemo(
    () => ({
      selectedContent,
      selectedChapter,
      selectedCollection,
      selectedContentId,
      onClick,
      getSelectedContentParentTree,
      reset,
      isSelected,
    }),
    [selectedContent, selectedChapter, selectedCollection, selectedContentId],
  );

  return (
    <ContentSidebarContext.Provider value={memoedValue}>
      {props.children}
    </ContentSidebarContext.Provider>
  );
};

const useContentSidebar = () => React.useContext(ContentSidebarContext);

export { ContentSidebarProvider, useContentSidebar };
