import { EffectType } from "../../../types/redux.type";
import {
  ContentInUserGroupEdit,
  UserGroupCreateInterface,
  UserGroupEditInterface,
  UsersInUserGroupEditInterface,
} from "../../../interfaces/user-group-create.interface";
import UserGroupEndpoints from "../../../api/user-group.endpoints";
import UserGroupModel from "../../../models/user-group/user-group.model";
import EffectUtility from "../../../utils/effect.utils";
import HttpUtility from "../../../utils/http.utils";
import UserModel from "../../../models/user/user.model";
import HttpErrorResponseModel from "../../../models/http-error-response.model";
import CreateUserGroupModel from "../../../models/user-group/create-user-group.model";

export default class UserGroupEffect {
  /**
   * Get all UserGroups via GET Request
   * @returns Promise UserGroupModel[]
   */
  static getAllUserGroups(): EffectType<UserGroupModel[]> {
    const endpoint = UserGroupEndpoints.overview;
    return EffectUtility.getToModel<UserGroupModel[]>(UserGroupModel, endpoint);
  }

  /**
   * Get one UserGroup via GET Request
   * @param groupTitle string
   * @returns Promise UserGroupModel
   */
  static getUserGroup(groupTitle: string): EffectType<UserGroupModel> {
    const endpoint = UserGroupEndpoints.detail(groupTitle);
    return new Promise<HttpErrorResponseModel | UserGroupModel>((resolve) => {
      EffectUtility.getToModel<UserGroupModel>(UserGroupModel, endpoint).then(
        async (userGroup) => {
          if (userGroup instanceof HttpErrorResponseModel) {
            resolve(userGroup);
            return;
          }

          const userGroupUsers = await UserGroupEffect.getUsersInUserGroup(
            userGroup.title,
          );
          if (userGroupUsers instanceof HttpErrorResponseModel) {
            resolve(userGroup);
            return;
          }

          userGroup.users = userGroupUsers;

          resolve(userGroup);
        },
      );
    });
  }

  /**
   * Create UserGroup via POST Request
   * @param data UserGroupCreateInterface
   * @returns Promise string (groupName)
   */
  static async createUserGroup(data: UserGroupCreateInterface) {
    const endpoint = UserGroupEndpoints.create;
    return await HttpUtility.post(endpoint, data);
  }

  /**
   * Edit UserGroup via POST Request
   * @param groupTitle string
   * @param data UserGroupEditInterface
   * @returns Promise UserGroupModel
   */
  static editUserGroup(
    groupTitle: string,
    data: UserGroupEditInterface,
  ): EffectType<CreateUserGroupModel> {
    const endpoint = UserGroupEndpoints.edit(groupTitle);
    return EffectUtility.postToModel<CreateUserGroupModel>(
      CreateUserGroupModel,
      endpoint,
      data,
    );
  }

  /**
   * Delete UserGroup via DELETE Request
   * @param groupTitle string
   * @returns Promise HttpRequest
   */
  static deleteUserGroup(groupTitle: string) {
    const endpoint = UserGroupEndpoints.delete(groupTitle);
    return HttpUtility.delete(endpoint);
  }

  /**
   * Get User(s) in UserGroup via GET Request
   * @param groupTitle string
   * @returns Promise UserModel[]
   */
  static getUsersInUserGroup(groupTitle: string): EffectType<UserModel[]> {
    const endpoint = UserGroupEndpoints.getUsers(groupTitle);
    return EffectUtility.getToModel<UserModel[]>(UserModel, endpoint);
  }

  /**
   * Add User(s) to UserGroup via POST Request
   * @param groupTitle string
   * @param data UsersInUserGroupEditInterface
   * @returns Promise UserModel[]
   */
  static addUserToUserGroup(
    groupTitle: string,
    data: UsersInUserGroupEditInterface,
  ): EffectType<UserModel[]> {
    const endpoint = UserGroupEndpoints.addUsers(groupTitle);
    return EffectUtility.postToModel<UserModel[]>(UserModel, endpoint, data);
  }

  /**
   * Remove User(s) from UserGroup via POST Request
   * @param groupTitle string
   * @param data UsersInUserGroupEditInterface
   * @returns Promise UserModel[]
   */
  static removeUserFromUserGroup(
    groupTitle: string,
    data: UsersInUserGroupEditInterface,
  ): EffectType<UserModel[]> {
    const endpoint = UserGroupEndpoints.removeUsers(groupTitle);
    return HttpUtility.post(endpoint, data);
  }

  /**
   * Update UserGroup via POST Request
   * @param apiKeyClientUid string
   * @param data ContentInUserGroupEdit
   * @returns Promise UserGroupModel[]
   */
  static updateUserGroup(
    apiKeyClientUid: string,
    data: ContentInUserGroupEdit,
  ): EffectType<UserGroupModel[]> {
    const endpoint = UserGroupEndpoints.update(apiKeyClientUid);
    return HttpUtility.post(endpoint, data);
  }
}
