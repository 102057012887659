import React, { useEffect, useRef, useState } from "react";
import { selectUserGroups } from "../../../core/redux/selectors/user-group/user-group.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Button, message, Modal } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import CreateImportInvitationForm, {
  CreateImportInvitationFormFields,
  CreateImportInvitationFormRef,
} from "../forms/create-import-invitation.form";
import { connect, useDispatch } from "react-redux";
import { CreateInvitationInterface } from "../../../core/interfaces/create-invitation.interface";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import InvitationAction from "../../../core/redux/stores/invitation/invitation.action";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import getErrorMessageInvitation from "../../../core/hooks/error-message-invitations";
import UserGroupsNotExistComponent from "../user-groups/user-groups-not-exist.component";

interface StateProps {
  groups: UserGroupModel[];
  //
  requestingGroups: boolean;
  requesting: boolean;
  error: HttpErrorResponseModel;
}

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps & StateProps;

const CreateImportInvitationModal = (props: Props) => {
  const [t] = useTranslation();
  const { visible, onCancel, groups, requesting, error, requestingGroups } =
    props;
  const createImportInvitationFormRef =
    useRef<CreateImportInvitationFormRef>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmittingGroups, setIsSubmittingGroups] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<CreateInvitationInterface[]>([]);
  const [groupsNotExists, setGroupsNotExists] = useState<string[]>([]);
  const [groupsToCreate, setGroupsToCreate] = useState<string[]>([]);
  const { errorMessage } = getErrorMessageInvitation(error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage && isSubmitting) {
      setIsSubmitting(false);
      createImportInvitationFormRef.current?.form.setFields([
        { name: "csvFileUpload", errors: [errorMessage] },
      ]);
      setIsDisabled(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (Object.keys(error).length > 0 && error.status !== 200) {
        const raw_errors = error.raw as unknown as any;
        createImportInvitationFormRef.current?.form.setFields([
          {
            name: "csvFileUpload",
            errors: [
              raw_errors.data.error.message?.join(",") ?? raw_errors.data.error,
            ],
          },
        ]);
        setIsDisabled(true);
      } else if (isSubmitting) {
        handleOnCancel();
      }
    }
  }, [requesting]);

  useEffect(() => {
    if (!requestingGroups && isSubmittingGroups) {
      setIsSubmittingGroups(false);
      if (Object.keys(error).length > 0 && error.status !== 200) {
        const raw_errors = error.raw as unknown as any;
        createImportInvitationFormRef.current?.form.setFields([
          {
            name: "csvFileUpload",
            errors: [
              raw_errors.data.error.message?.join(",") ?? raw_errors.data.error,
            ],
          },
        ]);
      } else {
        setGroupsNotExists([]);
        setGroupsToCreate([]);
        message.success(
          Capitalize(
            t("errors.warnings.succes_create-x", {
              container: t("containers.user-groups.key"),
            }),
          ),
        );
        handleOnCreateInvitations(csvData);
      }
    }
  }, [requestingGroups]);

  useEffect(() => {
    const newGroups: string[] = [];

    csvData.forEach((data) => {
      data.userGroups.filter((groupTitle) => {
        if (
          !groups.map((g) => g.title).includes(groupTitle) &&
          !newGroups.includes(groupTitle)
        ) {
          newGroups.push(groupTitle);
        }
      });
    });

    setIsDisabled(false);
    setGroupsNotExists(newGroups);
    setGroupsToCreate(newGroups);
  }, [csvData]);

  return (
    <Modal
      open={visible}
      title={
        Capitalize(t("common.import")) +
        " " +
        Capitalize(t("containers.invitations.key_plural"))
      }
      confirmLoading={requesting}
      width={"80rem"}
      onCancel={handleOnCancel}
      destroyOnClose
      footer={[
        <Button key={0} type={"text"} onClick={handleOnCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <Button
          key={1}
          type={"primary"}
          onClick={handleOnOk}
          loading={isSubmitting}
          disabled={requesting || isDisabled || csvData.length === 0}
        >
          {Capitalize(t("common.confirm"))}
        </Button>,
      ]}
    >
      <CreateImportInvitationForm
        ref={createImportInvitationFormRef}
        setCsvData={setCsvData}
      />

      {groupsNotExists.length > 0 && (
        <UserGroupsNotExistComponent
          groups={groupsNotExists}
          setGroupsToCreate={setGroupsToCreate}
        />
      )}
    </Modal>
  );

  function handleOnOk() {
    if (createImportInvitationFormRef.current) {
      createImportInvitationFormRef.current.form
        .validateFields()
        .then((res: CreateImportInvitationFormFields) => {
          if (groupsToCreate.length > 0) {
            groupsToCreate.forEach((group) => {
              dispatch(
                UserGroupAction.createUserGroup({
                  title: group
                    .trim()
                    .replace(/[^a-z0-9-_]/gi, "_")
                    .toLowerCase(),
                  description: group,
                  usernames: [],
                }),
              );
            });
            setIsSubmittingGroups(true);
          } else {
            handleOnCreateInvitations(res.csvFileUpload);
          }
        });
    }
  }

  function handleOnCreateInvitations(csvData: CreateInvitationInterface[]) {
    dispatch(InvitationAction.createInvitations(csvData));
    setIsSubmitting(true);
  }

  function handleOnCancel() {
    onCancel();
    if (createImportInvitationFormRef.current) {
      createImportInvitationFormRef.current.form.resetFields();
    }
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  groups: selectUserGroups(state),
  requesting: selectRequesting(state, [
    InvitationAction.REQUEST_CREATE_INVITATION,
  ]),
  error: selectRawErrors(state, [
    InvitationAction.REQUEST_CREATE_INVITATION_FINISHED,
    UserGroupAction.REQUEST_CREATE_USER_GROUP_FINISHED,
  ]),
  requestingGroups: selectRequesting(state, [
    UserGroupAction.REQUEST_USER_GROUPS,
  ]),
});

export default connect(mapStateToProps)(CreateImportInvitationModal);
