import { Dispatch } from "redux";
import { ThunkType } from "../../../types/redux.type";
import { SourceCreateEditInterface } from "../../../interfaces/source-create-edit.interface";
import ActionUtility from "../../../utils/action.utils";
import SourceModel from "../../../models/source/source.model";
import SourceEffect from "./source.effect";
import ContentTypesEnum from "../../../enums/content-types.enum";
import ContentAction from "../content/content.action";

export default class SourceAction {
  // Get Sources
  static REQUEST_SOURCES = "SourceAction.REQUEST_SOURCES";
  static REQUEST_SOURCES_FINISHED = "SourceAction.REQUEST_SOURCES_FINISHED";

  // Get Source
  static REQUEST_SOURCE = "SourceAction.REQUEST_SOURCE";
  static REQUEST_SOURCE_FINISHED = "SourceAction.REQUEST_SOURCE_FINISHED";

  // Create Source
  static REQUEST_CREATE_SOURCE = "SourceAction.REQUEST_CREATE_SOURCE";

  // Edit Sources
  static REQUEST_EDIT_SOURCE = "SourceAction.REQUEST_EDIT_SOURCE";

  // Delete Sources
  static REQUEST_DELETE_SOURCE = "SourceAction.REQUEST_DELETE_SOURCE";

  /**
   * Get all Sources
   * @returns Dispatch SourceEffect.getAllSources
   */
  static getAllSources(): ThunkType<SourceModel[]> {
    return ActionUtility.createThunk<SourceModel[]>(
      SourceAction.REQUEST_SOURCES,
      SourceEffect.getAllSources,
    );
  }

  /**
   * Get one Source
   * @param sourceUid string
   * @returns Dispatch SourceEffect.getSource
   */
  static getSource(sourceUid: string): ThunkType<SourceModel> {
    return ActionUtility.createThunk<SourceModel>(
      SourceAction.REQUEST_SOURCE,
      SourceEffect.getSource,
      sourceUid,
    );
  }

  /**
   * Create Source
   * @param data SourceCreateEditInterface
   * @returns Dispatch SourceEffect.createSource
   */
  static createSource(data: SourceCreateEditInterface) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<SourceModel>(
          SourceAction.REQUEST_CREATE_SOURCE,
          SourceEffect.createSource,
          data,
        ),
      );

      await dispatch(
        ContentAction.getContentDetail(ContentTypesEnum.CARD, data.cardUid),
      );
    };
  }

  /**
   * Edit Source
   * @param source SourceModel
   * @param data SourceCreateEditInterface
   * @returns Dispatch SourceEffect.editSource
   */
  static editSource(source: SourceModel, data: SourceCreateEditInterface) {
    return ActionUtility.createThunk<SourceModel>(
      SourceAction.REQUEST_EDIT_SOURCE,
      SourceEffect.editSource,
      source,
      data,
    );
  }

  /**
   * Delete Source
   * @param source SourceModel
   * @returns Dispatch SourceEffect.deleteSource
   */
  static deleteSource(source: SourceModel) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<SourceModel>(
          SourceAction.REQUEST_DELETE_SOURCE,
          SourceEffect.deleteSource,
          source,
        ),
      );

      await dispatch(
        ContentAction.getContentDetail(ContentTypesEnum.CARD, source.cardUid),
      );
    };
  }
}
