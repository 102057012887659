import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UserAction from "../redux/stores/user/user.action";
import UserGroupAction from "../redux/stores/user-group/user-group.action";
import NotificationAction from "../redux/stores/notification/notification.action";
import InvitationAction from "../redux/stores/invitation/invitation.action";
import LearningPathAction from "../redux/stores/learning-path/learning-path.action";

const useInitialDataFetch = (canFetch: boolean) => {
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const loadBigResourcesFirst = () =>
    Promise.all([dispatch(UserAction.getAllUsers())]);

  const loadResourcesInBackground = () =>
    Promise.all([
      dispatch(UserGroupAction.getAllUserGroups()),
      dispatch(NotificationAction.getCountScheduledNotifications()),
      dispatch(InvitationAction.getAllInvitations()),
      dispatch(LearningPathAction.getAllLearningPaths()),
    ]);

  useEffect(() => {
    if (!canFetch) {
      return;
    }

    setIsFetching(true);

    loadBigResourcesFirst().then(async () => {
      setIsFetching(false);
      await loadResourcesInBackground();
    });
  }, [canFetch]);

  return isFetching;
};

export default useInitialDataFetch;
