import axios, { AxiosRequestConfig } from "axios";
import HttpErrorResponseModel from "../models/http-error-response.model";
import getToken from "./getToken.utils";

export enum RequestMethodsEnum {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
  PATCH = "PATCH",
}

export default class HttpUtility {
  static async get(endpoint: string, params?: any, requestConfig?: any) {
    const paramsConfig = params ? { params } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethodsEnum.GET,
      },
      {
        ...paramsConfig,
        ...requestConfig,
      },
    );
  }

  static async post(endpoint: string, data: any) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethodsEnum.POST,
      },
      config,
    );
  }

  static async put(endpoint: string, data: any) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethodsEnum.PUT,
      },
      config,
    );
  }

  static async patch(endpoint: string, data: any) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethodsEnum.PATCH,
      },
      config,
    );
  }

  static async delete(endpoint: string, data?: any) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethodsEnum.DELETE,
      },
      config,
    );
  }

  static async _request(
    restRequest: { url: string; method: RequestMethodsEnum },
    config?: AxiosRequestConfig,
  ) {
    if (!restRequest.url) {
      console.error(
        `Received ${restRequest.url} which is invalid for a endpoint url`,
      );
    }

    try {
      const axiosRequestConfig: AxiosRequestConfig = {
        ...config,
        method: restRequest.method,
        url: restRequest.url,
        headers: {
          "Content-Type": "application/json",
          ...config?.headers,
        },
      };

      // TODO Take another look at this method of getting and setting the access_token
      const accessToken = await getToken();
      if (accessToken) {
        axiosRequestConfig.headers = {
          ...axiosRequestConfig.headers,
          Authorization: "Bearer " + accessToken,
        };
      } else {
        throw new Error("no access token");
      }

      const [axiosResponse]: any = await Promise.all([
        axios.request(axiosRequestConfig),
        HttpUtility._delay(),
      ]);

      const { status, data, request } = axiosResponse;

      if (data.success === false) {
        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: data.errors.join(" - "),
            errors: data.errors,
            url: request ? request.responseURL : restRequest.url,
            raw: axiosResponse,
          },
          restRequest,
        );
      }

      return {
        ...axiosResponse,
      };
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const { status, statusText, data } = error.response;
        const errors = Object.prototype.hasOwnProperty.call(data, "errors")
          ? data.errors
          : [statusText];

        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            errors,
            url: error.request.responseURL,
            raw: error.response,
          },
          restRequest,
        );
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        const { status, statusText, responseURL } = error.request;

        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: statusText,
            errors: [statusText],
            url: responseURL,
            raw: error.request,
          },
          restRequest,
        );
      }
      // Something happened in setting up the request that triggered an Error
      return HttpUtility._fillInErrorWithDefaults(
        {
          status: 0,
          message: error.message,
          errors: [error.message],
          url: restRequest.url,
          raw: error,
        },
        restRequest,
      );
    }
  }

  static _fillInErrorWithDefaults(error: any, request: any) {
    const model = new HttpErrorResponseModel();

    model.status = error.status || 0;
    model.message = error.message || "Error requesting data";
    model.errors = error.errors;
    model.url = error.url || request.url;
    model.raw = error.raw;

    return model;
  }

  /**
   * We want to show the loading indicator to the user but sometimes the api
   * request finished too quickly. This makes sure there the loading indicator is
   * visual for at least a given time.
   *
   * @param duration
   * @returns {Promise<unknown>}
   * @private
   */
  static _delay(duration = 250) {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }
}
