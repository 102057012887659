import LearningPathEndpoints from "../../api/learning-path.endpoints";
import EffectUtility from "../../utils/effect.utils";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import ContentLearningPathModel from "../../models/content/content-learning-path.model";
import ContentType from "../../types/content.type";
import ContentTypesEnum from "../../enums/content-types.enum";
import LearningPathUserGroupModel from "../../models/learning-path/learning-path-get-user-group.model";

export async function getLearningPathContent(contentUid: string) {
  const endpoint = LearningPathEndpoints.contentLearningPath([contentUid]);
  const response = await EffectUtility.getToModel<ContentLearningPathModel[]>(
    ContentLearningPathModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getLearningPathContentForContentType(
  content: ContentType | { playbookUid: string; contentType: string },
) {
  if (content.contentType !== ContentTypesEnum.PLAYBOOK) {
    // we don't need to check this when content is not a Playbook
    return;
  }

  return await getLearningPathContent(content.playbookUid);
}

export async function getUserGroupFromLearningPath(learningPathUid: string) {
  const endpoint =
    LearningPathEndpoints.getUserGroupsFromLearningPath(learningPathUid);
  const response = await EffectUtility.getToModel<LearningPathUserGroupModel[]>(
    LearningPathUserGroupModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
