import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import { Alert } from "antd";
import LoginFormComponent from "../../components/auth/login-form.component";
import Logo from "../../components/logo/logo.component";
import Space from "antd/lib/space";
import styles from "../../components/auth/login.module.css";

const LoginScreen = () => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const {
    location: { hash: hash },
  } = useHistory();

  useEffect(() => {
    if (!showConfirmation && hash === "#confirmed") {
      setShowConfirmation(true);
    }
  }, []);

  return (
    <>
      {showConfirmation && (
        <Alert
          type={"success"}
          closable
          message={t("screens.auth.change-password.confirmation")}
          onClose={() => setShowConfirmation(false)}
        />
      )}

      <Space size={"middle"} direction={"vertical"} style={{ width: "100%" }}>
        <Logo className={styles.login_logo} width={218} height={66} />
        <h2 className={styles.login_title}>
          {Capitalize(t("common.welcome")) + "!"}
        </h2>
        <LoginFormComponent />
      </Space>
    </>
  );
};

export default LoginScreen;
