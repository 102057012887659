import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { useSelector } from "react-redux";
import { Empty, Spin, Typography } from "antd";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import { selectCollectionsByChapter } from "../../../core/redux/selectors/content/content.selector";
import DragDropContextComponent from "../draggable/content/drag-drop-context.component";
import PlaybookDetailModel from "../../../core/models/content/playbook-detail.model";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import CollectionDetailModel from "../../../core/models/content/collection-detail.model";
import ContentSidebarItemComponent from "./content-sidebar-item.component";
import CreateContentButtonConnected from "../content-create-button/create-content-button.connected";
import styles from "./content-sidebar.module.css";
import ContentSidebarHeader from "./content-sidebar-header";
import ContentSortEnum from "../../../core/enums/content-sort.enum";
import sortPlaybooks from "../../../core/utils/sort-playbooks.utils";
import ContentFilterEnum from "../../../core/enums/content-filter.enum";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import createPlaybookEditModeSections from "../../../core/utils/create-playbook-edit-mode-sections";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import ContentAction from "../../../core/redux/stores/content/content.action";

interface Props {
  playbookDetails: PlaybookDetailModel[];
}

const ContentSidebarComponent = (props: Props) => {
  const { playbookDetails } = props;
  const { selectedChapter } = useContentSidebar();
  const { t } = useTranslation();
  const collections: CollectionDetailModel[] | undefined = useSelector(
    (state: StoreInterface) =>
      selectCollectionsByChapter(state, selectedChapter),
  );
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [sidebarWidth, setSidebarWidth] = useState<number>(275);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState(ContentSortEnum.CUSTOM);
  const [filter, setFilter] = useState(ContentFilterEnum.ALL);
  const [filteredPlaybooks, setFilteredPlaybooks] = useState(playbookDetails);
  const startResizing = useCallback(() => setIsResizing(true), []);
  const stopResizing = useCallback(() => setIsResizing(false), []);
  const [sections, setSections] =
    useState<{ title: string; data: PlaybookDetailModel[] }[]>();

  const isFetchingPlaybooks = useSelector((state) =>
    selectRequesting(state, [ContentAction.REQUEST_PLAYBOOKS]),
  );

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing && sidebarRef.current) {
        const newSidebarWidth =
          mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left;
        setSidebarWidth(newSidebarWidth);
      }
    },
    [isResizing],
  );

  useEffect(() => {
    const searchedPlaybooks =
      searchTerm === ""
        ? playbookDetails
        : playbookDetails.filter((playbook) =>
            playbook.title.toLowerCase().includes(searchTerm.toLowerCase()),
          );

    const filteredPlaybooks = searchedPlaybooks.filter((playbook) => {
      switch (filter) {
        case ContentFilterEnum.ALL:
          return true;
        case ContentFilterEnum.PUBLISHED:
          return playbook.contentState === ContentStatesEnum.PUBLISHED;
        case ContentFilterEnum.DRAFT:
          return playbook.contentState === ContentStatesEnum.DRAFT;
      }
    });

    setFilteredPlaybooks(filteredPlaybooks);
  }, [playbookDetails, searchTerm, filter]);

  useEffect(() => {
    setSections(
      createPlaybookEditModeSections(filteredPlaybooks, sortOption, {
        labels: {
          thisWeek: t("translations:common.period.THIS_WEEK"),
          lastMonth: t("translations:common.period.LAST_MONTH"),
          lastHalfYear: t("translations:common.period.PAST_HALF_YEAR"),
          other: t("translations:common.period.OLDER_THEN_X_MONTHS", {
            value: 6,
          }),
        },
      }),
    );
  }, [filteredPlaybooks, sortOption]);

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <div className={styles.container}>
      <div
        ref={sidebarRef}
        className={styles.app_sidebar}
        style={{ width: sidebarWidth }}
      >
        <div className={styles.container_column}>
          <ContentSidebarHeader
            searchTerm={searchTerm}
            onSearch={setSearchTerm}
            sortOption={sortOption}
            onSortSelect={setSortOption}
            filter={filter}
            onFilterChange={setFilter}
          />

          {playbookDetails.length === 0 && isFetchingPlaybooks && (
            <div style={{ textAlign: "center" }}>
              <Spin size={"large"} />
            </div>
          )}
          <DragDropContextComponent contentDetails={playbookDetails}>
            <>
              {filteredPlaybooks &&
                sections &&
                sortOption !== ContentSortEnum.CUSTOM && (
                  <ContentSidebarSectionList
                    sections={sections}
                    renderItem={(playbook, index) => (
                      <ContentSidebarItemComponent
                        index={index}
                        content={playbook}
                        enableDragAndDrop={false}
                      />
                    )}
                  />
                )}

              {filteredPlaybooks &&
                sortOption === ContentSortEnum.CUSTOM &&
                filteredPlaybooks
                  .sort((a, b) => sortPlaybooks(a, b, sortOption))
                  .map((playbook: PlaybookDetailModel, index: number) => (
                    <ContentSidebarItemComponent
                      key={index}
                      index={index}
                      content={playbook}
                      enableDragAndDrop={
                        filter === ContentFilterEnum.ALL && searchTerm === ""
                      }
                    />
                  ))}

              {searchTerm !== "" && filteredPlaybooks.length === 0 && (
                <p style={{ color: "white" }}>
                  {t("translations:errors.no-results", { searchTerm })}
                </p>
              )}
            </>
          </DragDropContextComponent>
        </div>

        <div
          className={styles.app_sidebar_resizer}
          onClick={resizeOnClick}
          onMouseDown={startResizing}
        />
      </div>

      <div className={styles.container_column}>
        {!selectedChapter ? (
          <Empty />
        ) : (
          <>
            <DragDropContextComponent contentDetails={collections}>
              <>
                {collections &&
                  collections.map(
                    (collection: CollectionDetailModel, index: number) => (
                      <ContentSidebarItemComponent
                        key={index}
                        index={index}
                        content={collection}
                      />
                    ),
                  )}
              </>
            </DragDropContextComponent>

            <div style={{ marginLeft: "2rem", marginRight: "2rem" }}>
              <CreateContentButtonConnected
                contentType={ContentTypesEnum.COLLECTION}
                parentContent={selectedChapter}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );

  function resizeOnClick() {
    if (!sidebarRef.current) {
      return;
    }

    if (sidebarWidth >= 270 / 2) {
      const newSidebarWidth =
        20 - sidebarRef.current.getBoundingClientRect().left;
      setSidebarWidth(newSidebarWidth);
    }

    if (sidebarWidth < 270 / 2) {
      const newSidebarWidth =
        275 - sidebarRef.current.getBoundingClientRect().left;
      setSidebarWidth(newSidebarWidth);
    }
  }
};

type SectionListProps<Type extends Record<string, any>> = {
  sections: { title: string; data: Type[] }[];
  renderItem: (item: Type, index: number) => ReactNode;
};

const ContentSidebarSectionList = <Type extends Record<string, any>>({
  sections,
  renderItem,
}: SectionListProps<Type>) => {
  return (
    <div>
      {sections.map((section, index) => (
        <div key={index} style={{ marginBottom: 8 }}>
          <Typography.Text style={{ color: "white", fontWeight: "bold" }}>
            {section.title}
          </Typography.Text>
          <div style={{ marginTop: 6 }}>
            {section.data.map((item, index) => (
              <div key={index}>{renderItem(item, index)}</div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContentSidebarComponent;
