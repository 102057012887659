import React from "react";
import { Form, Typography } from "antd";
import ImageUploadComponent from "../../media-upload/image-upload.component";
import { CreatePictureQuizCardOptionInterface } from "../../../../core/models/create-content/card-types/create-picture-quiz-card.model";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import PictureQuizOption from "./picture-quiz-option";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";

interface Props {
  options: CreatePictureQuizCardOptionInterface[];
  setOptions: (options: CreatePictureQuizCardOptionInterface[]) => void;
  maxAmount?: number;
}

const PictureQuizOptionsField = ({
  options,
  setOptions,
  maxAmount = 4,
}: Props) => {
  const { t } = useTranslation();
  const handleSetOption = (option: CreatePictureQuizCardOptionInterface) => {
    const _options = [...options];

    if (option.isCorrect) {
      _options.forEach((option) => (option.isCorrect = false));
    }

    const index = _options.findIndex((o) => o.sort === option.sort);
    if (index === -1) {
      _options.push(option);
    } else {
      _options[index] = option;
    }

    _options.sort((a, b) => {
      return a.sort - b.sort;
    });

    setOptions(_options);
  };

  const handleRemoveOption = (index: number) => {
    const _options = [...options];
    _options.splice(index, 1);
    _options.forEach((option, index) => (option.sort = index));
    _options.sort((a, b) => {
      return a.sort - b.sort;
    });
    setOptions(_options);
  };

  const reorder = <Type extends Record<string, any>>(
    list: Array<Type>,
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      [...options],
      result.source.index,
      result.destination.index,
    );

    items.forEach((option, index) => (option.sort = index));

    setOptions(items);
  };

  const getItemStyle: (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle,
  ) => React.CSSProperties = (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle,
  ) => ({
    borderRadius: 6,
    overflow: "hidden",
    background: "white",
    border: isDragging ? `1px solid #d9d9d9` : undefined,
    marginBottom: 10,
    ...draggableStyle,
  });

  return (
    <Form.Item
      name={"answersOptions"}
      label={Capitalize(t("translations:common.answer-options"))}
    >
      <div style={{ width: "80%" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {options.map((option, index) => (
                  <Draggable
                    key={index}
                    draggableId={option.sort.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <PictureQuizOption
                          option={option}
                          setOption={handleSetOption}
                          onDelete={() => handleRemoveOption(index)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {options.length !== maxAmount && (
          <div key={options.length} style={{ marginTop: "3rem" }}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              {t("translations:form.card.picture-quiz.new-option")}
            </Typography.Text>
            <div style={{ paddingTop: "1rem" }}>
              <ImageUploadComponent
                aspectRatio={1}
                onUpload={(mediaUid) => {
                  handleSetOption({
                    mediaUid,
                    sort: options.length,
                    isCorrect: options.length === 0,
                  });
                }}
                media={undefined}
              />
            </div>
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default PictureQuizOptionsField;
