import React from "react";
import {
  AppstoreOutlined,
  BulbOutlined,
  CommentOutlined,
  ControlOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { AntdIconProps } from "./content-icon.component";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import styles from "../content/content.module.css";

interface OwnProps {
  cardType: CardTypesEnum | "start" | "end";
}

type Props = OwnProps & AntdIconProps;

const CardTypeIconComponent = (props: Props) => {
  const { cardType, ...iconProps } = props;

  const style = {
    ...iconProps.style,
    color: iconProps.iconcolor,
  };

  switch (cardType) {
    case "start":
      return (
        <VerticalAlignBottomOutlined
          className={styles.card_summary_icon}
          {...iconProps}
          style={style}
        />
      );
    case CardTypesEnum.KNOWLEDGE_CARD:
      return (
        <BulbOutlined
          className={styles.card_type_icon}
          {...iconProps}
          style={style}
        />
      );
    case CardTypesEnum.QUESTION:
      return (
        <CommentOutlined
          className={styles.card_type_icon}
          {...iconProps}
          style={style}
        />
      );
    case CardTypesEnum.QUIZ:
      return (
        <QuestionCircleOutlined
          className={styles.card_type_icon}
          {...iconProps}
          style={style}
        />
      );
    case CardTypesEnum.SLIDER:
      return (
        <ControlOutlined
          className={styles.card_type_icon}
          {...iconProps}
          style={style}
          rotate={90}
        />
      );
    case CardTypesEnum.MULTIPLE_CHOICE:
      return (
        <UnorderedListOutlined
          className={styles.card_type_icon}
          {...iconProps}
          style={style}
        />
      );
    case CardTypesEnum.PICTURE_QUIZ:
      return (
        <AppstoreOutlined
          className={styles.card_type_icon}
          {...iconProps}
          style={style}
        />
      );
    case "end":
      return (
        <VerticalAlignTopOutlined
          className={styles.card_summary_icon}
          {...iconProps}
          style={style}
        />
      );
  }
};

export default CardTypeIconComponent;
