import React, { useEffect, useState } from "react";
import { ContentActionProps } from "../content-action/content-action.component";
import { grey } from "@ant-design/colors";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import CollectionSummaryModel from "../../../core/models/content/collection-summary.model";
import CardTypeIconComponent from "../icons/card-type-icon.component";
import SummaryCardPreviewComponent from "../card-types/summary-card/summary-card-preview.component";
import SummaryTypesEnum from "../../../core/enums/summary-types.enum";
import CardBoilerplate from "./card.boilerplate";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import EditSummaryCardForm from "../card-types/summary-card/edit-summary-card.form";
import styles from "../content/content.module.css";
import colors from "../../styles/colors";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import ContentAction from "../../../core/redux/stores/content/content.action";

interface Props {
  summary: CollectionSummaryModel;
}

const SummaryCardComponent = ({ summary }: Props) => {
  const [t] = useTranslation();
  const { onClick, isSelected } = useContentSidebar();
  const [editing, setEditing] = useState<boolean>(false);
  const requesting = useSelector((state: StoreInterface) =>
    selectRequesting(state, [ContentAction.REQUEST_UPDATE_CONTENT]),
  );
  //
  const className = isSelected(summary.getId)
    ? styles.content_container_selected
    : styles.content_container;
  const hoverBorderColor = {
    "--hover-card-border-color": grey[5],
  } as React.CSSProperties;
  const hoverCardTypeColor = {
    "--hover-card-type-color": grey[5],
  } as React.CSSProperties;
  const cardTypeColor =
    isSelected(summary.getId) &&
    ({ "--card-type-color": grey[5] } as React.CSSProperties);

  useEffect(() => {
    if (!editing) return;
    if (requesting) handleHideForm();
  }, [editing, requesting]);

  function handleHideForm() {
    setEditing(false);
  }

  return (
    <CardBoilerplate
      className={className}
      style={hoverBorderColor}
      shouldEdit={false}
      title={TextDecodeUtils(summary.title)}
      titleIcon={
        summary.contentState === ContentStatesEnum.PUBLISHED ? (
          <CheckCircleFilled style={{ color: colors.primary }} />
        ) : (
          <CheckCircleOutlined />
        )
      }
      icon={getIcon()}
      detail={getDetail()}
      onClick={() => onClick(summary.getId)}
      actions={getActions()}
    />
  );

  function getIcon() {
    const cardType =
      summary.summaryType === SummaryTypesEnum.END ? "end" : "start";
    return (
      <CardTypeIconComponent
        cardType={cardType}
        style={{ ...cardTypeColor, ...hoverCardTypeColor }}
      />
    );
  }

  function getDetail() {
    if (editing) {
      return <EditSummaryCardForm summary={summary} />;
    } else {
      return <SummaryCardPreviewComponent summaryCard={summary} />;
    }
  }

  function getActions(): ContentActionProps[] {
    if (editing) {
      return [];
    }

    return [
      {
        icon: <ContextActionIconComponent action={"edit"} />,
        label: Capitalize(t("common.edit")),
        onClick: () => setEditing(true),
      },
    ];
  }
};

export default SummaryCardComponent;
