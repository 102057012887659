import React from "react";
import { ContentProgressEnum } from "../../../../core/enums/content-progress.enum";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Divider, Spin, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useUserProgress } from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import SectionTitleComponent from "../../section-title/section-title.component";
import GradientProgressDonutComponent, {
  ContentProgressInterface,
} from "../../graphs/gradient-progress-donut.component";
import { selectUserProgressFromUserGroupUsers } from "../../../../core/redux/selectors/user-progress/user-progress";
import ContentProgressLegend from "../../content-user-progress/content-progress.legend";
import Empty from "../../empty/empty";
import graphStyles from "../../graphs/graph-container/graph-container.module.css";
import styles from "../../activity/activity.module.css";

const CollectionDetailGraphsComponent = () => {
  const { t } = useTranslation();
  const params = useParams<{
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
  }>();
  const { data: collectionProgress, isFetching: isFetchCollection } =
    useUserProgress(
      params.playbookUid,
      params.chapterUid,
      params.collectionUid,
    );

  const userProgressFromUserGroupUsers =
    selectUserProgressFromUserGroupUsers(collectionProgress);

  const filteredActiveUsers =
    userProgressFromUserGroupUsers.length > 0
      ? userProgressFromUserGroupUsers
      : collectionProgress
        ? collectionProgress.users
        : [];

  const history = useHistory();

  return (
    <>
      <Divider />

      <SectionTitleComponent title={t("screens.activity.statistics")} />

      <div className={styles.container}>
        <div className={styles.item}>
          <div
            className={graphStyles.container}
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexFlow: "column", width: "100%" }}>
              <Typography.Text className={graphStyles.title}>
                {Capitalize(t("content.collection.key")) +
                  " " +
                  t("containers.user-progress.key")}
              </Typography.Text>
              <Typography.Text
                type={"secondary"}
                style={{ marginLeft: ".6rem" }}
              >
                {Capitalize(t("common.period.ALL_TIME"))}
              </Typography.Text>
            </div>
            {renderCollectionProgress()}
            <ContentProgressLegend
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        </div>

        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
      </div>
    </>
  );

  function renderCollectionProgress() {
    if (!collectionProgress) {
      return (
        <div style={{ height: 201 }} className={graphStyles.container_spinner}>
          <div className={graphStyles.container_spinner_item}>
            <Empty image={"none_found"} message={""} />
          </div>
        </div>
      );
    }

    if (isFetchCollection) {
      return (
        <div style={{ height: 201 }} className={graphStyles.container_spinner}>
          <div className={graphStyles.container_spinner_item}>
            <Spin />
          </div>
        </div>
      );
    }

    const data: ContentProgressInterface[] = [
      {
        state: ContentProgressEnum.PENDING,
        amount: filteredActiveUsers.filter(
          (u) => u.state === ContentProgressEnum.PENDING,
        ).length,
      },
      {
        state: ContentProgressEnum.STARTED,
        amount: filteredActiveUsers.filter(
          (u) => u.state === ContentProgressEnum.STARTED,
        ).length,
      },
      {
        state: ContentProgressEnum.COMPLETED,
        amount: filteredActiveUsers.filter(
          (u) => u.state === ContentProgressEnum.COMPLETED,
        ).length,
      },
    ];

    return (
      <div style={{ marginTop: "-1.5rem" }}>
        <GradientProgressDonutComponent
          width={210}
          height={210}
          donutThickness={22}
          data={data}
          fontSize={20}
          onClick={(state) => {
            const path = RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_COLLECTION;
            history.push(
              `${path
                .replace(":playbookUid", params.playbookUid)
                .replace(":chapterUid", params.chapterUid)
                .replace(
                  ":collectionUid",
                  params.collectionUid,
                )}?state=${state}`,
            );
          }}
        />
      </div>
    );
  }
};

export default CollectionDetailGraphsComponent;
