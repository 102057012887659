import React from "react";
import { Tag, Typography } from "antd";
import { cyan, gold } from "@ant-design/colors";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import ProgressMetaIconComponent from "../../icons/progress-meta-icon.component";

interface OwnProps {
  type:
    | "correct"
    | "incorrect"
    | "popular"
    | "question"
    | "viewed"
    | "not-seen"
    | "no-answer"
    | "incorrect-clock";
  label: string;
}

type Props = OwnProps;

const CardTag = ({ type, label }: Props) => {
  let icon;
  let color;
  let backgroundColor;

  switch (type) {
    case "correct":
      color = "#237804";
      backgroundColor = "#D9F7BE";
      icon = <CheckOutlined style={{ color }} />;
      break;
    case "incorrect":
      color = "#820014";
      backgroundColor = "#FFCCC7";
      icon = <CloseOutlined style={{ color }} />;
      break;
    case "popular":
      color = "#9E1068";
      backgroundColor = "#FFF0F6";
      icon = <RiseOutlined style={{ color }} />;
      break;
    case "question":
      color = gold[8];
      backgroundColor = gold[1];
      icon = <CheckOutlined style={{ color }} />;
      break;
    case "viewed":
      color = cyan[8];
      backgroundColor = cyan[1];
      icon = <ProgressMetaIconComponent icon={"views"} style={{ color }} />;
      break;
    case "not-seen":
      color = "#434343";
      backgroundColor = "var(--grey200-color)";
      icon = <ExclamationCircleOutlined style={{ color }} />;
      break;
    case "no-answer":
      color = "#FAFAFA";
      backgroundColor = "#434343";
      icon = <ExclamationCircleOutlined style={{ color }} />;
      break;
    case "incorrect-clock":
      color = "#820014";
      backgroundColor = "#FFCCC7";
      icon = <ClockCircleOutlined style={{ color }} />;
      break;
  }

  return (
    <Tag color={backgroundColor} style={{ borderRadius: "6rem" }} icon={icon}>
      <Typography.Text style={{ color }}>{label}</Typography.Text>
    </Tag>
  );
};

export default CardTag;
