import { useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../user-group/user-group.selector";
import UserModel from "../../../models/user/user.model";
import { selectUsersInUserGroup } from "../user/user.selector";

export const selectUserProgressFromUserGroupUsers = (data) => {
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );
  const usersInUserGroup: UserModel[] = useSelector((state) =>
    selectUsersInUserGroup(state, currentlySelectedUserGroup),
  );
  const newProgress: any = [];

  usersInUserGroup.map((user) => {
    return data?.users.filter((u) => {
      if (u.userUid === user.sub) newProgress.push(u);
    });
  });

  return newProgress;
};
