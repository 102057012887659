import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { RoutesEnum } from "./constants/routes/app.route";
import { useAuth } from "./core/providers/auth.provider";
import { Col, Layout, Row } from "antd";
import "./views/styles/App.less";
import AppLoader from "./views/components/auth/app-loader";
import useInitialDataFetch from "./core/hooks/use-initial-data-fetch";
import Body from "./views/components/body/body";
import UnauthenticatedBody from "./views/components/body/unauthenticated-body";
import Logo from "./views/components/logo/logo.component";
import MenuBarComponent from "./views/components/menu-bar.component";
import CurrentUserMenuItem from "./views/components/current-user-menu-item/current-user-menu-item";
import { selectCurrentUserRole } from "./core/redux/selectors/user/user.selector";
import { useSelector } from "react-redux";
import UserRoleEnum from "./core/enums/user-role.enum";
import { useGetFeatureFlags } from "./core/api/primio/primioComponents";

function App() {
  const { isAuth } = useAuth();
  const currentUserRole: UserRoleEnum | undefined = useSelector(
    selectCurrentUserRole,
  );
  const history = useHistory();
  const isFetching = useInitialDataFetch(isAuth);
  const { data: featureFlags, isLoading: isFetchingFeatureFlags } =
    useGetFeatureFlags({}, { enabled: isAuth });

  useEffect(() => {
    if (
      !isAuth &&
      history.location.pathname !== RoutesEnum.FORGET_PASSWORD &&
      history.location.pathname !== RoutesEnum.CHANGE_PASSWORD
    ) {
      history.push(RoutesEnum.LOGIN);
    }

    if (isAuth && history.location.pathname === RoutesEnum.LOGIN) {
      history.push(RoutesEnum.HOME);
    }
  }, [isAuth]);

  if (isFetching) {
    return <AppLoader />;
  }

  if (isAuth && isFetchingFeatureFlags) {
    return <AppLoader />;
  }
  if (isAuth && !featureFlags) {
    return null;
  }

  if (!isAuth) {
    return <UnauthenticatedBody />;
  }

  if (!currentUserRole) {
    return <AppLoader />;
  }

  return (
    <Layout className={"App"}>
      <Layout.Header className={"Header"}>
        <Row wrap={false}>
          <Col>
            <NavLink exact={true} to={RoutesEnum.HOME}>
              <Logo width={109} height={33} />
            </NavLink>
          </Col>
          <div style={{ flex: 1 }} />
          <MenuBarComponent />
          <div style={{ flex: 1 }} />
          <Col>
            <CurrentUserMenuItem />
          </Col>
        </Row>
      </Layout.Header>
      <Body />
    </Layout>
  );
}

export default App;
