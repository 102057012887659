import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { VideoProvidersEnum } from "../../../core/enums/media-providers.enum";
import MediaModel from "../../../core/models/media/media.model";
import styles from "../media-types/media.module.css";

type Props = {
  media: MediaModel | undefined;
  onSubmit: (
    videoId: string,
    provider: VideoProvidersEnum,
    isValid: boolean,
  ) => void;
};

const VimeoUploadComponent = (props: Props) => {
  const [t] = useTranslation();
  const { media, onSubmit } = props;
  const [form] = useForm();
  const [url, setUrl] = useState<string>(
    media?.mediaUid ? `https://vimeo.com/${media.uri}` : "",
  );

  useEffect(() => {
    form.setFields([
      {
        name: "url",
        value: url,
      },
    ]);

    validateAndSubmit();
  }, [url]);

  async function validateAndSubmit() {
    if (!url.includes("/")) {
      form.setFields([
        {
          name: "url",
          errors: [t("errors.third-party.valid-url", { value: "Vimeo" })],
        },
      ]);
      return onSubmit("", VideoProvidersEnum.VIMEO, false);
    }

    const urlParts = url.split("/");
    const videoId = urlParts[urlParts.length - 1];
    const isValid = await form.validateFields();

    onSubmit(videoId, VideoProvidersEnum.VIMEO, isValid);
  }

  return (
    <Form form={form} className={styles.upload_wrapper_vimeo} component={false}>
      <Form.Item
        name={"url"}
        style={{ paddingTop: "1.5rem" }}
        label={"url"}
        rules={getRules()}
      >
        <Input
          type={"text"}
          value={url}
          style={{ width: "68%" }}
          placeholder={"https://vimeo.com/615865811"}
          onChange={(e) => setUrl(e.target.value)}
        />
      </Form.Item>
    </Form>
  );

  function getRules() {
    return [
      {
        required: true,
        message: Capitalize(t("errors.required", { item: "url" })),
      },
      {
        pattern: new RegExp("vimeo.com/"),
        message: t("errors.third-party.valid-url", { value: "Vimeo" }),
      },
    ];
  }
};

export default VimeoUploadComponent;
