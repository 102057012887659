export interface ContentInteractionUserResponse {
  firstActiveOn: string;
  lastActiveOn: string;
}

export interface ContentInteractionResponse {
  [contentUid: string]: {
    [userUid: string]: ContentInteractionUserResponse;
  };
}

export class ContentInteractionModel {
  userUid: string;
  firstActiveOn: Date;
  lastActiveOn: Date;

  constructor(userUid: string, response: ContentInteractionUserResponse) {
    this.userUid = userUid;
    this.firstActiveOn = new Date(response.firstActiveOn);
    this.lastActiveOn = new Date(response.lastActiveOn);
  }
}
