import BaseContentModel, { BaseContentResponse } from "./base-content.response";
import ContentTypesEnum from "../../enums/content-types.enum";
import CollectionSummaryModel, {
  CollectionSummaryResponse,
} from "./collection-summary.model";
import SummaryTypesEnum from "../../enums/summary-types.enum";
import CardTypes, { CardResponseTypes } from "../../types/card.types";
import { CardModelMapper } from "../../redux/stores/content/content.effect";

export interface CollectionResponse extends BaseContentResponse {
  collectionUid: string;
  startSummary?: CollectionSummaryResponse;
  endSummary?: CollectionSummaryResponse;
  chapterUid: string;
  cards?: CardResponseTypes[];
}

export default class CollectionModel extends BaseContentModel {
  collectionUid: string;
  contentType: ContentTypesEnum.COLLECTION;
  startSummary?: CollectionSummaryModel;
  endSummary?: CollectionSummaryModel;
  chapterUid: string;
  cards?: CardTypes[];
  getId: string;

  constructor(collection: CollectionResponse) {
    super(collection);
    this.collectionUid = collection.collectionUid;
    this.contentType = ContentTypesEnum.COLLECTION;
    if (collection.startSummary) {
      this.startSummary = new CollectionSummaryModel(
        collection.startSummary,
        this.collectionUid,
        SummaryTypesEnum.START,
        this.contentState,
      );
    }
    if (collection.endSummary) {
      this.endSummary = new CollectionSummaryModel(
        collection.endSummary,
        this.collectionUid,
        SummaryTypesEnum.END,
        this.contentState,
      );
    }
    this.chapterUid = collection.chapterUid;

    if (collection.cards) {
      this.cards = collection.cards
        .filter((c) => c.cardType !== undefined)
        .map((cardResponse) => {
          const model = CardModelMapper(cardResponse.cardType);

          if (!model) {
            throw new Error(
              `CardType ${cardResponse.cardType} is not supported`,
            );
          }

          // @ts-ignore
          return new model(cardResponse);
        });
    }

    this.getId = `collection_${this.collectionUid}`;
  }
}
