import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Empty, Menu, Modal, Typography } from "antd";
import SharedContentDetailOverviewScreen from "../../screens/shared-content/shared-content-detail-overview.screen";
import {
  useApiKey,
  useDeleteApiKeyMutation,
  useUpdateApiKeyStateMutation,
} from "../../../core/hooks/api-key/api-key.hook";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  StopOutlined,
} from "@ant-design/icons";
import colors from "../../styles/colors";
import ActionButtonComponent from "../../components/actions/action-button.component";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import { Capitalize } from "../../../core/utils/helper.utils";

const SharedContentDetailContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ key: string }>();
  const { data } = useApiKey(params.key);
  const [apiKey, setApiKey] = useState<ApiKeyModel>();
  const { mutate: changeState } = useUpdateApiKeyStateMutation();
  const { mutateAsync: deleteApiKey } = useDeleteApiKeyMutation();

  useEffect(() => {
    setApiKey(data);
  }, [data]);

  function handleDeleteApiKey() {
    if (!apiKey || apiKey.active) {
      return;
    }

    Modal.confirm({
      title: t("translations:screens.shared-content.delete.title"),
      content: (
        <Typography.Text>{Capitalize(apiKey.description)}</Typography.Text>
      ),
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
      onOk() {
        deleteApiKey(apiKey.key).finally(() => {
          history.push(RoutesEnum.SHARED_CONTENT);
        });
      },
    });
  }

  return (
    <PageLayoutDetailComponent
      showSider={false}
      title={apiKey ? apiKey.description : "Oops.."}
      goBack={() => history.push(RoutesEnum.SHARED_CONTENT)}
      container={"shared-content"}
      extra={renderButton()}
    >
      {apiKey ? (
        <SharedContentDetailOverviewScreen apiKey={apiKey} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("errors.general")}
        />
      )}
    </PageLayoutDetailComponent>
  );

  function renderButton() {
    if (!apiKey) {
      return null;
    }

    const menu = (
      <Menu>
        {apiKey.active ? (
          <Menu.Item
            key={"3"}
            icon={<StopOutlined />}
            onClick={handleChangeState}
          >
            {Capitalize(
              t("translations:common.disable-x", {
                item: t("translations:common.link"),
              }),
            )}
          </Menu.Item>
        ) : (
          <>
            <Menu.Item
              key={"3"}
              icon={<CheckCircleOutlined />}
              onClick={handleChangeState}
            >
              {Capitalize(
                t("translations:common.enable-x", {
                  item: t("translations:common.link"),
                }),
              )}
            </Menu.Item>
            <Menu.Item
              key={"4"}
              icon={<DeleteOutlined />}
              style={{ color: colors.secondary }}
              onClick={handleDeleteApiKey}
            >
              {Capitalize(t("translations:common.delete"))}
            </Menu.Item>
          </>
        )}
      </Menu>
    );

    return <ActionButtonComponent overlay={menu} />;
  }

  function handleChangeState() {
    if (!apiKey) {
      return;
    }

    changeState({
      key: apiKey ? apiKey.key : "",
      active: apiKey ? apiKey.active : false,
    });
  }
};

export default SharedContentDetailContainer;
