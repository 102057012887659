import React, { useEffect, useState } from "react";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { useAuth } from "../../../core/providers/auth.provider";
import { Button, Form, Input } from "antd";
import styles from "../../components/auth/login.module.css";
import Logo from "../../components/logo/logo.component";

const NewPasswordChallengeScreen = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const { completeNewPasswordChallenge, loading } = useAuth();
  const {
    location: { state: params },
  } = useHistory();

  useEffect(() => {
    const { email: _email } = params as any;

    if (_email) {
      setEmail(_email);
    }
  }, []);

  const onFinish = () => {
    const _password = password.trim();
    const _passwordConfirm = passwordConfirm.trim();

    if (_password !== _passwordConfirm) {
      form.setFields([
        {
          name: "password-confirm",
          errors: [
            t("errors.notAuthorizedException", {
              field: Capitalize(t("form.items.password-confirm.label")),
              item: t("form.items.password.label"),
            }),
          ],
        },
      ]);
      return;
    }

    const { requiredAttributes, remember } = params as any;

    completeNewPasswordChallenge(
      email,
      _password,
      requiredAttributes,
      remember,
      {
        onFailure: (err) => {
          switch (err.code) {
            case "UserNonAdminException":
              form.setFields([
                {
                  name: "email",
                  errors: [""],
                },
                {
                  name: "password",
                  errors: [t("errors.status.403")],
                },
              ]);
              break;
          }
        },
      },
    );
  };

  return (
    <>
      <Logo className={styles.login_logo} width={218} height={66} />
      <Form
        form={form}
        name={"normal_login"}
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Form.Item>
          <h2>{t("form.items.password.tooltip")}</h2>
        </Form.Item>

        <Form.Item htmlFor={"email"} label={t("form.items.email.label")}>
          <Input type={"text"} name={"email"} value={email} disabled />
        </Form.Item>

        <Form.Item
          name={"password"}
          label={t("form.items.password.label")}
          rules={[{ required: true, message: " " }]}
        >
          <Input.Password
            type={"password"}
            value={password}
            placeholder={t("form.items.password.label")}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name={"password-confirm"}
          label={t("form.items.password-confirm.label")}
          rules={[{ required: true, message: " " }]}
        >
          <Input.Password
            type={"password"}
            value={passwordConfirm}
            placeholder={t("form.items.password-confirm.label")}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            className={"Button"}
            type={"primary"}
            htmlType={"submit"}
            loading={loading}
          >
            {Capitalize(t("common.submit"))}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewPasswordChallengeScreen;
