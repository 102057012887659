import LearningPathContentModel, {
  LearningPathContentResponse,
} from "./learning-path-content.model";
import { LearningPathTypeEnum } from "../../enums/learning-path-state.enum";

export interface TimeBasedLearningPathContentNotification {
  title: string;
  message: string;
}

export interface TimeBasedContentResponse extends LearningPathContentResponse {
  data: {
    minutesAfterStarted: number;
    notification: TimeBasedLearningPathContentNotification;
  };
}

export default class TimeBasedContentModel extends LearningPathContentModel {
  learningPathType?: LearningPathTypeEnum.TIME_BASED;
  data: {
    minutesAfterStarted: number;
    notification: TimeBasedLearningPathContentNotification;
  };

  constructor(response: TimeBasedContentResponse) {
    super(response);
    this.learningPathType = LearningPathTypeEnum.TIME_BASED;
    this.data = response.data;
  }
}
