import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Form, Input, Typography } from "antd";
import { grey } from "@ant-design/colors";
import { Capitalize } from "../../../core/utils/helper.utils";
import styles from "../card-types/card-types.module.css";
import CharacterCounter from "../form-builder/components/character-counter";
import AppConfig from "../../../constants/config/app.config";
import PlaybooksSelectComponent from "./select/playbooks-select.component";

export interface CreateApiKeyFormFields {
  content: string[];
  description: string;
}

export interface CreateApiKeyFormRef {
  form: FormInstance<CreateApiKeyFormFields>;
}

const CreateApiKeyForm = forwardRef((_, ref: Ref<CreateApiKeyFormRef>) => {
  const [t] = useTranslation();
  const [form] = useForm<CreateApiKeyFormFields>();
  const { TextArea } = Input;
  const [content, setContent] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");

  useImperativeHandle(ref, () => ({ form }));

  useEffect(() => {
    form.setFields([{ name: "content", value: content }]);
  }, [content]);

  useEffect(() => {
    form.setFields([{ name: "description", value: description }]);
  }, [description]);

  const getRules = () => [
    {
      required: true,
      message: Capitalize(t("errors.required-generic")),
    },
  ];

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="content"
        rules={getRules()}
        label={
          <Typography.Text
            style={{
              color: grey[6],
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {t("common.pick-your-modules")}
          </Typography.Text>
        }
      >
        <PlaybooksSelectComponent
          playbookUids={content}
          onChange={(value) => {
            const playbookUids = value.map((p) => p.playbookUid);
            setContent(playbookUids);
          }}
          filter={(playbook) => playbook.contentState === "PUBLISHED"}
        />
      </Form.Item>

      <Form.Item
        initialValue={description}
        name={"description"}
        rules={getRules()}
        label={
          <Typography.Text
            style={{
              color: grey[6],
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {t("common.write-a-description")}
          </Typography.Text>
        }
      >
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: "599px" }}
          rows={4}
          maxLength={AppConfig.sharedContentDescriptionMaxLength}
        />
        <CharacterCounter
          currentLength={description.length}
          maxLength={AppConfig.sharedContentDescriptionMaxLength}
          className={styles.character_counter}
        />
      </Form.Item>
    </Form>
  );
});

CreateApiKeyForm.displayName = "CreateApiKeyForm";

export default CreateApiKeyForm;
