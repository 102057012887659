import UserProgressCardDetailModel, {
  UserProgressCardDetailResponse,
} from "./user-progress-card-detail.model";

interface UserProgressCollectionDetailDataInterface {
  cards: { [cardUid: string]: UserProgressCardDetailResponse };
  firstPublishedAt: Date;
}

interface UserProgressCollectionDetailResponse {
  [collectionUid: string]: UserProgressCollectionDetailDataInterface;
}

export default class UserProgressCollectionDetailModel {
  collectionUid: string;
  firstPublishedAt: Date;
  cards: UserProgressCardDetailModel[];

  constructor(response: UserProgressCollectionDetailResponse) {
    this.collectionUid = Object.keys(response)[0];

    if (Object.values(response).length > 0) {
      this.firstPublishedAt = new Date(
        Object.values(response)[0].firstPublishedAt,
      );
      this.cards = Object.entries(Object.values(response)[0].cards).map(
        ([cardUid, res]) => {
          return new UserProgressCardDetailModel(cardUid, res);
        },
      );
    } else {
      this.firstPublishedAt = new Date();
      this.cards = [];
    }
  }
}
