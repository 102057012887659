import React, { useEffect } from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import QrCodeDetailOverviewScreen from "../../screens/qr-codes/qr-code-detail-overview.screen";
import { useQrCode } from "../../../core/features/qr-code/qr-code.hooks";
import { useDispatch } from "react-redux";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

const QrCodeDetailContainer = (props: any) => {
  const { data: qrCode } = useQrCode(props.match.params.uid);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!qrCode) {
      return;
    }

    dispatch(
      ContentAction.getContentDetail(
        ContentTypesEnum.PLAYBOOK,
        qrCode.data.playbookUid,
      ),
    );

    if ("chapterUid" in qrCode.data) {
      dispatch(
        ContentAction.getContentDetail(
          ContentTypesEnum.CHAPTER,
          qrCode.data.chapterUid,
        ),
      );
    }

    if ("collectionUid" in qrCode.data) {
      dispatch(
        ContentAction.getContentDetail(
          ContentTypesEnum.COLLECTION,
          qrCode.data.collectionUid,
        ),
      );
    }

    if ("cardUid" in qrCode.data) {
      dispatch(
        ContentAction.getContentDetail(
          ContentTypesEnum.CARD,
          qrCode.data.cardUid,
        ),
      );
    }
  }, [qrCode]);

  return (
    <PageLayoutDetailComponent
      title={qrCode ? qrCode.description : "Oops.."}
      goBack={() => history.push(RoutesEnum.QR_CODE)}
      container={"qr"}
    >
      {qrCode ? (
        <QrCodeDetailOverviewScreen qrCode={qrCode} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("errors.general")}
        />
      )}
    </PageLayoutDetailComponent>
  );
};

export default QrCodeDetailContainer;
