import React from "react";
import { Typography } from "antd";
import HTMLReactParser from "html-react-parser";

interface Props {
  link: string;
}

const EmbedCodeMediaComponent = ({ link }: Props) => {
  return (
    <>
      <div>{HTMLReactParser(link)}</div>
      <Typography.Text code style={{ wordBreak: "break-all" }}>
        {link}
      </Typography.Text>
    </>
  );
};

export default EmbedCodeMediaComponent;
