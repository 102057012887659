import React, { useEffect } from "react";
import ContentType from "../../../core/types/content.type";
import CardTypes from "../../../core/types/card.types";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { List, Tooltip, Typography } from "antd";
import { grey } from "@ant-design/colors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import PlaybookModel from "../../../core/models/content/playbook.model";
import {
  selectChapterViaUid,
  selectCollectionViaUid,
  selectLatestPublishedCardViaId,
  selectPlaybookViaUid,
} from "../../../core/redux/selectors/content/content.selector";
import ChapterModel from "../../../core/models/content/chapter.model";
import CollectionModel from "../../../core/models/content/collection.model";
import ContentIconComponent from "../icons/content-icon.component";
import formStyles from "../form-builder/form-builder.module.css";
import ContentAction from "../../../core/redux/stores/content/content.action";

interface Props {
  data: {
    playbookUid?: string;
    chapterUid?: string;
    collectionUid?: string;
    cardUid?: string;
  };
}

const ContentListComponent = (props: Props) => {
  const [t] = useTranslation();
  const {
    data: { playbookUid, chapterUid, collectionUid, cardUid },
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const contentTypes = {
      playbook: playbookUid,
      chapter: chapterUid,
      collection: collectionUid,
      card: cardUid,
    };
    Object.keys(contentTypes).forEach((contentType) => {
      const uid = contentTypes[contentType];
      if (uid) {
        dispatch(
          ContentAction.getContentDetail(
            ContentTypesEnum[contentType.toUpperCase()],
            uid,
          ),
        );
      }
    });
  }, [playbookUid, chapterUid, collectionUid, cardUid]);

  const playbook: PlaybookModel | undefined = useSelector(
    (state: StoreInterface) => selectPlaybookViaUid(state, playbookUid),
  );
  const chapter: ChapterModel | undefined = useSelector(
    (state: StoreInterface) => selectChapterViaUid(state, chapterUid),
  );
  const collection: CollectionModel | undefined = useSelector(
    (state: StoreInterface) => selectCollectionViaUid(state, collectionUid),
  );
  const card: CardTypes | undefined = useSelector((state: StoreInterface) =>
    selectLatestPublishedCardViaId(state, cardUid),
  );
  const data = [playbook, chapter, collection, card];

  const contentTypes = [
    ContentTypesEnum.PLAYBOOK.toLowerCase(),
    ContentTypesEnum.CHAPTER.toLowerCase(),
    ContentTypesEnum.COLLECTION.toLowerCase(),
    ContentTypesEnum.CARD.toLowerCase(),
  ];

  return (
    <List<ContentType | undefined>
      dataSource={data}
      rowKey={(item: ContentType | undefined) => (item ? item.contentType : "")}
      className={formStyles.list}
      bordered
      renderItem={(item, index) => (
        <List.Item key={index}>
          <Tooltip
            title={Capitalize(t(`content.${contentTypes[index]}.key`))}
            placement={"top"}
          >
            <ContentIconComponent
              contentType={
                contentTypes[index].toUpperCase() as ContentTypesEnum
              }
            />
          </Tooltip>
          <div style={{ flex: "0.95" }}>
            {item ? (
              <Typography.Text>{TranslateDefaults(item.title)}</Typography.Text>
            ) : (
              <Typography.Text style={{ color: grey[0] }}>
                {"-"}
              </Typography.Text>
            )}
          </div>
        </List.Item>
      )}
    />
  );
};

export default ContentListComponent;
