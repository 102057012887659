import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import { Button, Dropdown, Menu, Tag } from "antd";
import { AlignCenterOutlined } from "@ant-design/icons";
import CardTypeIconComponent from "../icons/card-type-icon.component";
import ContentIconComponent from "../icons/content-icon.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import CardTypes from "../../../core/types/card.types";

interface OwnProps {
  cards: CardTypes[];
  filterCardTypes: (cardTypes: CardTypesEnum[]) => void;
}

type Props = OwnProps;

const FilterCardTypesComponent = ({ cards, filterCardTypes }: Props) => {
  const { t } = useTranslation();
  const [cardTypes, setCardTypes] = useState<CardTypesEnum[]>([]);

  const menu = (
    <Menu>
      <Menu.Item
        key={"0"}
        icon={<ContentIconComponent contentType={ContentTypesEnum.CARD} />}
      >
        <a onClick={handleOnResetFilterCardType}>
          {`${Capitalize(t("common.all"))} ${Capitalize(
            t("content.card.key_plural"),
          )} (${cards.length})`}
        </a>
      </Menu.Item>
      <Menu.Divider />
      {Object.keys(CardTypesEnum).map((key) => (
        <Menu.Item
          disabled={cards.filter((c) => c.cardType === key).length === 0}
          icon={<CardTypeIconComponent cardType={CardTypesEnum[key]} />}
          key={key}
        >
          <a onClick={() => handleOnAddCardType(key)}>
            {renderCardTypesLabel(key)}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ marginBottom: "1.4rem" }}>
      <Dropdown overlay={menu}>
        <Button
          type={"text"}
          icon={<AlignCenterOutlined />}
          style={{ padding: 0, marginRight: "1rem" }}
        >
          {`${Capitalize(t("common.filter"))} ${t("content.card-types.label")}`}
        </Button>
      </Dropdown>

      {cardTypes.map((key) => (
        <Tag key={key} onClose={() => handleOnCloseCardType(key)} closable>
          {renderCardTypesLabel(key)}
        </Tag>
      ))}
    </div>
  );

  function renderCardTypesLabel(key: string) {
    const amount = cards.filter((c) => c.cardType === key).length;
    return `${Capitalize(
      t(`content.card-types.${CardTypesEnum[key]}`),
    )} (${amount})`;
  }

  function handleOnResetFilterCardType() {
    setCardTypes([]);
    filterCardTypes([]);
  }

  function handleOnAddCardType(key: string) {
    if (!cardTypes.includes(CardTypesEnum[key])) {
      cardTypes.push(CardTypesEnum[key]);
    }

    filterCardTypes([...cardTypes]);
  }

  function handleOnCloseCardType(key: CardTypesEnum) {
    if (cardTypes.length === 1) {
      return handleOnResetFilterCardType();
    }

    const index = cardTypes.findIndex((type) => type === key);
    cardTypes.splice(index, 1);

    filterCardTypes([...cardTypes]);
  }
};

export default FilterCardTypesComponent;
