import React from "react";
import { Empty } from "antd";
import { LearningPathTypeEnum } from "../../../../core/enums/learning-path-state.enum";
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import TimeBasedContentModel from "../../../../core/models/learning-path/time-based-content.model";
import ProgressBasedContentModel from "../../../../core/models/learning-path/progress-based-content.model";
import daysToMinutes from "../../../../core/utils/days-to-minutes.utils";

/* eslint-disable-next-line */
const getListStyle = (isDraggingOver) => ({
  cursor: "move",
  overflow: "hidden",
});

interface Props {
  details: TimeBasedContentModel[] | ProgressBasedContentModel[];
  children: JSX.Element;
  onDragEnd: (
    content: TimeBasedContentModel[] | ProgressBasedContentModel[],
  ) => void;
}

const DragDropContextComponent = (props: Props) => {
  const { details } = props;

  if (!details) {
    return <Empty />;
  }

  const sortContent = (content, dragIndex: number, hoverIndex: number) => {
    const result = [...content];
    const [newOrder] = result.splice(dragIndex, 1);
    result.splice(hoverIndex, 0, newOrder);

    return result;
  };

  function handleSortContent(
    items: TimeBasedContentModel[] | ProgressBasedContentModel[],
  ) {
    const sortingOrder: number[] = items.map((content) => content.sort);

    let input: TimeBasedContentModel[] | ProgressBasedContentModel[] = [];
    if (
      items.map((c) => c.learningPathType === LearningPathTypeEnum.TIME_BASED)
    ) {
      input = items.map((content, index) => {
        const minutesAfterStarted =
          Math.min(...sortingOrder) + index !== 1 &&
          content.data?.minutesAfterStarted === 0
            ? daysToMinutes(1)
            : content.data?.minutesAfterStarted;
        return new TimeBasedContentModel({
          ...content,
          data: {
            minutesAfterStarted,
            notification: content.data?.notification,
          },
          sort: Math.min(...sortingOrder) + index,
        });
      });
    } else {
      input = items.map((content, index) => {
        return new ProgressBasedContentModel({
          ...content,
          sort: Math.min(...sortingOrder) + index,
        });
      });
    }

    props.onDragEnd(input);
  }

  function handleOnDragEnd(
    result: DropResult,
    content?: TimeBasedContentModel[] | ProgressBasedContentModel[],
  ) {
    if (!result.destination || !content) {
      return;
    }

    const dragIndex: number = result.source.index;
    const hoverIndex: number = result.destination.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    handleSortContent(sortContent(content, dragIndex, hoverIndex));
  }

  return (
    <DragDropContext onDragEnd={(e) => handleOnDragEnd(e, details)}>
      <Droppable droppableId={"droppable_parent"}>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropContextComponent;
