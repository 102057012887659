import { useState } from "react";
import { useDispatch } from "react-redux";

interface Props {
  dispatchAction: any;
  pagination: {
    current: number;
    pageSize: number;
  };
}

function PaginationUtils(props: Props) {
  const { dispatchAction } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(props.pagination);
  const dispatch = useDispatch();

  const fetch = (params = {}) => {
    setLoading(true);

    dispatch(dispatchAction).then((data) => {
      setLoading(false);
      setPagination({
        ...params,
        total: data.totalCount,
      });
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  return { handleTableChange, loading, pagination };
}

export default PaginationUtils;
