import { API_URL } from "../../constants/config/env.config";

const UserGroupEndpoints = {
  /**
   * GET all UserGroups endpoint.
   * @returns API_URL + /user-groups/
   */
  overview: `${API_URL}/user-groups/`,

  /**
   * GET one UserGroup endpoint.
   * @param groupTitle string
   * @returns API_URL + /user-groups/admins
   */
  detail(groupTitle: string) {
    return `${API_URL}/user-groups/${groupTitle}`;
  },

  /**
   * POST UserGroup endpoint.
   * @returns API_URL + /user-groups/
   */
  create: `${API_URL}/user-groups/`,

  /**
   * PUT UserGroup endpoint.
   * @param groupTitle string
   * @returns API_URL + /user-groups/admins
   */
  edit(groupTitle: string) {
    return `${API_URL}/user-groups/${groupTitle}`;
  },

  /**
   * DELETE UserGroup endpoint.
   * @param groupTitle string
   * @returns API_URL + /user-groups/admins
   */
  delete(groupTitle: string) {
    return `${API_URL}/user-groups/${groupTitle}`;
  },

  /**
   * GET Users from UserGroup endpoint.
   * @param groupTitle string
   * @returns API_URL + /user-groups/admins/users
   */
  getUsers(groupTitle: string) {
    return `${API_URL}/user-groups/${groupTitle}/users`;
  },

  /**
   * POST Users to UserGroup endpoint.
   * @param groupTitle string
   * @returns API_URL + /user-groups/admins/add-users
   */
  addUsers(groupTitle: string) {
    return `${API_URL}/user-groups/${groupTitle}/add-users`;
  },

  /**
   * POST Users from UserGroup endpoint.
   * @param groupTitle string
   * @returns API_URL + /user-groups/admins/remove-users
   */
  removeUsers(groupTitle: string) {
    return `${API_URL}/user-groups/${groupTitle}/remove-users`;
  },

  /**
   * POST update Api Key Client user group content endpoint.
   * @returns API_URL + /api-keys/clients/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx/content/user-groups
   */
  update(apiKeyClientUid: string) {
    return `${API_URL}/api-keys/clients/${apiKeyClientUid}/content/user-groups`;
  },
};

export default UserGroupEndpoints;
