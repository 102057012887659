import ThunkInterface from "../../../interfaces/thunk.interface";
import NotificationTypes from "../../../types/notification.types";
import BaseReducer from "../base/base.reducer";
import BaseNotificationModel from "../../../models/notification/base-notification.model";
import NotificationAction from "./notification.action";
import BaseNotificationCountModel from "../../../models/notification/base-notification-count.model";

export interface NotificationReducerInterface {
  notifications: NotificationTypes[];
  notificationsCount: BaseNotificationCountModel[];
}

export default class NotificationReducer extends BaseReducer<NotificationReducerInterface> {
  initialState: NotificationReducerInterface = {
    notifications: [],
    notificationsCount: [],
  };

  [NotificationAction.REQUEST_FETCH_NOTIFICATIONS_FINISHED](
    state: NotificationReducerInterface,
    action: ThunkInterface<BaseNotificationModel[]>,
  ) {
    return {
      ...state,
      notifications: action.payload,
    };
  }

  [NotificationAction.REQUEST_COUNT_NOTIFICATIONS_FINISHED](
    state: NotificationReducerInterface,
    action: ThunkInterface<BaseNotificationCountModel[]>,
  ) {
    return {
      ...state,
      notificationsCount: action.payload,
    };
  }

  [NotificationAction.REQUEST_NOTIFICATION_FINISHED](
    state: NotificationReducerInterface,
    action: ThunkInterface<BaseNotificationModel>,
  ) {
    return {
      ...state,
      notifications: this.addSingleToArray<BaseNotificationModel>(
        action.payload,
        [...(state.notifications as BaseNotificationModel[])],
        "notificationUid",
      ),
    };
  }
}
