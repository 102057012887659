import React from "react";
import { useDispatch } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { Col, Row, Space, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../../../core/enums/learning-path-state.enum";
import LearningPathTypeIconComponent from "../../components/icons/learning-path-type-icon.component";
import LearningPathAction from "../../../core/redux/stores/learning-path/learning-path.action";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import ContentActionComponent from "../../components/content-action/content-action.component";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import Moment from "moment";
import DraggableTableRowComponent from "../../components/draggable/draggable-table-row.component";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import SortLearningPathInterface from "../../../core/interfaces/sort-learning-path.interface";
import { RoutesEnum } from "../../../constants/routes/app.route";
import {
  ExpandOutlined,
  RocketOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { GroupTags } from "../../components/group-tag";
import { useDuplicateLearningPath } from "../../../core/api/primio/primioComponents";

interface Props {
  learningPaths: LearningPathModel[];
  onEdit?: (learningPath: LearningPathModel) => void;
}

const LearningPathOverviewScreen = ({ learningPaths, onEdit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutateAsync: duplicateLearningPath } = useDuplicateLearningPath({
    onSuccess: () => dispatch(LearningPathAction.getAllLearningPaths()),
  });

  const columns: ColumnsType<LearningPathModel> = [
    {
      key: "learningPathType",
      title: (
        <Typography.Text ellipsis style={{ width: 140 }}>
          {Capitalize(
            t("common.type") + " " + t("containers.learning-path.key"),
          )}
        </Typography.Text>
      ),
      dataIndex: "learningPathType",
      width: 120,
      filters: [
        {
          text: Capitalize(
            t("screens.learning-path.type.PROGRESS_BASED.label"),
          ),
          value: LearningPathTypeEnum.PROGRESS_BASED,
        },
        {
          text: Capitalize(t("screens.learning-path.type.TIME_BASED.label")),
          value: LearningPathTypeEnum.TIME_BASED,
        },
      ],
      onFilter: function filterText(value, data) {
        if (typeof value === "string") {
          return data.learningPathType.includes(value);
        }
        return false;
      },
      render: function renderText(text: LearningPathTypeEnum) {
        return (
          <Typography.Text style={{ width: 120 }}>
            <LearningPathTypeIconComponent
              type={text}
              style={{ padding: ".5rem" }}
            />
            {Capitalize(t(`screens.learning-path.type.${text}.label`))}
          </Typography.Text>
        );
      },
    },
    {
      key: "title",
      title: Capitalize(t("form.card.title.label")),
      dataIndex: "title",
      width: 150,
      ...GetColumnSearchProps({ dataIndex: "title" }),
      render: function renderText(text: string) {
        return (
          <Typography.Text ellipsis style={{ width: 150 }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      key: "createdAt",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("form.items.created_at.label"))}
        </Typography.Text>
      ),
      dataIndex: "createdAt",
      width: 120,
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.createdAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      key: "state",
      title: Capitalize(t("content-state.state")),
      dataIndex: "state",
      width: 80,
      filters: [
        {
          text: Capitalize(t(`screens.learning-path.state.CREATED`)),
          value: LearningPathStateEnum.CREATED,
        },
        {
          text: Capitalize(t(`screens.learning-path.state.POSTED`)),
          value: LearningPathStateEnum.POSTED,
        },
        {
          text: Capitalize(t(`screens.learning-path.state.REVOKED`)),
          value: LearningPathStateEnum.REVOKED,
        },
      ],
      onFilter: function filterText(value, data) {
        if (typeof value === "string") {
          return data.state.includes(value);
        }
        return false;
      },
      render: function renderText(_, data, index) {
        return (
          <Row
            key={index}
            align={"middle"}
            style={{
              opacity: data.state === LearningPathStateEnum.REVOKED ? 0.6 : 1,
            }}
          >
            <Col
              style={{
                marginRight: "0.5em",
                fontSize: 16,
              }}
            >
              {data.state === LearningPathStateEnum.POSTED && (
                <RocketOutlined />
              )}
              {data.state === LearningPathStateEnum.CREATED && (
                <ExpandOutlined />
              )}
            </Col>

            <Col>
              <Typography.Text>
                {Capitalize(t(`screens.learning-path.state.${data.state}`))}
              </Typography.Text>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      width: 150,
      render: (_, data) => <GroupTags groupNames={data.userGroups ?? []} />,
    },
    {
      width: 1,
      render: function showActions(_, data) {
        const route =
          data.learningPathType === null
            ? RoutesEnum.LEARNING_PATH_DETAIL
            : RoutesEnum.LEARNING_PATH_DETAIL_LEGACY;
        return (
          <Space size={"middle"} style={{ float: "right" }}>
            <Link
              to={{
                pathname: route.replace(":uid", data.learningPathUid),
              }}
            >
              <ContentActionComponent
                icon={<ContextActionIconComponent action={"open"} />}
                label={Capitalize(t("common.open"))}
                hideLabel={true}
              />
            </Link>
            <Link
              to={RoutesEnum.LEARNING_PATH_DETAIL_REPORT.replace(
                ":uid",
                data.learningPathUid,
              )}
            >
              <ContentActionComponent
                icon={<ReconciliationOutlined />}
                label={"Report"}
                hideLabel={true}
              />
            </Link>
            <ContentActionComponent
              icon={
                <ContextActionIconComponent
                  action={"edit"}
                  style={{ color: "var(--primary-color)" }}
                />
              }
              label={Capitalize(t("common.edit"))}
              hideLabel={true}
              onClick={() => {
                if (onEdit) onEdit(data);
              }}
            />
            <ContentActionComponent
              icon={
                <ContextActionIconComponent
                  action={"duplicate"}
                  style={{ color: "var(--primary-color)" }}
                />
              }
              label={Capitalize(t("common.duplicate"))}
              hideLabel={true}
              onClick={() =>
                duplicateLearningPath({
                  pathParams: { learningPathUid: data.learningPathUid },
                })
              }
            />
          </Space>
        );
      },
    },
  ];

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const items = [...learningPaths];
    const [newOrder] = items.splice(dragIndex, 1);
    items.splice(hoverIndex, 0, newOrder);

    handleSortLearningPaths(items);
  };

  function handleSortLearningPaths(items: LearningPathModel[]) {
    const data: SortLearningPathInterface = {
      learningPaths: items.map(({ learningPathUid }, index) => ({
        learningPathUid,
        sort: index,
      })),
    };

    dispatch(LearningPathAction.sortLearningPath(data));
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Table<LearningPathModel>
        dataSource={learningPaths}
        columns={columns}
        onRow={(_, index) => {
          const attr = { index, moveRow };
          return attr as React.HTMLAttributes<any>;
        }}
        rowKey={(data) => data.learningPathUid}
        tableLayout={"fixed"}
        pagination={false}
        scroll={{ x: true }}
        size={"small"}
        components={{ body: { row: DraggableTableRowComponent } }}
      />
    </DndProvider>
  );
};

export default LearningPathOverviewScreen;
