import UserModel from "../../../models/user/user.model";
import { UserModelExtendedInterface } from "../../../interfaces/user-model-extended.interface";
import { selectUsersExceptFor } from "../user/user.selector";
import { TopUsersModel } from "../../../models/user-progress/popular-users.model";
import { ADMIN_GROUP } from "../../../../constants";
import { createSelector } from "reselect";
import { selectState } from "../core/core.selector";

export const selectUserActivities = createSelector(
  selectState,
  (state) => selectUsersExceptFor(state, ADMIN_GROUP, undefined),
  (state, topUsers: TopUsersModel[] | undefined) => topUsers,
  (state, allUsersWithoutAdmins, topUsers) => {
    if (allUsersWithoutAdmins.length === 0 || !topUsers) {
      return [];
    }

    const _userActivities: UserModelExtendedInterface[] = [];
    topUsers.map((topUser) => {
      const user: UserModel | undefined = allUsersWithoutAdmins.find(
        (u) => u.sub === topUser.userUid,
      );

      if (!user) {
        return;
      }

      _userActivities.push({
        ...user,
        cardsCompleted: topUser.cardsCompleted,
        firstActiveOn: topUser.firstActiveOn,
      });
    });

    return _userActivities;
  },
);
