import { ContentStatesEnum } from "../../../enums/content-states.enum";
import { StoreInterface } from "../../stores/root.reducer";
import ContentType from "../../../types/content.type";
import CardTypes from "../../../types/card.types";
import PlaybookModel from "../../../models/content/playbook.model";
import ChapterModel from "../../../models/content/chapter.model";
import CollectionModel from "../../../models/content/collection.model";
import PlaybookDetailModel from "../../../models/content/playbook-detail.model";
import ContentCountModel from "../../../models/content/content-count.model";
import ContentTypesEnum from "../../../enums/content-types.enum";
import ChapterDetailModel from "../../../models/content/chapter-detail.model";
import CollectionDetailModel from "../../../models/content/collection-detail.model";
import Moment from "moment";
import { createSelector } from "reselect";
import { selectState } from "../core/core.selector";
import {
  selectCurrentUser,
  selectCurrentUserRole,
} from "../user/user.selector";
import UserRoleEnum from "../../../enums/user-role.enum";
import UserGroupModel from "../../../models/user-group/user-group.model";
import { Playbook } from "../../../api/primio/primioSchemas";

export const playbooksSelector = createSelector(
  selectState,
  selectCurrentUser,
  selectCurrentUserRole,
  (state, user, userRole) => {
    if (!user || !userRole) {
      return [];
    }

    switch (userRole) {
      // @ts-ignore
      case UserRoleEnum.SYS_ADMIN:
      case UserRoleEnum.PRIMIO_SUPPORT:
      case UserRoleEnum.ADMIN:
        return state.content.playbooks;
      case UserRoleEnum.GROUP_ADMIN:
        return state.content.playbooks.filter((playbook) => {
          if (playbook.userGroupAcl.length === 0) {
            return true;
          }

          return user.userGroups.some((userGroup) =>
            playbook.userGroupAcl.includes(userGroup),
          );
        });
      default:
        return [];
    }
  },
);
export const chaptersSelector = (state: StoreInterface) =>
  state.content.chapters;
export const collectionsSelector = (state: StoreInterface) =>
  state.content.collections;
export const cardsSelector = (state: StoreInterface) => state.content.cards;

// Select Content by uid
export const selectPlaybookViaUid = (
  state: StoreInterface,
  playbookUid: string | undefined,
) => {
  const playbooks = playbooksSelector(state);
  if (playbooks.length === 0 || !playbookUid) {
    return;
  }

  return playbooks.find((playbook) => playbook.playbookUid === playbookUid);
};
export const selectChapterViaUid = (
  state: StoreInterface,
  chapterUid: string | undefined,
) => {
  const chapters = chaptersSelector(state);
  if (chapters.length === 0 || !chapterUid) {
    return;
  }

  return chapters.find((chapter) => chapter.chapterUid === chapterUid);
};
export const selectCollectionViaUid = (
  state: StoreInterface,
  collectionUid: string | undefined,
) => {
  const collections = collectionsSelector(state);
  if (collections.length === 0 || !collectionUid) {
    return;
  }

  return collections.find(
    (collection) => collection.collectionUid === collectionUid,
  );
};
export const selectCardViaUid = (
  state: StoreInterface,
  cardUid: string | undefined,
) => {
  const cards = cardsSelector(state);
  if (cards.length === 0 || !cardUid) {
    return;
  }

  return cards.find((card) => `${card.cardUid}_${card.version}` === cardUid);
};
export const selectCardViaId = (
  state: StoreInterface,
  cardId: string | undefined,
) => {
  const cards = cardsSelector(state);
  if (cards.length === 0 || !cardId) {
    return;
  }

  return cards.filter((card) => card.cardUid === cardId);
};
export const selectCardsCountWithSameUidViaUid = (
  state: StoreInterface,
  cardUid: string | undefined,
) => {
  const cards = cardsSelector(state);

  return cards.filter((card) => card.cardUid === cardUid);
};
export const selectLatestPublishedCardViaId = (
  state: StoreInterface,
  cardId: string | undefined,
): CardTypes | undefined => {
  const selectedCards: CardTypes[] | undefined = selectCardViaId(state, cardId);
  if (!selectedCards) {
    return;
  }

  if (selectedCards.length === 1) {
    return selectedCards[0];
  }

  const publishedCard: CardTypes[] = selectedCards.filter(
    (c) => c.contentState === ContentStatesEnum.PUBLISHED,
  );
  if (publishedCard.length === 0) {
    return selectedCards[0];
  }

  return publishedCard.sort((a, b) => b.version - a.version)[0];
};
export const selectContentViaContentId = (
  state: StoreInterface,
  _contentId: string | undefined,
) => {
  if (!_contentId) {
    return;
  }

  const splittedContentId = _contentId.split("_");

  const contentTypeName = splittedContentId[0];
  const contentId = splittedContentId[1];
  const version = splittedContentId[2];

  if (!contentTypeName || !contentId) {
    return;
  }

  switch (contentTypeName) {
    case "playbook":
      return selectPlaybookViaUid(state, contentId);
    case "chapter":
      return selectChapterViaUid(state, contentId);
    case "collection":
    case "start-summary":
    case "end-summary":
      return selectCollectionViaUid(state, contentId);
    case "card":
      if (!version) {
        return selectCardViaUid(state, contentId);
      }
      return selectCardViaUid(state, contentId + "_" + version);
    default:
      return;
  }
};

export const selectContentViaContentUids = (
  state: StoreInterface,
  contentUids: string[],
  contentType: ContentTypesEnum,
) => {
  switch (contentType) {
    case ContentTypesEnum.PLAYBOOK:
      return playbooksSelector(state).filter((p) =>
        contentUids.includes(p.playbookUid),
      );
    case ContentTypesEnum.CHAPTER:
      return chaptersSelector(state).filter((c) =>
        contentUids.includes(c.chapterUid),
      );
    case ContentTypesEnum.COLLECTION:
      return collectionsSelector(state).filter((c) =>
        contentUids.includes(c.collectionUid),
      );
    case ContentTypesEnum.CARD:
      return cardsSelector(state).filter((c) =>
        contentUids.includes(c.cardUid),
      );
  }
};

// Select by Playbook
export const selectChaptersByPlaybook = (
  state: StoreInterface,
  playbook: PlaybookModel,
) => {
  const chapters = chaptersSelector(state);
  if (chapters.length === 0) {
    return [];
  }

  return chapters
    .filter((chapter) => chapter.playbookUid === playbook.playbookUid)
    .sort((a, b) => a.sort - b.sort);
};

// Select by Chapter(s)
export const selectCollectionsByChapter = (
  state: StoreInterface,
  chapter: ChapterModel | undefined,
) => {
  const collections = collectionsSelector(state);
  if (collections.length === 0 || !chapter) {
    return [];
  }

  return collections
    .filter((collection) => collection.chapterUid === chapter.chapterUid)
    .sort((a, b) => a.sort - b.sort);
};

export const selectCardsByCollection = (
  state: StoreInterface,
  collection: CollectionModel | undefined,
) => {
  const cards = cardsSelector(state);
  if (cards.length === 0 || !collection) {
    return;
  }

  return cards
    .filter((card) => card.collectionUid === collection.collectionUid)
    .sort((a, b) => a.sort - b.sort) as CardTypes[] | undefined;
};
export const selectContentTree = (
  state: StoreInterface,
  content: ContentType,
) => {
  if (content.contentType === ContentTypesEnum.CARD) {
    return;
  }

  let returnContent:
    | PlaybookDetailModel
    | ChapterDetailModel
    | CollectionDetailModel
    | undefined;

  switch (content.contentType) {
    case ContentTypesEnum.PLAYBOOK:
      returnContent = selectContentTreeViaPlaybook(state, content);
      break;
    case ContentTypesEnum.CHAPTER:
      returnContent = selectContentTreeViaChapter(state, content);
      break;
    case ContentTypesEnum.COLLECTION:
      returnContent = selectContentTreeViaCollection(state, content);
      break;
  }

  return returnContent;
};
const selectContentTreeViaPlaybook = (
  state: StoreInterface,
  playbook: PlaybookModel,
) => {
  const playbookDetail: PlaybookDetailModel = playbook as PlaybookDetailModel;

  playbookDetail.children = selectChaptersByPlaybook(state, playbook);

  if (!playbookDetail.children) {
    return;
  }

  playbookDetail.children.forEach((chapterDetail) => {
    chapterDetail.children = selectContentTreeViaChapter(
      state,
      chapterDetail,
    )?.children;
  });

  return playbookDetail;
};
const selectContentTreeViaChapter = (
  state: StoreInterface,
  chapter: ChapterModel,
) => {
  const chapterDetail: ChapterDetailModel = chapter as ChapterDetailModel;

  chapterDetail.children = selectCollectionsByChapter(state, chapterDetail);

  if (!chapterDetail.children) {
    return;
  }

  chapterDetail.children.forEach((collectionDetail) => {
    collectionDetail.children = selectContentTreeViaCollection(
      state,
      collectionDetail,
    ).children;
  });

  return chapterDetail;
};
const selectContentTreeViaCollection = (
  state: StoreInterface,
  collection: CollectionModel,
) => {
  const collectionDetail: CollectionDetailModel =
    collection as CollectionDetailModel;

  collectionDetail.children = selectCardsByCollection(state, collectionDetail);

  return collectionDetail;
};
export const selectContentTrees = (
  state: StoreInterface,
): PlaybookDetailModel[] => {
  const playbooks = playbooksSelector(state);
  if (playbooks.length === 0) {
    return [];
  }

  const playbookDetailModels: PlaybookDetailModel[] = [];

  playbooks.forEach((playbook) => {
    const playbookDetail = selectContentTreeViaPlaybook(state, playbook);
    if (!playbookDetail) {
      return;
    }
    playbookDetailModels.push(playbookDetail);
  });

  return playbookDetailModels;
};

// Select Parent ContentTrees
export const selectParentContentTree = (
  state: StoreInterface,
  content: ContentType,
) => {
  const getParentContentTypes = () => {
    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
      case ContentTypesEnum.CHAPTER:
        return [ContentTypesEnum.PLAYBOOK];
      case ContentTypesEnum.COLLECTION:
        return [ContentTypesEnum.PLAYBOOK, ContentTypesEnum.CHAPTER];
      case ContentTypesEnum.CARD:
        return [
          ContentTypesEnum.PLAYBOOK,
          ContentTypesEnum.CHAPTER,
          ContentTypesEnum.COLLECTION,
        ];
    }
  };

  const parentContentTypes = getParentContentTypes();
  const parents: (PlaybookModel | ChapterModel | CollectionModel)[] = [];

  parentContentTypes?.forEach(() => {
    const child = parents.length !== 0 ? parents[parents.length - 1] : content;
    const parent = selectParentContent(state, child);
    if (!parent) {
      return;
    }
    parents.push(parent);
  });

  return parents;
};
export const selectParentContent = (
  state: StoreInterface,
  content: ContentType,
) => {
  switch (content.contentType) {
    case ContentTypesEnum.PLAYBOOK:
    case ContentTypesEnum.CHAPTER:
      return selectPlaybookViaUid(state, content.playbookUid);
    case ContentTypesEnum.COLLECTION:
      return selectChapterViaUid(state, content.chapterUid);
    case ContentTypesEnum.CARD:
      return selectCollectionViaUid(state, content.collectionUid);
  }
};

/**
 * Raturns the uids of parent content layers
 * @param state
 * @param content
 */
export const selectParentContentUids = (
  state: StoreInterface,
  content?: ContentType,
) => {
  if (!content) return;

  if (content.contentType === ContentTypesEnum.PLAYBOOK) {
    return;
  } else if (content.contentType === ContentTypesEnum.CHAPTER) {
    return {
      playbookUid: content.playbookUid,
    };
  } else if (content.contentType === ContentTypesEnum.COLLECTION) {
    const chapter = selectChapterViaUid(state, content.chapterUid);
    if (!chapter) return;

    return {
      playbookUid: chapter.playbookUid,
      chapterUid: chapter.chapterUid,
    };
  } else if (content.contentType === ContentTypesEnum.CARD) {
    const collection = selectCollectionViaUid(state, content.collectionUid);
    if (!collection) return;

    const chapter = selectChapterViaUid(state, collection.chapterUid);
    if (!chapter) return;

    return {
      playbookUid: chapter.playbookUid,
      chapterUid: chapter.chapterUid,
      collectionUid: collection.collectionUid,
    };
  }
};
export type ParentContentUidsReturnType = ReturnType<
  typeof selectParentContentUids
>;

export const selectParentPlaybookDetailFromContentTree = (
  state: StoreInterface,
  content: ContentType,
): PlaybookDetailModel | undefined => {
  const parentContentTree:
    | (PlaybookModel | ChapterModel | CollectionModel)[]
    | undefined = selectParentContentTree(state, content);

  if (!parentContentTree) return;
  return parentContentTree[parentContentTree.length - 1] as PlaybookDetailModel;
};

// Select Content ACLs
export const selectContentACLs = (
  state: StoreInterface,
  contentType: ContentTypesEnum,
  contentUid: string,
): string[] => {
  if (!contentType || !contentUid) {
    return [];
  }

  if (contentType === ContentTypesEnum.PLAYBOOK) {
    const playbook = selectPlaybookViaUid(state, contentUid);
    return playbook ? playbook.userGroupAcl : [];
  } else {
    const content = selectContentViaContentId(
      state,
      `${contentType.toLowerCase()}_${contentUid}`,
    );
    if (!content) {
      return [];
    }

    const parentContent = selectParentContentTree(state, content);
    if (!parentContent) {
      return [];
    }

    const playbook = parentContent.find(
      (p) => p.contentType === ContentTypesEnum.PLAYBOOK,
    );
    return playbook && playbook instanceof PlaybookModel
      ? playbook.userGroupAcl
      : [];
  }
};

// Other selectors
export const selectContentCount = (
  contentDetail:
    | PlaybookDetailModel
    | ChapterDetailModel
    | CollectionDetailModel
    | undefined,
) => {
  const count: ContentCountModel = {
    chapters: 0,
    collections: 0,
    cards: 0,
  };

  if (!contentDetail) {
    return count;
  }

  switch (contentDetail.contentType) {
    case ContentTypesEnum.PLAYBOOK:
      contentDetail.children?.forEach((chapterDetail) => {
        count.chapters++;

        chapterDetail.children?.forEach((collectionDetail) => {
          count.collections++;

          collectionDetail.children?.forEach(() => {
            count.cards++;
          });
        });
      });
      break;
    case ContentTypesEnum.CHAPTER:
      contentDetail.children?.forEach((collectionDetail) => {
        count.collections++;

        collectionDetail.children?.forEach(() => {
          count.cards++;
        });
      });
      break;
    case ContentTypesEnum.COLLECTION:
      contentDetail.children?.forEach(() => {
        count.cards++;
      });
      break;
  }

  return count;
};
export const selectMostRecentPublishedPlaybooks = (
  state: StoreInterface,
): PlaybookModel[] => {
  const playbooks = playbooksSelector(state);
  if (playbooks.length === 0) {
    return [];
  }

  const publishedPlaybooks = playbooks.filter(
    (p) => p.contentState === ContentStatesEnum.PUBLISHED,
  );

  return publishedPlaybooks.sort(
    (a, b) => Moment(b.publishedAt).unix() - Moment(a.publishedAt).unix(),
  );
};

export const selectPlaybookByApikeyClientUid = (
  state: StoreInterface,
  apikeyClientUid: string,
) => {
  return playbooksSelector(state).filter(
    (p) => p.apiKeyClientUid === apikeyClientUid,
  );
};

export const selectPlaybooksFilteredByUserGroup = (
  playbooks: PlaybookModel[] | Playbook[],
  userGroup: UserGroupModel | undefined,
) => {
  const newPlaybooks: PlaybookModel[] = [];

  playbooks.forEach((playbook) => {
    if (playbook.userGroupAcl?.includes(<string>userGroup?.title)) {
      newPlaybooks.push(playbook);
    }
  });
  return newPlaybooks;
};
