import React from "react";
import { Typography } from "antd";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";

interface Props {
  value: string;
}

const TextEditorPreviewComponent = ({ value }: Props) => {
  return (
    <Typography.Text>
      <span dangerouslySetInnerHTML={{ __html: TranslateDefaults(value) }} />
    </Typography.Text>
  );
};

export default TextEditorPreviewComponent;
