export interface TrackPlaybookResponse {
  trackPlaybookUid: string;
  userUid: string;
  playbookUids: string[];
  createdAt: string;
}

export default class TrackPlaybookModel {
  trackPlaybookUid: string;
  userUid: string;
  playbookUids: string[];
  createdAt: Date;

  constructor(response: TrackPlaybookResponse) {
    this.trackPlaybookUid = response.trackPlaybookUid;
    this.userUid = response.userUid;
    this.playbookUids = response.playbookUids;
    this.createdAt = new Date(response.createdAt);
  }
}
