import React, { useEffect, useState } from "react";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import { useTranslation } from "react-i18next";
import SectionTitleComponent from "../section-title/section-title.component";
import { Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import Moment from "moment";
import { Capitalize } from "../../../core/utils/helper.utils";
import Empty from "../empty/empty";

interface OwnProps {
  apiKey: ApiKeyModel;
}

interface SharedWithInterface {
  key: string;
  server: string;
  addedAt: Date;
}

const SharedContentSharedWithComponent = (props: OwnProps) => {
  const [t] = useTranslation();
  const { apiKey } = props;
  const [sharedWith, setSharedWith] = useState<SharedWithInterface[]>([]);

  useEffect(() => {
    const tempShareWith: SharedWithInterface[] = [];

    apiKey.subscribers.forEach((c) => {
      tempShareWith.push({
        key: c.server,
        server: c.server,
        addedAt: c.createdAt,
      });
    });

    setSharedWith(tempShareWith);
  }, [apiKey]);

  const columns: ColumnsType<SharedWithInterface> = [
    {
      title: Capitalize(t("common.consumer")),
      dataIndex: "server",
      key: "server",
      width: "40%",
    },
    {
      title: Capitalize(t("common.added-at")),
      dataIndex: "addedAt",
      key: "addedAt",
      width: "30%",
      sorter: (a, b) => +new Date(a.addedAt) - +new Date(b.addedAt),
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.addedAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
  ];

  return (
    <>
      <SectionTitleComponent title={t("common.shared-with")} />

      <Table<SharedWithInterface>
        columns={columns}
        dataSource={sharedWith}
        pagination={{ pageSize: 5 }}
        locale={{
          emptyText: (
            <Empty
              image={"open_box"}
              message={t("containers.shared-content.no-one-receiving-content")}
            />
          ),
        }}
      />
    </>
  );
};

export default SharedContentSharedWithComponent;
