import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import BaseContentModel, {
  BaseContentResponse,
} from "../content/base-content.response";
import MediaModel, { MediaResponse } from "./media.model";

export interface BaseMediaContentResponse extends BaseContentResponse {
  media?: MediaResponse[];
}

export default class BaseMediaContentModel extends BaseContentModel {
  media?: MediaModel[];

  constructor(response: BaseMediaContentResponse) {
    super(response);
    if (response.media && response.media.length > 0) {
      this.media = MapResponseToModel(response.media, MediaModel);
    }
  }
}
