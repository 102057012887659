import React, { useEffect, useState } from "react";
import { ImageProvidersEnum } from "../../../../core/enums/media-providers.enum";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { Button, Empty, Form } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import MediaModel from "../../../../core/models/media/media.model";
import UnsplashSearchModal from "./unsplash-search.modal";
import ImageMediaComponent from "../../media-types/image-media.component";
import styles from "../../media-types/media.module.css";

type Props = {
  media: MediaModel | undefined;
  onSubmit: (
    videoId: string,
    provider: ImageProvidersEnum,
    isValid: boolean,
  ) => void;
};

const UnsplashUploadComponent = (props: Props) => {
  const [t] = useTranslation();
  const { media, onSubmit } = props;
  const [form] = useForm();
  const [url, setUrl] = useState<string>(
    media?.mediaUid
      ? `https://images.unsplash.com/${media.uri}`
      : "https://images.unsplash.com/",
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    form.setFields([
      {
        name: "url",
        value: url,
      },
    ]);

    validateAndSubmit();
  }, [url]);

  async function validateAndSubmit() {
    if (url.length > 0 && !url.includes("https://images.unsplash.com/")) {
      form.setFields([
        {
          name: "url",
          errors: [t("errors.third-party.valid-url", { value: "unsplash" })],
        },
      ]);

      return onSubmit("", ImageProvidersEnum.UNSPLASH, false);
    }
    const urlParts = url.split("/");
    const videoId = urlParts[urlParts.length - 1];
    const isValid = await form.validateFields();

    onSubmit(videoId, ImageProvidersEnum.UNSPLASH, isValid);
  }

  return (
    <>
      <Form
        form={form}
        className={styles.upload_wrapper_vimeo}
        component={false}
      >
        <Form.Item
          name={"url"}
          label={Capitalize(t("content.media-types.IMAGE.label"))}
          style={{ paddingTop: "1.5rem" }}
          rules={getRules()}
        >
          <div className={styles.upload_url_wrapper}>
            <Button
              value={url}
              icon={<FileImageOutlined />}
              autoFocus
              onClick={() => setIsModalVisible(true)}
            >
              {Capitalize(t("common.click-to_x", { verb: t("common.search") }))}
            </Button>
            {renderPreview()}
          </div>
        </Form.Item>
      </Form>

      <UnsplashSearchModal
        visible={isModalVisible}
        onSubmit={(value) => setUrl(value)}
        onCancel={() => setIsModalVisible(false)}
      />
    </>
  );

  function renderPreview() {
    return url !== "https://images.unsplash.com/" ? (
      <div className={styles.upload_preview}>
        <ImageMediaComponent path={url} visible={false} />
      </div>
    ) : (
      <div className={styles.upload_preview_empty}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  function getRules() {
    return [
      {
        required: true,
        message: t("errors.third-party.valid-url", { value: "unsplash" }),
      },
    ];
  }
};

export default UnsplashUploadComponent;
