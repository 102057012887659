import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import colors from "../../styles/colors";
import React from "react";
import CardTypes from "../../../core/types/card.types";
import CardTitleWarningIcon from "./card-title-warning-icon.component";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { selectCardsCountWithSameUidViaUid } from "../../../core/redux/selectors/content/content.selector";
import { connect } from "react-redux";

interface StateProps {
  cardsCountWithSameUid: CardTypes[];
}

interface OwnProps {
  card: CardTypes;
}

type Props = OwnProps & StateProps;

const CardTitleIconComponent = (props: Props) => {
  const { cardsCountWithSameUid, card } = props;

  switch (card.contentState) {
    case ContentStatesEnum.PUBLISHED:
      return <CheckCircleFilled style={{ color: colors.primary }} />;
    case ContentStatesEnum.DRAFT:
      if (cardsCountWithSameUid.length > 1) {
        return <CardTitleWarningIcon card={card} />;
      }

      return <CheckCircleOutlined />;
  }
};

const mapStateToProps = (
  state: StoreInterface,
  ownProps: OwnProps,
): StateProps => ({
  cardsCountWithSameUid: selectCardsCountWithSameUidViaUid(
    state,
    ownProps.card.cardUid,
  ),
});

export default connect(mapStateToProps)(CardTitleIconComponent);
