import React, { useEffect, useState } from "react";
import ChapterModel from "../../../../core/models/content/chapter.model";
import CollectionModel from "../../../../core/models/content/collection.model";
import CardTypes from "../../../../core/types/card.types";
import {
  useCopyCardToCollection,
  useCopyChapterToPlaybook,
  useCopyCollectionToChapter,
} from "../../../../core/features/content/copy/copy-content.hooks";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import { Button, Modal, Tooltip, Tree } from "antd";
import {
  playbooksSelector,
  selectContentTrees,
  selectContentViaContentUids,
} from "../../../../core/redux/selectors/content/content.selector";
import { useSelector } from "react-redux";
import PlaybookModel from "../../../../core/models/content/playbook.model";
import {
  CaretDownOutlined,
  CopyOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import PlaybookDetailModel from "../../../../core/models/content/playbook-detail.model";
import { TreeProps } from "antd/lib/tree/Tree";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import LongPressButtonComponent from "../../long-press/long-press-button.component";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";

const DuplicateContentModal = ({
  visible,
  content,
  onCancel,
}: {
  visible?: boolean;
  content?: ChapterModel | CollectionModel | CardTypes;
  onCancel: () => void;
}) => {
  const { mutateAsync: duplicateChapter, isLoading: isDuplicatingChapter } =
    useCopyChapterToPlaybook();
  const {
    mutateAsync: duplicateCollection,
    isLoading: isDuplicatingCollection,
  } = useCopyCollectionToChapter();
  const { mutateAsync: duplicateCard, isLoading: isDuplicatingCard } =
    useCopyCardToCollection();
  const [selectedTargets, setSelectedTargets] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      setSelectedTargets([]);
    }
  }, [visible]);

  const handleDuplicate = async () => {
    if (!content) {
      return;
    }

    switch (content.contentType) {
      case ContentTypesEnum.CHAPTER:
        await duplicateChapter({
          chapterUid: content.chapterUid,
          playbookUids: selectedTargets,
        });
        break;
      case ContentTypesEnum.COLLECTION:
        await duplicateCollection({
          collectionUid: content.collectionUid,
          chapterUids: selectedTargets,
        });
        break;
      case ContentTypesEnum.CARD:
        await duplicateCard({
          cardUid: content.cardUid,
          collectionUids: selectedTargets,
        });
        break;
    }
    onCancel();
  };

  const getButtonLabel = (key: string) =>
    Capitalize(
      t(key, {
        value: t(
          `translations:content.${content?.contentType.toLowerCase()}.key`,
        ),
      }),
    );

  return (
    <Modal
      destroyOnClose
      className={"Modal_Duplicate_Content"}
      open={visible}
      title={Capitalize(
        t("translations:common.duplicate_x", {
          value: TranslateDefaults(content?.title ?? ""),
        }),
      )}
      onCancel={onCancel}
      bodyStyle={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        padding: 0,
      }}
      width={700}
      footer={[
        <Button key={0} type={"text"} onClick={onCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <LongPressButtonComponent
          key={1}
          icon={<CopyOutlined />}
          type={"primary"}
          ms={1000}
          loading={
            isDuplicatingChapter || isDuplicatingCollection || isDuplicatingCard
          }
          labels={{
            default: getButtonLabel("translations:common.duplicate_x"),
            pressing: getButtonLabel("translations:common.hold_to_duplicate_x"),
            doing: getButtonLabel("translations:common.duplicating_x"),
          }}
          disabled={selectedTargets.length === 0}
          callback={handleDuplicate}
        />,
      ]}
    >
      {!!content && (
        <>
          <div className={"Modal_Duplicate_Content-Description"}>
            <p
              dangerouslySetInnerHTML={{
                __html: Capitalize(
                  t("translations:screens.modal.duplicate.message", {
                    title: TranslateDefaults(content.title),
                    type: t(
                      `translations:content.${getParentContentType(
                        content.contentType,
                      )?.toLowerCase()}.key`,
                    ),
                    suffix: getContentPluralSuffix(
                      getParentContentType(content.contentType),
                    ),
                  }),
                ),
              }}
            />

            {selectedTargets.length > 0 ? (
              <DuplicateContentMessage
                contentUids={selectedTargets}
                contentType={
                  getParentContentType(content.contentType) ??
                  ContentTypesEnum.CHAPTER
                }
              />
            ) : (
              <span style={{ opacity: 0.5 }}>
                {Capitalize(
                  t("translations:common.select_a_x", {
                    item: t(
                      `translations:content.${getParentContentType(
                        content.contentType,
                      )?.toLowerCase()}.key`,
                    ),
                  }),
                )}
              </span>
            )}
          </div>

          <div style={{ maxHeight: 600, overflowY: "auto", padding: "0 24px" }}>
            {content.contentType === ContentTypesEnum.CHAPTER && (
              <DuplicateChapterTree
                chapter={content}
                onCheck={setSelectedTargets}
              />
            )}
            {content.contentType === ContentTypesEnum.COLLECTION && (
              <DuplicateCollectionTree
                collection={content}
                onCheck={setSelectedTargets}
              />
            )}
            {content.contentType === ContentTypesEnum.CARD && (
              <DuplicateCardTree card={content} onCheck={setSelectedTargets} />
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

const getParentContentType = (contentType: ContentTypesEnum) => {
  switch (contentType) {
    case ContentTypesEnum.PLAYBOOK:
      return;
    case ContentTypesEnum.CHAPTER:
      return ContentTypesEnum.PLAYBOOK;
    case ContentTypesEnum.COLLECTION:
      return ContentTypesEnum.CHAPTER;
    case ContentTypesEnum.CARD:
      return ContentTypesEnum.COLLECTION;
  }
};
const getContentPluralSuffix = (contentType?: ContentTypesEnum) => {
  if (!contentType) return;
  switch (contentType) {
    case ContentTypesEnum.PLAYBOOK:
    case ContentTypesEnum.CHAPTER:
      return "-en";
    case ContentTypesEnum.COLLECTION:
    case ContentTypesEnum.CARD:
      return "s";
  }
};

const DuplicateContentMessage = ({
  contentUids,
  contentType,
}: {
  contentUids: string[];
  contentType: ContentTypesEnum;
}) => {
  const { t } = useTranslation();
  const content:
    | PlaybookModel[]
    | ChapterModel[]
    | CollectionModel[]
    | CardTypes[] = useSelector((state) =>
    selectContentViaContentUids(state, contentUids, contentType),
  );
  const maxLength = 3;
  const slicedArray = content.slice(0, maxLength);
  const diff = content.length - maxLength;

  return (
    <>
      <span style={{ marginRight: 5 }}>
        {slicedArray.map((c) => TranslateDefaults(c.title)).join(", ")}
      </span>

      {diff > 0 && (
        <Tooltip
          title={content
            .slice(maxLength)
            .map((c) => TranslateDefaults(c.title))
            .join(", ")}
        >
          <span>{t("translations:common.and")} </span>
          <span
            style={{
              color: "var(--primary-color)",
              textDecoration: "underline",
            }}
          >
            {t("translations:common.x_others", { value: diff })}
          </span>
          <span>.</span>
        </Tooltip>
      )}
    </>
  );
};

const defaultTreeProps: TreeProps<any> = {
  checkable: true,
  blockNode: true,
  defaultExpandAll: true,
  selectable: false,
  className: "Duplicate_Tree",
  switcherIcon: (
    <CaretDownOutlined style={{ fontSize: "16px", lineHeight: "36px" }} />
  ),
};

const DuplicateChapterTree = ({
  chapter,
  onCheck,
}: {
  chapter: ChapterModel;
  onCheck: (selectedKeys: string[]) => void;
}) => {
  const playbooks: PlaybookModel[] = useSelector(playbooksSelector);

  return (
    <Tree
      {...defaultTreeProps}
      treeData={playbooks.map((playbook) => ({
        key: playbook.playbookUid,
        title: TranslateDefaults(playbook.title),
      }))}
      onCheck={(selectedKeys) => {
        if (Array.isArray(selectedKeys) && onCheck) {
          onCheck(selectedKeys.map((k) => k.toString()));
        }
      }}
      titleRender={(nodeData) => (
        <TreeItemTitle nodeData={nodeData} itemUid={chapter.playbookUid} />
      )}
    />
  );
};

const DuplicateCollectionTree = ({
  collection,
  onCheck,
}: {
  collection: CollectionModel;
  onCheck: (selectedKeys: string[]) => void;
}) => {
  const content: PlaybookDetailModel[] = useSelector(selectContentTrees);

  return (
    <Tree
      {...defaultTreeProps}
      treeData={content.map((playbook) => ({
        key: playbook.playbookUid,
        title: TranslateDefaults(playbook.title),
        checkable: false,
        children: playbook.children?.map((chapter) => ({
          key: chapter.chapterUid,
          title: TranslateDefaults(chapter.title),
        })),
      }))}
      onCheck={(selectedKeys) => {
        if (Array.isArray(selectedKeys) && onCheck) {
          onCheck(selectedKeys.map((k) => k.toString()));
        }
      }}
      titleRender={(nodeData) => (
        <TreeItemTitle nodeData={nodeData} itemUid={collection.chapterUid} />
      )}
    />
  );
};
const DuplicateCardTree = ({
  card,
  onCheck,
}: {
  card: CardTypes;
  onCheck: (selectedKeys: string[]) => void;
}) => {
  const content: PlaybookDetailModel[] = useSelector(selectContentTrees);

  return (
    <Tree
      {...defaultTreeProps}
      treeData={content.map((playbook) => ({
        key: playbook.playbookUid,
        title: TranslateDefaults(playbook.title),
        checkable: false,
        children: playbook.children?.map((chapter) => ({
          key: chapter.chapterUid,
          title: TranslateDefaults(chapter.title),
          checkable: false,
          children: chapter.children?.map((collection) => ({
            key: collection.collectionUid,
            title: TranslateDefaults(collection.title),
          })),
        })),
      }))}
      onCheck={(selectedKeys) => {
        if (Array.isArray(selectedKeys) && onCheck) {
          onCheck(selectedKeys.map((k) => k.toString()));
        }
      }}
      titleRender={(nodeData) => (
        <TreeItemTitle nodeData={nodeData} itemUid={card.collectionUid} />
      )}
    />
  );
};

const TreeItemTitle = ({
  nodeData,
  itemUid,
}: {
  nodeData: { key: string; title: string };
  itemUid: string;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <span>{nodeData.title}</span>
      {nodeData.key === itemUid && (
        <div style={{ float: "right", marginRight: "0.5em" }}>
          <Tooltip
            title={Capitalize(t("translations:common.current-location"))}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default DuplicateContentModal;
