import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Form, Typography } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { green, grey } from "@ant-design/colors";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { decryptCipher } from "../../../core/utils/cipher-text";

export interface CreateApiKeyClientFormFields {
  cipher: string;
}

export interface CreateApiKeyClientFormRef {
  form: FormInstance<CreateApiKeyClientFormFields>;
  reset: () => void;
}

const CreateApiKeyClientForm = forwardRef(
  (_, ref: Ref<CreateApiKeyClientFormRef>) => {
    const [t] = useTranslation();
    const [cipher, setCipher] = useState<string>("");
    const [form] = useForm<CreateApiKeyClientFormFields>();
    const [cipherError, setCipherError] = useState<string>();
    const [description, setDescription] = useState<string>();

    useImperativeHandle(ref, () => ({ form, reset }));

    const reset = () => {
      setCipher("");
      setCipherError(undefined);
      setDescription(undefined);
      form.resetFields();
    };

    useEffect(() => {
      if (!cipher) return;

      try {
        setCipherError(undefined);

        const apiHashContent = decryptCipher(cipher);

        if (!apiHashContent) {
          setCipherError(
            t("errors.validation.invalid", {
              item: t("common.shared-content-code"),
            }),
          );
          return;
        }

        setDescription(apiHashContent.description);
      } catch (e: any) {
        setCipherError(e.message);
      }
    }, [cipher]);

    return (
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={cipher}
          name={"cipher"}
          label={
            <Typography.Text
              style={{
                color: grey[6],
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {t("screens.shared-content-client.step-0.form.label")}
            </Typography.Text>
          }
        >
          <TextArea
            onChange={(e) => setCipher(e.target.value)}
            style={{ width: "599px" }}
            rows={4}
          />
        </Form.Item>
        <Form.ErrorList errors={[cipherError]} />
        {description && (
          <Typography.Text style={{ color: green[6] }}>
            <strong>
              {t("screens.shared-content-client.step-0.form.found-content")}:
            </strong>{" "}
            &quot;{description}&quot;
          </Typography.Text>
        )}
      </Form>
    );
  },
);

CreateApiKeyClientForm.displayName = "CreateApiKeyClientForm";

export default CreateApiKeyClientForm;
