import React, { useEffect, useState } from "react";
import {
  usePlaybookDetails,
  usePlaybooks,
} from "../../../../core/hooks/content/content.hook";
import PlaybookModel from "../../../../core/models/content/playbook.model";
import { Link, useHistory } from "react-router-dom";
import Moment from "moment";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Breadcrumb, Menu, Typography } from "antd";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import ChapterModel from "../../../../core/models/content/chapter.model";
import CollectionModel from "../../../../core/models/content/collection.model";
import CardTypes from "../../../../core/types/card.types";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import CardTypeIconComponent from "../../icons/card-type-icon.component";
import ContentType from "../../../../core/types/content.type";

const ContentBreadcrumb = ({ pathSnippets }: { pathSnippets: string[] }) => {
  const [playbookUid, setPlaybookUid] = useState<string>();
  const { data: playbookDetail } = usePlaybookDetails(playbookUid);
  const { data: playbooks, refetch } = usePlaybooks();
  const [recentlyPublishedPlaybooks, setRecentlyPublishedPlaybooks] = useState<
    PlaybookModel[]
  >([]);

  const history = useHistory();
  const { t } = useTranslation();

  const sort = (a: any, b: any) =>
    Moment(b.publishedAt).unix() - Moment(a.publishedAt).unix();

  useEffect(() => {
    const index = pathSnippets.findIndex((s) => s === "playbook");
    if (index === -1) {
      setPlaybookUid(undefined);
      return;
    }

    const playbookUid = pathSnippets[index + 1];
    if (!playbookUid) {
      setPlaybookUid(undefined);
      return;
    }

    setPlaybookUid(playbookUid);
    refetch();
  }, [pathSnippets]);

  useEffect(() => {
    if (!playbooks) {
      setRecentlyPublishedPlaybooks([]);
      return;
    }

    const publishedPlaybooks = playbooks.filter(
      (p) => p.contentState === ContentStatesEnum.PUBLISHED,
    );

    setRecentlyPublishedPlaybooks(publishedPlaybooks.sort(sort));
  }, [playbooks]);

  function handleOnClickToNavigate(
    playbookUid: string,
    chapterUid?: string,
    collectionUid?: string,
    cardUid?: string,
  ) {
    if (chapterUid && collectionUid && cardUid) {
      const path = RoutesEnum.CARD_DETAIL;
      return history.push(
        `${path
          .replace(":playbookUid", playbookUid)
          .replace(":chapterUid", chapterUid)
          .replace(":collectionUid", collectionUid)
          .replace(":cardUid", cardUid)}`,
      );
    }

    if (chapterUid && collectionUid) {
      const path = RoutesEnum.COLLECTION_DETAIL;
      return history.push(
        `${path
          .replace(":playbookUid", playbookUid)
          .replace(":chapterUid", chapterUid)
          .replace(":collectionUid", collectionUid)}`,
      );
    }

    if (chapterUid) {
      const path = RoutesEnum.CHAPTER_DETAIL;
      return history.push(
        `${path
          .replace(":playbookUid", playbookUid)
          .replace(":chapterUid", chapterUid)}`,
      );
    }

    const path = RoutesEnum.MODULE_DETAIL;
    history.push(`${path.replace(":playbookUid", playbookUid)}`);
  }

  function renderBreadcrumbItemDropdown(
    content: ContentType[],
    selectedContent: ContentType,
    onPress: (content: ContentType) => void,
  ) {
    const menu = (
      <Menu>
        {content.map((content, index) => (
          <Menu.Item
            key={index}
            onClick={() => onPress(content)}
            icon={
              content.contentType === ContentTypesEnum.CARD && (
                <CardTypeIconComponent cardType={content.cardType} />
              )
            }
          >
            {t(content.title)}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Breadcrumb.Item key={selectedContent.getId} overlay={menu}>
        <Typography.Text
          ellipsis
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            overflowWrap: "break-word",
            maxWidth: "22rem",
          }}
        >
          {Capitalize(t(selectedContent.title))}
        </Typography.Text>
      </Breadcrumb.Item>
    );
  }

  if (playbookDetail === undefined) {
    return null;
  }

  return (
    <Breadcrumb>
      {pathSnippets.map((value, index) => {
        if (value === ContentTypesEnum.PLAYBOOK.toLowerCase()) {
          return renderBreadcrumbItemDropdown(
            recentlyPublishedPlaybooks,
            playbookDetail,
            (content) => {
              if (content.contentType !== ContentTypesEnum.PLAYBOOK) {
                return;
              }
              handleOnClickToNavigate(content.playbookUid);
            },
          );
        } else if (value === ContentTypesEnum.CHAPTER.toLowerCase()) {
          const chapter = playbookDetail.chapters.find(
            (c) => c.chapterUid === pathSnippets[index + 1],
          );
          const publishedChapters = playbookDetail.chapters.filter(
            (c) => c.contentState === ContentStatesEnum.PUBLISHED,
          );

          if (!chapter) {
            return null;
          }

          return renderBreadcrumbItemDropdown(
            publishedChapters.sort(sort),
            chapter,
            (content) => {
              if (content.contentType !== ContentTypesEnum.CHAPTER) {
                return;
              }
              handleOnClickToNavigate(
                playbookDetail.playbookUid,
                content.chapterUid,
              );
            },
          );
        } else if (value === ContentTypesEnum.COLLECTION.toLowerCase()) {
          let chapter: ChapterModel | undefined;
          let collection: CollectionModel | undefined;
          const collections: CollectionModel[] = [];

          playbookDetail.chapters.forEach((_chapter) => {
            const foundCollection = _chapter.collections.find(
              (c) => c.collectionUid === pathSnippets[index + 1],
            );

            if (foundCollection) {
              collection = foundCollection;
              chapter = _chapter;
            }

            collections.push(..._chapter.collections);
          });

          const publishedCollections = collections
            .filter((c) => c.chapterUid === chapter?.chapterUid)
            .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED);

          if (!collection) {
            return null;
          }

          return renderBreadcrumbItemDropdown(
            publishedCollections.sort(sort),
            collection,
            (content) => {
              if (!chapter) return;

              if (content.contentType !== ContentTypesEnum.COLLECTION) {
                return;
              }

              handleOnClickToNavigate(
                playbookDetail.playbookUid,
                chapter.chapterUid,
                content.collectionUid,
              );
            },
          );
        } else if (value === ContentTypesEnum.CARD.toLowerCase()) {
          let chapter: ChapterModel | undefined;
          let collection: CollectionModel | undefined;
          let card: CardTypes | undefined;
          const cards: CardTypes[] = [];

          playbookDetail.chapters.forEach((_chapter) => {
            _chapter.collections.forEach((_collection) => {
              const foundCard = _collection.cards.find(
                (c) => c.cardUid === pathSnippets[index + 1],
              );

              if (foundCard) {
                card = foundCard;
                chapter = _chapter;
                collection = _collection;
              }

              cards.push(..._collection.cards);
            });
          });

          const publishedCards = cards
            .filter((c) => c.collectionUid === collection?.collectionUid)
            .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED);

          if (!card) {
            return null;
          }

          return renderBreadcrumbItemDropdown(
            publishedCards.sort(sort),
            card,
            (content) => {
              if (!chapter || !collection) return;

              if (content.contentType !== ContentTypesEnum.CARD) {
                return;
              }

              handleOnClickToNavigate(
                playbookDetail.playbookUid,
                chapter.chapterUid,
                collection.collectionUid,
                content.cardUid,
              );
            },
          );
        }

        if (
          pathSnippets[index - 1] === ContentTypesEnum.PLAYBOOK.toLowerCase()
        ) {
          return null;
        } else if (
          pathSnippets[index - 1] === ContentTypesEnum.CHAPTER.toLowerCase()
        ) {
          return null;
        } else if (
          pathSnippets[index - 1] === ContentTypesEnum.COLLECTION.toLowerCase()
        ) {
          return null;
        } else if (
          pathSnippets[index - 1] === ContentTypesEnum.CARD.toLowerCase()
        ) {
          return null;
        }

        return (
          <Breadcrumb.Item
            key={`/${pathSnippets.slice(0, index + 1).join("/")}`}
          >
            <Link to={`/${pathSnippets.slice(0, index + 1).join("/")}`}>
              {Capitalize(t(`containers.${value}.title`))}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default ContentBreadcrumb;
