export interface ApiKeyContentResponse {
  playbookUid: string;
  createdAt: string;
}

export default class ApiKeyContentModel {
  playbookUid: string;
  createdAt: Date;

  constructor(response: ApiKeyContentResponse) {
    this.playbookUid = response.playbookUid;
    this.createdAt = new Date(response.createdAt);
  }
}
