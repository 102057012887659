import {
  Button,
  Card,
  Col,
  message,
  Result,
  Row,
  Space,
  Steps,
  Typography,
} from "antd";
import React, { useState } from "react";
import Dragger from "antd/lib/upload/Dragger";
import { importPlaybook } from "../../../core/hooks/import-export/import-api";
import { useDispatch } from "react-redux";
import ContentAction from "../../../core/redux/stores/content/content.action";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import styles from "../../components/content/content.module.css";
import ContentLabelComponent from "../../components/content/content-label/content-label.component";
import { useTranslation } from "react-i18next";
import importStyles from "./import.module.css";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { UploadOutlined } from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import loadFilesFromZip from "../../../core/utils/load-files-from-zip";

enum ImportingState {
  IDLE,
  REVIEW,
  SUCCESSFULL,
}

const ImportContainer = () => {
  const [playbooks, setPlaybooks] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isImporting, setIsImporting] = useState(false);
  const [importingState, setImportingState] = useState(ImportingState.IDLE);
  const [serverError, setServerError] = useState(false);

  const importPlaybooks = async () => {
    setServerError(false);
    if (playbooks.length === 0) return;

    const calls = playbooks.map(importPlaybook);
    setIsImporting(true);

    try {
      await Promise.all(calls);
      await dispatch(ContentAction.getAllContent());
      setIsImporting(false);
      setImportingState(ImportingState.SUCCESSFULL);
    } catch (e: any) {
      setIsImporting(false);
      setServerError(true);
      message.error(e.message);
    }
  };

  const reset = () => {
    setPlaybooks([]);
    setIsImporting(false);
    setImportingState(ImportingState.IDLE);
    setServerError(false);
  };

  const getCurrentStep = () => {
    switch (importingState) {
      case ImportingState.IDLE:
        return 0;
      case ImportingState.REVIEW:
        return 1;
      case ImportingState.SUCCESSFULL:
        return 2;
    }
  };

  const getStepStatus = ():
    | "finish"
    | "wait"
    | "process"
    | "error"
    | undefined => {
    if (serverError) return "error";

    switch (importingState) {
      case ImportingState.IDLE:
      case ImportingState.REVIEW:
        return "process";
      case ImportingState.SUCCESSFULL:
        return "finish";
    }
  };

  return (
    <PageLayoutComponent menuItems={[]} showSider={false}>
      <div style={{ margin: "0 auto", width: "90rem" }}>
        <Steps
          status={getStepStatus()}
          current={getCurrentStep()}
          style={{ marginBottom: 32 }}
        >
          <Steps.Step
            title={Capitalize(t("common.upload_x", { item: "zip" }))}
          />
          <Steps.Step title={t("containers.import-export.steps.review")} />
          <Steps.Step title={Capitalize(t("common.success"))} />
        </Steps>

        {importingState === ImportingState.IDLE && (
          <Dragger
            name={"file"}
            accept={"zip"}
            showUploadList
            maxCount={1}
            beforeUpload={async (file) => {
              const playbooks = await loadFilesFromZip(file as Blob);

              setPlaybooks(playbooks);
              setImportingState(ImportingState.REVIEW);

              return false;
            }}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              {t("containers.import-export.dragger.text")}
            </p>
            <p className="ant-upload-hint">
              {t("containers.import-export.dragger.hint")}
            </p>
          </Dragger>
        )}

        {importingState === ImportingState.REVIEW && (
          <div>
            <h1>
              {t("content.playbook.keyWithCount_plural", {
                count: playbooks.length,
              }) + " to import"}
            </h1>

            <div className={importStyles.grid}>
              {playbooks.map((playbook, index) => (
                <ImportedPlaybook key={index} playbook={playbook} />
              ))}
            </div>

            <Row style={{ marginTop: 16 }}>
              <Col flex={"auto"} />
              <Col>
                <Button onClick={reset} style={{ marginRight: 16 }}>
                  {Capitalize(t("common.cancel"))}
                </Button>
                <Button
                  loading={isImporting}
                  type={"primary"}
                  onClick={importPlaybooks}
                >
                  {Capitalize(
                    t("common.import_x", {
                      item: t("content.playbook.key_plural", {
                        count: playbooks.length,
                      }),
                    }),
                  )}
                  ({playbooks.length})
                </Button>
              </Col>
            </Row>
          </div>
        )}

        {importingState === ImportingState.SUCCESSFULL && (
          <Result
            status={"success"}
            title={t("containers.import-export.success.title")}
            subTitle={t("containers.import-export.success.subtitle_plural", {
              count: playbooks.length,
              item: t("content.playbook.keyWithCount_plural", {
                count: playbooks.length,
              }),
            })}
            extra={[
              <Link key="manage" to={RoutesEnum.EDIT_MODE}>
                <Button type="primary">
                  {t("containers.modules.button.edit-mode")}
                </Button>
              </Link>,
              <Button key="reset" onClick={reset}>
                {Capitalize(t("common.import_x", { item: t("common.more") }))}
              </Button>,
            ]}
          />
        )}
      </div>
    </PageLayoutComponent>
  );
};

const ImportedPlaybook = ({ playbook }: { playbook: any }) => {
  const { t } = useTranslation();

  const chapterCount = playbook.chapters.length;

  let collectionCount = 0;
  playbook.chapters.forEach(
    (chapter) => (collectionCount += chapter.collections.length),
  );

  let cardCount = 0;
  playbook.chapters.forEach((chapter) =>
    chapter.collections.forEach(
      (collection) => (cardCount += collection.cards.length),
    ),
  );

  return (
    <div className={styles.content_container_no_hover}>
      <Card className={styles.content}>
        <Typography.Title level={3}>{playbook.title}</Typography.Title>
        <Typography.Paragraph>{playbook.description}</Typography.Paragraph>
      </Card>
      <Row className={styles.content_header_content_container}>
        <Col flex={1} style={{ width: "100%" }}>
          <Space size={"large"} style={{ width: "100%" }}>
            <ContentLabelComponent
              contentType={ContentTypesEnum.CHAPTER}
              contentState={ContentStatesEnum.DRAFT}
            >
              {t("content.chapter.keyWithCount", { count: chapterCount })}
            </ContentLabelComponent>

            <ContentLabelComponent
              contentType={ContentTypesEnum.COLLECTION}
              contentState={ContentStatesEnum.DRAFT}
            >
              {t("content.collection.keyWithCount", { count: collectionCount })}
            </ContentLabelComponent>

            <ContentLabelComponent
              contentType={ContentTypesEnum.CARD}
              contentState={ContentStatesEnum.DRAFT}
            >
              {t("content.card.keyWithCount", { count: cardCount })}
            </ContentLabelComponent>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ImportContainer;
