import React, { useEffect } from "react";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { Empty, Layout } from "antd";
import { connect, useDispatch } from "react-redux";
import { selectLearningPathByUid } from "../../../core/redux/selectors/learning-path/learning-path.selector";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import LearningPathDetailOverviewLegacyScreen from "../../screens/learning-path/learning-path-detail-overview-legacy.screen";
import EditPageHeaderComponent from "../../components/page-header/edit-page-header.component";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import styles from "../../components/learning-path/learning-path.module.css";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface StateProps {
  learningPath: LearningPathModel | undefined;
}

type Props = StateProps;

const LearningPathDetailLegacyContainer = ({ learningPath }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ContentAction.getContent(ContentTypesEnum.PLAYBOOK, {
        excludeMedia: true,
      }),
    );
  }, []);

  return (
    <>
      <EditPageHeaderComponent
        title={t("containers.learning-path.title")}
        onClick={() => history.push(RoutesEnum.LEARNING_PATH)}
      />

      <Layout.Content className={styles.learning_container}>
        {learningPath ? (
          <LearningPathDetailOverviewLegacyScreen learningPath={learningPath} />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("errors.general")}
          />
        )}
      </Layout.Content>
    </>
  );
};

const mapStateToProps = (state: StoreInterface, ownProps): StateProps => ({
  learningPath: selectLearningPathByUid(state, ownProps.match.params.uid),
});

export default connect(mapStateToProps)(LearningPathDetailLegacyContainer);
