import React from "react";
import { Form, Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PictureQuizCardModel from "../../../../core/models/content/card-types/picture-quiz/picture-quiz-card.model";
import styles from "../card-types.module.css";
import { Capitalize } from "../../../../core/utils/helper.utils";
import TextEditorPreviewComponent from "../../form-builder/components/text-editor-preview.component";

interface Props {
  cardContent: PictureQuizCardModel;
}

const PictureQuizCardPreviewComponent = ({ cardContent }: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      labelCol={{ span: 6 }}
      layout={"horizontal"}
      labelAlign={"left"}
      className={styles.form_wrapper_horizontal}
    >
      <div className={"Form_item_card"}>
        <Form.Item
          label={
            <Typography.Text strong>
              {Capitalize(t(`form.card.question.label`))}
            </Typography.Text>
          }
        >
          <TextEditorPreviewComponent value={cardContent.data.question} />
        </Form.Item>

        <Form.Item
          label={
            <Typography.Text strong>
              {Capitalize(t(`form.card.answers.label`))}
            </Typography.Text>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {cardContent.data.options.map((option, index) => (
              <div
                key={index}
                style={{
                  marginLeft: index === 0 ? 0 : 10,
                }}
              >
                <Image
                  width={90}
                  height={90}
                  src={option.media?.uri}
                  style={{ objectFit: "cover" }}
                />
              </div>
            ))}
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default PictureQuizCardPreviewComponent;
