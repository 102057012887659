import React from "react";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";
import {
  GradientColors_ProgressCompleted,
  GradientColors_ProgressPending,
  GradientColors_ProgressStarted,
} from "../../styles/colors";

interface OwnProps {
  contentProgress: ContentProgressEnum;
  width?: number;
  height?: number;
}

type Props = OwnProps;

const ContentProgressStateIconComponent = (props: Props) => {
  const { contentProgress, width, height } = props;
  let backgroundColor = GradientColors_ProgressPending.from;

  switch (contentProgress) {
    case ContentProgressEnum.PENDING:
      backgroundColor = GradientColors_ProgressPending.from;
      break;
    case ContentProgressEnum.STARTED:
      backgroundColor = GradientColors_ProgressStarted.from;
      break;
    case ContentProgressEnum.COMPLETED:
      backgroundColor = GradientColors_ProgressCompleted.from;
      break;
  }

  return (
    <div
      style={{
        width,
        height,
        backgroundColor,
        borderRadius: "100%",
      }}
    />
  );
};

ContentProgressStateIconComponent.defaultProps = {
  width: "1.6rem",
  height: "1.6rem",
};

export default ContentProgressStateIconComponent;
