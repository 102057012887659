import React from "react";
import { Button, Layout, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../core/utils/helper.utils";
import animation from "../styles/animations.module.css";

interface Props {
  content: string | null;
  onPress?: () => void;
}

const NoResultsScreen = (props: Props) => {
  const { t } = useTranslation();
  const { onPress, content } = props;

  return (
    <Layout.Content
      data-testid="no-results"
      className={"Empty_wrapper"}
      style={{ height: "50vh" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          className={animation.vibrate}
          alt={"empty state image"}
          style={{ marginBottom: 8 }}
          src={`${process.env.PUBLIC_URL}/assets/images/empty_module_detail.svg`}
          height={108}
          width={128}
        />
        <Typography.Title level={3}>
          {Capitalize(t("containers.published-content.no-results.title"))}
        </Typography.Title>
        <Typography.Text
          style={{ fontSize: "1.5rem", maxWidth: "20em", textAlign: "center" }}
        >
          {Capitalize(t("containers.published-content.no-results.description"))}
          {""}
          {t(`content.${content}.key`)}
          {"."}
        </Typography.Text>
        {onPress && (
          <div style={{ marginTop: "2.5em" }}>
            <Button type={"primary"} onClick={onPress}>
              {t(`containers.published-content.no-results.button.empty-state`)}
            </Button>
          </div>
        )}
      </div>
    </Layout.Content>
  );
};

export default NoResultsScreen;
