import { useQuery } from "@tanstack/react-query";
import {
  getCompletedCards,
  getCompletedUserProgress,
  getContentDirectory,
  getContentInteractions,
  getLatestActivity,
  getPopularUsers,
  getTrackedPlaybooksProgress,
  getUserCollectionCompletion,
  getUserProgress,
  getUserProgressCardDetail,
  getUsersActivity,
  getUsersProgressCollectionDetail,
} from "./user-progress.api";
import { getUsersForPlaybook } from "./user-progress.util";
import { selectUsers } from "../../redux/selectors/user/user.selector";
import { ContentInteractionModel } from "../../models/user-progress/content-interaction.model";
import { DateRangeParamsInterface } from "../../interfaces/date-range-params.interface";
import { ContentProgressEnum } from "../../enums/content-progress.enum";
import { UserStatusEnum } from "../../enums/user-status.enum";
import { TopUsersModel } from "../../models/user-progress/popular-users.model";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import ContentTypesEnum from "../../enums/content-types.enum";
import UsersActivityModel from "../../models/user-progress/users-activity.model";
import CompletedCardsModel from "../../models/user-progress/completed-cards.model";
import LatestActivityModel from "../../models/user-progress/latest-activity.model";
import ContentDirectoryModel from "../../models/user-progress/content-directory/content-directory.model";
import UserProgressResponseType from "../../types/user-progress-response.type";
import ContentUserProgressModel from "../../models/user-progress/user-progress/user-progress-types/content-user-progress.model";
import CompletedUserProgressModel from "../../models/user-progress/completed-user-progress/completed-user-progress.model";
import UserProgressCardDetailModel from "../../models/user-progress/collection-detail/user-progress-card-detail.model";
import UserProgressCollectionDetailModel from "../../models/user-progress/collection-detail/user-progress-collection-detail.model";
import UserCollectionCompletionModel from "../../models/user-progress/user-content-completion/user-collection-completion.model";
import UserCardCompletionModel from "../../models/user-progress/user-content-completion/user-card-completion.model";
import InteractiveCardModel from "../../models/user-progress/interactive-card.model";
import UserProgressModel from "../../models/user-progress/user-progress/user-progress.model";
import UserModel from "../../models/user/user.model";
import rootStore from "../../redux/stores/root.store";

/**
 * Get user progress for a playbook
 * @param playbookUid string
 * @param chapterUid string | undefined
 * @param collectionUid string | undefined
 * @returns Promise<UserProgressResponseType>
 */
export const useUserProgress = (
  playbookUid: string,
  chapterUid?: string,
  collectionUid?: string,
) => {
  return useQuery<any, HttpErrorResponseModel, UserProgressResponseType>(
    ["userProgress", playbookUid, chapterUid, collectionUid],
    async () => {
      const [usersWhoHaveAccess, userProgress] = await Promise.all([
        getUsersForPlaybook(playbookUid),
        getUserProgress(playbookUid),
      ]);

      if (!usersWhoHaveAccess) {
        return userProgress.playbooks.find(
          (p) => p.playbookUid === playbookUid,
        );
      }

      const activeUsers: UserModel[] = usersWhoHaveAccess.filter(
        (u) => u.user_status === UserStatusEnum.CONFIRMED,
      );

      const getFilteredUsersForContent = (
        contentUsers: ContentUserProgressModel[],
      ) => {
        // By default we don't get users who haven't started with this content. We use this function to add those.
        return activeUsers.map((u) => {
          const _user = contentUsers.find((c) => c.userUid === u.sub);
          if (_user) {
            return _user;
          } else {
            return new ContentUserProgressModel({
              userUid: u.sub,
              state: ContentProgressEnum.PENDING,
            });
          }
        });
      };

      const updateContentProgressForUsers = (
        users: ContentUserProgressModel[],
        user: ContentUserProgressModel,
      ) => {
        const index = users.findIndex((u) => u.userUid === user.userUid);
        if (index === -1) {
          users.push(user);
        } else if (
          users[index].state === ContentProgressEnum.COMPLETED &&
          users[index].state !== user.state
        ) {
          users[index].state = ContentProgressEnum.STARTED;
        }
      };

      userProgress.playbooks.forEach((playbook) => {
        playbook.users = getFilteredUsersForContent(playbook.users);

        playbook.chapters.forEach((chapter) => {
          chapter.users = getFilteredUsersForContent(chapter.users);

          chapter.collections.forEach((collection) => {
            collection.users = getFilteredUsersForContent(collection.users);

            collection.users.forEach((user) => {
              updateContentProgressForUsers(chapter.users, user);
              updateContentProgressForUsers(playbook.users, user);
            });
          });
        });
      });

      if (chapterUid) {
        const playbook = userProgress.playbooks.find(
          (p) => p.playbookUid === playbookUid,
        );
        if (!playbook) {
          return;
        }

        const chapter = playbook.chapters.find(
          (c) => c.chapterUid === chapterUid,
        );

        if (collectionUid) {
          if (!chapter) {
            return;
          }

          return chapter.collections.find(
            (c) => c.collectionUid === collectionUid,
          );
        } else {
          return chapter;
        }
      }

      return userProgress.playbooks.find((p) => p.playbookUid === playbookUid);
    },
  );
};

/**
 * Get popular users by activity
 * @returns Promise<TopUsersModel[]>
 */
export const usePopularUsers = (props: DateRangeParamsInterface) => {
  return useQuery<any, HttpErrorResponseModel, TopUsersModel[]>(
    ["popularUsers"],
    async () => {
      const popularUsers = await getPopularUsers(props);
      const users = selectUsers(rootStore.store.getState());
      const popularUserExists = popularUsers.topUsers.filter((u) =>
        users.some((_u) => _u.sub === u.userUid),
      );
      return popularUserExists.sort(
        (a, b) => b.cardsCompleted - a.cardsCompleted,
      );
    },
  );
};

/**
 * Get tracked playbooks for admin user
 * @returns Promise<UserProgressModel>
 */
export const useTrackedPlaybooksProgress = () => {
  return useQuery<any, HttpErrorResponseModel, UserProgressModel>(
    ["trackedPlaybooksProgress"],
    getTrackedPlaybooksProgress,
  );
};

/**
 * Get user latest activities
 * @returns Promise<LatestActivityModel[]>
 */
export const useLatestActivities = () => {
  return useQuery<any, HttpErrorResponseModel, LatestActivityModel[]>(
    ["latestActivity"],
    getLatestActivity,
  );
};

/**
 * Get content directory
 * @returns Promise<ContentDirectoryModel>
 */
export const useContentDirectory = () => {
  return useQuery<any, HttpErrorResponseModel, ContentDirectoryModel>(
    ["contentDirectory"],
    getContentDirectory,
  );
};

/**
 * Get users activity
 * @param props DateRangeParamsInterface
 * @returns Promise<UsersActivityModel>
 */
export const useUsersActivity = (props: DateRangeParamsInterface) => {
  return useQuery<any, HttpErrorResponseModel, UsersActivityModel>(
    ["usersActivity"],
    () => getUsersActivity(props),
  );
};

/**
 * Get completed cards
 * @param props DateRangeParamsInterface
 * @returns Promise<CompletedCardsModel>
 */
export const useCompletedCards = (props: DateRangeParamsInterface) => {
  return useQuery<any, HttpErrorResponseModel, CompletedCardsModel>(
    ["completedCards"],
    () => getCompletedCards(props),
  );
};

/**
 * Get first and last interaction from user
 * @param contentUid string
 * @param contentType ContentTypesEnum
 * @returns Promise<ContentInteractionModel[]>
 */
export const useContentInteractions = (
  contentUid: string,
  contentType: ContentTypesEnum,
) => {
  return useQuery<any, HttpErrorResponseModel, ContentInteractionModel[]>(
    ["contentInteractions", contentUid],
    () => getContentInteractions(contentUid, contentType),
  );
};

/**
 * Get completed user progress
 * @param user UserModel
 * @returns Promise<CompletedUserProgressModel>
 */
export const useCompletedUserProgress = (user: UserModel) => {
  return useQuery<any, HttpErrorResponseModel, CompletedUserProgressModel>(
    ["completedUserProgress", user.sub],
    async () => {
      // Note: When an Admin completed a card when this card is not yet published,
      // this query WILL return the content (W.N.)
      const data = await getCompletedUserProgress(user.username);
      return data.users.find((u) => u.userUid === user.sub);
    },
  );
};

/**
 * Get users progress results for collection details for ALL users
 * @param collectionUid string
 * @param cardUid string | undefined
 * @returns Promise<UserProgressCollectionDetailModel | UserProgressCardDetailModel>
 */
export const useUsersProgressCollectionDetail = (
  collectionUid: string,
  cardUid?: string,
) => {
  return useQuery<
    any,
    HttpErrorResponseModel,
    UserProgressCollectionDetailModel | UserProgressCardDetailModel
  >(["usersProgressCollectionDetail", collectionUid, cardUid], async () => {
    const userProgressCollectionDetail =
      await getUsersProgressCollectionDetail(collectionUid);

    if (cardUid) {
      return userProgressCollectionDetail.cards.find(
        (card) => card.cardUid === cardUid,
      );
    }

    return userProgressCollectionDetail;
  });
};

/**
 * Get user progress answer for card details for all users
 * @param cardUid string
 * @returns Promise<InteractiveCardModel>
 */
export const useUserProgressCardDetail = (cardUid: string) => {
  return useQuery<any, HttpErrorResponseModel, InteractiveCardModel>(
    ["userProgressInteractiveCard", cardUid],
    () => getUserProgressCardDetail(cardUid),
  );
};

/**
 * Get user progress results for collection details ONE specific users
 * @param userUid string
 * @param collectionUid string | undefined
 * @param cardUid string | undefined
 * @returns Promise<UserCollectionCompletionModel | UserCardCompletionModel>
 */
export const useUserContentCompletion = (
  userUid: string,
  collectionUid?: string,
  cardUid?: string,
) => {
  return useQuery<
    any,
    HttpErrorResponseModel,
    UserCollectionCompletionModel | UserCardCompletionModel
  >(["userContentCompletion", userUid, collectionUid, cardUid], async () => {
    if (!collectionUid) {
      return;
    }

    // Note: When an Admin completed a card when this card is not yet published,
    // this query WILL NOT return the content (W.N.)
    const userCardsAnswers = await getUserCollectionCompletion(
      collectionUid,
      userUid,
    );

    if (cardUid) {
      return userCardsAnswers.cards.find((card) => card.cardUid === cardUid);
    }

    return userCardsAnswers;
  });
};
