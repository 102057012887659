import React, { CSSProperties, PropsWithChildren } from "react";
import styles from "./tag.module.css";

interface TagProps {
  style?: CSSProperties;
}

const Tag = ({ children, style }: PropsWithChildren<TagProps>) => (
  <span className={styles.tag} style={style}>
    {children}
  </span>
);

export default Tag;
