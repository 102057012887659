import * as Sentry from "@sentry/react";
import {
  CLIENT_NAME,
  DEBUG,
  PRODUCTION,
  SENTRY_DSN,
  VERSION,
} from "./env.config";

Sentry.init({
  dsn: SENTRY_DSN,
  release: VERSION,
  environment: CLIENT_NAME,
  debug: DEBUG,
  enabled: PRODUCTION,
});
