import UserGroupModel from "../models/user-group/user-group.model";

const roleGroups = ["Admins", "PrimioSupport", "SystemAdmins"];

export function filterRoleGroups(userGroups: UserGroupModel[]) {
  return userGroups.filter((group) => !roleGroups.includes(group.title));
}

export function isRoleGroup(title: string | undefined) {
  return roleGroups.includes(title as string);
}
