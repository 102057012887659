import React from "react";
import { useParams } from "react-router-dom";
import { useGetLearningPath } from "../../../core/api/primio/primioComponents";
import { Card, Divider } from "antd";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import { UserProgressProgressBar } from "../../components/content-user-progress/content-user-progress.bar";
import useLearningPathDetailReport from "../../../core/hooks/useLearningPathDetailReport";
import LearningPathReportTable from "../../components/table/learning-path-report.table";
import { GroupTags } from "../../components/group-tag";
import SectionTitleComponent from "../../components/section-title/section-title.component";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";

const LearningPathDetailReportContainer = () => {
  const { uid: learningPathUid } = useParams<{ uid: string }>();
  const { learningPathReportSummary } =
    useLearningPathDetailReport(learningPathUid);
  const { data: learningPathDetail } = useGetLearningPath({
    pathParams: { learningPathUid },
  });
  const { t } = useTranslation();

  if (!learningPathDetail) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayoutDetailComponent title={"Report: " + learningPathDetail.title}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <Card style={{ width: 300 }}>
          <SectionTitleComponent
            title={Capitalize(t("translations:form.card.description.label"))}
          />
          <p>{learningPathDetail.description}</p>
        </Card>

        <Card style={{ width: 300, marginLeft: 14, pointerEvents: "none" }}>
          <SectionTitleComponent
            title={Capitalize(t("translations:common.summary"))}
          />

          <UserProgressProgressBar
            completed={learningPathReportSummary.completed}
            started={learningPathReportSummary.started}
            notStarted={learningPathReportSummary.notStarted}
            total={learningPathReportSummary.total}
          />
        </Card>

        {learningPathDetail.userGroups.length > 0 && (
          <Card style={{ width: 300, marginLeft: 14 }}>
            <SectionTitleComponent
              title={Capitalize(t("containers.user-groups.key_plural"))}
            />
            <GroupTags groupNames={learningPathDetail.userGroups} />
          </Card>
        )}
      </div>

      <Divider type={"horizontal"} />

      <LearningPathReportTable learningPathUid={learningPathUid} />
    </PageLayoutDetailComponent>
  );
};

export default LearningPathDetailReportContainer;
