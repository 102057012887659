import SourceModel from "../models/source/source.model";
import { API_URL } from "../../constants/config/env.config";

const SourceEndpoints = {
  /**
   * Get Sources endpoint.
   * @returns API_URL + /sources/
   */
  overview: `${API_URL}/sources/`,

  /**
   * GET one Source endpoint.
   * @param sourceUid string
   * @returns API_URL + /sources/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  detail(sourceUid: string) {
    return `${API_URL}/sources/${sourceUid}`;
  },

  /**
   * POST Source endpoint.
   * @returns API_URL + /sources/
   */
  create: `${API_URL}/sources/`,

  /**
   * PUT Source endpoint.
   * @param source SourceModel
   * @returns API_URL + /sources/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  edit(source: SourceModel) {
    return `${API_URL}/sources/${source.sourceUid}`;
  },

  /**
   * DELETE Source endpoint.
   * @param source SourceModel
   * @returns API_URL + /sources/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  delete(source: SourceModel) {
    return `${API_URL}/sources/${source.sourceUid}`;
  },

  /**
   * POST Source endpoint.
   * @returns API_URL + /sources/sort
   */
  sort: `${API_URL}/sources/sort`,
};

export default SourceEndpoints;
