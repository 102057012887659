import CreateBaseContentModel, {
  CreateBaseContentResponse,
} from "./create-base-content.model";

export interface CreateChapterResponse extends CreateBaseContentResponse {
  playbookUid: string;
}

export default class CreateChapterModel extends CreateBaseContentModel {
  playbookUid: string;

  constructor(chapter: CreateChapterResponse) {
    super(chapter);
    this.playbookUid = chapter.playbookUid;
  }
}
