import { InvitationState } from "../../types/invitation.types";

export interface InvitationResponse {
  invitationUid: string;
  email: string;
  name: string;
  userGroups: string[];
  invitationCode: string;
  sentAt: Date | null;
  createdAt: Date;
  completedAt: Date | null;
  failedAt: Date | null;
  failedReason: string;
}

export default class InvitationModel {
  state: InvitationState;
  invitationUid: string;
  email: string;
  name: string;
  userGroups: string[];
  invitationCode: string;
  sentAt?: Date;
  createdAt: Date;
  completedAt?: Date;
  failedAt?: Date;
  failedReason?: string;

  constructor(response: InvitationResponse) {
    this.invitationUid = response.invitationUid;
    this.email = response.email;
    this.name = response.name;
    this.userGroups = response.userGroups;
    this.invitationCode = response.invitationCode;
    this.createdAt = response.createdAt;
    this.state = InvitationState.CREATED;

    if (response.completedAt) {
      this.completedAt = new Date(response.completedAt);
      this.state = InvitationState.COMPLETED;
      return;
    }

    if (response.failedAt) {
      // When a failed invitation is sent again
      if (response.sentAt && response.sentAt > response.failedAt) {
        this.sentAt = new Date(response.sentAt);
        this.state = InvitationState.PENDING;
        return;
      }

      this.failedAt = new Date(response.failedAt);
      this.state = InvitationState.FAILED;
      this.failedReason = response.failedReason;
      return;
    }

    if (response.sentAt) {
      this.sentAt = new Date(response.sentAt);
      this.state = InvitationState.PENDING;
      return;
    }
  }
}
