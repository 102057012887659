import PlaybookDetailModel from "../models/content/playbook-detail.model";
import ContentSortEnum from "../enums/content-sort.enum";

function sortPlaybooks(
  a: PlaybookDetailModel,
  b: PlaybookDetailModel,
  sortOption: ContentSortEnum,
) {
  switch (sortOption) {
    case ContentSortEnum.CUSTOM:
      return b.sort - a.sort;
    case ContentSortEnum.CREATED_AT_ASC:
      return a.createdAt.getTime() - b.createdAt.getTime();
    case ContentSortEnum.CREATED_AT_DESC:
      return b.createdAt.getTime() - a.createdAt.getTime();
    case ContentSortEnum.PUBLISHED_AT_ASC:
      if (a.publishedAt && b.publishedAt) {
        if (a.publishedAt.getTime() > b.publishedAt.getTime()) {
          return 1;
        }

        if (a.publishedAt.getTime() < b.publishedAt.getTime()) {
          return -1;
        }

        return 0;
      }

      if (a.publishedAt && !b.publishedAt) {
        return -1;
      }

      if (!a.publishedAt && b.publishedAt) {
        return 1;
      }

      return 0;
    case ContentSortEnum.PUBLISHED_AT_DESC:
      if (a.publishedAt && b.publishedAt) {
        if (a.publishedAt.getTime() > b.publishedAt.getTime()) {
          return -1;
        }

        if (a.publishedAt.getTime() < b.publishedAt.getTime()) {
          return 1;
        }

        return 0;
      }

      if (a.publishedAt && !b.publishedAt) {
        return -1;
      }

      if (!a.publishedAt && b.publishedAt) {
        return 1;
      }

      return 0;
    case ContentSortEnum.ALPHABETICAL:
      return a.title.trim().localeCompare(b.title.trim());
  }
}

export default sortPlaybooks;
