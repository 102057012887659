import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Tooltip } from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import colors from "../../../styles/colors";
import styles from "../form-builder.module.css";
import CharacterCounter from "./character-counter";

export interface EditableTextProps {
  value: string;
  isPublished: boolean;
  onSubmit?: (value: string) => void;
  tooltip?: string;
  maxLength?: number;
  inputStyle?: React.CSSProperties;
  children: (value: string) => React.ReactElement;
}

const EditableText = ({
  value,
  isPublished,
  onSubmit,
  tooltip,
  maxLength,
  inputStyle,
  children,
}: EditableTextProps) => {
  const [stateValue, setStateValue] = useState<string>(value);
  const [showInput, setShowInput] = useState<boolean>(false);
  const inputRef = useRef<typeof Input | any>(null);

  useEffect(() => {
    setStateValue(value);
  }, [showInput]);

  useEffect(() => {
    if (inputRef.current?.input.value !== value) {
      setShowInput(false);
    }
  }, [value]);

  function handleKeyPress(event) {
    if (event.key !== "Enter") {
      return;
    }

    submitForm();
  }

  function submitForm() {
    if (!onSubmit) {
      return;
    }

    onSubmit(stateValue);
    setShowInput(false);
  }

  useEffect(() => {
    setStateValue(value);
  }, [showInput]);

  return (
    <div>
      <div style={{ paddingTop: "5px", display: "flex" }}>
        <Input
          ref={inputRef}
          type={"text"}
          value={stateValue}
          maxLength={maxLength}
          onKeyPress={handleKeyPress}
          onChange={(e) => setStateValue(e.target.value)}
          suffix={
            <CharacterCounter
              currentLength={stateValue !== null ? stateValue.length : 0}
              maxLength={maxLength ? maxLength : 0}
            />
          }
          style={{
            width: "100%",
            position: showInput ? "relative" : "absolute",
            opacity: showInput ? 1 : 0,
            pointerEvents: showInput ? "auto" : "none",
            ...inputStyle,
          }}
        />

        {showInput ? (
          <>
            <Button
              className={`${styles.btn_edit_header_text}`}
              onClick={() => submitForm()}
            >
              <CheckOutlined />
            </Button>

            <Button
              className={`${styles.btn_edit_header_text}`}
              onClick={() => setShowInput(false)}
            >
              <CloseOutlined />
            </Button>
          </>
        ) : (
          <div className={styles.editable_wrapper}>
            {children(value)}
            <Tooltip title={tooltip}>
              <Button
                className={`${styles.btn_toggle}`}
                onClick={() => {
                  setShowInput(true);
                  inputRef.current?.focus({ cursor: "end" });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            {isPublished ? (
              <CheckCircleFilled style={{ color: colors.primary }} />
            ) : (
              <CheckCircleOutlined />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableText;
