import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../core/providers/auth.provider";
import { useForm } from "antd/lib/form/Form";
import PasswordValidationForm from "../../components/form-builder/password-validation.form";
import Logo from "../../components/logo/logo.component";
import styles from "../../components/auth/login.module.css";

interface ForgetPasswordInterface {
  email: string;
  verificationCode: string;
  password: string;
  passwordConfirm: string;
}

const ChangePasswordScreen = () => {
  const { t } = useTranslation();
  const [form] = useForm<ForgetPasswordInterface>();
  const [email, setEmail] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const { confirmPassword, loading } = useAuth();
  const {
    location: { state: params },
  } = useHistory();

  useEffect(() => {
    const { email: _email } = params as any;

    if (_email) {
      setEmail(_email);
    }
  }, []);

  const onFinish = () => {
    const _password = password.trim();
    const _passwordConfirm = passwordConfirm.trim();

    if (_password !== _passwordConfirm) {
      form.setFields([
        {
          name: "password-confirm",
          errors: [
            t("errors.notAuthorizedException", {
              field: Capitalize(t("form.items.password-confirm.label")),
              item: t("form.items.password.label"),
            }),
          ],
        },
      ]);
      return;
    }

    confirmPassword(email, verificationCode, _password, {
      onFailure: (err) => {
        switch (err.code) {
          default:
            // 'CodeMismatchException': Invalid verification code provided, please try again.
            // 'ExpiredCodeException': If a code isn't requested when using ForgotPassword
            form.setFields([
              {
                name: "verificationCode",
                errors: [err.message],
              },
            ]);
            break;
        }
      },
    });
  };

  return (
    <>
      <Logo className={styles.login_logo} width={218} height={66} />
      <Form form={form} layout={"vertical"} onFinish={onFinish}>
        <Form.Item>
          <h2 className={styles.forget_title}>
            {Capitalize(t("screens.auth.change-password.title"))}
          </h2>
        </Form.Item>

        <Form.Item htmlFor={"email"}>
          <Input
            type={"text"}
            name={"email"}
            value={email}
            prefix={<UserOutlined />}
            disabled
          />
        </Form.Item>

        <Form.Item
          name={"verificationCode"}
          tooltip={t("screens.auth.change-password.tooltip")}
          label={Capitalize(t("form.items.verification-code.label"))}
          extra={t("screens.auth.change-password.subtitle")}
          rules={getRules("verificationCode")}
        >
          <Input
            type={"text"}
            value={verificationCode}
            placeholder={"XXXXXX"}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name={"password"}
          label={Capitalize(t("form.items.password.label"))}
          rules={getRules("password")}
          hasFeedback={true}
        >
          <Input.Password
            type={"password"}
            value={password}
            placeholder={t("form.items.password.label")}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name={"password-confirm"}
          label={Capitalize(t("form.items.password-confirm.label"))}
          rules={getRules("password-confirm")}
        >
          <Input.Password
            type={"password"}
            value={passwordConfirm}
            placeholder={t("form.items.password-confirm.label")}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            className={"Button"}
            type={"primary"}
            htmlType={"submit"}
            loading={loading}
          >
            {Capitalize(t("common.submit"))}
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  function getRules(field: string) {
    if (field === "password") {
      return [
        {
          required: true,
          message: " ",
        },
        {
          pattern: new RegExp(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!\"#$%&'()*+,./:;<=>?@\\[\\]^_`{|}°~-]).{8,}$",
          ),
          message: <PasswordValidationForm password={password} />,
        },
      ];
    }

    return [{ required: true, message: "" }];
  }
};

export default ChangePasswordScreen;
