import React, { useEffect, useState } from "react";
import { Button, DatePicker, Divider } from "antd";
import { useDispatch } from "react-redux";
import TimeBasedContentModel from "../../../core/models/learning-path/time-based-content.model";
import LearningPathModel, {
  LearningPathResponse,
} from "../../../core/models/learning-path/learning-path.model";
import PreviewLearningPathContentComponent from "../../components/learning-path/learning-path-content/preview-learning-path-content.component";
import ProgressBasedContentModel from "../../../core/models/learning-path/progress-based-content.model";
import LearningPathAction from "../../../core/redux/stores/learning-path/learning-path.action";
import LearningPathIntroComponent from "../../components/learning-path/learning-path-intro.component";
import CreateLearningPathContentComponent from "../../components/learning-path/learning-path-content/create-learning-path-content.component";
import DragDropContextComponent from "../../components/draggable/learning-path/drag-drop-context.component";
import DraggableComponent from "../../components/draggable/learning-path/draggable.component";
import styles from "../../components/learning-path/learning-path.module.css";
import {
  CheckCircleOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../../../core/enums/learning-path-state.enum";
import locale from "antd/es/date-picker/locale/nl_NL";
import Moment from "moment";
import AppConfig from "../../../constants/config/app.config";
import LearningPathService from "../../../core/services/learning-path.service";
import LearningPathContextMenu from "../../components/learning-path/learning-path-context-menu";

interface OwnProps {
  learningPath: LearningPathModel;
}

type Props = OwnProps;

const LearningPathDetailOverviewLegacyScreen = ({ learningPath }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [scheduledDate, setScheduledDate] = useState<Moment.Moment>();

  useEffect(() => {
    if (!learningPath.scheduledFor) {
      setScheduledDate(undefined);
      return;
    }

    if (
      learningPath.scheduledFor.toISOString() === "1900-01-01T00:00:00.000Z"
    ) {
      setScheduledDate(undefined);
      return;
    }

    setScheduledDate(Moment(learningPath.scheduledFor));
  }, [learningPath]);

  const disabledDate = (current) => {
    const now = new Date();
    // Can not select days before today
    return current._d < now;
  };

  const handleOnChange = (value: Moment.Moment | null) => {
    if (!value) return;
    setScheduledDate(value);

    const edited = { ...learningPath };
    edited.scheduledFor = value.toDate();
    handleOnEdit(edited);
  };

  const handleSumMinutes = (index) => {
    const previousContent = learningPath.content.slice(0, index);
    let sumMinutes = 0;

    previousContent.forEach((content) => {
      if (content.learningPathType === LearningPathTypeEnum.TIME_BASED) {
        sumMinutes += content.data.minutesAfterStarted;
      }
    });

    return sumMinutes;
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <LearningPathContextMenu learningPath={learningPath} />
      </div>

      <LearningPathIntroComponent learningPath={learningPath} />

      <div style={{ padding: "0 0 2rem 6rem" }}>
        {learningPath.learningPathType ===
          LearningPathTypeEnum.SCHEDULE_BASED && (
          <>
            <Button
              shape={"circle"}
              type={"primary"}
              style={{
                marginTop: "2rem",
                marginRight: "2rem",
                backgroundColor: "var(--black-color)",
                borderColor: "var(--black-color)",
                zIndex: 1,
              }}
            >
              <VerticalAlignBottomOutlined />
            </Button>
            <span style={{ marginRight: "1rem" }}>
              {Capitalize(t("containers.learning-path.pick_date"))}
            </span>
            <DatePicker
              locale={locale}
              format="DD-MM-YYYY HH:mm"
              value={scheduledDate}
              placeholder={Capitalize(t("translations:common.select-date"))}
              disabled={learningPath.state !== LearningPathStateEnum.CREATED}
              disabledDate={disabledDate}
              allowClear={false}
              showTime={{
                defaultValue: Moment("12:00:00", "HH:mm"),
                format: "HH:mm",
              }}
              showNow={false}
              onChange={handleOnChange}
            />
          </>
        )}

        <div style={{ position: "relative" }}>
          {learningPath.state !== LearningPathStateEnum.POSTED ? (
            <DragDropContextComponent
              details={learningPath.content}
              onDragEnd={handleOnDragEnd}
            >
              <div
                className={styles.grid_default}
                style={{ padding: "2rem 0" }}
              >
                <Divider type={"vertical"} dashed className={styles.divider} />

                {learningPath.content.length > 0 &&
                  learningPath.content.map((content, index) => {
                    return (
                      <DraggableComponent
                        key={index}
                        index={index}
                        content={content}
                      >
                        <PreviewLearningPathContentComponent
                          content={content}
                          onEdit={handleOnEdit}
                          minutesOffset={handleSumMinutes(index)}
                        />
                      </DraggableComponent>
                    );
                  })}
              </div>
            </DragDropContextComponent>
          ) : (
            <div className={styles.grid_default} style={{ padding: "2rem 0" }}>
              <Divider type={"vertical"} dashed className={styles.divider} />
              {learningPath.content.map((content, index) => {
                return (
                  <PreviewLearningPathContentComponent
                    key={index}
                    content={content}
                    onEdit={handleOnEdit}
                    minutesOffset={handleSumMinutes(index)}
                  />
                );
              })}
            </div>
          )}
        </div>
        {learningPath.state !== LearningPathStateEnum.POSTED ? (
          <CreateLearningPathContentComponent
            learningPath={learningPath}
            onEdit={handleOnEdit}
            disabled={
              learningPath.learningPathType ===
              LearningPathTypeEnum.SCHEDULE_BASED
                ? scheduledDate === undefined
                : false
            }
          />
        ) : (
          <Button
            shape={"circle"}
            type={"primary"}
            style={{ marginRight: "2rem" }}
          >
            <CheckCircleOutlined />
          </Button>
        )}
      </div>
    </>
  );

  function handleOnEdit(learningPath: LearningPathModel) {
    learningPath =
      LearningPathService.convertContentFromRelativeToAbsolute(learningPath);

    learningPath.content.map((c) => (c.learningPathType = undefined));

    const data: Partial<LearningPathResponse> = {
      title: learningPath.title,
      learningPathType: learningPath.learningPathType,
      content: learningPath.content,
      description: learningPath.description,
      mediaUids: learningPath.mediaUids,
      sort: learningPath.sort,
      userGroups: learningPath.userGroups,
      scheduledFor: learningPath.scheduledFor
        ? learningPath.scheduledFor.toISOString()
        : undefined,
    };

    dispatch(LearningPathAction.editLearningPath(learningPath, data));
  }

  function handleOnDragEnd(
    content: TimeBasedContentModel[] | ProgressBasedContentModel[],
  ) {
    const newContent: TimeBasedContentModel[] | ProgressBasedContentModel[] =
      content.map((item, index) => {
        item.learningPathType = undefined;

        switch (learningPath.learningPathType) {
          case LearningPathTypeEnum.PROGRESS_BASED:
            return item;
          case LearningPathTypeEnum.TIME_BASED:
            item.data.minutesAfterStarted =
              index === 0 ? 0 : item.data.minutesAfterStarted;

            if (index === 0) {
              item.data.notification.title = "";
              item.data.notification.message = "";
            } else if (item.data.notification.title === "") {
              item.data.notification.title =
                AppConfig.learningPathNotificationTitleDefault;
              item.data.notification.message =
                AppConfig.learningPathNotificationMessageDefault;
            }

            return item;
          case LearningPathTypeEnum.SCHEDULE_BASED:
            item.data.minutesAfterStarted =
              index === 0 ? 0 : item.data.minutesAfterStarted;
            return item;
        }
      });

    const data: Partial<LearningPathResponse> = {
      content: newContent,
      title: learningPath.title,
      learningPathType: learningPath.learningPathType,
      description: learningPath.description,
      mediaUids: learningPath.mediaUids,
      sort: learningPath.sort,
    };

    dispatch(LearningPathAction.sortLearningPathContent(learningPath, data));
  }
};

export default LearningPathDetailOverviewLegacyScreen;
