import React, { CSSProperties } from "react";
import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { NavLink } from "react-router-dom";
import StatisticsBlockComponent from "./statistics-block.component";
import ProgressMetaIconComponent from "../icons/progress-meta-icon.component";

interface OwnProps {
  userGroupAcl: string[];
  style?: CSSProperties;
}

type Props = OwnProps;

const AssignedStatisticBlockComponent = ({ userGroupAcl, style }: Props) => {
  const { t } = useTranslation();

  const userGroupTitle =
    userGroupAcl.length === 1
      ? "containers.user-groups.key"
      : "containers.user-groups.key_plural";

  const dropdown = () => {
    return (
      <Menu>
        {userGroupAcl.map((group) => (
          <Menu.Item key={group}>
            <NavLink to={`/user-groups/${group}`}>{group}</NavLink>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  if (userGroupAcl.length === 0) {
    return (
      <StatisticsBlockComponent
        icon={<ProgressMetaIconComponent icon={"assigned"} style={style} />}
        text={"common.assigned_to"}
        title={`${userGroupAcl.length + " " + Capitalize(t(userGroupTitle))}`}
      />
    );
  }

  return (
    <Dropdown overlay={dropdown} placement={"bottomCenter"} arrow>
      <div>
        <StatisticsBlockComponent
          icon={<ProgressMetaIconComponent icon={"assigned"} style={style} />}
          text={"common.assigned_to"}
          title={`${userGroupAcl.length + " " + Capitalize(t(userGroupTitle))}`}
        />
      </div>
    </Dropdown>
  );
};

export default AssignedStatisticBlockComponent;
