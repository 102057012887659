import React from "react";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";

interface LinkProps {
  to: LocationDescriptor;
}

type Props = LinkProps & {
  children: React.ReactNode;
  disabled?: boolean;
};

const DisableableLink = ({ children, disabled, ...linkProps }: Props) => {
  if (disabled) {
    return <>{children}</>;
  }

  return <Link {...linkProps}>{children}</Link>;
};

export default DisableableLink;
