import React, { useState } from "react";
import { SelectionItem, TableRowSelection } from "antd/lib/table/interface";
import { ColumnsType } from "antd/lib/table";
import { Modal, Space, Table, Tag, Typography } from "antd";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Link } from "react-router-dom";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import QrCodeModel from "../../../core/models/qr-code/qr-code.model";
import colors from "../../styles/colors";
import {
  useDeleteQrCode,
  useDraftQrCode,
  usePublishQrCode,
} from "../../../core/features/qr-code/qr-code.hooks";
import {
  selectCardViaId,
  selectChapterViaUid,
  selectCollectionViaUid,
  selectPlaybookViaUid,
} from "../../../core/redux/selectors/content/content.selector";
import ContentTitle from "../../components/content/content-title";

interface Props {
  qrCodes: QrCodeModel[];
  contentState?: ContentStatesEnum;
  onEdit?: (data: QrCodeModel) => void;
}

const QrOverviewScreen = ({ qrCodes, contentState, onEdit }: Props) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");
  const [selectedRow, setSelectedRows] = useState<QrCodeModel[]>([]);
  const { mutate: deleteQr } = useDeleteQrCode();
  const { mutate: publish } = usePublishQrCode();
  const { mutate: draft } = useDraftQrCode();

  const columns: ColumnsType<QrCodeModel> = [
    {
      key: "description",
      title: Capitalize(t("form.card.description.label")),
      dataIndex: "description",
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "description" }),
      render: function renderText(text: string | undefined) {
        return (
          <Typography.Text ellipsis style={{ width: 120 }}>
            {text ? text : "-"}
          </Typography.Text>
        );
      },
    },
    {
      key: "playbook",
      title: Capitalize(t("content.playbook.key")),
      dataIndex: ["data", "playbookUid"],
      width: 120,
      ...GetColumnSearchProps({
        dataIndex: "data",
        dataIndexes: "playbookTitle",
      }),
      render: function renderText(playbookUid: string | undefined) {
        return (
          <ContentTitle selectorFn={selectPlaybookViaUid} uid={playbookUid} />
        );
      },
    },
    {
      key: "chapter",
      title: Capitalize(t("content.chapter.key")),
      dataIndex: ["data", "chapterUid"],
      width: 120,
      ...GetColumnSearchProps({
        dataIndex: "data",
        dataIndexes: "chapterTitle",
      }),
      render: function renderText(chapterUid: string | undefined) {
        return (
          <ContentTitle selectorFn={selectChapterViaUid} uid={chapterUid} />
        );
      },
    },
    {
      key: "collection",
      title: Capitalize(t("content.collection.key")),
      dataIndex: ["data", "collectionUid"],
      width: 120,
      ...GetColumnSearchProps({
        dataIndex: "data",
        dataIndexes: "collectionTitle",
      }),
      render: function renderText(collectionUid: string | undefined) {
        return (
          <ContentTitle
            selectorFn={selectCollectionViaUid}
            uid={collectionUid}
          />
        );
      },
    },
    {
      key: "card",
      title: Capitalize(t("content.card.key")),
      dataIndex: ["data", "cardUid"],
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "data", dataIndexes: "cardTitle" }),
      render: function renderText(cardUid: string | undefined) {
        return <ContentTitle selectorFn={selectCardViaId} uid={cardUid} />;
      },
    },
    {
      key: "isPublic",
      title: Capitalize(t("common.accessibility")),
      dataIndex: "isPublic",
      width: 120,
      sorter: (a, b) => Number(a.isPublic) - Number(b.isPublic),
      render: function renderText(_, data) {
        const color = data.isPublic ? "processing" : "default";
        const state = data.isPublic ? "PUBLIC" : "PRIVATE";
        return (
          <Tag color={color}>
            {Capitalize(t(`screens.accessibility.state.${state}`))}
          </Tag>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      fixed: "right" as const,
      render: function showActions(_, data) {
        return (
          <Space size={"middle"} style={{ float: "right" }}>
            <Link to={{ pathname: `/qr/${data.qrCodeUid as string}` }}>
              <ContentActionComponent
                icon={<ContextActionIconComponent action={"open"} />}
                label={Capitalize(t("common.open"))}
                hideLabel={true}
              />
            </Link>
            <ContentActionComponent
              icon={
                <ContextActionIconComponent
                  action={"edit"}
                  style={{ color: "var(--primary-color)" }}
                />
              }
              label={Capitalize(t("common.edit"))}
              hideLabel={true}
              onClick={() => {
                if (onEdit) onEdit(data);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<QrCodeModel> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (_r, _s, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    selections: handleGetRowSelections(),
  };

  return (
    <Table<QrCodeModel>
      dataSource={qrCodes}
      columns={columns}
      rowKey={(data) => data.qrCodeUid}
      rowSelection={rowSelection}
      pagination={false}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"middle"}
    />
  );

  function handleGetRowSelections(): SelectionItem[] {
    const _draft = {
      key: "draft",
      text: (
        <>
          <ContextActionIconComponent
            action={"draft"}
            style={{ paddingRight: "1rem" }}
          />
          <Typography.Text>
            {Capitalize(t("common.draft-save"))}
          </Typography.Text>
        </>
      ),
      onSelect: () => draftQRCodeModal(selectedRow),
    };

    const _publish = {
      key: "publish",
      text: (
        <>
          <ContextActionIconComponent
            action={"publish"}
            style={{ paddingRight: "1rem" }}
          />
          <Typography.Text>{Capitalize(t("common.publish"))}</Typography.Text>
        </>
      ),
      onSelect: () => publishQRCodeModal(selectedRow),
    };

    const _delete = {
      key: "delete",
      text: (
        <>
          <ContextActionIconComponent
            action={"delete"}
            style={{ color: colors.secondary, paddingRight: "1rem" }}
          />
          <Typography.Text style={{ color: colors.secondary }}>
            {Capitalize(t("common.delete"))}
          </Typography.Text>
        </>
      ),
      onSelect: () => deleteQRCodeModal(selectedRow),
    };

    if (!contentState) {
      return [_publish, _draft, _delete];
    }

    switch (contentState) {
      case ContentStatesEnum.PUBLISHED:
        return [_draft, _delete];
      case ContentStatesEnum.DRAFT:
        return [_publish, _delete];
      case ContentStatesEnum.DELETED:
        return [];
    }
  }

  function deleteQRCodeModal(data: QrCodeModel[]) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete", { field: t("containers.qr.key") }),
      ),
      content: (
        <Typography.Text>
          {Capitalize(t("containers.qr.key"))}: {`(${data.length})`}
        </Typography.Text>
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.delete")),
      onOk() {
        data.forEach((qr: QrCodeModel) => {
          deleteQr(qr.qrCodeUid);
        });
      },
    });
  }

  function publishQRCodeModal(data: QrCodeModel[]) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.publish", { field: t("containers.qr.key") }),
      ),
      icon: (
        <ContextActionIconComponent
          action={"publish"}
          style={{ color: colors.primary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.publish")),
      onOk() {
        data.forEach((qr: QrCodeModel) => {
          publish(qr.qrCodeUid);
        });
      },
    });
  }

  function draftQRCodeModal(data: QrCodeModel[]) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.draft", { field: t("containers.qr.key") }),
      ),
      icon: (
        <ContextActionIconComponent
          action={"draft"}
          style={{ color: colors.primary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.draft-save")),
      onOk() {
        data.forEach((qr: QrCodeModel) => {
          draft(qr.qrCodeUid);
        });
      },
    });
  }
};

export default QrOverviewScreen;
