import React, { useEffect, useState } from "react";
import ContentProgressMeta from "../../content-user-progress/content-progress.meta";
import { useApiKeyClient } from "../../../../core/hooks/api-key/api-key.hook";

const SharedContentMeta = ({
  apiKeyClientUid,
}: {
  apiKeyClientUid: string;
}) => {
  const { data } = useApiKeyClient(apiKeyClientUid);
  const [server, setServer] = useState<string>();

  useEffect(() => {
    if (!data) return;

    setServer(data.server);
  }, [data]);

  if (!server) {
    return null;
  }

  return (
    <div>
      <ContentProgressMeta icon={"shared"} label={server} />
    </div>
  );
};

export default SharedContentMeta;
