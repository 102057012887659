import React from "react";
import CardTypesConnected from "../card-types/card-types.connected";
import CollectionDetailModel from "../../../core/models/content/collection-detail.model";
import SummaryCardComponent from "../card/summary-card.component";
import CardDividerComponent from "../card/card-divider.component";
import CardComponent from "../card/card.component";

interface Props {
  collectionDetail: CollectionDetailModel;
}

const CollectionCardListComponent = (props: Props) => {
  const { collectionUid, startSummary, endSummary, children } =
    props.collectionDetail;

  const sortingOrder: number =
    children && children.length > 0
      ? Math.max(...children.map((c) => c.sort)) + 1
      : 0;

  return (
    <div
      style={{
        paddingRight: "4rem",
        paddingLeft: "4rem",
        alignItems: "center",
      }}
    >
      <CardDividerComponent />
      {startSummary && <SummaryCardComponent summary={startSummary} />}
      {children &&
        children.map((card, index) => {
          return (
            <div key={index}>
              <CardDividerComponent />
              <CardComponent card={card} />
            </div>
          );
        })}
      <CardDividerComponent />
      <CardTypesConnected
        collectionUid={collectionUid}
        sortIndex={sortingOrder}
        cardIndex={children ? children.length + 1 : 1}
      />
      <CardDividerComponent />
      {endSummary && <SummaryCardComponent summary={endSummary} />}
    </div>
  );
};

export default CollectionCardListComponent;
