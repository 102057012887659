import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useTrackPlaybooks,
  useTrackPlaybooksMutation,
} from "../../../core/hooks/track-playbook/track-playbook.hook";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { queryClient } from "../../../core/providers/app.provider";
import { Tooltip } from "antd";
import PlaybookModel from "../../../core/models/content/playbook.model";
import { useSelector } from "react-redux";
import { playbooksSelector } from "../../../core/redux/selectors/content/content.selector";
import animation from "../../styles/animations.module.css";
import styles from "../user-progress/user-progress.module.css";

interface OwnProps {
  uid: string;
}

type Props = OwnProps;

const HeartIconComponent = ({ uid }: Props) => {
  const { t } = useTranslation();
  const { data: trackedPlaybooks } = useTrackPlaybooks();
  const allPlaybooks: PlaybookModel[] = useSelector(playbooksSelector);
  const filterAllPlaybookUids = allPlaybooks.map(
    (playbook) => playbook.playbookUid,
  );

  const { mutate } = useTrackPlaybooksMutation();
  const [wobble, setWobble] = React.useState<number>(0);

  useEffect(() => {
    handleFilteredPlaybookUids();
  }, []);

  return (
    <div
      className={animation.animation_wobble}
      onClick={() => setWobble(1)}
      onAnimationEnd={() => setWobble(0)}
      //@ts-ignore
      wobble={wobble}
    >
      {trackedPlaybooks?.playbookUids.includes(uid) ? (
        <Tooltip
          title={t("screens.user-progress.favorite.heart.remove")}
          mouseEnterDelay={0.8}
        >
          <HeartFilled
            className={styles.heart_icon_filled}
            onClick={toggleTrackedPlaybooks}
          />
        </Tooltip>
      ) : trackedPlaybooks?.playbookUids.length === 3 ? (
        <Tooltip
          title={t("screens.user-progress.favorite.heart.disabled")}
          mouseEnterDelay={0.8}
        >
          <HeartOutlined className={styles.heart_icon_disabled} />
        </Tooltip>
      ) : (
        <Tooltip
          title={t("screens.user-progress.favorite.heart.add")}
          mouseEnterDelay={0.8}
        >
          <HeartOutlined
            className={styles.heart_icon_outlined}
            onClick={toggleTrackedPlaybooks}
          />
        </Tooltip>
      )}
    </div>
  );

  function toggleTrackedPlaybooks() {
    const playbookIsAvailable = filterAllPlaybookUids.includes(uid);

    if (!trackedPlaybooks || !playbookIsAvailable) return;

    const uids = trackedPlaybooks ? trackedPlaybooks.playbookUids : [];
    const index = uids.findIndex((_uid) => _uid === uid);
    index === -1 ? uids.push(uid) : uids.splice(index, 1);
    mutate(uids, {
      onSuccess: () => {
        queryClient.invalidateQueries(["trackPlaybooks"]);
        queryClient.invalidateQueries(["trackedPlaybooksProgress"]);
      },
    });
  }

  function handleFilteredPlaybookUids() {
    if (!trackedPlaybooks) return;

    const filtered = trackedPlaybooks.playbookUids.filter((id) =>
      filterAllPlaybookUids.includes(id),
    );

    mutate(filtered, {
      onSuccess: () => {
        queryClient.invalidateQueries(["trackPlaybooks"]);
        queryClient.invalidateQueries(["trackedPlaybooksProgress"]);
      },
    });
  }
};

export default HeartIconComponent;
