import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DispatchProps } from "./create-content-button.connected";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { grey } from "@ant-design/colors";
import AppConfig from "../../../constants/config/app.config";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import CollectionSummaryModel from "../../../core/models/content/collection-summary.model";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ContentDetailType from "../../../core/types/content-detail.type";
import ContentType from "../../../core/types/content.type";
import stylesButton from "../content-sidebar/content-sidebar.module.css";
import styles from "./create-content-button.module.css";
import CreatePlaybookModal from "../modals/create-playbook.modal";

export interface CreateContentButtonRef {
  renameContent: (content: ContentType) => void;
}

interface MergeProps {
  onSubmit?: (title: string) => void;
  onRename?: (content: ContentType, data: Partial<ContentType>) => void;
}

export interface OwnProps {
  contentType: ContentTypesEnum;
  parentContent: ContentDetailType | undefined;
  onCancel?: () => void;
}

type Props = OwnProps & MergeProps & DispatchProps;

const CreateContentButtonComponent = forwardRef(
  (props: Props, ref: Ref<CreateContentButtonRef>) => {
    const { t } = useTranslation();
    const { contentType } = props;
    const { selectedContentId } = useContentSidebar();
    const [content, setContent] = useState<ContentType>();
    const [title, setTitle] = useState<string>("");
    const [showForm, setShowForm] = useState<boolean>(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<typeof Input | any>(null);
    const [isCreatePlaybookModalVisible, setIsCreatePlaybookModalVisible] =
      useState(false);

    const placeholder = `${Capitalize(t("form.card.title.label"))} ${t(
      "common.new",
    )} ${t(`content.${contentType.toLowerCase()}.key`)}`;
    const label = `+ ${Capitalize(t("common.new"))} ${t(
      `content.${contentType.toLowerCase()}.key`,
    )}`;

    useImperativeHandle(ref, () => ({ renameContent }));

    useEffect(() => {
      if (content) {
        setSelectedTitle();
      }
    }, [content]);

    useEffect(() => {
      // Hide element on clicked outside
      const checkIfClickedOutside = (e) => {
        if (
          showForm &&
          containerRef.current &&
          !containerRef.current.contains(e.target)
        ) {
          setShowForm(false);
          if (props.onCancel) props.onCancel();
        }
      };

      document.addEventListener("mousedown", checkIfClickedOutside);
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [showForm]);

    const renameContent = (content: ContentType) => {
      setContent(content);
      setShowForm(true);
      inputRef.current?.focus({ cursor: "start" });
    };

    const reset = () => {
      setTitle("");
      setShowForm(false);
    };

    const handleOnButtonClick = () => {
      setShowForm(true);
      inputRef.current?.focus({ cursor: "start" });
    };

    const handleOnFinishForm = () => {
      if (props.onRename && props.onCancel && content) {
        props.onRename(content, setData());
        props.onCancel();
      }

      if (props.onSubmit && !content && title) {
        props.onSubmit(title);
      }

      reset();
    };

    // To display a simple button to present the CreatePlaybookModal
    if (props.contentType === ContentTypesEnum.PLAYBOOK) {
      return (
        <>
          <Button
            onClick={() => {
              setIsCreatePlaybookModalVisible(true);
            }}
            block
            type={"text"}
            className={stylesButton.button_sidebar_wrapper}
            style={{ color: grey[2] }}
          >
            {label}
          </Button>
          <CreatePlaybookModal
            visible={isCreatePlaybookModalVisible}
            onCancel={() => setIsCreatePlaybookModalVisible(false)}
          />
        </>
      );
    }

    return (
      <div ref={containerRef}>
        {!showForm && (
          <Button
            onClick={handleOnButtonClick}
            block
            type={"text"}
            className={stylesButton.button_sidebar_wrapper}
            style={{ color: grey[2] }}
          >
            {label}
          </Button>
        )}

        <Form
          onFinish={handleOnFinishForm}
          style={{
            position: showForm ? "relative" : "absolute",
            opacity: showForm ? 1 : 0,
            pointerEvents: showForm ? "auto" : "none",
          }}
        >
          <Row className={styles.container}>
            <Col flex={"auto"}>
              <Input
                ref={inputRef}
                type={"text"}
                value={title}
                maxLength={AppConfig.contentTitleMaxLength}
                onKeyDown={(e) => {
                  if (e.key === "Escape") reset();
                }}
                placeholder={placeholder}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
            <Col flex={"1rem"} />
            <Col>
              <Button
                className={"Button"}
                shape={"circle"}
                type={"primary"}
                htmlType={"submit"}
                icon={<CheckOutlined />}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );

    function setSelectedTitle(): void {
      if (!content) {
        return setTitle("");
      }

      if (
        selectedContentId &&
        content.contentType === ContentTypesEnum.COLLECTION
      ) {
        if (
          selectedContentId.startsWith("start-summary") &&
          content.startSummary
        ) {
          setTitle(TextDecodeUtils(content.startSummary.title));
        } else if (
          selectedContentId.startsWith("end-summary") &&
          content.endSummary
        ) {
          setTitle(TextDecodeUtils(content.endSummary.title));
        } else if (selectedContentId.startsWith("collection")) {
          setTitle(TextDecodeUtils(content.title));
        }
      } else {
        setTitle(TextDecodeUtils(content.title));
      }
    }

    function setData(): Partial<ContentType> {
      let data: Partial<ContentType> = { title: title };

      if (
        selectedContentId &&
        content &&
        content.contentType === ContentTypesEnum.COLLECTION
      ) {
        if (
          selectedContentId.startsWith("start-summary") &&
          content.startSummary
        ) {
          data = {
            startSummary: {
              title: title,
              description: content.startSummary.description,
            },
          } as Partial<CollectionSummaryModel>;
        } else if (
          selectedContentId.startsWith("end-summary") &&
          content.endSummary
        ) {
          data = {
            endSummary: {
              title: title,
              description: content.endSummary.description,
            },
          } as Partial<CollectionSummaryModel>;
        } else if (selectedContentId.startsWith("collection")) {
          return data;
        }
      } else {
        return data;
      }

      return data;
    }
  },
);

CreateContentButtonComponent.displayName = "CreateContentButtonComponent";

export default CreateContentButtonComponent;
