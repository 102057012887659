import React from "react";
import { TextProps } from "antd/lib/typography/Text";
import { Space, Tooltip, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import ContentIconComponent, {
  AntdIconProps,
} from "../../icons/content-icon.component";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import colors from "../../../styles/colors";
import styles from "./content-label.module.css";

interface OwnProps {
  contentType: ContentTypesEnum;
  children: any;
  contentState: ContentStatesEnum;
  textProps?: TextProps;
  linkTo?: string;
  showTooltip?: boolean;
}

type Props = OwnProps & AntdIconProps;

const { Text } = Typography;

const ContentLabelComponent = (props: Props) => {
  const {
    contentType,
    children,
    textProps,
    contentState,
    linkTo,
    showTooltip,
    ...iconProps
  } = props;
  const [t] = useTranslation();

  let iconColor = colors.grey10;
  let className = "textDraft";

  if (contentState === ContentStatesEnum.PUBLISHED) {
    iconColor = colors.primary;
    className = "textPublished";
  }

  const label = () => {
    return (
      <Space>
        <ContentIconComponent
          contentType={contentType}
          iconcolor={iconColor}
          {...iconProps}
        />
        <Text {...textProps} className={styles[className]}>
          {children}
        </Text>

        {showTooltip && contentState === ContentStatesEnum.PUBLISHED && (
          <Tooltip title={Capitalize(t(`content-state.PUBLISHED`))}>
            <CheckCircleFilled style={{ color: colors.primary }} />
          </Tooltip>
        )}
      </Space>
    );
  };

  if (linkTo) {
    return (
      <Link to={linkTo} className={styles.link}>
        {label()}
      </Link>
    );
  }

  return label();
};

export default ContentLabelComponent;
