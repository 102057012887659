import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Empty } from "antd";
import { selectUserGroupByGroupTitle } from "../../../core/redux/selectors/user-group/user-group.selector";
import { useTranslation } from "react-i18next";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import UserGroupDetailOverviewScreen from "../../screens/user-groups/user-group-detail-overview.screen";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import { Capitalize } from "../../../core/utils/helper.utils";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import { EditOutlined } from "@ant-design/icons";
import AppLoader from "../../components/auth/app-loader";
import CreateUserGroupModal from "../../components/modals/create-user-group.modal";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface StateProps {
  match: any;
}

type Props = StateProps;

const UserGroupDetailContainer = ({ match }: Props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const userRole: UserRoleEnum = useSelector(selectCurrentUserRole);
  const userGroup = useSelector((state) =>
    selectUserGroupByGroupTitle(state, match.params.title),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserGroupAction.getUserGroup(match.params.title));
  }, [match]);

  useEffect(() => {
    dispatch(
      ContentAction.getContent(ContentTypesEnum.PLAYBOOK, {
        excludeMedia: true,
      }),
    );
  }, []);

  if (userGroup === undefined) return <AppLoader />;

  return (
    <PageLayoutDetailComponent
      title={userGroup?.title ?? "Oops.."}
      extra={userRole !== UserRoleEnum.GROUP_ADMIN ? renderButton() : undefined}
    >
      {userGroup ? (
        <>
          <UserGroupDetailOverviewScreen userGroup={userGroup} />
          <CreateUserGroupModal
            group={userGroup}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(!isModalVisible)}
          />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("errors.general")}
        />
      )}
    </PageLayoutDetailComponent>
  );

  function renderButton() {
    return (
      <Button
        type={"primary"}
        onClick={() => setIsModalVisible(!isModalVisible)}
        icon={<EditOutlined />}
      >
        {Capitalize(t("containers.user-group-detail.edit"))}
      </Button>
    );
  }
};

export default UserGroupDetailContainer;
