import { Dispatch } from "redux";
import { ThunkType } from "../../../types/redux.type";
import {
  ContentInUserGroupEdit,
  UserGroupEditInterface,
  UsersInUserGroupEditInterface,
} from "../../../interfaces/user-group-create.interface";
import UserGroupModel from "../../../models/user-group/user-group.model";
import ActionUtility from "../../../utils/action.utils";
import UserGroupEffect from "./user-group.effect";
import UserModel from "../../../models/user/user.model";
import UserEffect from "../user/user.effect";
import HttpUtility from "../../../utils/http.utils";
import { ADMIN_GROUP } from "../../../../constants";
import i18n from "i18next";
import { StoreInterface } from "../root.reducer";
import { selectCurrentUserRole } from "../../selectors/user/user.selector";
import UserRoleEnum from "../../../enums/user-role.enum";
import CreateUserGroupModel from "../../../models/user-group/create-user-group.model";
import ContentAction from "../content/content.action";
import ContentTypesEnum from "../../../enums/content-types.enum";

export default class UserGroupAction {
  // Get UserGroups
  static REQUEST_USER_GROUPS = "UserGroupAction.REQUEST_USER_GROUPS";
  static REQUEST_USER_GROUPS_FINISHED =
    "UserGroupAction.REQUEST_USER_GROUPS_FINISHED";

  // Get UserGroup
  static REQUEST_USER_GROUP = "UserGroupAction.REQUEST_USER_GROUP";
  static REQUEST_USER_GROUP_FINISHED =
    "UserGroupAction.REQUEST_USER_GROUP_FINISHED";

  // Create UserGroup
  static REQUEST_CREATE_USER_GROUP =
    "UserGroupAction.REQUEST_CREATE_USER_GROUP";
  static REQUEST_CREATE_USER_GROUP_FINISHED =
    "UserGroupAction.REQUEST_CREATE_USER_GROUP_FINISHED";

  // Edit UserGroup
  static REQUEST_EDIT_USER_GROUP = "UserGroupAction.REQUEST_EDIT_USER_GROUP";

  // Delete UserGroup
  static REQUEST_DELETE_USER_GROUP =
    "UserGroupAction.REQUEST_DELETE_USER_GROUP";

  // Add User(s) to UserGroup
  static REQUEST_ADD_USERS_TO_GROUP =
    "UserGroupAction.REQUEST_ADD_USERS_TO_GROUP";

  // Remove User(s) from UserGroup
  static REQUEST_REMOVE_USERS_FROM_GROUP =
    "UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP";
  static REQUEST_REMOVE_USERS_FROM_GROUP_FINISHED =
    "UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP_FINISHED";

  static REQUEST_UPDATE_USER_GROUP =
    "UserGroupAction.REQUEST_UPDATE_USER_GROUP";
  static SET_SELECTED_USER_GROUP = "UserGroupAction.SET_SELECTED_USER_GROUP";

  /**
   * Get all UserGroups
   * @returns Dispatch UserGroupEffect.getAllUserGroups
   */
  static getAllUserGroups(): ThunkType<UserGroupModel[]> {
    return ActionUtility.createThunk<UserGroupModel[]>(
      UserGroupAction.REQUEST_USER_GROUPS,
      UserGroupEffect.getAllUserGroups,
    );
  }

  /**
   * Get one UserGroup
   * @param groupTitle string
   * @returns Dispatch UserGroupEffect.getUserGroup
   */
  static getUserGroup(groupTitle: string) {
    return ActionUtility.createThunk<UserGroupModel>(
      UserGroupAction.REQUEST_USER_GROUP,
      UserGroupEffect.getUserGroup,
      groupTitle,
    );
  }

  static createUserGroup(data: {
    title: string;
    description: string;
    usernames: string[];
  }) {
    return async (dispatch) => {
      const response = await dispatch(
        ActionUtility.createThunk(
          UserGroupAction.REQUEST_CREATE_USER_GROUP,
          UserGroupEffect.createUserGroup,
          data,
        ),
      );

      await dispatch(UserGroupAction.getUserGroup(response.data));
    };
  }

  /**
   * Edit UserGroup
   * @param groupTitle string
   * @param data UserGroupEditInterface
   * @returns Dispatch UserGroupEffect.editUserGroup
   */
  static editUserGroup(groupTitle: string, data: UserGroupEditInterface) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<CreateUserGroupModel>(
          UserGroupAction.REQUEST_EDIT_USER_GROUP,
          UserGroupEffect.editUserGroup,
          groupTitle,
          data,
        ),
      );

      await dispatch(UserGroupAction.getUserGroup(groupTitle));
    };
  }

  /**
   * Delete UserGroup
   * @param groupTitle string
   * @returns Dispatch UserGroupEffect.deleteUserGroup
   */
  static deleteUserGroup(groupTitle: string) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<UserGroupModel>(
          UserGroupAction.REQUEST_DELETE_USER_GROUP,
          UserGroupEffect.deleteUserGroup,
          groupTitle,
        ),
      );

      await dispatch(UserGroupAction.getAllUserGroups());
    };
  }

  /**
   * Add User(s) to UserGroup
   * @param groupTitle string
   * @param data UsersInUserGroupEditInterface
   * @returns Dispatch UserGroupEffect.addUserToUserGroup
   */
  static addUserToUserGroup(
    groupTitle: string,
    data: UsersInUserGroupEditInterface,
  ) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<UserModel[]>(
          UserGroupAction.REQUEST_ADD_USERS_TO_GROUP,
          UserGroupEffect.addUserToUserGroup,
          groupTitle,
          data,
        ),
      );

      await dispatch(UserGroupAction.getUserGroup(groupTitle));
    };
  }

  /**
   * Remove User(s) from UserGroup
   * @param groupTitle string
   * @param data UsersInUserGroupEditInterface
   * @returns Dispatch UserGroupEffect.removeUserFromUserGroup
   */
  static removeUserFromUserGroup(
    groupTitle: string,
    data: UsersInUserGroupEditInterface,
  ) {
    return async (dispatch: Dispatch<any>, getState: () => StoreInterface) => {
      const state = getState();
      const role = selectCurrentUserRole(state);

      if (!role) {
        return;
      }

      if (role === UserRoleEnum.GROUP_ADMIN) {
        // Only for Group Admins do we need to check the userGroups of the affected user.
        // Group Admins can't delete other Admins or Group Admins from a group.

        const userGroups = await UserEffect.getGroupsForUser(data.usernames[0]);
        if (userGroups.includes(ADMIN_GROUP)) {
          const message = i18n.t(
            "translations:errors.user-group.unable-delete-admin",
          );

          const error = HttpUtility._fillInErrorWithDefaults(
            {
              status: 400,
              errors: [message],
              url: "",
              raw: message,
            },
            { url: "" },
          );

          dispatch(
            ActionUtility.createAction(
              UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP,
            ),
          );
          dispatch(
            ActionUtility.createAction(
              UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP_FINISHED,
              error,
              true,
            ),
          );
          return;
        }
      }

      await dispatch(
        ActionUtility.createThunk<UserModel[]>(
          UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP,
          UserGroupEffect.removeUserFromUserGroup,
          groupTitle,
          data,
        ),
      );

      await dispatch(UserGroupAction.getUserGroup(groupTitle));
    };
  }

  static updateUserGroup(
    apiKeyClientUid: string,
    groupTitle: string,
    data: ContentInUserGroupEdit,
  ) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<UserGroupModel[]>(
          UserGroupAction.REQUEST_UPDATE_USER_GROUP,
          UserGroupEffect.updateUserGroup,
          apiKeyClientUid,
          data,
        ),
      );

      await dispatch(
        ContentAction.getContent(ContentTypesEnum.PLAYBOOK, {
          excludeMedia: true,
        }),
      );
      await dispatch(UserGroupAction.getUserGroup(groupTitle));
    };
  }

  static setSelectedUserGroup(groupTitle: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch(
        ActionUtility.createAction(
          UserGroupAction.SET_SELECTED_USER_GROUP,
          groupTitle,
        ),
      );
    };
  }
}
