import HttpErrorResponseModel from "../models/http-error-response.model";
import HttpUtility from "./http.utils";

export default class EffectUtility {
  static async getToModel<Type>(
    Model: any,
    endpoint: string,
    params?: any,
    ...args: any[]
  ): Promise<Type | HttpErrorResponseModel> {
    const response = await HttpUtility.get(endpoint, params);
    return EffectUtility._restModelCreator<Type>(Model, response, args);
  }

  static async postToModel<Type>(
    Model: any,
    endpoint: string,
    data: any,
    ...args: any[]
  ): Promise<Type | HttpErrorResponseModel> {
    const response = await HttpUtility.post(endpoint, data);
    return EffectUtility._restModelCreator<Type>(Model, response, args);
  }

  static async putToModel<Type>(
    Model: any,
    endpoint: string,
    data: any,
    ...args: any[]
  ): Promise<Type | HttpErrorResponseModel> {
    const response = await HttpUtility.put(endpoint, data);
    return EffectUtility._restModelCreator<Type>(Model, response, args);
  }

  static async patchToModel<Type>(
    Model: any,
    endpoint: string,
    data: any,
    ...args: any[]
  ): Promise<Type | HttpErrorResponseModel> {
    const response = await HttpUtility.patch(endpoint, data);
    return EffectUtility._restModelCreator<Type>(Model, response, args);
  }

  static _restModelCreator<Type>(
    Model: any,
    response: any,
    ...args: any[]
  ): Type | HttpErrorResponseModel {
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    if (response.data.data) {
      return !Array.isArray(response.data.data)
        ? new Model(response.data.data, ...args)
        : response.data.data
            .filter((d) => d !== null)
            .map((json: any) => new Model(json, ...args));
    } else {
      return !Array.isArray(response.data)
        ? new Model(response.data, ...args)
        : response.data
            .filter((d) => d !== null)
            .map((json: any) => new Model(json, ...args));
    }
  }
}
