import serverToClientName from "../../utils/server-to-client-name";

interface ApiKeyClientResponse {
  apiKeyClientUid: string;
  apiKey: string;
  server: string;
  createdAt: string;
}

export default class ApiKeyClientModel {
  apiKeyClientUid: string;
  apiKey: string;
  server: string;
  createdAt: Date;

  constructor(response: ApiKeyClientResponse) {
    this.apiKeyClientUid = response.apiKeyClientUid;
    this.apiKey = response.apiKey;
    this.createdAt = new Date(response.createdAt);

    if (response.server.includes("localhost")) {
      // If server is localhost don't change string
      this.server = response.server;
    } else {
      // now we need to parse the string and get only the client name
      this.server = serverToClientName(response.server);
    }
  }
}
