import ContentEndpoints from "./content.endpoints";
import ContentTypesEnum from "../../../enums/content-types.enum";

export default class CollectionEndpoints extends ContentEndpoints {
  contentType: ContentTypesEnum.COLLECTION;

  constructor() {
    super(ContentTypesEnum.COLLECTION);
    this.contentType = ContentTypesEnum.COLLECTION;
  }
}
