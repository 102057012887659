import React, { HTMLAttributes, useState } from "react";
import { Card, Col, Collapse, Row, Typography } from "antd";
import { UpOutlined } from "@ant-design/icons";
import ContentActionComponent, {
  ContentActionProps,
} from "../content-action/content-action.component";
import styles from "../content/content.module.css";

interface CardBoilerplateProps {
  detail?: JSX.Element;
  shouldEdit: boolean;
  actions?: ContentActionProps[];
}

type Props = CardBoilerplateProps &
  CardCollapseHeaderProps &
  HTMLAttributes<HTMLDivElement>;

const CardBoilerplate = ({
  detail,
  title,
  icon,
  titleIcon,
  actions,
  shouldEdit,
  ...attributes
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(shouldEdit);

  return (
    <div className={styles.content_container} {...attributes}>
      <Card className={styles.content} bodyStyle={{ padding: 0 }}>
        <Collapse
          activeKey={isExpanded ? [0] : shouldEdit ? [0] : []}
          ghost
          onChange={(e) => setIsExpanded(e.length === 1)}
        >
          <Collapse.Panel
            key={0}
            className={"Panel"}
            showArrow={false}
            header={
              <CardCollapseHeader
                title={title}
                icon={icon}
                isExpanded={isExpanded}
                titleIcon={titleIcon}
              />
            }
            collapsible={detail ? "header" : "disabled"}
          >
            <div style={{ padding: "0 1.5rem" }}>{detail && detail}</div>
          </Collapse.Panel>
        </Collapse>
        {actions && (
          <div className={styles.content_actions_container}>
            {actions.map((actionProps, index) => {
              return (
                <div key={index}>
                  {index !== 0 && <Col flex={"3rem"} />}
                  <Col
                    key={index}
                    className={styles.content_action}
                    style={{
                      opacity: isExpanded ? undefined : 0,
                      pointerEvents: isExpanded ? "all" : "none",
                    }}
                  >
                    <ContentActionComponent {...actionProps} />
                  </Col>
                </div>
              );
            })}
          </div>
        )}
      </Card>
    </div>
  );
};

interface CardCollapseHeaderProps {
  title: string;
  icon: JSX.Element;
  isExpanded?: boolean;
  titleIcon?: JSX.Element;
}

const CardCollapseHeader = ({
  title,
  icon,
  isExpanded,
  titleIcon,
}: CardCollapseHeaderProps) => {
  return (
    <Row align={"middle"} className={styles.content_header_card_container}>
      <Col
        flex={1}
        style={{ display: "flex", alignItems: "center", width: "90%" }}
      >
        <Col>{icon}</Col>
        <Col flex={"2rem"} />
        <Col style={{ maxWidth: "55%" }}>
          <Typography.Title ellipsis level={3} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        </Col>
        <Col flex={"2rem"} />
        <Col>{titleIcon}</Col>
      </Col>
      <Col>
        <UpOutlined rotate={isExpanded ? 0 : 180} />
      </Col>
    </Row>
  );
};

export default CardBoilerplate;
