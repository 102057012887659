import { CardTypesEnum } from "../../enums/card-types.enum";
import CreateBaseContentModel, {
  CreateBaseContentResponse,
} from "./create-base-content.model";

export interface CreateCardInterface extends CreateBaseContentResponse {
  collectionUid: string;
  cardType: CardTypesEnum;
  mediaUids: string[];
}

export default class CreateBaseCardModel extends CreateBaseContentModel {
  collectionUid: string;
  mediaUids: string[];

  constructor(card: CreateCardInterface) {
    super(card);
    this.collectionUid = card.collectionUid;
    this.mediaUids = card.mediaUids;
  }
}
