import React, { useEffect } from "react";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { Table, Typography } from "antd";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import Moment from "moment";
import colors from "../../styles/colors";
import graphStyles from "../../components/activity/app-metrics/app-metrics.module.css";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Link } from "react-router-dom";
import { selectPlaybookViaUid } from "../../../core/redux/selectors/content/content.selector";
import { useDispatch, useSelector } from "react-redux";
import Tag from "../../components/tag/tag";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface Props {
  apiKeys: ApiKeyModel[];
}

const SharedContentOverviewScreen = (props: Props) => {
  const { t } = useTranslation();
  const { apiKeys } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ContentAction.getContent(ContentTypesEnum.PLAYBOOK, {
        excludeMedia: true,
      }),
    );
  }, []);

  const columns: ColumnsType<ApiKeyModel> = [
    {
      key: "description",
      title: `${t("common.api").toUpperCase()} ${Capitalize(
        t("form.card.description.label"),
      )}`,
      dataIndex: "description",
    },
    {
      key: "content",
      title: Capitalize(t("form.items.content.label")),
      dataIndex: "content",
      width: "40%",
      render: function renderText(_, data) {
        return data.content.map((item, index) => (
          <PlaybookTag key={index} playbookUid={item.playbookUid} />
        ));
      },
    },
    {
      key: "active",
      title: Capitalize(t("content-state.state")),
      dataIndex: "active",
      width: "10%",
      render: function renderText(_, data) {
        return data.active ? (
          <Typography.Text>
            <CheckCircleOutlined /> Enabled
          </Typography.Text>
        ) : (
          <Typography.Text style={{ color: colors.secondary }}>
            <StopOutlined /> Disabled
          </Typography.Text>
        );
      },
    },
    {
      key: "createdAt",
      title: Capitalize(t("common.date-created")),
      dataIndex: "createdAt",
      width: "15%",
      sorter: (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt),
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.createdAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: "10%",
      fixed: "right" as const,
      render: function showActions(_, data) {
        return (
          <Link
            className={graphStyles.button}
            to={{ pathname: `/shared-content/${data.key as string}` }}
          >
            {`${Capitalize(t("common.show"))} ${t("common.details")}`}
          </Link>
        );
      },
    },
  ];

  return (
    <Table<ApiKeyModel>
      dataSource={apiKeys}
      columns={columns}
      rowKey={(apiKeys) => apiKeys.key}
      tableLayout={"fixed"}
      scroll={{ x: true }}
    />
  );
};

function PlaybookTag({ playbookUid }: { playbookUid: string }) {
  const playbook = useSelector((state) =>
    selectPlaybookViaUid(state, playbookUid),
  );

  if (!playbook) return null;

  return <Tag>{playbook.title}</Tag>;
}

export default SharedContentOverviewScreen;
