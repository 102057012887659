import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import { CardTypesEnum } from "../../../enums/card-types.enum";
import QuizCardDataModel, {
  QuizCardDataResponse,
} from "../../content/card-types/quiz/quiz-card-data.model";

export interface CreateQuizCardResponse extends CreateCardInterface {
  data: QuizCardDataResponse;
}

export default class CreateQuizCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.QUIZ;
  data: QuizCardDataModel;

  constructor(createQuizCard: CreateQuizCardResponse) {
    super(createQuizCard);
    this.cardType = CardTypesEnum.QUIZ;
    this.data = new QuizCardDataModel(createQuizCard.data);
  }
}
