import React, { useEffect, useState } from "react";
import UserModel from "../../../core/models/user/user.model";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserRole,
} from "../../../core/redux/selectors/user/user.selector";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { Dropdown, Menu } from "antd";
import {
  DownOutlined,
  FlagOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useAuth } from "../../../core/providers/auth.provider";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../../constants/routes/app.route";
import styles from "./current-user-menu-item.module.css";

const CurrentUserMenuItem = () => {
  const { logout } = useAuth();
  const { t, i18n } = useTranslation();
  const user: UserModel | undefined = useSelector(selectCurrentUser);
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);

  const [languages, setLanguages] = useState<string[]>([]);

  useEffect(() => {
    const array = [...i18n.languages];
    setLanguages(array.sort());
  }, [i18n]);

  if (!user || !userRole) {
    return null;
  }

  const menu = (
    <Menu>
      <Menu.SubMenu
        key={"0"}
        title={Capitalize(t("translations:common.change-language"))}
        icon={<FlagOutlined />}
      >
        {languages.map((language: string, index: number) => {
          return (
            <Menu.Item key={`lang-${index}`}>
              <a
                style={{
                  color:
                    language === i18n.language
                      ? "var(--primary-color)"
                      : undefined,
                }}
                onClick={() => i18n.changeLanguage(language)}
              >
                {language.toUpperCase()}
              </a>
            </Menu.Item>
          );
        })}
      </Menu.SubMenu>
      <Menu.Item key={"1"} icon={<SettingOutlined />}>
        <Link to={RoutesEnum.USER_DETAIL.replace(":username", user.username)}>
          {Capitalize(t("common.account"))}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={"2"} icon={<LogoutOutlined />}>
        <a onClick={logout}>{Capitalize(t("common.logout"))}</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement={"bottomRight"}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div style={{ marginRight: 12 }}>
            <p className={styles.name}>{user.name}</p>
            <p className={styles.role}>
              {Capitalize(t(`translations:user-role.${userRole}`))}
            </p>
          </div>
          <DownOutlined style={{ fontSize: 12 }} />
        </div>
      </div>
    </Dropdown>
  );
};

export default CurrentUserMenuItem;
