import CreateBaseContentModel, {
  CreateBaseContentResponse,
} from "./create-base-content.model";

export interface CreatePlaybookResponse extends CreateBaseContentResponse {
  mediaUids: string[];
  categoryUids?: string[];
  userGroupAcl?: string[];
}

export default class CreatePlaybookModel extends CreateBaseContentModel {
  mediaUids: string[];
  categoryUids?: string[];
  userGroupAcl?: string[];

  constructor(playbook: CreatePlaybookResponse) {
    super(playbook);
    this.mediaUids = playbook.mediaUids;
    this.categoryUids = playbook.categoryUids;
    this.userGroupAcl = playbook.userGroupAcl;
  }
}
