import LearningPathModel from "../models/learning-path/learning-path.model";
import { ContentStatesEnum } from "../enums/content-states.enum";
import { LearningPathTypeEnum } from "../enums/learning-path-state.enum";
import i18n from "i18next";
import { Playbook } from "../api/primio/primioSchemas";

type ValidationItem = {
  label: string;
  errorMessage: string;
  type?: "info" | "success" | "error" | "warning";
};

export default function learningPathPublicationValidator(
  learningPath: LearningPathModel,
  playbooks: Playbook[],
) {
  const items: ValidationItem[] = [];

  if (!learningPath.color) {
    items.push({
      errorMessage: i18n.t(
        "translations:errors.learning-path.color.description",
      ),
      label: i18n.t("translations:errors.learning-path.color.title"),
      type: "error",
    });
  }

  if (!learningPath.media || learningPath.media.length === 0) {
    items.push({
      errorMessage: i18n.t(
        "translations:errors.learning-path.media.description",
      ),
      label: i18n.t("translations:errors.learning-path.media.title"),
      type: "error",
    });
  }

  if (playbooks.length === 0) {
    items.push({
      errorMessage: i18n.t(
        "translations:errors.learning-path.no-content.description",
      ),
      label: i18n.t("translations:errors.learning-path.no-content.title"),
      type: "error",
    });
  }

  if (learningPath.learningPathType === LearningPathTypeEnum.SCHEDULE_BASED) {
    const hasScheduledFor = !(
      learningPath.scheduledFor === undefined ||
      learningPath.scheduledFor.toISOString() === "1900-01-01T00:00:00.000Z"
    );
    if (!hasScheduledFor) {
      items.push({
        errorMessage: i18n.t(
          "translations:errors.learning-path.required-scheduled-for.description",
        ),
        label: i18n.t(
          "translations:errors.learning-path.required-scheduled-for.title",
        ),
        type: "error",
      });
    }

    if (
      hasScheduledFor &&
      learningPath.scheduledFor &&
      learningPath.scheduledFor < new Date()
    ) {
      items.push({
        errorMessage: i18n.t(
          "translations:errors.learning-path.scheduled-for-past.description",
        ),
        label: i18n.t(
          "translations:errors.learning-path.scheduled-for-past.title",
        ),
        type: "error",
      });
    }
  }

  if (playbooks.length > 0) {
    const draftPlaybooks = playbooks.filter(
      (p) => p.contentState === ContentStatesEnum.DRAFT,
    );
    if (draftPlaybooks.length > 0) {
      items.push({
        errorMessage: `${i18n.t(
          "translations:containers.learning-path.publish.warning.message",
        )}: ${draftPlaybooks.map((p) => i18n.t(p.title)).join(", ")}`,
        label: i18n.t(
          "translations:containers.learning-path.publish.warning.title",
        ),
        type: "warning",
      });
    }
  }

  return items;
}
