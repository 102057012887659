import { EffectType } from "../../../types/redux.type";
import { CreateInvitationInterface } from "../../../interfaces/create-invitation.interface";
import EffectUtility from "../../../utils/effect.utils";
import InvitationModel from "../../../models/invitation/invitation.model";
import InvitationEndpoints from "../../../api/invitation.endpoints";
import HttpUtility from "../../../utils/http.utils";

export default class InvitationEffect {
  /**
   * Get all Invitations via GET Request
   * @returns Promise InvitationModel[]
   */
  static getAllInvitations(): EffectType<InvitationModel[]> {
    const endpoint = InvitationEndpoints.overview;
    return EffectUtility.getToModel<InvitationModel[]>(
      InvitationModel,
      endpoint,
    );
  }

  /**
   * Get one Invitation via GET Request
   * @param invitationCode string
   * @returns Promise InvitationModel
   */
  static getInvitation(invitationCode: string): EffectType<InvitationModel> {
    const endpoint = InvitationEndpoints.detail(invitationCode);
    return EffectUtility.getToModel<InvitationModel>(InvitationModel, endpoint);
  }

  /**
   * Create multiple Invitation via POST Request
   * @param res CreateInvitationInterface[]
   * @returns string
   */
  static createInvitations(
    res: CreateInvitationInterface[],
  ): EffectType<InvitationModel> {
    const endpoint = InvitationEndpoints.createBulk;
    return EffectUtility.postToModel<InvitationModel>(
      InvitationModel,
      endpoint,
      res,
    );
  }

  /**
   * Edit Invitation via POST Request
   * @param invitation InvitationModel
   * @param data Partial<CreateInvitationInterface>
   * @returns null
   */
  static editInvitation(
    invitation: InvitationModel,
    data: Partial<CreateInvitationInterface>,
  ): EffectType<InvitationModel> {
    const endpoint = InvitationEndpoints.edit(invitation);
    return EffectUtility.patchToModel<InvitationModel>(
      InvitationModel,
      endpoint,
      data,
    );
  }

  /**
   * Mark Invitations as Sent via POST Request
   * @param data { invitationUid: string }[]
   * @returns boolean
   */
  static markInvitationsAsSent(data: string[]): EffectType<InvitationModel[]> {
    const endpoint = InvitationEndpoints.sending;
    return HttpUtility.post(endpoint, data);
  }

  /**
   * Delete Invitation via DELETE Request
   * @param invitation InvitationModel
   * @returns null
   */
  static deleteInvitation(
    invitation: InvitationModel,
  ): EffectType<InvitationModel> {
    const endpoint = InvitationEndpoints.delete(invitation);
    return HttpUtility.delete(endpoint);
  }
}
