import BaseCardModel, { BaseCardResponse } from "../../base-card.model";
import { CardTypesEnum } from "../../../../enums/card-types.enum";
import PictureQuizCardDataModel, {
  PictureQuizCardDataResponse,
} from "./picture-quiz-card-data.model";

export interface PictureQuizCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.PICTURE_QUIZ;
  data: PictureQuizCardDataResponse;
}

export default class PictureQuizCardModel extends BaseCardModel {
  cardType: CardTypesEnum.PICTURE_QUIZ;
  data: PictureQuizCardDataModel;

  constructor(quizCard: PictureQuizCardResponse) {
    super(quizCard);
    this.cardType = quizCard.cardType;
    this.data = new PictureQuizCardDataModel(quizCard.data);
  }
}
