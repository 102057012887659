import { api } from "../../../api/config/axiosConfigs";

export const copyChapterToPlaybook = async ({
  chapterUid,
  playbookUid,
}: {
  chapterUid: string;
  playbookUid: string;
}) => {
  const { data } = await api.request<string>({
    url: `/chapters/copied/chapter/${chapterUid}/playbook/${playbookUid}`,
    method: "POST",
  });
  return data;
};
export const copyCollectionToChapter = async ({
  collectionUid,
  chapterUid,
}: {
  collectionUid: string;
  chapterUid: string;
}) => {
  const { data } = await api.request<string>({
    url: `/collections/copied/collection/${collectionUid}/chapter/${chapterUid}`,
    method: "POST",
  });
  return data;
};
export const copyCardToCollection = async ({
  cardUid,
  collectionUid,
}: {
  cardUid: string;
  collectionUid: string;
}) => {
  const { data } = await api.request<string>({
    url: `/cards/copied/card/${cardUid}/collection/${collectionUid}`,
    method: "POST",
  });
  return data;
};
