interface PopularUsersResponseData {
  firstActiveOn: string;
  cardsCompleted: number;
}

interface PopularUsersResponse {
  [userUid: string]: PopularUsersResponseData;
}

export default class PopularUserModel {
  topUsers: TopUsersModel[];

  constructor(response: PopularUsersResponse) {
    this.topUsers = Object.entries(response).map(
      ([key, value]) => new TopUsersModel(key, value),
    );
  }
}

export class TopUsersModel {
  userUid: string;
  firstActiveOn: Date;
  cardsCompleted: number;

  constructor(userUid: string, response: PopularUsersResponseData) {
    this.userUid = userUid;
    this.firstActiveOn = new Date(response.firstActiveOn);
    this.cardsCompleted = response.cardsCompleted;
  }
}
