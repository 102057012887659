import React from "react";
import { MediaTypesEnum } from "../../../../core/enums/media-providers.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import ContentProgressMeta from "../../content-user-progress/content-progress.meta";
import ContentProgressMetaType from "../../../../core/types/content-progress-meta.type";
import CardTypes from "../../../../core/types/card.types";

interface OwnProps {
  card: CardTypes;
}

type Props = OwnProps;

const MediaSuffixTag = ({ card }: Props) => {
  const { t } = useTranslation();

  if (!card.media) {
    return null;
  }

  let icon: ContentProgressMetaType | undefined = undefined;
  switch (card.media[0].mediaType) {
    case MediaTypesEnum.IMAGE:
      icon = "image";
      break;
    case MediaTypesEnum.VIDEO:
      icon = "video";
      break;
    case MediaTypesEnum.AUDIO:
      icon = "audio";
      break;
    case MediaTypesEnum.NO_MEDIA:
      break;
    default:
      icon = "image";
      break;
  }

  return (
    <ContentProgressMeta
      icon={icon}
      displayMode={card.media[0].displayMode}
      label={Capitalize(
        t(`content.media-types.${card.media[0].mediaType}.label`),
      )}
    />
  );
};

export default MediaSuffixTag;
