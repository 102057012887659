import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Form, Input, Switch } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import UserGroupSelectFormItem from "../form-builder/components/user-group-select.form-item";

export interface CreateInvitationFormFields {
  name: string;
  email: string;
  invitationCode: string;
  userGroups: string[];
  sendAt: boolean;
}

export interface CreateInvitationFormRef {
  form: FormInstance<CreateInvitationFormFields>;
}

interface Props {
  invitation: InvitationModel | undefined;
}

const CreateInvitationForm = forwardRef(
  ({ invitation }: Props, ref: Ref<CreateInvitationFormRef>) => {
    const [t] = useTranslation();
    const [form] = useForm<CreateInvitationFormFields>();
    const [name, setName] = useState<string>(invitation ? invitation.name : "");
    const [email, setEmail] = useState<string>(
      invitation ? invitation.email : "",
    );
    const [userGroups, setUserGroups] = useState<string[]>(
      invitation ? invitation.userGroups : [],
    );
    const [sendAt, setSendAt] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
      form.setFields([{ name: "userGroups", value: userGroups }]);
    }, [userGroups]);

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          initialValue={name}
          name={"name"}
          label={Capitalize(t("form.items.name.label"))}
          rules={getRules("name")}
        >
          <Input
            value={name}
            type={"text"}
            style={{ width: "90%" }}
            placeholder={t("form.placeholders.type_x", {
              item: t("form.items.name.label"),
            })}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          initialValue={email}
          name={"email"}
          label={Capitalize(t("form.items.email.label"))}
          rules={getRules("email")}
        >
          <Input
            value={email}
            type={"text"}
            style={{ width: "90%" }}
            placeholder={Capitalize(t("form.items.email.label"))}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <UserGroupSelectFormItem
          initialValue={userGroups}
          onChange={setUserGroups}
          name={"userGroups"}
        />

        <Form.Item
          name={"sendAt"}
          label={Capitalize(t("form.items.send.sent-now"))}
          tooltip={t("form.items.send.sent-now-tooltip")}
        >
          <Switch checked={sendAt} onChange={handleOnChange} />
        </Form.Item>
      </Form>
    );

    function handleOnChange(checked: boolean) {
      form.setFields([{ name: "sendAt", value: checked }]);
      setSendAt(checked);
    }

    function getRules(field: string) {
      const message =
        field === "name" ? "errors.required" : "errors.required-an";

      return [
        {
          required: true,
          message: Capitalize(
            t(message, { item: t(`form.items.${field}.label`) }),
          ),
        },
      ];
    }
  },
);

CreateInvitationForm.displayName = "CreateInvitationForm";

export default CreateInvitationForm;
