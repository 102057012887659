import { AnswerTypeEnum } from "../../../enums/answer-type.enum";

interface UserCardCompletionInterface {
  completedAt: Date;
  answerType: AnswerTypeEnum;
  version: number;
  timeSpent: number;
  answer?: string | number;
}

export interface UserCardCompletionResponse {
  data: { [completedAt: string]: UserCardCompletionInterface };
}

export default class UserCardCompletionModel {
  cardUid: string;
  data: UserCardCompletionInterface[];

  constructor(cardUid: string, response: UserCardCompletionResponse) {
    this.cardUid = cardUid;
    this.data = Object.entries(response.data).map(([key, value]) => {
      return {
        completedAt: new Date(key),
        answerType: value.answerType,
        version: value.version,
        timeSpent: value.timeSpent,
        answer: value.answer,
      };
    });
  }
}
