import React, { useEffect, useState } from "react";
import { Button, Col, Input, Modal, Row, Space, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import UserGroupUsersComponent from "../../components/user-groups/user-group-users.component";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import Moment from "moment";
import styles from "../../components/content/content.module.css";
import StatisticsBlockComponent from "../../components/statistics/statistics-block.component";
import {
  AppstoreOutlined,
  RocketOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PlaybookModel from "../../../core/models/content/playbook.model";
import { useDispatch, useSelector } from "react-redux";
import {
  playbooksSelector,
  selectPlaybooksFilteredByUserGroup,
} from "../../../core/redux/selectors/content/content.selector";
import { ColumnsType } from "antd/lib/table";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import colors from "../../styles/colors";
import UserGroupService from "../../../core/services/user-group.service";
import ContentLearningPathRelationErrorMessage from "../../components/content-context-actions/modal/content-learning-path-relation-error-message";
import { getLearningPathContentForContentType } from "../../../core/hooks/learning-path/learning-path.api";
import { selectUserGroupsFromLearningPathContent } from "../../../core/redux/selectors/learning-path/learning-path.selector";
import UserGroupLearningPathReportTable from "../../components/table/user-group-learning-path-report.table";

const { Search } = Input;

interface OwnProps {
  userGroup: UserGroupModel;
}

type Props = OwnProps;

const UserGroupDetailOverviewScreen = ({ userGroup }: Props) => {
  const [t] = useTranslation();
  const [filteredUserData, setFilteredUserData] = useState<any>({});
  const [userGroupFilteredPlaybooks, setUserGroupFilteredPlaybooks] =
    useState<any>([]);
  const allPlaybooks: PlaybookModel[] = useSelector(playbooksSelector);
  const userGroupFromPlaybooks = selectPlaybooksFilteredByUserGroup(
    allPlaybooks,
    userGroup,
  );
  const dispatch = useDispatch();
  const updatedLearningPaths = useSelector(
    selectUserGroupsFromLearningPathContent,
  );

  useEffect(() => {
    setFilteredUserData(userGroup);
  }, [userGroup]);

  useEffect(() => {
    setUserGroupFilteredPlaybooks(
      selectPlaybooksFilteredByUserGroup(allPlaybooks, userGroup),
    );
  }, [allPlaybooks]);

  const columns: ColumnsType<PlaybookModel> = [
    {
      key: "title",
      title: Capitalize(t("form.items.name.label")),
      render: function renderText(_, data) {
        return <Typography.Text>{data.title}</Typography.Text>;
      },
    },
    {
      dataIndex: "action",
      width: 50,
      fixed: "right" as const,
      render: function showActions(_, data: PlaybookModel) {
        return <Space size={"middle"}>{handleShowActions(data)}</Space>;
      },
    },
  ];

  function handleFilteredUsers(value: string) {
    if (value === "") {
      setFilteredUserData(userGroup);
      return;
    }

    setFilteredUserData({
      ...userGroup,
      users: userGroup.users.filter(
        (user) =>
          user.name.toLowerCase().includes(value.toLowerCase()) ||
          user.email.toLowerCase().includes(value.toLowerCase()),
      ),
    });
  }

  function handleFilteredContent(value: string) {
    if (value === "") {
      setUserGroupFilteredPlaybooks(
        selectPlaybooksFilteredByUserGroup(allPlaybooks, userGroup),
      );
      return;
    }

    setUserGroupFilteredPlaybooks(
      userGroupFilteredPlaybooks.filter((playbook) =>
        playbook.title.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  }

  return (
    <>
      <Row style={{ marginBottom: "5em" }}>
        <Typography.Text>{userGroup.description}</Typography.Text>
      </Row>
      <Row>
        <Typography.Title level={5}>
          {Capitalize(t("containers.user-group-detail.general-info"))}
        </Typography.Title>
      </Row>
      <Row style={{ marginBottom: "5em" }} align={"middle"}>
        <Col className={styles.grid_default_row}>
          <StatisticsBlockComponent
            icon={<RocketOutlined />}
            text={"form.items.created_at.label"}
            title={Moment(userGroup.created_at).format("DD MMM YYYY")}
          />
        </Col>
        <Col className={styles.grid_default_row}>
          <StatisticsBlockComponent
            icon={<UserOutlined />}
            text={"containers.user-group-detail.users"}
            title={`${userGroup.users.length}`}
          />
        </Col>
        <Col className={styles.grid_default_row}>
          <StatisticsBlockComponent
            icon={<AppstoreOutlined />}
            text={"containers.user-group-detail.content"}
            title={`${userGroupFromPlaybooks.length}`}
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: "5em" }}>
        <Col>
          <Typography.Title level={5}>
            {Capitalize(t("translations:containers.learning-path.key_plural"))}
          </Typography.Title>
          <UserGroupLearningPathReportTable userGroup={userGroup.title} />
        </Col>
      </Row>

      <Row className={styles.grid_default} gutter={[16, 16]}>
        <Col span={12} className={styles.grid_default_row}>
          <Typography.Title level={5}>
            {Capitalize(t("containers.user-group-detail.users"))}
          </Typography.Title>
          <Search
            placeholder={Capitalize(
              t("translations:common.search_x", {
                name: `${t("containers.user-group-detail.users")} ${t(
                  "containers.user-group-detail.in-this-group",
                )}`,
              }),
            )}
            onSearch={handleFilteredUsers}
            enterButton
            style={{ marginBottom: "1em" }}
          />
          <UserGroupUsersComponent group={filteredUserData} />
        </Col>
        <Col span={12} className={styles.grid_default_row}>
          <Typography.Title level={5}>
            {Capitalize(t("containers.user-group-detail.exclusive-content"))}
          </Typography.Title>
          <Search
            placeholder={Capitalize(
              t("translations:common.search_x", {
                name: `${t("containers.modules.key")} ${t(
                  "containers.user-group-detail.in-this-group",
                )}`,
              }),
            )}
            onSearch={handleFilteredContent}
            enterButton
            style={{ marginBottom: "1em" }}
          />
          <Table<any>
            size={"small"}
            columns={columns}
            pagination={false}
            expandIconColumnIndex={-1}
            tableLayout="auto"
            dataSource={userGroupFilteredPlaybooks}
            rowKey={(data) => data.playbookUid}
          />
        </Col>
      </Row>
    </>
  );

  function handleShowActions(data: PlaybookModel) {
    return (
      <>
        <Button
          size={"small"}
          type={"text"}
          icon={
            <ContextActionIconComponent
              action={"delete"}
              style={{ color: colors.secondary }}
            />
          }
          onClick={() => deletePlaybookFromGroupModal(data)}
        />
      </>
    );
  }

  async function deletePlaybookFromGroupModal(data: PlaybookModel) {
    const response = await getLearningPathContentForContentType(data);
    const filterLearningPathByUserGroup = updatedLearningPaths.filter((path) =>
      path.userGroups.includes(userGroup.title),
    );
    const getAssociatedLearningPath = filterLearningPathByUserGroup.filter(
      (item) => {
        // Check if the targetContentUid exists in any content object inside the current item
        return item.content.some(
          (contentItem) => contentItem.contentUid === data.playbookUid,
        );
      },
    );

    if (
      getAssociatedLearningPath.length > 0 &&
      response &&
      response.length > 0
    ) {
      return Modal.error({
        title: t("screens.modal.learning-path-relation.subtitle"),
        icon: (
          <ContextActionIconComponent
            action={"delete"}
            style={{ color: colors.secondary }}
          />
        ),
        content: (
          <ContentLearningPathRelationErrorMessage
            userGroup={false}
            relations={response}
          />
        ),
      });
    }

    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete-from-explicit", {
          field: data.title,
          item: userGroup.title,
        }),
      ),
      content: (
        <Typography.Text>
          {Capitalize(t("errors.user-group.no-access-module-users"))}
        </Typography.Text>
      ),
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
      onOk() {
        const userGroupService = new UserGroupService(dispatch);
        userGroupService.removePlaybooksFromUserGroup(
          allPlaybooks,
          userGroup.title,
          [{ playbookUid: data.playbookUid, isShared: !!data.apiKeyClientUid }],
        );
      },
    });
  }
};

export default UserGroupDetailOverviewScreen;
