import ChapterModel, { ChapterResponse } from "./chapter.model";
import CollectionHierarchyModel, {
  CollectionHierarchyResponse,
} from "./collection-hierarchy.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";

export interface ChapterHierarchyResponse extends ChapterResponse {
  collections: CollectionHierarchyResponse[];
}

export default class ChapterHierarchyModel extends ChapterModel {
  collections: CollectionHierarchyModel[];

  constructor(response: ChapterHierarchyResponse) {
    super(response);
    this.collections = MapResponseToModel(
      response.collections,
      CollectionHierarchyModel,
    );
  }
}
