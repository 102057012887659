import { ContentApi } from "../../api/content/content.api";
import ContentTypesEnum from "../../enums/content-types.enum";
import HttpUtility from "../../utils/http.utils";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import { AxiosResponse } from "axios";

export async function getExportedPlaybook(playbookUid: string) {
  const endpoints = new ContentApi(ContentTypesEnum.PLAYBOOK).endpoints;
  if (endpoints.contentType !== ContentTypesEnum.PLAYBOOK) {
    return;
  }

  const endpoint = endpoints.export(playbookUid);
  const response: AxiosResponse | HttpErrorResponseModel =
    await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response.data;
}
