import LearningPathModel from "../models/learning-path/learning-path.model";
import { LearningPathTypeEnum } from "../enums/learning-path-state.enum";
import TimeBasedContentModel from "../models/learning-path/time-based-content.model";

export default class LearningPathService {
  static convertContentFromRelativeToAbsolute(
    learningPath: LearningPathModel,
  ): LearningPathModel {
    if (learningPath.learningPathType === LearningPathTypeEnum.PROGRESS_BASED) {
      return learningPath;
    }

    learningPath.content.forEach((content, index) => {
      if (content.learningPathType !== LearningPathTypeEnum.TIME_BASED) return;
      if (index === 0) return;

      const previousContent = learningPath.content[index - 1];
      if (
        !previousContent ||
        previousContent.learningPathType !== LearningPathTypeEnum.TIME_BASED
      )
        return;

      content.data.minutesAfterStarted +=
        previousContent.data.minutesAfterStarted;
    });
    return learningPath;
  }

  static convertContentFromAbsoluteToRelative(
    content: TimeBasedContentModel[] | undefined,
  ) {
    let sum = 0;

    if (content === undefined) return [];

    content.forEach((item) => {
      item.data.minutesAfterStarted -= sum;
      sum += item.data.minutesAfterStarted;
    });

    return content;
  }
}
