interface UsersAnswersInterface {
  version: number;
  timeSpent: number;
  answer?: string | number;
}

export interface UsersAnswersResponse {
  [timeStamp: string]: UsersAnswersInterface;
}

export default class UsersAnswersModel {
  userUid: string;
  answers: UsersAnswersInterface[];

  constructor(userUid: string, response: UsersAnswersResponse) {
    this.userUid = userUid;

    this.answers = Object.entries(response).map(([key, value]) => ({
      timestamp: new Date(key),
      version: value.version,
      timeSpent: value.timeSpent,
      answer: value.answer,
    }));
  }
}
