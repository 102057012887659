import React, { useEffect } from "react";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import styles from "../../components/user-detail/user-detail.module.css";
import { Col, Row } from "antd";
import SharedContentGeneralInfoComponent from "../../components/shared-content/shared-content-general-info.component";
import SharedContentSharedWithComponent from "../../components/shared-content/shared-content-shared-with.component";
import SharedContentContentLinksComponent from "../../components/shared-content/shared-content-content-links.component";
import { useDispatch } from "react-redux";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface OwnProps {
  apiKey: ApiKeyModel;
}

const SharedContentDetailOverviewScreen = ({ apiKey }: OwnProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ContentAction.getContent(ContentTypesEnum.PLAYBOOK, {
        excludeMedia: true,
      }),
    );
  }, []);

  return (
    <div>
      <Row className={styles.wrapper}>
        <Col span={12}>
          <SharedContentGeneralInfoComponent apiKey={apiKey} />
        </Col>

        <Col span={11}>
          <div style={{ marginBottom: "5rem" }}>
            <SharedContentSharedWithComponent apiKey={apiKey} />
          </div>
          <SharedContentContentLinksComponent apiKey={apiKey} />
        </Col>
      </Row>
    </div>
  );
};

export default SharedContentDetailOverviewScreen;
