export interface CreateBaseContentResponse {
  title: string;
  description: string;
  sort?: number;
}

export default class CreateBaseContentModel {
  title: string;
  description: string;
  sort?: number;

  constructor(content: CreateBaseContentResponse) {
    this.title = content.title;
    this.description = content.description;
    this.sort = content.sort;
  }
}
