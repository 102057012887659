import i18n from "i18next";

export default function TranslateDefaults(value: string) {
  if (!value) {
    return value;
  }

  if (!value.includes("translations:")) {
    return value;
  }

  const parts = value.split("translations:");
  const hex = parts[1].split(" #");

  if (parts[0].startsWith("Copy")) {
    if (parts[1].includes("#")) {
      return parts[0] + i18n.t(hex[0]) + ` #${hex[1]}`;
    } else {
      return parts[0] + i18n.t(parts[1]);
    }
  } else {
    if (parts[1].includes("#")) {
      return i18n.t(hex[0]) + ` #${hex[1]}`;
    } else {
      return i18n.t(value);
    }
  }
}
