import { Dispatch } from "react";
import { connect } from "react-redux";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import CardTypesComponent, { OwnProps } from "./card-types.component";
import CreateKnowledgeCardModel from "../../../core/models/create-content/card-types/create-knowledge-card.model";
import CreateQuizCardModel from "../../../core/models/create-content/card-types/create-quiz-card.model";
import CreateMultipleCardModel from "../../../core/models/create-content/card-types/create-multiple-card.model";
import CreateSliderCardModel from "../../../core/models/create-content/card-types/create-slider-card.model";
import CreateQuestionCardModel from "../../../core/models/create-content/card-types/create-question-card.model";
import CreateCardType from "../../../core/types/create-card.type";
import CreatePictureQuizCardModel from "../../../core/models/create-content/card-types/create-picture-quiz-card.model";

export interface DispatchProps {
  createCard: (data: CreateCardType) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
  createCard: (data: CreateCardType) =>
    dispatch(ContentAction.createContent(ContentTypesEnum.CARD, data)),
});

type MergedProps = OwnProps | DispatchProps;

const mergeProps = (
  mapProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps,
): MergedProps => ({
  ...ownProps,
  ...mapProps,
  ...dispatchProps,
  onSubmit: (cardType: CardTypesEnum) => {
    const { createCard } = dispatchProps;
    const { collectionUid, cardIndex, sortIndex } = ownProps;

    switch (cardType) {
      case CardTypesEnum.KNOWLEDGE_CARD:
        createCard(
          new CreateKnowledgeCardModel({
            title: `translations:content.card-types.KNOWLEDGE_CARD #${cardIndex}`,
            description: "translations:defaults.card.description",
            collectionUid: collectionUid,
            cardType: CardTypesEnum.KNOWLEDGE_CARD,
            mediaUids: [],
            sort: sortIndex,
            data: { text: " " },
          }),
        );
        break;
      case CardTypesEnum.QUIZ:
        createCard(
          new CreateQuizCardModel({
            title: `translations:content.card-types.QUIZ #${cardIndex}`,
            description: "translations:defaults.card.description",
            collectionUid: collectionUid,
            cardType: CardTypesEnum.QUIZ,
            mediaUids: [],
            sort: sortIndex,
            data: {
              question: "translations:form.card.question.placeholder",
              options: [
                {
                  content: "translations:form.card.options.option-one",
                  isCorrect: true,
                  sort: 0,
                },
                {
                  content: "translations:form.card.options.option-two",
                  isCorrect: false,
                  sort: 0,
                },
                {
                  content: "translations:form.card.options.option-three",
                  isCorrect: false,
                  sort: 0,
                },
              ],
            },
          }),
        );
        break;
      case CardTypesEnum.MULTIPLE_CHOICE:
        createCard(
          new CreateMultipleCardModel({
            title: `translations:content.card-types.MULTIPLE_CHOICE #${cardIndex}`,
            description: "translations:defaults.card.description",
            collectionUid: collectionUid,
            cardType: CardTypesEnum.MULTIPLE_CHOICE,
            mediaUids: [],
            sort: sortIndex,
            data: {
              question: "translations:form.card.question.placeholder",
              options: [
                {
                  content: "translations:form.card.options.option-one",
                  explanation: "translations:form.card.answers.feedback",
                  isCorrect: true,
                  sort: 0,
                },
                {
                  content: "translations:form.card.options.option-two",
                  explanation: "translations:form.card.answers.feedback",
                  isCorrect: false,
                  sort: 0,
                },
                {
                  content: "translations:form.card.options.option-three",
                  explanation: "translations:form.card.answers.feedback",
                  isCorrect: false,
                  sort: 0,
                },
              ],
            },
          }),
        );
        break;
      case CardTypesEnum.SLIDER:
        createCard(
          new CreateSliderCardModel({
            title: `translations:content.card-types.SLIDER #${cardIndex}`,
            description: "translations:defaults.card.description",
            collectionUid: collectionUid,
            cardType: CardTypesEnum.SLIDER,
            mediaUids: [],
            sort: sortIndex,
            data: {
              question: "translations:form.card.question.placeholder",
              options: [
                {
                  label: "⭐",
                  sort: 0,
                },
                {
                  label: "⭐⭐",
                  sort: 0,
                },
                {
                  label: "⭐⭐⭐",
                  sort: 0,
                },
              ],
            },
          }),
        );
        break;
      case CardTypesEnum.QUESTION:
        createCard(
          new CreateQuestionCardModel({
            title: `translations:content.card-types.QUESTION #${cardIndex}`,
            description: "translations:defaults.card.description",
            collectionUid: collectionUid,
            cardType: CardTypesEnum.QUESTION,
            mediaUids: [],
            sort: sortIndex,
            data: {
              question: "translations:form.card.question.placeholder",
              explanation: "translations:form.card.explanation.placeholder",
            },
          }),
        );
        break;
      case CardTypesEnum.PICTURE_QUIZ:
        createCard(
          new CreatePictureQuizCardModel({
            title: `translations:content.card-types.PICTURE_QUIZ #${cardIndex}`,
            description: "translations:defaults.card.description",
            collectionUid: collectionUid,
            cardType: CardTypesEnum.PICTURE_QUIZ,
            mediaUids: [],
            sort: sortIndex,
            data: {
              question: "translations:form.card.question.placeholder",
              options: [],
            },
          }),
        );
        break;
    }
  },
});

const CardTypesConnected = connect(null, mapDispatchToProps, mergeProps, {
  forwardRef: true,
})(CardTypesComponent);

export default CardTypesConnected;
