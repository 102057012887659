import React, { forwardRef, Ref, useImperativeHandle } from "react";
import ContentType from "../../../core/types/content.type";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import ContentAction from "../../../core/redux/stores/content/content.action";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import colors from "../../styles/colors";
import { getLearningPathContentForContentType } from "../../../core/hooks/learning-path/learning-path.api";
import ContentLearningPathModel from "../../../core/models/content/content-learning-path.model";
import ContentLearningPathRelationErrorMessage from "./modal/content-learning-path-relation-error-message";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import Emitter from "../../../core/utils/emitter.utils";

export interface ContentContextRef {
  duplicateContent: (content: ContentType) => void;
  deleteContent: (content: ContentType) => void;
  publishContent: (content: ContentType) => void;
  draftContent: (content: ContentType) => void;
}

const ContentContextActionsComponent = forwardRef(
  (props, ref: Ref<ContentContextRef>) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      duplicateContent,
      deleteContent,
      publishContent,
      draftContent,
    }));

    function duplicateContent(content: ContentType) {
      if (content.contentType === ContentTypesEnum.PLAYBOOK) {
        Modal.confirm({
          title: Capitalize(
            t("errors.warnings.duplicate", {
              field: Capitalize(t(`content.${getContentType(content)}.key`)),
              item: ` “${TextDecodeUtils(content.title)}”`,
            }),
          ),
          icon: <ContextActionIconComponent action={"duplicate"} />,
          okText: Capitalize(t("common.duplicate")),
          onOk: () => dispatch(ContentAction.duplicateContent(content)),
          cancelText: Capitalize(t("common.cancel")),
          cancelButtonProps: { type: "text" },
        });
        return;
      }

      Emitter.emit("handleDuplicateContent", content);
    }

    async function deleteContent(content: ContentType) {
      const contentType = getContentType(content);
      const response = await getLearningPathContentForContentType(content);
      if (response && response.length > 0) {
        // triggered when this content or its children are connected to a learningPath
        return alertContentLearningPath(response);
      }

      const modalTitle = Capitalize(
        t("errors.warnings.delete", {
          field: Capitalize(t(`content.${contentType}.key`)),
          item: ` “${TextDecodeUtils(content.title)}”`,
        }),
      );
      deleteContentModal(modalTitle, content);
    }

    function publishContent(content: ContentType) {
      Modal.confirm({
        title: Capitalize(
          t("errors.warnings.publish", {
            field: Capitalize(t(`content.${getContentType(content)}.key`)),
            item: ` “${TextDecodeUtils(content.title)}”`,
          }),
        ),
        icon: (
          <ContextActionIconComponent
            action={"publish"}
            style={{ color: colors.primary }}
          />
        ),
        okText: Capitalize(t("common.publish")),
        onOk: () => dispatch(ContentAction.publishContent(content)),
        cancelText: Capitalize(t("common.cancel")),
        cancelButtonProps: { type: "text" },
      });
    }

    function draftContent(content: ContentType) {
      Modal.confirm({
        title: Capitalize(
          t("errors.warnings.draft", {
            field: Capitalize(t(`content.${getContentType(content)}.key`)),
            item: ` “${TextDecodeUtils(content.title)}”`,
          }),
        ),
        icon: (
          <ContextActionIconComponent
            action={"draft"}
            style={{ color: colors.primary }}
          />
        ),
        okText: Capitalize(t("common.draft-save")),
        onOk: () => dispatch(ContentAction.draftContent(content)),
        cancelText: Capitalize(t("common.cancel")),
        cancelButtonProps: { type: "text" },
      });
    }

    function getContentType(content: ContentType) {
      return content.contentType.toLowerCase();
    }

    function alertContentLearningPath(relations: ContentLearningPathModel[]) {
      Modal.error({
        title: t("screens.modal.learning-path-relation.subtitle"),
        icon: (
          <ContextActionIconComponent
            action={"delete"}
            style={{ color: colors.secondary }}
          />
        ),
        content: (
          <ContentLearningPathRelationErrorMessage relations={relations} />
        ),
      });
    }

    function deleteContentModal(title: string, content: ContentType) {
      Modal.confirm({
        title,
        icon: (
          <ContextActionIconComponent
            action={"delete"}
            style={{ color: colors.secondary }}
          />
        ),
        cancelText: Capitalize(t("common.cancel")),
        cancelButtonProps: { type: "text" },
        okText: Capitalize(t("common.delete")),
        onOk: () => dispatch(ContentAction.deleteContent(content)),
      });
    }

    return null;
  },
);

ContentContextActionsComponent.displayName = "ContentContextActionsComponent";

export default ContentContextActionsComponent;
