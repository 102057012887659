import { Dispatch } from "redux";
import { ThunkType } from "../../../types/redux.type";
import { NotificationCreateInterface } from "../../../interfaces/notification-create.interface";
import NotificationTypes from "../../../types/notification.types";
import { getFirstDayOfCurrentWeek } from "../../../utils/get-first-day-of-current-week.utils";
import ActionUtility from "../../../utils/action.utils";
import NotificationEffect from "./notification.effect";
import BaseNotificationCountModel from "../../../models/notification/base-notification-count.model";

export default class NotificationAction {
  // Get Scheduled Notifications
  static REQUEST_FETCH_NOTIFICATIONS =
    "NotificationAction.REQUEST_FETCH_NOTIFICATIONS";
  static REQUEST_FETCH_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_FETCH_NOTIFICATIONS_FINISHED";

  // Get amount Scheduled Notifications
  static REQUEST_COUNT_NOTIFICATIONS =
    "NotificationAction.REQUEST_COUNT_NOTIFICATIONS";
  static REQUEST_COUNT_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_COUNT_NOTIFICATIONS_FINISHED";

  // Get Notification
  static REQUEST_NOTIFICATION = "NotificationAction.REQUEST_NOTIFICATION";
  static REQUEST_NOTIFICATION_FINISHED =
    "NotificationAction.REQUEST_NOTIFICATION_FINISHED";

  // Create Notification for Users or UserGroup
  static REQUEST_CREATE_NOTIFICATION =
    "NotificationAction.REQUEST_CREATE_NOTIFICATION";

  // Delete Scheduled Notification
  static REQUEST_DELETE_NOTIFICATION =
    "NotificationAction.REQUEST_DELETE_NOTIFICATION";

  /**
   * Get Scheduled Notifications (e.g. next or last week)
   * @param startDate Date
   * @return Dispatch NotificationEffect.getScheduledNotifications
   */
  static getScheduledNotifications(startDate: Date) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<NotificationTypes[]>(
          NotificationAction.REQUEST_FETCH_NOTIFICATIONS,
          NotificationEffect.getScheduledNotifications,
          startDate,
        ),
      );

      await dispatch(NotificationAction.getCountScheduledNotifications());
    };
  }

  /**
   * Get total amount of Scheduled Notifications
   * @returns Dispatch NotificationEffect.getCountScheduledNotifications
   */
  static getCountScheduledNotifications(): ThunkType<BaseNotificationCountModel> {
    return ActionUtility.createThunk<BaseNotificationCountModel>(
      NotificationAction.REQUEST_COUNT_NOTIFICATIONS,
      NotificationEffect.getCountScheduledNotifications,
    );
  }

  /**
   * Get one Notification
   * @param key string
   * @returns Dispatch NotificationEffect.getNotification
   */
  static getNotification(key: string): ThunkType<NotificationTypes> {
    return ActionUtility.createThunk<NotificationTypes>(
      NotificationAction.REQUEST_NOTIFICATION,
      NotificationEffect.getNotification,
      key,
    );
  }

  /**
   * Create Notification for Users or UserGroup
   * @param data NotificationCreateInterface
   * @returns Dispatch NotificationEffect.createNotification
   */
  static createNotification(data: NotificationCreateInterface) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<NotificationTypes>(
          NotificationAction.REQUEST_CREATE_NOTIFICATION,
          NotificationEffect.createNotification,
          data,
        ),
      );

      await dispatch(
        NotificationAction.getScheduledNotifications(
          getFirstDayOfCurrentWeek(new Date()),
        ),
      );
    };
  }

  /**
   * Delete Scheduled Notification
   * @param notification NotificationTypes
   * @returns Dispatch NotificationEffect.deleteScheduledNotification
   */
  static deleteScheduledNotification(notification: NotificationTypes) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<NotificationTypes>(
          NotificationAction.REQUEST_DELETE_NOTIFICATION,
          NotificationEffect.deleteScheduledNotification,
          notification,
        ),
      );

      await dispatch(
        NotificationAction.getScheduledNotifications(
          getFirstDayOfCurrentWeek(new Date()),
        ),
      );
    };
  }
}
