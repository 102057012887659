interface UsersFavouriteCardResponse {
  cardUid: string;
  userUids: string[];
}

export default class UsersFavouriteCardModel {
  cardUid: string;
  userUids: string[];

  constructor(response: UsersFavouriteCardResponse) {
    this.cardUid = response.cardUid;
    this.userUids = response.userUids;
  }
}
