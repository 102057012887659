export function getOrdinalNumberUtils(n: number, language?: string) {
  if (language === "nl") {
    if (n === 1 || n === 8 || n >= 20) {
      return "common.ordinal-numbers.st";
    } else {
      return "common.ordinal-numbers.th";
    }
  } else {
    const nMod10: number = n % 10;
    const nMod100: number = n % 100;

    if (nMod10 == 1 && nMod100 != 11) {
      return "common.ordinal-numbers.st";
    } else if (nMod10 == 2 && nMod100 != 12) {
      return "common.ordinal-numbers.nd";
    } else if (nMod10 == 3 && nMod100 != 13) {
      return "common.ordinal-numbers.rd";
    } else {
      return "common.ordinal-numbers.th";
    }
  }
}
