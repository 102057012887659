import { useQuery } from "@tanstack/react-query";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import { getUserGroupFromLearningPath } from "./learning-path.api";
import LearningPathUserGroupModel from "../../models/learning-path/learning-path-get-user-group.model";
import ProgressBasedContentModel from "../../models/learning-path/progress-based-content.model";
import TimeBasedContentModel from "../../models/learning-path/time-based-content.model";

/**
 * Get User Group from Learning Path
 * @returns Promise<LearningPathUserGroupModel>
 */
export function useUserGroupFromLearningPath(
  learningPathUid: string,
  learningPathContent?: TimeBasedContentModel[] | ProgressBasedContentModel[],
) {
  return useQuery<any, HttpErrorResponseModel, LearningPathUserGroupModel>(
    ["getUserGroupFromLearningPath", learningPathContent],
    async () => getUserGroupFromLearningPath(learningPathUid),
    { enabled: learningPathUid !== "" },
  );
}
