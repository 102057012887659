import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import ContentDetailType from "../../../core/types/content-detail.type";
import { CardTypesColorEnum } from "../../../core/enums/card-types.enum";
import { Collapse, Space, Spin } from "antd";
import { grey } from "@ant-design/colors";
import {
  selectCardsByCollection,
  selectChaptersByPlaybook,
} from "../../../core/redux/selectors/content/content.selector";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import DraggableComponent from "../draggable/content/draggable.component";
import DroppableComponent from "../draggable/content/droppable.component";
import getChildContentType from "../../../core/utils/get-child-content-type.utils";
import ContentSidebarItemButtonComponent from "./content-sidebar-item-button.component";
import CreateCardButtonComponent from "../content-create-button/create-card-button.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import CardTypeIconComponent from "../icons/card-type-icon.component";
import CreateContentButtonConnected from "../content-create-button/create-content-button.connected";
import styles from "./content-sidebar.module.css";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import ContentAction from "../../../core/redux/stores/content/content.action";

interface Props {
  index: number;
  content: ContentDetailType;
  enableDragAndDrop?: boolean;
}

const ContentSidebarItemComponent = ({
  index,
  content,
  enableDragAndDrop,
}: Props) => {
  const { isSelected } = useContentSidebar();
  const [showSpinner, setShowSpinner] = useState(false);

  const children = useSelector((state: StoreInterface) => {
    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return selectChaptersByPlaybook(state, content);
      case ContentTypesEnum.COLLECTION:
        return selectCardsByCollection(state, content);
    }
  });

  const isFetchingPlaybookDetail = useSelector((state) =>
    selectRequesting(state, [ContentAction.REQUEST_DETAIL_PLAYBOOKS]),
  );
  const isFetchingCollectionDetail = useSelector((state) =>
    selectRequesting(state, [ContentAction.REQUEST_DETAIL_COLLECTIONS]),
  );

  useEffect(() => {
    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        setShowSpinner(
          isFetchingPlaybookDetail &&
            content.children &&
            content.children.length === 0,
        );
        break;
      case ContentTypesEnum.COLLECTION:
        setShowSpinner(isFetchingCollectionDetail);
        break;
    }
  }, [isFetchingCollectionDetail, isFetchingPlaybookDetail]);

  return (
    <DraggableComponent
      index={index}
      content={content}
      enableDragAndDrop={enableDragAndDrop}
    >
      <Collapse
        defaultActiveKey={isSelected(content.getId) ? 0 : 1}
        className={styles.parent_sidebar}
        destroyInactivePanel={false}
        bordered={false}
        ghost
        style={{ width: "235px" }}
      >
        <Collapse.Panel
          key={0}
          header={
            <ContentSidebarItemButtonComponent contentId={content.getId} />
          }
          showArrow={false}
          className={"Panel"}
        >
          <div>
            <div className={styles.child_sidebar}>
              <Space direction={"vertical"} style={{ width: "100%" }}>
                {showSpinner ? (
                  <div style={{ textAlign: "center" }}>
                    <Spin />
                  </div>
                ) : (
                  <>
                    {content.contentType === ContentTypesEnum.COLLECTION &&
                      content.startSummary && (
                        <ContentSidebarItemButtonComponent
                          icon={
                            <CardTypeIconComponent
                              cardType={"start"}
                              style={{
                                marginRight: ".8rem",
                                backgroundColor: grey[7],
                              }}
                            />
                          }
                          contentId={content.startSummary.getId}
                        />
                      )}

                    {children && (
                      <DroppableComponent content={content}>
                        {children.map(
                          (child: ContentDetailType, key: number) => {
                            let cardIcon;
                            if (child.contentType === ContentTypesEnum.CARD) {
                              const cardTypeColor = {
                                "--card-type-color":
                                  CardTypesColorEnum[child.cardType][0].color,
                              } as React.CSSProperties;
                              cardIcon = (
                                <CardTypeIconComponent
                                  cardType={child.cardType}
                                  style={{
                                    marginRight: ".8rem",
                                    ...cardTypeColor,
                                  }}
                                />
                              );
                            }

                            return (
                              <DraggableComponent
                                key={child.getId}
                                index={key}
                                content={child}
                              >
                                <ContentSidebarItemButtonComponent
                                  contentId={child.getId}
                                  icon={cardIcon}
                                  key={key}
                                />
                              </DraggableComponent>
                            );
                          },
                        )}
                      </DroppableComponent>
                    )}

                    {content.contentType === ContentTypesEnum.COLLECTION ? (
                      <CreateCardButtonComponent />
                    ) : (
                      <CreateContentButtonConnected
                        parentContent={content}
                        contentType={getChildContentType(content.contentType)}
                      />
                    )}

                    {content.contentType === ContentTypesEnum.COLLECTION &&
                      content.endSummary && (
                        <ContentSidebarItemButtonComponent
                          icon={
                            <CardTypeIconComponent
                              cardType={"end"}
                              style={{
                                marginRight: ".8rem",
                                backgroundColor: grey[7],
                              }}
                            />
                          }
                          contentId={content.endSummary.getId}
                        />
                      )}
                  </>
                )}
              </Space>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </DraggableComponent>
  );
};

export default ContentSidebarItemComponent;
