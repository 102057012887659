import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import ContentTypesEnum from "../../enums/content-types.enum";
import BaseMediaContentModel, {
  BaseMediaContentResponse,
} from "../media/base-media-content.model";
import CategoryModel, { CategoryResponse } from "../category/category.model";
import ChapterModel, { ChapterResponse } from "./chapter.model";

export interface PlaybookResponse extends BaseMediaContentResponse {
  playbookUid: string;
  userGroupAcl: string[];
  categories: CategoryResponse[];
  chapters?: ChapterResponse[];
}

export default class PlaybookModel extends BaseMediaContentModel {
  playbookUid: string;
  contentType: ContentTypesEnum.PLAYBOOK;
  userGroupAcl: string[];
  categories?: CategoryModel[];
  chapters?: ChapterModel[];
  getId: string;

  constructor(playbook: PlaybookResponse) {
    super(playbook);
    this.contentType = ContentTypesEnum.PLAYBOOK;
    this.playbookUid = playbook.playbookUid;
    this.userGroupAcl = playbook.userGroupAcl;

    if (playbook.categories && playbook.categories.length > 0) {
      this.categories = MapResponseToModel(playbook.categories, CategoryModel);
    }

    if (playbook.chapters) {
      this.chapters = MapResponseToModel(playbook.chapters, ChapterModel);
    }

    this.getId = `playbook_${this.playbookUid}`;
  }
}
