import React, { CSSProperties } from "react";
import { Col, Row } from "antd";
import {
  LineChartOutlined,
  MailOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { useCompletedUserProgress } from "../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import AssignedStatisticBlockComponent from "../statistics/assigned-statistic-block.component";
import ProgressMetaIconComponent from "../icons/progress-meta-icon.component";
import StatisticsBlockComponent from "../statistics/statistics-block.component";
import SectionTitleComponent from "../section-title/section-title.component";
import millisToMinutesUtils from "../../../core/utils/millis-to-minutes.utils";
import UserModel from "../../../core/models/user/user.model";
import styles from "./user-detail.module.css";
import Moment from "moment";

interface OwnProps {
  user: UserModel;
}

const UserInfoComponent = ({ user }: OwnProps) => {
  const [t] = useTranslation();
  const { data, isFetching } = useCompletedUserProgress(user);

  const style: CSSProperties = {
    color: "var(--primary-color)",
  };

  return (
    <>
      <div style={{ marginBottom: "2rem" }}>
        <SectionTitleComponent title={t("common.contact-details")} />
        <StatisticsBlockComponent
          icon={<MailOutlined />}
          text={"form.items.email.label"}
          title={user.email}
        />
      </div>

      <div>
        <SectionTitleComponent title={t("common.general-info")} />
        <Row className={styles.grid_default} style={{ columnGap: ".6rem" }}>
          <Col className={styles.grid_default_row}>
            <StatisticsBlockComponent
              icon={<TabletOutlined />}
              text={"common.active-since"}
              title={
                !data?.firstActiveOn || isFetching
                  ? "-"
                  : Moment(data.firstActiveOn).format("DD MMM YYYY")
              }
            />

            <AssignedStatisticBlockComponent
              userGroupAcl={user.userGroups}
              style={style}
            />
          </Col>
          <Col className={styles.grid_default_row}>
            <StatisticsBlockComponent
              icon={<LineChartOutlined />}
              text={"form.items.last_active_at.label"}
              title={
                !user.last_active_at
                  ? "-"
                  : Moment(user.last_active_at).format("DD MMM YYYY")
              }
            />
            <StatisticsBlockComponent
              icon={
                <ProgressMetaIconComponent icon={"timeSpent"} style={style} />
              }
              text={"graphs.users-active.total-timespent"}
              title={
                isFetching
                  ? "-"
                  : `${millisToMinutesUtils(
                      data ? data.totalTimeSpent : 0,
                    )} min`
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserInfoComponent;
