import { selectUserGroupByGroupTitle } from "../user-group/user-group.selector";
import { createSelector } from "reselect";
import { selectState } from "../core/core.selector";
import UserRoleEnum from "../../../enums/user-role.enum";
import {
  ADMIN_GROUP,
  PRIMIO_SUPPORT_GROUP,
  SYS_ADMIN_GROUP,
} from "../../../../constants";
import UserModel from "../../../models/user/user.model";

const sortUsers = (a: UserModel, b: UserModel) =>
  +new Date(b.created_at) - +new Date(a.created_at);

export const selectUsers = createSelector(selectState, (state) =>
  state.user.users.sort(sortUsers),
);

export const selectUserByUsername = createSelector(
  selectUsers,
  (state, username: string) => username,
  (users, username) => {
    if (users.length === 0) return;
    return users.find((u) => u.username === username);
  },
);

export const selectUserByUids = createSelector(
  selectUsers,
  (state, userUids: string[]) => userUids,
  (users, userUids) => {
    if (users.length === 0) return [];
    return users.filter((u) => userUids.includes(u.sub));
  },
);

export const selectUsersInUserGroup = createSelector(
  selectUserGroupByGroupTitle,
  (group) => {
    if (!group) return [];
    return group.users;
  },
);

export const selectUsersExceptFor = createSelector(
  selectState,
  selectUsers,
  (state, users, groupTitle: string) => groupTitle,
  (state, users, groupTitle) => selectUsersInUserGroup(state, groupTitle),
  (state, users, groupTitle, excludedGroup) => {
    if (users.length === 0) return [];
    if (excludedGroup.length === 0) return users;

    return users.filter(
      (user) => !excludedGroup.some((a) => user.username === a.username),
    );
  },
);

export const selectCurrentUser = createSelector(
  selectState,
  (state) => state.user.currentUser,
);

export const selectCurrentUserRole = createSelector(
  selectCurrentUser,
  (user) => {
    if (!user) {
      return;
    }

    // Todo: A user can have multiple roles

    if (user.userGroups.includes(SYS_ADMIN_GROUP)) {
      return UserRoleEnum.SYS_ADMIN;
    }

    if (user.userGroups.includes(PRIMIO_SUPPORT_GROUP)) {
      return UserRoleEnum.PRIMIO_SUPPORT;
    }

    if (user.userGroups.includes(ADMIN_GROUP) && user.userGroups.length > 1) {
      return UserRoleEnum.GROUP_ADMIN;
    }

    if (user.userGroups.includes(ADMIN_GROUP)) {
      return UserRoleEnum.ADMIN;
    }

    return;
  },
);
