import { ActionType } from "../../../types/redux.type";
import ActionUtility from "../../../utils/action.utils";

export default class ErrorAction {
  static CLEAR_ALL = "ErrorAction.CLEAR_ALL";
  static REMOVE = "ErrorAction.REMOVE";

  static removeById(id: string): ActionType {
    return ActionUtility.createAction(ErrorAction.REMOVE, id);
  }

  static clearAll(): ActionType {
    return ActionUtility.createAction(ErrorAction.CLEAR_ALL);
  }
}
