import React, { useEffect, useState } from "react";
import { AnswerTypeEnum } from "../../../../core/enums/answer-type.enum";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import { Progress, Row } from "antd";
import { GradientColors_ProgressStarted } from "../../../styles/colors";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useUserContentCompletion } from "../../../../core/hooks/user-progress/user-progress.hook";
import StatisticsBlockComponent from "../../statistics/statistics-block.component";
import ContentIconComponent from "../../icons/content-icon.component";
import ProgressMetaIconComponent from "../../icons/progress-meta-icon.component";
import millisToMinutesUtils from "../../../../core/utils/millis-to-minutes.utils";
import UserCollectionCompletionModel from "../../../../core/models/user-progress/user-content-completion/user-collection-completion.model";
import UserCardCompletionModel from "../../../../core/models/user-progress/user-content-completion/user-card-completion.model";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import CardTypes from "../../../../core/types/card.types";
import styles from "../user-detail.module.css";
import { useCollectionDetail } from "../../../../core/hooks/content/content.hook";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";

interface Props {
  userUid: string;
  collectionUid?: string;
}

const CollectionStatisticsComponent = (props: Props) => {
  const { collectionUid, userUid } = props;
  const [cards, setCards] = useState<CardTypes[]>([]);
  const { data, isFetching } = useUserContentCompletion(userUid, collectionUid);
  const collectionCompletion = data as
    | UserCollectionCompletionModel
    | undefined;
  const { data: collection } = useCollectionDetail(collectionUid);

  useEffect(() => {
    if (!collection) {
      setCards([]);
      return;
    }

    setCards(
      collection.cards
        .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED)
        .sort((a, b) => a.sort - b.sort),
    );
  }, [collection]);

  const collectionProgress = (): number => {
    if (!collectionCompletion) {
      return 0;
    }

    const cardsCompleted: UserCardCompletionModel[] = [];
    collectionCompletion.cards.filter((c) => {
      cards.map((card) => {
        if (card.cardUid !== c.cardUid) {
          return;
        }

        const completedVersions = c.data.map((c) => c.version);

        if (!completedVersions.includes(card.version)) {
          return;
        }

        cardsCompleted.push(c);
      });
    });

    const percentage = cardsCompleted.length / cards.length;
    return Math.round((isFinite(percentage) ? percentage : 0) * 100);
  };

  const amountOfCorrectAnswers = (): number | undefined => {
    if (!collectionCompletion) {
      return 0;
    }

    const quizAnswers: UserCardCompletionModel[] = [];
    collectionCompletion.cards.map((c) => {
      c.data.forEach((d) => {
        if (d.answerType === AnswerTypeEnum.QUIZ) {
          quizAnswers.push(c);
        }
      });
    });

    if (quizAnswers.length === 0) {
      return;
    }

    const correctQuizAnswers: UserCardCompletionModel[] = [];
    cards.map((card) => {
      if (card.cardType !== CardTypesEnum.QUIZ) {
        return;
      }

      quizAnswers.forEach((c) => {
        if (c.cardUid === card.cardUid) {
          const mostRecentAnswer = c.data[c.data.length - 1].answer;
          const correctAnswer = card.data.options.findIndex((o) => o.isCorrect);

          if (
            mostRecentAnswer !== undefined &&
            mostRecentAnswer === correctAnswer
          ) {
            correctQuizAnswers.push(c);
          }
        }
      });
    });

    const percentage = correctQuizAnswers.length / quizAnswers.length;
    return Math.round((isFinite(percentage) ? percentage : 0) * 100);
  };

  const totalTimeSpent = (): number => {
    if (!collectionCompletion) {
      return 0;
    }

    let spent = 0;
    collectionCompletion.cards.map((c) => {
      c.data.forEach((d) => {
        spent = spent + d.timeSpent;
      });
    });

    return millisToMinutesUtils(spent);
  };

  return (
    <Row className={styles.overview_wrapper}>
      <StatisticsBlockComponent
        icon={
          <Progress
            type={"circle"}
            strokeWidth={16}
            width={40}
            percent={collectionProgress()}
            showInfo={false}
            style={{ width: "4rem", height: "4.5rem" }}
            strokeColor={{
              "0%": GradientColors_ProgressStarted.from,
              "100%": GradientColors_ProgressStarted.to,
            }}
          />
        }
        text={"containers.user-progress.key"}
        title={`${collectionProgress()}%`}
        isLoading={isFetching}
      />
      <StatisticsBlockComponent
        icon={<CheckCircleOutlined />}
        text={"form.card.answers.correct-answers"}
        title={
          amountOfCorrectAnswers() === undefined
            ? "-"
            : `${amountOfCorrectAnswers()}%`
        }
        isLoading={isFetching}
      />
      <StatisticsBlockComponent
        icon={<ContentIconComponent contentType={ContentTypesEnum.CARD} />}
        text={"content.card.cards-in-collection"}
        title={`${cards.length}`}
      />
      <StatisticsBlockComponent
        icon={
          <ProgressMetaIconComponent
            icon={"timeSpent"}
            style={{ color: "var(--primary-color)" }}
          />
        }
        text={"graphs.users-active.total-timespent"}
        title={`${totalTimeSpent()} min`}
        isLoading={isFetching}
      />
    </Row>
  );
};

export default CollectionStatisticsComponent;
