import { API_URL } from "../../constants/config/env.config";

const MediaEndpoints = {
  /**
   * GET Media endpoint.
   * @param mediaUid string
   * @returns API_URL + /media/07510ef8-0bbe-4ec9-8201-a372de70924e
   */
  detail(mediaUid: string) {
    return `${API_URL}/media/${mediaUid}`;
  },

  /**
   * POST Media endpoint.
   * @returns API_URL + /media
   */
  create: `${API_URL}/media`,
};

export default MediaEndpoints;
