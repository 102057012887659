import {
  ContentInteractionModel,
  ContentInteractionResponse,
} from "../../models/user-progress/content-interaction.model";
import { UsersCompletedUserProgressModel } from "../../models/user-progress/completed-user-progress/completed-user-progress.model";
import { AxiosResponse } from "axios";
import { DateRangeParamsInterface } from "../../interfaces/date-range-params.interface";
import UserProgressEndpoints from "../../api/user-progress/user-progress.endpoints";
import EffectUtility from "../../utils/effect.utils";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import PopularUserModel from "../../models/user-progress/popular-users.model";
import UserProgressModel from "../../models/user-progress/user-progress/user-progress.model";
import PlaybookUserProgressModel from "../../models/user-progress/user-progress/user-progress-types/playbook-user-progress.model";
import UsersActivityModel from "../../models/user-progress/users-activity.model";
import CompletedCardsModel from "../../models/user-progress/completed-cards.model";
import LatestActivityModel from "../../models/user-progress/latest-activity.model";
import ContentDirectoryModel from "../../models/user-progress/content-directory/content-directory.model";
import UserProgressCollectionDetailModel from "../../models/user-progress/collection-detail/user-progress-collection-detail.model";
import UserCollectionCompletionModel from "../../models/user-progress/user-content-completion/user-collection-completion.model";
import InteractiveCardModel from "../../models/user-progress/interactive-card.model";
import ContentTypesEnum from "../../enums/content-types.enum";
import HttpUtility from "../../utils/http.utils";

export async function getUserProgress(playbookUid: string) {
  const endpoint = UserProgressEndpoints.overview(playbookUid);
  const response = await EffectUtility.getToModel<UserProgressModel>(
    UserProgressModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getPopularUsers(dateRange: DateRangeParamsInterface) {
  const endpoint = UserProgressEndpoints.popularUsers;
  const params = convertDateRangeParamsToQueryParam(dateRange);
  const response = await EffectUtility.getToModel<PopularUserModel>(
    PopularUserModel,
    endpoint,
    params,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getTrackedPlaybooksProgress() {
  const endpoint = UserProgressEndpoints.trackedPlaybooks;
  const response = await EffectUtility.getToModel<UserProgressModel[]>(
    UserProgressModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  const newUserProgressModel = new UserProgressModel({});
  const newPlaybookUserProgressModel: PlaybookUserProgressModel[] = [];

  response.forEach((userProgress) => {
    userProgress.playbooks.forEach((p) => newPlaybookUserProgressModel.push(p));
  });

  newUserProgressModel.playbooks = newPlaybookUserProgressModel;

  return newUserProgressModel;
}

export async function getLatestActivity() {
  const endpoint = UserProgressEndpoints.latestActivity;
  const response = await EffectUtility.getToModel<LatestActivityModel[]>(
    LatestActivityModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }
  return response;
}

export async function getContentDirectory() {
  const endpoint = UserProgressEndpoints.contentDirectory;
  const response = await EffectUtility.getToModel<ContentDirectoryModel>(
    ContentDirectoryModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }
  return response;
}

export async function getUsersActivity(params: DateRangeParamsInterface) {
  const endpoint = UserProgressEndpoints.usersActivity;
  return getDateRangeDate<UsersActivityModel>(
    UsersActivityModel,
    endpoint,
    params,
  );
}

export async function getCompletedCards(params: DateRangeParamsInterface) {
  const endpoint = UserProgressEndpoints.completedCards;
  return getDateRangeDate<CompletedCardsModel>(
    CompletedCardsModel,
    endpoint,
    params,
  );
}

export async function getContentInteractions(
  contentUid: string,
  contentType: ContentTypesEnum,
) {
  if (contentType === ContentTypesEnum.CARD) {
    const error = new HttpErrorResponseModel();
    error.id = "0";
    error.errors = ["ContentType CARD is not available"] as never[];
    error.message = "ContentType CARD is not available";
    throw error;
  }

  const endpoint = UserProgressEndpoints.interactions(contentUid);
  const {
    data,
  }: AxiosResponse<ContentInteractionResponse | HttpErrorResponseModel> =
    await HttpUtility.get(endpoint, { "content-type": contentType });

  if (data instanceof HttpErrorResponseModel) {
    throw data;
  }

  const interactions: ContentInteractionModel[] = [];

  Object.values(data).forEach((_value) => {
    Object.entries(_value).forEach(([key, value]) => {
      interactions.push(new ContentInteractionModel(key, value));
    });
  });

  return interactions;
}

export async function getCompletedUserProgress(username: string) {
  const endpoint = UserProgressEndpoints.userCompletedUserProgress(username);
  const response =
    await EffectUtility.getToModel<UsersCompletedUserProgressModel>(
      UsersCompletedUserProgressModel,
      endpoint,
    );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getUsersProgressCollectionDetail(collectionUid: string) {
  const endpoint =
    UserProgressEndpoints.usersProgressCollectionDetail(collectionUid);
  const response =
    await EffectUtility.getToModel<UserProgressCollectionDetailModel>(
      UserProgressCollectionDetailModel,
      endpoint,
    );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getUserProgressCardDetail(cardUid: string) {
  const endpoint = UserProgressEndpoints.userProgressCardDetail(cardUid);
  const response = await EffectUtility.getToModel<InteractiveCardModel>(
    InteractiveCardModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getUserCollectionCompletion(
  collectionUid: string,
  userUid: string,
) {
  const endpoint = UserProgressEndpoints.userCollectionCompletion(
    collectionUid,
    userUid,
  );
  const response =
    await EffectUtility.getToModel<UserCollectionCompletionModel>(
      UserCollectionCompletionModel,
      endpoint,
    );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

const convertDateRangeParamsToQueryParam = ({
  filter,
  startDate,
  endDate,
}: DateRangeParamsInterface) => {
  return {
    filter,
    startDate: startDate ? startDate.getTime() : undefined,
    endDate: endDate ? endDate.getTime() : undefined,
  };
};

async function getDateRangeDate<Type>(
  model: any,
  endpoint: string,
  dateRange: DateRangeParamsInterface,
): Promise<Type> {
  const params = convertDateRangeParamsToQueryParam(dateRange);
  const response = await EffectUtility.getToModel<Type>(
    model,
    endpoint,
    params,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
