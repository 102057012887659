import { AnswerTypeEnum } from "../../../enums/answer-type.enum";
import UsersAnswersModel, { UsersAnswersResponse } from "./users-answers.model";
import TextAnswerModel from "./answer-types/text-answer.model";
import IndexAnswerModel from "./answer-types/index-answer.model";

export interface UserProgressCardDetailResponse {
  answerType: AnswerTypeEnum;
  answers: { [index: string]: number };
  cardCompleted: number;
  completionData: { [day: string]: number };
  timeSpent: number;
  users: { [userUid: string]: UsersAnswersResponse };
  usersCompleted: number;
}

export default class UserProgressCardDetailModel {
  cardUid: string;
  answerType: AnswerTypeEnum;
  answers: (TextAnswerModel | IndexAnswerModel)[];
  cardCompleted: number; // amount of times that card has been completed (regardless how many times it has been completed)
  completionData: { [day: string]: number }; // sun = 0, mo = 1, tue = 2, ...
  timeSpent: number; // total of all users on a card
  users: UsersAnswersModel[];
  usersCompleted: number; // amount of users completed a card
  //
  answersCount: number;

  constructor(cardUid: string, response: UserProgressCardDetailResponse) {
    this.cardUid = cardUid;
    this.answerType = response.answerType;
    this.cardCompleted = response.cardCompleted;
    this.completionData = response.completionData;
    this.timeSpent = response.timeSpent;

    this.answers = [];

    Object.entries(response.answers).forEach(([answer, amount]) => {
      if (response.answerType === AnswerTypeEnum.QUESTION) {
        this.answers.push(new TextAnswerModel({ text: answer, amount }));
      } else {
        if (answer === undefined || isNaN(parseInt(answer))) {
          return;
        }
        this.answers.push(
          new IndexAnswerModel({ index: parseInt(answer), amount }),
        );
      }
    });

    this.usersCompleted = response.usersCompleted;
    this.users = Object.entries(response.users).map(([userUid, res]) => {
      return new UsersAnswersModel(userUid, res);
    });

    // Note: Sometimes the answer in UserAnswerModel is undefined
    // we don't want to include this in the answerCount, when we display the amount of answers on a card
    this.answersCount = this.users.filter((u) =>
      u.answers.find((a) => a.answer !== undefined),
    ).length;
  }
}
