import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { AlignCenterOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tag } from "antd";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";

interface OwnProps {
  filterContentProgress: (progress: ContentProgressEnum[]) => void;
}

type Props = OwnProps;

const FilterContentProgressComponent = ({ filterContentProgress }: Props) => {
  const { t } = useTranslation();
  const [progressFilter, setProgressFilter] = useState<ContentProgressEnum[]>(
    [],
  );

  const menu = (
    <Menu>
      {Object.keys(ContentProgressEnum).map((key) => (
        <Menu.Item key={key}>
          <a onClick={() => handleOnAddFilter(key)}>
            {Capitalize(t(`screens.user-progress.state.${key}`))}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ marginBottom: "1.4rem" }}>
      <Dropdown overlay={menu}>
        <Button
          type={"text"}
          icon={<AlignCenterOutlined />}
          style={{ padding: 0, marginRight: "1rem" }}
        >
          {`${Capitalize(t("common.filter"))} ${t(
            "content.playbook.key_plural",
          )}`}
        </Button>
      </Dropdown>

      {progressFilter.map((key) => (
        <Tag key={key} onClose={() => handleOnCloseFilter(key)} closable>
          {Capitalize(t(`screens.user-progress.state.${key}`))}
        </Tag>
      ))}
    </div>
  );

  function handleOnResetFilter() {
    setProgressFilter([]);
    filterContentProgress([]);
  }

  function handleOnAddFilter(key: string) {
    if (!progressFilter.includes(ContentProgressEnum[key])) {
      progressFilter.push(ContentProgressEnum[key]);
    }

    filterContentProgress([...progressFilter]);
  }

  function handleOnCloseFilter(key: ContentProgressEnum) {
    if (progressFilter.length === 1) {
      return handleOnResetFilter();
    }

    const index = progressFilter.findIndex((type) => type === key);
    progressFilter.splice(index, 1);

    filterContentProgress([...progressFilter]);
  }
};

export default FilterContentProgressComponent;
