import React, { useEffect } from "react";
import { Select, Typography } from "antd";
import { LearningPathTypeEnum } from "../../../core/enums/learning-path-state.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import useActiveFeatureFlags from "../../../core/hooks/useFeatureFlags";

interface OwnProps {
  type?: LearningPathTypeEnum;
  setType: (value: LearningPathTypeEnum) => void;
  disabled?: boolean;
}

const LearningPathTypesComponent = ({ type, setType, disabled }: OwnProps) => {
  const [t] = useTranslation();
  const checkFeatureFlags = useActiveFeatureFlags();

  useEffect(() => {
    if (type) {
      setType(type);
    }
  }, []);

  return (
    <Select
      value={type}
      onChange={setType}
      disabled={disabled}
      placeholder={t("translations:form.placeholders.select_x", {
        x: t("translations:common.type"),
      })}
    >
      {Object.keys(LearningPathTypeEnum)
        .filter(
          (key) =>
            !(
              key === "OPEN" &&
              !checkFeatureFlags.includes("open-learning-path-type")
            ),
        )
        .map((type, index) => (
          <Select.Option
            key={index}
            value={type}
            label={Capitalize(t(`screens.learning-path.type.${type}.label`))}
          >
            <div>
              <Typography.Text>
                {Capitalize(t(`screens.learning-path.type.${type}.label`))}
              </Typography.Text>
            </div>
            <Typography.Text type={"secondary"}>
              {Capitalize(t(`screens.learning-path.type.${type}.explanation`))}
            </Typography.Text>
          </Select.Option>
        ))}
    </Select>
  );
};

export default LearningPathTypesComponent;
