import ContentTypesEnum from "../../enums/content-types.enum";

interface ContentLearningPathResponse {
  learningPathUid: string;
  contentUid: string;
  contentType: ContentTypesEnum;
}

export default class ContentLearningPathModel {
  learningPathUid: string;
  contentUid: string;
  contentType: ContentTypesEnum;

  constructor(response: ContentLearningPathResponse) {
    this.learningPathUid = response.learningPathUid;
    this.contentUid = response.contentUid;
    this.contentType = response.contentType;
  }
}
