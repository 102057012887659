import React from "react";
import { Space } from "antd";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Trim } from "../../../core/utils/helper.utils";
import PlaybookDetailModel from "../../../core/models/content/playbook-detail.model";
import CollectionDetailModel from "../../../core/models/content/collection-detail.model";
import ContentLabelComponent from "../content/content-label/content-label.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ChapterDetailModel from "../../../core/models/content/chapter-detail.model";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import styles from "./content-tree.module.css";

interface Props {
  contentDetail:
    | PlaybookDetailModel
    | ChapterDetailModel
    | CollectionDetailModel;
}

const ContentTreeComponent = (props: Props) => {
  const { t } = useTranslation();
  const { contentDetail } = props;

  const renderCollections = (
    collections: CollectionDetailModel[] | undefined,
  ) => {
    if (!collections || collections.length === 0) {
      return (
        <ContentLabelComponent
          contentState={ContentStatesEnum.DRAFT}
          contentType={ContentTypesEnum.COLLECTION}
          textProps={{ type: "secondary" }}
        >
          {t("content.collection.keyWithCount", { count: 0 })}
        </ContentLabelComponent>
      );
    }

    return (
      <Space direction={"vertical"}>
        {collections.map((collection, index) => (
          <div key={index}>
            <ContentLabelComponent
              contentState={collection.contentState}
              showTooltip
              contentType={ContentTypesEnum.COLLECTION}
            >
              {Trim(TranslateDefaults(collection.title))}
            </ContentLabelComponent>
          </div>
        ))}
      </Space>
    );
  };

  const renderCardsCount = (
    collections: CollectionDetailModel[] | undefined,
  ) => {
    if (!collections || collections.length === 0) {
      return (
        <ContentLabelComponent
          contentState={ContentStatesEnum.DRAFT}
          contentType={ContentTypesEnum.CARD}
          textProps={{ type: "secondary" }}
        >
          {t("content.card.keyWithCount", { count: 0 })}
        </ContentLabelComponent>
      );
    }

    return (
      <Space direction={"vertical"}>
        {collections.map((collection, index) => {
          const count = collection.children ? collection.children.length : 0;
          const label = t("content.card.keyWithCount", { count });
          const type = count === 0 ? "secondary" : undefined;

          return (
            <div key={index}>
              <ContentLabelComponent
                contentState={ContentStatesEnum.DRAFT}
                contentType={ContentTypesEnum.CARD}
                textProps={{ type }}
              >
                {Trim(label)}
              </ContentLabelComponent>
            </div>
          );
        })}
      </Space>
    );
  };

  const renderContentTree = (chapters: ChapterDetailModel[]) => {
    return chapters.map((chapter, index) => {
      const { children } = chapter;
      return (
        <tr key={index}>
          <td valign={"top"} className={styles.table_data}>
            <ContentLabelComponent
              contentState={chapter.contentState}
              showTooltip
              contentType={ContentTypesEnum.CHAPTER}
            >
              {Trim(TranslateDefaults(chapter.title))}
            </ContentLabelComponent>
          </td>
          <td valign={"top"} className={styles.table_data}>
            {renderCollections(children)}
          </td>
          <td valign={"top"}>{renderCardsCount(children)}</td>
        </tr>
      );
    });
  };

  const renderChapterContentTree = (collections: CollectionDetailModel[]) => {
    return collections.map((collection, index) => (
      <tr key={index}>
        <td valign={"top"} className={styles.table_data}>
          <ContentLabelComponent
            contentState={collection.contentState}
            showTooltip
            contentType={ContentTypesEnum.CHAPTER}
          >
            {Trim(TranslateDefaults(collection.title))}
          </ContentLabelComponent>
        </td>
        <td valign={"top"} className={styles.table_data}>
          {renderCardsCount([collection])}
        </td>
      </tr>
    ));
  };

  switch (contentDetail.contentType) {
    case ContentTypesEnum.PLAYBOOK:
      // chapters, collections and cards
      return (
        <table>
          <tbody>
            {contentDetail.children &&
              renderContentTree(contentDetail.children)}
          </tbody>
        </table>
      );
    case ContentTypesEnum.CHAPTER:
      // collections and cards
      return (
        <table>
          <tbody>
            {contentDetail.children &&
              renderChapterContentTree(contentDetail.children)}
          </tbody>
        </table>
      );
    case ContentTypesEnum.COLLECTION:
      // nothing?
      return null;
    default:
      return null;
  }
};

export default ContentTreeComponent;
