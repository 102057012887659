export default interface ApiKeyHashContent {
  apiKeyUid: string;
  server: string;
  description: string;
}

export function instanceOfApiKeyHashContent(
  object: any,
): object is ApiKeyHashContent {
  if (!("apiKeyUid" in object)) {
    return false;
  }

  if (!("server" in object)) {
    return false;
  }

  return "description" in object;
}
