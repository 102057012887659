import { createSelector } from "reselect";
import { StoreInterface } from "../../stores/root.reducer";

/**
 * @typedef {Object.<string, HttpErrorResponseModel>} IErrorState
 */

/**
 * ErrorSelector
 *
 * @static
 */
export class ErrorSelector {
  /**
   * Returns a new object with the keys being the finished action type
   * (e.g. "SomeAction.REQUEST_*_FINISHED") and the value being a
   * HttpErrorResponseModel.
   *
   * @param {IErrorState} errorState
   * @param {string[]} actionTypes
   * @returns {IErrorState}
   * @static
   */
  static selectRawErrors(errorState: any, actionTypes: string[]) {
    return actionTypes.reduce((partialState: any, actionType: any) => {
      const httpErrorResponseModel = errorState[actionType];

      if (httpErrorResponseModel) {
        if (typeof actionType === "string") {
          partialState = httpErrorResponseModel;
        } else {
          partialState[actionType] = httpErrorResponseModel;
        }
      }

      return partialState;
    }, {});
  }

  /**
   * Finds any errors matching the array of actionTypes and combines all error
   * messages in to a single string.
   *
   * @param {IErrorState} errorState
   * @param {string[]} actionTypes
   * @returns {string}
   * @static
   */
  static selectErrorText(errorState: any, actionTypes: string[]) {
    const errorList = actionTypes.reduce(
      (errorMessages: any, actionType: any) => {
        const httpErrorResponseModel = errorState[actionType];

        if (httpErrorResponseModel) {
          const { message, errors } = httpErrorResponseModel;
          const arrayOfErrors = errors.length ? errors : [message];

          return errorMessages.concat(arrayOfErrors);
        }

        return errorMessages;
      },
      [],
    );

    return errorList.join(", ");
  }

  /**
   * Returns true or false if there are errors found matching the array of actionTypes.
   *
   * @param {IErrorState} errorState
   * @param {string[]} actionTypes
   * @returns {boolean}
   * @static
   */
  static hasErrors(errorState: any, actionTypes: string[]) {
    return (
      actionTypes
        .map((actionType: any) => errorState[actionType])
        .filter(Boolean).length > 0
    );
  }

  /**
   * Returns true or false if there any 401 errors found.
   * @param {IErrorState} errorState
   * @returns {boolean}
   * @static
   */
  static has401Error(errorState: any) {
    let hasError = false;

    Object.values(errorState).forEach((error: any) => {
      if (error.status === 401) {
        hasError = true;
      }
    });

    return hasError;
  }
}

export const selectRawErrors = createSelector(
  (state: StoreInterface) => state.error,
  (state: any, actionTypes: any) => actionTypes,
  ErrorSelector.selectRawErrors,
);

export const selectErrorText = createSelector(
  (state: StoreInterface) => state.error,
  (state: any, actionTypes: any) => actionTypes,
  ErrorSelector.selectErrorText,
);

export const hasErrors = createSelector(
  (state: StoreInterface) => state.error,
  (state: any, actionTypes: any) => actionTypes,
  ErrorSelector.hasErrors,
);

export const has401Error = createSelector(
  (state: StoreInterface) => state.error,
  ErrorSelector.has401Error,
);
