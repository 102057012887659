import React from "react";
import { Form, Layout, Switch, Typography } from "antd";
import {
  useGetFeatureFlags,
  useUpdateFeatureFlag,
} from "../../../core/api/primio/primioComponents";
import { Capitalize } from "../../../core/utils/helper.utils";
import * as Schemas from "../../../core/api/primio/primioSchemas";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeyFn } from "../../../core/api/primio/primioContext";

const FeatureFlagsContainer = () => {
  const { data: featureFlags = [] } = useGetFeatureFlags({});

  return (
    <Layout.Content className="Content_wrapper_without_sider">
      <Typography.Title level={2}>Feature Flags</Typography.Title>
      <Typography.Paragraph>
        Changes are saved automatically
      </Typography.Paragraph>

      <div style={{ marginTop: 32 }}>
        {featureFlags
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((featureFlag, index) => (
            <FeatureFlagItem featureFlag={featureFlag} key={index} />
          ))}
      </div>
    </Layout.Content>
  );
};

const platforms = ["api", "cms", "app"];

const FeatureFlagItem = ({
  featureFlag,
}: {
  featureFlag: Schemas.FeatureFlagModel;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateFeatureFlag({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeyFn({
          path: "/feature-flags",
          operationId: "getFeatureFlags",
          variables: {},
        }),
      });
    },
  });

  return (
    <div style={{ marginBottom: 32 }}>
      <Typography.Title level={3}>
        {Capitalize(featureFlag.name)}
      </Typography.Title>

      <Form layout="inline">
        {Object.entries(featureFlag)
          .filter((e) => platforms.includes(e[0]))
          .sort((a, b) => {
            if (a[0] < b[0]) {
              return -1;
            }
            if (a[0] > b[0]) {
              return 1;
            }
            return 0;
          })
          .map(([key, value], index) => (
            <Form.Item key={index} label={Capitalize(key)} name={key}>
              <Switch
                loading={isLoading}
                checked={value.toString() === "true"}
                onChange={() => {
                  mutate({
                    body: [
                      {
                        name: featureFlag.name,
                        properties: {
                          [key]: !featureFlag[key],
                        },
                      },
                    ],
                  });
                }}
              />
            </Form.Item>
          ))}
      </Form>
    </div>
  );
};

export default FeatureFlagsContainer;
