import React from "react";
import { Card } from "antd";
import PageHeaderBreadcrumbsComponent from "../../components/page-header/breadcrumbs/page-header-breadcrumbs.component";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import ContentProgressLegend from "../../components/content-user-progress/content-progress.legend";
import RecentlyPublishedComponent from "../../components/user-progress/recently-published.component";
import ViewModulesComponent from "../../components/user-progress/view-modules.component";
import TrackedPlaybooksComponent from "../../components/user-progress/tracked-playbooks.component";
import styles from "../../components/user-progress/user-progress.module.css";
import UserGroupSelectComponent from "../../components/user-groups/user-group-select.component";

const PublishedContentContainer = () => {
  return (
    <PageLayoutComponent
      menuItems={[]}
      title={"published-content"}
      breadCrumbs={<PageHeaderBreadcrumbsComponent />}
      showSider={false}
      selectEnabled={<UserGroupSelectComponent />}
    >
      <>
        <div style={{ position: "relative" }}>
          <Card className={styles.legenda_wrapper}>
            <ContentProgressLegend />
          </Card>
        </div>

        <RecentlyPublishedComponent />
        <ViewModulesComponent />
        <TrackedPlaybooksComponent />
      </>
    </PageLayoutComponent>
  );
};

export default PublishedContentContainer;
