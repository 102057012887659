import { useEffect, useState } from "react";

const clamp = (min: number, max: number, value: number) =>
  Math.min(Math.max(value, min), max);
const useClampedNumber = (value: number, min: number, max: number) => {
  const [selectedIndex, _setSelectedIndex] = useState(clamp(min, max, value));

  useEffect(() => {
    _setSelectedIndex(clamp(min, max, value));
  }, [min, max]);

  const setSelectedIndex = (value: number) =>
    _setSelectedIndex(clamp(min, max, value));

  return { selectedIndex, setSelectedIndex };
};

export default useClampedNumber;
