import NotificationTypes from "../../../types/notification.types";
import { StoreInterface } from "../../stores/root.reducer";
import { NotifiableTypeEnum } from "../../../enums/notification-types.enum";
import { selectUsersInUserGroup } from "../user/user.selector";
import UserModel from "../../../models/user/user.model";

export const selectNotifications = (
  state: StoreInterface,
): NotificationTypes[] => {
  const notifications: NotificationTypes[] = state.notification.notifications;
  if (!notifications || notifications.length === 0) {
    return [];
  }

  return notifications.map((notification) => {
    if (
      !notification ||
      !notification.notifiable ||
      !notification.notifiable.recipients
    ) {
      return {
        ...notification,
      };
    }

    let amountOfUsers = notification.amountOfUsers;
    switch (notification.notifiable.notifiableType) {
      case NotifiableTypeEnum.USERS:
        amountOfUsers = notification.notifiable.recipients.length;
        break;
      case NotifiableTypeEnum.USER_GROUPS:
        notification.notifiable.recipients.map((group) => {
          const usersInUserGroup: UserModel[] = selectUsersInUserGroup(
            state,
            group,
          );
          if (!usersInUserGroup || usersInUserGroup.length === 0) {
            return {
              ...notification,
            };
          }
          amountOfUsers += usersInUserGroup.length;
        });
        break;
    }

    return {
      ...notification,
      amountOfUsers: amountOfUsers,
    };
  });
};

export const selectNotificationById = (
  state: StoreInterface,
  notificationUid: string,
): NotificationTypes | undefined => {
  const notifications: NotificationTypes[] = selectNotifications(state);
  if (!notifications || notifications.length === 0) {
    return;
  }

  return notifications.find((n) => n.notificationUid === notificationUid);
};
