import { API_URL } from "../../constants/config/env.config";

const LearningPathEndpoints = {
  /**
   * GET all Learning Paths endpoint.
   * @returns API_URL + /learning-paths/
   */
  overview: `${API_URL}/learning-paths/`,

  /**
   * GET one Learning Path endpoint.
   * @param learningPathUid string
   * @returns API_URL + /learning-paths/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  detail(learningPathUid: string) {
    return `${API_URL}/learning-paths/${learningPathUid}`;
  },

  /**
   * POST one Learning Path endpoint.
   * @returns API_URL + /learning-paths/
   */
  create: `${API_URL}/learning-paths/`,

  /**
   * PUT one Learning Path endpoint.
   * @param learningPathUid string
   * @returns API_URL + /learning-paths/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  edit(learningPathUid: string) {
    return `${API_URL}/learning-paths/${learningPathUid}`;
  },

  /**
   * POST one Learning Path endpoint.
   * @param learningPathUid string
   * @returns API_URL + /learning-paths/1b21d7d3-6788-4383-aa6e-0a9163e462c3/post
   */
  post(learningPathUid: string) {
    return `${API_URL}/learning-paths/${learningPathUid}/post`;
  },

  /**
   * POST one Learning Path endpoint.
   * @param learningPathUid string
   * @returns API_URL + /learning-paths/1b21d7d3-6788-4383-aa6e-0a9163e462c3/revoke
   */
  revoke(learningPathUid: string) {
    return `${API_URL}/learning-paths/${learningPathUid}/revoke`;
  },

  /**
   * GET Content Learning Path endpoint.
   * @param contentUids string[]
   * @returns API_URL + /learning-paths/content?content-uids=1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  contentLearningPath(contentUids: string[]) {
    return `${API_URL}/learning-paths/content?content-uids=${contentUids.toString()}`;
  },

  /**
   * GET User-Groups Learning Path endpoint.
   * @param learningPathUid string
   * @returns API_URL + /learning-paths/1b21d7d3-6788-4383-aa6e-0a9163e462c3/content/user-groups
   */

  getUserGroupsFromLearningPath(learningPathUid: string) {
    return `${API_URL}/learning-paths/${learningPathUid}/content/user-groups`;
  },

  /**
   * POST to sort learningPaths
   */
  sort: `${API_URL}/learning-paths/sort`,
};

export default LearningPathEndpoints;
