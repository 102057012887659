import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useLocation } from "react-router-dom";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";

const ResetSelectedUserGroup = () => {
  const dispatch = useDispatch();
  const selectedUserGroup = useSelector(selectCurrentlySelectedUserGroup);
  const routeForUserGroupFiltering: string[] = [
    RoutesEnum.PUBLISHED_CONTENT,
    RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS,
    RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_PLAYBOOK,
    RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_CHAPTER,
    RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_COLLECTION,
  ];

  const location = useLocation();
  useEffect(() => {
    if (selectedUserGroup === "") return;
    if (!routeForUserGroupFiltering.includes(location.pathname)) {
      dispatch(UserGroupAction.setSelectedUserGroup(""));
    }
  }, [location, selectedUserGroup]);

  return null;
};

export default ResetSelectedUserGroup;
