import React, { useEffect, useRef, useState } from "react";
import CreateUserGroupsForm, {
  CreateUserGroupFormRef,
} from "../forms/create-user-groups.form";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import UserModel from "../../../core/models/user/user.model";
import {
  playbooksSelector,
  selectPlaybooksFilteredByUserGroup,
} from "../../../core/redux/selectors/content/content.selector";
import PlaybookModel from "../../../core/models/content/playbook.model";
import getDifferencesInArray from "../../../core/utils/get-differences-in-array";
import UserGroupService from "../../../core/services/user-group.service";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

interface Props {
  group?: UserGroupModel;
  visible: boolean;
  onCancel: () => void;
  selectedUsers?: UserModel[] | undefined;
}

const CreateUserGroupModal = ({
  group,
  selectedUsers,
  visible,
  onCancel,
}: Props) => {
  const [t] = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const createUserGroupFormRef = useRef<CreateUserGroupFormRef>(null);
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalOkLabel, setModalOkLabel] = useState("");
  const [users, setUsers] = useState<UserModel[]>([]);
  const allPlaybooks: PlaybookModel[] = useSelector(playbooksSelector);
  const userGroupFromPlaybooks = selectPlaybooksFilteredByUserGroup(
    allPlaybooks,
    group,
  );

  useEffect(() => {
    dispatch(ContentAction.getContent(ContentTypesEnum.PLAYBOOK));
  }, []);

  useEffect(() => {
    if (group) {
      if (group.title) setModalTitle(group.title);
      setUsers(group.users);
      setModalOkLabel(Capitalize(t("common.save")));
      return;
    }

    setUsers(selectedUsers ? selectedUsers : []);
    setModalTitle(
      Capitalize(t("common.new-plural")) +
        " " +
        Capitalize(t("containers.user-groups.key")),
    );
    setModalOkLabel(Capitalize(t("common.add")));
  }, [group, selectedUsers]);

  const requesting = useSelector((state) =>
    selectRequesting(state, [
      UserGroupAction.REQUEST_CREATE_USER_GROUP,
      UserGroupAction.REQUEST_EDIT_USER_GROUP,
      UserGroupAction.REQUEST_ADD_USERS_TO_GROUP,
      UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP,
    ]),
  );

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        handleOnFinish();
      }
    }
  }, [requesting, isSubmitting]);

  const handleOnFinish = () => {
    if (createUserGroupFormRef.current) {
      createUserGroupFormRef.current.form.resetFields();
    }

    if (!selectedUsers) {
      return onCancel();
    }

    setConfirmLoading(true);
  };

  return (
    <Modal
      open={visible}
      title={modalTitle}
      width={"80rem"}
      destroyOnClose
      afterClose={() => setConfirmLoading(false)}
      okText={modalOkLabel}
      okButtonProps={{ disabled: confirmLoading }}
      onOk={handleOnOk}
      cancelText={Capitalize(t("common.cancel"))}
      cancelButtonProps={{ disabled: confirmLoading }}
      onCancel={handleOnCancel}
    >
      <CreateUserGroupsForm
        ref={createUserGroupFormRef}
        group={group}
        groupUsers={users}
      />
    </Modal>
  );

  async function handleOnOk() {
    if (!createUserGroupFormRef.current) return;

    const res = await createUserGroupFormRef.current.form.validateFields();
    const userGroupService = new UserGroupService(dispatch);

    if (!group) {
      // Create User Group
      const userGroupName = res.title
        .trim()
        .replace(/[^a-z0-9-_]/gi, "_")
        .toLowerCase();
      dispatch(
        UserGroupAction.createUserGroup({
          title: userGroupName,
          description: res.description,
          usernames: res.usernames,
        }),
      );

      userGroupService.addPlaybooksToUserGroup(
        allPlaybooks,
        userGroupName,
        res.playbookUids,
      );
      setIsSubmitting(true);
      return;
    }

    // Edit group description if needed
    if (group.description !== res.description) {
      dispatch(
        UserGroupAction.editUserGroup(group.title, {
          description: res.description,
        }),
      );
    }

    if (res.playbookUids) {
      // Update playbooks
      const [playbooksToAdd, playbooksToRemove] = getDifferencesInArray(
        userGroupFromPlaybooks.map((p) => ({
          playbookUid: p.playbookUid,
          isShared: !!p.apiKeyClientUid,
        })),
        res.playbookUids,
      );

      userGroupService.removePlaybooksFromUserGroup(
        allPlaybooks,
        group.title,
        playbooksToRemove,
      );
      userGroupService.addPlaybooksToUserGroup(
        allPlaybooks,
        group.title,
        playbooksToAdd,
      );
    }

    // Update users
    const [usersToAdd, usersToRemove] = getDifferencesInArray(
      group.users.map((u) => u.username),
      res.usernames,
    );

    userGroupService.addUsersToUserGroup(group.title, usersToAdd);
    userGroupService.removeUsersFromUserGroup(group.title, usersToRemove);

    setIsSubmitting(true);
  }

  function handleOnCancel() {
    if (createUserGroupFormRef.current) {
      createUserGroupFormRef.current.form.resetFields();
    }

    onCancel();
  }
};

export default CreateUserGroupModal;
