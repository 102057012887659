import React, { useEffect, useState } from "react";
import { Capitalize } from "../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../constants/routes/app.route";
import { NavLink, useLocation } from "react-router-dom";
import { Col, Dropdown, Menu } from "antd";
import {
  AppstoreOutlined,
  BellOutlined,
  BranchesOutlined,
  FlagOutlined,
  QrcodeOutlined,
  RiseOutlined,
  TagsOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import MenuItemInterface from "../../core/interfaces/menu-item.interface";
import routePermissionChecker from "../../core/utils/route-permission-checker";
import UserRoleEnum from "../../core/enums/user-role.enum";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../core/redux/selectors/user/user.selector";
import useActiveFeatureFlags from "../../core/hooks/useFeatureFlags";

const useMenuItems = () => {
  const [menuItems, setMenuItems] = useState<MenuItemInterface[]>([]);
  const { t } = useTranslation();
  const currentUserRole: UserRoleEnum =
    useSelector(selectCurrentUserRole) ?? UserRoleEnum.ADMIN;
  const globalAdminMenu: MenuItemInterface[] = [
    {
      title: Capitalize(t("containers.modules.title")),
      route: RoutesEnum.PLAYBOOKS,
      icon: <AppstoreOutlined />,
      children: [
        {
          title: Capitalize(t("containers.modules.title")),
          route: RoutesEnum.PLAYBOOKS,
          icon: <AppstoreOutlined />,
        },
        {
          title: Capitalize(t("containers.categories.title")),
          route: RoutesEnum.CATEGORIES,
          icon: <TagsOutlined />,
        },
      ],
    },
    {
      title: Capitalize(t("containers.learning-path.title")),
      route: RoutesEnum.LEARNING_PATH,
      icon: <BranchesOutlined />,
      featureFlag: "learning-path",
    },
    {
      title: Capitalize(t("containers.qr.title")),
      route: RoutesEnum.QR_CODE,
      icon: <QrcodeOutlined />,
    },
    {
      title: Capitalize(t("containers.activity.title")),
      route: RoutesEnum.ACTIVITY,
      icon: <RiseOutlined />,
    },
    {
      title: Capitalize(t("containers.notifications.title")),
      route: RoutesEnum.NOTIFICATIONS,
      icon: <BellOutlined />,
    },
    {
      title: Capitalize(t("containers.users.title")),
      route: RoutesEnum.USERS,
      icon: <UserOutlined />,
      children: [
        {
          title: Capitalize(t("containers.users.title")),
          route: RoutesEnum.USERS,
          icon: <UserOutlined />,
        },
        {
          title: Capitalize(t("containers.user-groups.title")),
          route: RoutesEnum.USER_GROUPS,
          icon: <UsergroupAddOutlined />,
        },
        {
          title: Capitalize(t("containers.invitations.title")),
          route: RoutesEnum.INVITATIONS,
          icon: <UserAddOutlined />,
          featureFlag: "invitation",
        },
      ],
    },
    {
      title: "Feature flags",
      route: RoutesEnum.FEATURE_FLAGS,
      icon: <FlagOutlined />,
    },
  ];

  const groupAdminMenu: MenuItemInterface[] = [
    {
      title: Capitalize(t("containers.modules.title")),
      route: RoutesEnum.PLAYBOOKS,
      icon: <AppstoreOutlined />,
    },
    {
      title: Capitalize(t("containers.activity.title")),
      route: RoutesEnum.PUBLISHED_CONTENT,
      icon: <RiseOutlined />,
    },
    {
      title: Capitalize(t("containers.notifications.title")),
      route: RoutesEnum.NOTIFICATIONS,
      icon: <BellOutlined />,
    },
    {
      title: Capitalize(t("containers.user-groups.title")),
      route: RoutesEnum.USER_GROUPS,
      icon: <UsergroupAddOutlined />,
    },
    {
      title: Capitalize(t("containers.invitations.title")),
      route: RoutesEnum.INVITATIONS,
      icon: <UserAddOutlined />,
    },
  ];

  useEffect(() => {
    switch (currentUserRole) {
      case UserRoleEnum.SYS_ADMIN:
      case UserRoleEnum.PRIMIO_SUPPORT:
      case UserRoleEnum.ADMIN:
        setMenuItems(globalAdminMenu);
        break;
      case UserRoleEnum.GROUP_ADMIN:
        setMenuItems(groupAdminMenu);
        break;
    }
  }, [currentUserRole, t]);

  return menuItems;
};

const MenuBarComponent = () => {
  const location = useLocation();
  const currentUserRole: UserRoleEnum =
    useSelector(selectCurrentUserRole) ?? UserRoleEnum.ADMIN;
  const menuItems = useMenuItems();
  const checkFeatureFlags = useActiveFeatureFlags();

  const getSelectedKeys = (): string[] => {
    let key = "";

    menuItems.forEach((menuItem: MenuItemInterface, index: number) => {
      if (menuItem.children) {
        menuItem.children.forEach((child: MenuItemInterface) => {
          if (location.pathname.includes(child.route)) {
            key = index.toString();
          }
        });
      }

      if (location.pathname.includes(menuItem.route)) {
        key = index.toString();
      }
    });

    return [key];
  };

  const renderMenuBarDropdown = (menuItem: MenuItemInterface) => {
    const dropdown = (
      <Menu>
        {menuItem.children?.map((child: MenuItemInterface, index: number) => {
          const canAccess = routePermissionChecker(
            child.route as RoutesEnum,
            currentUserRole,
          );

          if (
            !canAccess ||
            (child.featureFlag &&
              !checkFeatureFlags.includes(child.featureFlag))
          ) {
            return null;
          }

          return (
            <Menu.Item key={index.toString()} icon={child.icon}>
              <NavLink to={child.route}>{child.title}</NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <Dropdown
        overlay={dropdown}
        placement={"bottomCenter"}
        overlayStyle={{
          paddingTop: "2rem",
          paddingRight: "2rem",
        }}
      >
        <NavLink to={menuItem.route}>{menuItem.title}</NavLink>
      </Dropdown>
    );
  };

  return (
    <>
      <Col flex={"auto"}>
        <Menu
          mode={"horizontal"}
          selectedKeys={getSelectedKeys()}
          style={{ justifyContent: "center" }}
        >
          {menuItems.map((menuItem: MenuItemInterface, index: number) => {
            const canAccess = routePermissionChecker(
              menuItem.route as RoutesEnum,
              currentUserRole,
            );

            if (
              !canAccess ||
              (menuItem.featureFlag &&
                !checkFeatureFlags.includes(menuItem.featureFlag))
            ) {
              return null;
            }

            return (
              <Menu.Item key={index} icon={menuItem.icon}>
                {menuItem.children ? (
                  renderMenuBarDropdown(menuItem)
                ) : (
                  <NavLink to={menuItem.route}>{menuItem.title}</NavLink>
                )}
              </Menu.Item>
            );
          })}
        </Menu>
      </Col>
    </>
  );
};

export default MenuBarComponent;
