import { useQuery } from "@tanstack/react-query";
import { api } from "../api/config/axiosConfigs";

const getUid = (path: string) => {
  const part1 = path.split("/media/")[1];
  return part1.split("/")[0];
};

const getUrl = (path: string) => {
  return path.split("primio.app")[1];
};

export const useImportedMedia = (path: string) =>
  useQuery(["external-media", getUid(path)], async () => {
    const { data } = await api.request<string>({
      url: getUrl(path),
      method: "GET",
    });
    return data;
  });

export default useImportedMedia;
