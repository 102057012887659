import React, { useState } from "react";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Link } from "react-router-dom";
import { Table, Tooltip } from "antd";
import useUserLearningPathReport, {
  UserLearningPathReport,
} from "../../../core/hooks/useUserLearningPathReport";
import UserModel from "../../../core/models/user/user.model";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { GroupTags } from "../group-tag";

import createDateColumn from "./columns/date-column";
import DownloadCertificateButton from "../download-certificate-button/download-certificate-button";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import SelectionContextMenu from "../selection-context-menu/selection-context-menu";
import useDownloadCertificates from "../../../core/hooks/use-download-certificates";

const UserLearningPathReportTable = ({ user }: { user: UserModel }) => {
  const { userLearningPathReport } = useUserLearningPathReport(user);
  const { t } = useTranslation();
  const currentUserRole: UserRoleEnum | undefined = useSelector(
    selectCurrentUserRole,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { downloadCertificates } = useDownloadCertificates();

  return (
    <>
      <SelectionContextMenu
        selectedRowKeys={selectedRowKeys}
        onClearSelection={() => setSelectedRowKeys([])}
        actions={[
          {
            key: "download",
            label: (selectedRowKeys) => {
              const count = userLearningPathReport.filter(
                (u) =>
                  selectedRowKeys.includes(u.learningPathUid) &&
                  !!u.completedAt,
              ).length;

              return `${Capitalize(
                t("translations:common.download_x", {
                  item: t("translations:common.certificate", { count }),
                }),
              )} (${count})`;
            },
            icon: <DownloadOutlined />,
            onClick: async (selectedRowKeys) => {
              const data = userLearningPathReport.filter(
                (u) =>
                  selectedRowKeys.includes(u.learningPathUid) &&
                  !!u.completedAt,
              );

              if (data.length === 0) {
                return;
              }

              await downloadCertificates(
                data.map((u) => ({
                  userUid: user.sub,
                  learningPathUid: u.learningPathUid,
                })),
                `${t("translations:common.certificate_plural")}.zip`,
              );
            },
          },
        ]}
      />
      <Table
        dataSource={userLearningPathReport}
        size="small"
        rowSelection={{
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
          ],
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        pagination={{
          showSizeChanger: true,
        }}
        rowKey={(data) => data.learningPathUid}
        columns={[
          {
            title: Capitalize(t("form.items.name.label")),
            dataIndex: "title",
            ellipsis: true,
            width: 300,
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: (value, record) => {
              if (
                currentUserRole &&
                currentUserRole === UserRoleEnum.GROUP_ADMIN
              ) {
                return <>{value}</>;
              }

              const url =
                record.type === null
                  ? RoutesEnum.LEARNING_PATH_DETAIL
                  : RoutesEnum.LEARNING_PATH_DETAIL_LEGACY;
              return (
                <Link to={url.replace(":uid", record.learningPathUid)}>
                  {value}
                </Link>
              );
            },
          },
          {
            title: Capitalize(t("containers.user-groups.key_plural")),
            dataIndex: "userGroups",
            render: (value: string[]) => <GroupTags groupNames={value} />,
          },
          createDateColumn<UserLearningPathReport>({
            title: Capitalize(t("translations:form.items.started-at.label")),
            dataIndex: "startedAt",
          }),
          createDateColumn<UserLearningPathReport>({
            title: Capitalize(t("translations:form.items.completed-at.label")),
            dataIndex: "completedAt",
          }),
          {
            dataIndex: "hasAccess",
            width: 40,
            render: (value: boolean) =>
              !value && (
                <Tooltip
                  title={t(
                    "translations:errors.user.no-access-to-learning-path",
                  )}
                >
                  <ExclamationCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ),
          },
          {
            title: Capitalize(t("translations:common.certificate")),
            width: 100,
            render: (_value, record) => {
              return (
                <div style={{ textAlign: "right" }}>
                  {!!record.completedAt && (
                    <DownloadCertificateButton
                      userName={user.name}
                      userUid={user.sub}
                      learningPathTitle={record.title}
                      learningPathUid={record.learningPathUid}
                    />
                  )}
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default UserLearningPathReportTable;
