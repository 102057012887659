import BaseCardModel, { BaseCardResponse } from "../../base-card.model";
import { CardTypesEnum } from "../../../../enums/card-types.enum";
import QuestionCardDataModel, {
  QuestionCardDataResponse,
} from "./question-card-data.model";

export interface QuestionCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.QUESTION;
  data: QuestionCardDataResponse;
}

export default class QuestionCardModel extends BaseCardModel {
  cardType: CardTypesEnum.QUESTION;
  data: QuestionCardDataModel;

  constructor(questionCard: QuestionCardResponse) {
    super(questionCard);
    this.cardType = questionCard.cardType;
    this.data = new QuestionCardDataModel(questionCard.data);
  }
}
