import { CognitoUser } from "amazon-cognito-identity-js";
import CognitoService from "../services/cognito.service";

function getCurrentAuthenticatedUser() {
  try {
    const cognitoService = new CognitoService();
    return cognitoService.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
  }
}

function isTokenWithinTimeRange(expiration: number) {
  const TWO_MINUTES = 1000 * 60 * 2;
  return expiration - TWO_MINUTES > Date.now();
}

async function getJwtTokenAsCognitoUser(cognitoUser: CognitoUser) {
  return new Promise<string>((resolve, reject) => {
    cognitoUser.getSession((error, session) => {
      if (error || !session) {
        reject();
      }

      const accessToken = session.getAccessToken();

      if (isTokenWithinTimeRange(accessToken.getExpiration() * 1000)) {
        return resolve(accessToken.getJwtToken());
      }

      const refreshToken = session.getRefreshToken();
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err || !session) {
          reject();
        }
        const tokens = getTokens(session);

        cognitoUser.setSignInUserSession(session);
        resolve(tokens.accessToken);
      });
    });
  });
}

const getTokens = function (session: any) {
  return {
    accessToken: session.getAccessToken().getJwtToken(),
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

export default async function getJwtToken() {
  const cognitoUser = getCurrentAuthenticatedUser();

  if (!cognitoUser) {
    return;
  }

  return new Promise<string | undefined>((resolve, reject) => {
    getJwtTokenAsCognitoUser(cognitoUser).then(resolve).catch(reject);
  });
}
