import React from "react";
import { useSelector } from "react-redux";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { PaperClipOutlined } from "@ant-design/icons";
import { Image, Skeleton } from "antd";
import Link from "antd/lib/typography/Link";
import DecodeMediaUri from "../../../core/utils/decode-media-uri.utils";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import MediaAction from "../../../core/redux/stores/media/media.action";
import { DisplayModeEnum } from "../../../core/enums/display-mode.enum";

interface Props {
  path: string;
  visible?: boolean;
  width?: number | string;
  height?: number | string;
  displayMode?: string;
}

const ImageMediaComponent = (props: Props) => {
  const { path, visible, width, height, displayMode } = props;
  const requesting: boolean = useSelector((state) =>
    selectRequesting(state, [MediaAction.REQUEST_MEDIA]),
  );
  const error: HttpErrorResponseModel = useSelector((state) =>
    selectRawErrors(state, [MediaAction.REQUEST_MEDIA_FINISHED]),
  );

  const isPortrait = displayMode === DisplayModeEnum.PORTRAIT;

  const imageHeight = isPortrait ? 130 : height;
  const imageWidth = isPortrait ? 75 : width;

  if (requesting || (error && error.errors)) {
    return <Skeleton.Image style={{ width: width, height: height }} />;
  }

  return (
    <>
      <Image
        width={imageWidth}
        height={imageHeight}
        src={path}
        style={{ objectFit: "cover" }}
      />
      {visible && (
        <div>
          <PaperClipOutlined />
          <Link href={path} target={"_blank"}>
            {DecodeMediaUri(path)}
          </Link>
        </div>
      )}
    </>
  );
};

ImageMediaComponent.defaultProps = {
  visible: true,
  width: 130,
  height: 75,
};

export default ImageMediaComponent;
