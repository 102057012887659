import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import ApiKeyContentModel, {
  ApiKeyContentResponse,
} from "./api-key-content.model";
import ApiKeySubscriberModel, {
  ApiKeySubscriberResponse,
} from "./api-key-subscriber.model";

export interface ApiKeyResponse {
  key: string;
  description: string;
  content: ApiKeyContentResponse[];
  active: boolean;
  subscribers?: ApiKeySubscriberResponse[];
  createdAt: string;
  updatedAt?: string;
}

export default class ApiKeyModel {
  key: string;
  description: string;
  content: ApiKeyContentModel[];
  active: boolean;
  subscribers: ApiKeySubscriberModel[];
  createdAt: Date;
  updatedAt?: Date;

  constructor(response: ApiKeyResponse) {
    this.key = response.key;
    this.description = response.description;
    this.content = MapResponseToModel(response.content, ApiKeyContentModel);
    this.active = response.active;

    if (response.subscribers) {
      this.subscribers = MapResponseToModel(
        response.subscribers,
        ApiKeySubscriberModel,
      );
    } else {
      this.subscribers = [];
    }

    this.createdAt = new Date(response.createdAt);

    if (response.updatedAt) {
      this.updatedAt = new Date(response.updatedAt);
    }
  }
}
