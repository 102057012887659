import React, { useEffect, useState } from "react";
import { DateRange } from "../../../core/enums/date-range.enum";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

interface OwnProps {
  range: DateRange;
  options?: DateRange[];
  onChange: (value: DateRange) => void;
}

type Props = OwnProps;

const DateRangeComponent = (props: Props) => {
  const { t } = useTranslation();
  const { range, onChange, options = Object.values(DateRange) } = props;
  const [value, setValue] = useState<DateRange>(range);

  useEffect(() => {
    if (!options.includes(range)) return;
    setValue(range);
  }, [range]);

  useEffect(() => {
    onChange(DateRange[value]);
  }, [value]);

  return (
    <Select defaultValue={value} onSelect={setValue} style={{ minWidth: 120 }}>
      {options.map((option, index) => (
        <Select.Option key={index} value={DateRange[option]}>
          {t(`common.period.${option}`)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DateRangeComponent;
