import TranslateDefaults from "./translate-defaults.utils";

export default function TextDecodeUtils(text: string) {
  return TranslateDefaults(text)
    .replace(/(<([^>]+)>)/gi, "") // remove html tags
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&#x60;/g, "`");
}
