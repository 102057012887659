import React from "react";
import { AnswerTypeEnum } from "../../../core/enums/answer-type.enum";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import { getOrdinalNumberUtils } from "../../../core/utils/get-ordinal-number.utils";
import { useFavouriteCards } from "../../../core/hooks/favourite/favourite.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import {
  useUserContentCompletion,
  useUsersProgressCollectionDetail,
} from "../../../core/hooks/user-progress/user-progress.hook";
import ContentProgressMeta from "../content-user-progress/content-progress.meta";
import MediaSuffixTag from "./tags/media-suffix.tag";
import CardTag from "./tags/card-tag";
import UserProgressCardDetailModel from "../../../core/models/user-progress/collection-detail/user-progress-card-detail.model";
import UsersFavouriteCardModel from "../../../core/models/favourite/users-favourite-card.model";
import UserCardCompletionModel from "../../../core/models/user-progress/user-content-completion/user-card-completion.model";
import IndexAnswerModel from "../../../core/models/user-progress/collection-detail/answer-types/index-answer.model";
import CardTypes from "../../../core/types/card.types";
import Moment from "moment";
import PopoverImage from "../popover-image/popover-image";
import { Tooltip } from "antd";

interface OwnProps {
  card: CardTypes;
  userUid: string;
  collectionUid: string;
}

type Props = OwnProps;

const CardUserAnswerSuffixComponent = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { card, userUid, collectionUid } = props;
  //
  const { data: completion } = useUserContentCompletion(
    userUid,
    collectionUid,
    card.cardUid,
  );
  const cardCompletion = completion as UserCardCompletionModel | undefined;
  //
  const { data: favoCard } = useFavouriteCards(collectionUid, card.cardUid);
  const favouriteCard = favoCard as UsersFavouriteCardModel | undefined;
  //
  const { data: progress } = useUsersProgressCollectionDetail(
    collectionUid,
    card.cardUid,
  );
  const cardDetail = progress as UserProgressCardDetailModel | undefined;

  const completedAt = () => {
    if (!cardCompletion) {
      return null;
    }
    const completedVersions = cardCompletion.data.map((c) => c.version);
    const hasCompletedLatestVersion = completedVersions.includes(card.version);

    if (hasCompletedLatestVersion) {
      return (
        <ContentProgressMeta
          icon={"timeSpent"}
          label={
            cardCompletion
              ? Moment(cardCompletion.data[0].completedAt).format("DD-MM-YY")
              : " "
          }
        />
      );
    }

    return (
      <Tooltip
        title={t(
          "translations:screens.user-detail.tags.not-completed-recent-card",
        )}
      >
        <span>
          <CardTag
            type={"incorrect-clock"}
            label={
              cardCompletion
                ? Moment(cardCompletion.data[0].completedAt).format("DD-MM-YY")
                : " "
            }
          />
        </span>
      </Tooltip>
    );
  };

  switch (card.cardType) {
    case CardTypesEnum.KNOWLEDGE_CARD:
      return (
        <>
          {cardCompletion && cardCompletion.data.length > 1 && (
            <CardTag
              type={"viewed"}
              label={`${cardCompletion.data.length}x ${t("common.views")}`}
            />
          )}
          {completedAt()}
          <MediaSuffixTag card={card} />
          {favouriteCard?.cardUid === card.cardUid &&
            favouriteCard.userUids.includes(userUid) && (
              <ContentProgressMeta
                icon={"bookmark"}
                label={Capitalize(t("common.bookmarked"))}
              />
            )}
        </>
      );
    case CardTypesEnum.PICTURE_QUIZ:
    case CardTypesEnum.QUIZ:
      return (
        <>
          {getQuizTag()}
          {completedAt()}
          {getAnswerTag()}
        </>
      );
    case CardTypesEnum.MULTIPLE_CHOICE:
      return (
        <>
          {completedAt()}
          {getAnswerTag()}
        </>
      );
    case CardTypesEnum.SLIDER:
      return (
        <>
          {getSliderTag()}
          {completedAt()}
          {getAnswerTag()}
        </>
      );
    case CardTypesEnum.QUESTION:
      return (
        <>
          {getQuestionTag()}
          {completedAt()}
          {getAnswerTag()}
        </>
      );
    default:
      return null;
  }

  function getAnswerTag() {
    if (!cardCompletion || cardCompletion.data.length === 0) {
      return;
    }

    const latestUserAnswer =
      cardCompletion.data[cardCompletion.data.length - 1].answer;
    if (latestUserAnswer === undefined) {
      return;
    }

    switch (card.cardType) {
      case CardTypesEnum.QUIZ:
        return (
          <ContentProgressMeta
            icon={"answers"}
            label={card.data.options[latestUserAnswer].content}
          />
        );
      case CardTypesEnum.PICTURE_QUIZ:
        return (
          <PopoverImage src={card.data.options[latestUserAnswer].media.uri}>
            <div>
              <ContentProgressMeta
                icon={"answers"}
                label={`${Capitalize(t("translations:common.picture"))} ${
                  card.data.options[latestUserAnswer].sort + 1
                }`}
              />
            </div>
          </PopoverImage>
        );
      case CardTypesEnum.MULTIPLE_CHOICE:
        return (
          <ContentProgressMeta
            icon={"answers"}
            label={card.data.options[latestUserAnswer].content}
          />
        );
      case CardTypesEnum.SLIDER:
        return (
          <ContentProgressMeta
            icon={"answers"}
            label={card.data.options[latestUserAnswer].label}
          />
        );
      case CardTypesEnum.QUESTION:
        return (
          <ContentProgressMeta icon={"answers"} label={`${latestUserAnswer}`} />
        );
      case CardTypesEnum.KNOWLEDGE_CARD:
        return;
    }
  }

  function getQuizTag() {
    if (!cardCompletion) {
      return;
    }

    if (
      !(
        card.cardType === CardTypesEnum.PICTURE_QUIZ ||
        card.cardType === CardTypesEnum.QUIZ
      )
    ) {
      return;
    }

    if (cardCompletion.data.length === 0) {
      return (
        <CardTag
          type={"no-answer"}
          label={Capitalize(t("form.card.answers.no-answer"))}
        />
      );
    }

    const findCorrectAnswer = card.data.options.findIndex((o) => o.isCorrect);
    const latestUserAnswer =
      cardCompletion.data[cardCompletion.data.length - 1].answer;
    if (latestUserAnswer === undefined) {
      return;
    }

    if (findCorrectAnswer === latestUserAnswer) {
      return (
        <CardTag type={"correct"} label={Capitalize(t("common.correct"))} />
      );
    } else {
      return (
        <CardTag type={"incorrect"} label={Capitalize(t("common.incorrect"))} />
      );
    }
  }

  function getSliderTag() {
    if (
      !cardCompletion ||
      card.cardType !== CardTypesEnum.SLIDER ||
      cardCompletion.data.length === 0
    ) {
      return;
    }

    if (
      !cardDetail ||
      cardDetail.answerType !== AnswerTypeEnum.SLIDER ||
      cardDetail.answers.length === 0
    ) {
      return;
    }

    const latestUserAnswer =
      cardCompletion.data[cardCompletion.data.length - 1].answer;
    const sortedAnswers = cardDetail.answers.sort(
      (a, b) => b.amount - a.amount,
    ) as IndexAnswerModel[];
    const mostPopular = sortedAnswers[0] as IndexAnswerModel;

    if (
      latestUserAnswer === undefined ||
      latestUserAnswer !== mostPopular.index
    ) {
      return;
    }

    return <CardTag type={"popular"} label={Capitalize(t("common.popular"))} />;
  }

  function getQuestionTag() {
    if (
      !cardCompletion ||
      card.cardType !== CardTypesEnum.QUESTION ||
      cardCompletion.data.length === 0
    ) {
      return;
    }

    const amount = cardCompletion.data.length;
    const ordinalNumber = getOrdinalNumberUtils(amount, i18n.language);

    return (
      <CardTag
        type={"question"}
        label={`${amount}${t(ordinalNumber)} ${t("common.answer")}`}
      />
    );
  }
};

export default CardUserAnswerSuffixComponent;
