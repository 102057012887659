import { MapResponseToModel } from "../../../../utils/map-response-to-model.utils";
import PictureQuizOptionModel, {
  PictureQuizOptionResponse,
} from "./picture-quiz-option.model";

export interface PictureQuizCardDataResponse {
  options: PictureQuizOptionResponse[];
  question: string;
}

export default class PictureQuizCardDataModel {
  options: PictureQuizOptionModel[];
  question: string;

  constructor(quizCard: PictureQuizCardDataResponse) {
    this.options = MapResponseToModel(
      quizCard.options,
      PictureQuizOptionModel,
    ).sort((a, b) => a.sort - b.sort);
    this.question = quizCard.question;
  }
}
