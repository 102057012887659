import React from "react";
import { Progress, Typography } from "antd";
import styles from "./graph-container/graph-container.module.css";
import {
  GradientColors_ProgressCompleted,
  GradientColors_ProgressPending,
  GradientColors_ProgressStarted,
} from "../../styles/colors";

interface OwnProps {
  amount: number;
  color: { from: string; to: string };
  showValue?: boolean;
  size?: number;
}

type Props = OwnProps;

const GradientProgressBarComponent = ({
  amount,
  color,
  showValue = true,
  size = 11,
}: Props) => {
  let className = styles.boxShadow;
  switch (color.from) {
    case GradientColors_ProgressPending.from:
      className = styles.boxShadow_red;
      break;
    case GradientColors_ProgressStarted.from:
      className = styles.boxShadow_yellow;
      break;
    case GradientColors_ProgressCompleted.from:
      className = styles.boxShadow_green;
      break;
  }

  return (
    <div>
      {showValue && (
        <Typography.Title level={5} style={{ marginBottom: ".2rem" }}>
          {amount}
        </Typography.Title>
      )}
      <div style={{ width: "100%", fontSize: 0 }}>
        <Progress
          percent={100}
          strokeWidth={size}
          showInfo={false}
          style={{ fontSize: 0 }}
          className={className}
          strokeColor={{ "0%": color.from, "100%": color.to }}
        />
      </div>
    </div>
  );
};

export default GradientProgressBarComponent;
