import EffectUtility from "../../utils/effect.utils";
import TrackPlaybooksEndpoint from "../../api/user-progress/track-playbooks.endpoint";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import TrackPlaybookModel from "../../models/track-playbook/track-playbook.model";
import HttpUtility from "../../utils/http.utils";

export async function getTrackPlaybooks() {
  const endpoint = TrackPlaybooksEndpoint.overview;
  const response = await EffectUtility.getToModel<TrackPlaybookModel>(
    TrackPlaybookModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}

export async function getTrackPlaybooksMutation(playbookUids: string[]) {
  const endpoint = TrackPlaybooksEndpoint.overview;
  const response = await HttpUtility.post(endpoint, {
    playbookUids: playbookUids,
  });

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
