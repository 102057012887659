import EffectUtility from "../../utils/effect.utils";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import InvitationModel from "../../models/invitation/invitation.model";
import InvitationEndpoints from "../../api/invitation.endpoints";

export async function getInvitations() {
  const endpoint = InvitationEndpoints.overview;
  const response = await EffectUtility.getToModel<InvitationModel[]>(
    InvitationModel,
    endpoint,
  );

  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }

  return response;
}
