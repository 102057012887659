import { ContentNotificationData } from "./content-notification.model";
import { AnnouncementData } from "./announcement.model";
import { NotificationTypesEnum } from "../../enums/notification-types.enum";
import NotificationModel from "./notification.model";
import NotifiableModel from "./notifiable.model";

export type BaseNotificationData = AnnouncementData | ContentNotificationData;

export interface BaseNotificationResponse {
  scheduledNotificationUid: string;
  notificationType: NotificationTypesEnum;
  notifiable: NotifiableModel;
  data: BaseNotificationData;
  createdAt: Date;
  createdBy: string;
  sendAt: Date;
  sentAt?: Date;
  seen: number;
  received: number;
  //
  completed: boolean;
  notifications: NotificationModel[];
  errors: [];
}

export default class BaseNotificationModel {
  notificationUid: string;
  notificationType: NotificationTypesEnum;
  notifiable: NotifiableModel;
  data: BaseNotificationData;
  createdAt: Date;
  createdBy: string;
  scheduledAt: Date;
  sentAt?: Date;
  seenBy: number;
  receivedBy: number;
  //
  amountOfUsers = 0;

  constructor(notification: BaseNotificationResponse) {
    this.notificationUid = notification.scheduledNotificationUid;
    this.notificationType = notification.notificationType;
    this.notifiable = notification.notifiable;
    this.data = notification.data;
    this.createdAt = notification.createdAt;
    this.createdBy = notification.createdBy;
    this.scheduledAt = notification.sendAt;
    this.sentAt = notification.sentAt;
    this.seenBy = notification.seen;
    this.receivedBy = notification.received;
  }
}
