import React from "react";
import { AntdIconProps } from "./content-icon.component";
import {
  CalendarOutlined,
  DoubleRightOutlined,
  FieldTimeOutlined,
  DownSquareOutlined,
} from "@ant-design/icons";
import { LearningPathTypeEnum } from "../../../core/enums/learning-path-state.enum";

interface OwnProps {
  type: LearningPathTypeEnum;
}

type Props = OwnProps & AntdIconProps;

const LearningPathTypeIconComponent = ({ type, ...iconProps }: Props) => {
  switch (type) {
    case LearningPathTypeEnum.TIME_BASED:
      return <FieldTimeOutlined {...iconProps} />;
    case LearningPathTypeEnum.PROGRESS_BASED:
      return <DoubleRightOutlined {...iconProps} />;
    case LearningPathTypeEnum.SCHEDULE_BASED:
      return <CalendarOutlined {...iconProps} />;
    case null:
    case LearningPathTypeEnum.OPEN:
      return <DownSquareOutlined {...iconProps} />;
  }
};

export default LearningPathTypeIconComponent;
