import CollectionModel, { CollectionResponse } from "./collection.model";
import CardTypes, { CardResponseTypes } from "../../types/card.types";
import { CardModelMapper } from "../../redux/stores/content/content.effect";

export interface CollectionHierarchyResponse extends CollectionResponse {
  cards: CardResponseTypes[];
}

export default class CollectionHierarchyModel extends CollectionModel {
  cards: CardTypes[];

  constructor(response: CollectionHierarchyResponse) {
    super(response);

    this.cards = response.cards.map((cardResponse: CardResponseTypes) => {
      const model = CardModelMapper(cardResponse.cardType);

      if (!model) {
        throw new Error(`CardType ${cardResponse.cardType} is not supported`);
      }

      // @ts-ignore
      return new model(cardResponse);
    });
  }
}
