import PlaybookCompletedUserProgressModel, {
  PlaybookCompletedUserProgressResponse,
} from "./completed-user-progress-type/playbook-completed-user-progress.model";

interface UsersCompletedUserProgressResponse {
  users: { [userUid: string]: CompletedUserProgressResponse };
}

export class UsersCompletedUserProgressModel {
  users: CompletedUserProgressModel[];

  constructor(response: UsersCompletedUserProgressResponse) {
    if (response.users) {
      this.users = Object.entries(response.users).map(([userUid, response]) => {
        return new CompletedUserProgressModel(userUid, response);
      });
    } else {
      this.users = [];
    }
  }
}

export interface CompletedUserProgressResponse {
  firstActiveOn?: string;
  lastActiveOn?: string;
  totalTimeSpent?: number;
  progress?: { [playbookUid: string]: PlaybookCompletedUserProgressResponse };
}

export default class CompletedUserProgressModel {
  userUid: string;
  firstActiveOn?: Date;
  lastActiveOn?: Date;
  totalTimeSpent: number;
  playbooks: PlaybookCompletedUserProgressModel[];

  constructor(userUid: string, response: CompletedUserProgressResponse) {
    this.userUid = userUid;

    if (response.firstActiveOn) {
      this.firstActiveOn = new Date(response.firstActiveOn);
    }

    if (response.lastActiveOn) {
      this.lastActiveOn = new Date(response.lastActiveOn);
    }

    this.totalTimeSpent = response.totalTimeSpent ? response.totalTimeSpent : 0;

    if (response.progress) {
      this.playbooks = Object.entries(response.progress).map(
        ([playbookUid, response]) => {
          return new PlaybookCompletedUserProgressModel(playbookUid, response);
        },
      );
    } else {
      this.playbooks = [];
    }
  }
}
