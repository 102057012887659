import React, { useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { MediaTypesEnum } from "../../../core/enums/media-providers.enum";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import MediaUploadUtils from "../../../core/utils/media-upload.utils";
import MediaModel from "../../../core/models/media/media.model";
import styles from "../media-types/media.module.css";

interface Props {
  media: MediaModel | undefined;
  onUpload: (mediaUid: string) => void;
}

const AudioUploadComponent = ({ onUpload, media }: Props) => {
  const [t] = useTranslation();
  const [progress, setProgress] = useState(0);
  const [fileList, setFileList] = useState<UploadFile[] | undefined>(
    media && media.uri
      ? [
          {
            uid: media.mediaUid ? media.mediaUid : media.uri,
            name: media.uri,
            url: media.uri,
          },
        ]
      : undefined,
  );

  return (
    <Upload
      listType={"picture-card"}
      className={styles.upload_wrapper_upload}
      accept={"audio/mp3"}
      defaultFileList={fileList}
      maxCount={1}
      customRequest={handleOnUpload}
      onChange={handleOnChange}
      showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
      beforeUpload={handleBeforeUpload}
    >
      <div>
        {progress > 0 && progress !== 100 ? (
          <LoadingOutlined />
        ) : (
          <PlusOutlined />
        )}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Upload>
  );

  function handleOnUpload(options) {
    return MediaUploadUtils({
      setProgress,
      options,
      mediaType: MediaTypesEnum.AUDIO,
    }).then((e: any) => {
      if (e.status === 201) {
        onUpload(e.data);
        message.success(Capitalize(t("errors.upload.success")));
      }
    });
  }

  function handleOnChange(info) {
    const { fileList } = info;
    setFileList(fileList);
  }

  function handleBeforeUpload(file) {
    const isAudio =
      file.type === "audio/mpeg" ||
      file.type === "audio/mp4" ||
      file.type === "audio/ogg" ||
      file.type === "audio/wav";
    if (!isAudio) {
      message.error(Capitalize(t("errors.upload.format", { field: "MP3" })));
    }

    const isLt5M = file.size / 1024 / 1024 < 50;
    if (!isLt5M) {
      message.error(
        Capitalize(
          t("errors.upload.size", {
            type: Capitalize(t("content.media-types.AUDIO.label")),
            size: "50MB",
          }),
        ),
      );
    }

    return isAudio;
  }
};

export default AudioUploadComponent;
