import React, { useEffect, useState } from "react";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";
import { CreateOptionInterface } from "../option-card/create-option.form";
import { useDispatch } from "react-redux";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import SliderCardModel from "../../../../core/models/content/card-types/slider/slider-card.model";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import CreateSliderCardModel, {
  CreateSliderCardResponse,
} from "../../../../core/models/create-content/card-types/create-slider-card.model";
import CreateOptionContainerComponent from "../option-card/create-option-container.component";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import SliderOptionModel from "../../../../core/models/content/card-types/slider/slider-option.model";
import FormQuestionField from "../../form-builder/components/form-question.field";

type CreateProps = {
  cardContent: undefined;
  collectionUid: string;
};

type EditProps = {
  cardContent: SliderCardModel;
};

type Props = CreateProps | EditProps;

const CreateSliderCardForm = (props: Props) => {
  const { cardContent } = props;
  const [question, setQuestion] = useState<string>(
    cardContent ? TranslateDefaults(cardContent.data.question) : "",
  );
  const [options, setOptions] = useState<SliderOptionModel[]>(
    cardContent ? cardContent.data.options : [],
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const isCreating = props.cardContent === undefined;
  const isEditing = !isCreating;

  const maxOptions = options.length >= 5;
  const minOptions = options.length > 3;

  useEffect(() => {
    const isValid = options.map((item) => item.label.length === 0);
    setIsDisabled(isValid.includes(true));
  }, [options]);

  function handleOnFinish(
    data: CreateCardInterface,
    isPressedButton?: ContentButtonStatesEnum,
  ) {
    const createSliderCard: CreateSliderCardResponse = {
      ...data,
      title: question,
      collectionUid: props.cardContent
        ? props.cardContent.collectionUid
        : props.collectionUid,
      cardType: CardTypesEnum.SLIDER,
      mediaUids: data.mediaUids,
      data: {
        question,
        options,
      },
    };

    const CreateSliderCard = new CreateSliderCardModel(createSliderCard);

    if (isCreating) {
      dispatch(
        ContentAction.createContent(ContentTypesEnum.CARD, CreateSliderCard),
      );
    }

    if (isEditing && cardContent) {
      if (
        isPressedButton &&
        cardContent.contentState === ContentStatesEnum.PUBLISHED
      ) {
        switch (isPressedButton) {
          case ContentButtonStatesEnum.CREATE:
            dispatch(
              ContentAction.updateContent(
                ContentTypesEnum.CARD,
                cardContent,
                CreateSliderCard,
              ),
            );
            break;
          case ContentButtonStatesEnum.UPDATE:
            cardContent.version++;
            dispatch(
              ContentAction.updateAndPublishCard(cardContent, CreateSliderCard),
            );
            break;
        }
      } else {
        dispatch(
          ContentAction.updateContent(
            ContentTypesEnum.CARD,
            cardContent,
            CreateSliderCard,
          ),
        );
      }
    }
  }

  return (
    <CreateCardBaseForm
      hideFields
      cardContent={cardContent}
      disabledButton={!question || isDisabled}
      onSubmit={handleOnFinish}
    >
      <>
        <FormQuestionField question={question} setQuestion={setQuestion} />

        {options && (
          <CreateOptionContainerComponent
            options={options as SliderOptionModel[]}
            maxOptions={maxOptions}
            minOptions={minOptions}
            editOption={handleOnChangeOption}
            addOption={handleOnAddOption}
            deleteOption={() => {
              setOptions([...options] as SliderOptionModel[]);
            }}
          />
        )}
      </>
    </CreateCardBaseForm>
  );

  function handleOnAddOption() {
    if (maxOptions) {
      return;
    }

    const newOption = new SliderOptionModel({
      label: "",
      sort: 0,
    });

    setOptions([...options, newOption] as SliderOptionModel[]);
  }

  function handleOnChangeOption(index: number, value: CreateOptionInterface) {
    const newOptions: SliderOptionModel[] = [...options] as SliderOptionModel[];

    newOptions[index].label = value.content;

    setOptions([...newOptions] as SliderOptionModel[]);
  }
};

export default CreateSliderCardForm;
