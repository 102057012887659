import React, { Key, useEffect, useState } from "react";
import { BulkPublishCardsInterface } from "../../../core/interfaces/bulk-publish-cards.interface";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { connect, useDispatch } from "react-redux";
import { Button, Modal, Typography } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import LongPressButtonComponent from "../long-press/long-press-button.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import BulkPublishTree from "../tree/bulk-publish.tree";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentType from "../../../core/types/content.type";

interface StateProps {
  requesting: boolean;
}

interface OwnProps {
  visible: boolean;
  content: ContentType;
  onCancel: () => void;
}

type Props = OwnProps & StateProps;

const BulkPublishModal = (props: Props) => {
  const [t] = useTranslation();
  const { visible, content, onCancel, requesting } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [checkedKeys, setCheckedKeys] = useState<{
    checked: Key[];
    halfChecked: Key[];
  }>({
    checked: [],
    halfChecked: [],
  });

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        onCancel();
      }
    }
  }, [requesting]);

  return (
    <Modal
      open={visible}
      title={Capitalize(t("screens.bulk-publish.title"))}
      confirmLoading={requesting}
      bodyStyle={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        minHeight: "45rem",
      }}
      className={"Modal_bulk_publish"}
      onCancel={onCancel}
      width={700}
      centered
      footer={[
        <Button key={0} type={"text"} onClick={onCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <LongPressButtonComponent
          key={1}
          type={"primary"}
          ms={1000}
          loading={isSubmitting}
          disabled={disableButton()}
          callback={handleOnOk}
          labels={{
            default: Capitalize(t("common.publish")),
            pressing: Capitalize(t("common.hold_to_publish")),
            doing: Capitalize(t("common.publishing")),
          }}
        />,
      ]}
    >
      {renderModalBody()}
    </Modal>
  );

  function renderModalBody() {
    if (!content) return null;
    return (
      <>
        <Typography.Text style={{ paddingBottom: "2rem" }}>
          {Capitalize(
            t("screens.bulk-publish.subtitle", {
              content: t(
                `content.${content.contentType.toLocaleLowerCase()}.key`,
              ),
            }),
          )}
        </Typography.Text>

        <BulkPublishTree
          content={content}
          checkedKeys={checkedKeys}
          handleOnCheck={handleOnCheck}
        />
      </>
    );
  }

  function handleOnCheck(
    checkedKeys: { checked: Key[]; halfChecked: Key[] } | Key[],
    info: any,
  ) {
    setCheckedKeys({
      checked: checkedKeys as Key[],
      halfChecked: info.halfCheckedKeys as Key[],
    });
  }

  function handleOnOk() {
    const allKeys: Key[] = checkedKeys["checked"].concat(
      checkedKeys["halfChecked"],
    );
    const cards: BulkPublishCardsInterface[] = [];
    const parentContent: string[] = [];

    allKeys.forEach((key: Key) => {
      const contentType: ContentTypesEnum = key
        .toString()
        .split("_")[0]
        .toUpperCase() as ContentTypesEnum;
      const uid = key.toString().split("_")[1];
      const cardVersion = key.toString().split("_")[2];

      switch (contentType) {
        case ContentTypesEnum.PLAYBOOK:
        case ContentTypesEnum.CHAPTER:
        case ContentTypesEnum.COLLECTION:
          parentContent.push(key.toString());
          break;
        case ContentTypesEnum.CARD:
          cards.push({
            cardUid: uid,
            version: parseInt(cardVersion),
          });
          break;
      }
    });

    setIsSubmitting(true);
    dispatch(ContentAction.bulkPublishContent(parentContent, cards));
  }

  function disableButton(): boolean {
    if (checkedKeys["checked"]) {
      return checkedKeys["checked"].length === 0;
    }

    return (
      !checkedKeys || !checkedKeys["halfChecked"] || !checkedKeys["checked"]
    );
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  requesting: selectRequesting(state, [
    ContentAction.REQUEST_PUBLISH_PLAYBOOKS,
    ContentAction.REQUEST_PUBLISH_CHAPTERS,
    ContentAction.REQUEST_PUBLISH_COLLECTIONS,
    ContentAction.REQUEST_PUBLISH_CARDS,
    ContentAction.REQUEST_BULK_PUBLISH_CARDS,
  ]),
});

export default connect(mapStateToProps)(BulkPublishModal);
