import React from "react";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectCardsCountWithSameUidViaUid,
  selectLatestPublishedCardViaId,
} from "../../../core/redux/selectors/content/content.selector";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import CardTypes from "../../../core/types/card.types";

interface StateProps {
  cardsCountWithSameUid: CardTypes[];
  latestPublishedCard: CardTypes | undefined;
}

interface OwnProps {
  card: CardTypes;
}

type Props = OwnProps & StateProps;

const CardTitleWarningIcon = (props: Props) => {
  const [t] = useTranslation();
  const { cardsCountWithSameUid, latestPublishedCard, card } = props;

  if (cardsCountWithSameUid.length <= 1 || !latestPublishedCard) {
    return null;
  }

  return (
    <Tooltip
      title={
        t("common.version") +
        ` ${card.version} ` +
        t("common.from") +
        ` ${latestPublishedCard.title}`
      }
    >
      <InfoCircleOutlined style={{ color: "var(--warning-color)" }} />
    </Tooltip>
  );
};

const mapStateToProps = (
  state: StoreInterface,
  ownProps: OwnProps,
): StateProps => ({
  cardsCountWithSameUid: selectCardsCountWithSameUidViaUid(
    state,
    ownProps.card.cardUid,
  ),
  latestPublishedCard: selectLatestPublishedCardViaId(
    state,
    ownProps.card.cardUid,
  ),
});

export default connect(mapStateToProps)(CardTitleWarningIcon);
