export enum NoMediaTypesEnum {
  NO_MEDIA = "NO_MEDIA",
}

export enum MediaTypesEnum {
  NO_MEDIA = "NO_MEDIA",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  EMBED = "EMBED",
}

export enum ImageProvidersEnum {
  S3 = "S3",
  S3X = "S3X",
  UNSPLASH = "UNSPLASH",
  GIPHY = "GIPHY",
}

export enum VideoProvidersEnum {
  S3 = "S3",
  S3X = "S3X",
  VIMEO = "VIMEO",
  YOUTUBE = "YOUTUBE",
}

export enum AudioProvidersEnum {
  S3 = "S3",
  S3X = "S3X",
}

export enum EmbedProvidersEnum {
  LINK = "LINK",
  CODE = "CODE",
}

const MediaProvidersEnum = {
  NO_MEDIA: NoMediaTypesEnum,
  IMAGE: ImageProvidersEnum,
  VIDEO: VideoProvidersEnum,
  AUDIO: AudioProvidersEnum,
  EMBED: EmbedProvidersEnum,
};

export default MediaProvidersEnum;
