import React, { useRef, useState } from "react";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { LinearGradient } from "@visx/gradient";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";
import {
  GradientColors_ProgressCompleted,
  GradientColors_ProgressPending,
  GradientColors_ProgressStarted,
} from "../../styles/colors";
import Pie from "@visx/shape/lib/shapes/Pie";

export interface ContentProgressInterface {
  state: ContentProgressEnum;
  amount: number;
}

const getContentProgressColor = scaleOrdinal({
  domain: Object.keys(ContentProgressEnum),
  range: [
    GradientColors_ProgressCompleted,
    GradientColors_ProgressStarted,
    GradientColors_ProgressPending,
  ],
});

const defaultFontSize = 14;
const defaultDonutThickness = 8;
const defaultMargin = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 10,
};

export type OwnProps = {
  data: ContentProgressInterface[];
  width: number;
  height: number;
  onClick: (state: ContentProgressEnum) => void;
  //
  donutThickness?: number;
  margin?: typeof defaultMargin;
  fontSize?: number;
};

function GradientProgressDonutComponent(props: OwnProps) {
  const {
    data,
    width,
    height,
    onClick,
    donutThickness = defaultDonutThickness,
    margin = defaultMargin,
    fontSize = defaultFontSize,
  } = props;

  const [showAmount, setShowAmount] = useState<number>();
  const [selectedProgress, setSelectedProgress] =
    useState<ContentProgressEnum>();
  const id = useRef(Math.random().toString(16).slice(-4)).current;

  if (width < 10) {
    return null;
  }
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const cornerRadius = 30;
  const padAngle = 0.09;

  return (
    <svg width={width} height={height}>
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={data}
          pieValue={(d: ContentProgressInterface) => d.amount}
          innerRadius={radius - donutThickness}
          outerRadius={radius}
          cornerRadius={cornerRadius}
          padAngle={padAngle}
        >
          {(pie) =>
            pie.arcs.map((arc, index) => (
              <React.Fragment key={index}>
                <filter key={`arc-${index}`} id={`arc-${id}`}>
                  <feDropShadow
                    dx={"0"}
                    dy={"1"}
                    stdDeviation={"2.5"}
                    floodOpacity={"0.5"}
                    floodColor={
                      selectedProgress
                        ? getContentProgressColor(selectedProgress).from
                        : undefined
                    }
                  />
                </filter>

                <g
                  key={`arc-${arc.data.state.toString()}-${index}`}
                  filter={
                    selectedProgress === arc.data.state ? `url(#arc-${id})` : ""
                  }
                  style={{
                    opacity: selectedProgress === arc.data.state ? 1 : 0.95,
                    cursor: "pointer",
                  }}
                  onClick={() => onClick(arc.data.state)}
                  onMouseLeave={() => {
                    setShowAmount(undefined);
                    setSelectedProgress(undefined);
                  }}
                  onMouseMove={() => {
                    setSelectedProgress(arc.data.state);
                    setShowAmount(arc.data.amount);
                  }}
                >
                  <LinearGradient
                    id={`gradient-${index}`}
                    {...getContentProgressColor(arc.data.state)}
                  />
                  <path
                    d={pie.path(arc) as string}
                    fill={`url(#gradient-${index})`}
                  />
                </g>
              </React.Fragment>
            ))
          }
        </Pie>

        <Pie data={showAmount ? [showAmount] : undefined}>
          {(pie) =>
            pie.arcs.map((arc, index) => {
              const [centroidX, centroidY] = pie.path.centroid(arc);
              return (
                <text
                  key={`pie-${index}`}
                  fill={"var(--primary-color)"}
                  x={isNaN(centroidX) ? 0 : centroidX}
                  y={isNaN(centroidY) ? 0 : centroidY}
                  dy={".33em"}
                  fontSize={fontSize}
                  fontWeight={"bold"}
                  textAnchor={"middle"}
                  pointerEvents={"none"}
                >
                  {arc.data}
                </text>
              );
            })
          }
        </Pie>
      </Group>
    </svg>
  );
}

GradientProgressDonutComponent.displayName = "DonutContainer";

export default GradientProgressDonutComponent;
