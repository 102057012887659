export interface CreateUserGroupResponse {
  title: string;
}

export default class CreateUserGroupModel {
  title: string;

  constructor(group: CreateUserGroupResponse) {
    this.title = group.title;
  }
}
