import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import React from "react";
import Moment from "moment";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { selectUsers } from "../../../../core/redux/selectors/user/user.selector";
import UserModel from "../../../../core/models/user/user.model";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { InteractiveCard } from "../../../../core/models/user-progress/interactive-card.model";

interface StateProps {
  users: UserModel[];
}

interface OwnProps {
  data: InteractiveCard[];
}

type Props = OwnProps & StateProps;

const CardDetailOverviewQuestionScreen = ({ data, users }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnsType<InteractiveCard> = [
    {
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "userUid",
      render: function renderText(_, data) {
        const user = users.find((u) => u.sub === data.userUid);
        return user ? user.name : "-";
      },
    },
    {
      title: Capitalize(t("common.time-completed")),
      dataIndex: "completedAt",
      sorter: (a, b) => {
        if (!a.completedAt || !b.completedAt) {
          return 0;
        }
        return +new Date(a.completedAt) - +new Date(b.completedAt);
      },
      render: function renderText(_, data) {
        return data.completedAt
          ? Moment(data.completedAt).format("DD/MM/YYYY (hh:mm:ss A)")
          : "-";
      },
    },
    {
      title: Capitalize(t("common.answer")),
      dataIndex: "answer",
      sorter: (a, b) => a.answer - b.answer,
    },
  ];

  return (
    <Table<InteractiveCard>
      columns={columns}
      dataSource={data}
      rowKey={(data) => data.userUid}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"middle"}
    />
  );
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
});

export default connect(mapStateToProps)(CardDetailOverviewQuestionScreen);
