import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import CreatePlaybookForm, {
  CreatePlaybookFormRef,
} from "../forms/create-playbook.form";
import CreatePlaybookModel, {
  CreatePlaybookResponse,
} from "../../../core/models/create-content/create-playbook.model";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ContentAction from "../../../core/redux/stores/content/content.action";

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps;

const CreatePlaybookModal = (props: Props) => {
  const [t] = useTranslation();
  const { visible, onCancel } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmittingPlus, setIsSubmittingPlus] = useState<boolean>(false);
  const createPlaybookFormRef = useRef<CreatePlaybookFormRef>(null);
  const dispatch = useDispatch();
  const requesting = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_CREATE_PLAYBOOKS,
      ContentAction.REQUEST_PLAYBOOK_HIERARCHY,
    ]),
  );

  useEffect(() => {
    if (!requesting && (isSubmitting || isSubmittingPlus)) {
      setIsSubmitting(false);
      setIsSubmittingPlus(false);

      if (isSubmitting) {
        handleOnCancel();
      }
    }
  }, [requesting]);

  return (
    <Modal
      open={visible}
      title={
        Capitalize(t("common.new-plural")) +
        " " +
        Capitalize(t("containers.modules.key"))
      }
      confirmLoading={requesting}
      width={"80rem"}
      onCancel={handleOnCancel}
      destroyOnClose
      footer={[
        <Button key={0} type={"text"} onClick={handleOnCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <Button key={1} onClick={handleOnAddPlus} loading={isSubmittingPlus}>
          {t("common.create-add-another")}
        </Button>,
        <Button
          key={2}
          type={"primary"}
          onClick={handleOnOk}
          loading={isSubmitting}
        >
          {Capitalize(t("common.create"))}
        </Button>,
      ]}
    >
      <CreatePlaybookForm ref={createPlaybookFormRef} />
    </Modal>
  );

  function handleOnOk() {
    if (createPlaybookFormRef.current) {
      createPlaybookFormRef.current.form.validateFields().then((res) => {
        dispatch(
          ContentAction.createContent(
            ContentTypesEnum.PLAYBOOK,
            new CreatePlaybookModel({ ...res, mediaUids: [] }),
          ),
        );
        setIsSubmitting(true);
      });
    }
  }

  function handleOnAddPlus() {
    if (createPlaybookFormRef.current) {
      createPlaybookFormRef.current.form.validateFields().then((res) => {
        const data: CreatePlaybookResponse = {
          ...res,
          mediaUids: [],
        };
        dispatch(
          ContentAction.createContent(
            ContentTypesEnum.PLAYBOOK,
            new CreatePlaybookModel(data),
          ),
        );
        setIsSubmittingPlus(true);

        if (createPlaybookFormRef.current) {
          createPlaybookFormRef.current.form.resetFields();
          createPlaybookFormRef.current.focusTitle();
        }
      });
    }
  }

  function handleOnCancel() {
    onCancel();
    if (createPlaybookFormRef.current) {
      createPlaybookFormRef.current.form.resetFields();
    }
  }
};

export default CreatePlaybookModal;
