import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./root.reducer";

const middleware = [thunk].filter(Boolean);

const store = createStore(
  rootReducer(),
  compose(composeWithDevTools(applyMiddleware(...middleware))),
);

export default { store };
