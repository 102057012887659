import { Dispatch } from "redux";
import { ThunkType } from "../../../types/redux.type";
import {
  CategoryEditInterface,
  PlaybooksInCategoryEditInterface,
} from "../../../interfaces/category-create.interface";
import CategoryModel from "../../../models/category/category.model";
import ActionUtility from "../../../utils/action.utils";
import CategoryEffect from "./category.effect";

export default class CategoryAction {
  // Get Categories
  static REQUEST_CATEGORIES = "CategoryAction.REQUEST_CATEGORIES";
  static REQUEST_CATEGORIES_FINISHED =
    "CategoryAction.REQUEST_CATEGORIES_FINISHED";

  // Get Category
  static REQUEST_CATEGORY = "CategoryAction.REQUEST_CATEGORY";
  static REQUEST_CATEGORY_FINISHED = "CategoryAction.REQUEST_CATEGORY_FINISHED";

  // Edit Category
  static REQUEST_EDIT_CATEGORY = "CategoryAction.REQUEST_EDIT_CATEGORY";

  // Edit Playbooks in Category
  static REQUEST_EDIT_PLAYBOOKS = "CategoryAction.REQUEST_EDIT_PLAYBOOKS";

  /**
   * Get one Category
   * @param categoryUid string
   * @returns Dispatch CategoryEffect.getCategory
   */
  static getCategory(categoryUid: string): ThunkType<CategoryModel> {
    return ActionUtility.createThunk<CategoryModel>(
      CategoryAction.REQUEST_CATEGORY,
      CategoryEffect.getCategory,
      categoryUid,
    );
  }

  /**
   * Edit Category
   * @param category CategoryModel
   * @param data CategoryEditInterface
   * @returns Dispatch CategoryEffect.editCategory
   */
  static editCategory(category: CategoryModel, data: CategoryEditInterface) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<CategoryModel>(
          CategoryAction.REQUEST_EDIT_CATEGORY,
          CategoryEffect.editCategory,
          category,
          data,
        ),
      );

      await dispatch(CategoryAction.getCategory(category.categoryUid));
    };
  }

  /**
   * Sort Playbooks in Category
   * @param data PlaybooksInCategoryEditInterface
   * @returns Dispatch CategoryEffect.sortPlaybooks
   */
  static sortPlaybooks(data: PlaybooksInCategoryEditInterface) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<CategoryModel>(
          CategoryAction.REQUEST_EDIT_PLAYBOOKS,
          CategoryEffect.sortPlaybooks,
          data,
        ),
      );

      await dispatch(CategoryAction.getCategory(data.categoryUid));
    };
  }
}
