import React, { useState } from "react";
import { CLIENT_NAME } from "../../../constants/config/env.config";

function Logo(
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
) {
  const [imgSrc, setImgSrc] = useState<string>(
    `${process.env.PUBLIC_URL}/assets/images/${CLIENT_NAME}/logo.png`,
  );

  const onError = (e) => {
    e.currentTarget.onerror = null;
    setImgSrc(`${process.env.PUBLIC_URL}/assets/images/Dio/logo.png`);
  };

  return (
    <img
      src={imgSrc}
      width={109}
      height={33}
      alt={"logo"}
      onError={onError}
      {...props}
    />
  );
}

export default Logo;
