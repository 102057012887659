import React, { useEffect, useState } from "react";
import { Button, PageHeader, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { grey } from "@ant-design/colors";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ContentAction from "../../../core/redux/stores/content/content.action";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";

interface Props {
  title: string;
  onClick: () => void;
}

const EditPageHeaderComponent = ({ title, onClick }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [retrieveContent, setRetrieveContent] = useState(false);
  const requesting = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_PLAYBOOKS,
      ContentAction.REQUEST_CHAPTERS,
      ContentAction.REQUEST_COLLECTIONS,
      ContentAction.REQUEST_CARDS,
    ]),
  );

  useEffect(() => {
    if (!retrieveContent) {
      return;
    }

    if (!requesting && retrieveContent) {
      setRetrieveContent(false);
    }
  }, [requesting, retrieveContent]);

  return (
    <PageHeader
      backIcon={
        <ArrowLeftOutlined
          style={{ color: grey[1], padding: "0rem 0rem 0.7rem 0rem" }}
        />
      }
      title={renderTitle()}
      onBack={onClick}
      style={{
        backgroundColor: "var(--grey800-color)",
        marginTop: "-64px",
        zIndex: 120,
        position: "fixed",
        width: "100%",
        height: "64px",
      }}
      extra={
        <Button
          loading={retrieveContent && requesting}
          type={"primary"}
          onClick={() => {
            setRetrieveContent(true);
            dispatch(ContentAction.getAllContent());
          }}
        >
          {Capitalize(t("translations:screens.edit-mode.refetch"))}
        </Button>
      }
    />
  );

  function renderTitle() {
    return (
      <Typography.Title
        level={5}
        style={{ color: grey[1], cursor: "pointer" }}
        onClick={onClick}
      >
        {Capitalize(t("common.back_to", { item: t(title).toLowerCase() }))}
      </Typography.Title>
    );
  }
};

export default EditPageHeaderComponent;
