import ContentType from "../../../types/content.type";
import CreateContentType from "../../../types/create-content.type";
import CardTypes, { CardResponseTypes } from "../../../types/card.types";
import { SortContentInterface } from "../../../interfaces/sort-content.interface";
import { BulkPublishCardsInterface } from "../../../interfaces/bulk-publish-cards.interface";
import { CardTypesEnum } from "../../../enums/card-types.enum";
import { ContentApi } from "../../../api/content/content.api";
import PictureQuizCardModel from "../../../models/content/card-types/picture-quiz/picture-quiz-card.model";
import PlaybookModel from "../../../models/content/playbook.model";
import ChapterModel from "../../../models/content/chapter.model";
import CollectionModel from "../../../models/content/collection.model";
import KnowledgeCardModel from "../../../models/content/card-types/knowledge/knowledge-card.model";
import QuizCardModel from "../../../models/content/card-types/quiz/quiz-card.model";
import MultipleChoiceCardModel from "../../../models/content/card-types/multiple-choice/multiple-choice-card.model";
import SliderCardModel from "../../../models/content/card-types/slider/slider-card.model";
import QuestionCardModel from "../../../models/content/card-types/question/question-card.model";
import BaseCardModel from "../../../models/content/base-card.model";
import ContentTypesEnum from "../../../enums/content-types.enum";
import EffectUtility from "../../../utils/effect.utils";
import HttpUtility, { RequestMethodsEnum } from "../../../utils/http.utils";
import ContentQueryOptions from "../../../interfaces/content-query.options";

function ContentModelMapper(contentType: ContentTypesEnum) {
  switch (contentType) {
    case ContentTypesEnum.PLAYBOOK:
      return PlaybookModel;
    case ContentTypesEnum.CHAPTER:
      return ChapterModel;
    case ContentTypesEnum.COLLECTION:
      return CollectionModel;
    case ContentTypesEnum.CARD:
      return BaseCardModel;
  }
}

export function CardModelMapper(cardType: CardTypesEnum) {
  switch (cardType) {
    case CardTypesEnum.KNOWLEDGE_CARD:
      return KnowledgeCardModel;
    case CardTypesEnum.QUIZ:
      return QuizCardModel;
    case CardTypesEnum.MULTIPLE_CHOICE:
      return MultipleChoiceCardModel;
    case CardTypesEnum.SLIDER:
      return SliderCardModel;
    case CardTypesEnum.QUESTION:
      return QuestionCardModel;
    case CardTypesEnum.PICTURE_QUIZ:
      return PictureQuizCardModel;
  }
}

export default class ContentEffect {
  static async getContent(
    contentType: ContentTypesEnum,
    options?: ContentQueryOptions,
  ) {
    const contentApi = new ContentApi(contentType);
    let endpoint = contentApi.endpoints.index();

    if (options) {
      if (options.excludeMedia) {
        endpoint += "?excludeMedia=true";
      }
    }

    if (contentType === ContentTypesEnum.CARD) {
      const response = await HttpUtility.get(endpoint);

      if (!response || !response.data) {
        throw new Error(`${response.status} ${response.message}`);
      }

      return response.data.map((cardResponse: CardResponseTypes) => {
        const model = CardModelMapper(cardResponse.cardType);

        if (!model) {
          throw new Error(`CardType ${cardResponse.cardType} is not supported`);
        }

        // @ts-ignore
        return new model(cardResponse);
      });
    }

    const model = ContentModelMapper(contentType);
    return EffectUtility.getToModel<ContentType>(model, endpoint);
  }

  /**
   * Get Content Detail via uid via GET Request
   * @param contentType ContentTypesEnum
   * @param contentUid string
   * @returns EffectUtility.getToModel<ContentType>(model, endpoint)
   */
  static async getContentDetail(
    contentType: ContentTypesEnum,
    contentUid: string,
  ) {
    const contentApi = new ContentApi(contentType);
    const endpoint = contentApi.endpoints.detail(contentUid);

    if (contentType === ContentTypesEnum.CARD) {
      const response = await HttpUtility.get(endpoint);

      if (!response || !response.data) {
        throw new Error(`${response.status} ${response.message}`);
      }

      const model = CardModelMapper(response.data.cardType);

      if (!model) {
        throw new Error(`CardType ${response.data.cardType} is not supported`);
      }

      return new model(response.data);
    }

    const model = ContentModelMapper(contentType);
    return EffectUtility.getToModel<ContentType>(model, endpoint);
  }

  static async getPlaybookHierarchy(playbookUid: string) {
    const contentApi = new ContentApi(ContentTypesEnum.PLAYBOOK);
    if (contentApi.endpoints.contentType !== ContentTypesEnum.PLAYBOOK) {
      return;
    }

    const endpoint = contentApi.endpoints.details(playbookUid);
    return EffectUtility.getToModel(PlaybookModel, endpoint);
  }

  /**
   * Create Content via POST Request
   * @param contentType ContentTypesEnum
   * @param data CreateContentType
   */
  static async createContent(
    contentType: ContentTypesEnum,
    data: CreateContentType,
  ) {
    const contentApi = new ContentApi(contentType);
    const endpoint = contentApi.endpoints.index();
    return await HttpUtility.post(endpoint, data);
  }

  /**
   * Update Content via POST Request
   * @param contentType ContentTypesEnum
   * @param contentUid string
   * @param data Partial<ContentType>
   */
  static async updateContent(
    contentType: ContentTypesEnum,
    contentUid: string,
    data: Partial<ContentType>,
  ) {
    const contentApi = new ContentApi(contentType);
    const endpoint = contentApi.endpoints.detail(contentUid);
    return await HttpUtility.post(endpoint, data);
  }

  /**
   * Update Content via POST Request
   * @param card
   * @param data Partial<ContentType>
   */
  static async updateCardAndKeepProgress(
    card: CardTypes,
    data: Partial<CardTypes>,
  ) {
    const contentApi = new ContentApi(ContentTypesEnum.CARD);

    if (contentApi.endpoints.contentType !== ContentTypesEnum.CARD) return;

    const endpoint = contentApi.endpoints.updateContentKeepProgress(
      card.cardUid,
    );
    return await HttpUtility.post(endpoint, data);
  }

  /**
   * Duplicate Content via POST Request
   * @param content ContentTypes
   */
  static async duplicateContent(content: ContentType) {
    const contentApi = new ContentApi(content.contentType);
    const contentUid = ContentEffect.getContentUid(content);

    const endpoint = contentApi.endpoints.duplicate(contentUid);
    return await HttpUtility.post(endpoint, {});
  }

  /**
   * Delete Content via DELETE Request
   * @param content ContentTypes
   * @param version
   */
  static async deleteContent(content: ContentType, version?: number) {
    const contentApi = new ContentApi(content.contentType);
    const contentUid = ContentEffect.getContentUid(content);
    const endpoint = contentApi.endpoints.detail(contentUid);

    switch (content.contentType) {
      case ContentTypesEnum.CHAPTER:
      case ContentTypesEnum.PLAYBOOK:
      case ContentTypesEnum.COLLECTION:
        return await HttpUtility.delete(endpoint);
      case ContentTypesEnum.CARD:
        return await HttpUtility._request(
          { url: endpoint, method: RequestMethodsEnum.DELETE },
          { params: { version } },
        );
    }
  }

  /**
   * Publish Content via POST Request
   * @param content ContentTypes
   */
  static async publishContent(content: ContentType) {
    const contentApi = new ContentApi(content.contentType);
    const contentUid = ContentEffect.getContentUid(content);

    let endpoint = contentApi.endpoints.publish(contentUid);

    if (content.contentType === ContentTypesEnum.CARD) {
      endpoint = contentApi.endpoints.publishCard(contentUid, content.version);
    }

    return await HttpUtility.post(endpoint, null);
  }

  /**
   * Publish Content via contentUid via POST Request (NO cards!!)
   * @param contentType ContentTypesEnum
   * @param contentUid string
   */
  static async publishContentViaUid(
    contentType: ContentTypesEnum,
    contentUid: string,
  ) {
    if (contentType === ContentTypesEnum.CARD) {
      return;
    }

    const contentApi = new ContentApi(contentType);
    const endpoint = contentApi.endpoints.publish(contentUid);
    return await HttpUtility.post(endpoint, null);
  }

  /**
   * Bulk Publish Cards via POST Request
   * @param contentType ContentTypesEnum
   * @param cards BulkPublishCardsInterface[]
   */
  static async bulkPublishCards(
    contentType: ContentTypesEnum,
    cards: BulkPublishCardsInterface[],
  ) {
    if (contentType !== ContentTypesEnum.CARD) {
      return;
    }

    const contentApi = new ContentApi(contentType);
    const endpoint = contentApi.endpoints.bulkPublishCards();
    return await HttpUtility.post(endpoint, { cards: cards });
  }

  /**
   * Draft Content via POST Request
   * @param content ContentTypes
   */
  static async draftContent(content: ContentType) {
    const contentApi = new ContentApi(content.contentType);
    const contentUid = ContentEffect.getContentUid(content);

    let endpoint = contentApi.endpoints.draft(contentUid);

    if (content.contentType === ContentTypesEnum.CARD) {
      endpoint += "?version=" + content.version;
    }

    return await HttpUtility.post(endpoint, null);
  }

  /**
   * Sort Content via POST Request
   * @param input { content: ContentType, data: SortContentInterface }[]
   */
  static async sortContent(
    input: { content: ContentType; data: SortContentInterface }[],
  ) {
    const contentApi = new ContentApi(input[0].content.contentType);
    const endpoint = contentApi.endpoints.sort();
    const body = ContentEffect.getContentSortData(input);

    return HttpUtility.post(endpoint, body);
  }

  /**
   * Get Content Uid
   * @param content ContentType
   * @returns {string} ContentUid
   */
  private static getContentUid(content: ContentType): string {
    switch (content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return content.playbookUid;
      case ContentTypesEnum.CHAPTER:
        return content.chapterUid;
      case ContentTypesEnum.COLLECTION:
        return content.collectionUid;
      case ContentTypesEnum.CARD:
        return content.cardUid;
    }
  }

  /**
   * Get Content Sort Data
   * @param input { content: ContentType, data: SortContentInterface }[]
   */
  private static getContentSortData(
    input: { content: ContentType; data: SortContentInterface }[],
  ) {
    switch (input[0].content.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return {
          playbooks: input.map(({ content }) => {
            if (content.contentType !== ContentTypesEnum.PLAYBOOK) return;
            return {
              playbookUid: content.playbookUid,
              sort: content.sort,
            };
          }),
        };
      case ContentTypesEnum.CHAPTER:
        return {
          chapters: input.map(({ content }) => {
            if (content.contentType !== ContentTypesEnum.CHAPTER) return;
            return {
              chapterUid: content.chapterUid,
              sort: content.sort,
            };
          }),
        };
      case ContentTypesEnum.COLLECTION:
        return {
          collections: input.map(({ content }) => {
            if (content.contentType !== ContentTypesEnum.COLLECTION) return;
            return {
              collectionUid: content.collectionUid,
              sort: content.sort,
            };
          }),
        };
      case ContentTypesEnum.CARD:
        return {
          cards: input.map(({ content }) => {
            if (content.contentType !== ContentTypesEnum.CARD) return;
            return {
              cardUid: content.cardUid,
              sort: content.sort,
              version: content.version,
            };
          }),
        };
    }
  }
}
