import PageHeaderBreadcrumbsComponent from "../../components/page-header/breadcrumbs/page-header-breadcrumbs.component";
import React, { useEffect, useState } from "react";
import CardDetailOverviewScreen from "../../screens/user-progress/card-detail-overview.screen";
import CardTypes from "../../../core/types/card.types";
import EmptyStateScreen from "../../screens/empty-state.screen";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCardsFromPlaybookDetail } from "../../../core/hooks/content/content.hook";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";

const CardDetailContainer = () => {
  const { t } = useTranslation();
  const params = useParams<{
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
    cardUid: string;
  }>();
  const { data: cards } = useCardsFromPlaybookDetail(
    params.playbookUid,
    params.cardUid,
  );
  const [card, setCard] = useState<CardTypes>();

  useEffect(() => {
    if (!cards) {
      return;
    }

    if (cards.length === 1) {
      return setCard(cards[0]);
    }

    const publishedCard: CardTypes[] = cards.filter(
      (c) => c.contentState === ContentStatesEnum.PUBLISHED,
    );

    if (publishedCard.length === 0) {
      return setCard(cards[0]);
    }

    return setCard(publishedCard.sort((a, b) => b.version - a.version)[0]);
  }, [cards]);

  const menuItems = () => {
    const menuItems: MenuItemInterface[] = [
      {
        title: t("containers.activity.sidebar"),
        route: RoutesEnum.CARD_DETAIL,
      },
    ];

    return menuItems;
  };

  return (
    <PageLayoutComponent
      menuItems={menuItems()}
      breadCrumbs={<PageHeaderBreadcrumbsComponent />}
      showSider={false}
    >
      {card ? (
        <CardDetailOverviewScreen card={card} />
      ) : (
        <EmptyStateScreen route={"activity"} />
      )}
    </PageLayoutComponent>
  );
};

export default CardDetailContainer;
