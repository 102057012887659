import React, { useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { getExportedPlaybook } from "../../../core/hooks/import-export/export.api";
import JSZip from "jszip";
import FileSaver from "file-saver";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import PlaybooksSelectComponent from "../../components/forms/select/playbooks-select.component";

const ExportContainer = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string[]>([]);

  const download = async () => {
    if (value.length === 0) return;

    const calls = value.map(getExportedPlaybook);
    const responses = await Promise.all(calls);
    zip(responses);
  };

  const zip = (data: any[]) => {
    const zip = new JSZip();
    data.forEach((item, index) =>
      zip.file(`module-${index}.json`, JSON.stringify(item)),
    );
    zip
      .generateAsync({ type: "blob" })
      .then((content) => FileSaver.saveAs(content, "modules.zip"));
  };

  return (
    <PageLayoutComponent menuItems={[]} showSider={false}>
      <div style={{ margin: "0 auto", width: "60rem" }}>
        <Typography.Title level={3}>
          {t("containers.import-export.download.title")}
        </Typography.Title>

        <Row>
          <Col flex={"auto"}>
            <PlaybooksSelectComponent
              playbookUids={value}
              onChange={(value) => setValue(value.map((p) => p.playbookUid))}
              filter={(playbook) => !playbook.apiKeyClientUid}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 16 }}>
          <Col flex={"auto"} />
          <Col>
            <Button
              type={"primary"}
              disabled={value.length === 0}
              onClick={download}
            >
              {value.length === 0
                ? Capitalize(t("translations:common.download"))
                : `${Capitalize(t("translations:common.download"))} (${
                    value.length
                  })`}
            </Button>
          </Col>
        </Row>
      </div>
    </PageLayoutComponent>
  );
};

export default ExportContainer;
