import NotificationTypes from "../types/notification.types";
import { API_URL } from "../../constants/config/env.config";

const NotificationEndpoints = {
  /**
   * GET more Notifications endpoint (e.g. next or last week)
   * @param startDate Date
   * @returns API_URL + /notifications/scheduler?from=Sun Jan 09 2022 14:41:43 GMT+0100
   */
  overview(startDate: Date) {
    return `${API_URL}/notifications/scheduler?from=${startDate}`;
  },

  /**
   * GET total amount of Scheduled Notifications
   * @returns API_URL + /notifications/scheduler/summary
   */
  count: `${API_URL}/notifications/scheduler/summary`,

  /**
   * GET one Notification endpoint.
   * @param key string
   * @returns API_URL + /notifications/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  detail(key: string) {
    return `${API_URL}/notifications/${key}`;
  },

  /**
   * POST Notification endpoint.
   * @returns API_URL + /notifications/scheduler
   */
  create: `${API_URL}/notifications/scheduler`,

  /**
   * DELETE Notification endpoint.
   * @param notification NotificationTypes
   * @returns API_URL + /notifications/scheduler/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  delete(notification: NotificationTypes) {
    return `${API_URL}/notifications/scheduler/${notification.notificationUid}`;
  },
};

export default NotificationEndpoints;
