import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";

type Props = {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSearch: (query: string) => void;
  children: JSX.Element;
};

const SearchMediaModal = ({
  title,
  visible,
  children,
  onSearch,
  onCancel,
}: Props) => {
  const [t] = useTranslation();
  const [form] = useForm();
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    onSearch(query);
  }, [query]);

  return (
    <Modal
      open={visible}
      title={Capitalize(t("form.placeholders.find_x", { item: title }))}
      destroyOnClose
      width={"80rem"}
      okText={Capitalize(t("common.upload_x", { item: "" }))}
      onOk={onCancel}
      cancelButtonProps={{ type: "text" }}
      cancelText={Capitalize(t("common.cancel"))}
      onCancel={onCancel}
    >
      <Form form={form}>
        <Input.Search
          type={"text"}
          autoFocus
          value={query}
          placeholder={t("form.placeholders.type_x", {
            item: t("common.short") + " " + t("form.card.description.label"),
          })}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Form.Item name={"url"}>{children}</Form.Item>
      </Form>
    </Modal>
  );
};

export default SearchMediaModal;
