import React from "react";
import { Select } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import UserModel from "../../../../core/models/user/user.model";

type Props = {
  userNames: string[];
  users: UserModel[];
  onChange?: (value: string[]) => void;
};

function UserSelectComponent({ userNames, users, onChange }: Props) {
  const [t] = useTranslation();

  return (
    <Select
      value={userNames}
      placeholder={Capitalize(
        t("form.placeholders.select_x", { x: t("containers.users.key") }),
      )}
      onChange={onChange}
      listHeight={250}
      mode={"multiple"}
      style={{ flex: "1" }}
      maxTagCount={"responsive" as const}
      allowClear
      showArrow
      showSearch
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {users.map((user: UserModel, index: number) => (
        <Select.Option key={index} value={user.username}>
          {user.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default UserSelectComponent;
