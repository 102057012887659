import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TopUsersModel } from "../../../core/models/user-progress/popular-users.model";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { DateRange } from "../../../core/enums/date-range.enum";
import { Button, Card, List, Typography } from "antd";
import { grey } from "@ant-design/colors";
import { Link, useHistory } from "react-router-dom";
import { usePopularUsers } from "../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { selectUsers } from "../../../core/redux/selectors/user/user.selector";
import { Capitalize } from "../../../core/utils/helper.utils";
import SectionTitleComponent from "../section-title/section-title.component";
import DateRangeComponent from "../actions/date-range.component";
import useDateRangeHelper from "../../../core/hooks/useDateRangeHelper";
import UserModel from "../../../core/models/user/user.model";
import graphStyles from "./app-metrics/app-metrics.module.css";
import styles from "./activity.module.css";
import Moment from "moment";

interface StateProps {
  users: UserModel[];
}

type Props = StateProps;

const TopUsersComponent = ({ users }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [range, setRange] = useState(DateRange.ALL_TIME);
  const { props } = useDateRangeHelper(range);
  const { data, isFetching, refetch } = usePopularUsers(props);

  useEffect(() => {
    refetch();
  }, [props]);

  return (
    <div style={{ width: "50%", marginBottom: "2rem" }}>
      <div className={styles.header}>
        <SectionTitleComponent
          title={t("common.top") + " " + t("containers.users.key_plural")}
        />
        <DateRangeComponent
          range={range}
          options={[
            DateRange.ALL_TIME,
            DateRange.LAST_WEEK,
            DateRange.LAST_MONTH,
          ]}
          onChange={setRange}
        />
      </div>

      <Card
        title={
          <Typography.Text style={{ fontSize: 14, fontWeight: 400 }}>
            {Capitalize(t("containers.users.key"))}
          </Typography.Text>
        }
        extra={Capitalize(t("graphs.completed-card.title"))}
      >
        {data && (
          <List<TopUsersModel>
            itemLayout={"horizontal"}
            dataSource={data.slice(0, 5)}
            loading={isFetching && !data}
            renderItem={(user, index) => {
              const userData = users.find((u) => u.sub == user.userUid);
              const title = () => (
                <Link
                  to={{ pathname: `/users/${userData?.username as string}` }}
                >
                  <Typography.Text style={{ fontSize: 20 }}>
                    {index + 1}. {userData?.name}
                  </Typography.Text>
                </Link>
              );

              const description = () => (
                <Typography.Text
                  style={{ paddingLeft: "2.3rem", color: grey[2] }}
                >
                  {Capitalize(t("common.active-since")) +
                    " " +
                    Moment(user.firstActiveOn).format("DD-MM-YYYY")}
                </Typography.Text>
              );

              return (
                <List.Item
                  extra={
                    <Typography.Title>{user.cardsCompleted}</Typography.Title>
                  }
                >
                  <List.Item.Meta description={description()} title={title()} />
                </List.Item>
              );
            }}
          />
        )}
      </Card>

      <Button
        type={"text"}
        className={graphStyles.button}
        onClick={() => history.push(RoutesEnum.USER_ACTIVITY)}
      >
        {Capitalize(t("common.show")) +
          " " +
          t("common.all") +
          " " +
          t("containers.users.key_plural")}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
});

export default connect(mapStateToProps)(TopUsersComponent);
