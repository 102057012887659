/**
 * Generated by @openapi-codegen
 *
 * @version 1.80.0
 */
import * as reactQuery from "@tanstack/react-query";
import { usePrimioContext, PrimioContext } from "./primioContext";
import type * as Fetcher from "./primioFetcher";
import { primioFetch } from "./primioFetcher";
import type * as Schemas from "./primioSchemas";
import type { ClientErrorStatus, ServerErrorStatus } from "./primioUtils";

export type HealthCheckError = Fetcher.ErrorWrapper<undefined>;

export type HealthCheckVariables = PrimioContext["fetcherOptions"];

/**
 * Returns OK if the server is healthy.
 */
export const fetchHealthCheck = (
  variables: HealthCheckVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, HealthCheckError, undefined, {}, {}, {}>({
    url: "/health",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns OK if the server is healthy.
 */
export const useHealthCheck = <TData = string>(
  variables: HealthCheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthCheckError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<string, HealthCheckError, TData>({
    queryKey: queryKeyFn({
      path: "/health",
      operationId: "healthCheck",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchHealthCheck({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SaveUserProgressError = Fetcher.ErrorWrapper<undefined>;

export type SaveUserProgressVariables = {
  body: Schemas.CardCompletedDto;
} & PrimioContext["fetcherOptions"];

/**
 * Saves user progress for a card. This can include answers and other relevant data. <br />Invoking this endpoint will create an event that is handled by different projections to retrieve user progress. <br />This will also check if a learning path is started or completed and create events accordingly. <br />
 */
export const fetchSaveUserProgress = (
  variables: SaveUserProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    SaveUserProgressError,
    Schemas.CardCompletedDto,
    {},
    {},
    {}
  >({ url: "/user-progress", method: "post", ...variables, signal });

/**
 * Saves user progress for a card. This can include answers and other relevant data. <br />Invoking this endpoint will create an event that is handled by different projections to retrieve user progress. <br />This will also check if a learning path is started or completed and create events accordingly. <br />
 */
export const useSaveUserProgress = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SaveUserProgressError,
      SaveUserProgressVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    SaveUserProgressError,
    SaveUserProgressVariables
  >({
    mutationFn: (variables: SaveUserProgressVariables) =>
      fetchSaveUserProgress({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserProgressQueryParams = {
  summarise?: boolean;
};

export type GetUserProgressError = Fetcher.ErrorWrapper<undefined>;

export type GetUserProgressVariables = {
  queryParams?: GetUserProgressQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * by default returns summarised version  true
 */
export const fetchGetUserProgress = (
  variables: GetUserProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetUserProgressError,
    undefined,
    {},
    GetUserProgressQueryParams,
    {}
  >({ url: "/user-progress", method: "get", ...variables, signal });

/**
 * by default returns summarised version  true
 */
export const useGetUserProgress = <TData = void>(
  variables: GetUserProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetUserProgressError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetUserProgressError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress",
      operationId: "getUserProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserProgress({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetProgressForUserPathParams = {
  username: string;
};

export type GetProgressForUserError = Fetcher.ErrorWrapper<undefined>;

export type GetProgressForUserVariables = {
  pathParams: GetProgressForUserPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * given a username get the user progress with meta data
 */
export const fetchGetProgressForUser = (
  variables: GetProgressForUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetProgressForUserError,
    undefined,
    {},
    {},
    GetProgressForUserPathParams
  >({
    url: "/user-progress/users/{username}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * given a username get the user progress with meta data
 */
export const useGetProgressForUser = <TData = void>(
  variables: GetProgressForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetProgressForUserError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetProgressForUserError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/users/{username}",
      operationId: "getProgressForUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetProgressForUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUsersStartedLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersStartedLearningPathsVariables =
  PrimioContext["fetcherOptions"];

/**
 * Gets the learning path progress
 */
export const fetchGetUsersStartedLearningPaths = (
  variables: GetUsersStartedLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Record<string, any>,
    GetUsersStartedLearningPathsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/user-progress/learning-paths",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Gets the learning path progress
 */
export const useGetUsersStartedLearningPaths = <TData = Record<string, any>>(
  variables: GetUsersStartedLearningPathsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetUsersStartedLearningPathsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetUsersStartedLearningPathsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/learning-paths",
      operationId: "getUsersStartedLearningPaths",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsersStartedLearningPaths(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetCurrentUserLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type GetCurrentUserLearningPathsVariables =
  PrimioContext["fetcherOptions"];

export const fetchGetCurrentUserLearningPaths = (
  variables: GetCurrentUserLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Record<string, any>,
    GetCurrentUserLearningPathsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/user-progress/learning-paths/user",
    method: "get",
    ...variables,
    signal,
  });

export const useGetCurrentUserLearningPaths = <TData = Record<string, any>>(
  variables: GetCurrentUserLearningPathsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetCurrentUserLearningPathsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetCurrentUserLearningPathsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/learning-paths/user",
      operationId: "getCurrentUserLearningPaths",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCurrentUserLearningPaths(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetLearningPathProgressPathParams = {
  learningPathUid: string;
};

export type GetLearningPathProgressError = Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathProgressVariables = {
  pathParams: GetLearningPathProgressPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPathProgress = (
  variables: GetLearningPathProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Record<string, any>,
    GetLearningPathProgressError,
    undefined,
    {},
    {},
    GetLearningPathProgressPathParams
  >({
    url: "/user-progress/learning-paths/{learningPathUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLearningPathProgress = <TData = Record<string, any>>(
  variables: GetLearningPathProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetLearningPathProgressError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetLearningPathProgressError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/learning-paths/{learningPathUid}",
      operationId: "getLearningPathProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPathProgress({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUserLearningPathsPathParams = {
  userUid: string;
};

export type GetUserLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type GetUserLearningPathsVariables = {
  pathParams: GetUserLearningPathsPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserLearningPaths = (
  variables: GetUserLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Record<string, any>,
    GetUserLearningPathsError,
    undefined,
    {},
    {},
    GetUserLearningPathsPathParams
  >({
    url: "/user-progress/learning-paths/users/{userUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserLearningPaths = <TData = Record<string, any>>(
  variables: GetUserLearningPathsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      GetUserLearningPathsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    GetUserLearningPathsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/learning-paths/users/{userUid}",
      operationId: "getUserLearningPaths",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserLearningPaths({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetContentDirectoryQueryParams = {
  /**
   * true, returns only published content, false returns all content ever published
   */
  published: boolean;
};

export type GetContentDirectoryError = Fetcher.ErrorWrapper<undefined>;

export type GetContentDirectoryVariables = {
  queryParams: GetContentDirectoryQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * returns the content directory structure, for all things ever published, includes date of first published
 */
export const fetchGetContentDirectory = (
  variables: GetContentDirectoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetContentDirectoryError,
    undefined,
    {},
    GetContentDirectoryQueryParams,
    {}
  >({
    url: "/user-progress/content-directory",
    method: "get",
    ...variables,
    signal,
  });

/**
 * returns the content directory structure, for all things ever published, includes date of first published
 */
export const useGetContentDirectory = <TData = void>(
  variables: GetContentDirectoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetContentDirectoryError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetContentDirectoryError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/content-directory",
      operationId: "getContentDirectory",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContentDirectory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPopularUsersQueryParams = {
  /**
   * unix timestamp defaults to 0
   */
  startDate?: number;
  /**
   * unix timestamp defaults to today
   */
  endDate?: number;
};

export type GetPopularUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetPopularUsersVariables = {
  queryParams?: GetPopularUsersQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * For a specific time frame each user has done how many cards
 */
export const fetchGetPopularUsers = (
  variables: GetPopularUsersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetPopularUsersError,
    undefined,
    {},
    GetPopularUsersQueryParams,
    {}
  >({
    url: "/user-progress/popular-users",
    method: "get",
    ...variables,
    signal,
  });

/**
 * For a specific time frame each user has done how many cards
 */
export const useGetPopularUsers = <TData = void>(
  variables: GetPopularUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetPopularUsersError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetPopularUsersError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/popular-users",
      operationId: "getPopularUsers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPopularUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUserLatestActivityError = Fetcher.ErrorWrapper<undefined>;

export type GetUserLatestActivityVariables = PrimioContext["fetcherOptions"];

/**
 * all users date descending last card completed
 */
export const fetchGetUserLatestActivity = (
  variables: GetUserLatestActivityVariables,
  signal?: AbortSignal,
) =>
  primioFetch<void, GetUserLatestActivityError, undefined, {}, {}, {}>({
    url: "/user-progress/latest-activity",
    method: "get",
    ...variables,
    signal,
  });

/**
 * all users date descending last card completed
 */
export const useGetUserLatestActivity = <TData = void>(
  variables: GetUserLatestActivityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetUserLatestActivityError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetUserLatestActivityError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/latest-activity",
      operationId: "getUserLatestActivity",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserLatestActivity({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetCollectionAnswersPathParams = {
  collectionUid: string;
};

export type GetCollectionAnswersError = Fetcher.ErrorWrapper<undefined>;

export type GetCollectionAnswersVariables = {
  pathParams: GetCollectionAnswersPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * for a collection gives a view of all users that answered and groups where the answer is same
 */
export const fetchGetCollectionAnswers = (
  variables: GetCollectionAnswersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetCollectionAnswersError,
    undefined,
    {},
    {},
    GetCollectionAnswersPathParams
  >({
    url: "/user-progress/community-answers/{collectionUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * for a collection gives a view of all users that answered and groups where the answer is same
 */
export const useGetCollectionAnswers = <TData = void>(
  variables: GetCollectionAnswersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetCollectionAnswersError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetCollectionAnswersError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/community-answers/{collectionUid}",
      operationId: "getCollectionAnswers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCollectionAnswers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetCardsCompletedPathParams = {
  collectionUid: string;
};

export type GetCardsCompletedError = Fetcher.ErrorWrapper<undefined>;

export type GetCardsCompletedVariables = {
  pathParams: GetCardsCompletedPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * returns all the data captured, for a user on a collection
 */
export const fetchGetCardsCompleted = (
  variables: GetCardsCompletedVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetCardsCompletedError,
    undefined,
    {},
    {},
    GetCardsCompletedPathParams
  >({
    url: "/user-progress/cards-completed/{collectionUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * returns all the data captured, for a user on a collection
 */
export const useGetCardsCompleted = <TData = void>(
  variables: GetCardsCompletedVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetCardsCompletedError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetCardsCompletedError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/cards-completed/{collectionUid}",
      operationId: "getCardsCompleted",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCardsCompleted({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetActiveUserParticipationQueryParams = {
  /**
   * unix timestamp defaults to now - one month
   */
  startDate?: number;
  endDate?: number;
  /**
   * unix timestamp defaults to today
   *
   * @example HOUR
   * @example DAY
   * @example WEEK
   */
  filter?: void;
};

export type GetActiveUserParticipationError = Fetcher.ErrorWrapper<undefined>;

export type GetActiveUserParticipationVariables = {
  queryParams?: GetActiveUserParticipationQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Given a date range, and Period type it will calculate the amount of users that was active for that period
 */
export const fetchGetActiveUserParticipation = (
  variables: GetActiveUserParticipationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetActiveUserParticipationError,
    undefined,
    {},
    GetActiveUserParticipationQueryParams,
    {}
  >({
    url: "/user-progress/activity/users",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Given a date range, and Period type it will calculate the amount of users that was active for that period
 */
export const useGetActiveUserParticipation = <TData = void>(
  variables: GetActiveUserParticipationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetActiveUserParticipationError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetActiveUserParticipationError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/activity/users",
      operationId: "getActiveUserParticipation",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetActiveUserParticipation(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetCardsCompletedParticipationQueryParams = {
  /**
   * unix timestamp defaults to today -  1 month
   */
  startDate?: number;
  /**
   * unix timestamp defaults to today
   */
  endDate?: number;
  /**
   * @example HOUR
   * @example DAY
   * @example WEEK
   */
  filter?: void;
};

export type GetCardsCompletedParticipationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetCardsCompletedParticipationVariables = {
  queryParams?: GetCardsCompletedParticipationQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Given a date range, and Period type it will calculate the amount of cards completed for that period
 */
export const fetchGetCardsCompletedParticipation = (
  variables: GetCardsCompletedParticipationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetCardsCompletedParticipationError,
    undefined,
    {},
    GetCardsCompletedParticipationQueryParams,
    {}
  >({
    url: "/user-progress/activity/cards",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Given a date range, and Period type it will calculate the amount of cards completed for that period
 */
export const useGetCardsCompletedParticipation = <TData = void>(
  variables: GetCardsCompletedParticipationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      void,
      GetCardsCompletedParticipationError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetCardsCompletedParticipationError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/activity/cards",
      operationId: "getCardsCompletedParticipation",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCardsCompletedParticipation(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetUserCardsCompletedParticipationQueryParams = {
  startDate: number;
  endDate: number;
  filter: string;
};

export type GetUserCardsCompletedParticipationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetUserCardsCompletedParticipationVariables = {
  queryParams: GetUserCardsCompletedParticipationQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserCardsCompletedParticipation = (
  variables: GetUserCardsCompletedParticipationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    GetUserCardsCompletedParticipationError,
    undefined,
    {},
    GetUserCardsCompletedParticipationQueryParams,
    {}
  >({
    url: "/user-progress/activity/cards/users",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserCardsCompletedParticipation = <TData = undefined>(
  variables: GetUserCardsCompletedParticipationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetUserCardsCompletedParticipationError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    undefined,
    GetUserCardsCompletedParticipationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/activity/cards/users",
      operationId: "getUserCardsCompletedParticipation",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserCardsCompletedParticipation(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetPlaybookProgressPathParams = {
  playbookUid: string;
};

export type GetPlaybookProgressQueryParams = {
  /**
   * unix timestamp defaults to today
   */
  date?: number;
};

export type GetPlaybookProgressError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybookProgressVariables = {
  pathParams: GetPlaybookProgressPathParams;
  queryParams?: GetPlaybookProgressQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Playbook, chapter, collection, user, COMPLETED or STARTED
 */
export const fetchGetPlaybookProgress = (
  variables: GetPlaybookProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetPlaybookProgressError,
    undefined,
    {},
    GetPlaybookProgressQueryParams,
    GetPlaybookProgressPathParams
  >({
    url: "/user-progress/playbook/{playbookUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Playbook, chapter, collection, user, COMPLETED or STARTED
 */
export const useGetPlaybookProgress = <TData = void>(
  variables: GetPlaybookProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetPlaybookProgressError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetPlaybookProgressError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/playbook/{playbookUid}",
      operationId: "getPlaybookProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybookProgress({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetContentInteractionsPathParams = {
  contentUid: string;
};

export type GetContentInteractionsQueryParams = {
  /**
   * content type
   */
  ["content-type"]: string;
};

export type GetContentInteractionsError = Fetcher.ErrorWrapper<undefined>;

export type GetContentInteractionsVariables = {
  pathParams: GetContentInteractionsPathParams;
  queryParams: GetContentInteractionsQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * by content type gets when a user first completed and last completed a card
 */
export const fetchGetContentInteractions = (
  variables: GetContentInteractionsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetContentInteractionsError,
    undefined,
    {},
    GetContentInteractionsQueryParams,
    GetContentInteractionsPathParams
  >({
    url: "/user-progress/interactions/{contentUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * by content type gets when a user first completed and last completed a card
 */
export const useGetContentInteractions = <TData = void>(
  variables: GetContentInteractionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetContentInteractionsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetContentInteractionsError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/interactions/{contentUid}",
      operationId: "getContentInteractions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContentInteractions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetTrackedPlaybookProgressError = Fetcher.ErrorWrapper<undefined>;

export type GetTrackedPlaybookProgressResponse = Record<string, any>[];

export type GetTrackedPlaybookProgressVariables =
  PrimioContext["fetcherOptions"];

/**
 * gets array of playbook progress by playbook uids from tracked playbooks
 */
export const fetchGetTrackedPlaybookProgress = (
  variables: GetTrackedPlaybookProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetTrackedPlaybookProgressResponse,
    GetTrackedPlaybookProgressError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/user-progress/track-playbooks",
    method: "get",
    ...variables,
    signal,
  });

/**
 * gets array of playbook progress by playbook uids from tracked playbooks
 */
export const useGetTrackedPlaybookProgress = <
  TData = GetTrackedPlaybookProgressResponse,
>(
  variables: GetTrackedPlaybookProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTrackedPlaybookProgressResponse,
      GetTrackedPlaybookProgressError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetTrackedPlaybookProgressResponse,
    GetTrackedPlaybookProgressError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/track-playbooks",
      operationId: "getTrackedPlaybookProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTrackedPlaybookProgress(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetProcessedCollectionTracerPathParams = {
  collectionUid: string;
};

export type GetProcessedCollectionTracerError = Fetcher.ErrorWrapper<undefined>;

export type GetProcessedCollectionTracerVariables = {
  pathParams: GetProcessedCollectionTracerPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Traces the collection by uid and gives results for collection details, completion data days sun = 0, userCompleted amount of users completed a card, times-spent total all users on card, card completed every saved card completed event for card, answers index and amount of times the choice has been selected
 */
export const fetchGetProcessedCollectionTracer = (
  variables: GetProcessedCollectionTracerVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetProcessedCollectionTracerError,
    undefined,
    {},
    {},
    GetProcessedCollectionTracerPathParams
  >({
    url: "/user-progress/collections/{collectionUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Traces the collection by uid and gives results for collection details, completion data days sun = 0, userCompleted amount of users completed a card, times-spent total all users on card, card completed every saved card completed event for card, answers index and amount of times the choice has been selected
 */
export const useGetProcessedCollectionTracer = <TData = void>(
  variables: GetProcessedCollectionTracerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetProcessedCollectionTracerError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetProcessedCollectionTracerError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/collections/{collectionUid}",
      operationId: "getProcessedCollectionTracer",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetProcessedCollectionTracer(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetCollectionTracerForUserPathParams = {
  collectionUid: string;
  userUid: string;
};

export type GetCollectionTracerForUserError = Fetcher.ErrorWrapper<undefined>;

export type GetCollectionTracerForUserVariables = {
  pathParams: GetCollectionTracerForUserPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * for every card completed in the collection, the card completed data for a user, [collection uid] -> card uid -> data -> completed at -> card completed
 */
export const fetchGetCollectionTracerForUser = (
  variables: GetCollectionTracerForUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetCollectionTracerForUserError,
    undefined,
    {},
    {},
    GetCollectionTracerForUserPathParams
  >({
    url: "/user-progress/collections/{collectionUid}/users/{userUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * for every card completed in the collection, the card completed data for a user, [collection uid] -> card uid -> data -> completed at -> card completed
 */
export const useGetCollectionTracerForUser = <TData = void>(
  variables: GetCollectionTracerForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetCollectionTracerForUserError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetCollectionTracerForUserError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/collections/{collectionUid}/users/{userUid}",
      operationId: "getCollectionTracerForUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCollectionTracerForUser(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetCardCompletedByUsersPathParams = {
  cardUid: string;
};

export type GetCardCompletedByUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetCardCompletedByUsersVariables = {
  pathParams: GetCardCompletedByUsersPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * for a given card the latest answers each user has provided
 */
export const fetchGetCardCompletedByUsers = (
  variables: GetCardCompletedByUsersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetCardCompletedByUsersError,
    undefined,
    {},
    {},
    GetCardCompletedByUsersPathParams
  >({
    url: "/user-progress/cards/{cardUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * for a given card the latest answers each user has provided
 */
export const useGetCardCompletedByUsers = <TData = void>(
  variables: GetCardCompletedByUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetCardCompletedByUsersError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetCardCompletedByUsersError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/cards/{cardUid}",
      operationId: "getCardCompletedByUsers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCardCompletedByUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPlaybookCompletedPathParams = {
  playbookUid: string;
};

export type GetPlaybookCompletedError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybookCompletedVariables = {
  pathParams: GetPlaybookCompletedPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * each user has a status determine at this time if playbook is completed or started
 */
export const fetchGetPlaybookCompleted = (
  variables: GetPlaybookCompletedVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    GetPlaybookCompletedError,
    undefined,
    {},
    {},
    GetPlaybookCompletedPathParams
  >({
    url: "/user-progress/completed/playbook/{playbookUid}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * each user has a status determine at this time if playbook is completed or started
 */
export const useGetPlaybookCompleted = <TData = void>(
  variables: GetPlaybookCompletedVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<void, GetPlaybookCompletedError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<void, GetPlaybookCompletedError, TData>({
    queryKey: queryKeyFn({
      path: "/user-progress/completed/playbook/{playbookUid}",
      operationId: "getPlaybookCompleted",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybookCompleted({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetCompletedLearningPathsForUserPathParams = {
  userUid: string;
};

export type GetCompletedLearningPathsForUserError =
  Fetcher.ErrorWrapper<undefined>;

export type GetCompletedLearningPathsForUserResponse = Schemas.LearningPath[];

export type GetCompletedLearningPathsForUserVariables = {
  pathParams: GetCompletedLearningPathsForUserPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetCompletedLearningPathsForUser = (
  variables: GetCompletedLearningPathsForUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetCompletedLearningPathsForUserResponse,
    GetCompletedLearningPathsForUserError,
    undefined,
    {},
    {},
    GetCompletedLearningPathsForUserPathParams
  >({
    url: "/user-progress/users/{userUid}/learning-paths/completed",
    method: "get",
    ...variables,
    signal,
  });

export const useGetCompletedLearningPathsForUser = <
  TData = GetCompletedLearningPathsForUserResponse,
>(
  variables: GetCompletedLearningPathsForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCompletedLearningPathsForUserResponse,
      GetCompletedLearningPathsForUserError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetCompletedLearningPathsForUserResponse,
    GetCompletedLearningPathsForUserError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/users/{userUid}/learning-paths/completed",
      operationId: "getCompletedLearningPathsForUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCompletedLearningPathsForUser(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetUsersCompletedLearningPathPathParams = {
  learningPathUid: string;
};

export type GetUsersCompletedLearningPathError =
  Fetcher.ErrorWrapper<undefined>;

export type GetUsersCompletedLearningPathResponse = Schemas.CognitoUser[];

export type GetUsersCompletedLearningPathVariables = {
  pathParams: GetUsersCompletedLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUsersCompletedLearningPath = (
  variables: GetUsersCompletedLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUsersCompletedLearningPathResponse,
    GetUsersCompletedLearningPathError,
    undefined,
    {},
    {},
    GetUsersCompletedLearningPathPathParams
  >({
    url: "/user-progress/learning-paths/{learningPathUid}/users/completed",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUsersCompletedLearningPath = <
  TData = GetUsersCompletedLearningPathResponse,
>(
  variables: GetUsersCompletedLearningPathVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUsersCompletedLearningPathResponse,
      GetUsersCompletedLearningPathError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUsersCompletedLearningPathResponse,
    GetUsersCompletedLearningPathError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/learning-paths/{learningPathUid}/users/completed",
      operationId: "getUsersCompletedLearningPath",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsersCompletedLearningPath(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetUserCardAnswerPathParams = {
  cardUid: string;
  version: number;
};

export type GetUserCardAnswerError = Fetcher.ErrorWrapper<undefined>;

export type GetUserCardAnswerResponse = Schemas.UserAnswerModel[];

export type GetUserCardAnswerVariables = {
  pathParams: GetUserCardAnswerPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserCardAnswer = (
  variables: GetUserCardAnswerVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUserCardAnswerResponse,
    GetUserCardAnswerError,
    undefined,
    {},
    {},
    GetUserCardAnswerPathParams
  >({
    url: "/user-progress/cards-completed/cards/{cardUid}/{version}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserCardAnswer = <TData = GetUserCardAnswerResponse>(
  variables: GetUserCardAnswerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUserCardAnswerResponse,
      GetUserCardAnswerError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUserCardAnswerResponse,
    GetUserCardAnswerError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/cards-completed/cards/{cardUid}/{version}",
      operationId: "getUserCardAnswer",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserCardAnswer({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUsersCardAnswersPathParams = {
  cardUid: string;
  version: number;
};

export type GetUsersCardAnswersError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersCardAnswersResponse = Schemas.AnsweredQuestionResponse[];

export type GetUsersCardAnswersVariables = {
  pathParams: GetUsersCardAnswersPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUsersCardAnswers = (
  variables: GetUsersCardAnswersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUsersCardAnswersResponse,
    GetUsersCardAnswersError,
    undefined,
    {},
    {},
    GetUsersCardAnswersPathParams
  >({
    url: "/user-progress/cards-completed/users/cards/{cardUid}/{version}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUsersCardAnswers = <TData = GetUsersCardAnswersResponse>(
  variables: GetUsersCardAnswersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUsersCardAnswersResponse,
      GetUsersCardAnswersError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUsersCardAnswersResponse,
    GetUsersCardAnswersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-progress/cards-completed/users/cards/{cardUid}/{version}",
      operationId: "getUsersCardAnswers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsersCardAnswers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type TransferCardProgressQueryParams = {
  dryRun: boolean;
};

export type TransferCardProgressError = Fetcher.ErrorWrapper<undefined>;

export type TransferCardProgressRequestBody = Schemas.TransferCardProgressDto[];

export type TransferCardProgressVariables = {
  body?: TransferCardProgressRequestBody;
  queryParams: TransferCardProgressQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchTransferCardProgress = (
  variables: TransferCardProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    TransferCardProgressError,
    TransferCardProgressRequestBody,
    {},
    TransferCardProgressQueryParams,
    {}
  >({ url: "/user-progress/transfer", method: "post", ...variables, signal });

export const useTransferCardProgress = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TransferCardProgressError,
      TransferCardProgressVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    TransferCardProgressError,
    TransferCardProgressVariables
  >({
    mutationFn: (variables: TransferCardProgressVariables) =>
      fetchTransferCardProgress({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTrackedPlaybooksError = Fetcher.ErrorWrapper<undefined>;

export type GetTrackedPlaybooksVariables = PrimioContext["fetcherOptions"];

export const fetchGetTrackedPlaybooks = (
  variables: GetTrackedPlaybooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.TrackPlaybooks,
    GetTrackedPlaybooksError,
    undefined,
    {},
    {},
    {}
  >({ url: "/track-playbooks", method: "get", ...variables, signal });

export const useGetTrackedPlaybooks = <TData = Schemas.TrackPlaybooks>(
  variables: GetTrackedPlaybooksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrackPlaybooks,
      GetTrackedPlaybooksError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.TrackPlaybooks,
    GetTrackedPlaybooksError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/track-playbooks",
      operationId: "getTrackedPlaybooks",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTrackedPlaybooks({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type TrackPlaybooksError = Fetcher.ErrorWrapper<undefined>;

export type TrackPlaybooksVariables = {
  body?: Schemas.PostTrackPlaybooksDto;
} & PrimioContext["fetcherOptions"];

export const fetchTrackPlaybooks = (
  variables: TrackPlaybooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    TrackPlaybooksError,
    Schemas.PostTrackPlaybooksDto,
    {},
    {},
    {}
  >({ url: "/track-playbooks", method: "post", ...variables, signal });

export const useTrackPlaybooks = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrackPlaybooksError,
      TrackPlaybooksVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    TrackPlaybooksError,
    TrackPlaybooksVariables
  >({
    mutationFn: (variables: TrackPlaybooksVariables) =>
      fetchTrackPlaybooks({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersResponse = Schemas.CognitoUser[];

export type GetUsersVariables = PrimioContext["fetcherOptions"];

/**
 * Returns all users in the Cognito User Pool.<BR/>Note: Only accessible by Admins
 */
export const fetchGetUsers = (
  variables: GetUsersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<GetUsersResponse, GetUsersError, undefined, {}, {}, {}>({
    url: "/users",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Returns all users in the Cognito User Pool.<BR/>Note: Only accessible by Admins
 */
export const useGetUsers = <TData = GetUsersResponse>(
  variables: GetUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetUsersResponse, GetUsersError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetUsersResponse, GetUsersError, TData>({
    queryKey: queryKeyFn({
      path: "/users",
      operationId: "getUsers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAuthenticatedUserError = Fetcher.ErrorWrapper<undefined>;

export type GetAuthenticatedUserVariables = PrimioContext["fetcherOptions"];

export const fetchGetAuthenticatedUser = (
  variables: GetAuthenticatedUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.CognitoUser,
    GetAuthenticatedUserError,
    undefined,
    {},
    {},
    {}
  >({ url: "/users/user", method: "get", ...variables, signal });

export const useGetAuthenticatedUser = <TData = Schemas.CognitoUser>(
  variables: GetAuthenticatedUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CognitoUser,
      GetAuthenticatedUserError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.CognitoUser,
    GetAuthenticatedUserError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/users/user",
      operationId: "getAuthenticatedUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAuthenticatedUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAuthenticatedUserError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAuthenticatedUserVariables = {
  body?: Schemas.UpdateUserDto;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateAuthenticatedUser = (
  variables: UpdateAuthenticatedUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateAuthenticatedUserError,
    Schemas.UpdateUserDto,
    {},
    {},
    {}
  >({ url: "/users/user", method: "patch", ...variables, signal });

export const useUpdateAuthenticatedUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateAuthenticatedUserError,
      UpdateAuthenticatedUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateAuthenticatedUserError,
    UpdateAuthenticatedUserVariables
  >({
    mutationFn: (variables: UpdateAuthenticatedUserVariables) =>
      fetchUpdateAuthenticatedUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeprecatedSendUserInvitationsError =
  Fetcher.ErrorWrapper<undefined>;

export type DeprecatedSendUserInvitationsVariables = {
  body: Schemas.SendUserInvitationsDataDto;
} & PrimioContext["fetcherOptions"];

export const fetchDeprecatedSendUserInvitations = (
  variables: DeprecatedSendUserInvitationsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    DeprecatedSendUserInvitationsError,
    Schemas.SendUserInvitationsDataDto,
    {},
    {},
    {}
  >({
    url: "/users/invitations/sending",
    method: "post",
    ...variables,
    signal,
  });

export const useDeprecatedSendUserInvitations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      DeprecatedSendUserInvitationsError,
      DeprecatedSendUserInvitationsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    void,
    DeprecatedSendUserInvitationsError,
    DeprecatedSendUserInvitationsVariables
  >({
    mutationFn: (variables: DeprecatedSendUserInvitationsVariables) =>
      fetchDeprecatedSendUserInvitations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetGroupsForUserPathParams = {
  username: string;
};

export type GetGroupsForUserError = Fetcher.ErrorWrapper<undefined>;

export type GetGroupsForUserResponse = string[];

export type GetGroupsForUserVariables = {
  pathParams: GetGroupsForUserPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * returns array of group names that user belongs to
 */
export const fetchGetGroupsForUser = (
  variables: GetGroupsForUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetGroupsForUserResponse,
    GetGroupsForUserError,
    undefined,
    {},
    {},
    GetGroupsForUserPathParams
  >({ url: "/users/{username}/groups", method: "get", ...variables, signal });

/**
 * returns array of group names that user belongs to
 */
export const useGetGroupsForUser = <TData = GetGroupsForUserResponse>(
  variables: GetGroupsForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetGroupsForUserResponse,
      GetGroupsForUserError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetGroupsForUserResponse,
    GetGroupsForUserError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/users/{username}/groups",
      operationId: "getGroupsForUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetGroupsForUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateUserFromInvitationPathParams = {
  invitationUid: string;
};

export type CreateUserFromInvitationError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserFromInvitationVariables = {
  body: Schemas.CreateUserFromInvitationDto;
  pathParams: CreateUserFromInvitationPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchCreateUserFromInvitation = (
  variables: CreateUserFromInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    boolean,
    CreateUserFromInvitationError,
    Schemas.CreateUserFromInvitationDto,
    {},
    {},
    CreateUserFromInvitationPathParams
  >({
    url: "/users/invitations/{invitationUid}/createUser",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateUserFromInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      boolean,
      CreateUserFromInvitationError,
      CreateUserFromInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    boolean,
    CreateUserFromInvitationError,
    CreateUserFromInvitationVariables
  >({
    mutationFn: (variables: CreateUserFromInvitationVariables) =>
      fetchCreateUserFromInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteUserAttributesPathParams = {
  userAttributesUid: string;
};

export type DeleteUserAttributesError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserAttributesVariables = {
  pathParams: DeleteUserAttributesPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteUserAttributes = (
  variables: DeleteUserAttributesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteUserAttributesError,
    undefined,
    {},
    {},
    DeleteUserAttributesPathParams
  >({
    url: "/users/attributes/{userAttributesUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteUserAttributes = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteUserAttributesError,
      DeleteUserAttributesVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteUserAttributesError,
    DeleteUserAttributesVariables
  >({
    mutationFn: (variables: DeleteUserAttributesVariables) =>
      fetchDeleteUserAttributes({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteUserPathParams = {
  username: string;
  email: string;
};

export type DeleteUserError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserVariables = {
  pathParams: DeleteUserPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteUser = (
  variables: DeleteUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteUserError,
    undefined,
    {},
    {},
    DeleteUserPathParams
  >({
    url: "/users/{username}/{email}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteUserError,
      DeleteUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteUserError,
    DeleteUserVariables
  >({
    mutationFn: (variables: DeleteUserVariables) =>
      fetchDeleteUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableUserPathParams = {
  identifier: string;
};

export type DisableUserError = Fetcher.ErrorWrapper<undefined>;

export type DisableUserVariables = {
  pathParams: DisableUserPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDisableUser = (
  variables: DisableUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DisableUserError,
    undefined,
    {},
    {},
    DisableUserPathParams
  >({
    url: "/users/disable/{identifier}",
    method: "post",
    ...variables,
    signal,
  });

export const useDisableUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DisableUserError,
      DisableUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DisableUserError,
    DisableUserVariables
  >({
    mutationFn: (variables: DisableUserVariables) =>
      fetchDisableUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableUserPathParams = {
  identifier: string;
};

export type EnableUserError = Fetcher.ErrorWrapper<undefined>;

export type EnableUserVariables = {
  pathParams: EnableUserPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchEnableUser = (
  variables: EnableUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    EnableUserError,
    undefined,
    {},
    {},
    EnableUserPathParams
  >({
    url: "/users/enable/{identifier}",
    method: "post",
    ...variables,
    signal,
  });

export const useEnableUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EnableUserError,
      EnableUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    EnableUserError,
    EnableUserVariables
  >({
    mutationFn: (variables: EnableUserVariables) =>
      fetchEnableUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserByUidPathParams = {
  userUid: string;
};

export type GetUserByUidError = Fetcher.ErrorWrapper<undefined>;

export type GetUserByUidVariables = {
  pathParams: GetUserByUidPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserByUid = (
  variables: GetUserByUidVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.CognitoUser,
    GetUserByUidError,
    undefined,
    {},
    {},
    GetUserByUidPathParams
  >({ url: "/users/{userUid}", method: "get", ...variables, signal });

export const useGetUserByUid = <TData = Schemas.CognitoUser>(
  variables: GetUserByUidVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CognitoUser, GetUserByUidError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.CognitoUser, GetUserByUidError, TData>({
    queryKey: queryKeyFn({
      path: "/users/{userUid}",
      operationId: "getUserByUid",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserByUid({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathsResponse = Schemas.LearningPath[];

export type GetLearningPathsVariables = PrimioContext["fetcherOptions"];

export const fetchGetLearningPaths = (
  variables: GetLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetLearningPathsResponse,
    GetLearningPathsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/learning-paths", method: "get", ...variables, signal });

export const useGetLearningPaths = <TData = GetLearningPathsResponse>(
  variables: GetLearningPathsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetLearningPathsResponse,
      GetLearningPathsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetLearningPathsResponse,
    GetLearningPathsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths",
      operationId: "getLearningPaths",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPaths({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type CreateLearningPathVariables = {
  body: Schemas.CreateLearningPathDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateLearningPath = (
  variables: CreateLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CreateLearningPathError,
    Schemas.CreateLearningPathDto,
    {},
    {},
    {}
  >({ url: "/learning-paths", method: "post", ...variables, signal });

export const useCreateLearningPath = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateLearningPathError,
      CreateLearningPathVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateLearningPathError,
    CreateLearningPathVariables
  >({
    mutationFn: (variables: CreateLearningPathVariables) =>
      fetchCreateLearningPath({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DownloadCertificatesError = Fetcher.ErrorWrapper<undefined>;

export type DownloadCertificatesRequestBody =
  Schemas.ZipLearningPathCertificateDto[];

export type DownloadCertificatesVariables = {
  body?: DownloadCertificatesRequestBody;
} & PrimioContext["fetcherOptions"];

export const fetchDownloadCertificates = (
  variables: DownloadCertificatesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DownloadCertificatesError,
    DownloadCertificatesRequestBody,
    {},
    {},
    {}
  >({
    url: "/learning-paths/certificates/zip",
    method: "post",
    ...variables,
    signal,
  });

export const useDownloadCertificates = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DownloadCertificatesError,
      DownloadCertificatesVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DownloadCertificatesError,
    DownloadCertificatesVariables
  >({
    mutationFn: (variables: DownloadCertificatesVariables) =>
      fetchDownloadCertificates({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLearningPathsForContentsQueryParams = {
  ["content-uids"]?: string;
};

export type GetLearningPathsForContentsError = Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathsForContentsResponse =
  Schemas.LearningPathContentModel[];

export type GetLearningPathsForContentsVariables = {
  queryParams?: GetLearningPathsForContentsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPathsForContents = (
  variables: GetLearningPathsForContentsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetLearningPathsForContentsResponse,
    GetLearningPathsForContentsError,
    undefined,
    {},
    GetLearningPathsForContentsQueryParams,
    {}
  >({ url: "/learning-paths/content", method: "get", ...variables, signal });

export const useGetLearningPathsForContents = <
  TData = GetLearningPathsForContentsResponse,
>(
  variables: GetLearningPathsForContentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetLearningPathsForContentsResponse,
      GetLearningPathsForContentsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetLearningPathsForContentsResponse,
    GetLearningPathsForContentsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths/content",
      operationId: "getLearningPathsForContents",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPathsForContents(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetLearningPathPathParams = {
  learningPathUid: string;
};

export type GetLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathVariables = {
  pathParams: GetLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPath = (
  variables: GetLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.LearningPath,
    GetLearningPathError,
    undefined,
    {},
    {},
    GetLearningPathPathParams
  >({
    url: "/learning-paths/{learningPathUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLearningPath = <TData = Schemas.LearningPath>(
  variables: GetLearningPathVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LearningPath,
      GetLearningPathError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.LearningPath, GetLearningPathError, TData>(
    {
      queryKey: queryKeyFn({
        path: "/learning-paths/{learningPathUid}",
        operationId: "getLearningPath",
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetLearningPath({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateLearningPathPathParams = {
  learningPathUid: string;
};

export type UpdateLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type UpdateLearningPathVariables = {
  body?: Schemas.UpdateLearningPathDto;
  pathParams: UpdateLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateLearningPath = (
  variables: UpdateLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateLearningPathError,
    Schemas.UpdateLearningPathDto,
    {},
    {},
    UpdateLearningPathPathParams
  >({
    url: "/learning-paths/{learningPathUid}",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateLearningPath = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateLearningPathError,
      UpdateLearningPathVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateLearningPathError,
    UpdateLearningPathVariables
  >({
    mutationFn: (variables: UpdateLearningPathVariables) =>
      fetchUpdateLearningPath({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteLearningPathPathParams = {
  learningPathUid: string;
};

export type DeleteLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type DeleteLearningPathVariables = {
  pathParams: DeleteLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteLearningPath = (
  variables: DeleteLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteLearningPathError,
    undefined,
    {},
    {},
    DeleteLearningPathPathParams
  >({
    url: "/learning-paths/{learningPathUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteLearningPath = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteLearningPathError,
      DeleteLearningPathVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteLearningPathError,
    DeleteLearningPathVariables
  >({
    mutationFn: (variables: DeleteLearningPathVariables) =>
      fetchDeleteLearningPath({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLearningPathDetailV2PathParams = {
  learningPathUid: string;
};

export type GetLearningPathDetailV2Error = Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathDetailV2Variables = {
  pathParams: GetLearningPathDetailV2PathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPathDetailV2 = (
  variables: GetLearningPathDetailV2Variables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.LearningPathV2,
    GetLearningPathDetailV2Error,
    undefined,
    {},
    {},
    GetLearningPathDetailV2PathParams
  >({
    url: "/v2/learning-paths/{learningPathUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLearningPathDetailV2 = <TData = Schemas.LearningPathV2>(
  variables: GetLearningPathDetailV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LearningPathV2,
      GetLearningPathDetailV2Error,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.LearningPathV2,
    GetLearningPathDetailV2Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v2/learning-paths/{learningPathUid}",
      operationId: "getLearningPathDetailV2",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPathDetailV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SortLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type SortLearningPathsVariables = {
  body: Schemas.SortLearningPathsDto;
} & PrimioContext["fetcherOptions"];

export const fetchSortLearningPaths = (
  variables: SortLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    SortLearningPathsError,
    Schemas.SortLearningPathsDto,
    {},
    {},
    {}
  >({ url: "/learning-paths/sort", method: "post", ...variables, signal });

export const useSortLearningPaths = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SortLearningPathsError,
      SortLearningPathsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    SortLearningPathsError,
    SortLearningPathsVariables
  >({
    mutationFn: (variables: SortLearningPathsVariables) =>
      fetchSortLearningPaths({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSummarizedLearningPathProgressError =
  Fetcher.ErrorWrapper<undefined>;

export type GetSummarizedLearningPathProgressVariables =
  PrimioContext["fetcherOptions"];

export const fetchGetSummarizedLearningPathProgress = (
  variables: GetSummarizedLearningPathProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.ProgressSummaryModel,
    GetSummarizedLearningPathProgressError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/learning-paths/progress/summary",
    method: "get",
    ...variables,
    signal,
  });

export const useGetSummarizedLearningPathProgress = <
  TData = Schemas.ProgressSummaryModel,
>(
  variables: GetSummarizedLearningPathProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProgressSummaryModel,
      GetSummarizedLearningPathProgressError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.ProgressSummaryModel,
    GetSummarizedLearningPathProgressError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths/progress/summary",
      operationId: "getSummarizedLearningPathProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSummarizedLearningPathProgress(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostLearningPathPathParams = {
  learningPathUid: string;
};

export type PostLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type PostLearningPathVariables = {
  pathParams: PostLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchPostLearningPath = (
  variables: PostLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    PostLearningPathError,
    undefined,
    {},
    {},
    PostLearningPathPathParams
  >({
    url: "/learning-paths/{learningPathUid}/post",
    method: "post",
    ...variables,
    signal,
  });

export const usePostLearningPath = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostLearningPathError,
      PostLearningPathVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    PostLearningPathError,
    PostLearningPathVariables
  >({
    mutationFn: (variables: PostLearningPathVariables) =>
      fetchPostLearningPath({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RevokeLearningPathPathParams = {
  learningPathUid: string;
};

export type RevokeLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type RevokeLearningPathVariables = {
  pathParams: RevokeLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchRevokeLearningPath = (
  variables: RevokeLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    RevokeLearningPathError,
    undefined,
    {},
    {},
    RevokeLearningPathPathParams
  >({
    url: "/learning-paths/{learningPathUid}/revoke",
    method: "post",
    ...variables,
    signal,
  });

export const useRevokeLearningPath = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RevokeLearningPathError,
      RevokeLearningPathVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    RevokeLearningPathError,
    RevokeLearningPathVariables
  >({
    mutationFn: (variables: RevokeLearningPathVariables) =>
      fetchRevokeLearningPath({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DuplicateLearningPathPathParams = {
  learningPathUid: string;
};

export type DuplicateLearningPathError = Fetcher.ErrorWrapper<undefined>;

export type DuplicateLearningPathVariables = {
  pathParams: DuplicateLearningPathPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDuplicateLearningPath = (
  variables: DuplicateLearningPathVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    DuplicateLearningPathError,
    undefined,
    {},
    {},
    DuplicateLearningPathPathParams
  >({
    url: "/learning-paths/{learningPathUid}/duplicate",
    method: "post",
    ...variables,
    signal,
  });

export const useDuplicateLearningPath = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DuplicateLearningPathError,
      DuplicateLearningPathVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    DuplicateLearningPathError,
    DuplicateLearningPathVariables
  >({
    mutationFn: (variables: DuplicateLearningPathVariables) =>
      fetchDuplicateLearningPath({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLearningPathContentUserGroupsPathParams = {
  learningPathUid: string;
};

export type GetLearningPathContentUserGroupsError =
  Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathContentUserGroupsVariables = {
  pathParams: GetLearningPathContentUserGroupsPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPathContentUserGroups = (
  variables: GetLearningPathContentUserGroupsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.LearningPathContentUserGroupsModel,
    GetLearningPathContentUserGroupsError,
    undefined,
    {},
    {},
    GetLearningPathContentUserGroupsPathParams
  >({
    url: "/learning-paths/{learningPathUid}/content/user-groups",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLearningPathContentUserGroups = <
  TData = Schemas.LearningPathContentUserGroupsModel,
>(
  variables: GetLearningPathContentUserGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LearningPathContentUserGroupsModel,
      GetLearningPathContentUserGroupsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.LearningPathContentUserGroupsModel,
    GetLearningPathContentUserGroupsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths/{learningPathUid}/content/user-groups",
      operationId: "getLearningPathContentUserGroups",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPathContentUserGroups(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetUserLearningPathCertificatePathParams = {
  learningPathUid: string;
  userUid: string;
};

export type GetUserLearningPathCertificateError =
  Fetcher.ErrorWrapper<undefined>;

export type GetUserLearningPathCertificateVariables = {
  pathParams: GetUserLearningPathCertificatePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserLearningPathCertificate = (
  variables: GetUserLearningPathCertificateVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    GetUserLearningPathCertificateError,
    undefined,
    {},
    {},
    GetUserLearningPathCertificatePathParams
  >({
    url: "/learning-paths/{learningPathUid}/users/{userUid}/certificate",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserLearningPathCertificate = <TData = undefined>(
  variables: GetUserLearningPathCertificateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetUserLearningPathCertificateError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    undefined,
    GetUserLearningPathCertificateError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths/{learningPathUid}/users/{userUid}/certificate",
      operationId: "getUserLearningPathCertificate",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserLearningPathCertificate(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetLearningPathCertificatePathParams = {
  learningPathUid: string;
};

export type GetLearningPathCertificateError = Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathCertificateVariables = {
  pathParams: GetLearningPathCertificatePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPathCertificate = (
  variables: GetLearningPathCertificateVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.LearningPathCertificateModel,
    GetLearningPathCertificateError,
    undefined,
    {},
    {},
    GetLearningPathCertificatePathParams
  >({
    url: "/learning-paths/{learningPathUid}/certificate/pdf",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLearningPathCertificate = <
  TData = Schemas.LearningPathCertificateModel,
>(
  variables: GetLearningPathCertificateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LearningPathCertificateModel,
      GetLearningPathCertificateError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.LearningPathCertificateModel,
    GetLearningPathCertificateError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths/{learningPathUid}/certificate/pdf",
      operationId: "getLearningPathCertificate",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPathCertificate(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetLearningPathCertificateViewPathParams = {
  learningPathUid: string;
};

export type GetLearningPathCertificateViewError =
  Fetcher.ErrorWrapper<undefined>;

export type GetLearningPathCertificateViewVariables = {
  pathParams: GetLearningPathCertificateViewPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetLearningPathCertificateView = (
  variables: GetLearningPathCertificateViewVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.LearningPathCertificateViewModel,
    GetLearningPathCertificateViewError,
    undefined,
    {},
    {},
    GetLearningPathCertificateViewPathParams
  >({
    url: "/learning-paths/{learningPathUid}/certificate",
    method: "get",
    ...variables,
    signal,
  });

export const useGetLearningPathCertificateView = <
  TData = Schemas.LearningPathCertificateViewModel,
>(
  variables: GetLearningPathCertificateViewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LearningPathCertificateViewModel,
      GetLearningPathCertificateViewError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.LearningPathCertificateViewModel,
    GetLearningPathCertificateViewError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/learning-paths/{learningPathUid}/certificate",
      operationId: "getLearningPathCertificateView",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLearningPathCertificateView(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetApiKeysError = Fetcher.ErrorWrapper<undefined>;

export type GetApiKeysResponse = Schemas.ApiKey[];

export type GetApiKeysVariables = PrimioContext["fetcherOptions"];

export const fetchGetApiKeys = (
  variables: GetApiKeysVariables,
  signal?: AbortSignal,
) =>
  primioFetch<GetApiKeysResponse, GetApiKeysError, undefined, {}, {}, {}>({
    url: "/api-keys",
    method: "get",
    ...variables,
    signal,
  });

export const useGetApiKeys = <TData = GetApiKeysResponse>(
  variables: GetApiKeysVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetApiKeysResponse, GetApiKeysError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetApiKeysResponse, GetApiKeysError, TData>({
    queryKey: queryKeyFn({
      path: "/api-keys",
      operationId: "getApiKeys",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetApiKeys({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type CreateApiKeyVariables = {
  body: Schemas.CreateApiKeyDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateApiKey = (
  variables: CreateApiKeyVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    CreateApiKeyError,
    Schemas.CreateApiKeyDto,
    {},
    {},
    {}
  >({ url: "/api-keys", method: "post", ...variables, signal });

export const useCreateApiKey = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CreateApiKeyError,
      CreateApiKeyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    CreateApiKeyError,
    CreateApiKeyVariables
  >({
    mutationFn: (variables: CreateApiKeyVariables) =>
      fetchCreateApiKey({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateAndConnectApiKeyClientError = Fetcher.ErrorWrapper<undefined>;

export type CreateAndConnectApiKeyClientVariables = {
  body: Schemas.CreateApiKeyClientDto;
} & PrimioContext["fetcherOptions"];

/**
 * This also does a call to the publisher to add itself to the subscribers
 */
export const fetchCreateAndConnectApiKeyClient = (
  variables: CreateAndConnectApiKeyClientVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    CreateAndConnectApiKeyClientError,
    Schemas.CreateApiKeyClientDto,
    {},
    {},
    {}
  >({ url: "/api-keys/clients", method: "post", ...variables, signal });

/**
 * This also does a call to the publisher to add itself to the subscribers
 */
export const useCreateAndConnectApiKeyClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      CreateAndConnectApiKeyClientError,
      CreateAndConnectApiKeyClientVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    void,
    CreateAndConnectApiKeyClientError,
    CreateAndConnectApiKeyClientVariables
  >({
    mutationFn: (variables: CreateAndConnectApiKeyClientVariables) =>
      fetchCreateAndConnectApiKeyClient({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetApiKeyClientsError = Fetcher.ErrorWrapper<undefined>;

export type GetApiKeyClientsResponse = Schemas.ApiKeyClient[];

export type GetApiKeyClientsVariables = PrimioContext["fetcherOptions"];

export const fetchGetApiKeyClients = (
  variables: GetApiKeyClientsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetApiKeyClientsResponse,
    GetApiKeyClientsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api-keys/clients", method: "get", ...variables, signal });

export const useGetApiKeyClients = <TData = GetApiKeyClientsResponse>(
  variables: GetApiKeyClientsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetApiKeyClientsResponse,
      GetApiKeyClientsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetApiKeyClientsResponse,
    GetApiKeyClientsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api-keys/clients",
      operationId: "getApiKeyClients",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetApiKeyClients({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetApikeyPathParams = {
  key: string;
};

export type GetApikeyError = Fetcher.ErrorWrapper<undefined>;

export type GetApikeyVariables = {
  pathParams: GetApikeyPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetApikey = (
  variables: GetApikeyVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.ApiKey,
    GetApikeyError,
    undefined,
    {},
    {},
    GetApikeyPathParams
  >({ url: "/api-keys/{key}", method: "get", ...variables, signal });

export const useGetApikey = <TData = Schemas.ApiKey>(
  variables: GetApikeyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ApiKey, GetApikeyError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.ApiKey, GetApikeyError, TData>({
    queryKey: queryKeyFn({
      path: "/api-keys/{key}",
      operationId: "getApikey",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetApikey({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateApiKeyPathParams = {
  key: string;
};

export type UpdateApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type UpdateApiKeyVariables = {
  body?: Schemas.UpdateApiKeyDto;
  pathParams: UpdateApiKeyPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateApiKey = (
  variables: UpdateApiKeyVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateApiKeyError,
    Schemas.UpdateApiKeyDto,
    {},
    {},
    UpdateApiKeyPathParams
  >({ url: "/api-keys/{key}", method: "post", ...variables, signal });

export const useUpdateApiKey = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateApiKeyError,
      UpdateApiKeyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateApiKeyError,
    UpdateApiKeyVariables
  >({
    mutationFn: (variables: UpdateApiKeyVariables) =>
      fetchUpdateApiKey({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteApiKeyPathParams = {
  key: string;
};

export type DeleteApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type DeleteApiKeyVariables = {
  pathParams: DeleteApiKeyPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteApiKey = (
  variables: DeleteApiKeyVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteApiKeyError,
    undefined,
    {},
    {},
    DeleteApiKeyPathParams
  >({ url: "/api-keys/{key}", method: "delete", ...variables, signal });

export const useDeleteApiKey = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteApiKeyError,
      DeleteApiKeyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteApiKeyError,
    DeleteApiKeyVariables
  >({
    mutationFn: (variables: DeleteApiKeyVariables) =>
      fetchDeleteApiKey({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddSubscriberPathParams = {
  key: string;
  subscriber: string;
};

export type AddSubscriberError = Fetcher.ErrorWrapper<undefined>;

export type AddSubscriberVariables = {
  pathParams: AddSubscriberPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchAddSubscriber = (
  variables: AddSubscriberVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    AddSubscriberError,
    undefined,
    {},
    {},
    AddSubscriberPathParams
  >({
    url: "/api-keys/{key}/subscribers/{subscriber}",
    method: "post",
    ...variables,
    signal,
  });

export const useAddSubscriber = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddSubscriberError,
      AddSubscriberVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    AddSubscriberError,
    AddSubscriberVariables
  >({
    mutationFn: (variables: AddSubscriberVariables) =>
      fetchAddSubscriber({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveSubscriberPathParams = {
  key: string;
  subscriber: string;
};

export type RemoveSubscriberError = Fetcher.ErrorWrapper<undefined>;

export type RemoveSubscriberVariables = {
  pathParams: RemoveSubscriberPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchRemoveSubscriber = (
  variables: RemoveSubscriberVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    RemoveSubscriberError,
    undefined,
    {},
    {},
    RemoveSubscriberPathParams
  >({
    url: "/api-keys/{key}/subscribers/{subscriber}",
    method: "delete",
    ...variables,
    signal,
  });

export const useRemoveSubscriber = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveSubscriberError,
      RemoveSubscriberVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    RemoveSubscriberError,
    RemoveSubscriberVariables
  >({
    mutationFn: (variables: RemoveSubscriberVariables) =>
      fetchRemoveSubscriber({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableApiKeyPathParams = {
  key: string;
};

export type DisableApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type DisableApiKeyVariables = {
  pathParams: DisableApiKeyPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDisableApiKey = (
  variables: DisableApiKeyVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DisableApiKeyError,
    undefined,
    {},
    {},
    DisableApiKeyPathParams
  >({ url: "/api-keys/{key}/disable", method: "post", ...variables, signal });

export const useDisableApiKey = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DisableApiKeyError,
      DisableApiKeyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DisableApiKeyError,
    DisableApiKeyVariables
  >({
    mutationFn: (variables: DisableApiKeyVariables) =>
      fetchDisableApiKey({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableApiKeyPathParams = {
  key: string;
};

export type EnableApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type EnableApiKeyVariables = {
  pathParams: EnableApiKeyPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchEnableApiKey = (
  variables: EnableApiKeyVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    EnableApiKeyError,
    undefined,
    {},
    {},
    EnableApiKeyPathParams
  >({ url: "/api-keys/{key}/enable", method: "post", ...variables, signal });

export const useEnableApiKey = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EnableApiKeyError,
      EnableApiKeyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    EnableApiKeyError,
    EnableApiKeyVariables
  >({
    mutationFn: (variables: EnableApiKeyVariables) =>
      fetchEnableApiKey({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetApiKeyClientPathParams = {
  apiKeyClientUid: string;
};

export type GetApiKeyClientError = Fetcher.ErrorWrapper<undefined>;

export type GetApiKeyClientVariables = {
  pathParams: GetApiKeyClientPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetApiKeyClient = (
  variables: GetApiKeyClientVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.ApiKeyClient,
    GetApiKeyClientError,
    undefined,
    {},
    {},
    GetApiKeyClientPathParams
  >({
    url: "/api-keys/clients/{apiKeyClientUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetApiKeyClient = <TData = Schemas.ApiKeyClient>(
  variables: GetApiKeyClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ApiKeyClient,
      GetApiKeyClientError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.ApiKeyClient, GetApiKeyClientError, TData>(
    {
      queryKey: queryKeyFn({
        path: "/api-keys/clients/{apiKeyClientUid}",
        operationId: "getApiKeyClient",
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetApiKeyClient({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    },
  );
};

export type DeleteApiKeyClientPathParams = {
  apiKeyClientUid: string;
};

export type DeleteApiKeyClientError = Fetcher.ErrorWrapper<undefined>;

export type DeleteApiKeyClientVariables = {
  pathParams: DeleteApiKeyClientPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * This also does a call to the publisher to remove itself from the subscribers
 */
export const fetchDeleteApiKeyClient = (
  variables: DeleteApiKeyClientVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteApiKeyClientError,
    undefined,
    {},
    {},
    DeleteApiKeyClientPathParams
  >({
    url: "/api-keys/clients/{apiKeyClientUid}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * This also does a call to the publisher to remove itself from the subscribers
 */
export const useDeleteApiKeyClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteApiKeyClientError,
      DeleteApiKeyClientVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteApiKeyClientError,
    DeleteApiKeyClientVariables
  >({
    mutationFn: (variables: DeleteApiKeyClientVariables) =>
      fetchDeleteApiKeyClient({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateApiKeyClientContentUserGroupsPathParams = {
  apiKeyClientUid: string;
};

export type UpdateApiKeyClientContentUserGroupsError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateApiKeyClientContentUserGroupsVariables = {
  body: Schemas.UpdateUserGroupsApiKeyClientContentDto;
  pathParams: UpdateApiKeyClientContentUserGroupsPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateApiKeyClientContentUserGroups = (
  variables: UpdateApiKeyClientContentUserGroupsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateApiKeyClientContentUserGroupsError,
    Schemas.UpdateUserGroupsApiKeyClientContentDto,
    {},
    {},
    UpdateApiKeyClientContentUserGroupsPathParams
  >({
    url: "/api-keys/clients/{apiKeyClientUid}/content/user-groups",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateApiKeyClientContentUserGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateApiKeyClientContentUserGroupsError,
      UpdateApiKeyClientContentUserGroupsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateApiKeyClientContentUserGroupsError,
    UpdateApiKeyClientContentUserGroupsVariables
  >({
    mutationFn: (variables: UpdateApiKeyClientContentUserGroupsVariables) =>
      fetchUpdateApiKeyClientContentUserGroups({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type UpdateApiKeyClientContentSortPathParams = {
  apiKeyClientUid: string;
};

export type UpdateApiKeyClientContentSortError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateApiKeyClientContentSortVariables = {
  body: Schemas.UpdateSortApiKeyClientContentDto;
  pathParams: UpdateApiKeyClientContentSortPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateApiKeyClientContentSort = (
  variables: UpdateApiKeyClientContentSortVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateApiKeyClientContentSortError,
    Schemas.UpdateSortApiKeyClientContentDto,
    {},
    {},
    UpdateApiKeyClientContentSortPathParams
  >({
    url: "/api-keys/clients/{apiKeyClientUid}/content/sort",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateApiKeyClientContentSort = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateApiKeyClientContentSortError,
      UpdateApiKeyClientContentSortVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateApiKeyClientContentSortError,
    UpdateApiKeyClientContentSortVariables
  >({
    mutationFn: (variables: UpdateApiKeyClientContentSortVariables) =>
      fetchUpdateApiKeyClientContentSort({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPaginatedPlayBooksQueryParams = {
  /**
   * @default 1000
   */
  limit?: number;
  /**
   * @default 0
   */
  offset?: number;
  playbookUids?: string[];
  /**
   * @default ALL
   */
  provider?: "ALL" | "DATABASE" | "APIS";
  /**
   * @default false
   */
  includeMedia?: boolean;
  contentStates?: ("DRAFT" | "PUBLISHED" | "DELETED")[];
  categoryUids?: string[];
  userGroupAcl?: string[];
  q?: string;
  include?: string;
  progressStates?: ("not-started" | "started" | "completed")[];
  /**
   * @default false
   */
  excludeTrainingPlaybooks?: boolean;
  /**
   * @default sort_desc
   */
  sort?: "sort_desc" | "title_desc" | "title_asc";
};

export type GetPaginatedPlayBooksError = Fetcher.ErrorWrapper<undefined>;

export type GetPaginatedPlayBooksResponse = {
  data: never;
  metadata: Schemas.Metadata;
};

export type GetPaginatedPlayBooksVariables = {
  queryParams?: GetPaginatedPlayBooksQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetPaginatedPlayBooks = (
  variables: GetPaginatedPlayBooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetPaginatedPlayBooksResponse,
    GetPaginatedPlayBooksError,
    undefined,
    {},
    GetPaginatedPlayBooksQueryParams,
    {}
  >({ url: "/v2/playbooks", method: "get", ...variables, signal });

export const useGetPaginatedPlayBooks = <TData = GetPaginatedPlayBooksResponse>(
  variables: GetPaginatedPlayBooksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPaginatedPlayBooksResponse,
      GetPaginatedPlayBooksError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetPaginatedPlayBooksResponse,
    GetPaginatedPlayBooksError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/v2/playbooks",
      operationId: "getPaginatedPlayBooks",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPaginatedPlayBooks({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPlaybooksQueryParams = {
  ["playbook-uids"]?: string;
  provider?: string;
  excludeMedia?: boolean;
  excludeTrainingPlaybooks?: boolean;
  include?: string;
  includeContent?: boolean;
};

export type GetPlaybooksError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybooksResponse = Schemas.Playbook[];

export type GetPlaybooksVariables = {
  queryParams?: GetPlaybooksQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Returns the playbooks a user has access to.<br />For normal users it returns only Published playbooks, for Admins, the playbooks in Draft state are also included.<BR/>Note: Chapters are not included <br />Note: Deprecated in favor of `GET v2/playbooks`, but still used for shared content
 */
export const fetchGetPlaybooks = (
  variables: GetPlaybooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetPlaybooksResponse,
    GetPlaybooksError,
    undefined,
    {},
    GetPlaybooksQueryParams,
    {}
  >({ url: "/playbooks", method: "get", ...variables, signal });

/**
 * Returns the playbooks a user has access to.<br />For normal users it returns only Published playbooks, for Admins, the playbooks in Draft state are also included.<BR/>Note: Chapters are not included <br />Note: Deprecated in favor of `GET v2/playbooks`, but still used for shared content
 */
export const useGetPlaybooks = <TData = GetPlaybooksResponse>(
  variables: GetPlaybooksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPlaybooksResponse, GetPlaybooksError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetPlaybooksResponse, GetPlaybooksError, TData>({
    queryKey: queryKeyFn({
      path: "/playbooks",
      operationId: "getPlaybooks",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybooks({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreatePlaybookError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlaybookVariables = {
  body: Schemas.CreatePlaybookDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreatePlaybook = (
  variables: CreatePlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CreatePlaybookError,
    Schemas.CreatePlaybookDto,
    {},
    {},
    {}
  >({ url: "/playbooks", method: "post", ...variables, signal });

export const useCreatePlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreatePlaybookError,
      CreatePlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreatePlaybookError,
    CreatePlaybookVariables
  >({
    mutationFn: (variables: CreatePlaybookVariables) =>
      fetchCreatePlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlaybooksOverviewError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybooksOverviewResponse = Schemas.PlaybookOverviewModel[];

export type GetPlaybooksOverviewVariables = PrimioContext["fetcherOptions"];

export const fetchGetPlaybooksOverview = (
  variables: GetPlaybooksOverviewVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetPlaybooksOverviewResponse,
    GetPlaybooksOverviewError,
    undefined,
    {},
    {},
    {}
  >({ url: "/playbooks/overview", method: "get", ...variables, signal });

export const useGetPlaybooksOverview = <TData = GetPlaybooksOverviewResponse>(
  variables: GetPlaybooksOverviewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPlaybooksOverviewResponse,
      GetPlaybooksOverviewError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetPlaybooksOverviewResponse,
    GetPlaybooksOverviewError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbooks/overview",
      operationId: "getPlaybooksOverview",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybooksOverview({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPlaybookHierarchiesQueryParams = {
  ["playbook-uids"]?: string;
};

export type GetPlaybookHierarchiesError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybookHierarchiesResponse = Schemas.Playbook[];

export type GetPlaybookHierarchiesVariables = {
  queryParams?: GetPlaybookHierarchiesQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetPlaybookHierarchies = (
  variables: GetPlaybookHierarchiesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetPlaybookHierarchiesResponse,
    GetPlaybookHierarchiesError,
    undefined,
    {},
    GetPlaybookHierarchiesQueryParams,
    {}
  >({ url: "/playbooks/hierarchies", method: "get", ...variables, signal });

export const useGetPlaybookHierarchies = <
  TData = GetPlaybookHierarchiesResponse,
>(
  variables: GetPlaybookHierarchiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPlaybookHierarchiesResponse,
      GetPlaybookHierarchiesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetPlaybookHierarchiesResponse,
    GetPlaybookHierarchiesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbooks/hierarchies",
      operationId: "getPlaybookHierarchies",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybookHierarchies({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetSummarizedPlaybooksProgressError =
  Fetcher.ErrorWrapper<undefined>;

export type GetSummarizedPlaybooksProgressVariables =
  PrimioContext["fetcherOptions"];

export const fetchGetSummarizedPlaybooksProgress = (
  variables: GetSummarizedPlaybooksProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.ProgressSummaryModel,
    GetSummarizedPlaybooksProgressError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/playbooks/progress/summary",
    method: "get",
    ...variables,
    signal,
  });

export const useGetSummarizedPlaybooksProgress = <
  TData = Schemas.ProgressSummaryModel,
>(
  variables: GetSummarizedPlaybooksProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProgressSummaryModel,
      GetSummarizedPlaybooksProgressError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.ProgressSummaryModel,
    GetSummarizedPlaybooksProgressError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbooks/progress/summary",
      operationId: "getSummarizedPlaybooksProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSummarizedPlaybooksProgress(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetPlaybookPathParams = {
  playbookUid: string;
};

export type GetPlaybookQueryParams = {
  excludeMedia?: boolean;
  include?: string;
};

export type GetPlaybookError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybookVariables = {
  pathParams: GetPlaybookPathParams;
  queryParams?: GetPlaybookQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetPlaybook = (
  variables: GetPlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Playbook,
    GetPlaybookError,
    undefined,
    {},
    GetPlaybookQueryParams,
    GetPlaybookPathParams
  >({ url: "/playbooks/{playbookUid}", method: "get", ...variables, signal });

export const useGetPlaybook = <TData = Schemas.Playbook>(
  variables: GetPlaybookVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Playbook, GetPlaybookError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Playbook, GetPlaybookError, TData>({
    queryKey: queryKeyFn({
      path: "/playbooks/{playbookUid}",
      operationId: "getPlaybook",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybook({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdatePlaybookPathParams = {
  playbookUid: string;
};

export type UpdatePlaybookError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePlaybookVariables = {
  body?: Schemas.UpdatePlaybookDto;
  pathParams: UpdatePlaybookPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdatePlaybook = (
  variables: UpdatePlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Playbook,
    UpdatePlaybookError,
    Schemas.UpdatePlaybookDto,
    {},
    {},
    UpdatePlaybookPathParams
  >({ url: "/playbooks/{playbookUid}", method: "post", ...variables, signal });

export const useUpdatePlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Playbook,
      UpdatePlaybookError,
      UpdatePlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Playbook,
    UpdatePlaybookError,
    UpdatePlaybookVariables
  >({
    mutationFn: (variables: UpdatePlaybookVariables) =>
      fetchUpdatePlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePlaybookPathParams = {
  playbookUid: string;
};

export type DeletePlaybookError = Fetcher.ErrorWrapper<undefined>;

export type DeletePlaybookVariables = {
  pathParams: DeletePlaybookPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeletePlaybook = (
  variables: DeletePlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeletePlaybookError,
    undefined,
    {},
    {},
    DeletePlaybookPathParams
  >({
    url: "/playbooks/{playbookUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeletePlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePlaybookError,
      DeletePlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeletePlaybookError,
    DeletePlaybookVariables
  >({
    mutationFn: (variables: DeletePlaybookVariables) =>
      fetchDeletePlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlaybookDetailsPathParams = {
  playbookUid: string;
};

export type GetPlaybookDetailsQueryParams = {
  excludeMedia?: boolean;
  include?: string;
};

export type GetPlaybookDetailsError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybookDetailsVariables = {
  pathParams: GetPlaybookDetailsPathParams;
  queryParams?: GetPlaybookDetailsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetPlaybookDetails = (
  variables: GetPlaybookDetailsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Playbook,
    GetPlaybookDetailsError,
    undefined,
    {},
    GetPlaybookDetailsQueryParams,
    GetPlaybookDetailsPathParams
  >({
    url: "/playbooks/{playbookUid}/details",
    method: "get",
    ...variables,
    signal,
  });

export const useGetPlaybookDetails = <TData = Schemas.Playbook>(
  variables: GetPlaybookDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Playbook,
      GetPlaybookDetailsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Playbook, GetPlaybookDetailsError, TData>({
    queryKey: queryKeyFn({
      path: "/playbooks/{playbookUid}/details",
      operationId: "getPlaybookDetails",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlaybookDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SortPlaybooksError = Fetcher.ErrorWrapper<undefined>;

export type SortPlaybooksVariables = {
  body: Schemas.SortPlaybookDto;
} & PrimioContext["fetcherOptions"];

export const fetchSortPlaybooks = (
  variables: SortPlaybooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    SortPlaybooksError,
    Schemas.SortPlaybookDto,
    {},
    {},
    {}
  >({ url: "/playbooks/sort", method: "post", ...variables, signal });

export const useSortPlaybooks = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SortPlaybooksError,
      SortPlaybooksVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    SortPlaybooksError,
    SortPlaybooksVariables
  >({
    mutationFn: (variables: SortPlaybooksVariables) =>
      fetchSortPlaybooks({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportPlaybookError = Fetcher.ErrorWrapper<undefined>;

export type ImportPlaybookVariables = {
  body: Schemas.PlaybookExportModel;
} & PrimioContext["fetcherOptions"];

export const fetchImportPlaybook = (
  variables: ImportPlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Playbook,
    ImportPlaybookError,
    Schemas.PlaybookExportModel,
    {},
    {},
    {}
  >({ url: "/playbooks/imports", method: "post", ...variables, signal });

export const useImportPlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Playbook,
      ImportPlaybookError,
      ImportPlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Playbook,
    ImportPlaybookError,
    ImportPlaybookVariables
  >({
    mutationFn: (variables: ImportPlaybookVariables) =>
      fetchImportPlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PublishPlaybookPathParams = {
  playbookUid: string;
};

export type PublishPlaybookError = Fetcher.ErrorWrapper<undefined>;

export type PublishPlaybookVariables = {
  pathParams: PublishPlaybookPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchPublishPlaybook = (
  variables: PublishPlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Playbook,
    PublishPlaybookError,
    undefined,
    {},
    {},
    PublishPlaybookPathParams
  >({
    url: "/playbooks/{playbookUid}/publish",
    method: "post",
    ...variables,
    signal,
  });

export const usePublishPlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Playbook,
      PublishPlaybookError,
      PublishPlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Playbook,
    PublishPlaybookError,
    PublishPlaybookVariables
  >({
    mutationFn: (variables: PublishPlaybookVariables) =>
      fetchPublishPlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DraftPlaybookPathParams = {
  playbookUid: string;
};

export type DraftPlaybookError = Fetcher.ErrorWrapper<undefined>;

export type DraftPlaybookVariables = {
  pathParams: DraftPlaybookPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDraftPlaybook = (
  variables: DraftPlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Playbook,
    DraftPlaybookError,
    undefined,
    {},
    {},
    DraftPlaybookPathParams
  >({
    url: "/playbooks/{playbookUid}/draft",
    method: "post",
    ...variables,
    signal,
  });

export const useDraftPlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Playbook,
      DraftPlaybookError,
      DraftPlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Playbook,
    DraftPlaybookError,
    DraftPlaybookVariables
  >({
    mutationFn: (variables: DraftPlaybookVariables) =>
      fetchDraftPlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PublishPlaybookModulePathParams = {
  playbookUid: string;
};

export type PublishPlaybookModuleError = Fetcher.ErrorWrapper<undefined>;

export type PublishPlaybookModuleVariables = {
  pathParams: PublishPlaybookModulePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchPublishPlaybookModule = (
  variables: PublishPlaybookModuleVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    PublishPlaybookModuleError,
    undefined,
    {},
    {},
    PublishPlaybookModulePathParams
  >({
    url: "/playbooks/{playbookUid}/publish/module",
    method: "post",
    ...variables,
    signal,
  });

export const usePublishPlaybookModule = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PublishPlaybookModuleError,
      PublishPlaybookModuleVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    PublishPlaybookModuleError,
    PublishPlaybookModuleVariables
  >({
    mutationFn: (variables: PublishPlaybookModuleVariables) =>
      fetchPublishPlaybookModule({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DuplicatePlaybookPathParams = {
  playbookUid: string;
};

export type DuplicatePlaybookError = Fetcher.ErrorWrapper<undefined>;

export type DuplicatePlaybookVariables = {
  pathParams: DuplicatePlaybookPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDuplicatePlaybook = (
  variables: DuplicatePlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    DuplicatePlaybookError,
    undefined,
    {},
    {},
    DuplicatePlaybookPathParams
  >({
    url: "/playbooks/{playbookUid}/duplicate",
    method: "post",
    ...variables,
    signal,
  });

export const useDuplicatePlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DuplicatePlaybookError,
      DuplicatePlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    DuplicatePlaybookError,
    DuplicatePlaybookVariables
  >({
    mutationFn: (variables: DuplicatePlaybookVariables) =>
      fetchDuplicatePlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPlaybooksWhereUserGroupPathParams = {
  userGroup: string;
};

export type GetPlaybooksWhereUserGroupError = Fetcher.ErrorWrapper<undefined>;

export type GetPlaybooksWhereUserGroupVariables = {
  pathParams: GetPlaybooksWhereUserGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetPlaybooksWhereUserGroup = (
  variables: GetPlaybooksWhereUserGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    GetPlaybooksWhereUserGroupError,
    undefined,
    {},
    {},
    GetPlaybooksWhereUserGroupPathParams
  >({
    url: "/playbooks/user-groups/{userGroup}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetPlaybooksWhereUserGroup = <TData = undefined>(
  variables: GetPlaybooksWhereUserGroupVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetPlaybooksWhereUserGroupError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<undefined, GetPlaybooksWhereUserGroupError, TData>(
    {
      queryKey: queryKeyFn({
        path: "/playbooks/user-groups/{userGroup}",
        operationId: "getPlaybooksWhereUserGroup",
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetPlaybooksWhereUserGroup(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    },
  );
};

export type ExportPlaybookPathParams = {
  playbookUid: string;
};

export type ExportPlaybookError = Fetcher.ErrorWrapper<undefined>;

export type ExportPlaybookVariables = {
  pathParams: ExportPlaybookPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchExportPlaybook = (
  variables: ExportPlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.PlaybookExportModel,
    ExportPlaybookError,
    undefined,
    {},
    {},
    ExportPlaybookPathParams
  >({
    url: "/playbooks/{playbookUid}/export",
    method: "get",
    ...variables,
    signal,
  });

export const useExportPlaybook = <TData = Schemas.PlaybookExportModel>(
  variables: ExportPlaybookVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlaybookExportModel,
      ExportPlaybookError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.PlaybookExportModel,
    ExportPlaybookError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbooks/{playbookUid}/export",
      operationId: "exportPlaybook",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchExportPlaybook({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetChaptersQueryParams = {
  ["chapter-uids"]?: string;
  provider?: string;
  include?: string;
};

export type GetChaptersError = Fetcher.ErrorWrapper<undefined>;

export type GetChaptersResponse = Schemas.OmitTypeClass[];

export type GetChaptersVariables = {
  queryParams?: GetChaptersQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Returns the chapters a user has access to.<BR/>For normal users it returns only Published chapters, for Admins, the chapters in Draft state are also included.<BR/>Note: Collections are not included
 */
export const fetchGetChapters = (
  variables: GetChaptersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetChaptersResponse,
    GetChaptersError,
    undefined,
    {},
    GetChaptersQueryParams,
    {}
  >({ url: "/chapters", method: "get", ...variables, signal });

/**
 * Returns the chapters a user has access to.<BR/>For normal users it returns only Published chapters, for Admins, the chapters in Draft state are also included.<BR/>Note: Collections are not included
 */
export const useGetChapters = <TData = GetChaptersResponse>(
  variables: GetChaptersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetChaptersResponse, GetChaptersError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetChaptersResponse, GetChaptersError, TData>({
    queryKey: queryKeyFn({
      path: "/chapters",
      operationId: "getChapters",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetChapters({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateChapterError = Fetcher.ErrorWrapper<undefined>;

export type CreateChapterVariables = {
  body: Schemas.CreateChapterDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateChapter = (
  variables: CreateChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, CreateChapterError, Schemas.CreateChapterDto, {}, {}, {}>(
    { url: "/chapters", method: "post", ...variables, signal },
  );

export const useCreateChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateChapterError,
      CreateChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateChapterError,
    CreateChapterVariables
  >({
    mutationFn: (variables: CreateChapterVariables) =>
      fetchCreateChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetChapterPathParams = {
  chapterUid: string;
};

export type GetChapterQueryParams = {
  include?: string;
};

export type GetChapterError = Fetcher.ErrorWrapper<undefined>;

export type GetChapterVariables = {
  pathParams: GetChapterPathParams;
  queryParams?: GetChapterQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetChapter = (
  variables: GetChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Chapter,
    GetChapterError,
    undefined,
    {},
    GetChapterQueryParams,
    GetChapterPathParams
  >({ url: "/chapters/{chapterUid}", method: "get", ...variables, signal });

export const useGetChapter = <TData = Schemas.Chapter>(
  variables: GetChapterVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Chapter, GetChapterError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Chapter, GetChapterError, TData>({
    queryKey: queryKeyFn({
      path: "/chapters/{chapterUid}",
      operationId: "getChapter",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetChapter({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateChapterPathParams = {
  chapterUid: string;
};

export type UpdateChapterError = Fetcher.ErrorWrapper<undefined>;

export type UpdateChapterVariables = {
  body?: Schemas.UpdateChapterDto;
  pathParams: UpdateChapterPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Updates the chapter.<BR/>Note: Only accessible by Admins
 */
export const fetchUpdateChapter = (
  variables: UpdateChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateChapterError,
    Schemas.UpdateChapterDto,
    {},
    {},
    UpdateChapterPathParams
  >({ url: "/chapters/{chapterUid}", method: "post", ...variables, signal });

/**
 * Updates the chapter.<BR/>Note: Only accessible by Admins
 */
export const useUpdateChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateChapterError,
      UpdateChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateChapterError,
    UpdateChapterVariables
  >({
    mutationFn: (variables: UpdateChapterVariables) =>
      fetchUpdateChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteChapterPathParams = {
  chapterUid: string;
};

export type DeleteChapterError = Fetcher.ErrorWrapper<undefined>;

export type DeleteChapterVariables = {
  pathParams: DeleteChapterPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteChapter = (
  variables: DeleteChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteChapterError,
    undefined,
    {},
    {},
    DeleteChapterPathParams
  >({ url: "/chapters/{chapterUid}", method: "delete", ...variables, signal });

export const useDeleteChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteChapterError,
      DeleteChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteChapterError,
    DeleteChapterVariables
  >({
    mutationFn: (variables: DeleteChapterVariables) =>
      fetchDeleteChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SortChaptersError = Fetcher.ErrorWrapper<undefined>;

export type SortChaptersVariables = {
  body: Schemas.SortChapterDto;
} & PrimioContext["fetcherOptions"];

export const fetchSortChapters = (
  variables: SortChaptersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<undefined, SortChaptersError, Schemas.SortChapterDto, {}, {}, {}>(
    { url: "/chapters/sort", method: "post", ...variables, signal },
  );

export const useSortChapters = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SortChaptersError,
      SortChaptersVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    SortChaptersError,
    SortChaptersVariables
  >({
    mutationFn: (variables: SortChaptersVariables) =>
      fetchSortChapters({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PublishChapterPathParams = {
  chapterUid: string;
};

export type PublishChapterError = Fetcher.ErrorWrapper<undefined>;

export type PublishChapterVariables = {
  pathParams: PublishChapterPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchPublishChapter = (
  variables: PublishChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    PublishChapterError,
    undefined,
    {},
    {},
    PublishChapterPathParams
  >({
    url: "/chapters/{chapterUid}/publish",
    method: "post",
    ...variables,
    signal,
  });

export const usePublishChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PublishChapterError,
      PublishChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    PublishChapterError,
    PublishChapterVariables
  >({
    mutationFn: (variables: PublishChapterVariables) =>
      fetchPublishChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DraftChapterPathParams = {
  chapterUid: string;
};

export type DraftChapterError = Fetcher.ErrorWrapper<undefined>;

export type DraftChapterVariables = {
  pathParams: DraftChapterPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDraftChapter = (
  variables: DraftChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DraftChapterError,
    undefined,
    {},
    {},
    DraftChapterPathParams
  >({
    url: "/chapters/{chapterUid}/draft",
    method: "post",
    ...variables,
    signal,
  });

export const useDraftChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DraftChapterError,
      DraftChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DraftChapterError,
    DraftChapterVariables
  >({
    mutationFn: (variables: DraftChapterVariables) =>
      fetchDraftChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DuplicateChapterPathParams = {
  chapterUid: string;
};

export type DuplicateChapterError = Fetcher.ErrorWrapper<undefined>;

export type DuplicateChapterVariables = {
  pathParams: DuplicateChapterPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDuplicateChapter = (
  variables: DuplicateChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    DuplicateChapterError,
    undefined,
    {},
    {},
    DuplicateChapterPathParams
  >({
    url: "/chapters/{chapterUid}/duplicate",
    method: "post",
    ...variables,
    signal,
  });

export const useDuplicateChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DuplicateChapterError,
      DuplicateChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    DuplicateChapterError,
    DuplicateChapterVariables
  >({
    mutationFn: (variables: DuplicateChapterVariables) =>
      fetchDuplicateChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CopyChapterToPlaybookPathParams = {
  chapterUid: string;
  playbookUid: string;
};

export type CopyChapterToPlaybookError = Fetcher.ErrorWrapper<undefined>;

export type CopyChapterToPlaybookVariables = {
  pathParams: CopyChapterToPlaybookPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * given chapter to copy and playbook to copy into
 */
export const fetchCopyChapterToPlaybook = (
  variables: CopyChapterToPlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CopyChapterToPlaybookError,
    undefined,
    {},
    {},
    CopyChapterToPlaybookPathParams
  >({
    url: "/chapters/copied/chapter/{chapterUid}/playbook/{playbookUid}",
    method: "post",
    ...variables,
    signal,
  });

/**
 * given chapter to copy and playbook to copy into
 */
export const useCopyChapterToPlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CopyChapterToPlaybookError,
      CopyChapterToPlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CopyChapterToPlaybookError,
    CopyChapterToPlaybookVariables
  >({
    mutationFn: (variables: CopyChapterToPlaybookVariables) =>
      fetchCopyChapterToPlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCollectionsQueryParams = {
  ["collection-uids"]?: string;
  provider?: string;
  excludeMedia?: boolean;
  include?: string;
};

export type GetCollectionsError = Fetcher.ErrorWrapper<undefined>;

export type GetCollectionsResponse = Schemas.OmitTypeClass[];

export type GetCollectionsVariables = {
  queryParams?: GetCollectionsQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Returns the collections a user has access to.<br/>For normal users it returns only Published collections, for Admins, the collections in Draft state are also included.<BR/>Note: Cards are not included
 */
export const fetchGetCollections = (
  variables: GetCollectionsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetCollectionsResponse,
    GetCollectionsError,
    undefined,
    {},
    GetCollectionsQueryParams,
    {}
  >({ url: "/collections", method: "get", ...variables, signal });

/**
 * Returns the collections a user has access to.<br/>For normal users it returns only Published collections, for Admins, the collections in Draft state are also included.<BR/>Note: Cards are not included
 */
export const useGetCollections = <TData = GetCollectionsResponse>(
  variables: GetCollectionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCollectionsResponse,
      GetCollectionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetCollectionsResponse,
    GetCollectionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/collections",
      operationId: "getCollections",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCollections({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateCollectionError = Fetcher.ErrorWrapper<undefined>;

export type CreateCollectionVariables = {
  body: Schemas.CreateCollectionDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateCollection = (
  variables: CreateCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CreateCollectionError,
    Schemas.CreateCollectionDto,
    {},
    {},
    {}
  >({ url: "/collections", method: "post", ...variables, signal });

export const useCreateCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateCollectionError,
      CreateCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateCollectionError,
    CreateCollectionVariables
  >({
    mutationFn: (variables: CreateCollectionVariables) =>
      fetchCreateCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCollectionPathParams = {
  collectionUid: string;
};

export type GetCollectionQueryParams = {
  excludeMedia?: boolean;
  include?: string;
};

export type GetCollectionError = Fetcher.ErrorWrapper<undefined>;

export type GetCollectionVariables = {
  pathParams: GetCollectionPathParams;
  queryParams?: GetCollectionQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetCollection = (
  variables: GetCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Collection,
    GetCollectionError,
    undefined,
    {},
    GetCollectionQueryParams,
    GetCollectionPathParams
  >({
    url: "/collections/{collectionUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetCollection = <TData = Schemas.Collection>(
  variables: GetCollectionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Collection, GetCollectionError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Collection, GetCollectionError, TData>({
    queryKey: queryKeyFn({
      path: "/collections/{collectionUid}",
      operationId: "getCollection",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCollection({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateCollectionPathParams = {
  collectionUid: string;
};

export type UpdateCollectionError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCollectionVariables = {
  body?: Schemas.UpdateCollectionDto;
  pathParams: UpdateCollectionPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateCollection = (
  variables: UpdateCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateCollectionError,
    Schemas.UpdateCollectionDto,
    {},
    {},
    UpdateCollectionPathParams
  >({
    url: "/collections/{collectionUid}",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateCollectionError,
      UpdateCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateCollectionError,
    UpdateCollectionVariables
  >({
    mutationFn: (variables: UpdateCollectionVariables) =>
      fetchUpdateCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCollectionPathParams = {
  collectionUid: string;
};

export type DeleteCollectionError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCollectionVariables = {
  pathParams: DeleteCollectionPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteCollection = (
  variables: DeleteCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteCollectionError,
    undefined,
    {},
    {},
    DeleteCollectionPathParams
  >({
    url: "/collections/{collectionUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteCollectionError,
      DeleteCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteCollectionError,
    DeleteCollectionVariables
  >({
    mutationFn: (variables: DeleteCollectionVariables) =>
      fetchDeleteCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SortCollectionsError = Fetcher.ErrorWrapper<undefined>;

export type SortCollectionsVariables = {
  body: Schemas.SortCollectionDto;
} & PrimioContext["fetcherOptions"];

export const fetchSortCollections = (
  variables: SortCollectionsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    SortCollectionsError,
    Schemas.SortCollectionDto,
    {},
    {},
    {}
  >({ url: "/collections/sort", method: "post", ...variables, signal });

export const useSortCollections = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SortCollectionsError,
      SortCollectionsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    SortCollectionsError,
    SortCollectionsVariables
  >({
    mutationFn: (variables: SortCollectionsVariables) =>
      fetchSortCollections({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PublishCollectionPathParams = {
  collectionUid: string;
};

export type PublishCollectionError = Fetcher.ErrorWrapper<undefined>;

export type PublishCollectionVariables = {
  pathParams: PublishCollectionPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchPublishCollection = (
  variables: PublishCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    PublishCollectionError,
    undefined,
    {},
    {},
    PublishCollectionPathParams
  >({
    url: "/collections/{collectionUid}/publish",
    method: "post",
    ...variables,
    signal,
  });

export const usePublishCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PublishCollectionError,
      PublishCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    PublishCollectionError,
    PublishCollectionVariables
  >({
    mutationFn: (variables: PublishCollectionVariables) =>
      fetchPublishCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DraftCollectionPathParams = {
  collectionUid: string;
};

export type DraftCollectionError = Fetcher.ErrorWrapper<undefined>;

export type DraftCollectionVariables = {
  pathParams: DraftCollectionPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDraftCollection = (
  variables: DraftCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DraftCollectionError,
    undefined,
    {},
    {},
    DraftCollectionPathParams
  >({
    url: "/collections/{collectionUid}/draft",
    method: "post",
    ...variables,
    signal,
  });

export const useDraftCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DraftCollectionError,
      DraftCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DraftCollectionError,
    DraftCollectionVariables
  >({
    mutationFn: (variables: DraftCollectionVariables) =>
      fetchDraftCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DuplicateCollectionPathParams = {
  collectionUid: string;
};

export type DuplicateCollectionError = Fetcher.ErrorWrapper<undefined>;

export type DuplicateCollectionVariables = {
  pathParams: DuplicateCollectionPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDuplicateCollection = (
  variables: DuplicateCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    DuplicateCollectionError,
    undefined,
    {},
    {},
    DuplicateCollectionPathParams
  >({
    url: "/collections/{collectionUid}/duplicate",
    method: "post",
    ...variables,
    signal,
  });

export const useDuplicateCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DuplicateCollectionError,
      DuplicateCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    DuplicateCollectionError,
    DuplicateCollectionVariables
  >({
    mutationFn: (variables: DuplicateCollectionVariables) =>
      fetchDuplicateCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CopyCollectionToChapterPathParams = {
  collectionUid: string;
  chapterUid: string;
};

export type CopyCollectionToChapterError = Fetcher.ErrorWrapper<undefined>;

export type CopyCollectionToChapterVariables = {
  pathParams: CopyCollectionToChapterPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * given collection to copy and chapter to copy into
 */
export const fetchCopyCollectionToChapter = (
  variables: CopyCollectionToChapterVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CopyCollectionToChapterError,
    undefined,
    {},
    {},
    CopyCollectionToChapterPathParams
  >({
    url: "/collections/copied/collection/{collectionUid}/chapter/{chapterUid}",
    method: "post",
    ...variables,
    signal,
  });

/**
 * given collection to copy and chapter to copy into
 */
export const useCopyCollectionToChapter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CopyCollectionToChapterError,
      CopyCollectionToChapterVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CopyCollectionToChapterError,
    CopyCollectionToChapterVariables
  >({
    mutationFn: (variables: CopyCollectionToChapterVariables) =>
      fetchCopyCollectionToChapter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCardsQueryParams = {
  ["card-uids"]?: string;
  provider?: string;
  excludeMedia?: boolean;
  include?: string;
};

export type GetCardsError = Fetcher.ErrorWrapper<undefined>;

export type GetCardsResponse = Schemas.BaseCard[];

export type GetCardsVariables = {
  queryParams?: GetCardsQueryParams;
} & PrimioContext["fetcherOptions"];

/**
 * Returns the cards a user has access to.<br/>For normal users it returns only Published cards, for Admins, the cards in Draft state are also included.<BR/>Note: Collections are not included
 */
export const fetchGetCards = (
  variables: GetCardsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetCardsResponse,
    GetCardsError,
    undefined,
    {},
    GetCardsQueryParams,
    {}
  >({ url: "/cards", method: "get", ...variables, signal });

/**
 * Returns the cards a user has access to.<br/>For normal users it returns only Published cards, for Admins, the cards in Draft state are also included.<BR/>Note: Collections are not included
 */
export const useGetCards = <TData = GetCardsResponse>(
  variables: GetCardsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetCardsResponse, GetCardsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetCardsResponse, GetCardsError, TData>({
    queryKey: queryKeyFn({
      path: "/cards",
      operationId: "getCards",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCards({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateCardError = Fetcher.ErrorWrapper<undefined>;

export type CreateCardVariables = {
  body: Schemas.CreateCardDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateCard = (
  variables: CreateCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, CreateCardError, Schemas.CreateCardDto, {}, {}, {}>({
    url: "/cards",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, CreateCardError, CreateCardVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<string, CreateCardError, CreateCardVariables>({
    mutationFn: (variables: CreateCardVariables) =>
      fetchCreateCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSummarizedCardsProgressError = Fetcher.ErrorWrapper<undefined>;

export type GetSummarizedCardsProgressVariables =
  PrimioContext["fetcherOptions"];

export const fetchGetSummarizedCardsProgress = (
  variables: GetSummarizedCardsProgressVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.TotalTimeCardsModel,
    GetSummarizedCardsProgressError,
    undefined,
    {},
    {},
    {}
  >({ url: "/cards/progress/summary", method: "get", ...variables, signal });

export const useGetSummarizedCardsProgress = <
  TData = Schemas.TotalTimeCardsModel,
>(
  variables: GetSummarizedCardsProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TotalTimeCardsModel,
      GetSummarizedCardsProgressError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.TotalTimeCardsModel,
    GetSummarizedCardsProgressError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/cards/progress/summary",
      operationId: "getSummarizedCardsProgress",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSummarizedCardsProgress(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetCardPathParams = {
  cardUid: string;
};

export type GetCardQueryParams = {
  excludeMedia?: boolean;
  include?: string;
};

export type GetCardError = Fetcher.ErrorWrapper<undefined>;

export type GetCardVariables = {
  pathParams: GetCardPathParams;
  queryParams?: GetCardQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetCard = (
  variables: GetCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.BaseCard,
    GetCardError,
    undefined,
    {},
    GetCardQueryParams,
    GetCardPathParams
  >({ url: "/cards/{cardUid}", method: "get", ...variables, signal });

export const useGetCard = <TData = Schemas.BaseCard>(
  variables: GetCardVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BaseCard, GetCardError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.BaseCard, GetCardError, TData>({
    queryKey: queryKeyFn({
      path: "/cards/{cardUid}",
      operationId: "getCard",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCard({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateCardPathParams = {
  cardUid: string;
};

export type UpdateCardError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCardVariables = {
  body?: Schemas.UpdateCardDto;
  pathParams: UpdateCardPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Updates the card.<br/>If a version of this card already exists in a Draft state, we'll update that version. Otherwise a new version is created in Draft state.
 */
export const fetchUpdateCard = (
  variables: UpdateCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateCardError,
    Schemas.UpdateCardDto,
    {},
    {},
    UpdateCardPathParams
  >({ url: "/cards/{cardUid}", method: "post", ...variables, signal });

/**
 * Updates the card.<br/>If a version of this card already exists in a Draft state, we'll update that version. Otherwise a new version is created in Draft state.
 */
export const useUpdateCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateCardError,
      UpdateCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateCardError,
    UpdateCardVariables
  >({
    mutationFn: (variables: UpdateCardVariables) =>
      fetchUpdateCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCardPathParams = {
  cardUid: string;
};

export type DeleteCardQueryParams = {
  version?: number;
};

export type DeleteCardError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCardVariables = {
  pathParams: DeleteCardPathParams;
  queryParams?: DeleteCardQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteCard = (
  variables: DeleteCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteCardError,
    undefined,
    {},
    DeleteCardQueryParams,
    DeleteCardPathParams
  >({ url: "/cards/{cardUid}", method: "delete", ...variables, signal });

export const useDeleteCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteCardError,
      DeleteCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteCardError,
    DeleteCardVariables
  >({
    mutationFn: (variables: DeleteCardVariables) =>
      fetchDeleteCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SortCardsError = Fetcher.ErrorWrapper<undefined>;

export type SortCardsVariables = {
  body: Schemas.SortCardDto;
} & PrimioContext["fetcherOptions"];

export const fetchSortCards = (
  variables: SortCardsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<undefined, SortCardsError, Schemas.SortCardDto, {}, {}, {}>({
    url: "/cards/sort",
    method: "post",
    ...variables,
    signal,
  });

export const useSortCards = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SortCardsError,
      SortCardsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<undefined, SortCardsError, SortCardsVariables>({
    mutationFn: (variables: SortCardsVariables) =>
      fetchSortCards({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BulkPublishCardsError = Fetcher.ErrorWrapper<undefined>;

export type BulkPublishCardsVariables = {
  body: Schemas.CardsArrayDto;
} & PrimioContext["fetcherOptions"];

/**
 * Receives list of card uuids and versions to publish
 */
export const fetchBulkPublishCards = (
  variables: BulkPublishCardsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    BulkPublishCardsError,
    Schemas.CardsArrayDto,
    {},
    {},
    {}
  >({ url: "/cards/published", method: "post", ...variables, signal });

/**
 * Receives list of card uuids and versions to publish
 */
export const useBulkPublishCards = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      BulkPublishCardsError,
      BulkPublishCardsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    BulkPublishCardsError,
    BulkPublishCardsVariables
  >({
    mutationFn: (variables: BulkPublishCardsVariables) =>
      fetchBulkPublishCards({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OverwriteCardPathParams = {
  cardUid: string;
};

export type OverwriteCardError = Fetcher.ErrorWrapper<undefined>;

export type OverwriteCardVariables = {
  body: Schemas.CreateCardDto;
  pathParams: OverwriteCardPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchOverwriteCard = (
  variables: OverwriteCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    OverwriteCardError,
    Schemas.CreateCardDto,
    {},
    {},
    OverwriteCardPathParams
  >({
    url: "/cards/{cardUid}/overwrite",
    method: "post",
    ...variables,
    signal,
  });

export const useOverwriteCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OverwriteCardError,
      OverwriteCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    OverwriteCardError,
    OverwriteCardVariables
  >({
    mutationFn: (variables: OverwriteCardVariables) =>
      fetchOverwriteCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SaveUserFeedbackPathParams = {
  cardUid: string;
};

export type SaveUserFeedbackError = Fetcher.ErrorWrapper<undefined>;

export type SaveUserFeedbackVariables = {
  body: Schemas.CreateCardFeedbackDto;
  pathParams: SaveUserFeedbackPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchSaveUserFeedback = (
  variables: SaveUserFeedbackVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    SaveUserFeedbackError,
    Schemas.CreateCardFeedbackDto,
    {},
    {},
    SaveUserFeedbackPathParams
  >({ url: "/cards/{cardUid}/feedback", method: "post", ...variables, signal });

export const useSaveUserFeedback = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SaveUserFeedbackError,
      SaveUserFeedbackVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    SaveUserFeedbackError,
    SaveUserFeedbackVariables
  >({
    mutationFn: (variables: SaveUserFeedbackVariables) =>
      fetchSaveUserFeedback({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PublishCardPathParams = {
  cardUid: string;
  version: number;
};

export type PublishCardError = Fetcher.ErrorWrapper<undefined>;

export type PublishCardVariables = {
  pathParams: PublishCardPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Publishes the specified card version.<BR/>Note: Only accessible by Admins
 */
export const fetchPublishCard = (
  variables: PublishCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    PublishCardError,
    undefined,
    {},
    {},
    PublishCardPathParams
  >({
    url: "/cards/published/{cardUid}/versions/{version}",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Publishes the specified card version.<BR/>Note: Only accessible by Admins
 */
export const usePublishCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PublishCardError,
      PublishCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    PublishCardError,
    PublishCardVariables
  >({
    mutationFn: (variables: PublishCardVariables) =>
      fetchPublishCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DraftCardPathParams = {
  cardUid: string;
};

export type DraftCardQueryParams = {
  version: number;
};

export type DraftCardError = Fetcher.ErrorWrapper<undefined>;

export type DraftCardVariables = {
  pathParams: DraftCardPathParams;
  queryParams: DraftCardQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchDraftCard = (
  variables: DraftCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DraftCardError,
    undefined,
    {},
    DraftCardQueryParams,
    DraftCardPathParams
  >({ url: "/cards/{cardUid}/draft", method: "post", ...variables, signal });

export const useDraftCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DraftCardError,
      DraftCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<undefined, DraftCardError, DraftCardVariables>({
    mutationFn: (variables: DraftCardVariables) =>
      fetchDraftCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DuplicateCardPathParams = {
  cardUid: string;
};

export type DuplicateCardError = Fetcher.ErrorWrapper<undefined>;

export type DuplicateCardVariables = {
  pathParams: DuplicateCardPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDuplicateCard = (
  variables: DuplicateCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    DuplicateCardError,
    undefined,
    {},
    {},
    DuplicateCardPathParams
  >({
    url: "/cards/{cardUid}/duplicate",
    method: "post",
    ...variables,
    signal,
  });

export const useDuplicateCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DuplicateCardError,
      DuplicateCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    DuplicateCardError,
    DuplicateCardVariables
  >({
    mutationFn: (variables: DuplicateCardVariables) =>
      fetchDuplicateCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CopyCardToCollectionPathParams = {
  collectionUid: string;
  cardUid: string;
};

export type CopyCardToCollectionError = Fetcher.ErrorWrapper<undefined>;

export type CopyCardToCollectionVariables = {
  pathParams: CopyCardToCollectionPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * given card to copy and collection to copy into
 */
export const fetchCopyCardToCollection = (
  variables: CopyCardToCollectionVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CopyCardToCollectionError,
    undefined,
    {},
    {},
    CopyCardToCollectionPathParams
  >({
    url: "/cards/copied/card/{cardUid}/collection/{collectionUid}",
    method: "post",
    ...variables,
    signal,
  });

/**
 * given card to copy and collection to copy into
 */
export const useCopyCardToCollection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CopyCardToCollectionError,
      CopyCardToCollectionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CopyCardToCollectionError,
    CopyCardToCollectionVariables
  >({
    mutationFn: (variables: CopyCardToCollectionVariables) =>
      fetchCopyCardToCollection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateSourceError = Fetcher.ErrorWrapper<undefined>;

export type CreateSourceVariables = {
  body: Schemas.CreateSourceDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateSource = (
  variables: CreateSourceVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Source,
    CreateSourceError,
    Schemas.CreateSourceDto,
    {},
    {},
    {}
  >({ url: "/sources", method: "post", ...variables, signal });

export const useCreateSource = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Source,
      CreateSourceError,
      CreateSourceVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Source,
    CreateSourceError,
    CreateSourceVariables
  >({
    mutationFn: (variables: CreateSourceVariables) =>
      fetchCreateSource({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSourcesError = Fetcher.ErrorWrapper<undefined>;

export type GetSourcesResponse = Schemas.Source[];

export type GetSourcesVariables = PrimioContext["fetcherOptions"];

export const fetchGetSources = (
  variables: GetSourcesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<GetSourcesResponse, GetSourcesError, undefined, {}, {}, {}>({
    url: "/sources",
    method: "get",
    ...variables,
    signal,
  });

export const useGetSources = <TData = GetSourcesResponse>(
  variables: GetSourcesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetSourcesResponse, GetSourcesError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetSourcesResponse, GetSourcesError, TData>({
    queryKey: queryKeyFn({
      path: "/sources",
      operationId: "getSources",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSources({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetSourcePathParams = {
  sourceUid: string;
};

export type GetSourceError = Fetcher.ErrorWrapper<undefined>;

export type GetSourceVariables = {
  pathParams: GetSourcePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetSource = (
  variables: GetSourceVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Source,
    GetSourceError,
    undefined,
    {},
    {},
    GetSourcePathParams
  >({ url: "/sources/{sourceUid}", method: "get", ...variables, signal });

export const useGetSource = <TData = Schemas.Source>(
  variables: GetSourceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Source, GetSourceError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Source, GetSourceError, TData>({
    queryKey: queryKeyFn({
      path: "/sources/{sourceUid}",
      operationId: "getSource",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSource({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateSourcePathParams = {
  sourceUid: string;
};

export type UpdateSourceError = Fetcher.ErrorWrapper<undefined>;

export type UpdateSourceVariables = {
  body?: Schemas.UpdateSourceDto;
  pathParams: UpdateSourcePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateSource = (
  variables: UpdateSourceVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Source,
    UpdateSourceError,
    Schemas.UpdateSourceDto,
    {},
    {},
    UpdateSourcePathParams
  >({ url: "/sources/{sourceUid}", method: "patch", ...variables, signal });

export const useUpdateSource = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Source,
      UpdateSourceError,
      UpdateSourceVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Source,
    UpdateSourceError,
    UpdateSourceVariables
  >({
    mutationFn: (variables: UpdateSourceVariables) =>
      fetchUpdateSource({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteSourcePathParams = {
  sourceUid: string;
};

export type DeleteSourceError = Fetcher.ErrorWrapper<undefined>;

export type DeleteSourceVariables = {
  pathParams: DeleteSourcePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteSource = (
  variables: DeleteSourceVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteSourceError,
    undefined,
    {},
    {},
    DeleteSourcePathParams
  >({ url: "/sources/{sourceUid}", method: "delete", ...variables, signal });

export const useDeleteSource = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteSourceError,
      DeleteSourceVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteSourceError,
    DeleteSourceVariables
  >({
    mutationFn: (variables: DeleteSourceVariables) =>
      fetchDeleteSource({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateCategoryError = Fetcher.ErrorWrapper<undefined>;

export type CreateCategoryVariables = {
  body: Schemas.CreateCategoryDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateCategory = (
  variables: CreateCategoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Category,
    CreateCategoryError,
    Schemas.CreateCategoryDto,
    {},
    {},
    {}
  >({ url: "/categories", method: "post", ...variables, signal });

export const useCreateCategory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Category,
      CreateCategoryError,
      CreateCategoryVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Category,
    CreateCategoryError,
    CreateCategoryVariables
  >({
    mutationFn: (variables: CreateCategoryVariables) =>
      fetchCreateCategory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCategoriesQueryParams = {
  excludeMedia?: boolean;
};

export type GetCategoriesError = Fetcher.ErrorWrapper<undefined>;

export type GetCategoriesResponse = Schemas.Category[];

export type GetCategoriesVariables = {
  queryParams?: GetCategoriesQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetCategories = (
  variables: GetCategoriesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetCategoriesResponse,
    GetCategoriesError,
    undefined,
    {},
    GetCategoriesQueryParams,
    {}
  >({ url: "/categories", method: "get", ...variables, signal });

export const useGetCategories = <TData = GetCategoriesResponse>(
  variables: GetCategoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCategoriesResponse,
      GetCategoriesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetCategoriesResponse, GetCategoriesError, TData>({
    queryKey: queryKeyFn({
      path: "/categories",
      operationId: "getCategories",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCategories({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetCategoryPathParams = {
  /**
   * The UUID of the category. You can use a specific UUID or the FAKE_CATEGORY_UID "00000000-0000-4000-A000-000000000000".
   *
   * @format uuid
   */
  categoryUid: string;
};

export type GetCategoryQueryParams = {
  excludeMedia?: boolean;
  excludePlaybooksInLearningPaths?: boolean;
  include?: string;
};

export type GetCategoryError = Fetcher.ErrorWrapper<undefined>;

export type GetCategoryVariables = {
  pathParams: GetCategoryPathParams;
  queryParams?: GetCategoryQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetCategory = (
  variables: GetCategoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Category,
    GetCategoryError,
    undefined,
    {},
    GetCategoryQueryParams,
    GetCategoryPathParams
  >({ url: "/categories/{categoryUid}", method: "get", ...variables, signal });

export const useGetCategory = <TData = Schemas.Category>(
  variables: GetCategoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Category, GetCategoryError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Category, GetCategoryError, TData>({
    queryKey: queryKeyFn({
      path: "/categories/{categoryUid}",
      operationId: "getCategory",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCategory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateCategoryPathParams = {
  categoryUid: string;
};

export type UpdateCategoryError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCategoryVariables = {
  body?: Schemas.UpdateCategoryDto;
  pathParams: UpdateCategoryPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateCategory = (
  variables: UpdateCategoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Category,
    UpdateCategoryError,
    Schemas.UpdateCategoryDto,
    {},
    {},
    UpdateCategoryPathParams
  >({
    url: "/categories/{categoryUid}",
    method: "patch",
    ...variables,
    signal,
  });

export const useUpdateCategory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Category,
      UpdateCategoryError,
      UpdateCategoryVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.Category,
    UpdateCategoryError,
    UpdateCategoryVariables
  >({
    mutationFn: (variables: UpdateCategoryVariables) =>
      fetchUpdateCategory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCategoryPathParams = {
  categoryUid: string;
};

export type DeleteCategoryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCategoryVariables = {
  pathParams: DeleteCategoryPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteCategory = (
  variables: DeleteCategoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteCategoryError,
    undefined,
    {},
    {},
    DeleteCategoryPathParams
  >({
    url: "/categories/{categoryUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteCategory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteCategoryError,
      DeleteCategoryVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteCategoryError,
    DeleteCategoryVariables
  >({
    mutationFn: (variables: DeleteCategoryVariables) =>
      fetchDeleteCategory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddPlaybookToCategoryPathParams = {
  categoryUid: string;
  playbookUid: string;
};

export type AddPlaybookToCategoryError = Fetcher.ErrorWrapper<undefined>;

export type AddPlaybookToCategoryVariables = {
  pathParams: AddPlaybookToCategoryPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchAddPlaybookToCategory = (
  variables: AddPlaybookToCategoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    AddPlaybookToCategoryError,
    undefined,
    {},
    {},
    AddPlaybookToCategoryPathParams
  >({
    url: "/categories/{categoryUid}/playbooks/{playbookUid}",
    method: "post",
    ...variables,
    signal,
  });

export const useAddPlaybookToCategory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddPlaybookToCategoryError,
      AddPlaybookToCategoryVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    AddPlaybookToCategoryError,
    AddPlaybookToCategoryVariables
  >({
    mutationFn: (variables: AddPlaybookToCategoryVariables) =>
      fetchAddPlaybookToCategory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemovePlaybookFromCategoryPathParams = {
  categoryUid: string;
  playbookUid: string;
};

export type RemovePlaybookFromCategoryError = Fetcher.ErrorWrapper<undefined>;

export type RemovePlaybookFromCategoryVariables = {
  pathParams: RemovePlaybookFromCategoryPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchRemovePlaybookFromCategory = (
  variables: RemovePlaybookFromCategoryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    RemovePlaybookFromCategoryError,
    undefined,
    {},
    {},
    RemovePlaybookFromCategoryPathParams
  >({
    url: "/categories/{categoryUid}/playbooks/{playbookUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useRemovePlaybookFromCategory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemovePlaybookFromCategoryError,
      RemovePlaybookFromCategoryVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    RemovePlaybookFromCategoryError,
    RemovePlaybookFromCategoryVariables
  >({
    mutationFn: (variables: RemovePlaybookFromCategoryVariables) =>
      fetchRemovePlaybookFromCategory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetFilePathParams = {
  key: string;
};

export type GetFileError = Fetcher.ErrorWrapper<undefined>;

export type GetFileVariables = {
  pathParams: GetFilePathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Gets the file path from the key
 */
export const fetchGetFile = (
  variables: GetFileVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, GetFileError, undefined, {}, {}, GetFilePathParams>({
    url: "/media/{key}/signed-url",
    method: "get",
    ...variables,
    signal,
  });

/**
 * Gets the file path from the key
 */
export const useGetFile = <TData = string>(
  variables: GetFileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetFileError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<string, GetFileError, TData>({
    queryKey: queryKeyFn({
      path: "/media/{key}/signed-url",
      operationId: "getFile",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFile({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UploadMediaError = Fetcher.ErrorWrapper<undefined>;

export type UploadMediaVariables = {
  body: Schemas.UploadMediaDto;
} & PrimioContext["fetcherOptions"];

/**
 * Creates a new media object after uploading the file to S3.<BR/>Note: Only accessible by Admins
 */
export const fetchUploadMedia = (
  variables: UploadMediaVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, UploadMediaError, Schemas.UploadMediaDto, {}, {}, {}>({
    url: "/media/upload",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Creates a new media object after uploading the file to S3.<BR/>Note: Only accessible by Admins
 */
export const useUploadMedia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      UploadMediaError,
      UploadMediaVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<string, UploadMediaError, UploadMediaVariables>(
    {
      mutationFn: (variables: UploadMediaVariables) =>
        fetchUploadMedia({ ...fetcherOptions, ...variables }),
      ...options,
    },
  );
};

export type CreateMediaError = Fetcher.ErrorWrapper<undefined>;

export type CreateMediaVariables = {
  body: Schemas.CreateMediaDto;
} & PrimioContext["fetcherOptions"];

/**
 * Creates a new media object referring to remote content.<BR/>Note: Only accessible by Admins
 */
export const fetchCreateMedia = (
  variables: CreateMediaVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, CreateMediaError, Schemas.CreateMediaDto, {}, {}, {}>({
    url: "/media",
    method: "post",
    ...variables,
    signal,
  });

/**
 * Creates a new media object referring to remote content.<BR/>Note: Only accessible by Admins
 */
export const useCreateMedia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateMediaError,
      CreateMediaVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<string, CreateMediaError, CreateMediaVariables>(
    {
      mutationFn: (variables: CreateMediaVariables) =>
        fetchCreateMedia({ ...fetcherOptions, ...variables }),
      ...options,
    },
  );
};

export type GetMediaPathParams = {
  mediaUid: string;
};

export type GetMediaError = Fetcher.ErrorWrapper<undefined>;

export type GetMediaVariables = {
  pathParams: GetMediaPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Returns requested media object
 */
export const fetchGetMedia = (
  variables: GetMediaVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.Media,
    GetMediaError,
    undefined,
    {},
    {},
    GetMediaPathParams
  >({ url: "/media/{mediaUid}", method: "get", ...variables, signal });

/**
 * Returns requested media object
 */
export const useGetMedia = <TData = Schemas.Media>(
  variables: GetMediaVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Media, GetMediaError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.Media, GetMediaError, TData>({
    queryKey: queryKeyFn({
      path: "/media/{mediaUid}",
      operationId: "getMedia",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMedia({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateMediaPathParams = {
  mediaUid: string;
};

export type UpdateMediaError = Fetcher.ErrorWrapper<undefined>;

export type UpdateMediaVariables = {
  body?: Schemas.UpdateMediaDto;
  pathParams: UpdateMediaPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * update media
 */
export const fetchUpdateMedia = (
  variables: UpdateMediaVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateMediaError,
    Schemas.UpdateMediaDto,
    {},
    {},
    UpdateMediaPathParams
  >({ url: "/media/{mediaUid}", method: "post", ...variables, signal });

/**
 * update media
 */
export const useUpdateMedia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateMediaError,
      UpdateMediaVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateMediaError,
    UpdateMediaVariables
  >({
    mutationFn: (variables: UpdateMediaVariables) =>
      fetchUpdateMedia({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetImportMediaPathParams = {
  mediaUid: string;
};

export type GetImportMediaError = Fetcher.ErrorWrapper<undefined>;

export type GetImportMediaVariables = {
  pathParams: GetImportMediaPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * given media is saved as s3x it can be fetched from foreign server
 */
export const fetchGetImportMedia = (
  variables: GetImportMediaVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    GetImportMediaError,
    undefined,
    {},
    {},
    GetImportMediaPathParams
  >({ url: "/media/{mediaUid}/import", method: "get", ...variables, signal });

/**
 * given media is saved as s3x it can be fetched from foreign server
 */
export const useGetImportMedia = <TData = string>(
  variables: GetImportMediaVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetImportMediaError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<string, GetImportMediaError, TData>({
    queryKey: queryKeyFn({
      path: "/media/{mediaUid}/import",
      operationId: "getImportMedia",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetImportMedia({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ScheduleNotificationError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleNotificationVariables = {
  body: Schemas.CreateScheduledNotificationDto;
} & PrimioContext["fetcherOptions"];

export const fetchScheduleNotification = (
  variables: ScheduleNotificationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    ScheduleNotificationError,
    Schemas.CreateScheduledNotificationDto,
    {},
    {},
    {}
  >({ url: "/notifications/scheduler", method: "post", ...variables, signal });

export const useScheduleNotification = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ScheduleNotificationError,
      ScheduleNotificationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    ScheduleNotificationError,
    ScheduleNotificationVariables
  >({
    mutationFn: (variables: ScheduleNotificationVariables) =>
      fetchScheduleNotification({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetScheduledNotificationsQueryParams = {
  /**
   * @format date-time
   * @default 2024-01-31T00:00:00.000Z
   */
  from: string;
};

export type GetScheduledNotificationsError = Fetcher.ErrorWrapper<undefined>;

export type GetScheduledNotificationsResponse =
  Schemas.BaseScheduledNotification[];

export type GetScheduledNotificationsVariables = {
  queryParams: GetScheduledNotificationsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetScheduledNotifications = (
  variables: GetScheduledNotificationsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetScheduledNotificationsResponse,
    GetScheduledNotificationsError,
    undefined,
    {},
    GetScheduledNotificationsQueryParams,
    {}
  >({ url: "/notifications/scheduler", method: "get", ...variables, signal });

export const useGetScheduledNotifications = <
  TData = GetScheduledNotificationsResponse,
>(
  variables: GetScheduledNotificationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetScheduledNotificationsResponse,
      GetScheduledNotificationsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetScheduledNotificationsResponse,
    GetScheduledNotificationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/notifications/scheduler",
      operationId: "getScheduledNotifications",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetScheduledNotifications(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ScheduledNotificationSummaryError = Fetcher.ErrorWrapper<undefined>;

export type ScheduledNotificationSummaryVariables =
  PrimioContext["fetcherOptions"];

export const fetchScheduledNotificationSummary = (
  variables: ScheduledNotificationSummaryVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.ScheduledNotificationSummary,
    ScheduledNotificationSummaryError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/notifications/scheduler/summary",
    method: "get",
    ...variables,
    signal,
  });

export const useScheduledNotificationSummary = <
  TData = Schemas.ScheduledNotificationSummary,
>(
  variables: ScheduledNotificationSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ScheduledNotificationSummary,
      ScheduledNotificationSummaryError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.ScheduledNotificationSummary,
    ScheduledNotificationSummaryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/notifications/scheduler/summary",
      operationId: "scheduledNotificationSummary",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchScheduledNotificationSummary(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DeleteScheduledNotificationPathParams = {
  scheduledNotificationUid: string;
};

export type DeleteScheduledNotificationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteScheduledNotificationVariables = {
  pathParams: DeleteScheduledNotificationPathParams;
} & PrimioContext["fetcherOptions"];

/**
 * Note: Only possible to delete a scheduled notification that is not sent
 */
export const fetchDeleteScheduledNotification = (
  variables: DeleteScheduledNotificationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteScheduledNotificationError,
    undefined,
    {},
    {},
    DeleteScheduledNotificationPathParams
  >({
    url: "/notifications/scheduler/{scheduledNotificationUid}",
    method: "delete",
    ...variables,
    signal,
  });

/**
 * Note: Only possible to delete a scheduled notification that is not sent
 */
export const useDeleteScheduledNotification = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteScheduledNotificationError,
      DeleteScheduledNotificationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteScheduledNotificationError,
    DeleteScheduledNotificationVariables
  >({
    mutationFn: (variables: DeleteScheduledNotificationVariables) =>
      fetchDeleteScheduledNotification({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateNotificationPathParams = {
  notificationUid: string;
};

export type UpdateNotificationQueryParams = {
  action: "RECEIVED" | "READ" | "COMPLETED";
};

export type UpdateNotificationError = Fetcher.ErrorWrapper<undefined>;

export type UpdateNotificationVariables = {
  pathParams: UpdateNotificationPathParams;
  queryParams: UpdateNotificationQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateNotification = (
  variables: UpdateNotificationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateNotificationError,
    undefined,
    {},
    UpdateNotificationQueryParams,
    UpdateNotificationPathParams
  >({
    url: "/notifications/{notificationUid}",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateNotification = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateNotificationError,
      UpdateNotificationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateNotificationError,
    UpdateNotificationVariables
  >({
    mutationFn: (variables: UpdateNotificationVariables) =>
      fetchUpdateNotification({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetNotificationsError = Fetcher.ErrorWrapper<undefined>;

export type GetNotificationsResponse = Schemas.Notification[];

export type GetNotificationsVariables = PrimioContext["fetcherOptions"];

export const fetchGetNotifications = (
  variables: GetNotificationsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetNotificationsResponse,
    GetNotificationsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/notifications", method: "get", ...variables, signal });

export const useGetNotifications = <TData = GetNotificationsResponse>(
  variables: GetNotificationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetNotificationsResponse,
      GetNotificationsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetNotificationsResponse,
    GetNotificationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/notifications",
      operationId: "getNotifications",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetNotifications({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetNotificationsForScheduledNotificationPathParams = {
  scheduledNotificationUid: string;
};

export type GetNotificationsForScheduledNotificationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetNotificationsForScheduledNotificationResponse =
  Schemas.Notification[];

export type GetNotificationsForScheduledNotificationVariables = {
  pathParams: GetNotificationsForScheduledNotificationPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetNotificationsForScheduledNotification = (
  variables: GetNotificationsForScheduledNotificationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetNotificationsForScheduledNotificationResponse,
    GetNotificationsForScheduledNotificationError,
    undefined,
    {},
    {},
    GetNotificationsForScheduledNotificationPathParams
  >({
    url: "/notifications/scheduled-notification/{scheduledNotificationUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetNotificationsForScheduledNotification = <
  TData = GetNotificationsForScheduledNotificationResponse,
>(
  variables: GetNotificationsForScheduledNotificationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetNotificationsForScheduledNotificationResponse,
      GetNotificationsForScheduledNotificationError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetNotificationsForScheduledNotificationResponse,
    GetNotificationsForScheduledNotificationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/notifications/scheduled-notification/{scheduledNotificationUid}",
      operationId: "getNotificationsForScheduledNotification",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetNotificationsForScheduledNotification(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetQrCodesError = Fetcher.ErrorWrapper<undefined>;

export type GetQrCodesResponse = Schemas.QrCode[];

export type GetQrCodesVariables = PrimioContext["fetcherOptions"];

export const fetchGetQrCodes = (
  variables: GetQrCodesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<GetQrCodesResponse, GetQrCodesError, undefined, {}, {}, {}>({
    url: "/qr-codes",
    method: "get",
    ...variables,
    signal,
  });

export const useGetQrCodes = <TData = GetQrCodesResponse>(
  variables: GetQrCodesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetQrCodesResponse, GetQrCodesError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetQrCodesResponse, GetQrCodesError, TData>({
    queryKey: queryKeyFn({
      path: "/qr-codes",
      operationId: "getQrCodes",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetQrCodes({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type CreateQrCodeVariables = {
  body: Schemas.CreateQrCodeDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateQrCode = (
  variables: CreateQrCodeVariables,
  signal?: AbortSignal,
) =>
  primioFetch<string, CreateQrCodeError, Schemas.CreateQrCodeDto, {}, {}, {}>({
    url: "/qr-codes",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateQrCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateQrCodeError,
      CreateQrCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateQrCodeError,
    CreateQrCodeVariables
  >({
    mutationFn: (variables: CreateQrCodeVariables) =>
      fetchCreateQrCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetQrCodePathParams = {
  qrCodeUid: string;
};

export type GetQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type GetQrCodeVariables = {
  pathParams: GetQrCodePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetQrCode = (
  variables: GetQrCodeVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.QrCode,
    GetQrCodeError,
    undefined,
    {},
    {},
    GetQrCodePathParams
  >({ url: "/qr-codes/{qrCodeUid}", method: "get", ...variables, signal });

export const useGetQrCode = <TData = Schemas.QrCode>(
  variables: GetQrCodeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.QrCode, GetQrCodeError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.QrCode, GetQrCodeError, TData>({
    queryKey: queryKeyFn({
      path: "/qr-codes/{qrCodeUid}",
      operationId: "getQrCode",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetQrCode({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateQrCodePathParams = {
  qrCodeUid: string;
};

export type UpdateQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type UpdateQrCodeVariables = {
  body?: Schemas.UpdateQrCodeDto;
  pathParams: UpdateQrCodePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateQrCode = (
  variables: UpdateQrCodeVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateQrCodeError,
    Schemas.UpdateQrCodeDto,
    {},
    {},
    UpdateQrCodePathParams
  >({ url: "/qr-codes/{qrCodeUid}", method: "post", ...variables, signal });

export const useUpdateQrCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateQrCodeError,
      UpdateQrCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateQrCodeError,
    UpdateQrCodeVariables
  >({
    mutationFn: (variables: UpdateQrCodeVariables) =>
      fetchUpdateQrCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteQrCodePathParams = {
  qrCodeUid: string;
};

export type DeleteQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type DeleteQrCodeVariables = {
  pathParams: DeleteQrCodePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteQrCode = (
  variables: DeleteQrCodeVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteQrCodeError,
    undefined,
    {},
    {},
    DeleteQrCodePathParams
  >({ url: "/qr-codes/{qrCodeUid}", method: "delete", ...variables, signal });

export const useDeleteQrCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteQrCodeError,
      DeleteQrCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteQrCodeError,
    DeleteQrCodeVariables
  >({
    mutationFn: (variables: DeleteQrCodeVariables) =>
      fetchDeleteQrCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PublishQrCodePathParams = {
  qrCodeUid: string;
};

export type PublishQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type PublishQrCodeVariables = {
  pathParams: PublishQrCodePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchPublishQrCode = (
  variables: PublishQrCodeVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    PublishQrCodeError,
    undefined,
    {},
    {},
    PublishQrCodePathParams
  >({
    url: "/qr-codes/{qrCodeUid}/publish",
    method: "post",
    ...variables,
    signal,
  });

export const usePublishQrCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PublishQrCodeError,
      PublishQrCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    PublishQrCodeError,
    PublishQrCodeVariables
  >({
    mutationFn: (variables: PublishQrCodeVariables) =>
      fetchPublishQrCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DraftQrCodePathParams = {
  qrCodeUid: string;
};

export type DraftQrCodeError = Fetcher.ErrorWrapper<undefined>;

export type DraftQrCodeVariables = {
  pathParams: DraftQrCodePathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDraftQrCode = (
  variables: DraftQrCodeVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DraftQrCodeError,
    undefined,
    {},
    {},
    DraftQrCodePathParams
  >({
    url: "/qr-codes/{qrCodeUid}/draft",
    method: "post",
    ...variables,
    signal,
  });

export const useDraftQrCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DraftQrCodeError,
      DraftQrCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DraftQrCodeError,
    DraftQrCodeVariables
  >({
    mutationFn: (variables: DraftQrCodeVariables) =>
      fetchDraftQrCode({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPublicQrCodeContentPathParams = {
  qrCodeUid: string;
};

export type GetPublicQrCodeContentError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicQrCodeContentVariables = {
  pathParams: GetPublicQrCodeContentPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetPublicQrCodeContent = (
  variables: GetPublicQrCodeContentVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.QrCodeContent,
    GetPublicQrCodeContentError,
    undefined,
    {},
    {},
    GetPublicQrCodeContentPathParams
  >({
    url: "/qr-codes/{qrCodeUid}/content",
    method: "get",
    ...variables,
    signal,
  });

export const useGetPublicQrCodeContent = <TData = Schemas.QrCodeContent>(
  variables: GetPublicQrCodeContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.QrCodeContent,
      GetPublicQrCodeContentError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.QrCodeContent,
    GetPublicQrCodeContentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/qr-codes/{qrCodeUid}/content",
      operationId: "getPublicQrCodeContent",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPublicQrCodeContent({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetQrCodeContentPathParams = {
  qrCodeUid: string;
};

export type GetQrCodeContentError = Fetcher.ErrorWrapper<undefined>;

export type GetQrCodeContentVariables = {
  pathParams: GetQrCodeContentPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetQrCodeContent = (
  variables: GetQrCodeContentVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.QrCodeContent,
    GetQrCodeContentError,
    undefined,
    {},
    {},
    GetQrCodeContentPathParams
  >({
    url: "/qr-codes/{qrCodeUid}/content/user",
    method: "get",
    ...variables,
    signal,
  });

export const useGetQrCodeContent = <TData = Schemas.QrCodeContent>(
  variables: GetQrCodeContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.QrCodeContent,
      GetQrCodeContentError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.QrCodeContent,
    GetQrCodeContentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/qr-codes/{qrCodeUid}/content/user",
      operationId: "getQrCodeContent",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetQrCodeContent({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUserGroupsQueryParams = {
  shouldExcludeUsers?: boolean;
};

export type GetUserGroupsError = Fetcher.ErrorWrapper<undefined>;

export type GetUserGroupsResponse = Schemas.UserGroupModel[];

export type GetUserGroupsVariables = {
  queryParams?: GetUserGroupsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserGroups = (
  variables: GetUserGroupsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUserGroupsResponse,
    GetUserGroupsError,
    undefined,
    {},
    GetUserGroupsQueryParams,
    {}
  >({ url: "/user-groups", method: "get", ...variables, signal });

export const useGetUserGroups = <TData = GetUserGroupsResponse>(
  variables: GetUserGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUserGroupsResponse,
      GetUserGroupsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<GetUserGroupsResponse, GetUserGroupsError, TData>({
    queryKey: queryKeyFn({
      path: "/user-groups",
      operationId: "getUserGroups",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserGroups({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateUserGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserGroupVariables = {
  body: Schemas.CreateUserGroupDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateUserGroup = (
  variables: CreateUserGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CreateUserGroupError,
    Schemas.CreateUserGroupDto,
    {},
    {},
    {}
  >({ url: "/user-groups", method: "post", ...variables, signal });

export const useCreateUserGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateUserGroupError,
      CreateUserGroupVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateUserGroupError,
    CreateUserGroupVariables
  >({
    mutationFn: (variables: CreateUserGroupVariables) =>
      fetchCreateUserGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserGroupPathParams = {
  groupName: string;
};

export type GetUserGroupError = Fetcher.ErrorWrapper<undefined>;

export type GetUserGroupVariables = {
  pathParams: GetUserGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserGroup = (
  variables: GetUserGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserGroupModel,
    GetUserGroupError,
    undefined,
    {},
    {},
    GetUserGroupPathParams
  >({ url: "/user-groups/{groupName}", method: "get", ...variables, signal });

export const useGetUserGroup = <TData = Schemas.UserGroupModel>(
  variables: GetUserGroupVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserGroupModel,
      GetUserGroupError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<Schemas.UserGroupModel, GetUserGroupError, TData>({
    queryKey: queryKeyFn({
      path: "/user-groups/{groupName}",
      operationId: "getUserGroup",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserGroup({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateUserGroupPathParams = {
  groupName: string;
};

export type UpdateUserGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserGroupVariables = {
  body: Schemas.UpdateUserGroupDto;
  pathParams: UpdateUserGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateUserGroup = (
  variables: UpdateUserGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateUserGroupError,
    Schemas.UpdateUserGroupDto,
    {},
    {},
    UpdateUserGroupPathParams
  >({ url: "/user-groups/{groupName}", method: "post", ...variables, signal });

export const useUpdateUserGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateUserGroupError,
      UpdateUserGroupVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateUserGroupError,
    UpdateUserGroupVariables
  >({
    mutationFn: (variables: UpdateUserGroupVariables) =>
      fetchUpdateUserGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteUserGroupPathParams = {
  groupName: string;
};

export type DeleteUserGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserGroupVariables = {
  pathParams: DeleteUserGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteUserGroup = (
  variables: DeleteUserGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteUserGroupError,
    undefined,
    {},
    {},
    DeleteUserGroupPathParams
  >({
    url: "/user-groups/{groupName}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteUserGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteUserGroupError,
      DeleteUserGroupVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteUserGroupError,
    DeleteUserGroupVariables
  >({
    mutationFn: (variables: DeleteUserGroupVariables) =>
      fetchDeleteUserGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListUsersInGroupPathParams = {
  groupName: string;
};

export type ListUsersInGroupError = Fetcher.ErrorWrapper<undefined>;

export type ListUsersInGroupResponse = Schemas.CognitoUser[];

export type ListUsersInGroupVariables = {
  pathParams: ListUsersInGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchListUsersInGroup = (
  variables: ListUsersInGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    ListUsersInGroupResponse,
    ListUsersInGroupError,
    undefined,
    {},
    {},
    ListUsersInGroupPathParams
  >({
    url: "/user-groups/{groupName}/users",
    method: "get",
    ...variables,
    signal,
  });

export const useListUsersInGroup = <TData = ListUsersInGroupResponse>(
  variables: ListUsersInGroupVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListUsersInGroupResponse,
      ListUsersInGroupError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    ListUsersInGroupResponse,
    ListUsersInGroupError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-groups/{groupName}/users",
      operationId: "listUsersInGroup",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListUsersInGroup({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AddUsersToGroupPathParams = {
  groupName: string;
};

export type AddUsersToGroupError = Fetcher.ErrorWrapper<undefined>;

export type AddUsersToGroupVariables = {
  body: Schemas.UsersToGroupDto;
  pathParams: AddUsersToGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchAddUsersToGroup = (
  variables: AddUsersToGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    AddUsersToGroupError,
    Schemas.UsersToGroupDto,
    {},
    {},
    AddUsersToGroupPathParams
  >({
    url: "/user-groups/{groupName}/add-users",
    method: "post",
    ...variables,
    signal,
  });

export const useAddUsersToGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddUsersToGroupError,
      AddUsersToGroupVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    AddUsersToGroupError,
    AddUsersToGroupVariables
  >({
    mutationFn: (variables: AddUsersToGroupVariables) =>
      fetchAddUsersToGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddUserToGroupsError = Fetcher.ErrorWrapper<undefined>;

export type AddUserToGroupsVariables = {
  body: Schemas.UpdateUserInGroupsDto;
} & PrimioContext["fetcherOptions"];

export const fetchAddUserToGroups = (
  variables: AddUserToGroupsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    AddUserToGroupsError,
    Schemas.UpdateUserInGroupsDto,
    {},
    {},
    {}
  >({ url: "/user-groups/user/add", method: "post", ...variables, signal });

export const useAddUserToGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddUserToGroupsError,
      AddUserToGroupsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    AddUserToGroupsError,
    AddUserToGroupsVariables
  >({
    mutationFn: (variables: AddUserToGroupsVariables) =>
      fetchAddUserToGroups({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveUserFromGroupsError = Fetcher.ErrorWrapper<undefined>;

export type RemoveUserFromGroupsVariables = {
  body: Schemas.UpdateUserInGroupsDto;
} & PrimioContext["fetcherOptions"];

export const fetchRemoveUserFromGroups = (
  variables: RemoveUserFromGroupsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    RemoveUserFromGroupsError,
    Schemas.UpdateUserInGroupsDto,
    {},
    {},
    {}
  >({ url: "/user-groups/user/remove", method: "post", ...variables, signal });

export const useRemoveUserFromGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveUserFromGroupsError,
      RemoveUserFromGroupsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    RemoveUserFromGroupsError,
    RemoveUserFromGroupsVariables
  >({
    mutationFn: (variables: RemoveUserFromGroupsVariables) =>
      fetchRemoveUserFromGroups({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveUsersFromGroupPathParams = {
  groupName: string;
};

export type RemoveUsersFromGroupError = Fetcher.ErrorWrapper<undefined>;

export type RemoveUsersFromGroupVariables = {
  body: Schemas.UsersToGroupDto;
  pathParams: RemoveUsersFromGroupPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchRemoveUsersFromGroup = (
  variables: RemoveUsersFromGroupVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    RemoveUsersFromGroupError,
    Schemas.UsersToGroupDto,
    {},
    {},
    RemoveUsersFromGroupPathParams
  >({
    url: "/user-groups/{groupName}/remove-users",
    method: "post",
    ...variables,
    signal,
  });

export const useRemoveUsersFromGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveUsersFromGroupError,
      RemoveUsersFromGroupVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    RemoveUsersFromGroupError,
    RemoveUsersFromGroupVariables
  >({
    mutationFn: (variables: RemoveUsersFromGroupVariables) =>
      fetchRemoveUsersFromGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateUserInvitationError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserInvitationVariables = {
  body: Schemas.CreateUserInvitationDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateUserInvitation = (
  variables: CreateUserInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserInvitation,
    CreateUserInvitationError,
    Schemas.CreateUserInvitationDto,
    {},
    {},
    {}
  >({ url: "/user-invitations", method: "post", ...variables, signal });

export const useCreateUserInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserInvitation,
      CreateUserInvitationError,
      CreateUserInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.UserInvitation,
    CreateUserInvitationError,
    CreateUserInvitationVariables
  >({
    mutationFn: (variables: CreateUserInvitationVariables) =>
      fetchCreateUserInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertUserInvitationError = Fetcher.ErrorWrapper<undefined>;

export type UpsertUserInvitationVariables = {
  body: Schemas.CreateUserInvitationDto;
} & PrimioContext["fetcherOptions"];

export const fetchUpsertUserInvitation = (
  variables: UpsertUserInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpsertUserInvitationError,
    Schemas.CreateUserInvitationDto,
    {},
    {},
    {}
  >({ url: "/user-invitations", method: "put", ...variables, signal });

export const useUpsertUserInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpsertUserInvitationError,
      UpsertUserInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpsertUserInvitationError,
    UpsertUserInvitationVariables
  >({
    mutationFn: (variables: UpsertUserInvitationVariables) =>
      fetchUpsertUserInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserInvitationsError = Fetcher.ErrorWrapper<undefined>;

export type GetUserInvitationsResponse = Schemas.UserInvitation[];

export type GetUserInvitationsVariables = PrimioContext["fetcherOptions"];

export const fetchGetUserInvitations = (
  variables: GetUserInvitationsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUserInvitationsResponse,
    GetUserInvitationsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/user-invitations", method: "get", ...variables, signal });

export const useGetUserInvitations = <TData = GetUserInvitationsResponse>(
  variables: GetUserInvitationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUserInvitationsResponse,
      GetUserInvitationsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUserInvitationsResponse,
    GetUserInvitationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-invitations",
      operationId: "getUserInvitations",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserInvitations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateUserInvitationsBulkError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserInvitationsBulkResponse = Schemas.UserInvitation[];

export type CreateUserInvitationsBulkRequestBody =
  Schemas.CreateUserInvitationDto[];

export type CreateUserInvitationsBulkVariables = {
  body?: CreateUserInvitationsBulkRequestBody;
} & PrimioContext["fetcherOptions"];

export const fetchCreateUserInvitationsBulk = (
  variables: CreateUserInvitationsBulkVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    CreateUserInvitationsBulkResponse,
    CreateUserInvitationsBulkError,
    CreateUserInvitationsBulkRequestBody,
    {},
    {},
    {}
  >({ url: "/user-invitations/bulk", method: "post", ...variables, signal });

export const useCreateUserInvitationsBulk = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateUserInvitationsBulkResponse,
      CreateUserInvitationsBulkError,
      CreateUserInvitationsBulkVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    CreateUserInvitationsBulkResponse,
    CreateUserInvitationsBulkError,
    CreateUserInvitationsBulkVariables
  >({
    mutationFn: (variables: CreateUserInvitationsBulkVariables) =>
      fetchCreateUserInvitationsBulk({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserInvitationPathParams = {
  invitationCode: string;
};

export type GetUserInvitationError = Fetcher.ErrorWrapper<undefined>;

export type GetUserInvitationVariables = {
  pathParams: GetUserInvitationPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserInvitation = (
  variables: GetUserInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserInvitation,
    GetUserInvitationError,
    undefined,
    {},
    {},
    GetUserInvitationPathParams
  >({
    url: "/user-invitations/{invitationCode}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserInvitation = <TData = Schemas.UserInvitation>(
  variables: GetUserInvitationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserInvitation,
      GetUserInvitationError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.UserInvitation,
    GetUserInvitationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/user-invitations/{invitationCode}",
      operationId: "getUserInvitation",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserInvitation({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateUserInvitationPathParams = {
  invitationUid: string;
};

export type UpdateUserInvitationError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserInvitationVariables = {
  body?: Schemas.UpdateUserInvitationDto;
  pathParams: UpdateUserInvitationPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateUserInvitation = (
  variables: UpdateUserInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserInvitation,
    UpdateUserInvitationError,
    Schemas.UpdateUserInvitationDto,
    {},
    {},
    UpdateUserInvitationPathParams
  >({
    url: "/user-invitations/{invitationUid}",
    method: "patch",
    ...variables,
    signal,
  });

export const useUpdateUserInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserInvitation,
      UpdateUserInvitationError,
      UpdateUserInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.UserInvitation,
    UpdateUserInvitationError,
    UpdateUserInvitationVariables
  >({
    mutationFn: (variables: UpdateUserInvitationVariables) =>
      fetchUpdateUserInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteUserInvitationPathParams = {
  invitationUid: string;
};

export type DeleteUserInvitationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserInvitationVariables = {
  pathParams: DeleteUserInvitationPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteUserInvitation = (
  variables: DeleteUserInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteUserInvitationError,
    undefined,
    {},
    {},
    DeleteUserInvitationPathParams
  >({
    url: "/user-invitations/{invitationUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteUserInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteUserInvitationError,
      DeleteUserInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteUserInvitationError,
    DeleteUserInvitationVariables
  >({
    mutationFn: (variables: DeleteUserInvitationVariables) =>
      fetchDeleteUserInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CompleteInvitationPathParams = {
  invitationUid: string;
};

export type CompleteInvitationError = Fetcher.ErrorWrapper<undefined>;

export type CompleteInvitationVariables = {
  pathParams: CompleteInvitationPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchCompleteInvitation = (
  variables: CompleteInvitationVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserInvitation,
    CompleteInvitationError,
    undefined,
    {},
    {},
    CompleteInvitationPathParams
  >({
    url: "/user-invitations/{invitationUid}/complete",
    method: "post",
    ...variables,
    signal,
  });

export const useCompleteInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserInvitation,
      CompleteInvitationError,
      CompleteInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.UserInvitation,
    CompleteInvitationError,
    CompleteInvitationVariables
  >({
    mutationFn: (variables: CompleteInvitationVariables) =>
      fetchCompleteInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SendUserInvitationsError = Fetcher.ErrorWrapper<undefined>;

export type SendUserInvitationsVariables = {
  body: Schemas.SendUserInvitationsDto;
} & PrimioContext["fetcherOptions"];

export const fetchSendUserInvitations = (
  variables: SendUserInvitationsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    void,
    SendUserInvitationsError,
    Schemas.SendUserInvitationsDto,
    {},
    {},
    {}
  >({ url: "/user-invitations/send", method: "post", ...variables, signal });

export const useSendUserInvitations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      void,
      SendUserInvitationsError,
      SendUserInvitationsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    void,
    SendUserInvitationsError,
    SendUserInvitationsVariables
  >({
    mutationFn: (variables: SendUserInvitationsVariables) =>
      fetchSendUserInvitations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUiLearningPathOverviewError = Fetcher.ErrorWrapper<undefined>;

export type GetUiLearningPathOverviewVariables =
  PrimioContext["fetcherOptions"];

export const fetchGetUiLearningPathOverview = (
  variables: GetUiLearningPathOverviewVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UiLearningPathOverview,
    GetUiLearningPathOverviewError,
    undefined,
    {},
    {},
    {}
  >({ url: "/ui/learning-paths", method: "get", ...variables, signal });

export const useGetUiLearningPathOverview = <
  TData = Schemas.UiLearningPathOverview,
>(
  variables: GetUiLearningPathOverviewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UiLearningPathOverview,
      GetUiLearningPathOverviewError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.UiLearningPathOverview,
    GetUiLearningPathOverviewError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/ui/learning-paths",
      operationId: "getUiLearningPathOverview",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUiLearningPathOverview(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetDashboardLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type GetDashboardLearningPathsResponse = Schemas.UiLearningPath[];

export type GetDashboardLearningPathsVariables =
  PrimioContext["fetcherOptions"];

export const fetchGetDashboardLearningPaths = (
  variables: GetDashboardLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetDashboardLearningPathsResponse,
    GetDashboardLearningPathsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/ui/learning-paths/dashboard",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDashboardLearningPaths = <
  TData = GetDashboardLearningPathsResponse,
>(
  variables: GetDashboardLearningPathsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDashboardLearningPathsResponse,
      GetDashboardLearningPathsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetDashboardLearningPathsResponse,
    GetDashboardLearningPathsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/ui/learning-paths/dashboard",
      operationId: "getDashboardLearningPaths",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDashboardLearningPaths(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetDateLockedLearningPathsQueryParams = {
  limit?: number;
};

export type GetDateLockedLearningPathsError = Fetcher.ErrorWrapper<undefined>;

export type GetDateLockedLearningPathsResponse =
  Schemas.DateLockedUiLearningPath[];

export type GetDateLockedLearningPathsVariables = {
  queryParams?: GetDateLockedLearningPathsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetDateLockedLearningPaths = (
  variables: GetDateLockedLearningPathsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetDateLockedLearningPathsResponse,
    GetDateLockedLearningPathsError,
    undefined,
    {},
    GetDateLockedLearningPathsQueryParams,
    {}
  >({
    url: "/ui/learning-paths/date-locked",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDateLockedLearningPaths = <
  TData = GetDateLockedLearningPathsResponse,
>(
  variables: GetDateLockedLearningPathsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDateLockedLearningPathsResponse,
      GetDateLockedLearningPathsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetDateLockedLearningPathsResponse,
    GetDateLockedLearningPathsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/ui/learning-paths/date-locked",
      operationId: "getDateLockedLearningPaths",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDateLockedLearningPaths(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetFavouriteCardsQueryParams = {
  playbookUid?: string;
  chapterUid?: string;
  collectionUid?: string;
  cardUid?: string;
};

export type GetFavouriteCardsError = Fetcher.ErrorWrapper<undefined>;

export type GetFavouriteCardsResponse = Schemas.FavouriteCard[];

export type GetFavouriteCardsVariables = {
  queryParams?: GetFavouriteCardsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetFavouriteCards = (
  variables: GetFavouriteCardsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetFavouriteCardsResponse,
    GetFavouriteCardsError,
    undefined,
    {},
    GetFavouriteCardsQueryParams,
    {}
  >({ url: "/favourite-cards", method: "get", ...variables, signal });

export const useGetFavouriteCards = <TData = GetFavouriteCardsResponse>(
  variables: GetFavouriteCardsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFavouriteCardsResponse,
      GetFavouriteCardsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetFavouriteCardsResponse,
    GetFavouriteCardsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/favourite-cards",
      operationId: "getFavouriteCards",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFavouriteCards({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateFavouriteCardError = Fetcher.ErrorWrapper<undefined>;

export type CreateFavouriteCardVariables = {
  body: Schemas.CreateFavouriteCardDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateFavouriteCard = (
  variables: CreateFavouriteCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CreateFavouriteCardError,
    Schemas.CreateFavouriteCardDto,
    {},
    {},
    {}
  >({ url: "/favourite-cards", method: "post", ...variables, signal });

export const useCreateFavouriteCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateFavouriteCardError,
      CreateFavouriteCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateFavouriteCardError,
    CreateFavouriteCardVariables
  >({
    mutationFn: (variables: CreateFavouriteCardVariables) =>
      fetchCreateFavouriteCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetFavouriteCardUsersPathParams = {
  collectionUid: string;
};

export type GetFavouriteCardUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetFavouriteCardUsersResponse = Schemas.UsersFavouriteCardModel[];

export type GetFavouriteCardUsersVariables = {
  pathParams: GetFavouriteCardUsersPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetFavouriteCardUsers = (
  variables: GetFavouriteCardUsersVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetFavouriteCardUsersResponse,
    GetFavouriteCardUsersError,
    undefined,
    {},
    {},
    GetFavouriteCardUsersPathParams
  >({
    url: "/favourite-cards/collections/{collectionUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetFavouriteCardUsers = <TData = GetFavouriteCardUsersResponse>(
  variables: GetFavouriteCardUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFavouriteCardUsersResponse,
      GetFavouriteCardUsersError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetFavouriteCardUsersResponse,
    GetFavouriteCardUsersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/favourite-cards/collections/{collectionUid}",
      operationId: "getFavouriteCardUsers",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFavouriteCardUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteFavouriteCardPathParams = {
  favouriteCardUid: string;
};

export type DeleteFavouriteCardError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFavouriteCardVariables = {
  pathParams: DeleteFavouriteCardPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteFavouriteCard = (
  variables: DeleteFavouriteCardVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteFavouriteCardError,
    undefined,
    {},
    {},
    DeleteFavouriteCardPathParams
  >({
    url: "/favourite-cards/{favouriteCardUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteFavouriteCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteFavouriteCardError,
      DeleteFavouriteCardVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteFavouriteCardError,
    DeleteFavouriteCardVariables
  >({
    mutationFn: (variables: DeleteFavouriteCardVariables) =>
      fetchDeleteFavouriteCard({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetFavouritePlaybooksError = Fetcher.ErrorWrapper<undefined>;

export type GetFavouritePlaybooksResponse = Schemas.FavouritePlaybook[];

export type GetFavouritePlaybooksVariables = PrimioContext["fetcherOptions"];

export const fetchGetFavouritePlaybooks = (
  variables: GetFavouritePlaybooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetFavouritePlaybooksResponse,
    GetFavouritePlaybooksError,
    undefined,
    {},
    {},
    {}
  >({ url: "/favourite-playbooks", method: "get", ...variables, signal });

export const useGetFavouritePlaybooks = <TData = GetFavouritePlaybooksResponse>(
  variables: GetFavouritePlaybooksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFavouritePlaybooksResponse,
      GetFavouritePlaybooksError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetFavouritePlaybooksResponse,
    GetFavouritePlaybooksError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/favourite-playbooks",
      operationId: "getFavouritePlaybooks",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFavouritePlaybooks({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateFavouritePlaybookError = Fetcher.ErrorWrapper<undefined>;

export type CreateFavouritePlaybookVariables = {
  body: Schemas.CreateFavouritePlaybookDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateFavouritePlaybook = (
  variables: CreateFavouritePlaybookVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    string,
    CreateFavouritePlaybookError,
    Schemas.CreateFavouritePlaybookDto,
    {},
    {},
    {}
  >({ url: "/favourite-playbooks", method: "post", ...variables, signal });

export const useCreateFavouritePlaybook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      CreateFavouritePlaybookError,
      CreateFavouritePlaybookVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    string,
    CreateFavouritePlaybookError,
    CreateFavouritePlaybookVariables
  >({
    mutationFn: (variables: CreateFavouritePlaybookVariables) =>
      fetchCreateFavouritePlaybook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteFavouritePlaybooksPathParams = {
  favouritePlaybookUid: string;
};

export type DeleteFavouritePlaybooksError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFavouritePlaybooksVariables = {
  pathParams: DeleteFavouritePlaybooksPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchDeleteFavouritePlaybooks = (
  variables: DeleteFavouritePlaybooksVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    DeleteFavouritePlaybooksError,
    undefined,
    {},
    {},
    DeleteFavouritePlaybooksPathParams
  >({
    url: "/favourite-playbooks/{favouritePlaybookUid}",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteFavouritePlaybooks = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteFavouritePlaybooksError,
      DeleteFavouritePlaybooksVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    DeleteFavouritePlaybooksError,
    DeleteFavouritePlaybooksVariables
  >({
    mutationFn: (variables: DeleteFavouritePlaybooksVariables) =>
      fetchDeleteFavouritePlaybooks({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateUserPlaybooksReviewsError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserPlaybooksReviewsVariables = {
  body: Schemas.CreateUserPlaybooksReviewDto;
} & PrimioContext["fetcherOptions"];

export const fetchCreateUserPlaybooksReviews = (
  variables: CreateUserPlaybooksReviewsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserPlaybooksReviewModel,
    CreateUserPlaybooksReviewsError,
    Schemas.CreateUserPlaybooksReviewDto,
    {},
    {},
    {}
  >({ url: "/playbook-reviews", method: "post", ...variables, signal });

export const useCreateUserPlaybooksReviews = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserPlaybooksReviewModel,
      CreateUserPlaybooksReviewsError,
      CreateUserPlaybooksReviewsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    Schemas.UserPlaybooksReviewModel,
    CreateUserPlaybooksReviewsError,
    CreateUserPlaybooksReviewsVariables
  >({
    mutationFn: (variables: CreateUserPlaybooksReviewsVariables) =>
      fetchCreateUserPlaybooksReviews({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUsersPlaybooksReviewsError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersPlaybooksReviewsResponse =
  Schemas.UserPlaybooksReviewModel[];

export type GetUsersPlaybooksReviewsVariables = PrimioContext["fetcherOptions"];

export const fetchGetUsersPlaybooksReviews = (
  variables: GetUsersPlaybooksReviewsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUsersPlaybooksReviewsResponse,
    GetUsersPlaybooksReviewsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/playbook-reviews", method: "get", ...variables, signal });

export const useGetUsersPlaybooksReviews = <
  TData = GetUsersPlaybooksReviewsResponse,
>(
  variables: GetUsersPlaybooksReviewsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUsersPlaybooksReviewsResponse,
      GetUsersPlaybooksReviewsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUsersPlaybooksReviewsResponse,
    GetUsersPlaybooksReviewsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbook-reviews",
      operationId: "getUsersPlaybooksReviews",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUsersPlaybooksReviews(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetUserPlaybooksReviewForCurrentUserQueryParams = {
  PlaybookUid?: string;
};

export type GetUserPlaybooksReviewForCurrentUserError =
  Fetcher.ErrorWrapper<undefined>;

export type GetUserPlaybooksReviewForCurrentUserResponse =
  Schemas.UserPlaybooksReviewModel[];

export type GetUserPlaybooksReviewForCurrentUserVariables = {
  queryParams?: GetUserPlaybooksReviewForCurrentUserQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserPlaybooksReviewForCurrentUser = (
  variables: GetUserPlaybooksReviewForCurrentUserVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUserPlaybooksReviewForCurrentUserResponse,
    GetUserPlaybooksReviewForCurrentUserError,
    undefined,
    {},
    GetUserPlaybooksReviewForCurrentUserQueryParams,
    {}
  >({ url: "/playbook-reviews/user", method: "get", ...variables, signal });

export const useGetUserPlaybooksReviewForCurrentUser = <
  TData = GetUserPlaybooksReviewForCurrentUserResponse,
>(
  variables: GetUserPlaybooksReviewForCurrentUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUserPlaybooksReviewForCurrentUserResponse,
      GetUserPlaybooksReviewForCurrentUserError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUserPlaybooksReviewForCurrentUserResponse,
    GetUserPlaybooksReviewForCurrentUserError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbook-reviews/user",
      operationId: "getUserPlaybooksReviewForCurrentUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserPlaybooksReviewForCurrentUser(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetUserPlaybooksReviewPathParams = {
  userUid: string;
};

export type GetUserPlaybooksReviewError = Fetcher.ErrorWrapper<undefined>;

export type GetUserPlaybooksReviewResponse = Schemas.UserPlaybooksReviewModel[];

export type GetUserPlaybooksReviewVariables = {
  pathParams: GetUserPlaybooksReviewPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserPlaybooksReview = (
  variables: GetUserPlaybooksReviewVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUserPlaybooksReviewResponse,
    GetUserPlaybooksReviewError,
    undefined,
    {},
    {},
    GetUserPlaybooksReviewPathParams
  >({
    url: "/playbook-reviews/users/{userUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserPlaybooksReview = <
  TData = GetUserPlaybooksReviewResponse,
>(
  variables: GetUserPlaybooksReviewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUserPlaybooksReviewResponse,
      GetUserPlaybooksReviewError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUserPlaybooksReviewResponse,
    GetUserPlaybooksReviewError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbook-reviews/users/{userUid}",
      operationId: "getUserPlaybooksReview",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserPlaybooksReview({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetUserPlaybookReviewPathParams = {
  reviewUid: string;
};

export type GetUserPlaybookReviewError = Fetcher.ErrorWrapper<undefined>;

export type GetUserPlaybookReviewVariables = {
  pathParams: GetUserPlaybookReviewPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchGetUserPlaybookReview = (
  variables: GetUserPlaybookReviewVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.UserPlaybooksReviewModel,
    GetUserPlaybookReviewError,
    undefined,
    {},
    {},
    GetUserPlaybookReviewPathParams
  >({
    url: "/playbook-reviews/{reviewUid}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetUserPlaybookReview = <
  TData = Schemas.UserPlaybooksReviewModel,
>(
  variables: GetUserPlaybookReviewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserPlaybooksReviewModel,
      GetUserPlaybookReviewError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.UserPlaybooksReviewModel,
    GetUserPlaybookReviewError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/playbook-reviews/{reviewUid}",
      operationId: "getUserPlaybookReview",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserPlaybookReview({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateUserPlaybookReviewPathParams = {
  reviewUid: string;
};

export type UpdateUserPlaybookReviewError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserPlaybookReviewVariables = {
  body?: Schemas.UpdateUserPlaybooksReviewDto;
  pathParams: UpdateUserPlaybookReviewPathParams;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateUserPlaybookReview = (
  variables: UpdateUserPlaybookReviewVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    undefined,
    UpdateUserPlaybookReviewError,
    Schemas.UpdateUserPlaybooksReviewDto,
    {},
    {},
    UpdateUserPlaybookReviewPathParams
  >({
    url: "/playbook-reviews/{reviewUid}",
    method: "post",
    ...variables,
    signal,
  });

export const useUpdateUserPlaybookReview = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateUserPlaybookReviewError,
      UpdateUserPlaybookReviewVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    undefined,
    UpdateUserPlaybookReviewError,
    UpdateUserPlaybookReviewVariables
  >({
    mutationFn: (variables: UpdateUserPlaybookReviewVariables) =>
      fetchUpdateUserPlaybookReview({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetFeatureFlagsError = Fetcher.ErrorWrapper<{
  status: Exclude<ClientErrorStatus | ServerErrorStatus, 200>;
  payload: Schemas.FeatureFlagModel[];
}>;

export type GetFeatureFlagsResponse = Schemas.FeatureFlagModel[];

export type GetFeatureFlagsVariables = PrimioContext["fetcherOptions"];

export const fetchGetFeatureFlags = (
  variables: GetFeatureFlagsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetFeatureFlagsResponse,
    GetFeatureFlagsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/feature-flags", method: "get", ...variables, signal });

export const useGetFeatureFlags = <TData = GetFeatureFlagsResponse>(
  variables: GetFeatureFlagsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFeatureFlagsResponse,
      GetFeatureFlagsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetFeatureFlagsResponse,
    GetFeatureFlagsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/feature-flags",
      operationId: "getFeatureFlags",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFeatureFlags({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateFeatureFlagError = Fetcher.ErrorWrapper<{
  status: Exclude<ClientErrorStatus | ServerErrorStatus, 200>;
  payload: Schemas.FeatureFlagModel[];
}>;

export type UpdateFeatureFlagResponse = Schemas.FeatureFlagModel[];

export type UpdateFeatureFlagRequestBody = Schemas.UpdateFeatureFlagDto[];

export type UpdateFeatureFlagVariables = {
  body?: UpdateFeatureFlagRequestBody;
} & PrimioContext["fetcherOptions"];

export const fetchUpdateFeatureFlag = (
  variables: UpdateFeatureFlagVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    UpdateFeatureFlagResponse,
    UpdateFeatureFlagError,
    UpdateFeatureFlagRequestBody,
    {},
    {},
    {}
  >({ url: "/feature-flags", method: "patch", ...variables, signal });

export const useUpdateFeatureFlag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateFeatureFlagResponse,
      UpdateFeatureFlagError,
      UpdateFeatureFlagVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = usePrimioContext();
  return reactQuery.useMutation<
    UpdateFeatureFlagResponse,
    UpdateFeatureFlagError,
    UpdateFeatureFlagVariables
  >({
    mutationFn: (variables: UpdateFeatureFlagVariables) =>
      fetchUpdateFeatureFlag({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUiCategoriesError = Fetcher.ErrorWrapper<undefined>;

export type GetUiCategoriesResponse = Schemas.UICategory[];

export type GetUiCategoriesVariables = PrimioContext["fetcherOptions"];

export const fetchGetUiCategories = (
  variables: GetUiCategoriesVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    GetUiCategoriesResponse,
    GetUiCategoriesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/ui/categories", method: "get", ...variables, signal });

export const useGetUiCategories = <TData = GetUiCategoriesResponse>(
  variables: GetUiCategoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetUiCategoriesResponse,
      GetUiCategoriesError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    GetUiCategoriesResponse,
    GetUiCategoriesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/ui/categories",
      operationId: "getUiCategories",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUiCategories({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SearchCollectionsQueryParams = {
  q: string;
};

export type SearchCollectionsError = Fetcher.ErrorWrapper<undefined>;

export type SearchCollectionsVariables = {
  queryParams: SearchCollectionsQueryParams;
} & PrimioContext["fetcherOptions"];

export const fetchSearchCollections = (
  variables: SearchCollectionsVariables,
  signal?: AbortSignal,
) =>
  primioFetch<
    Schemas.SearchResults,
    SearchCollectionsError,
    undefined,
    {},
    SearchCollectionsQueryParams,
    {}
  >({ url: "/search", method: "get", ...variables, signal });

export const useSearchCollections = <TData = Schemas.SearchResults>(
  variables: SearchCollectionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.SearchResults,
      SearchCollectionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    usePrimioContext(options);
  return reactQuery.useQuery<
    Schemas.SearchResults,
    SearchCollectionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/search",
      operationId: "searchCollections",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchSearchCollections({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/health";
      operationId: "healthCheck";
      variables: HealthCheckVariables;
    }
  | {
      path: "/user-progress";
      operationId: "getUserProgress";
      variables: GetUserProgressVariables;
    }
  | {
      path: "/user-progress/users/{username}";
      operationId: "getProgressForUser";
      variables: GetProgressForUserVariables;
    }
  | {
      path: "/user-progress/learning-paths";
      operationId: "getUsersStartedLearningPaths";
      variables: GetUsersStartedLearningPathsVariables;
    }
  | {
      path: "/user-progress/learning-paths/user";
      operationId: "getCurrentUserLearningPaths";
      variables: GetCurrentUserLearningPathsVariables;
    }
  | {
      path: "/user-progress/learning-paths/{learningPathUid}";
      operationId: "getLearningPathProgress";
      variables: GetLearningPathProgressVariables;
    }
  | {
      path: "/user-progress/learning-paths/users/{userUid}";
      operationId: "getUserLearningPaths";
      variables: GetUserLearningPathsVariables;
    }
  | {
      path: "/user-progress/content-directory";
      operationId: "getContentDirectory";
      variables: GetContentDirectoryVariables;
    }
  | {
      path: "/user-progress/popular-users";
      operationId: "getPopularUsers";
      variables: GetPopularUsersVariables;
    }
  | {
      path: "/user-progress/latest-activity";
      operationId: "getUserLatestActivity";
      variables: GetUserLatestActivityVariables;
    }
  | {
      path: "/user-progress/community-answers/{collectionUid}";
      operationId: "getCollectionAnswers";
      variables: GetCollectionAnswersVariables;
    }
  | {
      path: "/user-progress/cards-completed/{collectionUid}";
      operationId: "getCardsCompleted";
      variables: GetCardsCompletedVariables;
    }
  | {
      path: "/user-progress/activity/users";
      operationId: "getActiveUserParticipation";
      variables: GetActiveUserParticipationVariables;
    }
  | {
      path: "/user-progress/activity/cards";
      operationId: "getCardsCompletedParticipation";
      variables: GetCardsCompletedParticipationVariables;
    }
  | {
      path: "/user-progress/activity/cards/users";
      operationId: "getUserCardsCompletedParticipation";
      variables: GetUserCardsCompletedParticipationVariables;
    }
  | {
      path: "/user-progress/playbook/{playbookUid}";
      operationId: "getPlaybookProgress";
      variables: GetPlaybookProgressVariables;
    }
  | {
      path: "/user-progress/interactions/{contentUid}";
      operationId: "getContentInteractions";
      variables: GetContentInteractionsVariables;
    }
  | {
      path: "/user-progress/track-playbooks";
      operationId: "getTrackedPlaybookProgress";
      variables: GetTrackedPlaybookProgressVariables;
    }
  | {
      path: "/user-progress/collections/{collectionUid}";
      operationId: "getProcessedCollectionTracer";
      variables: GetProcessedCollectionTracerVariables;
    }
  | {
      path: "/user-progress/collections/{collectionUid}/users/{userUid}";
      operationId: "getCollectionTracerForUser";
      variables: GetCollectionTracerForUserVariables;
    }
  | {
      path: "/user-progress/cards/{cardUid}";
      operationId: "getCardCompletedByUsers";
      variables: GetCardCompletedByUsersVariables;
    }
  | {
      path: "/user-progress/completed/playbook/{playbookUid}";
      operationId: "getPlaybookCompleted";
      variables: GetPlaybookCompletedVariables;
    }
  | {
      path: "/user-progress/users/{userUid}/learning-paths/completed";
      operationId: "getCompletedLearningPathsForUser";
      variables: GetCompletedLearningPathsForUserVariables;
    }
  | {
      path: "/user-progress/learning-paths/{learningPathUid}/users/completed";
      operationId: "getUsersCompletedLearningPath";
      variables: GetUsersCompletedLearningPathVariables;
    }
  | {
      path: "/user-progress/cards-completed/cards/{cardUid}/{version}";
      operationId: "getUserCardAnswer";
      variables: GetUserCardAnswerVariables;
    }
  | {
      path: "/user-progress/cards-completed/users/cards/{cardUid}/{version}";
      operationId: "getUsersCardAnswers";
      variables: GetUsersCardAnswersVariables;
    }
  | {
      path: "/track-playbooks";
      operationId: "getTrackedPlaybooks";
      variables: GetTrackedPlaybooksVariables;
    }
  | {
      path: "/users";
      operationId: "getUsers";
      variables: GetUsersVariables;
    }
  | {
      path: "/users/user";
      operationId: "getAuthenticatedUser";
      variables: GetAuthenticatedUserVariables;
    }
  | {
      path: "/users/{username}/groups";
      operationId: "getGroupsForUser";
      variables: GetGroupsForUserVariables;
    }
  | {
      path: "/users/{userUid}";
      operationId: "getUserByUid";
      variables: GetUserByUidVariables;
    }
  | {
      path: "/learning-paths";
      operationId: "getLearningPaths";
      variables: GetLearningPathsVariables;
    }
  | {
      path: "/learning-paths/content";
      operationId: "getLearningPathsForContents";
      variables: GetLearningPathsForContentsVariables;
    }
  | {
      path: "/learning-paths/{learningPathUid}";
      operationId: "getLearningPath";
      variables: GetLearningPathVariables;
    }
  | {
      path: "/v2/learning-paths/{learningPathUid}";
      operationId: "getLearningPathDetailV2";
      variables: GetLearningPathDetailV2Variables;
    }
  | {
      path: "/learning-paths/progress/summary";
      operationId: "getSummarizedLearningPathProgress";
      variables: GetSummarizedLearningPathProgressVariables;
    }
  | {
      path: "/learning-paths/{learningPathUid}/content/user-groups";
      operationId: "getLearningPathContentUserGroups";
      variables: GetLearningPathContentUserGroupsVariables;
    }
  | {
      path: "/learning-paths/{learningPathUid}/users/{userUid}/certificate";
      operationId: "getUserLearningPathCertificate";
      variables: GetUserLearningPathCertificateVariables;
    }
  | {
      path: "/learning-paths/{learningPathUid}/certificate/pdf";
      operationId: "getLearningPathCertificate";
      variables: GetLearningPathCertificateVariables;
    }
  | {
      path: "/learning-paths/{learningPathUid}/certificate";
      operationId: "getLearningPathCertificateView";
      variables: GetLearningPathCertificateViewVariables;
    }
  | {
      path: "/api-keys";
      operationId: "getApiKeys";
      variables: GetApiKeysVariables;
    }
  | {
      path: "/api-keys/clients";
      operationId: "getApiKeyClients";
      variables: GetApiKeyClientsVariables;
    }
  | {
      path: "/api-keys/{key}";
      operationId: "getApikey";
      variables: GetApikeyVariables;
    }
  | {
      path: "/api-keys/clients/{apiKeyClientUid}";
      operationId: "getApiKeyClient";
      variables: GetApiKeyClientVariables;
    }
  | {
      path: "/v2/playbooks";
      operationId: "getPaginatedPlayBooks";
      variables: GetPaginatedPlayBooksVariables;
    }
  | {
      path: "/playbooks";
      operationId: "getPlaybooks";
      variables: GetPlaybooksVariables;
    }
  | {
      path: "/playbooks/overview";
      operationId: "getPlaybooksOverview";
      variables: GetPlaybooksOverviewVariables;
    }
  | {
      path: "/playbooks/hierarchies";
      operationId: "getPlaybookHierarchies";
      variables: GetPlaybookHierarchiesVariables;
    }
  | {
      path: "/playbooks/progress/summary";
      operationId: "getSummarizedPlaybooksProgress";
      variables: GetSummarizedPlaybooksProgressVariables;
    }
  | {
      path: "/playbooks/{playbookUid}";
      operationId: "getPlaybook";
      variables: GetPlaybookVariables;
    }
  | {
      path: "/playbooks/{playbookUid}/details";
      operationId: "getPlaybookDetails";
      variables: GetPlaybookDetailsVariables;
    }
  | {
      path: "/playbooks/user-groups/{userGroup}";
      operationId: "getPlaybooksWhereUserGroup";
      variables: GetPlaybooksWhereUserGroupVariables;
    }
  | {
      path: "/playbooks/{playbookUid}/export";
      operationId: "exportPlaybook";
      variables: ExportPlaybookVariables;
    }
  | {
      path: "/chapters";
      operationId: "getChapters";
      variables: GetChaptersVariables;
    }
  | {
      path: "/chapters/{chapterUid}";
      operationId: "getChapter";
      variables: GetChapterVariables;
    }
  | {
      path: "/collections";
      operationId: "getCollections";
      variables: GetCollectionsVariables;
    }
  | {
      path: "/collections/{collectionUid}";
      operationId: "getCollection";
      variables: GetCollectionVariables;
    }
  | {
      path: "/cards";
      operationId: "getCards";
      variables: GetCardsVariables;
    }
  | {
      path: "/cards/progress/summary";
      operationId: "getSummarizedCardsProgress";
      variables: GetSummarizedCardsProgressVariables;
    }
  | {
      path: "/cards/{cardUid}";
      operationId: "getCard";
      variables: GetCardVariables;
    }
  | {
      path: "/sources";
      operationId: "getSources";
      variables: GetSourcesVariables;
    }
  | {
      path: "/sources/{sourceUid}";
      operationId: "getSource";
      variables: GetSourceVariables;
    }
  | {
      path: "/categories";
      operationId: "getCategories";
      variables: GetCategoriesVariables;
    }
  | {
      path: "/categories/{categoryUid}";
      operationId: "getCategory";
      variables: GetCategoryVariables;
    }
  | {
      path: "/media/{key}/signed-url";
      operationId: "getFile";
      variables: GetFileVariables;
    }
  | {
      path: "/media/{mediaUid}";
      operationId: "getMedia";
      variables: GetMediaVariables;
    }
  | {
      path: "/media/{mediaUid}/import";
      operationId: "getImportMedia";
      variables: GetImportMediaVariables;
    }
  | {
      path: "/notifications/scheduler";
      operationId: "getScheduledNotifications";
      variables: GetScheduledNotificationsVariables;
    }
  | {
      path: "/notifications/scheduler/summary";
      operationId: "scheduledNotificationSummary";
      variables: ScheduledNotificationSummaryVariables;
    }
  | {
      path: "/notifications";
      operationId: "getNotifications";
      variables: GetNotificationsVariables;
    }
  | {
      path: "/notifications/scheduled-notification/{scheduledNotificationUid}";
      operationId: "getNotificationsForScheduledNotification";
      variables: GetNotificationsForScheduledNotificationVariables;
    }
  | {
      path: "/qr-codes";
      operationId: "getQrCodes";
      variables: GetQrCodesVariables;
    }
  | {
      path: "/qr-codes/{qrCodeUid}";
      operationId: "getQrCode";
      variables: GetQrCodeVariables;
    }
  | {
      path: "/qr-codes/{qrCodeUid}/content";
      operationId: "getPublicQrCodeContent";
      variables: GetPublicQrCodeContentVariables;
    }
  | {
      path: "/qr-codes/{qrCodeUid}/content/user";
      operationId: "getQrCodeContent";
      variables: GetQrCodeContentVariables;
    }
  | {
      path: "/user-groups";
      operationId: "getUserGroups";
      variables: GetUserGroupsVariables;
    }
  | {
      path: "/user-groups/{groupName}";
      operationId: "getUserGroup";
      variables: GetUserGroupVariables;
    }
  | {
      path: "/user-groups/{groupName}/users";
      operationId: "listUsersInGroup";
      variables: ListUsersInGroupVariables;
    }
  | {
      path: "/user-invitations";
      operationId: "getUserInvitations";
      variables: GetUserInvitationsVariables;
    }
  | {
      path: "/user-invitations/{invitationCode}";
      operationId: "getUserInvitation";
      variables: GetUserInvitationVariables;
    }
  | {
      path: "/ui/learning-paths";
      operationId: "getUiLearningPathOverview";
      variables: GetUiLearningPathOverviewVariables;
    }
  | {
      path: "/ui/learning-paths/dashboard";
      operationId: "getDashboardLearningPaths";
      variables: GetDashboardLearningPathsVariables;
    }
  | {
      path: "/ui/learning-paths/date-locked";
      operationId: "getDateLockedLearningPaths";
      variables: GetDateLockedLearningPathsVariables;
    }
  | {
      path: "/favourite-cards";
      operationId: "getFavouriteCards";
      variables: GetFavouriteCardsVariables;
    }
  | {
      path: "/favourite-cards/collections/{collectionUid}";
      operationId: "getFavouriteCardUsers";
      variables: GetFavouriteCardUsersVariables;
    }
  | {
      path: "/favourite-playbooks";
      operationId: "getFavouritePlaybooks";
      variables: GetFavouritePlaybooksVariables;
    }
  | {
      path: "/playbook-reviews";
      operationId: "getUsersPlaybooksReviews";
      variables: GetUsersPlaybooksReviewsVariables;
    }
  | {
      path: "/playbook-reviews/user";
      operationId: "getUserPlaybooksReviewForCurrentUser";
      variables: GetUserPlaybooksReviewForCurrentUserVariables;
    }
  | {
      path: "/playbook-reviews/users/{userUid}";
      operationId: "getUserPlaybooksReview";
      variables: GetUserPlaybooksReviewVariables;
    }
  | {
      path: "/playbook-reviews/{reviewUid}";
      operationId: "getUserPlaybookReview";
      variables: GetUserPlaybookReviewVariables;
    }
  | {
      path: "/feature-flags";
      operationId: "getFeatureFlags";
      variables: GetFeatureFlagsVariables;
    }
  | {
      path: "/ui/categories";
      operationId: "getUiCategories";
      variables: GetUiCategoriesVariables;
    }
  | {
      path: "/search";
      operationId: "searchCollections";
      variables: SearchCollectionsVariables;
    };
