import React, { useEffect, useState } from "react";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { RoutesQRCodeEnum } from "../../../constants/routes/qr-code.route";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import useQuery from "../../../core/hooks/useQuery";
import EmptyStateScreen from "../../screens/empty-state.screen";
import QrOverviewScreen from "../../screens/qr-codes/qr-overview.screen";
import QrCodeModel from "../../../core/models/qr-code/qr-code.model";
import { useQrCodes } from "../../../core/features/qr-code/qr-code.hooks";
import { useDispatch } from "react-redux";
import ContentAction from "../../../core/redux/stores/content/content.action";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";

const QrCodeContainer = () => {
  const { t } = useTranslation();
  const [qrStateFilter, setQrStateFilter] = useState<
    ContentStatesEnum | undefined
  >(ContentStatesEnum.PUBLISHED);
  const query = useQuery();
  const { data: qrCodes } = useQrCodes();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  useEffect(() => {
    dispatch(ContentAction.getAllContent());
  }, []);

  const state: ContentStatesEnum | null = query.get(
    "state",
  ) as ContentStatesEnum | null;
  if (state) {
    if (state !== qrStateFilter) {
      setQrStateFilter(state);
    }
  } else {
    if (qrStateFilter !== undefined) {
      setQrStateFilter(undefined);
    }
  }

  const menuItems = () => {
    const qrCount = qrCodes ? qrCodes.length : 0;
    const menuItems: MenuItemInterface[] = [
      {
        title: `${t("containers.qr.sidebar")} (${qrCount})`,
        route: RoutesEnum.QR_CODE,
      },
    ];

    for (const key in {
      PUBLISHED: ContentStatesEnum.PUBLISHED,
      DRAFT: ContentStatesEnum.DRAFT,
    }) {
      const count = qrCodes
        ? qrCodes.filter((qr) => qr.contentState === ContentStatesEnum[key])
            .length
        : 0;
      menuItems.push({
        title: `${Capitalize(t(`content-state.${key}`))} (${count})`,
        route: RoutesQRCodeEnum[key],
      });
    }

    return menuItems;
  };

  const filteredQrCodes = (): QrCodeModel[] => {
    if (!qrCodes) {
      return [];
    }

    if (!qrStateFilter) {
      return qrCodes;
    }

    return qrCodes.filter((qr) => qr.contentState === qrStateFilter);
  };

  return (
    <PageLayoutComponent
      menuItems={menuItems()}
      title={"qr"}
      onPress={() => onShowModal()}
    >
      {!qrCodes || filteredQrCodes().length === 0 ? (
        <EmptyStateScreen
          contentState={qrStateFilter}
          route={"qr"}
          onPress={() => onShowModal()}
        />
      ) : (
        <QrOverviewScreen
          qrCodes={filteredQrCodes()}
          contentState={qrStateFilter}
          onEdit={onShowModal}
        />
      )}
    </PageLayoutComponent>
  );

  function onShowModal(qrCode?: QrCodeModel) {
    openModal(ModalNames.CREATE_QR, { qrCode });
  }
};

export default QrCodeContainer;
