import EffectUtility from "../../utils/effect.utils";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import UsersFavouriteCardModel from "../../models/favourite/users-favourite-card.model";
import FavouriteEndpoints from "../../api/favourite.endpoints";

export async function getFavouriteCards(collectionUid: string) {
  const endpoint = FavouriteEndpoints.favouriteCards(collectionUid);
  const response = await EffectUtility.getToModel<UsersFavouriteCardModel[]>(
    UsersFavouriteCardModel,
    endpoint,
  );
  if (response instanceof HttpErrorResponseModel) {
    throw response;
  }
  return response;
}
