import { MediaTypesEnum } from "../enums/media-providers.enum";
import MediaModel from "../models/media/media.model";

export function disableButton(media: MediaModel | undefined): boolean {
  if (!media) {
    return false;
  }

  switch (media.mediaType) {
    case MediaTypesEnum.NO_MEDIA:
      return false;
    case MediaTypesEnum.AUDIO:
    case MediaTypesEnum.EMBED:
    case MediaTypesEnum.IMAGE:
    case MediaTypesEnum.VIDEO:
      return media.mediaUid === undefined && media.uri === "";
  }
}
