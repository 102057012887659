import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
// @ts-ignore
import { CascaderOptionType, CascaderValueType } from "antd/lib/cascader";
import { ContentCascaderInterface } from "../../../core/interfaces/content-cascader.interface";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { Form, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContentCascaderComponent from "./select/content-cascader.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import QrCodeModel from "../../../core/models/qr-code/qr-code.model";
import CharacterCounter from "../form-builder/components/character-counter";
import TextArea from "antd/lib/input/TextArea";
import AppConfig from "../../../constants/config/app.config";
import styles from "../card-types/card-types.module.css";

export interface CreateQRCodeFormFields {
  content: ContentCascaderInterface[];
  description: string;
  isPublic: boolean;
  openInApp: boolean;
  publishNow: boolean;
}

export interface CreateQRCodeFormRef {
  form: FormInstance<CreateQRCodeFormFields>;
}

interface OwnProps {
  qrCode: QrCodeModel | undefined;
}

type Props = OwnProps;

const CreateQrCodeForm = forwardRef(
  (props: Props, ref: Ref<CreateQRCodeFormRef>) => {
    const [t] = useTranslation();
    const { qrCode } = props;
    const [form] = useForm<CreateQRCodeFormFields>();
    const [content, setContent] = useState<(string | number)[] | null>(
      handleGetContent(),
    );
    const [description, setDescription] = useState<string>(
      qrCode ? qrCode.description : "",
    );
    const [isPublic, setIsPublic] = useState<boolean>(
      qrCode ? qrCode.isPublic : true,
    );
    const [openInApp, setOpenInApp] = useState(
      qrCode ? qrCode.openInApp : false,
    );

    const [publishNow, setPublishNow] = useState<boolean>(
      qrCode ? !!qrCode.publishNow : false,
    );

    const descriptionMaxLength = AppConfig.userGroupDescriptionMaxLength;

    useImperativeHandle(ref, () => ({ form }));

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          initialValue={content}
          name={"content"}
          label={Capitalize(t("form.items.content.label"))}
          rules={getRules("content")}
        >
          <ContentCascaderComponent
            content={content}
            setContent={handleSetContent}
          />
        </Form.Item>

        <Form.Item
          initialValue={description}
          name={"description"}
          label={Capitalize(t("form.card.description.label"))}
          rules={getRules("description")}
        >
          <div>
            <TextArea
              value={description}
              rows={4}
              style={{ width: "80%" }}
              maxLength={descriptionMaxLength}
              placeholder={t("form.placeholders.describe_x", {
                item: t("containers.qr.key"),
              })}
              onChange={(e) => setDescription(e.target.value)}
            />
            <CharacterCounter
              currentLength={description.length}
              maxLength={descriptionMaxLength}
              className={styles.character_counter}
            />
          </div>
        </Form.Item>
        <Form.Item
          initialValue={isPublic}
          name={"isPublic"}
          label={Capitalize(t("screens.accessibility.state.PUBLIC"))}
          tooltip={t("screens.accessibility.tooltip", {
            item: t("containers.qr.key"),
          })}
        >
          <Switch
            disabled={openInApp}
            checked={isPublic}
            onChange={handleOnIsPublic}
          />
        </Form.Item>
        <Form.Item
          initialValue={openInApp}
          name={"openInApp"}
          label={t("translations:screens.qr.open-in-app.label")}
          tooltip={t("translations:screens.qr.open-in-app.tooltip", {
            label: t("translations:screens.qr.open-in-app.label"),
          })}
        >
          <Switch
            disabled={isPublic}
            checked={openInApp}
            onChange={handleOpenInApp}
          />
        </Form.Item>
        <Form.Item
          name={"publishNow"}
          label={Capitalize(t("form.items.send.publish-now"))}
          tooltip={Capitalize(t("form.items.send.publish-now-tooltip"))}
        >
          <Switch checked={publishNow} onChange={handleOnChange} />
        </Form.Item>
      </Form>
    );

    function handleOnIsPublic(checked: boolean) {
      setIsPublic(checked);
      form.setFields([{ name: "isPublic", value: checked }]);
    }

    function handleOpenInApp(checked: boolean) {
      if (isPublic) {
        return;
      }

      setOpenInApp(checked);
      form.setFields([{ name: "openInApp", value: checked }]);
    }

    function handleGetContent() {
      if (!qrCode) {
        return null;
      }
      switch (qrCode.data.contentType) {
        case ContentTypesEnum.PLAYBOOK:
          return [qrCode.data.playbookUid];
        case ContentTypesEnum.CHAPTER:
          return [qrCode.data.playbookUid, qrCode.data.chapterUid];
        case ContentTypesEnum.COLLECTION:
          return [
            qrCode.data.playbookUid,
            qrCode.data.chapterUid,
            qrCode.data.collectionUid,
          ];
        case ContentTypesEnum.CARD:
          return [
            qrCode.data.playbookUid,
            qrCode.data.chapterUid,
            qrCode.data.collectionUid,
            qrCode.data.cardUid,
          ];
      }
    }

    function handleSetContent(
      value: CascaderValueType,
      selectedOptions?: CascaderOptionType[],
    ) {
      setContent(value);
      form.setFields([
        { name: "content", value: selectedOptions ? selectedOptions : value },
      ]);
    }

    function handleOnChange(checked: boolean) {
      form.setFields([{ name: "publishNow", value: checked }]);
      setPublishNow(checked);
    }

    function getRules(formItem: string) {
      if (formItem === "description") {
        return [
          {
            max: descriptionMaxLength,
            message: Capitalize(
              t("errors.max-length", {
                field: t("form.card.description.label"),
                amount: descriptionMaxLength,
              }),
            ),
          },
          {
            required: true,
            message: t("errors.required", {
              item: t("form.card.description.label"),
            }),
          },
        ];
      }

      return [
        {
          required: true,
          message: t("errors.required", {
            item: t("form.items.content.label"),
          }),
        },
      ];
    }
  },
);

CreateQrCodeForm.displayName = "CreateQrCodeForm";

export default CreateQrCodeForm;
