export interface SourceResponse {
  sourceUid: string;
  text: string;
  url: string;
  sort: number;
  createdAt: Date;
  cardUid: string;
  cardVersion: number;
}

export default class SourceModel {
  sourceUid: string;
  text: string;
  url: string;
  sort: number;
  createdAt: Date;
  cardUid: string;
  cardVersion: number;

  constructor(response: SourceResponse) {
    this.sourceUid = response.sourceUid;
    this.text = response.text;
    this.url = response.url;
    this.sort = response.sort;
    this.createdAt = response.createdAt;
    this.cardUid = response.cardUid;
    this.cardVersion = response.cardVersion;
  }
}
