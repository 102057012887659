import React, { CSSProperties } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeTwoTone,
  InboxOutlined,
  RocketOutlined,
  StarFilled,
} from "@ant-design/icons";

interface Props {
  action:
    | "delete"
    | "duplicate"
    | "edit"
    | "publish"
    | "draft"
    | "open"
    | "exclusive";
  style?: CSSProperties;
}

const ContextActionIconComponent = (props: Props) => {
  const { action, style } = props;

  switch (action) {
    case "delete":
      return <DeleteOutlined style={style} />;
    case "duplicate":
      return <CopyOutlined style={style} />;
    case "edit":
      return <EditOutlined style={style} />;
    case "publish":
      return <RocketOutlined style={style} />;
    case "draft":
      return <InboxOutlined style={style} />;
    case "open":
      return <EyeTwoTone style={style} />;
    case "exclusive":
      return (
        <StarFilled
          style={{ color: "var(--exclusive-color-primary)", ...style }}
        />
      );
    default:
      return null;
  }
};

export default ContextActionIconComponent;
