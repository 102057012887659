import { useCallback, useEffect, useState } from "react";
import { useInterval } from "react-interval-hook";

export interface LongPressProps {
  callback: () => void;
  ms: number;
  labels: {
    default: string;
    pressing: string;
    doing: string;
  };
}

export default function useLongPress({ callback, ms, labels }: LongPressProps) {
  const [startLongPress, setStartLongPress] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [progressNormalized, setProgressNormalized] = useState<number>(0);
  const [label, setLabel] = useState<string>(labels.default);
  const { start: startInterval, stop: stopInterval } = useInterval(() => {
    setProgress(progress + 50);
  }, 50);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      setLabel(labels.doing);
      timerId = setTimeout(() => {
        callback();
        stopInterval();
        setProgress(ms);
      }, ms);
      startInterval();
    } else {
      setLabel(labels.default);
      clearTimeout(timerId);
      stopInterval();
      setProgress(0);
    }

    return () => {
      clearTimeout(timerId);
      stopInterval();
      setProgress(0);
    };
  }, [callback, ms, startLongPress]);

  useEffect(() => {
    if (progress === 0) {
      setProgressNormalized(0);
      return;
    }

    setProgressNormalized(progress / ms);
  }, [progress]);

  const start = useCallback(() => {
    setStartLongPress(true);
    setProgress(0);
  }, []);

  const stop = useCallback(() => {
    setLabel(labels.default);
    setStartLongPress(false);
    setProgress(0);
  }, []);

  const handleMouseEnter = () => {
    setLabel(labels.pressing);
  };

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
    onMouseEnter: handleMouseEnter,
    progress,
    progressNormalized,
    label,
  };
}
