import React from "react";
import { Form, Typography } from "antd";
import { CheckSquareFilled, CloseSquareFilled } from "@ant-design/icons";
import { green, red } from "@ant-design/colors";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import MultipleChoiceOptionModel from "../../../../core/models/content/card-types/multiple-choice/multiple-choice-option.model";
import QuizOptionModel from "../../../../core/models/content/card-types/quiz/quiz-option.model";
import SliderOptionModel from "../../../../core/models/content/card-types/slider/slider-option.model";

interface OwnProps {
  options:
    | MultipleChoiceOptionModel[]
    | QuizOptionModel[]
    | SliderOptionModel[];
}

type Props = OwnProps;

const OptionPreviewComponent = (props: Props) => {
  const [t] = useTranslation();
  const { options } = props;

  return (
    <Form.Item
      label={
        <Typography.Text strong>
          {Capitalize(t(`form.card.answers.label`))}
        </Typography.Text>
      }
    >
      {options.map(
        (
          option:
            | MultipleChoiceOptionModel
            | QuizOptionModel
            | SliderOptionModel,
          index: number,
        ) => {
          const content =
            option instanceof SliderOptionModel ? option.label : option.content;
          const explanation =
            option instanceof MultipleChoiceOptionModel
              ? option.explanation
              : undefined;

          return (
            <Form.Item key={index}>
              <div style={{ paddingLeft: ".5rem" }}>
                {renderPrefix(index, option)}
                {content && (
                  <Typography.Text style={{ paddingLeft: "1rem" }}>
                    {TranslateDefaults(content)}
                  </Typography.Text>
                )}
              </div>
              {explanation && (
                <Typography.Text italic style={{ paddingLeft: "2.5rem" }}>
                  {TranslateDefaults(explanation)}
                </Typography.Text>
              )}
            </Form.Item>
          );
        },
      )}
    </Form.Item>
  );

  function renderPrefix(
    index: number,
    option: MultipleChoiceOptionModel | QuizOptionModel | SliderOptionModel,
  ) {
    if (option instanceof SliderOptionModel) {
      return <Typography.Text>{`${index + 1}.`}</Typography.Text>;
    } else {
      return option.isCorrect ? (
        <CheckSquareFilled style={{ color: green[5] }} />
      ) : (
        <CloseSquareFilled style={{ color: red[5] }} />
      );
    }
  }
};

export default OptionPreviewComponent;
