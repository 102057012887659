import { ContentProgressEnum } from "../../../../enums/content-progress.enum";
import ChapterUserProgressModel, {
  ChapterUserProgressResponse,
} from "./chapter-user-progress.model";
import ContentUserProgressModel from "./content-user-progress.model";

export interface PlaybookUserProgressResponse {
  [chapterUid: string]: ChapterUserProgressResponse;
}

export default class PlaybookUserProgressModel {
  playbookUid: string;
  chapters: ChapterUserProgressModel[];
  users: ContentUserProgressModel[];

  constructor(response: PlaybookUserProgressResponse, playbookUid: string) {
    this.playbookUid = playbookUid;
    this.chapters = Object.keys(response).map((chapterUid) => {
      return new ChapterUserProgressModel(response[chapterUid], chapterUid);
    });

    this.users = [];

    /* eslint-disable-next-line */
    Object.entries(response).map(([_, chapterUserProgress]) => {
      /* eslint-disable-next-line */
      Object.entries(chapterUserProgress).map(([_, collectionUserProgress]) => {
        Object.entries(collectionUserProgress).map(([userUid, state]) => {
          const index = this.users.findIndex((u) => u.userUid === userUid);
          if (index === -1) {
            this.users.push(new ContentUserProgressModel({ userUid, state }));
          } else if (
            this.users[index].state === ContentProgressEnum.COMPLETED &&
            this.users[index].state !== state
          ) {
            this.users[index] = new ContentUserProgressModel({
              userUid,
              state: ContentProgressEnum.STARTED,
            });
          }
        });
      });
    });
  }
}
