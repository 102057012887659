import { createSelector } from "reselect";
import { StoreInterface } from "../../stores/root.reducer";

export class RequestingSelector {
  static selectRequesting(requestingState: any, actionTypes: string[]) {
    return actionTypes.some(
      (actionType: string) => requestingState[actionType],
    );
  }
}

export const selectRequesting = createSelector(
  (state: StoreInterface) => state.requesting,
  (state: any, actionTypes: string[]) => actionTypes,
  RequestingSelector.selectRequesting,
);
