export interface QuestionCardDataResponse {
  question: string;
  explanation: string;
}

export default class QuestionCardDataModel {
  question: string;
  explanation: string;

  constructor(questionCard: QuestionCardDataResponse) {
    this.question = questionCard.question;
    this.explanation = questionCard.explanation;
  }
}
