import React from "react";
import { Select, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentlySelectedUserGroup,
  selectUserGroups,
} from "../../../core/redux/selectors/user-group/user-group.selector";
import { useTranslation } from "react-i18next";

interface OwnProps {
  userGroups?: string[];
}

type Props = OwnProps;

export const UserGroupSelectComponent: any = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userGroups } = props;
  const groups: UserGroupModel[] = useSelector(selectUserGroups);
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );
  const { Option } = Select;

  return (
    <>
      <Typography.Text
        type={"secondary"}
        style={{ fontSize: "1.3rem", marginRight: "1.1em" }}
      >
        {Capitalize(t("containers.published-content.group-content"))}
      </Typography.Text>
      <Select
        value={currentlySelectedUserGroup}
        style={{ width: "200px" }}
        placeholder={Capitalize(t("form.placeholders.select_x"))}
        onChange={(value) =>
          dispatch(UserGroupAction.setSelectedUserGroup(value))
        }
      >
        <Option value={""}>
          {Capitalize(t("containers.published-content.all-groups-content"))}
        </Option>
        {groups.map((group, index) => {
          if (userGroups && !userGroups?.includes(group.title)) {
            return (
              <Option key={index} value={group.title} disabled={true}>
                {group.title}
              </Option>
            );
          }

          return (
            <Option key={index} value={group.title}>
              {group.title}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export default UserGroupSelectComponent;
