import { API_URL } from "../../constants/config/env.config";

const UserEndpoints = {
  /**
   * Get Users endpoint.
   * @returns API_URL + /users/
   */
  overview: `${API_URL}/users/`,

  /**
   * Get User endpoint.
   * @returns API_URL + /users/user
   */
  user: `${API_URL}/users/user`,

  /**
   * Get Groups for User endpoint.
   * @param username string
   * @returns API_URL + /users/:username/groups
   */
  groupsForUser(username: string) {
    return `${API_URL}/users/${username}/groups`;
  },
};

export default UserEndpoints;
