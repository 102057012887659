import { useMutation, useQuery } from "@tanstack/react-query";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import MediaModel, { MediaResponse } from "../../models/media/media.model";
import { createMedia, getMedia } from "./media.api";

export function useMedia(mediaUid?: string) {
  return useQuery<any, HttpErrorResponseModel, MediaModel>(
    ["media", mediaUid],
    () => {
      if (!mediaUid) {
        return;
      }
      return getMedia(mediaUid);
    },
    {
      enabled: mediaUid !== undefined,
    },
  );
}

export function useCreateMedia() {
  return useMutation<any, HttpErrorResponseModel, any>(
    ["media"],
    (data: Partial<MediaResponse>) => createMedia(data),
  );
}
