import React from "react";
import { Card, Form, List, Tag, Typography } from "antd";
import { grey } from "@ant-design/colors";
import {
  NotifiableTypeEnum,
  NotificationTypesEnum,
} from "../../../core/enums/notification-types.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Link } from "react-router-dom";
import BarGroupedComponent, {
  BarGroupedChartData,
} from "../../components/graphs/bar-grouped.component";
import ContentListComponent from "../../components/content-list/content-list.component";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import formStyles from "../../components/form-builder/form-builder.module.css";
import styles from "../../components/content/content.module.css";
import NotificationTypes from "../../../core/types/notification.types";
import Moment from "moment";
import { useGetLearningPath } from "../../../core/api/primio/primioComponents";
import { RoutesEnum } from "../../../constants/routes/app.route";

interface OwnProps {
  notification: NotificationTypes;
}

type Props = OwnProps;

const NotificationsDetailOverviewScreen = ({ notification }: Props) => {
  const [t] = useTranslation();

  const barData: BarGroupedChartData[] = [
    {
      type: Capitalize(t("screens.notifications.state.SENT")),
      amount: notification.amountOfUsers,
    },
    {
      type: Capitalize(t("common.received")),
      amount: notification.receivedBy,
      percentage: renderPercentage(notification.receivedBy),
    },
    {
      type: Capitalize(t("common.read")),
      amount: notification.seenBy,
      percentage: renderPercentage(notification.seenBy),
    },
  ];

  return (
    <>
      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form labelCol={{ span: 4 }} layout={"horizontal"} labelAlign={"left"}>
          <Form.Item
            label={renderLabel(
              `${Capitalize(t("containers.notifications.key") + " id")}`,
            )}
          >
            <Typography.Text>{notification.notificationUid}</Typography.Text>
          </Form.Item>

          <Form.Item label={renderLabel("screens.notifications.type.label")}>
            <Typography.Text>
              {Capitalize(
                t(
                  `screens.notifications.type.${notification.notificationType}`,
                ),
              )}
            </Typography.Text>
          </Form.Item>

          <Form.Item label={renderLabel("form.items.created_at.label")}>
            <Typography.Text>
              {Moment(notification.createdAt).format("ddd. D MMM yyyy (LT)")}
            </Typography.Text>
          </Form.Item>

          <Form.Item label={renderLabel("form.items.created_by.label")}>
            <Typography.Text>
              {Capitalize(notification.createdBy.toLowerCase())}
            </Typography.Text>
          </Form.Item>
        </Form>
      </Card>

      <Typography.Title level={5}>
        {Capitalize(t("form.items.content.label"))}
      </Typography.Title>
      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form labelCol={{ span: 4 }} layout={"horizontal"} labelAlign={"left"}>
          <Form.Item label={renderLabel("form.card.title.label")}>
            <Typography.Text>
              {TranslateDefaults(notification.data.title)}
            </Typography.Text>
          </Form.Item>

          {notification.data.subtitle && (
            <Form.Item label={renderLabel("form.items.subtitle.label")}>
              <Typography.Text>
                {TranslateDefaults(notification.data.subtitle)}
              </Typography.Text>
            </Form.Item>
          )}

          <Form.Item label={renderLabel("form.items.text.label")}>
            <Typography.Text>
              {TranslateDefaults(notification.data.message)}
            </Typography.Text>
          </Form.Item>

          {notification.notificationType === NotificationTypesEnum.CONTENT && (
            <Form.Item label={renderLabel("form.items.content.linked")}>
              {renderContentList()}
            </Form.Item>
          )}
        </Form>
      </Card>

      <Typography.Title level={5}>
        {Capitalize(t("common.timeline"))}
      </Typography.Title>
      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form labelCol={{ span: 4 }} layout={"horizontal"} labelAlign={"left"}>
          <Form.Item label={renderLabel("form.items.send.send-at")}>
            <Typography.Text>
              {Moment(notification.scheduledAt).format("ddd. D MMM yyyy (LT)")}
            </Typography.Text>
          </Form.Item>

          <Form.Item label={renderLabel("form.items.send.sent-at")}>
            {notification.sentAt ? (
              <Typography.Text>
                {Moment(notification.sentAt).format("ddd. D MMM yyyy (LT)")}
              </Typography.Text>
            ) : (
              <Typography.Text italic style={{ color: grey[0] }}>
                {"n/a"}
              </Typography.Text>
            )}
          </Form.Item>
          {notification.notifiable.notifiableType !==
          NotifiableTypeEnum.USERS ? (
            <Form.Item
              labelCol={{ span: 4 }}
              label={renderLabel("form.items.send.sent-to-user-group")}
            >
              {notification.notifiable.recipients?.length === 0 ? (
                <BarGroupedComponent data={barData} />
              ) : (
                notification.notifiable.recipients?.map((recipient) => {
                  return <span key={recipient}>{recipient}</span>;
                })
              )}
            </Form.Item>
          ) : null}
        </Form>
      </Card>

      <Typography.Title level={5}>
        {Capitalize(t("form.items.audience.label"))}
      </Typography.Title>
      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form labelCol={{ span: 4 }} layout={"horizontal"} labelAlign={"left"}>
          <Form.Item label={renderLabel("common.type")}>
            <Tag>{renderNotifiableTypeLabel()}</Tag>
          </Form.Item>

          <Form.Item
            label={renderLabel("form.items.send.scheduled-to")}
            style={{ alignItems: "flex-start" }}
          >
            {renderRecipientList()}
          </Form.Item>
        </Form>
      </Card>
    </>
  );

  function renderPercentage(amount: number) {
    const percentage = isFinite(amount / notification.amountOfUsers)
      ? amount / notification.amountOfUsers
      : 0;
    return parseFloat((percentage * 100).toFixed(2));
  }

  function renderLabel(text: string) {
    return <Typography.Text strong>{Capitalize(t(text))}</Typography.Text>;
  }

  function renderContentList() {
    if (notification.notificationType !== NotificationTypesEnum.CONTENT) {
      return;
    }

    if (notification.data.learningPathUid) {
      return (
        <RenderLearningPathLink
          learningPathUid={notification.data.learningPathUid}
        />
      );
    }

    const playbookUid: string = notification.data.playbookUid;
    const chapterUid: string | undefined = notification.data.chapterUid;
    const collectionUid: string | undefined = notification.data.collectionUid;
    const cardUid: string | undefined = notification.data.cardUid;

    return (
      <ContentListComponent
        data={{ playbookUid, chapterUid, collectionUid, cardUid }}
      />
    );
  }

  function renderNotifiableTypeLabel() {
    if (
      notification.notifiable.notifiableType === NotifiableTypeEnum.USER_GROUPS
    ) {
      return Capitalize(t("containers.user-groups.key"));
    } else {
      return Capitalize(t("containers.users.key_plural"));
    }
  }

  function renderRecipientList() {
    if (notification.notifiable.recipients === undefined) {
      return (
        <div>
          {Capitalize(
            t("form.items.send.sent-to", {
              item: t("common.everyone"),
            }),
          )}
        </div>
      );
    }

    return (
      <div style={{ maxHeight: "20rem", overflow: "scroll" }}>
        <List<string>
          dataSource={notification.notifiable.recipients}
          className={formStyles.list}
          bordered
          renderItem={(recipient) => {
            if (
              notification.notifiable.notifiableType ===
              NotifiableTypeEnum.USER_GROUPS
            ) {
              return (
                <List.Item>
                  <Link
                    to={{ pathname: `/user-groups/${recipient as string}` }}
                  >
                    {recipient}
                  </Link>
                </List.Item>
              );
            } else {
              return (
                <List.Item>
                  <Link to={{ pathname: `/users/${recipient as string}` }}>
                    <Typography.Text>{recipient}</Typography.Text>
                  </Link>
                </List.Item>
              );
            }
          }}
        />
      </div>
    );
  }
};

const RenderLearningPathLink = ({
  learningPathUid,
}: {
  learningPathUid: string;
}) => {
  const { data } = useGetLearningPath({ pathParams: { learningPathUid } });

  if (!data) {
    return null;
  }

  const route =
    data.learningPathType === null
      ? (RoutesEnum.LEARNING_PATH_DETAIL as string)
      : (RoutesEnum.LEARNING_PATH_DETAIL_LEGACY as string);

  return (
    <Link to={{ pathname: route.replace(":uid", learningPathUid) }}>
      {data.title}
    </Link>
  );
};

export default NotificationsDetailOverviewScreen;
