import React, { createContext, useContext, useEffect, useState } from "react";
import { IAuthenticationCallback } from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes/app.route";
import CognitoService from "../services/cognito.service";
import NewPasswordChallengeCallback from "../types/new-password-challenge-callback.type";
import ForgetPasswordCallbackType from "../types/forget-password-callback.type";
import ConfirmPasswordCallbackType from "../types/confirm-password-callback.type";
import { validate as validateEmail } from "email-validator";
import { useDispatch } from "react-redux";
import UserAction from "../redux/stores/user/user.action";
import getJwtToken from "../utils/get-jwt-token";
import { useInterval } from "usehooks-ts";
import AppLoader from "../../views/components/auth/app-loader";

interface AuthContextType {
  loading: boolean;
  isAuth: boolean;
  login: (
    email: string,
    password: string,
    remember: boolean,
    callbacks?: Partial<IAuthenticationCallback>,
  ) => void;
  completeNewPasswordChallenge: (
    email: string,
    password: string,
    requiredAttributeData: [],
    remember: boolean,
    callbacks?: Partial<NewPasswordChallengeCallback>,
  ) => void;
  logout: () => void;
  forgetPassword: (
    email: string,
    callbacks: Partial<ForgetPasswordCallbackType>,
  ) => void;
  confirmPassword: (
    email: string,
    verificationCode: string,
    newPassword: string,
    callbacks: Partial<ConfirmPasswordCallbackType>,
  ) => void;
  token?: string;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

function AuthProvider(props: { children: JSX.Element }) {
  const [appLoading, setAppLoading] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCheckingAuth, setIsCheckingAuth] = useState(false);

  // check Authentication when first opening the app
  useEffect(() => {
    checkAuthentication();
  }, []);

  // check Authentication every minute while authenticated
  useInterval(checkAuthentication, isAuth ? 1000 * 60 : null);

  useEffect(() => {
    if (isAuth) {
      dispatch(UserAction.getCurrentUser());
    } else {
      dispatch(UserAction.clearCurrentUser());
    }
  }, [isAuth]);

  function checkAuthentication() {
    if (isCheckingAuth) {
      return;
    }

    if (!isCheckingAuth) {
      setIsCheckingAuth(true);
    }

    getJwtToken()
      .then((token) => {
        setToken(token);
        setIsAuth(!!token);
      })
      .catch(logout)
      .finally(() => {
        setIsCheckingAuth(false);
        setAppLoading(false);
      });
  }

  // TODO: Re-implement the Remember param
  const login = async (
    email: string,
    password: string,
    remember: boolean,
    callbacks?: Partial<IAuthenticationCallback>,
  ) => {
    setLoading(true);

    const cognitoService = new CognitoService();
    cognitoService.authenticateUser(email, password, {
      ...callbacks,
      onSuccess: async () => {
        await checkAuthentication();
        setLoading(false);
        history.push(RoutesEnum.HOME);
      },
      onFailure: (err) => {
        setIsAuth(false);
        setLoading(false);
        callbacks?.onFailure?.(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setIsAuth(false);
        setLoading(false);

        const location = {
          pathname: RoutesEnum.NEW_PASSWORD_CHALLENGE,
          state: {
            email: userAttributes.email,
            requiredAttributes: requiredAttributes,
            remember,
          },
        };

        history.push(location);
      },
    });
  };

  const completeNewPasswordChallenge = async (
    email: string,
    password: string,
    requiredAttributeData: [],
    remember: boolean,
    callbacks?: Partial<NewPasswordChallengeCallback>,
  ) => {
    setLoading(true);

    const cognitoService = new CognitoService();
    cognitoService.completeNewPasswordChallenge(
      email,
      password,
      requiredAttributeData,
      {
        ...callbacks,
        onSuccess: async () => {
          await checkAuthentication();
          setLoading(false);
          history.push(RoutesEnum.HOME);
        },
        onFailure: (err) => {
          setIsAuth(false);
          setLoading(false);
          callbacks?.onFailure?.(err);
        },
      },
    );
  };

  const forgetPassword = async (
    email: string,
    callbacks: Partial<ForgetPasswordCallbackType>,
  ) => {
    setLoading(true);
    setIsAuth(false);

    const cognitoService = new CognitoService();
    cognitoService.forgetPassword(email, {
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: (err) => {
        setLoading(false);
        callbacks.onFailure?.(err);
      },
      inputVerificationCode: () => {
        setLoading(false);

        if (!validateEmail(email)) {
          callbacks.onFailure?.({ code: "InvalidEmailException" });
        } else {
          const location = {
            pathname: RoutesEnum.CHANGE_PASSWORD,
            state: { email },
          };

          history.push(location);
        }
      },
    });
  };

  const confirmPassword = async (
    email: string,
    verificationCode: string,
    newPassword: string,
    callbacks: Partial<ConfirmPasswordCallbackType>,
  ) => {
    setLoading(true);
    setIsAuth(false);

    const cognitoService = new CognitoService();
    cognitoService.confirmPassword(email, verificationCode, newPassword, {
      onSuccess: () => {
        setLoading(false);

        const location = {
          pathname: RoutesEnum.LOGIN,
          hash: "#confirmed",
        };

        history.push(location);
      },
      onFailure: (err) => {
        setLoading(false);
        callbacks.onFailure?.(err);
      },
    });
  };

  const logout = () => {
    setLoading(true);

    const cognitoService = new CognitoService();
    cognitoService.signOut();

    localStorage.clear();

    setIsAuth(false);
    setToken(undefined);
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        loading,
        isAuth,
        login,
        logout,
        completeNewPasswordChallenge,
        forgetPassword,
        confirmPassword,
      }}
    >
      {appLoading ? <AppLoader /> : props.children}
    </AuthContext.Provider>
  );
}

const useAuth = () => React.useContext(AuthContext);

const useToken = () => {
  const { token } = useContext(AuthContext);
  return token;
};

export { AuthProvider, useAuth, useToken };
