import LearningPathModel from "../../../../core/models/learning-path/learning-path.model";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import TextDecodeUtils from "../../../../core/utils/text-decode.utils";
import React, { useEffect, useState } from "react";
import styles from "./content-learning-path-relation-error-message.module.css";
import { Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import rootStore from "../../../../core/redux/stores/root.store";
import { selectPlaybookViaUid } from "../../../../core/redux/selectors/content/content.selector";
import { selectLearningPathByUid } from "../../../../core/redux/selectors/learning-path/learning-path.selector";
import ContentLearningPathModel from "../../../../core/models/content/content-learning-path.model";
import PlaybookModel from "../../../../core/models/content/playbook.model";

interface Props {
  relations: ContentLearningPathModel[];
  userGroup?: boolean;
}

interface Content {
  playbook?: PlaybookModel;
  learningPath?: LearningPathModel;
}

export default function ContentLearningPathRelationErrorMessage({
  relations,
  userGroup,
}: Props) {
  const { t } = useTranslation();
  const [content, setContent] = useState<Content[]>();

  useEffect(() => {
    const state = rootStore.store.getState();
    const content: Content[] = relations.map((relation) => ({
      playbook: selectPlaybookViaUid(state, relation.contentUid),
      learningPath: selectLearningPathByUid(state, relation.learningPathUid),
    }));
    setContent(content);
  }, [relations]);

  if (!content) {
    return null;
  }

  return (
    <div>
      <Typography.Paragraph>
        {userGroup
          ? t("screens.modal.learning-path-user-group-relation.subtitle")
          : t("screens.modal.learning-path-relation.subtitle")}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {userGroup
          ? t(
              "translations:screens.modal.learning-path-user-group-relation.action",
            )
          : t("translations:screens.modal.learning-path-relation.action")}
      </Typography.Paragraph>
      {content.map(({ learningPath, playbook }, index) => {
        if (!playbook || !learningPath) {
          return null;
        }
        return (
          <div key={index} style={{ marginTop: index !== 0 ? 10 : 0 }}>
            <a
              className={styles.container}
              title={t(
                "translations:screens.modal.learning-path-relation.hover",
                { title: learningPath.title },
              )}
              href={
                window.location.origin +
                RoutesEnum.LEARNING_PATH_DETAIL_LEGACY.replace(
                  ":uid",
                  learningPath.learningPathUid,
                )
              }
            >
              <div className={styles.relation}>
                <Typography.Text className={styles.label}>
                  {Capitalize(t("translations:content.playbook.key"))}
                </Typography.Text>
                <Typography.Text>
                  {TextDecodeUtils(playbook.title)}
                </Typography.Text>
              </div>
              <div
                style={{
                  alignContent: "center",
                  alignSelf: "center",
                  margin: "0 1em",
                }}
              >
                <ArrowRightOutlined style={{ color: "black" }} />
              </div>
              <div className={styles.relation}>
                <Typography.Text className={styles.label}>
                  {Capitalize(t("containers.learning-path.key"))}
                </Typography.Text>
                <Typography.Text>
                  {TextDecodeUtils(learningPath.title)}
                </Typography.Text>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
}
