import BaseContentModel, { BaseContentResponse } from "./base-content.response";
import ContentTypesEnum from "../../enums/content-types.enum";
import CollectionModel, { CollectionResponse } from "./collection.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";

export interface ChapterResponse extends BaseContentResponse {
  chapterUid: string;
  playbookUid: string;
  collections?: CollectionResponse[];
}

export default class ChapterModel extends BaseContentModel {
  chapterUid: string;
  contentType: ContentTypesEnum.CHAPTER;
  playbookUid: string;
  collections?: CollectionModel[];
  getId: string;

  constructor(chapter: ChapterResponse) {
    super(chapter);
    this.chapterUid = chapter.chapterUid;
    this.contentType = ContentTypesEnum.CHAPTER;
    this.playbookUid = chapter.playbookUid;

    if (chapter.collections) {
      this.collections = MapResponseToModel(
        chapter.collections,
        CollectionModel,
      );
    }

    this.getId = `chapter_${this.chapterUid}`;
  }
}
