import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import PageHeaderBreadcrumbsComponent from "../../components/page-header/breadcrumbs/page-header-breadcrumbs.component";
import CollectionDetailGraphsComponent from "../../components/user-progress/collection-detail/collection-detail-graphs.component";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import CollectionDetailAnswersComponent from "../../components/user-progress/collection-detail/collection-detail-answers.component";
import CollectionDetailInfoComponent from "../../components/user-progress/collection-detail/collection-detail-info.component";
import { useParams } from "react-router-dom";
import { useCollectionFromPlaybookDetail } from "../../../core/hooks/content/content.hook";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import { useDispatch, useSelector } from "react-redux";
import NoResultsScreen from "../../screens/no-results.screen";
import UserGroupSelectComponent from "../../components/user-groups/user-group-select.component";
import { selectContentACLs } from "../../../core/redux/selectors/content/content.selector";
import ContentTypesEnum from "../../../core/enums/content-types.enum";

const CollectionDetailContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
  }>();
  const { data: collection } = useCollectionFromPlaybookDetail(
    params.playbookUid,
    params.collectionUid,
  );
  const getContentUserGroups = useSelector((state) =>
    selectContentACLs(
      state,
      ContentTypesEnum.COLLECTION,
      collection ? collection?.collectionUid : "",
    ),
  );

  return (
    <PageLayoutComponent
      menuItems={[]}
      breadCrumbs={<PageHeaderBreadcrumbsComponent />}
      showSider={false}
      secondaryButton={
        <UserGroupSelectComponent userGroups={getContentUserGroups} />
      }
    >
      {collection ? (
        <div>
          <div style={{ marginBottom: "4rem", marginTop: "-3.2rem" }}>
            <Typography.Title level={2}>
              {Capitalize(t(collection.title))}
            </Typography.Title>
            <Typography.Text>
              {Capitalize(t(collection.description))}
            </Typography.Text>
          </div>
          <CollectionDetailInfoComponent collection={collection} />
          <CollectionDetailGraphsComponent />
          <CollectionDetailAnswersComponent collection={collection} />
        </div>
      ) : (
        <div
          style={{
            marginBottom: "4rem",
            marginTop: "-3.2rem",
            minHeight: "65vh",
            display: "flex",
            alignItems: "center",
            maxWidth: "70rem",
            margin: "auto",
          }}
        >
          <NoResultsScreen
            content={params.collectionUid ? "collection" : null}
            onPress={() => dispatch(UserGroupAction.setSelectedUserGroup(""))}
          />
        </div>
      )}
    </PageLayoutComponent>
  );
};

export default CollectionDetailContainer;
