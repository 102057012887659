import React from "react";
import { ContentActionProps } from "../content-action/content-action.component";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Menu } from "antd";
import styles from "../playbook-preview/playbook-preview.module.css";

type Props = {
  actions: ContentActionProps[];
  children?: JSX.Element;
};

const MediaTypesDropdownComponent = ({ actions, children }: Props) => {
  const [t] = useTranslation();
  return (
    <Menu
      style={{
        maxWidth: "20rem",
        boxShadow: "1.5px 2.5px 7.5px var(--grey350-color)",
      }}
    >
      <Menu.ItemGroup
        title={Capitalize(
          t("common.upload_x", { item: t("content.media-types.IMAGE.label") }),
        )}
      >
        {actions.map((actionProps: ContentActionProps, index: number) => {
          const className =
            index === actions.length - 1 ? styles.playbook_dropdown_danger : "";
          return (
            <Menu.ItemGroup key={`${actionProps.label}`}>
              {index === actions.length - 1 && <Menu.Divider />}

              <Menu.Item
                key={`${index.toString()}`}
                className={className}
                icon={actionProps.icon}
                onClick={actionProps.onClick}
              >
                <>
                  {!actionProps.hideLabel && actionProps.label}
                  {index === 0 && children && children}
                </>
              </Menu.Item>
            </Menu.ItemGroup>
          );
        })}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default MediaTypesDropdownComponent;
