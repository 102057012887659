import ContentTypesEnum from "../../enums/content-types.enum";
import {
  AudioProvidersEnum,
  EmbedProvidersEnum,
  ImageProvidersEnum,
  MediaTypesEnum,
  NoMediaTypesEnum,
  VideoProvidersEnum,
} from "../../enums/media-providers.enum";
import { DisplayModeEnum } from "../../enums/display-mode.enum";

export interface MediaResponse {
  contentType: ContentTypesEnum;
  mediaUid: string;
  provider:
    | NoMediaTypesEnum
    | ImageProvidersEnum
    | VideoProvidersEnum
    | AudioProvidersEnum
    | EmbedProvidersEnum;
  mediaType: MediaTypesEnum;
  uri: string;
  createdAt: Date;
  displayMode?: DisplayModeEnum;
}

// TODO: remove NoMediaTypesEnum from provider + mediaUid not undefined!
export default class MediaModel {
  contentType?: ContentTypesEnum;
  mediaUid?: string;
  provider:
    | NoMediaTypesEnum
    | ImageProvidersEnum
    | VideoProvidersEnum
    | AudioProvidersEnum
    | EmbedProvidersEnum;
  mediaType: MediaTypesEnum;
  uri: string;
  displayMode?: DisplayModeEnum;

  constructor(media: MediaResponse) {
    this.contentType = media.contentType;
    this.mediaUid = media.mediaUid;
    this.provider = media.provider;
    this.mediaType = media.mediaType;
    this.uri = media.uri;
    this.displayMode = media.displayMode;
  }
}
