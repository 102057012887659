import React, { CSSProperties } from "react";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";
import {
  GradientColors_ProgressCompleted,
  GradientColors_ProgressPending,
  GradientColors_ProgressStarted,
} from "../../styles/colors";
import { Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import styles from "../../components/user-progress/user-progress.module.css";

interface OwnProps {
  style?: CSSProperties;
}

type Props = OwnProps;

const ContentProgressLegend = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div style={props.style}>
      {Object.keys(ContentProgressEnum).map((key) => {
        let style;
        switch (key) {
          case ContentProgressEnum.PENDING:
            style = {
              background: `linear-gradient(to right, ${GradientColors_ProgressPending.from}, ${GradientColors_ProgressPending.to})`,
            };
            break;
          case ContentProgressEnum.STARTED:
            style = {
              background: `linear-gradient(to right, ${GradientColors_ProgressStarted.from}, ${GradientColors_ProgressStarted.to})`,
            };
            break;
          case ContentProgressEnum.COMPLETED:
            style = {
              background: `linear-gradient(to right, ${GradientColors_ProgressCompleted.from}, ${GradientColors_ProgressCompleted.to})`,
            };
            break;
        }
        return (
          <LegendItem
            key={key}
            style={style}
            label={Capitalize(t(`screens.user-progress.state.${key}`))}
          />
        );
      })}
    </div>
  );
};

interface LegendItemProps {
  label: string;
  style: CSSProperties;
}

const LegendItem = ({ label, style }: LegendItemProps) => {
  return (
    <div style={{ display: "flex", margin: "0.5rem" }}>
      <div className={styles.legenda_item_bullet} style={style} />
      <Typography.Text type={"secondary"} style={{ fontSize: "1.2rem" }}>
        {label}
      </Typography.Text>
    </div>
  );
};

export default ContentProgressLegend;
