import React, { useEffect, useState } from "react";
import { useTrackedPlaybooksProgress } from "../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { HeartOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import PlaybookUserProgressComponent from "../content-user-progress/user-progress-types/playbook-user-progress.component";
import styles from "./user-progress.module.css";
import PlaybookModel from "../../../core/models/content/playbook.model";
import { useSelector } from "react-redux";
import { playbooksSelector } from "../../../core/redux/selectors/content/content.selector";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import UserProgressModel from "../../../core/models/user-progress/user-progress/user-progress.model";
import PlaybookUserProgressModel from "../../../core/models/user-progress/user-progress/user-progress-types/playbook-user-progress.model";

const TrackedPlaybooksComponent = () => {
  const { t } = useTranslation();
  const [filteredPlaybooks, setFilteredPlaybooks] = useState<PlaybookModel[]>(
    [],
  );
  const { data: trackedPlaybooks } = useTrackedPlaybooksProgress();
  const allPlaybooks: PlaybookModel[] = useSelector(playbooksSelector);
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );
  const filtered = trackedPlaybooks && filterTrackedPlaybooks(trackedPlaybooks);

  useEffect(() => {
    if (currentlySelectedUserGroup === "") {
      return setFilteredPlaybooks(allPlaybooks);
    }

    setFilteredPlaybooks(
      allPlaybooks.filter((playbook) =>
        playbook.userGroupAcl.includes(currentlySelectedUserGroup),
      ),
    );
  }, [currentlySelectedUserGroup]);

  return (
    <div className={styles.pcup_container}>
      <Typography.Text className={styles.pcup_heading}>
        {Capitalize(t("common.favorite")) +
          " " +
          t("containers.modules.key_plural")}
      </Typography.Text>

      {filtered && filtered.playbooks.length > 0 ? (
        <div className={styles.pcup_wrapper} style={{ overflow: "scroll" }}>
          {filtered.playbooks.map((playbook, index) => (
            <PlaybookUserProgressComponent
              key={index}
              playbookUid={playbook.playbookUid}
            />
          ))}
        </div>
      ) : (
        <div className={styles.pcup_card_empty}>
          <HeartOutlined className={styles.heart_icon} />
          <Typography.Text
            type={"secondary"}
            className={styles.pcup_card_empty_text}
          >
            {t("screens.user-progress.favorite.empty-state")}
          </Typography.Text>
        </div>
      )}
    </div>
  );

  function filterTrackedPlaybooks(playbooks) {
    const newPlaybooks: PlaybookUserProgressModel[] = [];
    const newTrackedPlaybooks: UserProgressModel = { playbooks: [] };
    Object.keys(playbooks).map((playbook) => {
      playbooks[playbook].map((value) => {
        filteredPlaybooks.map((item) => {
          if (value.playbookUid === item.playbookUid) {
            newPlaybooks.push(value);
          }
        });
      });
    });
    newTrackedPlaybooks.playbooks = newPlaybooks;
    return newTrackedPlaybooks;
  }
};

export default TrackedPlaybooksComponent;
