const colors = {
  primary: "#1890FF",
  secondary: "#F5222D",
  grey10: "#262626",
};

export const GradientColors_ProgressPending = {
  from: "#D0D0D0", //rgb(208, 208, 208)
  to: "#e3e3e3",
};

export const GradientColors_ProgressStarted = {
  from: "#FAAD14", //rgb(250, 173, 20)
  to: "#FFD666",
};

export const GradientColors_ProgressCompleted = {
  from: "#73D13D", //rgb(115, 209, 61)
  to: "#BAE637",
};

export const GradientColors_GraphBlue = {
  from: "#91D5FF",
  to: "#1890FF",
};

export const GradientColors_GraphPink = {
  from: "#FFADD2",
  to: "#F759AB", //rgb(247, 89, 171)
};

export const GradientColors_GraphPurple = {
  from: "#D3ADF7",
  to: "#9254DE",
};

export const GradientColors_GraphYellow = {
  from: "#FFD572",
  to: "#FAAD14",
};

export default colors;
