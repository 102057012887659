import React from "react";
import useImportedMedia from "../../../core/hooks/use-imported-media";

interface Props {
  children: (path: string) => React.ReactNode;
  path: string;
}

const ImportedMedia = ({ path, children }: Props) => {
  const { data } = useImportedMedia(path);

  if (!data) {
    return null;
  }

  return <>{children(data)}</>;
};

export default ImportedMedia;
