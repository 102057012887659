import React, { useEffect, useState } from "react";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Col, Collapse, Row } from "antd";
import { getContentTypeFromParamsUtils } from "../../../core/utils/get-contentType-from-params.utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageHeaderBreadcrumbsComponent from "../../components/page-header/breadcrumbs/page-header-breadcrumbs.component";
import CollapseButtonComponent from "../../components/actions/collapse-button.component";
import SectionTitleComponent from "../../components/section-title/section-title.component";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import isInViewport from "../../../core/utils/is-in-viewport.utils";
import PlaybookUserProgressComponent from "../../components/content-user-progress/user-progress-types/playbook-user-progress.component";
import ChapterUserProgressComponent from "../../components/content-user-progress/user-progress-types/chapter-user-progress.component";
import CollectionUserProgressComponent from "../../components/content-user-progress/user-progress-types/collection-user-progress.component";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import { usePlaybookDetails } from "../../../core/hooks/content/content.hook";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import NoResultsScreen from "../../screens/no-results.screen";
import UserGroupSelectComponent from "../../components/user-groups/user-group-select.component";

const ModuleDetailContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ playbookUid: string; chapterUid?: string }>();
  const contentType = getContentTypeFromParamsUtils(params);
  const { data: playbookDetail, isLoading } = usePlaybookDetails(
    params.playbookUid,
  );
  const [expandAll, setExpandAll] = useState<string | string[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );
  const filteredPlaybookDetails = filterPlaybookDetailsByUserGroup();

  const menuItems = () => {
    const menuItems: MenuItemInterface[] = [
      {
        title: t("containers.activity.sidebar"),
        route: RoutesEnum.MODULE_DETAIL,
      },
    ];

    return menuItems;
  };

  useEffect(() => {
    if (contentType === ContentTypesEnum.CHAPTER && params.chapterUid) {
      setExpandAll([params.chapterUid]);

      if (containerRef.current && !isInViewport(containerRef)) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [params, contentType]);

  if (isLoading && !playbookDetail) {
    return (
      <PageLayoutComponent
        menuItems={menuItems()}
        breadCrumbs={<PageHeaderBreadcrumbsComponent />}
        showSider={false}
      >
        <p>Loading</p>
      </PageLayoutComponent>
    );
  }

  if (!playbookDetail) {
    return (
      <PageLayoutComponent
        menuItems={menuItems()}
        breadCrumbs={<PageHeaderBreadcrumbsComponent />}
        showSider={false}
      >
        <p>No Data Found</p>
      </PageLayoutComponent>
    );
  }

  return (
    <PageLayoutComponent
      menuItems={menuItems()}
      breadCrumbs={<PageHeaderBreadcrumbsComponent />}
      showSider={false}
      secondaryButton={
        <UserGroupSelectComponent
          userGroups={filteredPlaybookDetails?.userGroupAcl}
        />
      }
    >
      {filteredPlaybookDetails ? (
        <div style={{ margin: "0 auto", width: "60rem" }}>
          <PlaybookUserProgressComponent
            playbookUid={params.playbookUid}
            showAction={false}
            showSuffixAction={false}
            style={{ width: "100%" }}
            size={"large"}
          />
          <div style={{ margin: "4rem 0" }}>
            {filteredPlaybookDetails.chapters
              .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED)
              .sort((a, b) => a.sort - b.sort)
              .map((chapter, index) => {
                return (
                  <div key={index}>
                    {renderChapterSectionTitle()}
                    <Collapse
                      activeKey={expandAll}
                      onChange={setExpandAll}
                      className={"Module_detail"}
                      expandIcon={() => null}
                      ghost
                    >
                      <Collapse.Panel
                        key={chapter.chapterUid}
                        header={
                          <ChapterUserProgressComponent
                            chapter={chapter}
                            showAction={expandAll.includes(chapter.chapterUid)}
                            style={{ width: "100%", margin: "0 0 2rem 0" }}
                            size={"middle"}
                          />
                        }
                      >
                        {chapter.collections.length > 0 && (
                          <div ref={containerRef}>
                            {renderCollectionSectionTitle()}
                            {chapter.collections
                              .filter(
                                (c) =>
                                  c.contentState ===
                                  ContentStatesEnum.PUBLISHED,
                              )
                              .sort((a, b) => a.sort - b.sort)
                              .map((collection) => (
                                <CollectionUserProgressComponent
                                  key={collection.collectionUid}
                                  style={{
                                    width: "100%",
                                    margin: "0 0 1rem 0",
                                  }}
                                  playbookUid={params.playbookUid}
                                  collection={collection}
                                />
                              ))}
                          </div>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div style={{ margin: "0 auto", width: "70rem" }}>
          <NoResultsScreen
            content={params.playbookUid ? "playbook" : null}
            onPress={() => dispatch(UserGroupAction.setSelectedUserGroup(""))}
          />
        </div>
      )}
    </PageLayoutComponent>
  );

  function renderChapterSectionTitle() {
    return (
      <Row>
        <Col flex={1}>
          <SectionTitleComponent title={t("content.chapter.key_plural")} />
        </Col>
        <Col>
          <CollapseButtonComponent
            expand={expandAll.length === 0}
            setCollapse={() => setExpandAll([])}
            setExpand={() => {
              if (!playbookDetail?.chapters) {
                return;
              }
              setExpandAll(
                playbookDetail.chapters.map((chap) => chap.chapterUid),
              );
            }}
          />
        </Col>
      </Row>
    );
  }

  function filterPlaybookDetailsByUserGroup() {
    if (
      !playbookDetail?.userGroupAcl.includes(currentlySelectedUserGroup) &&
      currentlySelectedUserGroup !== ""
    ) {
      return null;
    }
    return playbookDetail;
  }

  function renderCollectionSectionTitle() {
    return <SectionTitleComponent title={t("content.collection.key_plural")} />;
  }
};

export default ModuleDetailContainer;
