import React from "react";
import { Bar } from "@ant-design/charts";

export interface BarGroupedChartData {
  type: string;
  amount: number;
  percentage?: number;
}

const BarGroupedComponent = ({ data }: { data: BarGroupedChartData[] }) => {
  const config = {
    data,
    isGroup: true,
    xField: "amount",
    yField: "type",
    seriesField: "type",
    color: ["#1890FF", "#13C2C2", "#FFC53D"],
    height: 160,
    marginRatio: 0,
    padding: [0, 0, 40, 0],
    intervalPadding: 0,
    dodgePadding: 0,
    minBarWidth: 20,
    maxBarWidth: 25,
    barStyle: {
      radius: [10, 10, 10, 10],
    },
    label: {
      position: "middle",
      offsetX: 4,
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      grid: {
        line: {
          style: { opacity: 0 },
        },
      },
      tickInterval: 1,
      label: {
        autoHide: true,
        style: { opacity: 0 },
      },
    },
    yAxis: {
      grid: {
        line: {
          style: { opacity: 0 },
        },
      },
      label: {
        autoHide: true,
        style: { opacity: 0 },
      },
      line: {
        style: { opacity: 0 },
      },
      tickLine: {
        style: { opacity: 0 },
      },
      subTickLine: {
        count: 1,
        style: { opacity: 0 },
      },
    },
    tooltip: {
      fields: ["type", "amount", "percentage"],
      formatter: function renderText(data: any) {
        if (data.percentage) {
          return {
            name: data.type,
            value: `${data.percentage}% (${data.amount})`,
          };
        } else {
          return { name: data.type, value: `${data.amount}` };
        }
      },
      follow: true,
      showContent: true,
      showTitle: false,
      enterable: true,
      offset: 100,
      domStyles: {
        "g2-tooltip": { borderRadius: "10rem" },
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    interactions: [{ type: "element-active" }],
    options: {
      hover: { mode: null },
    },
  };

  return <Bar {...config} />;
};

export default BarGroupedComponent;
