import ActionInterface from "../../../interfaces/action.interface";
import HttpErrorResponseModel from "../../../models/http-error-response.model";

type ReducerMethod<T> = (
  state: T,
  action: ActionInterface<any | HttpErrorResponseModel>,
) => T;

interface IndexSignature {
  [key: string]: any;
}

export default class BaseReducer<Type extends IndexSignature> {
  [key: string]: any;

  initialState: Type = {} as any;

  reducer = (
    state: Type = this.initialState,
    action: ActionInterface<any | HttpErrorResponseModel>,
  ): Type => {
    // if the action type is used for a method name then this be a reference to
    // that class method.
    // if the action type is not found then the "method" const will be undefined.
    const method: ReducerMethod<Type> | undefined = this[action.type];

    // if the action type "method" const is undefined or the action is an error
    // return the state.
    if (
      !method ||
      action.error ||
      action.payload instanceof HttpErrorResponseModel
    ) {
      return state;
    }

    // Calls the method with the correct "this" and returns the modified state.
    return method.call(this, state, action);
  };

  /**
   * Add a single object to an array of objects
   * @param single
   * @param array
   * @param fieldName
   * @return Array with edited or added single object
   * @protected
   */
  protected addSingleToArray<Type extends IndexSignature>(
    single: Type,
    array: Type[] | undefined,
    fieldName: string,
  ): Type[] {
    if (!array || array.length === 0) {
      return [single];
    }

    if (!(fieldName in single)) {
      console.error(
        "Object is missing required fieldname, " +
          "please check if fieldName is correct or object contains the desired field name",
        fieldName,
      );
      return array;
    }

    const editedObjectIndex = array.findIndex(
      (item) => item[fieldName] === single[fieldName],
    );

    if (editedObjectIndex === -1) {
      array.push(single);
    } else {
      array[editedObjectIndex] = single;
    }
    return array;
  }

  /**
   * Add one array to another but replace the once that already exists
   * @param A
   * @param B
   * @param fieldName
   * @protected
   */
  protected addArrayToArray<Type extends object>(
    A: Type[],
    B: Type[] | undefined,
    fieldName: string,
  ): Type[] {
    if (A.length === 0 && B?.length === 0) {
      return [];
    }

    if (!B || B.length === 0) {
      return A;
    }

    if (A.length === 0 && B.length > 0) {
      return B;
    }

    if (!(fieldName in A[0]) || !(fieldName in B[0])) {
      console.error(
        "Object is missing required fieldname, " +
          "please check if fieldName is correct or object contains the desired field name",
        fieldName,
      );
      return B;
    }

    const B_Copy: Type[] = [...B];

    A.forEach((item: Type) => {
      const editedObjectIndex = B_Copy.findIndex(
        (b) => b[fieldName] === item[fieldName],
      );

      if (editedObjectIndex === -1) {
        B_Copy.push(item);
      } else {
        B_Copy[editedObjectIndex] = item;
      }
    });

    return B_Copy;
  }

  /**
   * Remove a single object from an array of objects
   * @param single
   * @param array
   * @param fieldName
   * @return Array with edited or added single object
   * @protected
   */
  protected removeSingleFromArray<Type extends IndexSignature>(
    single: Type,
    array: Type[] | undefined,
    fieldName: string,
  ): Type[] {
    if (!array) {
      return [single];
    }
    if (!(fieldName in single)) {
      console.error(
        "Object is missing required fieldname, " +
          "please check if fieldName is correct or object contains the desired field name",
        fieldName,
      );
      return array;
    }

    const editedObjectIndex = array.findIndex(
      (item) => item[fieldName] === single[fieldName],
    );

    if (editedObjectIndex === -1) {
      return array;
    } else {
      array.splice(editedObjectIndex, 1);
    }

    return array;
  }
}
