enum ContentSortEnum {
  CUSTOM = "CUSTOM",
  ALPHABETICAL = "ALPHABETICAL",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  PUBLISHED_AT_DESC = "PUBLISHED_AT_DESC",
  PUBLISHED_AT_ASC = "PUBLISHED_AT_ASC",
}

export default ContentSortEnum;
