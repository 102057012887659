import UserCardCompletionModel, {
  UserCardCompletionResponse,
} from "./user-card-completion.model";

interface UserCollectionCompletionResponse {
  [collectionUid: string]: UserCardCompletionResponse;
}

export default class UserCollectionCompletionModel {
  collectionUid: string;
  cards: UserCardCompletionModel[];

  constructor(response: UserCollectionCompletionResponse) {
    this.collectionUid = Object.keys(response)[0];
    this.cards = [];

    Object.values(response).map((res) => {
      Object.entries(res).forEach(([cardUid, value]) => {
        this.cards.push(new UserCardCompletionModel(cardUid, value));
      });
    });
  }
}
