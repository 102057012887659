import { NotificationCreateInterface } from "../../../interfaces/notification-create.interface";
import { EffectType } from "../../../types/redux.type";
import NotificationTypes from "../../../types/notification.types";
import HttpUtility from "../../../utils/http.utils";
import EffectUtility from "../../../utils/effect.utils";
import BaseNotificationModel from "../../../models/notification/base-notification.model";
import BaseNotificationCountModel from "../../../models/notification/base-notification-count.model";
import NotificationEndpoints from "../../../api/notification.endpoints";

export default class NotificationEffect {
  /**
   * Get Scheduled Notifications (e.g. next or last week)
   * @param startDate Date
   * @return Promise BaseNotificationModel[]
   */
  static getScheduledNotifications(
    startDate: Date,
  ): EffectType<NotificationTypes[]> {
    const endpoint = NotificationEndpoints.overview(startDate);
    return EffectUtility.getToModel<NotificationTypes[]>(
      BaseNotificationModel,
      endpoint,
    );
  }

  /**
   * Get total amount of Scheduled Notifications
   * @return Promise BaseNotificationCountModel[]
   */
  static getCountScheduledNotifications(): EffectType<BaseNotificationCountModel> {
    const endpoint = NotificationEndpoints.count;
    return EffectUtility.getToModel<BaseNotificationCountModel>(
      BaseNotificationCountModel,
      endpoint,
    );
  }

  /**
   * Get one Notification via GET Request
   * @param key string
   * @returns Promise BaseNotificationModel
   */
  static getNotification(key: string): EffectType<NotificationTypes> {
    const endpoint = NotificationEndpoints.detail(key);
    return EffectUtility.getToModel<NotificationTypes>(
      BaseNotificationModel,
      endpoint,
    );
  }

  /**
   * Create Notification for Users or UserGroup via POST Request
   * @param data NotificationCreateInterface
   * @returns Promise BaseNotificationModel
   */
  static createNotification(
    data: NotificationCreateInterface,
  ): EffectType<NotificationTypes> {
    const endpoint = NotificationEndpoints.create;
    return EffectUtility.postToModel<NotificationTypes>(
      BaseNotificationModel,
      endpoint,
      data,
    );
  }

  /**
   * Delete Scheduled Notification via DELETE Request
   * @param notification NotificationTypes
   * @returns Promise HttpRequest
   */
  static deleteScheduledNotification(notification: NotificationTypes) {
    const endpoint = NotificationEndpoints.delete(notification);
    return HttpUtility.delete(endpoint);
  }
}
