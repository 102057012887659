import React from "react";
import {
  ColumnHeightOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import { Button, Divider } from "antd";
import { grey } from "@ant-design/colors";
import styles from "../../card-types/card-types.module.css";

interface Props {
  hiddenField: boolean;
  onClick: () => void;
}

const FormDividerComponent = ({ hiddenField, onClick }: Props) => {
  return (
    <div className={styles.form_divider_wrapper} onClick={onClick}>
      <Divider style={{ marginTop: hiddenField ? "0px" : "" }}>
        <Button
          type={"text"}
          size={"small"}
          shape={"circle"}
          className={styles.form_divider_button}
          style={{ color: grey[0] }}
          icon={
            hiddenField ? (
              <ColumnHeightOutlined />
            ) : (
              <VerticalAlignMiddleOutlined />
            )
          }
          onClick={onClick}
        />
      </Divider>
    </div>
  );
};

export default FormDividerComponent;
