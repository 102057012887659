import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { Button, Checkbox, Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { selectUserGroups } from "../../../core/redux/selectors/user-group/user-group.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentType from "../../../core/types/content.type";
import { getLearningPathContentForContentType } from "../../../core/hooks/learning-path/learning-path.api";
import ContentLearningPathModel from "../../../core/models/content/content-learning-path.model";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import colors from "../../styles/colors";
import ContentLearningPathRelationErrorMessage from "../content-context-actions/modal/content-learning-path-relation-error-message";
import { selectUserGroupsFromLearningPathContent } from "../../../core/redux/selectors/learning-path/learning-path.selector";
import { useUserGroupFromLearningPath } from "../../../core/hooks/learning-path/learning-path.hook";
import { LearningPathStateEnum } from "../../../core/enums/learning-path-state.enum";
import { filterRoleGroups } from "../../../core/utils/filter-role-groups";

export interface ExclusiveContentRef {
  excludeContent: (content: ContentType) => void;
}

const ExclusiveContentModal = forwardRef(
  (props, ref: Ref<ExclusiveContentRef>) => {
    const [t] = useTranslation();
    const [content, setContent] = useState<ContentType>();
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const groups: UserGroupModel[] = useSelector((state: StoreInterface) =>
      selectUserGroups(state),
    );
    const filteredAdminGroups = filterRoleGroups(groups);
    const dispatch = useDispatch();
    const updatedLearningPaths = useSelector(
      selectUserGroupsFromLearningPathContent,
    );

    const requesting = useSelector((state) =>
      selectRequesting(state, [ContentAction.REQUEST_UPDATE_CONTENT]),
    );
    const learningPathUid = getLearningPathFromPlaybook();
    const { data: userGroups } = useUserGroupFromLearningPath(learningPathUid);
    const filteredPlaybookUserGroup: any = userGroups?.contentUserGroups.filter(
      (u: any) =>
        u.playbookUid ===
        (content?.contentType === ContentTypesEnum.PLAYBOOK &&
          content.playbookUid),
    );
    useImperativeHandle(ref, () => ({ excludeContent }));

    useEffect(() => {
      if (!requesting && isSubmitting) {
        setIsSubmitting(false);
        if (isSubmitting) handleOnCancel();
      }
    }, [requesting]);

    useEffect(() => {
      setCheckedKeys(setChecked());
    }, [content]);

    function getLearningPathFromPlaybook() {
      if (!content) return "";
      let newLearningPathUid = "";
      updatedLearningPaths.forEach((paths) => {
        paths.content.forEach((item) => {
          if (
            item.contentUid ===
            (content.contentType === ContentTypesEnum.PLAYBOOK
              ? content.playbookUid
              : null)
          ) {
            newLearningPathUid = item.learningPathUid;
          }
        });
      });
      return newLearningPathUid;
    }

    function setChecked(): string[] {
      if (!content) return [];

      let userGroupACL: string[] = [];

      if (
        content.contentType === ContentTypesEnum.PLAYBOOK &&
        content.userGroupAcl
      ) {
        userGroupACL = content.userGroupAcl;
      }

      return userGroupACL;
    }

    function excludeContent(content: ContentType) {
      setContent(content);
      setIsModalVisible(true);
    }

    return (
      <Modal
        open={isModalVisible}
        title={t("screens.exclusive-publish.title")}
        confirmLoading={requesting}
        bodyStyle={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          minHeight: "45rem",
        }}
        className={"Modal_exclusive"}
        onCancel={handleOnCancel}
        width={700}
        centered
        footer={[
          <Button key={0} type={"text"} onClick={handleOnCancel}>
            {Capitalize(t("common.cancel"))}
          </Button>,
          <Button
            key={1}
            type={"primary"}
            loading={isSubmitting}
            onClick={handleOnOk}
            style={{
              color: "var(--grey800-color)",
              borderColor: "var(--exclusive-color-primary)",
              backgroundColor: "var(--exclusive-color-primary)",
            }}
          >
            {Capitalize(t("common.exclusive-make"))}
          </Button>,
        ]}
      >
        {renderModalBody()}
      </Modal>
    );

    function handleOnCancel() {
      setCheckedKeys(setChecked());
      setIsModalVisible(false);
    }

    function handleOnOk() {
      if (!content) return;
      if (content.contentType === ContentTypesEnum.CARD) return;
      const data: Partial<ContentType> = { userGroupAcl: checkedKeys };

      dispatch(ContentAction.updateContent(content.contentType, content, data));
      setIsSubmitting(true);
    }

    function alertContentUserGroupLearningPath(
      relations: ContentLearningPathModel[],
      context: string,
    ) {
      Modal.error({
        title: t(`screens.modal.learning-path-${context}-relation.subtitle`),
        icon: (
          <ContextActionIconComponent
            action={"delete"}
            style={{ color: colors.secondary }}
          />
        ),
        content: (
          <ContentLearningPathRelationErrorMessage
            userGroup={true}
            relations={relations}
          />
        ),
      });
    }

    async function onChangeCheckbox(e: CheckboxChangeEvent) {
      if (!content) return;
      const response = await getLearningPathContentForContentType(content);
      const isInPublishedLearningPath =
        content.learningPaths &&
        content.learningPaths.filter(
          (path) => path.state === LearningPathStateEnum.POSTED,
        );
      const isContentPublic =
        filteredPlaybookUserGroup &&
        filteredPlaybookUserGroup[0]?.userGroupAcl.length === 0;
      const userCanAccessContent =
        filteredPlaybookUserGroup &&
        filteredPlaybookUserGroup?.[0]?.userGroupAcl.includes(e.target.value);
      const isResponseValid = response && response.length > 0;

      if (
        (isContentPublic || userCanAccessContent) &&
        isInPublishedLearningPath &&
        isResponseValid
      ) {
        return alertContentUserGroupLearningPath(
          response,
          isContentPublic ? "playbook" : "user-group",
        );
      }

      if (e.target.checked) {
        const newKeys = [...checkedKeys];
        newKeys.push(e.target.value);
        setCheckedKeys(newKeys);
      } else {
        const newKeys = checkedKeys.filter((key) => key !== e.target.value);
        setCheckedKeys(newKeys);
      }
    }

    function renderModalBody() {
      const title = content ? `“${TextDecodeUtils(content.title)}”` : "";

      return (
        <>
          <Typography.Text style={{ paddingBottom: "2rem" }}>
            {t("screens.exclusive-publish.subtitle")} {title}
          </Typography.Text>

          <div
            style={{
              display: "flex",
              flexFlow: "column",
              maxHeight: "38.3rem",
              overflow: "scroll",
            }}
          >
            {groups &&
              filteredAdminGroups.map((group, index) => (
                <Checkbox
                  key={index}
                  value={group.title}
                  checked={checkedKeys.includes(group.title)}
                  className={"Checkbox_exclusive"}
                  onChange={onChangeCheckbox}
                >
                  {group.title}
                </Checkbox>
              ))}
          </div>
        </>
      );
    }
  },
);

ExclusiveContentModal.displayName = "ExclusiveContentModal";

export default ExclusiveContentModal;
