import React, { CSSProperties } from "react";
import { Typography } from "antd";
import { GroupTags } from "../../group-tag";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";

type Props = {
  userGroupAcl: string[];
  style?: CSSProperties;
};

const ContentExclusiveComponent = ({ userGroupAcl }: Props) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: 24 }}>
      <Typography.Title level={4}>
        {Capitalize(t("containers.user-groups.key_plural"))}
      </Typography.Title>
      <div>
        <GroupTags groupNames={userGroupAcl} />
      </div>
    </div>
  );
};

export default ContentExclusiveComponent;
