import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useUpdateApiKeyMutation } from "../../../core/hooks/api-key/api-key.hook";
import { CreateApiKeyInterface } from "../../../core/interfaces/create-api-key.interface";
import AddContentToLinkForm, {
  AddContentToLinkFormRef,
} from "../forms/add-content-to-link.form";
import ApiKeyModel from "../../../core/models/api-key/api-key.model";
import { queryClient } from "../../../core/providers/app.provider";

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
  apiKey: ApiKeyModel;
  preselectedContent: string[];
}

type Props = OwnProps;

const AddContentToLinkModal = (props: Props) => {
  const [t] = useTranslation();
  const { visible, onCancel, apiKey, preselectedContent } = props;
  const addContentToLinkFormRef = useRef<AddContentToLinkFormRef>(null);

  const { mutate } = useUpdateApiKeyMutation(apiKey.key);

  return (
    <Modal
      open={visible}
      title={t("containers.shared-content.add-content-to-link")}
      width={"80rem"}
      onCancel={handleOnCancel}
      onOk={handleOnOk}
      okText={"Ok"}
      closable={false}
    >
      <AddContentToLinkForm
        preselectedContent={preselectedContent}
        ref={addContentToLinkFormRef}
      />
    </Modal>
  );

  function handleOnCancel() {
    onCancel();
    if (addContentToLinkFormRef.current) {
      addContentToLinkFormRef.current.form.resetFields();
    }
  }

  function handleOnOk() {
    if (addContentToLinkFormRef.current) {
      addContentToLinkFormRef.current.form.validateFields().then((res) => {
        const dataAddContent: Partial<CreateApiKeyInterface> = {
          playbookUids: res.content,
        };

        mutate(dataAddContent, {
          onSuccess: () => {
            queryClient.invalidateQueries(["apiKey", apiKey.key]);
            handleOnCancel();
          },
          onError: (error) => {
            Modal.error({
              content: error.message,
            });
          },
        });
      });
    }
  }
};

export default AddContentToLinkModal;
