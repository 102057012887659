import BaseCompletedUserProgressModel, {
  BaseCompletedUserProgressResponse,
} from "./base-completed-user-progress.model";
import CardCompletedUserProgressModel from "./card-completed-user-progress.model";

export interface CollectionCompletedUserProgressResponse
  extends BaseCompletedUserProgressResponse {
  cards: CardCompletedUserProgressModel[];
}

export default class CollectionCompletedUserProgressModel extends BaseCompletedUserProgressModel {
  collectionUid: string;
  cards: CardCompletedUserProgressModel[];

  constructor(
    collectionUid: string,
    response: CollectionCompletedUserProgressResponse,
  ) {
    super(response);
    this.collectionUid = collectionUid;

    if (response.cards) {
      this.cards = Object.entries(response.cards).map(([cardUid, value]) => {
        return new CardCompletedUserProgressModel(cardUid, value);
      });
    } else {
      this.cards = [];
    }
  }
}
