import CryptoJS from "crypto-js";
import ApiKeyHashContent, {
  instanceOfApiKeyHashContent,
} from "../types/api-key-hash-content";
import { API_KEY_HASH_KEY } from "../../constants";
import { API_URL } from "../../constants/config/env.config";

export default function encryptCipher(apiKeyUid: string, description: string) {
  const dataToEncrypt: ApiKeyHashContent = {
    apiKeyUid,
    description,
    server: API_URL,
  };

  return CryptoJS.AES.encrypt(
    JSON.stringify(dataToEncrypt),
    API_KEY_HASH_KEY,
  ).toString();
}

export function decryptCipher(cipher: string) {
  const bytes = CryptoJS.AES.decrypt(cipher, API_KEY_HASH_KEY);
  const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  if (instanceOfApiKeyHashContent(originalData)) {
    return originalData;
  }
}
