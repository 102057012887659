export interface SliderOptionResponse {
  sort: number;
  label: string;
}

export default class SliderOptionModel {
  sort: number;
  label: string;

  constructor(sliderCard: SliderOptionResponse) {
    this.sort = sliderCard.sort;
    this.label = sliderCard.label;
  }
}
