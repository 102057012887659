import React, { useState } from "react";
import { Form, Typography } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import FormDividerComponent from "../../form-builder/components/form-divider.component";
import CardBasePreviewComponent from "../base-card/card-base-preview.component";
import OptionPreviewComponent from "./option-preview.component";
import MediaPreviewComponent from "../../media-types/media-preview.component";
import MultipleChoiceCardModel from "../../../../core/models/content/card-types/multiple-choice/multiple-choice-card.model";
import QuizCardModel from "../../../../core/models/content/card-types/quiz/quiz-card.model";
import SliderCardModel from "../../../../core/models/content/card-types/slider/slider-card.model";
import styles from "../card-types.module.css";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";

interface OwnProps {
  cardContent: MultipleChoiceCardModel | QuizCardModel | SliderCardModel;
  showBaseFields?: boolean;
}

type Props = OwnProps;

const OptionCardPreviewComponent = ({
  cardContent,
  showBaseFields = true,
}: Props) => {
  const [t] = useTranslation();
  const [hiddenField, setHiddenField] = useState<boolean>(false);

  return (
    <Form
      labelCol={{ span: 6 }}
      layout={"horizontal"}
      labelAlign={"left"}
      className={styles.form_wrapper_horizontal}
    >
      {showBaseFields &&
        !cardContent.media &&
        !hiddenField &&
        cardContent.cardType !== CardTypesEnum.QUIZ && (
          <CardBasePreviewComponent cardContent={cardContent} />
        )}

      {showBaseFields &&
        !cardContent.media &&
        cardContent.cardType !== CardTypesEnum.QUIZ && (
          <FormDividerComponent
            hiddenField={hiddenField}
            onClick={() => setHiddenField(!hiddenField)}
          />
        )}

      <div className={"Form_item_card"}>
        {cardContent.media && cardContent.media[0] && (
          <Form.Item
            label={
              <Typography.Text strong>
                {Capitalize(t(`form.card.media.label`))}
              </Typography.Text>
            }
          >
            <MediaPreviewComponent media={cardContent.media[0]} />
          </Form.Item>
        )}

        <Form.Item
          label={
            <Typography.Text strong>
              {Capitalize(t(`form.card.question.label`))}
            </Typography.Text>
          }
        >
          <Typography.Text>
            {TranslateDefaults(cardContent.data.question)}
          </Typography.Text>
        </Form.Item>

        <OptionPreviewComponent options={cardContent.data.options} />
      </div>
    </Form>
  );
};

export default OptionCardPreviewComponent;
