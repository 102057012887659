import PlaybookEndpoints from "./endpoints/playbook.endpoints";
import ChapterEndpoints from "./endpoints/chapter.endpoints";
import CardEndpoints from "./endpoints/card.endpoints";
import CollectionEndpoints from "./endpoints/collection.endpoints";
import ContentTypesEnum from "../../enums/content-types.enum";

export class ContentApi {
  endpoints:
    | PlaybookEndpoints
    | ChapterEndpoints
    | CollectionEndpoints
    | CardEndpoints;

  constructor(contentType: ContentTypesEnum) {
    switch (contentType) {
      case ContentTypesEnum.PLAYBOOK:
        this.endpoints = new PlaybookEndpoints();
        break;
      case ContentTypesEnum.CHAPTER:
        this.endpoints = new ChapterEndpoints();
        break;
      case ContentTypesEnum.COLLECTION:
        this.endpoints = new CollectionEndpoints();
        break;
      case ContentTypesEnum.CARD:
        this.endpoints = new CardEndpoints();
        break;
    }
  }
}
