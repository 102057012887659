import React, { useState } from "react";
import { TimeBasedLearningPathContentNotification } from "../../../../core/models/learning-path/time-based-content.model";
import { useTranslation } from "react-i18next";
import { BellOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Collapse, Row, Typography } from "antd";
import styles from "../learning-path.module.css";

type Props = {
  notification: TimeBasedLearningPathContentNotification;
};

const PreviewLearningPathContentMetaComponent = ({ notification }: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Collapse
      ghost
      className={styles.collapse}
      onChange={(e) => setIsExpanded(e.length === 1)}
    >
      <Collapse.Panel
        key={0}
        className={"Panel_2"}
        showArrow={false}
        header={renderHeader()}
        collapsible={"header"}
        style={{ marginTop: "-2px" }}
      >
        <Typography.Text className={styles.collapse_body}>
          {t(notification.message)}
        </Typography.Text>
      </Collapse.Panel>
    </Collapse>
  );

  function renderHeader() {
    return (
      <Row className={styles.collapse_header}>
        <Col>
          <BellOutlined style={{ paddingRight: "2rem" }} />
          <Typography.Text>{t(notification.title)}</Typography.Text>
        </Col>
        <Col>
          <UpOutlined rotate={isExpanded ? 0 : 180} />
        </Col>
      </Row>
    );
  }
};

export default PreviewLearningPathContentMetaComponent;
