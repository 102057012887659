import { cyan, gold, green, magenta, orange, purple } from "@ant-design/colors";

export enum CardTypesEnum {
  KNOWLEDGE_CARD = "KNOWLEDGE_CARD",
  QUIZ = "QUIZ",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  SLIDER = "SLIDER",
  QUESTION = "QUESTION",
  PICTURE_QUIZ = "PICTURE_QUIZ",
}

export const CardTypesColorEnum = {
  KNOWLEDGE_CARD: [{ color: cyan[5] }, { background: cyan[0] }],
  QUIZ: [{ color: green[4] }, { background: green[1] }],
  MULTIPLE_CHOICE: [{ color: purple[3] }, { background: purple[0] }],
  SLIDER: [{ color: magenta[4] }, { background: magenta[0] }],
  QUESTION: [{ color: gold[4] }, { background: gold[0] }],
  PICTURE_QUIZ: [{ color: orange[4] }, { background: orange[0] }],
};
