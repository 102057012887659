import React, { PropsWithChildren } from "react";
import {
  BaseLearningPathContent,
  LearningPath,
} from "../api/primio/primioSchemas";
import { useUpdateLearningPath } from "../api/primio/primioComponents";
import { useQueryClient } from "@tanstack/react-query";

interface LearningPathProviderType {
  learningPath: LearningPath;
  addContent: (contentUids: string[]) => Promise<void>;
  removeContent: (contentUid: string) => Promise<void>;
  updateContent: (content: BaseLearningPathContent[]) => Promise<void>;
}

const LearningPathContext = React.createContext({} as LearningPathProviderType);

interface LearningPathProviderProps {
  learningPath: LearningPath;
}

const LearningPathProvider = ({
  children,
  ...props
}: PropsWithChildren<LearningPathProviderProps>) => {
  const { learningPath } = props;
  const queryClient = useQueryClient();
  const { mutateAsync: updateLearningPathAsync } = useUpdateLearningPath({
    onSuccess: () =>
      queryClient.invalidateQueries([
        "learning-paths",
        learningPath.learningPathUid,
      ]),
  });

  const addContent = async (contentUids: string[]) => {
    const previousContent = [...learningPath.content];
    const newContent = contentUids.map<BaseLearningPathContent>(
      (contentUid, index) => ({
        contentUid,
        contentType: "PLAYBOOK",
        sort: previousContent.length + index + 1,
        learningPathUid: learningPath.learningPathUid,
      }),
    );

    const content = [...previousContent, ...newContent];
    content.forEach((content, index) => {
      // @ts-ignore
      delete content.scheduledNotificationUid;
      delete content.data;
      content.sort = index + 1;
    });

    await updateLearningPathAsync({
      pathParams: { learningPathUid: learningPath.learningPathUid },
      body: { content },
    });
  };

  const removeContent = async (contentUid: string) => {
    const index = learningPath.content.findIndex(
      (c) => c.contentUid === contentUid,
    );
    if (index === -1) {
      return;
    }

    const content = [...learningPath.content];
    content.splice(index, 1);

    content.forEach((content, index) => {
      // @ts-ignore
      delete content.scheduledNotificationUid;
      delete content.data;
      content.sort = index + 1;
    });

    await updateLearningPathAsync({
      pathParams: { learningPathUid: learningPath.learningPathUid },
      body: { content },
    });
  };

  const updateContent = async (content: BaseLearningPathContent[]) => {
    content.forEach((content, index) => {
      // @ts-ignore
      delete content.scheduledNotificationUid;
      delete content.data;
      content.sort = index + 1;
    });

    await updateLearningPathAsync({
      pathParams: { learningPathUid: learningPath.learningPathUid },
      body: { content },
    });
  };

  return (
    <LearningPathContext.Provider
      value={{
        learningPath: props.learningPath,
        addContent,
        removeContent,
        updateContent,
      }}
    >
      {children}
    </LearningPathContext.Provider>
  );
};

const useLearningPath = () => React.useContext(LearningPathContext);

export { LearningPathProvider, useLearningPath };
