import React, { useEffect, useState } from "react";
import { useCompletedUserProgress } from "../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { GradientColors_GraphBlue } from "../../styles/colors";
import { Col, Progress, Row, Spin, Typography } from "antd";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import UserModel from "../../../core/models/user/user.model";
import graphStyles from "../graphs/graph-container/graph-container.module.css";
import styles from "./user-detail.module.css";
import SectionTitleComponent from "../section-title/section-title.component";
import StatisticsBlockComponent from "../statistics/statistics-block.component";
import Empty from "../empty/empty";

interface OwnProps {
  user: UserModel;
}

interface ContentCompletedType {
  totalModules: number;
  modulesCompleted: number;
  totalChapters: number;
  chaptersCompleted: number;
  totalCollections: number;
  collectionsCompleted: number;
  totalCards: number;
  cardsCompleted: number;
}

const UserAppCompletedComponent = ({ user }: OwnProps) => {
  const [t] = useTranslation();
  const { data, isFetching } = useCompletedUserProgress(user);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contentCompleted, setContentCompleted] =
    useState<ContentCompletedType>({
      totalModules: 0,
      modulesCompleted: 0,
      totalChapters: 0,
      chaptersCompleted: 0,
      totalCollections: 0,
      collectionsCompleted: 0,
      totalCards: 0,
      cardsCompleted: 0,
    });

  useEffect(() => {
    const contentCount: ContentCompletedType = {
      totalModules: 0,
      modulesCompleted: 0,
      totalChapters: 0,
      chaptersCompleted: 0,
      totalCollections: 0,
      collectionsCompleted: 0,
      totalCards: 0,
      cardsCompleted: 0,
    };

    if (!data || data.userUid !== user.sub) {
      setContentCompleted(contentCount);
      setIsLoading(false);
      return;
    }

    data.playbooks.forEach((playbook) => {
      contentCount.totalModules++;
      if (playbook.cardsCompleted === playbook.totalCards) {
        contentCount.modulesCompleted++;
      }

      // Todo: We need to test if the higher level cardCompleted count is the same cardCompleted count in lowest level
      contentCount.cardsCompleted += playbook.cardsCompleted;
      contentCount.totalCards += playbook.totalCards;

      playbook.chapters.forEach((chapter) => {
        contentCount.totalChapters++;
        if (chapter.cardsCompleted === chapter.totalCards) {
          contentCount.chaptersCompleted++;
        }

        chapter.collections.forEach((collection) => {
          contentCount.totalCollections++;
          if (collection.cardsCompleted === collection.totalCards) {
            contentCount.collectionsCompleted++;
          }
        });
      });
    });

    setContentCompleted(contentCount);
    setIsLoading(false);
  }, [data]);

  return (
    <>
      <SectionTitleComponent title={t("screens.activity.statistics")} />

      <Row className={styles.grid_default} style={{ height: "31.8rem" }}>
        <div className={graphStyles.container} style={{ width: "31rem" }}>
          <Typography.Text className={graphStyles.title}>
            {Capitalize(t("graphs.app-completion"))}
          </Typography.Text>
          <div
            className={graphStyles.container_spinner}
            style={{ alignItems: "center", height: "100%" }}
          >
            {renderAppCompletedProgressBar()}
          </div>
        </div>

        <Col className={styles.grid_default_row}>
          {renderContentCompletedProgressBar(
            ContentTypesEnum.PLAYBOOK,
            contentCompleted.modulesCompleted,
            contentCompleted.totalModules,
          )}
          {renderContentCompletedProgressBar(
            ContentTypesEnum.CHAPTER,
            contentCompleted.chaptersCompleted,
            contentCompleted.totalChapters,
          )}
          {renderContentCompletedProgressBar(
            ContentTypesEnum.COLLECTION,
            contentCompleted.collectionsCompleted,
            contentCompleted.totalCollections,
          )}
          {renderContentCompletedProgressBar(
            ContentTypesEnum.CARD,
            contentCompleted.cardsCompleted,
            contentCompleted.totalCards,
          )}
        </Col>
      </Row>
    </>
  );

  function renderAppCompletedProgressBar() {
    if (isFetching || isLoading) {
      return (
        <div className={graphStyles.container_spinner_item}>
          <Spin />
        </div>
      );
    }

    if (!data || data.playbooks.length === 0) {
      return (
        <div className={graphStyles.container_spinner_item}>
          <Empty image={"404"} message={""} />
        </div>
      );
    }

    return (
      <Progress
        type={"circle"}
        strokeWidth={12}
        width={210}
        percent={Math.round(
          (contentCompleted.cardsCompleted / contentCompleted.totalCards) * 100,
        )}
        format={(percent) => (
          <span style={{ color: "var(--black-color)" }}>{percent}%</span>
        )}
        style={{
          fontSize: "3.6rem",
          fontWeight: "bold",
          width: "21rem",
          height: "21rem",
        }}
        strokeColor={{
          "0%": GradientColors_GraphBlue.from,
          "100%": GradientColors_GraphBlue.to,
        }}
      />
    );
  }

  function renderContentCompletedProgressBar(
    contentType: ContentTypesEnum,
    completed: number,
    total: number,
  ) {
    const progressBar = (
      <Progress
        type={"circle"}
        strokeWidth={16}
        width={40}
        percent={Math.round((completed / total) * 100)}
        showInfo={false}
        style={{
          width: "4rem",
          height: "4.5rem",
        }}
        strokeColor={{
          "0%": GradientColors_GraphBlue.from,
          "100%": GradientColors_GraphBlue.to,
        }}
      />
    );

    return (
      <StatisticsBlockComponent
        icon={progressBar}
        text={`content.${contentType.toLowerCase()}.key_plural`}
        title={isLoading ? "-" : `${completed} ${t("common.of")} ${total}`}
      />
    );
  }
};

export default UserAppCompletedComponent;
