import React, { Key, useState } from "react";
import { Tooltip, Tree, TreeDataNode, Typography } from "antd";
import { CaretDownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectParentPlaybookDetailFromContentTree } from "../../../core/redux/selectors/content/content.selector";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import PlaybookDetailModel from "../../../core/models/content/playbook-detail.model";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import CardTypeIconComponent from "../icons/card-type-icon.component";
import ContentType from "../../../core/types/content.type";
import CardTypes from "../../../core/types/card.types";
import styles from "./tree.module.css";
import CardTitleWarningIcon from "../card/card-title-warning-icon.component";

interface Props {
  content: ContentType;
  checkedKeys: { checked: Key[]; halfChecked: Key[] };
  handleOnCheck: (
    checkedKeys: { checked: Key[]; halfChecked: Key[] } | Key[],
    info: any,
  ) => void;
}

const BulkPublishTree = ({ content, checkedKeys, handleOnCheck }: Props) => {
  const [t] = useTranslation();
  const parentPlaybookDetail: PlaybookDetailModel | undefined = useSelector(
    (state) => selectParentPlaybookDetailFromContentTree(state, content),
  );
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([content.getId]);

  const treeData = (detail: PlaybookDetailModel): TreeDataNode[] => [
    {
      key: `${detail.getId}`,
      title: () => renderContentTitle(detail),
      disableCheckbox: detail.contentState === ContentStatesEnum.PUBLISHED,
      selectable: false,
      children: !detail.children
        ? undefined
        : detail.children.map((chapter) => ({
            key: `${chapter.getId}`,
            disableCheckbox:
              chapter.contentState === ContentStatesEnum.PUBLISHED,
            selectable: false,
            title: () => renderContentTitle(chapter),
            children: !chapter.children
              ? undefined
              : chapter.children.map((collection) => ({
                  key: `${collection.getId}`,
                  disableCheckbox:
                    collection.contentState === ContentStatesEnum.PUBLISHED,
                  selectable: false,
                  title: () => renderContentTitle(collection),
                  children: !collection.children
                    ? undefined
                    : collection.children.map((card) => ({
                        key: `${card.getId}`,
                        title: () => renderCardTitle(card),
                        disableCheckbox:
                          card.contentState === ContentStatesEnum.PUBLISHED,
                        selectable: false,
                      })),
                })),
          })),
    },
  ];

  if (!parentPlaybookDetail) {
    return null;
  }

  return (
    <Tree
      className={"Publish_tree"}
      treeData={treeData(parentPlaybookDetail)}
      switcherIcon={
        <CaretDownOutlined style={{ fontSize: "16px", lineHeight: "36px" }} />
      }
      onExpand={handleOnExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={handleOnCheck}
      checkedKeys={checkedKeys}
      checkable
    />
  );

  function handleOnExpand(expandedKeys: Key[]) {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  }

  function renderContentTitle(content: ContentType) {
    return (
      <div className={styles.title_wrapper_content}>
        <div className={styles.title_wrapper}>
          <Typography.Text>{TextDecodeUtils(content.title)}</Typography.Text>
          {content.contentState === ContentStatesEnum.PUBLISHED &&
            renderTooltip()}
        </div>
      </div>
    );
  }

  function renderCardTitle(card: CardTypes) {
    return (
      <div className={styles.title_wrapper}>
        <div>
          <Typography.Text>{TextDecodeUtils(card.title)}</Typography.Text>
          <div style={{ marginLeft: "-.7rem" }}>
            <CardTypeIconComponent
              cardType={card.cardType}
              iconcolor={"black"}
              style={{ backgroundColor: "transparent" }}
            />
            <Typography.Text>
              {TextDecodeUtils(t(`content.card-types.${card.cardType}`))}
            </Typography.Text>
          </div>
        </div>
        {card.contentState === ContentStatesEnum.PUBLISHED && renderTooltip()}
        {card.contentState === ContentStatesEnum.DRAFT && (
          <span style={{ padding: "0px 2rem" }}>
            <CardTitleWarningIcon card={card} />{" "}
          </span>
        )}
      </div>
    );
  }

  function renderTooltip() {
    return (
      <Tooltip
        title={Capitalize(t(`content-state.${ContentStatesEnum.PUBLISHED}`))}
        overlayInnerStyle={{ width: "11rem" }}
      >
        <span style={{ padding: "0 2rem" }}>
          <InfoCircleOutlined />
        </span>
      </Tooltip>
    );
  }
};

export default BulkPublishTree;
