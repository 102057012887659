import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import ContentTypesEnum from "../../enums/content-types.enum";
import BaseMediaContentModel, {
  BaseMediaContentResponse,
} from "../media/base-media-content.model";
import SourceModel, { SourceResponse } from "../source/source.model";

export interface BaseCardResponse extends BaseMediaContentResponse {
  cardUid: string;
  version: number;
  collectionUid: string;
  mediaUid?: string;
  sources: SourceResponse[];
}

export default class BaseCardModel extends BaseMediaContentModel {
  cardUid: string;
  version: number;
  collectionUid: string;
  contentType: ContentTypesEnum.CARD;
  sources?: SourceModel[];
  shouldEdit: boolean;
  getId: string;

  constructor(card: BaseCardResponse) {
    super(card);
    this.cardUid = card.cardUid;
    this.version = card.version;
    this.collectionUid = card.collectionUid;
    this.contentType = ContentTypesEnum.CARD;
    this.shouldEdit = new Date(card.createdAt).getTime() + 5000 > Date.now();

    if (card.sources && card.sources.length > 0) {
      this.sources = MapResponseToModel(card.sources, SourceModel);
    }

    this.getId = `card_${this.cardUid}_${this.version}`;
  }
}
