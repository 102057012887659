import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { isArrayOf } from "../../../core/utils/is-array-of.utils";
import { Button, Form, message, Typography, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import CreateImportInvitationInterface, {
  CreateInvitationInterface,
} from "../../../core/interfaces/create-invitation.interface";
import csvFileToObject from "../../../core/utils/csv-file-to-object.utils";

export interface CreateImportInvitationFormFields {
  csvFileUpload: CreateInvitationInterface[];
}

export interface CreateImportInvitationFormRef {
  form: FormInstance<CreateImportInvitationFormFields>;
}

interface OwnProps {
  setCsvData: (data: CreateInvitationInterface[]) => void;
}

type Props = OwnProps;

const CreateImportInvitationForm = forwardRef(
  (props: Props, ref: Ref<CreateImportInvitationFormRef>) => {
    const [t] = useTranslation();
    const { setCsvData } = props;
    const [form] = useForm<CreateImportInvitationFormFields>();
    const [fileList, setFileList] = useState<UploadFile[]>();
    const [isAllowedFileType, setIsAllowedFileType] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({ form }));

    return (
      <>
        <Typography.Paragraph>
          <Typography.Text>
            {Capitalize(t("common.download-csv-template")) + " "}
          </Typography.Text>
          <Link href={"/assets/csv/bulk-users-invitation-template.csv"}>
            {Capitalize(t("common.download"))} {t("common.here")}
          </Link>
          .
        </Typography.Paragraph>

        <Form form={form}>
          <Form.Item name={"csvFileUpload"} rules={getRules()}>
            <Upload
              fileList={fileList}
              accept={".csv"}
              multiple={false}
              maxCount={1}
              beforeUpload={handleBeforeUpload}
              onRemove={handleOnRemove}
              onChange={handleOnChange}
            >
              <Button icon={<UploadOutlined />}>
                {Capitalize(
                  t("common.click-to_x", { verb: t("common.import") }),
                )}
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </>
    );

    function getRules() {
      return [
        {
          required: true,
          message: Capitalize(t("errors.require-file")),
        },
      ];
    }

    function handleOnRemove() {
      form.setFields([{ name: "csvFileUpload", value: [] }]);
      setCsvData([]);
    }

    function handleBeforeUpload(file: RcFile) {
      const isAllowedFileTypeExt =
        file.type === "text/csv" || file.type === "application/vnd.ms-excel";

      if (!isAllowedFileTypeExt) {
        message.error(Capitalize(t("errors.upload.format", { field: "CSV" })));
      }

      setIsAllowedFileType(isAllowedFileTypeExt);

      // If we return true here, the reader.readAsText in handleOnChange is getting blob error
      // to solve it, I return static false and created setIsAllowedFileType and use it in handleOnChange function
      return false;
    }

    function handleOnChange({ file, fileList }) {
      if (!isAllowedFileType || file.status === "removed") {
        return handleOnRemove();
      }

      setFileList(fileList);

      const input = file;
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target?.result;

        const data: CreateImportInvitationInterface[] = csvFileToObject(text);

        if (
          !isArrayOf<CreateImportInvitationInterface>(data, [
            "name",
            "email",
            "userGroups",
          ])
        ) {
          form.setFields([
            {
              name: "csvFileUpload",
              errors: [Capitalize(t("errors.upload.invalid-data"))],
            },
          ]);

          return handleOnRemove();
        }

        const parsedCsvData: CreateInvitationInterface[] = data.map((i) => ({
          email: i.email.trim().toLowerCase(),
          name: i.name,
          userGroups:
            i.userGroups.length > 0
              ? i.userGroups.includes("|")
                ? i.userGroups.split("|")
                : [i.userGroups]
              : [],
        }));

        const { duplicatedEmails, isValid } = validateCSVData(parsedCsvData);
        if (!isValid) {
          form.setFields([
            {
              name: "csvFileUpload",
              errors: [
                `${t("errors.upload.duplicate-emails")}:`,
                ...duplicatedEmails,
              ],
            },
          ]);

          return;
        }

        form.setFields([{ name: "csvFileUpload", value: parsedCsvData }]);
        setCsvData(parsedCsvData);
      };

      reader.readAsText(input);
    }
  },
);

function validateCSVData(data: CreateInvitationInterface[]) {
  const emails: string[] = [];
  const duplicatedEmails: string[] = [];

  data.forEach(({ email }) => {
    if (emails.includes(email)) {
      duplicatedEmails.push(email);
    } else {
      emails.push(email);
    }
  });

  return {
    isValid: duplicatedEmails.length === 0,
    duplicatedEmails,
  };
}

CreateImportInvitationForm.displayName = "CreateImportInvitationForm";

export default CreateImportInvitationForm;
