// Check if obj has all keys props[]
const hasAllProperties = <T>(ele: any, props: (keyof T)[]): ele is T => {
  /* eslint-disable */
  return props.every((prop) => {
    return Object.prototype.hasOwnProperty.call(ele, prop);
  });
};

// Check that arr is an array of newType , ie arr: newType[]
export const isArrayOf = <T>(obj: any[], props: (keyof T)[]): obj is T[] => {
  // Check if every elements have all keys in props
  return obj.every((ele: any) => {
    return hasAllProperties<T>(ele, props);
  });
};
