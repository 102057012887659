import rootStore from "../../redux/stores/root.store";
import { selectPlaybookViaUid } from "../../redux/selectors/content/content.selector";
import UserModel from "../../models/user/user.model";
import { selectUsers } from "../../redux/selectors/user/user.selector";
import UserGroupEffect from "../../redux/stores/user-group/user-group.effect";
import HttpErrorResponseModel from "../../models/http-error-response.model";

export async function getUsersForPlaybook(playbookUid: string) {
  const state = rootStore.store.getState();
  const playbook = selectPlaybookViaUid(state, playbookUid);

  if (!playbook) {
    return;
  }

  const users: UserModel[] = [];

  if (playbook.userGroupAcl.length === 0) {
    users.push(...selectUsers(state));
  } else {
    const calls = playbook.userGroupAcl.map((userGroup) =>
      UserGroupEffect.getUsersInUserGroup(userGroup),
    );
    const res: (HttpErrorResponseModel | UserModel[])[] =
      await Promise.all(calls);

    const filteredResponse =
      res.length > 1
        ? res.filter((item) => !(item instanceof HttpErrorResponseModel))
        : res;

    filteredResponse.forEach((response) => {
      if (response instanceof HttpErrorResponseModel) {
        throw response;
      }

      const uniqueUsers = response.filter(
        (user) => !users.some((u) => user.username === u.username),
      );
      users.push(...uniqueUsers);
    });
  }

  return users;
}
