import { CardTypesEnum } from "../../../enums/card-types.enum";
import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import QuestionCardDataModel, {
  QuestionCardDataResponse,
} from "../../content/card-types/question/question-card-data.model";

export interface CreateQuestionCardResponse extends CreateCardInterface {
  data: QuestionCardDataResponse;
}

export default class CreateQuestionCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.QUESTION;
  data: QuestionCardDataModel;

  constructor(createQuestionCard: CreateQuestionCardResponse) {
    super(createQuestionCard);
    this.cardType = CardTypesEnum.QUESTION;
    this.data = new QuestionCardDataModel(createQuestionCard.data);
  }
}
