import { useEffect, useState } from "react";
import { API_URL } from "../../constants/config/env.config";

const appSchemeLookUp = {
  studiebreek: "studiebreek",
  "dio-sandbox": "primio-sandbox",
  kennisbankvrbzo: "veiligheidsregio-kennis-app",
};

const useEnvironment = () => {
  const [environment] = useState(API_URL.split(".")[1]);
  const [isWhiteLabelEnvironment, setWhiteLabelEnvironment] = useState(false);
  const [appScheme, setAppScheme] = useState("primio-v2");

  useEffect(() => {
    switch (environment) {
      case "studiebreek":
      case "dio-sandbox":
      case "kennisbankvrbzo":
        setWhiteLabelEnvironment(true);
        setAppScheme(appSchemeLookUp[environment]);
        break;
      default:
        setWhiteLabelEnvironment(false);
        setAppScheme("primio-v2");
        break;
    }
  }, [environment]);

  return {
    environment,
    isWhiteLabelEnvironment,
    appScheme,
  };
};

export default useEnvironment;
