import React, { useEffect, useState } from "react";
import { useGetUsersStartedLearningPaths } from "../../../core/api/primio/primioComponents";
import { useSelector } from "react-redux";
import { selectLearningPaths } from "../../../core/redux/selectors/learning-path/learning-path.selector";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import {
  selectCurrentUserRole,
  selectUsersInUserGroup,
} from "../../../core/redux/selectors/user/user.selector";
import MediaModel from "../../../core/models/media/media.model";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { Capitalize } from "../../../core/utils/helper.utils";
import { UserProgressProgressBar } from "../content-user-progress/content-user-progress.bar";
import useLearningPathDetailReport from "../../../core/hooks/useLearningPathDetailReport";
import DownloadCertificateButton from "../download-certificate-button/download-certificate-button";
import { useTranslation } from "react-i18next";
import DisableableLink from "../utils/disableable-link";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { RoutesEnum } from "../../../constants/routes/app.route";

interface LearningPathReportResponse {
  [learningPathUid: string]: {
    state: "REVOKED" | "STARTED" | "COMPLETED";
    learningPathType: string;
    users?: {
      [userId: string]: {
        username: string;
        startedAt?: string;
        completed?: {
          completedAt: string;
        };
      };
    };
  };
}

interface LearningPathProgress {
  learningPathUid: string;
  state: "REVOKED" | "STARTED" | "COMPLETED";
  learningPathType: string;
  users?: {
    userId: string;
    username: string;
    startedAt?: string;
    completed?: {
      completedAt: string;
    };
  }[];
}

interface UserLearningPathReport {
  username: string;
  userUid: string;
  name: string;
  learningPaths: {
    learningPathUid: string;
    learningPathTitle: string;
    media: MediaModel[];
    userGroups?: string[];
    color: string;
    startedAt?: string;
    completedAt?: string;
  }[];
}

function useUserGroupLearningPathReport(userGroup: string) {
  const allLearningPaths: LearningPathModel[] =
    useSelector(selectLearningPaths);
  const users = useSelector((state) =>
    selectUsersInUserGroup(state, userGroup),
  );
  const { data } = useGetUsersStartedLearningPaths<LearningPathReportResponse>(
    {},
  );
  const [userGroupLearningPaths, setUserGroupLearningPaths] = useState<
    LearningPathModel[]
  >([]);

  const [userLearningPathReport, setUserLearningPathReport] = useState<
    UserLearningPathReport[]
  >([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const userGroupLearningPaths = allLearningPaths.filter((learningPath) => {
      if (learningPath.userGroups?.length === 0) {
        return true;
      }
      return learningPath.userGroups?.includes(userGroup);
    });
    setUserGroupLearningPaths(userGroupLearningPaths);

    const learningPathProgress: LearningPathProgress[] = [];
    Object.entries(data).forEach(([learningPathUid, value]) => {
      if (value.state === "REVOKED") {
        return;
      }

      const canAccessLearningPath = !!userGroupLearningPaths.find(
        (path) => path.learningPathUid === learningPathUid,
      );
      if (!canAccessLearningPath) {
        return;
      }

      const users: LearningPathProgress["users"] = [];
      if (value.users) {
        Object.entries(value.users).forEach(([userId, user]) => {
          users.push({
            userId,
            ...user,
          });
        });
      }

      learningPathProgress.push({
        learningPathUid,
        users,
        state: value.state,
        learningPathType: value.learningPathType,
      });
    });

    const report: UserLearningPathReport[] = users.map((user) => {
      return {
        username: user.username,
        userUid: user.sub,
        name: user.name,
        learningPaths: userGroupLearningPaths.map((path) => {
          const progress = learningPathProgress.find((p) => {
            return p.learningPathUid === path.learningPathUid;
          });

          const userProgress = progress?.users?.find(
            (u) => u.userId === user.sub,
          );

          return {
            learningPathUid: path.learningPathUid,
            learningPathTitle: path.title,
            media: path.media,
            userGroups: path.userGroups,
            color: path.color,
            startedAt: userProgress?.startedAt,
            completedAt: userProgress?.completed?.completedAt,
          };
        }),
      };
    });

    setUserLearningPathReport(report);
  }, [data]);

  return { userLearningPathReport, userGroupLearningPaths };
}

const LearningPathHeader = ({
  learningPath,
  userGroup,
}: {
  learningPath: LearningPathModel;
  userGroup: string;
}) => {
  const { learningPathReportSummary } = useLearningPathDetailReport(
    learningPath.learningPathUid,
    [userGroup],
  );
  const [disableLink, setDisableLink] = useState(false);

  const currentUserRole = useSelector(selectCurrentUserRole);

  useEffect(() => {
    if (currentUserRole && currentUserRole === UserRoleEnum.GROUP_ADMIN) {
      setDisableLink(true);
    } else {
      setDisableLink(false);
    }
  }, [currentUserRole]);

  return (
    <DisableableLink
      disabled={disableLink}
      to={{
        pathname: RoutesEnum.LEARNING_PATH_DETAIL_REPORT.replace(
          ":uid",
          learningPath.learningPathUid,
        ),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          width: 200,
          pointerEvents: "none",
        }}
      >
        <img
          src={
            learningPath.media[0]
              ? learningPath.media[0].uri
              : `${process.env.PUBLIC_URL}/assets/images/default_training_image.png`
          }
          alt={learningPath.title}
          style={{
            width: 38,
            height: 38,
            background: learningPath.color,
            marginRight: 8,
            borderRadius: 4,
          }}
        />
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              margin: 0,
              marginBottom: ".3rem",
            }}
            title={learningPath.title}
          >
            {learningPath.title}
          </p>
          <UserProgressProgressBar
            {...learningPathReportSummary}
            showValue={false}
            size={6}
          />
        </div>
      </div>
    </DisableableLink>
  );
};

interface Props {
  userGroup: string;
}

const UserGroupLearningPathReportTable = ({ userGroup }: Props) => {
  const { userLearningPathReport, userGroupLearningPaths } =
    useUserGroupLearningPathReport(userGroup);
  const { t } = useTranslation();

  return (
    <div>
      <Table
        dataSource={userLearningPathReport}
        tableLayout={"fixed"}
        size={"small"}
        pagination={{
          showSizeChanger: true,
        }}
        scroll={{ x: true }}
        className="user-group-learning-path-report-table"
        columns={[
          {
            dataIndex: "name",
            fixed: "left" as const,
            render: (name, record) => (
              <div style={{ width: 150 }}>
                <Link to={{ pathname: `/users/${record.username}` }}>
                  {name}
                </Link>
              </div>
            ),
          },
          ...userGroupLearningPaths.map((path) => ({
            title: (
              <LearningPathHeader learningPath={path} userGroup={userGroup} />
            ),
            width: 400,
            render: (_value, record: UserLearningPathReport) => {
              const learningPath = record.learningPaths.find(
                (p) => p.learningPathUid === path.learningPathUid,
              );

              if (!learningPath) {
                return <div>-</div>;
              }

              const label = learningPath.startedAt
                ? t("translations:common.started")
                : t("translations:common.not-started");
              if (learningPath.completedAt) {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignContent: "center",
                      color: "var(--success-color)",
                    }}
                  >
                    <span>
                      {Capitalize(t("translations:common.completed"))}
                    </span>
                    <DownloadCertificateButton
                      userName={record.username}
                      userUid={record.userUid}
                      learningPathTitle={path.title}
                      learningPathUid={path.learningPathUid}
                      showLabel={false}
                    />
                  </div>
                );
              }

              return (
                <div
                  style={{
                    color: learningPath.completedAt
                      ? "var(--success-color)"
                      : !learningPath.startedAt
                        ? "var(--grey350-color)"
                        : undefined,
                  }}
                >
                  {Capitalize(label)}
                </div>
              );
            },
          })),
        ]}
      />
    </div>
  );
};

export default UserGroupLearningPathReportTable;
