import React, { useEffect, useState } from "react";
import BaseContentModel from "../../../core/models/content/base-content.response";
import { useSelector } from "react-redux";
import getObjectWithHighestVersion from "../../../core/utils/get-object-with-highest-version";
import BaseCardModel from "../../../core/models/content/base-card.model";
import { Typography } from "antd";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";

const ContentTitle = ({
  selectorFn,
  uid,
}: {
  selectorFn: (state: any, uid?: string) => void;
  uid?: string;
}) => {
  const content: BaseContentModel | BaseContentModel[] | undefined =
    useSelector((state) => selectorFn(state, uid));
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    if (!content) {
      setTitle(undefined);
      return;
    }

    if (Array.isArray(content)) {
      const item = getObjectWithHighestVersion(content as BaseCardModel[]);
      if (item) {
        setTitle(item.title);
      }
    } else {
      setTitle(content.title);
    }
  }, [content]);

  return (
    <Typography.Text ellipsis style={{ width: 120 }}>
      {title ? TranslateDefaults(title) : "-"}
    </Typography.Text>
  );
};

export default ContentTitle;
