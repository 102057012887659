export default function CleanHTML(input: string) {
  // Create a DOM parser to parse the input HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, "text/html");

  // Remove all span tags, comments, and unwanted attributes
  function cleanNode(node) {
    // If it's a comment node, remove it
    if (node.nodeType === Node.COMMENT_NODE) {
      node.remove();
      return;
    }

    // Replace h1-h6 tags with p tags
    if (node.nodeType === Node.ELEMENT_NODE && /^H[1-6]$/.test(node.tagName)) {
      const pTag = doc.createElement("p");
      // Copy content from the original header to the new p tag
      while (node.firstChild) {
        pTag.appendChild(node.firstChild);
      }
      // Replace the original header tag with the new p tag
      node.parentNode.replaceChild(pTag, node);
      node = pTag; // Update the node reference to the new p tag
    }

    // Remove unwanted attributes
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Remove inline styles, id's, and classes
      node.removeAttribute("style");
      node.removeAttribute("id");
      node.removeAttribute("class");

      // Remove all other attributes except "href" and "src" (for links and images)
      for (let i = node.attributes.length - 1; i >= 0; i--) {
        const attrName = node.attributes[i].name;
        if (attrName !== "href" && attrName !== "src") {
          node.removeAttribute(attrName);
        }
      }

      // Recursively clean child nodes
      node.childNodes.forEach(cleanNode);
    }

    // If the node is a span, replace it with its children
    if (node.tagName === "SPAN") {
      while (node.firstChild) {
        node.parentNode.insertBefore(node.firstChild, node);
      }
      node.remove();
    }
  }

  if (!doc.body) return "";

  // Clean the document recursively
  cleanNode(doc.body);

  return doc?.body?.innerHTML ?? "";
}
