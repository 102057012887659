import React from "react";
import { useTranslation } from "react-i18next";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import CardDetailBoilerplate from "./card-detail.boilerplate";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import KnowledgeCardPreviewComponent from "../card-types/knowledge-card/knowledge-card-preview.component";
import OptionCardPreviewComponent from "../card-types/option-card/option-card-preview.component";
import QuestionCardPreviewComponent from "../card-types/question-card/question-card-preview.component";
import CardTypes from "../../../core/types/card.types";
import PictureQuizCardPreviewComponent from "../card-types/picture-quiz/picture-quiz-card-preview.component";

interface Props {
  card: CardTypes;
}

const CardDetailComponent = ({ card }: Props) => {
  const [t] = useTranslation();

  const properties: any = {
    [t("form.card.title.label")]: card.title
      ? TranslateDefaults(card.title)
      : "",
    [t("form.card.description.label")]: card.description
      ? TranslateDefaults(card.description)
      : "",
  };

  switch (card.cardType) {
    case CardTypesEnum.KNOWLEDGE_CARD:
      return <KnowledgeCardPreviewComponent cardContent={card} />;
    case CardTypesEnum.QUIZ:
      return <OptionCardPreviewComponent cardContent={card} />;
    case CardTypesEnum.MULTIPLE_CHOICE:
      return (
        <OptionCardPreviewComponent cardContent={card} showBaseFields={false} />
      );
    case CardTypesEnum.SLIDER:
      return (
        <OptionCardPreviewComponent cardContent={card} showBaseFields={false} />
      );
    case CardTypesEnum.QUESTION:
      return <QuestionCardPreviewComponent cardContent={card} />;
    case CardTypesEnum.PICTURE_QUIZ:
      return <PictureQuizCardPreviewComponent cardContent={card} />;
  }

  return <CardDetailBoilerplate properties={properties} />;
};

export default CardDetailComponent;
