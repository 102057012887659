import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { useTranslation } from "react-i18next";
import { selectUsers } from "../../../core/redux/selectors/user/user.selector";
import { RoutesUsersEnum } from "../../../constants/routes/users.route";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { UserStatusEnum } from "../../../core/enums/user-status.enum";
import { Capitalize } from "../../../core/utils/helper.utils";
import { getAllUserGroupsPerUser } from "../../../core/redux/selectors/user-group/user-group.selector";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import useQuery from "../../../core/hooks/useQuery";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import UserAction from "../../../core/redux/stores/user/user.action";
import UserModel from "../../../core/models/user/user.model";
import EmptyStateScreen from "../../screens/empty-state.screen";
import UsersOverviewScreen from "../../screens/users/users-overview.screen";
import CreateUserGroupModal from "../../components/modals/create-user-group.modal";
import CreateNotificationModal from "../../components/modals/create-notification.modal";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";

interface StateProps {
  users: UserModel[];
  requesting: boolean;
  error: HttpErrorResponseModel;
}

type Props = StateProps;

const UsersContainer = ({ users }: Props) => {
  const { t } = useTranslation();
  const [isModalUserGroupVisible, setIsModalUserGroupVisible] =
    useState<boolean>(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>();
  const [userStatusFilter, setUserStatusFilter] = useState<
    UserStatusEnum | undefined
  >(UserStatusEnum.CONFIRMED);
  const dispatch = useDispatch();
  const usersWithUserGroups: UserModel[] = useSelector(getAllUserGroupsPerUser);
  const query = useQuery();

  useEffect(() => {
    dispatch(UserAction.getAllUsers());
  }, []);

  const state: UserStatusEnum | null = query.get(
    "state",
  ) as UserStatusEnum | null;
  if (state) {
    if (state !== userStatusFilter) {
      setUserStatusFilter(state);
    }
  } else {
    if (userStatusFilter !== undefined) {
      setUserStatusFilter(undefined);
    }
  }

  const menuItems = () => {
    const usersCount = users.length > 0 ? users.length : 0;
    const menuItems: MenuItemInterface[] = [
      {
        title: `${t("containers.users.sidebar")} (${usersCount})`,
        route: RoutesEnum.USERS,
      },
    ];

    for (const key in UserStatusEnum) {
      let count;
      if (key === UserStatusEnum.CONFIRMED) {
        count =
          users.length > 0
            ? users.filter((u) => u.user_status === UserStatusEnum.CONFIRMED)
                .length
            : 0;
      } else {
        count =
          users.length > 0
            ? users.filter((u) => u.user_status !== UserStatusEnum.CONFIRMED)
                .length
            : 0;
      }

      menuItems.push({
        title: `${Capitalize(t(`screens.user-state.${key}`))} (${count})`,
        route: RoutesUsersEnum[key],
      });
    }

    return menuItems;
  };

  const filteredUsers = (): UserModel[] => {
    if (usersWithUserGroups.length === 0) return [];
    if (!userStatusFilter) return usersWithUserGroups;
    switch (userStatusFilter) {
      case UserStatusEnum.CONFIRMED:
        return usersWithUserGroups.filter(
          (u) => u.user_status === UserStatusEnum.CONFIRMED,
        );
      case UserStatusEnum.NOT_CONFIRMED:
        return usersWithUserGroups.filter(
          (u) => u.user_status !== UserStatusEnum.CONFIRMED,
        );
    }
  };

  return (
    <PageLayoutComponent menuItems={menuItems()} title={"users"}>
      {!usersWithUserGroups ||
      usersWithUserGroups.length === 0 ||
      filteredUsers().length === 0 ? (
        <EmptyStateScreen route={"users"} />
      ) : (
        <UsersOverviewScreen
          users={filteredUsers()}
          onSelectGroup={onShowUserGroupModal}
          onSelectNotification={onShowNotificationModal}
        />
      )}

      <CreateUserGroupModal
        group={undefined}
        selectedUsers={selectedUsers}
        visible={isModalUserGroupVisible}
        onCancel={onHideUserGroupModal}
      />

      <CreateNotificationModal
        notification={undefined}
        notifiable={selectedUsers}
        visible={isModalNotificationVisible}
        onCancel={onHideNotificationModal}
      />
    </PageLayoutComponent>
  );

  function onShowUserGroupModal(data: UserModel[]) {
    setSelectedUsers(data);
    setIsModalUserGroupVisible(true);
  }

  function onHideUserGroupModal() {
    setIsModalUserGroupVisible(false);
  }

  function onShowNotificationModal(data: UserModel[]) {
    setSelectedUsers(data);
    setIsModalNotificationVisible(true);
  }

  function onHideNotificationModal() {
    setIsModalNotificationVisible(false);
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
  requesting: selectRequesting(state, [UserAction.REQUEST_USERS]),
  error: selectRawErrors(state, [UserAction.REQUEST_USERS_FINISHED]),
});

export default connect(mapStateToProps)(UsersContainer);
