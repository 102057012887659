import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CreateContentButtonRef } from "../content-create-button/create-content-button.component";
import {
  CaretLeftOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { selectContentViaContentId } from "../../../core/redux/selectors/content/content.selector";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import { Trim } from "../../../core/utils/helper.utils";
import ContentContextMenu from "../content-context-actions/content-context-menu.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import CreateContentButtonConnected from "../content-create-button/create-content-button.connected";
import colors from "../../styles/colors";
import ContentType from "../../../core/types/content.type";
import styles from "./content-sidebar.module.css";
import CardTitleIconComponent from "../card/card-title-icon.component";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";

interface Props {
  contentId: string;
  userGroupAcl?: JSX.Element;
  icon?: JSX.Element;
}

const ContentSidebarItemButtonComponent = (props: Props) => {
  const { onClick, selectedContentId, getSelectedContentParentTree } =
    useContentSidebar();
  const { icon, userGroupAcl, contentId } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editContentType, setEditContentType] = useState<ContentType>();
  const content: ContentType | undefined = useSelector((state) =>
    selectContentViaContentId(state, props.contentId),
  );
  const createContentButtonRef = useRef<CreateContentButtonRef>(null);

  const isSelected = () => {
    if (!selectedContentId) {
      return false;
    }

    if (selectedContentId === contentId) {
      return true;
    }

    if (contentId && contentId.startsWith("collection")) {
      if (
        selectedContentId.startsWith("start-summary") ||
        selectedContentId.startsWith("end-summary")
      ) {
        const id = selectedContentId.split("_")[1];
        return contentId.includes(id);
      }
    }

    const contentTree = getSelectedContentParentTree();

    if (!contentTree) {
      return false;
    }

    let isSelected = false;

    contentTree.forEach((parentContent) => {
      if (parentContent.getId === contentId) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  useEffect(() => {
    if (editContentType && createContentButtonRef.current) {
      createContentButtonRef.current.renameContent(editContentType);
    } else {
      setEditMode(false);
      setEditContentType(undefined);
    }
  }, [editContentType]);

  return (
    <>
      <div
        className={styles.button_sidebar}
        style={{ color: getLabelColor(), backgroundColor: getLabelBgColor() }}
        onClick={() => onClick(contentId)}
        title={getLabel()}
      >
        <div className={styles.button_sidebar_wrapper}>
          <div style={{ flex: 1, display: "flex", flexFlow: "row nowrap" }}>
            {userGroupAcl && <span>{userGroupAcl}</span>}
            {icon && <span>{icon}</span>}

            <Typography.Text
              ellipsis
              style={{
                color: getLabelColor(),
                maxWidth: icon ? "75%" : "",
                paddingRight: ".3rem",
              }}
            >
              {Trim(getLabel())}
            </Typography.Text>
          </div>

          {getIcon()}

          <ContentIdWrapper contentId={contentId}>
            {(content) => (
              <ContentContextMenu
                content={content}
                onRename={(contentType: ContentType) => {
                  setEditMode(true);
                  setEditContentType(contentType);
                }}
              />
            )}
          </ContentIdWrapper>
        </div>
        {renderButtonArrow()}
      </div>

      {editMode && editContentType && (
        <div style={{ position: "relative" }}>
          <div className={styles.button_sidebar_create_button}>
            <CreateContentButtonConnected
              ref={createContentButtonRef}
              onCancel={() => {
                setEditMode(false);
                setEditContentType(undefined);
              }}
              contentType={editContentType.contentType}
              parentContent={undefined}
            />
          </div>
        </div>
      )}
    </>
  );

  function getIcon() {
    if (content?.contentType === ContentTypesEnum.CARD) {
      return <CardTitleIconComponent card={content} />;
    } else {
      if (content?.contentState === ContentStatesEnum.PUBLISHED) {
        return <CheckCircleFilled style={{ color: getLabelColor() }} />;
      } else {
        return <CheckCircleOutlined />;
      }
    }
  }

  function renderButtonArrow() {
    if (
      !isSelected() ||
      contentId.startsWith("playbook") ||
      contentId.startsWith("collection")
    ) {
      return;
    }
    return (
      <CaretLeftOutlined className={styles.button_sidebar_button_triangle} />
    );
  }

  function getLabel(): string {
    let label = "";

    if (!content) {
      return label;
    }

    if (content.contentType === ContentTypesEnum.COLLECTION) {
      if (contentId.startsWith("start-summary") && content.startSummary) {
        label = content.startSummary.title;
      } else if (contentId.startsWith("end-summary") && content.endSummary) {
        label = content.endSummary.title;
      } else if (contentId.startsWith("collection")) {
        label = content.title;
      }
    } else {
      label = content.title;
    }

    return TextDecodeUtils(label);
  }

  function getLabelColor(): string {
    if (
      (contentId && contentId.startsWith("playbook")) ||
      (contentId && contentId.startsWith("collection"))
    ) {
      return "var(--white-color)";
    }
    return isSelected() ? colors.primary : "var(--white-color)";
  }

  function getLabelBgColor(): string {
    if (contentId && contentId.startsWith("playbook")) {
      return isSelected() ? colors.primary : "var(--black-color)";
    } else if (contentId && contentId.startsWith("collection")) {
      return isSelected() ? colors.primary : colors.grey10;
    }
    return "";
  }
};

const ContentIdWrapper = ({
  contentId,
  children,
}: {
  contentId: string;
  children: (content: ContentType) => ReactNode;
}) => {
  const content: ContentType | undefined = useSelector(
    (state: StoreInterface) => selectContentViaContentId(state, contentId),
  );

  if (!content) {
    return null;
  }

  return <>{children(content)}</>;
};

export default ContentSidebarItemButtonComponent;
