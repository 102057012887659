import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../../enums/learning-path-state.enum";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import MediaModel from "../media/media.model";
import TimeBasedContentModel, {
  TimeBasedContentResponse,
} from "./time-based-content.model";
import ProgressBasedContentModel from "./progress-based-content.model";
import { LearningPathContentResponse } from "./learning-path-content.model";
import LearningPathService from "../../services/learning-path.service";

export interface LearningPathResponse {
  learningPathUid: string;
  learningPathType: LearningPathTypeEnum;
  state: LearningPathStateEnum;
  title: string;
  subtitle?: string;
  color?: string;
  description?: string;
  content: TimeBasedContentResponse[] | LearningPathContentResponse[];
  createdAt: string;
  sort?: number;
  media: MediaModel[];
  mediaUids: string[];
  userGroups?: string[];
  scheduledFor?: string;
}

export default class LearningPathModel {
  learningPathUid: string;
  learningPathType: LearningPathTypeEnum;
  state: LearningPathStateEnum;
  title: string;
  description?: string;
  subtitle?: string;
  color?: string;
  content: TimeBasedContentModel[] | ProgressBasedContentModel[];
  createdAt: Date;
  sort?: number;
  media: MediaModel[];
  mediaUids: string[];
  userGroups?: string[];
  scheduledFor?: Date;

  constructor(response: LearningPathResponse) {
    this.learningPathUid = response.learningPathUid;
    this.learningPathType = response.learningPathType;
    this.state = response.state;
    this.title = response.title;
    this.description = response.description;
    this.subtitle = response.subtitle;
    this.color = response.color;

    if (response.learningPathType === LearningPathTypeEnum.PROGRESS_BASED) {
      this.content = MapResponseToModel(
        response.content as LearningPathContentResponse[],
        ProgressBasedContentModel,
      );
    } else if (this.learningPathType) {
      const relativeContent =
        LearningPathService.convertContentFromAbsoluteToRelative(
          response.content as TimeBasedContentResponse[],
        );

      this.content = MapResponseToModel(relativeContent, TimeBasedContentModel);
    } else {
      this.content = [];
    }

    this.createdAt = new Date(response.createdAt);
    this.sort = response.sort;
    this.media = response.media;
    this.mediaUids = response.mediaUids;
    this.userGroups = response.userGroups;

    if (response.scheduledFor) {
      this.scheduledFor = new Date(response.scheduledFor);
    }
  }
}
