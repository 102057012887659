import React from "react";
import { Button, PageHeader, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import PageHeaderSearchComponent from "./page-header-search.component";
import ContainerIconComponent from "../icons/container-icon.component";

interface OwnProps {
  title?: string;
  onPress?: () => void;
  secondaryButton?: JSX.Element;
  onSearch?: (searchTerm: string) => void;
  breadCrumbs?: JSX.Element;
  selectEnabled?: JSX.Element;
  customTitle?: string;
  singular?: boolean;
}

type Props = OwnProps;

const PageHeaderComponent = (props: Props) => {
  const { t } = useTranslation();
  const {
    title,
    onPress,
    secondaryButton,
    onSearch,
    breadCrumbs,
    selectEnabled,
    customTitle,
    singular,
  } = props;

  return (
    <PageHeader
      title={showTitle()}
      subTitle={onSearch && <PageHeaderSearchComponent onSearch={onSearch} />}
      breadcrumb={breadCrumbs}
      extra={showButton()}
    />
  );

  function showButton() {
    return (
      <>
        {secondaryButton && secondaryButton}
        {title && onPress && (
          <Button
            type={"primary"}
            onClick={onPress}
            icon={<ContainerIconComponent screen={title} />}
          >
            {singular
              ? Capitalize(t("common.new"))
              : Capitalize(t("common.new-plural"))}{" "}
            {Capitalize(t(`containers.${title}.key`))}
          </Button>
        )}
      </>
    );
  }

  function showTitle() {
    if (customTitle && !selectEnabled) {
      return (
        <Typography.Title level={2}>{Capitalize(customTitle)}</Typography.Title>
      );
    }

    if (customTitle && selectEnabled) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography.Title level={2}>
            {Capitalize(customTitle)}
          </Typography.Title>
          <div
            style={{
              marginBottom: "0.5em",
              marginLeft: "2em",
              fontWeight: 400,
            }}
          >
            {selectEnabled && selectEnabled}
          </div>
        </div>
      );
    }

    if (title && !selectEnabled) {
      return (
        <Typography.Title level={2}>
          {Capitalize(t(`containers.${title}.title`))}
        </Typography.Title>
      );
    }

    if (title && selectEnabled) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography.Title level={2}>
            {Capitalize(t(`containers.${title}.title`))}
          </Typography.Title>
          <div
            style={{
              marginBottom: "0.5em",
              marginLeft: "2em",
              fontWeight: 400,
            }}
          >
            {selectEnabled && selectEnabled}
          </div>
        </div>
      );
    }
  }
};

export default PageHeaderComponent;
