import React, { useState } from "react";
import { UpOutlined } from "@ant-design/icons";
import { Col, Collapse, Row, Space } from "antd";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { selectContentCount } from "../../../core/redux/selectors/content/content.selector";
import {
  isChapter,
  isCollection,
  isPlaybook,
} from "../../../core/utils/helper.utils";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import PlaybookDetailModel from "../../../core/models/content/playbook-detail.model";
import ContentLabelComponent from "./content-label/content-label.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ContentTreeComponent from "../content-tree/content-tree.component";
import ChapterDetailModel from "../../../core/models/content/chapter-detail.model";
import CollectionDetailModel from "../../../core/models/content/collection-detail.model";
import styles from "./content.module.css";

interface Props {
  contentDetail:
    | PlaybookDetailModel
    | ChapterDetailModel
    | CollectionDetailModel;
}

const ContentMetaComponent = (props: Props) => {
  const [t] = useTranslation();
  const { contentDetail } = props;
  const { contentType } = contentDetail;
  const { isSelected } = useContentSidebar();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const contentCount = selectContentCount(contentDetail);

  const showContentTree = () => {
    if (isCollection(contentType)) {
      return false;
    }

    if (isPlaybook(contentType)) {
      return contentCount.chapters > 0;
    }

    if (isChapter(contentType)) {
      return contentCount.collections > 0;
    }

    return false;
  };

  const renderHeader = () => {
    return (
      <Row className={styles.content_header_content_container}>
        <Col flex={1} style={{ width: "100%" }}>
          <Space size={"large"} style={{ width: "100%" }}>
            {isPlaybook(contentType) && (
              <ContentLabelComponent
                contentType={ContentTypesEnum.CHAPTER}
                contentState={ContentStatesEnum.DRAFT}
              >
                {t("content.chapter.keyWithCount", {
                  count: contentCount.chapters,
                })}
              </ContentLabelComponent>
            )}

            {(isPlaybook(contentType) || isChapter(contentType)) && (
              <ContentLabelComponent
                contentType={ContentTypesEnum.COLLECTION}
                contentState={ContentStatesEnum.DRAFT}
              >
                {t("content.collection.keyWithCount", {
                  count: contentCount.collections,
                })}
              </ContentLabelComponent>
            )}

            {(isPlaybook(contentType) ||
              isChapter(contentType) ||
              isCollection(contentType)) && (
              <ContentLabelComponent
                contentType={ContentTypesEnum.CARD}
                contentState={ContentStatesEnum.DRAFT}
              >
                {t("content.card.keyWithCount", { count: contentCount.cards })}
              </ContentLabelComponent>
            )}
          </Space>
        </Col>
        {showContentTree() && (
          <Col>
            <UpOutlined rotate={isExpanded ? 0 : 180} />
          </Col>
        )}
      </Row>
    );
  };

  const getSelected = (): number => {
    switch (contentDetail.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return isSelected(`playbook_${contentDetail.playbookUid}`) ? 0 : 1;
      case ContentTypesEnum.CHAPTER:
        return isSelected(`chapter_${contentDetail.chapterUid}`) ? 0 : 1;
      case ContentTypesEnum.COLLECTION:
        return 1;
    }
  };

  if (showContentTree()) {
    return (
      <Collapse
        ghost
        defaultActiveKey={getSelected()}
        onChange={(e) => setIsExpanded(e.length === 1)}
      >
        <Collapse.Panel
          key={0}
          className={"Panel_2"}
          showArrow={false}
          header={renderHeader()}
          collapsible={showContentTree() ? "header" : "disabled"}
        >
          <ContentTreeComponent contentDetail={contentDetail} />
        </Collapse.Panel>
      </Collapse>
    );
  } else {
    return <div style={{ userSelect: "none" }}>{renderHeader()}</div>;
  }
};

export default ContentMetaComponent;
