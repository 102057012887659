import React, { useEffect, useState } from "react";
import { ContentProgressEnum } from "../../../../core/enums/content-progress.enum";
import { Spin } from "antd";
import { useCompletedUserProgress } from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import SectionTitleComponent from "../../section-title/section-title.component";
import FilterContentProgressComponent from "../../actions/filter-content-progress.component";
import ModuleCompletionComponent from "./module-completion.component";
import Empty from "../../empty/empty";
import PlaybookCompletedUserProgressModel from "../../../../core/models/user-progress/completed-user-progress/completed-user-progress-type/playbook-completed-user-progress.model";
import ContentCompletedUserProgressType from "../../../../core/types/content-completed-user-progress.type";
import graphStyles from "../../graphs/graph-container/graph-container.module.css";
import UserModel from "../../../../core/models/user/user.model";
import { useGetPlaybooks } from "../../../../core/api/primio/primioComponents";

interface OwnProps {
  user: UserModel;
}

type Props = OwnProps;

const UserContentCompletionComponent = ({ user }: Props) => {
  const { t } = useTranslation();
  const { data, isFetching } = useCompletedUserProgress(user);
  const [filteredModules, setFilteredModules] = useState<
    PlaybookCompletedUserProgressModel[]
  >([]);
  const [progressFilter, setProgressFilter] = useState<ContentProgressEnum[]>(
    [],
  );
  const { data: playbooks = [] } = useGetPlaybooks({
    queryParams: { excludeMedia: true },
  });

  useEffect(() => {
    if (!data || data.userUid !== user.sub) {
      return;
    }

    if (progressFilter.length === 0) {
      setFilteredModules(data.playbooks);
    } else {
      setFilteredModules(renderFilteredModules(data.playbooks));
    }
  }, [data, progressFilter]);

  return (
    <>
      <SectionTitleComponent
        title={`${t("containers.users.key")} ${t(
          "graphs.activity-screen.title",
        )}`}
      />

      <FilterContentProgressComponent
        filterContentProgress={setProgressFilter}
      />

      {renderEmptyStates() ? (
        <div className={graphStyles.container} style={{ height: "17.5rem" }}>
          <div
            className={graphStyles.container_spinner}
            style={{ alignItems: "center", height: "100%" }}
          >
            <div className={graphStyles.container_spinner_item}>
              {renderEmptyStates()}
            </div>
          </div>
        </div>
      ) : (
        filteredModules.map((play) => {
          const playbook = playbooks.find(
            (p) => p.playbookUid === play.playbookUid,
          );
          if (!playbook) return null;
          return (
            <ModuleCompletionComponent
              key={play.playbookUid}
              playbookProgress={play}
              playbook={playbook}
            />
          );
        })
      )}
    </>
  );

  function renderFilteredModules(
    content: PlaybookCompletedUserProgressModel[],
  ) {
    const filtered: PlaybookCompletedUserProgressModel[] = [];

    progressFilter.forEach((filter) => {
      filtered.push(...content.filter((c) => handleOnFilterModules(filter, c)));
    });

    return filtered;
  }

  function renderEmptyStates() {
    if (isFetching) {
      return <Spin />;
    }

    if (!data || data.playbooks.length === 0) {
      return <Empty image={"404"} message={""} />;
    }

    if (filteredModules.length === 0) {
      const pending = data.playbooks.filter((p) =>
        handleOnFilterModules(ContentProgressEnum.PENDING, p),
      );
      const started = data.playbooks.filter((p) =>
        handleOnFilterModules(ContentProgressEnum.STARTED, p),
      );
      const completed = data.playbooks.filter((p) =>
        handleOnFilterModules(ContentProgressEnum.COMPLETED, p),
      );

      if (
        progressFilter.includes(ContentProgressEnum.PENDING) &&
        pending.length === 0
      ) {
        const message = `screens.user-detail.content-completion.empty-state.${ContentProgressEnum.PENDING}`;
        return <Empty image={"wow"} message={t(message)} />;
      }

      if (
        progressFilter.includes(ContentProgressEnum.STARTED) &&
        started.length === 0
      ) {
        const message = `screens.user-detail.content-completion.empty-state.${ContentProgressEnum.STARTED}`;
        return <Empty image={"chill"} message={t(message)} />;
      }

      if (
        progressFilter.includes(ContentProgressEnum.COMPLETED) &&
        completed.length === 0
      ) {
        const message = `screens.user-detail.content-completion.empty-state.${ContentProgressEnum.COMPLETED}`;
        return <Empty image={"chill"} message={t(message)} />;
      }
    }

    return;
  }

  function handleOnFilterModules(
    filter: ContentProgressEnum,
    content: ContentCompletedUserProgressType,
  ): boolean {
    switch (filter) {
      case ContentProgressEnum.PENDING:
        return content.cardsCompleted / content.totalCards === 0;
      case ContentProgressEnum.STARTED:
        return (
          content.cardsCompleted / content.totalCards > 0 &&
          content.cardsCompleted / content.totalCards < 1
        );
      case ContentProgressEnum.COMPLETED:
        return content.cardsCompleted / content.totalCards === 1;
    }
  }
};

export default UserContentCompletionComponent;
