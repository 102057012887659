import { SortContentInterface } from "../../../interfaces/sort-content.interface";
import PlaybookModel from "../../../models/content/playbook.model";
import ChapterModel from "../../../models/content/chapter.model";
import CollectionModel from "../../../models/content/collection.model";
import BaseCardModel from "../../../models/content/base-card.model";
import ContentTypesEnum from "../../../enums/content-types.enum";
import ContentAction from "./content.action";
import BaseReducer from "../base/base.reducer";
import ThunkInterface from "../../../interfaces/thunk.interface";
import CardTypes from "../../../types/card.types";
import ActionInterface from "../../../interfaces/action.interface";
import ContentType from "../../../types/content.type";
import { ContentStatesEnum } from "../../../enums/content-states.enum";
import CreatePlaybookModel from "../../../models/create-content/create-playbook.model";
import CreateChapterModel from "../../../models/create-content/create-chapter.model";
import CreateCollectionModel from "../../../models/create-content/create-collection.model";
import { BulkPublishCardsInterface } from "../../../interfaces/bulk-publish-cards.interface";

export interface ContentReducerInterface {
  playbooks: PlaybookModel[];
  chapters: ChapterModel[];
  collections: CollectionModel[];
  cards: CardTypes[];
}

export default class ContentReducer extends BaseReducer<ContentReducerInterface> {
  initialState: ContentReducerInterface = {
    playbooks: [],
    chapters: [],
    collections: [],
    cards: [],
  };

  getPluralContentName(content: ContentType) {
    switch (content.contentType) {
      case ContentTypesEnum.CHAPTER:
        return "chapters";
      case ContentTypesEnum.PLAYBOOK:
        return "playbooks";
      case ContentTypesEnum.COLLECTION:
        return "collections";
      case ContentTypesEnum.CARD:
        return "cards";
    }
  }

  [ContentAction.REQUEST_PLAYBOOKS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<PlaybookModel[]>,
  ) {
    return {
      ...state,
      playbooks: action.payload,
    };
  }

  [ContentAction.REQUEST_DETAIL_PLAYBOOKS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<PlaybookModel>,
  ) {
    const playbook = action.payload;

    if (!playbook) {
      return { ...state };
    }

    const newCards: CardTypes[] = [];
    const newCollections: CollectionModel[] = [];
    const newChapters: ChapterModel[] = [];

    playbook.chapters?.forEach((chapter) => {
      newChapters.push(chapter);
      chapter.collections?.forEach((collection) => {
        newCollections.push(collection);
        collection.cards?.forEach((card) => {
          newCards.push(card);
        });
      });
    });

    newChapters.map((c) => (c.collections = undefined));
    playbook.chapters = undefined;

    return {
      ...state,
      playbooks: this.addSingleToArray(
        playbook,
        state.playbooks,
        "playbookUid",
      ),
      chapters: this.addArrayToArray(newChapters, state.chapters, "chapterUid"),
      collections: this.addArrayToArray(
        newCollections,
        state.collections,
        "collectionUid",
      ),
      cards: this.addArrayToArray(newCards, state.cards, "cardUid"),
    };
  }

  [ContentAction.REQUEST_CHAPTERS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<ChapterModel[]>,
  ) {
    return {
      ...state,
      chapters: action.payload,
    };
  }

  [ContentAction.REQUEST_DETAIL_CHAPTERS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<ChapterModel>,
  ) {
    return {
      ...state,
      chapters: this.addSingleToArray<ChapterModel>(
        action.payload,
        [...state.chapters] as ChapterModel[],
        "chapterUid",
      ),
    };
  }

  [ContentAction.REQUEST_COLLECTIONS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<CollectionModel[]>,
  ) {
    return {
      ...state,
      collections: action.payload,
    };
  }

  [ContentAction.REQUEST_DETAIL_COLLECTIONS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<CollectionModel>,
  ) {
    const collection = action.payload;
    let cards = [...state.cards];

    if (collection.cards) {
      cards = cards.filter((c) => c.collectionUid !== collection.collectionUid);
      cards.push(...collection.cards);
      collection.cards = undefined;
    }

    return {
      ...state,
      collections: this.addSingleToArray<CollectionModel>(
        collection,
        [...state.collections] as CollectionModel[],
        "collectionUid",
      ),
      cards,
    };
  }

  [ContentAction.REQUEST_CARDS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<BaseCardModel[]>,
  ) {
    return {
      ...state,
      cards: action.payload,
    };
  }

  [ContentAction.REQUEST_DETAIL_CARDS_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<CardTypes>,
  ) {
    const card = action.payload;
    const cards = [...state.cards];
    const index = cards.findIndex(
      (c) => c.cardUid === card.cardUid && c.version === card.version,
    );

    if (index === -1) {
      // Card did not exist before
      cards.push(card);
      return { ...state, cards };
    }

    cards[index] = card;

    return { ...state, cards };
  }

  [ContentAction.SORT_CONTENT](
    state: ContentReducerInterface,
    action: ActionInterface<
      { content: ContentType; data: SortContentInterface }[]
    >,
  ) {
    if (!action.payload) {
      return { ...state };
    }

    const input = action.payload;

    if (input.length === 0) {
      return { ...state };
    }

    const contentName = this.getPluralContentName(input[0].content);
    const contentArray = state[contentName];

    if (!contentArray) {
      return { ...state };
    }

    input.forEach(({ content, data }) => {
      const index = contentArray.findIndex((c) => c.getId === content.getId);
      if (index === -1) {
        return { ...state };
      }

      contentArray[index].sort = data.sort;
    });

    contentArray.sort((a, b) => b.sort - a.sort);

    return {
      ...state,
      [contentName]: contentArray,
    };
  }

  [ContentAction.REQUEST_CHAPTERS_VIA_PLAYBOOK_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<PlaybookModel>,
  ) {
    const playbook = action.payload;

    if (playbook && playbook.chapters) {
      return {
        ...state,
        chapters: this.addArrayToArray(
          playbook.chapters,
          [...state.chapters] as ChapterModel[],
          "chapterUid",
        ),
      };
    }

    return { ...state };
  }

  [ContentAction.REQUEST_COLLECTIONS_VIA_CHAPTER_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<ChapterModel>,
  ) {
    const chapter = action.payload;

    if (chapter && chapter.collections) {
      return {
        ...state,
        collections: this.addArrayToArray(
          chapter.collections,
          [...state.collections],
          "collectionUid",
        ),
      };
    }

    return { ...state };
  }

  [ContentAction.REQUEST_CARDS_VIA_COLLECTION_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<CollectionModel>,
  ) {
    const collection = action.payload;
    let cards = [...state.cards];

    if (!collection) {
      return { ...state };
    }

    if (collection.cards) {
      cards = cards.filter((c) => c.collectionUid !== collection.collectionUid);
      cards.push(...collection.cards);
      collection.cards = undefined;
    }

    return { ...state, cards };
  }

  [ContentAction.REQUEST_DRAFT_PLAYBOOKS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const playbookUid = action.meta.args[0].playbookUid as string;
    const index = state.playbooks.findIndex(
      (p) => p.playbookUid === playbookUid,
    );

    if (index === -1) {
      return { ...state };
    }

    const playbooks = [...state.playbooks];
    playbooks[index].contentState = ContentStatesEnum.DRAFT;

    return {
      ...state,
      playbooks,
    };
  }

  [ContentAction.REQUEST_DRAFT_CHAPTERS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const chapterUid = action.meta.args[0].chapterUid as string;
    const index = state.chapters.findIndex((c) => c.chapterUid === chapterUid);

    if (index === -1) {
      return { ...state };
    }

    const chapters = [...state.chapters];
    chapters[index].contentState = ContentStatesEnum.DRAFT;

    return {
      ...state,
      chapters,
    };
  }

  [ContentAction.REQUEST_DRAFT_COLLECTIONS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const collectionUid = action.meta.args[0].collectionUid as string;
    const index = state.collections.findIndex(
      (c) => c.collectionUid === collectionUid,
    );

    if (index === -1) {
      return { ...state };
    }

    const collections = [...state.collections];
    collections[index].contentState = ContentStatesEnum.DRAFT;

    return {
      ...state,
      collections,
    };
  }

  [ContentAction.REQUEST_DRAFT_CARDS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const cardUid = action.meta.args[0].cardUid as string;
    const index = state.cards.findIndex((c) => c.cardUid === cardUid);

    if (index === -1) {
      return { ...state };
    }

    const cards = [...state.cards];
    cards[index].contentState = ContentStatesEnum.DRAFT;

    return {
      ...state,
      cards,
    };
  }

  [ContentAction.REQUEST_PUBLISH_PLAYBOOKS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const { args } = action.meta;
    const playbookUid = args[1];

    const index = state.playbooks.findIndex(
      (p) => p.playbookUid === playbookUid,
    );
    if (index === -1) {
      return { ...state };
    }

    const playbooks = [...state.playbooks];
    playbooks[index].contentState = ContentStatesEnum.PUBLISHED;

    return { ...state, playbooks };
  }

  [ContentAction.REQUEST_PUBLISH_CHAPTERS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const { args } = action.meta;
    const chapterUid = args[1];

    const index = state.chapters.findIndex((p) => p.chapterUid === chapterUid);
    if (index === -1) {
      return { ...state };
    }

    const chapters = [...state.chapters];
    chapters[index].contentState = ContentStatesEnum.PUBLISHED;

    return { ...state, chapters };
  }

  [ContentAction.REQUEST_PUBLISH_COLLECTIONS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const { args } = action.meta;
    const collectionUid = args[1];

    const index = state.collections.findIndex(
      (p) => p.collectionUid === collectionUid,
    );
    if (index === -1) {
      return { ...state };
    }

    const collections = [...state.collections];
    collections[index].contentState = ContentStatesEnum.PUBLISHED;

    return { ...state, collections };
  }

  [ContentAction.REQUEST_PUBLISH_CARDS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const { args } = action.meta;
    const cardUid = args[1];

    const index = state.cards.findIndex((p) => p.cardUid === cardUid);
    if (index === -1) {
      return { ...state };
    }

    const cards = [...state.cards];
    cards[index].contentState = ContentStatesEnum.PUBLISHED;

    return { ...state, cards };
  }

  [ContentAction.REQUEST_BULK_PUBLISH_CARDS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const { args } = action.meta;
    const publishedCards: BulkPublishCardsInterface[] = args[1];
    const cards = [...state.cards];

    publishedCards.forEach((pc) => {
      const index = cards.findIndex((c) => c.cardUid === pc.cardUid);
      if (index !== -1) {
        cards[index].contentState = ContentStatesEnum.DELETED;
      }

      const newPublishedCardIndex = cards.findIndex(
        (c) => c.cardUid === pc.cardUid && c.version === pc.version,
      );
      if (newPublishedCardIndex === -1) {
        return;
      }

      cards[newPublishedCardIndex].contentState = ContentStatesEnum.PUBLISHED;
    });

    return {
      ...state,
      cards: cards.filter((c) => c.contentState !== ContentStatesEnum.DELETED),
    };
  }

  [ContentAction.REQUEST_DELETE_PLAYBOOKS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const playbookUid = action.meta.args[0].playbookUid as string;
    return {
      ...state,
      playbooks: [...state.playbooks].filter(
        (p) => p.playbookUid !== playbookUid,
      ),
    };
  }

  [ContentAction.REQUEST_DELETE_CHAPTERS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const chapterUid = action.meta.args[0].chapterUid as string;
    return {
      ...state,
      chapters: [...state.chapters].filter((c) => c.chapterUid !== chapterUid),
    };
  }

  [ContentAction.REQUEST_DELETE_COLLECTIONS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const collectionUid = action.meta.args[0].collectionUid as string;
    return {
      ...state,
      cards: [...state.cards].filter((c) => c.collectionUid !== collectionUid),
      collections: [...state.collections].filter(
        (c) => c.collectionUid !== collectionUid,
      ),
    };
  }

  [ContentAction.REQUEST_DELETE_CARDS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const [card, version] = action.meta.args;
    return {
      ...state,
      cards: [...state.cards].filter(
        (c) => !(c.cardUid === card.cardUid && c.version === version),
      ),
    };
  }

  [ContentAction.REQUEST_UPDATE_CONTENT_FINISHED](
    state: ContentReducerInterface,
    action: ThunkInterface<any>,
  ) {
    const [contentType, contentUid, patch, previousData] = action.meta.args as [
      contentType: "PLAYBOOK" | "CHAPTER" | "COLLECTION" | "CARD",
      contentUid: string,
      patch: any,
      previousData: ContentType,
    ];
    if (contentType === "PLAYBOOK") {
      const playbooks = [...state.playbooks];
      const index = playbooks.findIndex((p) => p.playbookUid === contentUid);

      if (index === -1) {
        return { ...this.state };
      }

      playbooks[index] = {
        ...playbooks[index],
        ...patch,
      };

      return {
        ...state,
        playbooks,
      };
    } else if (contentType === "CHAPTER") {
      const chapters = [...state.chapters];
      const index = chapters.findIndex((p) => p.chapterUid === contentUid);

      if (index === -1) {
        return { ...this.state };
      }

      chapters[index] = {
        ...chapters[index],
        ...patch,
      };

      return {
        ...state,
        chapters,
      };
    } else if (contentType === "COLLECTION") {
      const collections = [...state.collections];
      const index = collections.findIndex(
        (p) => p.collectionUid === contentUid,
      );

      if (index === -1) {
        return { ...this.state };
      }

      collections[index] = {
        ...collections[index],
        ...patch,
      };

      return {
        ...state,
        collections,
      };
    } else if (contentType === "CARD") {
      if (previousData.contentType !== ContentTypesEnum.CARD) {
        return { ...this.state };
      }

      const cards = [...state.cards];
      const index = cards.findIndex(
        (p) => p.cardUid === contentUid && p.version === previousData.version,
      );

      if (index === -1) {
        return { ...this.state };
      }

      cards[index] = {
        ...cards[index],
        ...patch,
      };

      return {
        ...state,
        cards,
      };
    }

    return { ...state };
  }

  [ContentAction.REQUEST_CREATE_PLAYBOOKS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    // This reducer is not 100% complete.
    // We can't add chapters and collections as de don't know their Uid's

    const playbookUid = action.payload.data;
    const meta = action.meta.args as [
      contentType: ContentTypesEnum.PLAYBOOK,
      data: CreatePlaybookModel,
    ];
    const data = meta[1];
    const highestSortPlaybook = this.findHighestSortValueObject([
      ...state.playbooks,
    ]);
    const highestSort = highestSortPlaybook ? highestSortPlaybook.sort : 0;

    const playbook = new PlaybookModel({
      playbookUid,
      title: data.title,
      description: data.description,
      userGroupAcl: data.userGroupAcl ?? [],
      sort: highestSort + 1,
      createdAt: new Date().toString(),
      categories: [],
      contentState: ContentStatesEnum.DRAFT,
    });

    return {
      ...state,
      playbooks: this.addSingleToArray(
        playbook,
        state.playbooks,
        "playbookUid",
      ),
    };
  }

  [ContentAction.REQUEST_CREATE_CHAPTERS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const meta = action.meta.args as [
      contentType: ContentTypesEnum.CHAPTER,
      data: CreateChapterModel,
    ];
    const data = meta[1];
    const highestSortChapter = this.findHighestSortValueObject([
      ...state.chapters,
    ]);
    const highestSort = highestSortChapter ? highestSortChapter.sort : 0;

    const chapter = new ChapterModel({
      title: data.title,
      description: data.description,
      playbookUid: data.playbookUid,
      sort: highestSort + 1,
      chapterUid: action.payload.data,
      contentState: ContentStatesEnum.DRAFT,
      createdAt: new Date().toString(),
    });

    return {
      ...state,
      chapters: this.addSingleToArray(chapter, state.chapters, "chapterUid"),
    };
  }

  [ContentAction.REQUEST_CREATE_COLLECTIONS_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<any>,
  ) {
    const meta = action.meta.args as [
      contentType: ContentTypesEnum.COLLECTION,
      data: CreateCollectionModel,
    ];
    const data = meta[1];
    const highestSortCollection = this.findHighestSortValueObject([
      ...state.collections,
    ]);
    const highestSort = highestSortCollection ? highestSortCollection.sort : 0;

    const collection = new CollectionModel({
      title: data.title,
      chapterUid: data.chapterUid,
      description: data.description,
      collectionUid: action.payload.data,
      contentState: ContentStatesEnum.DRAFT,
      createdAt: new Date().toString(),
      sort: highestSort + 1,
      endSummary: {
        title: "translations:defaults.start-summary.title",
        description: "translations:defaults.start-summary.description",
      },
      startSummary: {
        title: "translations:defaults.end-summary.title",
        description: "translations:defaults.end-summary.description",
      },
    });

    return {
      ...state,
      collections: this.addSingleToArray(
        collection,
        state.collections,
        "collectionUid",
      ),
    };
  }

  [ContentAction.REQUEST_PLAYBOOK_HIERARCHY_FINISHED](
    state: ContentReducerInterface,
    action: ActionInterface<PlaybookModel>,
  ) {
    const playbook = action.payload;

    if (!playbook) {
      return { ...state };
    }

    const newCollections: CollectionModel[] = [];
    const newChapters: ChapterModel[] = [];

    playbook.chapters?.forEach((chapter) => {
      newChapters.push(chapter);
      chapter.collections?.forEach((collection) => {
        newCollections.push(collection);
      });
    });

    newChapters.map((c) => (c.collections = undefined));
    playbook.chapters = undefined;

    return {
      ...state,
      playbooks: this.addSingleToArray(
        playbook,
        state.playbooks,
        "playbookUid",
      ),
      chapters: this.addArrayToArray(newChapters, state.chapters, "chapterUid"),
      collections: this.addArrayToArray(
        newCollections,
        state.collections,
        "collectionUid",
      ),
    };
  }

  private findHighestSortValueObject<T extends { sort: number }>(arr: T[]) {
    if (arr.length === 0) return undefined;

    return arr.reduce((prev, current) => {
      return prev.sort > current.sort ? prev : current;
    });
  }
}
