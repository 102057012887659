import { AnswerTypeEnum } from "../../enums/answer-type.enum";

export class InteractiveCard {
  cardUid: string;
  userUid: string;
  version: number;
  completedAt: Date;
  timeSpent: number;
  answerType: AnswerTypeEnum;
  answer: number;

  constructor(cardUid: string, response: InteractiveCardInterface) {
    this.cardUid = cardUid;
    this.userUid = response.userUid;
    this.version = response.version;
    this.completedAt = new Date(response.completedAt);
    this.timeSpent = response.timeSpent;
    this.answerType = response.answerType;
    this.answer = response.answer;
  }
}

export interface InteractiveCardInterface {
  userUid: string;
  version: number;
  completedAt: Date;
  timeSpent: number;
  answerType: AnswerTypeEnum;
  answer: number;
}

export interface InteractiveCardResponse {
  [cardUid: string]: InteractiveCardInterface[];
}

export default class InteractiveCardModel {
  data: InteractiveCard[];

  constructor(response: InteractiveCardResponse) {
    this.data = [];

    const result = Object.entries(response);
    if (result.length !== 1) {
      throw new Error("empty response");
    }

    const [cardUid, data] = result[0];
    this.data = data.map((card) => new InteractiveCard(cardUid, card));
  }
}
