import ThunkInterface from "../../../interfaces/thunk.interface";
import BaseReducer from "../base/base.reducer";
import InvitationModel from "../../../models/invitation/invitation.model";
import InvitationAction from "./invitation.action";

export interface InvitationReducerInterface {
  invitations: InvitationModel[];
}

export default class InvitationReducer extends BaseReducer<InvitationReducerInterface> {
  initialState: InvitationReducerInterface = {
    invitations: [],
  };

  [InvitationAction.REQUEST_INVITATIONS_FINISHED](
    state: InvitationReducerInterface,
    action: ThunkInterface<InvitationModel[]>,
  ) {
    return {
      ...state,
      invitations: action.payload,
    };
  }

  [InvitationAction.REQUEST_INVITATION_FINISHED](
    state: InvitationReducerInterface,
    action: ThunkInterface<InvitationModel>,
  ) {
    return {
      ...state,
      invitations: this.addSingleToArray<InvitationModel>(
        action.payload,
        [...(state.invitations as InvitationModel[])],
        "invitationUid",
      ),
    };
  }

  [InvitationAction.REQUEST_DELETE_INVITATION_FINISHED](
    state: InvitationReducerInterface,
    action: ThunkInterface<InvitationModel>,
  ) {
    return {
      ...state,
      invitations: this.removeSingleFromArray<InvitationModel>(
        action.meta.args[0],
        [...(state.invitations as InvitationModel[])],
        "invitationUid",
      ),
    };
  }
}
