import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useStore } from "react-redux";
import {
  ParentContentUidsReturnType,
  selectCardViaId,
  selectChapterViaUid,
  selectCollectionViaUid,
  selectParentContentUids,
  selectPlaybookViaUid,
} from "../../../core/redux/selectors/content/content.selector";
import { useEffect } from "react";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import ContentAction from "../../../core/redux/stores/content/content.action";

interface EditModeScreenParams {
  playbookUid?: string;
  chapterUid?: string;
  collectionUid?: string;
  cardUid?: string;
}

const EditPageUrlParamHandler = () => {
  const { selectedContent, onClick } = useContentSidebar();
  const params = useParams<EditModeScreenParams>();
  const history = useHistory();
  const store = useStore();
  const parentContentUids: ParentContentUidsReturnType = useSelector((state) =>
    selectParentContentUids(state, selectedContent),
  );

  const isFetchingPlaybooks = useSelector((state) =>
    selectRequesting(state, [ContentAction.REQUEST_PLAYBOOKS]),
  );
  const isFetchingDetail = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_DETAIL_PLAYBOOKS,
      ContentAction.REQUEST_DETAIL_COLLECTIONS,
    ]),
  );

  useEffect(() => {
    if (!selectedContent) {
      return;
    }

    let url = "";
    switch (selectedContent.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        url = RoutesEnum.EDIT_MODE_PLAYBOOK.toString().replace(
          ":playbookUid",
          selectedContent.playbookUid,
        );
        history.push(url);
        break;
      case ContentTypesEnum.CHAPTER:
        url = RoutesEnum.EDIT_MODE_CHAPTER.toString()
          .replace(":playbookUid", selectedContent.playbookUid)
          .replace(":chapterUid", selectedContent.chapterUid);
        history.push(url);
        break;
      case ContentTypesEnum.COLLECTION:
        if (!parentContentUids) return;

        url = RoutesEnum.EDIT_MODE_COLLECTION.toString()
          .replace(":playbookUid", parentContentUids.playbookUid || "")
          .replace(":chapterUid", selectedContent.chapterUid)
          .replace(":collectionUid", selectedContent.collectionUid);
        history.push(url);
        break;
      case ContentTypesEnum.CARD:
        if (!parentContentUids) return;

        url = RoutesEnum.EDIT_MODE_CARD.toString()
          .replace(":playbookUid", parentContentUids.playbookUid || "")
          .replace(":chapterUid", parentContentUids.chapterUid || "")
          .replace(":collectionUid", selectedContent.collectionUid)
          .replace(":cardUid", selectedContent.cardUid);
        history.push(url);
        break;
    }
  }, [selectedContent]);

  useEffect(() => {
    if (isFetchingDetail || isFetchingPlaybooks) {
      return;
    }

    const state: StoreInterface = store.getState();

    if (params.cardUid) {
      const cards = selectCardViaId(state, params.cardUid);
      if (!cards) return;

      const card = cards.sort((a, b) => b.version - a.version)[0];

      if (!card) return;
      onClick(card.getId);
    } else if (params.collectionUid) {
      const collection = selectCollectionViaUid(state, params.collectionUid);
      if (!collection) return;
      onClick(collection.getId);
    } else if (params.chapterUid) {
      const chapter = selectChapterViaUid(state, params.chapterUid);
      if (!chapter) return;
      onClick(chapter.getId);
    } else if (params.playbookUid) {
      const playbook = selectPlaybookViaUid(state, params.playbookUid);
      if (!playbook) return;
      onClick(playbook.getId);
    }
  }, [isFetchingDetail, isFetchingPlaybooks]);

  return null;
};

export default EditPageUrlParamHandler;
