import React from "react";
import CharacterCounter from "./character-counter";
import countStyle from "../../card-types/card-types.module.css";
import TextDecodeUtils from "../../../../core/utils/text-decode.utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CleanHTML from "../../../../core/utils/clean-html";

interface OwnProps {
  value: string;
  placeholder?: string;
  onChange: (e: string) => void;
  showCounter?: boolean;
  maxLength?: number;
  disabled: boolean;
}

type Props = OwnProps;

const TextEditorBuildComponent = (props: Props) => {
  const { value, onChange, showCounter, maxLength } = props;

  return (
    <>
      <ReactQuill
        className={"react-quill"}
        theme="snow"
        value={CleanHTML(value)}
        onChange={(value) => onChange(CleanHTML(value))}
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        }}
      />
      {showCounter && (
        <CharacterCounter
          currentLength={TextDecodeUtils(value).length}
          maxLength={maxLength ? maxLength : 0}
          className={countStyle.character_counter}
        />
      )}
    </>
  );
};

export default TextEditorBuildComponent;
