import { MapResponseToModel } from "../../../../utils/map-response-to-model.utils";
import QuizOptionModel, { QuizOptionResponse } from "./quiz-option.model";

export interface QuizCardDataResponse {
  options: QuizOptionResponse[];
  question: string;
}

export default class QuizCardDataModel {
  options: QuizOptionModel[];
  question: string;

  constructor(quizCard: QuizCardDataResponse) {
    this.options = MapResponseToModel(quizCard.options, QuizOptionModel);
    this.question = quizCard.question;
  }
}
