import { EffectType } from "../../../types/redux.type";
import {
  CategoryEditInterface,
  PlaybooksInCategoryEditInterface,
} from "../../../interfaces/category-create.interface";
import CategoryEndpoints from "../../../api/category.endpoints";
import CategoryModel from "../../../models/category/category.model";
import EffectUtility from "../../../utils/effect.utils";

export default class CategoryEffect {
  /**
   * Get all Categories via GET Request
   * @returns Promise CategoryModel[]
   */
  static getAllCategories(): EffectType<CategoryModel[]> {
    const endpoint = CategoryEndpoints.overview;
    return EffectUtility.getToModel<CategoryModel[]>(CategoryModel, endpoint);
  }

  /**
   * Get one Category via GET Request
   * @param categoryUid string
   * @returns Promise CategoryModel
   */
  static getCategory(categoryUid: string): EffectType<CategoryModel> {
    const endpoint = CategoryEndpoints.detail(categoryUid);
    return EffectUtility.getToModel<CategoryModel>(CategoryModel, endpoint);
  }

  /**
   * Edit Category via POST Request
   * @param category CategoryModel
   * @param data CategoryEditInterface
   * @returns Promise CategoryModel
   */
  static editCategory(
    category: CategoryModel,
    data: CategoryEditInterface,
  ): EffectType<CategoryModel> {
    const endpoint = CategoryEndpoints.edit(category);
    return EffectUtility.postToModel<CategoryModel>(
      CategoryModel,
      endpoint,
      data,
    );
  }

  /**
   * Sort Playbooks in Category via POST Request
   * @param data PlaybooksInCategoryEditInterface
   * @returns Promise CategoryModel
   */
  static sortPlaybooks(
    data: PlaybooksInCategoryEditInterface,
  ): EffectType<CategoryModel> {
    const endpoint = CategoryEndpoints.sortPlaybooks(data);
    const playbookUids = {
      playbookUids: data.playbookUids,
    };
    return EffectUtility.postToModel<CategoryModel>(
      CategoryModel,
      endpoint,
      playbookUids,
    );
  }
}
