import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useForm } from "antd/lib/form/Form";
import { getRules } from "../../../../core/utils/get-rules.utils";
import { Button, Form, Input, Space, Tooltip, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { selectRequesting } from "../../../../core/redux/selectors/requesting/requesting.selector";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";
import { CreateCollectionSummaryResponse } from "../../../../core/models/create-content/create-collection.model";
import CardTypes from "../../../../core/types/card.types";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import TextEditorBuildComponent from "../../form-builder/components/text-editor-build.component";
import TextDecodeUtils from "../../../../core/utils/text-decode.utils";
import CollectionSummaryModel from "../../../../core/models/content/collection-summary.model";
import FormDividerComponent from "../../form-builder/components/form-divider.component";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import CharacterCounter from "../../form-builder/components/character-counter";
import AppConfig from "../../../../constants/config/app.config";
import Emitter from "../../../../core/utils/emitter.utils";
import TextArea from "antd/lib/input/TextArea";
import styles from "../card-types.module.css";

interface SharedProps {
  cardContent: CardTypes | CollectionSummaryModel | undefined;
  disabledButton: boolean;
  maxLength?: number;
  isOptional: boolean;
  richText: boolean;
  disabledFields: boolean;
  onSubmit?: (
    values: CreateCardInterface,
    pressedButton?: ContentButtonStatesEnum,
  ) => void;
  onSubmitSummary?: (values: CreateCollectionSummaryResponse) => void;
  children: JSX.Element;
  hideFields?: boolean;
}

type Props = SharedProps;

const CreateCardBaseForm = (props: Props) => {
  const [t] = useTranslation();
  const [form] = useForm<CreateCardInterface>();
  const {
    cardContent,
    maxLength,
    isOptional,
    richText,
    disabledFields,
    disabledButton,
    onSubmit,
    onSubmitSummary,
    hideFields,
  } = props;
  const [title, setTitle] = useState<string>(
    cardContent && cardContent.title
      ? TranslateDefaults(cardContent.title)
      : "",
  );
  const [description, setDescription] = useState<string>(
    cardContent && cardContent.description
      ? TranslateDefaults(cardContent.description)
      : "",
  );
  const [hiddenField, setHiddenField] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isPressedButton, setIsPressedButton] = useState<
    ContentButtonStatesEnum | undefined
  >();

  const titleMaxLength = maxLength
    ? AppConfig.knowledgeTitleMaxLength
    : AppConfig.formTitleMaxLength;
  const descriptionMaxLength = maxLength
    ? maxLength
    : AppConfig.formDescriptionMaxLength;

  let requesting: boolean;
  if (!(cardContent instanceof CollectionSummaryModel)) {
    requesting = useSelector((state) =>
      selectRequesting(state, [
        ContentAction.REQUEST_CREATE_CARDS,
        ContentAction.REQUEST_UPDATE_CONTENT,
        ContentAction.REQUEST_CARDS_VIA_COLLECTION,
      ]),
    );
  } else {
    requesting = useSelector((state: StoreInterface) =>
      selectRequesting(state, [ContentAction.REQUEST_UPDATE_CONTENT]),
    );
  }

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        form.resetFields();
        handleOnCancel();
      }
    }
  }, [requesting]);

  function handleOnFinish() {
    setIsSubmitting(true);
    if (!cardContent) {
      return;
    }

    if (!(cardContent instanceof CollectionSummaryModel)) {
      const createBaseCardData: CreateCardInterface = {
        title: title,
        description: description,
        collectionUid: cardContent.collectionUid,
        cardType: cardContent.cardType,
        mediaUids: [],
        sort: cardContent.sort,
      };

      if (onSubmit) {
        onSubmit(createBaseCardData, isPressedButton);
      }
    } else {
      const createBaseSummaryCardData: CreateCollectionSummaryResponse = {
        title: title,
        description: description,
        mediaUids: [],
      };

      if (onSubmitSummary) {
        onSubmitSummary(createBaseSummaryCardData);
      }
    }
  }

  return (
    <Form
      form={form}
      className={styles.form_wrapper_horizontal}
      labelCol={{ span: 6 }}
      onFinish={handleOnFinish}
      onKeyDown={keyShortCuts}
      layout={"horizontal"}
      labelAlign={"left"}
    >
      <div className={styles.form_wrapper_bottom}>
        {!hideFields && (
          <>
            <Form.Item
              initialValue={title}
              name={"title"}
              hidden={hiddenField}
              label={Capitalize(t("form.card.title.label"))}
              rules={getRules("title", isOptional, maxLength)}
            >
              <Input
                value={title}
                type={"text"}
                suffix={
                  <CharacterCounter
                    currentLength={title.length}
                    maxLength={titleMaxLength}
                  />
                }
                style={{ width: "80%" }}
                autoFocus
                maxLength={titleMaxLength}
                placeholder={t("form.card.title.placeholder")}
                disabled={disabledFields}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>

            {richText ? (
              <Form.Item
                initialValue={description}
                name={"description"}
                hidden={hiddenField}
                label={renderDescriptionLabel(isOptional)}
              >
                <div style={{ width: "80%", position: "relative" }}>
                  <TextEditorBuildComponent
                    placeholder={t("form.card.description.placeholder")}
                    value={description}
                    onChange={updatedDescription}
                    showCounter={!hiddenField}
                    disabled={disabledFields}
                    maxLength={descriptionMaxLength}
                  />
                </div>
              </Form.Item>
            ) : (
              <Form.Item
                initialValue={description}
                name={"description"}
                hidden={hiddenField}
                label={Capitalize(t("form.card.description.label"))}
                rules={getRules("description", isOptional, maxLength)}
              >
                <TextArea
                  value={description}
                  rows={4}
                  style={{ width: "80%" }}
                  maxLength={descriptionMaxLength}
                  placeholder={t("form.card.description.placeholder")}
                  disabled={disabledFields}
                  onChange={(e) => setDescription(e.target.value)}
                />

                {!disabledFields && (
                  <CharacterCounter
                    currentLength={TextDecodeUtils(description).length}
                    maxLength={descriptionMaxLength}
                    className={styles.character_counter}
                  />
                )}
              </Form.Item>
            )}

            {isOptional && (
              <>
                {disabledFields && !hiddenField && (
                  <div>
                    <Typography.Text italic>
                      {t("form.card.media.disabled-field", {
                        field: Capitalize(
                          t("form.card.title.label") +
                            " + " +
                            t("form.card.description.label"),
                        ),
                      })}
                    </Typography.Text>
                  </div>
                )}

                <FormDividerComponent
                  hiddenField={hiddenField}
                  onClick={() => setHiddenField(!hiddenField)}
                />
              </>
            )}
          </>
        )}

        {props.children}
      </div>
      {cardContent?.contentState === ContentStatesEnum.PUBLISHED &&
        !(cardContent instanceof CollectionSummaryModel) && (
          <div className={styles.form_alert}>
            <div className={styles.form_alert_wrapper}>
              <div style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
                <InfoCircleFilled style={{ color: "#1890FF" }} />
              </div>
              <div>
                <span>{Capitalize(t("containers.modules.alert_first"))}</span>
                <Tooltip
                  title={t("containers.modules.edit.tooltip-new-version")}
                >
                  <span style={{ textDecoration: "underline" }}>
                    {Capitalize(t("common.create-new-card"))}
                  </span>
                </Tooltip>
                <span>{" " + t("containers.modules.alert_second")}</span>
                <Tooltip title={t("containers.modules.edit.tooltip-update")}>
                  <span style={{ textDecoration: "underline" }}>
                    {Capitalize(t("common.update")) +
                      " " +
                      t("content.card.key")}
                  </span>
                </Tooltip>
                <span>{" " + t("containers.modules.alert_third")}</span>
              </div>
            </div>
          </div>
        )}
      <Form.Item>
        <Space
          size={"middle"}
          direction={"horizontal"}
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <Button type={"text"} onClick={handleOnCancel}>
            {Capitalize(t("common.cancel"))}
          </Button>
          {renderButtons()}
        </Space>
      </Form.Item>
    </Form>
  );

  function renderDescriptionLabel(isOptional) {
    return (
      <>
        {isOptional ? (
          Capitalize(t("form.card.description.label"))
        ) : (
          <>
            <span
              style={{
                color: "#ff4d4f",
                marginRight: "3px",
                fontFamily: "SimSun, sans-serif",
              }}
            >
              *
            </span>{" "}
            {Capitalize(t("form.card.description.label"))}
          </>
        )}
      </>
    );
  }

  function updatedDescription(text: string) {
    setDescription(text);

    if (!richText) {
      return;
    }

    if (TextDecodeUtils(text).length > descriptionMaxLength) {
      form.setFields([
        {
          name: "description",
          errors: [
            Capitalize(
              t("errors.max-length", {
                field: t(`form.card.description.label`),
                amount: descriptionMaxLength,
              }),
            ),
          ],
        },
      ]);
      return;
    }

    form.setFields([
      {
        name: "description",
        errors: [],
      },
    ]);
  }

  function renderButtons() {
    if (
      cardContent?.contentState === ContentStatesEnum.PUBLISHED &&
      !(cardContent instanceof CollectionSummaryModel)
    ) {
      return (
        <>
          <Button
            type={"primary"}
            ghost
            disabled={disableButton()}
            htmlType={"submit"}
            onClick={() => setIsPressedButton(ContentButtonStatesEnum.CREATE)}
            loading={isSubmitting}
          >
            {" "}
            {Capitalize(t("common.create-new-card"))}
          </Button>
          <Button
            type={"primary"}
            disabled={disableButton()}
            htmlType={"submit"}
            onClick={() => setIsPressedButton(ContentButtonStatesEnum.UPDATE)}
            loading={isSubmitting}
          >
            {Capitalize(t("common.update")) + " " + t("content.card.key")}
          </Button>
        </>
      );
    }

    return (
      <Button
        type={"primary"}
        disabled={disableButton()}
        htmlType={"submit"}
        loading={isSubmitting}
      >
        {Capitalize(t("common.save"))}
      </Button>
    );
  }

  function handleOnCancel() {
    if (!cardContent) {
      return;
    }

    if (!(cardContent instanceof CollectionSummaryModel)) {
      Emitter.emit("handleOnCancelCardBase", cardContent.cardUid);
      return;
    }
  }

  function disableButton() {
    if (isOptional) {
      return disabledButton;
    }

    if (richText) {
      return (
        disabledButton ||
        !title ||
        TextDecodeUtils(title).length === 0 ||
        TextDecodeUtils(title).length > titleMaxLength ||
        !description ||
        TextDecodeUtils(description).length === 0 ||
        TextDecodeUtils(description).length > descriptionMaxLength
      );
    }

    return !title || !description || disabledButton;
  }

  function keyShortCuts(e) {
    if (e.key === "Escape") {
      handleOnCancel();
    }
  }
};

CreateCardBaseForm.defaultProps = {
  isOptional: true,
  richText: false,
  disabledFields: false,
};

export default CreateCardBaseForm;
