import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input } from "antd";
import { LearningPathStateEnum } from "../../../../core/enums/learning-path-state.enum";
import minutesToDaysUtils from "../../../../core/utils/minutes-to-days.utils";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";

type Props = {
  delay: number;
  setDelay: (value: number) => void;
  learningPathState?: LearningPathStateEnum;
};

const MINIMUM_DAY_DELAY = 1;

const LearningPathDelayComponent = ({
  delay,
  setDelay,
  learningPathState,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number | undefined>(
    minutesToDaysUtils(delay),
  );

  useEffect(() => {
    setValue(minutesToDaysUtils(delay));
  }, [delay]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value);

    if (isNaN(number)) {
      setValue(undefined);
      return;
    }

    setValue(number < MINIMUM_DAY_DELAY ? MINIMUM_DAY_DELAY : number);
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <div style={{ marginRight: 12 }}>
        <Input
          disabled={learningPathState === LearningPathStateEnum.POSTED}
          type={"number"}
          value={value}
          min={MINIMUM_DAY_DELAY}
          style={{ maxWidth: 75, marginRight: 4 }}
          onChange={handleOnChange}
        />
        <span>{Capitalize(t("translations:common.day_plural"))}</span>
      </div>
      <Button
        type={"primary"}
        disabled={value === minutesToDaysUtils(delay) || value === undefined}
        onClick={() => {
          if (value === undefined) {
            return;
          }
          setDelay(value);
        }}
      >
        {Capitalize(t("translations:common.save"))}
      </Button>
    </div>
  );
};

export default LearningPathDelayComponent;
