import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
// @ts-ignore
import { CascaderOptionType, CascaderValueType } from "antd/lib/cascader";
import { selectContentTrees } from "../../../../core/redux/selectors/content/content.selector";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { Cascader } from "antd";
import PlaybookDetailModel from "../../../../core/models/content/playbook-detail.model";
import TextDecodeUtils from "../../../../core/utils/text-decode.utils";

interface OwnProps {
  content: (string | number)[] | null;
  setContent: (
    value: CascaderValueType,
    selectedOptions?: CascaderOptionType[],
  ) => void;
}

type Props = OwnProps;

const ContentCascaderComponent = (props: Props) => {
  const [t] = useTranslation();
  const { content, setContent } = props;
  const playbookDetails: PlaybookDetailModel[] = useSelector(
    (state: StoreInterface) => selectContentTrees(state),
  );

  const options: CascaderOptionType[] | undefined = playbookDetails.map(
    (play) => ({
      value: play.playbookUid,
      label: TextDecodeUtils(play.title),
      contentState: play.contentState,
      contentType: play.contentType,
      disabled: play.contentState === ContentStatesEnum.DRAFT,
      children: play.children?.map((chap) => ({
        value: chap.chapterUid,
        label: TextDecodeUtils(chap.title),
        contentState: chap.contentState,
        contentType: chap.contentType,
        disabled: chap.contentState === ContentStatesEnum.DRAFT,
        children: chap.children?.map((col) => ({
          value: col.collectionUid,
          label: TextDecodeUtils(col.title),
          contentState: col.contentState,
          contentType: col.contentType,
          disabled: col.contentState === ContentStatesEnum.DRAFT,
          children: col.children?.map((card) => ({
            value: card.cardUid,
            label: TextDecodeUtils(card.title),
            contentState: card.contentState,
            contentType: card.contentType,
            disabled: card.contentState === ContentStatesEnum.DRAFT,
          })),
        })),
      })),
    }),
  );

  return (
    <Cascader
      placeholder={Capitalize(
        t("form.placeholders.display_x", {
          field: t("form.items.content.label"),
        }),
      )}
      defaultValue={content ? content : []}
      onChange={setContent}
      autoFocus={true}
      popupClassName={"Cascader"}
      options={options}
      changeOnSelect
    />
  );
};

export default ContentCascaderComponent;
