import { useToken } from "../providers/auth.provider";
import { fetchDownloadCertificates } from "../api/primio/primioComponents";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ZipLearningPathCertificateDto } from "../api/primio/primioSchemas";

function saveBlob(blob, fileName) {
  const a = document.createElement("a");
  document.body.appendChild(a);

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

function fetchCertificatePdfs() {
  const token = useToken();
  return useMutation({
    mutationFn: ({ data }: { data: ZipLearningPathCertificateDto[] }) =>
      fetchDownloadCertificates({
        body: data,
        headers: {
          authorization: token ? `Bearer ${token}` : undefined,
        },
      }),
  });
}

export default function useDownloadCertificates() {
  const { t } = useTranslation();
  const { mutateAsync: fetchCertificates, ...props } = fetchCertificatePdfs();

  const downloadCertificates = async (
    data: ZipLearningPathCertificateDto[],
    fileName: string,
  ) => {
    try {
      const response = await fetchCertificates({ data });

      if (response) {
        saveBlob(response, fileName);
        toast.success(
          t("translations:form.download.download-certificate.success", {
            count: data.length,
          }),
        );
      }
    } catch (error) {
      toast.error(t("translations:errors.general"));
    }
  };

  return { downloadCertificates, ...props };
}
