import React, { useState } from "react";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import SharedContentClientOverviewScreen from "../../screens/shared-content-client/shared-content-client-overview.screen";
import CreateApiKeyClientModel from "../../components/modals/create-api-key-client.model";

const SharedContentClientContainer = () => {
  const [
    isCreateApiKeyClientModalVisible,
    setIsCreateApiKeyClientModalVisible,
  ] = useState<boolean>(false);

  const menuItems = () => {
    const menuItems: MenuItemInterface[] = [];
    return menuItems;
  };

  return (
    <PageLayoutComponent
      menuItems={menuItems()}
      title={"shared-content-client"}
      onPress={onShowCreateApiKeyClientModal}
      showSider={false}
    >
      <SharedContentClientOverviewScreen />
      <CreateApiKeyClientModel
        visible={isCreateApiKeyClientModalVisible}
        onCancel={onHideCreateApiKeyClientModal}
      />
    </PageLayoutComponent>
  );

  function onShowCreateApiKeyClientModal() {
    setIsCreateApiKeyClientModalVisible(true);
  }

  function onHideCreateApiKeyClientModal() {
    setIsCreateApiKeyClientModalVisible(false);
  }
};

export default SharedContentClientContainer;
