import React from "react";
import styles from "./content-sidebar.module.css";
import CreateContentButtonConnected from "../content-create-button/create-content-button.connected";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ContentSortEnum from "../../../core/enums/content-sort.enum";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Input, Menu } from "antd";
import {
  CloseOutlined,
  FilterOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import ContentFilterEnum from "../../../core/enums/content-filter.enum";
import { Capitalize } from "../../../core/utils/helper.utils";

interface Props {
  searchTerm: string;
  onSearch: (searchTerm: string) => void;
  sortOption: ContentSortEnum;
  onSortSelect: (sortOption: ContentSortEnum) => void;
  filter: ContentFilterEnum;
  onFilterChange: (filter: ContentFilterEnum) => void;
}

const ContentSidebarHeader = ({
  onSearch,
  searchTerm,
  sortOption,
  onSortSelect,
  filter,
  onFilterChange,
}: Props) => {
  const { t } = useTranslation();

  function handleChange(event) {
    onSearch(event.target.value);
  }

  const menu = (
    <Menu selectable selectedKeys={[sortOption]}>
      {Object.entries(ContentSortEnum).map((item) => (
        <Menu.Item key={item[0]} onClick={() => onSortSelect(item[1])}>
          {t(`translations:content-sort.${item[0]}`)}
        </Menu.Item>
      ))}
    </Menu>
  );

  const filterMenu = (
    <Menu selectable selectedKeys={[filter]}>
      {Object.entries(ContentFilterEnum).map((item) => (
        <Menu.Item key={item[0]} onClick={() => onFilterChange(item[1])}>
          {Capitalize(t(`translations:content-state.${item[0]}`))}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles.container_header}>
      <div style={{ display: "flex" }}>
        <Input
          allowClear
          placeholder={Capitalize(t("translations:common.search") + "...")}
          value={searchTerm}
          onChange={handleChange}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            type={sortOption === ContentSortEnum.CUSTOM ? "default" : "primary"}
            icon={
              sortOption === ContentSortEnum.CREATED_AT_ASC ||
              sortOption === ContentSortEnum.PUBLISHED_AT_ASC ? (
                <SortDescendingOutlined />
              ) : (
                <SortAscendingOutlined />
              )
            }
            style={{ borderRadius: 0 }}
          />
        </Dropdown>

        <Dropdown overlay={filterMenu} trigger={["click"]}>
          <Button
            type={filter === ContentFilterEnum.ALL ? "default" : "primary"}
            icon={<FilterOutlined />}
            style={{ borderRadius: 0 }}
          />
        </Dropdown>

        <Button
          icon={<CloseOutlined />}
          title={"Reset"}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={() => {
            onSearch("");
            onSortSelect(ContentSortEnum.CUSTOM);
            onFilterChange(ContentFilterEnum.ALL);
          }}
        />
      </div>

      <div style={{ marginTop: 12 }}>
        <CreateContentButtonConnected
          contentType={ContentTypesEnum.PLAYBOOK}
          parentContent={undefined}
        />
      </div>
    </div>
  );
};

export default ContentSidebarHeader;
