import React, { PropsWithChildren } from "react";
import { RouteProps } from "react-router";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import routePermissionChecker from "../../../core/utils/route-permission-checker";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Redirect, Route } from "react-router-dom";
import useActiveFeatureFlags, {
  FeatureFlag,
} from "../../../core/hooks/useFeatureFlags";

interface GuardedRouteProps extends RouteProps {
  featureFlag?: FeatureFlag | undefined;
}

const GuardedRoute = ({
  children,
  ...otherProps
}: PropsWithChildren<GuardedRouteProps>) => {
  const checkFeatureFlags = useActiveFeatureFlags();
  const hasFeatureEnabled =
    otherProps.featureFlag &&
    checkFeatureFlags.includes(otherProps.featureFlag);

  const currentUserRole: UserRoleEnum =
    useSelector(selectCurrentUserRole) ?? UserRoleEnum.ADMIN;

  const canAccess = routePermissionChecker(
    otherProps.path as RoutesEnum,
    currentUserRole,
  );

  if (!canAccess || (!hasFeatureEnabled && hasFeatureEnabled !== undefined)) {
    return <Redirect to={"/not-found"} />;
  }

  return <Route {...otherProps}>{children}</Route>;
};

export default GuardedRoute;
