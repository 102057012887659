import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resources from "../assets/translations/resources.translations";
import { DEBUG } from "./env.config";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ["nl", "en"],
    defaultNS: "translations",
    debug: DEBUG,
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: "v3",
  });
