import CreateBaseContentModel, {
  CreateBaseContentResponse,
} from "./create-base-content.model";

export interface CreateCollectionSummaryResponse {
  title: string;
  description: string;
  mediaUids: string[];
}

export interface CreateCollectionResponse extends CreateBaseContentResponse {
  chapterUid: string;
  startSummary: CreateCollectionSummaryResponse;
  endSummary: CreateCollectionSummaryResponse;
}

export default class CreateCollectionModel extends CreateBaseContentModel {
  chapterUid: string;
  startSummary: CreateCollectionSummaryResponse;
  endSummary: CreateCollectionSummaryResponse;

  constructor(collection: CreateCollectionResponse) {
    super(collection);
    this.chapterUid = collection.chapterUid;
    this.startSummary = collection.startSummary;
    this.endSummary = collection.endSummary;
  }
}
