import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Category } from "../api/primio/primioSchemas";
import { useUpdateCategory } from "../api/primio/primioComponents";

const key = "categories";

const useSortCategories = () => {
  const qc = useQueryClient();
  const { mutateAsync: updateCategory } = useUpdateCategory({});

  return useMutation({
    mutationFn: (categories: Category[]) => {
      const calls = categories.map((category, index) => {
        return updateCategory({
          pathParams: {
            categoryUid: category.categoryUid,
          },
          body: {
            sort: categories.length - index,
          },
        });
      });
      return Promise.all(calls);
    },
    onMutate: async (categories) => {
      await qc.cancelQueries([key]);
      const previousCategories: Category[] | undefined = await qc.getQueryData([
        key,
      ]);

      qc.setQueryData([key], categories);

      return { previousCategories };
    },
    onError: (error, variables, context) => {
      qc.setQueryData([key], context?.previousCategories);
    },
  });
};

export default useSortCategories;
