import getToken from "./getToken.utils";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import axios from "axios";
import { MediaTypesEnum } from "../enums/media-providers.enum";
import { API_URL } from "../../constants/config/env.config";
import { DisplayModeEnum } from "../enums/display-mode.enum";

interface Props {
  setProgress: (value: number) => void;
  options: RcCustomRequestOptions;
  mediaType: MediaTypesEnum;
  displayMode?: DisplayModeEnum;
}

interface FileInterface {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  uid: string;
  webkitRelativePath: string;
}

const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

const MediaUploadUtils = async ({
  setProgress,
  options,
  mediaType,
  displayMode = DisplayModeEnum.LANDSCAPE,
}: Props) => {
  const fmData = new FormData();
  const accessToken = await getToken();
  const uploadConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
    onUploadProgress: (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      setProgress(percent);
      if (percent === 100) {
        setTimeout(() => setProgress(0), 1000);
      }
      if (options.onProgress) {
        // @ts-ignore
        options.onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    },
  };

  const originalFile = options.file as unknown as FileInterface;
  const file = renameFile(
    options.file,
    `${new Date().getTime()}---${originalFile.name}`,
  );

  fmData.append("file", file);
  fmData.append("mediaType", mediaType);
  fmData.append("displayMode", displayMode);

  try {
    const res = await axios.post(
      `${API_URL}/media/upload?file`,
      fmData,
      uploadConfig,
    );

    if (options.onSuccess) {
      // @ts-ignore
      options.onSuccess(res, null);
    }

    return res;
  } catch (err) {
    if (options.onError) {
      // @ts-ignore
      options.onError({ err });
    }
    return err;
  }
};

export default MediaUploadUtils;
