import { useEffect, useState } from "react";
import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../enums/learning-path-state.enum";
import { useGetUserLearningPaths } from "../api/primio/primioComponents";
import LearningPathModel from "../models/learning-path/learning-path.model";
import { useSelector } from "react-redux";
import { selectLearningPaths } from "../redux/selectors/learning-path/learning-path.selector";
import UserModel from "../models/user/user.model";

export interface UserLearningPathReport {
  learningPathUid: string;
  state: LearningPathStateEnum;
  userGroups: string[];
  title: string;
  type?: LearningPathTypeEnum;
  hasProgress: boolean;
  hasAccess: boolean;
  startedAt?: string;
  completedAt?: string;
  sort: number;
}

interface UserLearningPathProgress {
  learningPathUid: string;
  state: LearningPathStateEnum;
  startedAt?: string;
  completedAt?: string;
}

export default function useUserLearningPathReport(user: UserModel) {
  const [learningPathProgresses, setLearningPathProgresses] = useState<
    UserLearningPathProgress[]
  >([]);
  const [userLearningPathReport, setUserLearningPathReport] = useState<
    UserLearningPathReport[]
  >([]);

  const { data: learningPathsProgress } = useGetUserLearningPaths({
    pathParams: { userUid: user.sub },
  });
  const learningPaths: LearningPathModel[] = useSelector(selectLearningPaths);

  useEffect(() => {
    if (learningPathProgresses.length === 0 || learningPaths.length === 0) {
      return;
    }

    const availableLearningPaths = learningPaths.filter((learningPath) => {
      if (
        learningPath.userGroups === undefined ||
        learningPath.userGroups.length === 0
      ) {
        return true;
      }

      return learningPath.userGroups?.some((u) => user.userGroups.includes(u));
    });

    const formatted = availableLearningPaths.map<UserLearningPathReport>(
      (learningPath) => {
        const userLearningPathProgress = learningPathProgresses.find(
          (p) => p.learningPathUid === learningPath.learningPathUid,
        );

        return {
          learningPathUid: learningPath.learningPathUid,
          state: learningPath.state,
          userGroups: learningPath.userGroups ?? [],
          title: learningPath.title,
          type: learningPath.learningPathType,
          hasAccess: true,
          hasProgress: !!userLearningPathProgress,
          startedAt: userLearningPathProgress?.startedAt,
          completedAt: userLearningPathProgress?.completedAt,
          sort: learningPath.sort ?? 0,
        };
      },
    );

    const unusedUserLearningPathProgresses = learningPathProgresses.filter(
      (p) => !formatted.find((f) => f.learningPathUid === p.learningPathUid),
    );
    if (unusedUserLearningPathProgresses.length > 0) {
      unusedUserLearningPathProgresses.forEach((p) => {
        const learningPath = learningPaths.find(
          (learningPath) => learningPath.learningPathUid === p.learningPathUid,
        );

        if (!learningPath) {
          return;
        }

        formatted.push({
          learningPathUid: p.learningPathUid,
          state: p.state,
          userGroups: learningPath.userGroups ?? [],
          title: learningPath.title,
          type: learningPath.learningPathType,
          hasProgress: true,
          startedAt: p.startedAt,
          completedAt: p.completedAt,
          hasAccess: false,
          sort: learningPath.sort ?? 0,
        });
      });
    }

    const sorted = formatted.sort((a, b) => a.sort - b.sort);

    setUserLearningPathReport(sorted);
  }, [user, learningPaths, learningPathProgresses]);

  useEffect(() => {
    if (!learningPathsProgress) {
      return;
    }

    const progress: UserLearningPathProgress[] = [];

    Object.entries(learningPathsProgress).forEach(
      ([learningPathUid, users]) => {
        const user = Object.values(users.users)[0] as any;

        progress.push({
          learningPathUid,
          state: user.state,
          startedAt: user.startedAt,
          completedAt: user.completed?.completedAt,
        });
      },
    );

    setLearningPathProgresses(progress);
  }, [learningPathsProgress]);

  return { userLearningPathReport };
}
