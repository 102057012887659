import JSZip from "jszip";

export default async function loadFilesFromZip(file: Blob) {
  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(file);
  const calls = Object.keys(zip.files).map((filename) =>
    zip.files[filename].async("string"),
  );
  const responses = await Promise.all(calls);
  return responses
    .map((string) => {
      try {
        return JSON.parse(string);
      } catch {
        return;
      }
    })
    .filter((obj) => obj !== undefined);
}
