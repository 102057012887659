import React, { useEffect } from "react";
import { CreatePictureQuizCardOptionInterface } from "../../../../core/models/create-content/card-types/create-picture-quiz-card.model";
import { useMedia } from "../../../../core/hooks/media/media.hook";
import { useTranslation } from "react-i18next";
import styles from "./picture-quiz.module.css";
import {
  DeleteOutlined,
  MoreOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  ImageProvidersEnum,
  MediaTypesEnum,
} from "../../../../core/enums/media-providers.enum";
import { Image, Popconfirm, Typography } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import Link from "antd/lib/typography/Link";
import DecodeMediaUri from "../../../../core/utils/decode-media-uri.utils";

const PictureQuizOption = ({
  option,
  setOption,
  onDelete,
}: {
  option: CreatePictureQuizCardOptionInterface;
  setOption: (option: CreatePictureQuizCardOptionInterface) => void;
  onDelete: (option: CreatePictureQuizCardOptionInterface) => void;
}) => {
  const { data: media, refetch } = useMedia(
    option ? option.mediaUid : undefined,
  );
  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, [option.mediaUid]);

  return (
    <div className={styles.option}>
      <div className={styles.handle}>
        <MoreOutlined />
        <MoreOutlined />
      </div>
      {media &&
        media.mediaType === MediaTypesEnum.IMAGE &&
        media.provider === ImageProvidersEnum.S3 && (
          <div
            style={{
              position: "relative",
              height: "130px",
              overflow: "hidden",
            }}
            className={styles.imageContainer}
          >
            <Image
              width={130}
              height={130}
              src={media.uri}
              preview={false}
              style={{ objectFit: "cover" }}
            />
            <div className={styles.deleteContainer}>
              <Popconfirm
                placement="top"
                title={t("translations:errors.warnings.check-delete", {
                  item: t("translations:common.answer-option"),
                })}
                onConfirm={() => onDelete(option)}
                okText={Capitalize(t("translations:common.delete"))}
                cancelText={Capitalize(t("translations:common.cancel"))}
              >
                <DeleteOutlined style={{ color: "red", fontSize: 18 }} />
              </Popconfirm>
            </div>
          </div>
        )}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignSelf: "stretch",
          padding: 10,
        }}
      >
        <Typography.Text style={{ fontWeight: "bold", marginBottom: 6 }}>
          {Capitalize(t("translations:common.answer"))} {option.sort + 1}
        </Typography.Text>
        {media && (
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 6 }}
          >
            <PaperClipOutlined />
            <Link href={media.uri} target={"_blank"}>
              {DecodeMediaUri(media.uri)}
            </Link>
          </div>
        )}

        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOption({
                ...option,
                isCorrect: !option.isCorrect,
              });
            }}
          >
            <label className={styles.switch}>
              <input checked={option.isCorrect} type="checkbox" disabled />
              <span>
                <div />
              </span>
            </label>
            <Typography.Text>
              {Capitalize(t("translations:form.card.answers.correct-answer"))}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PictureQuizOption;
