import React from "react";
import Moment from "moment";
import { DatePicker, Form, Switch } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";

interface OwnProps {
  dateTime: Moment.Moment | null;
  switchDateTime: boolean;
  onSelect: (value: Moment.Moment) => void;
  onChange: (value: boolean) => void;
}

type Props = OwnProps;

const DateTimePickerComponent = (props: Props) => {
  const [t] = useTranslation();
  const { dateTime, switchDateTime, onSelect, onChange } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <DatePicker
        value={dateTime}
        format={"YYYY-MM-DD HH:mm"}
        placeholder={Capitalize(
          t("form.placeholders.select_x", { x: t("common.date") }),
        )}
        style={{ flex: 1 }}
        disabledDate={disabledDate}
        onSelect={onSelect}
        showTime
      />
      <Form.Item
        name={"sendNow"}
        style={{ marginLeft: "3rem", marginBottom: "0" }}
        label={Capitalize(t("form.items.send.sent-now"))}
      >
        <Switch checked={switchDateTime} onChange={onChange} />
      </Form.Item>
    </div>
  );

  function disabledDate(current) {
    return current && current < Moment().startOf("day");
  }
};

export default DateTimePickerComponent;
