import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { ContentProgressEnum } from "../../../../core/enums/content-progress.enum";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Tooltip } from "antd";
import { selectContentACLs } from "../../../../core/redux/selectors/content/content.selector";
import { Link, useHistory } from "react-router-dom";
import { useUserProgress } from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import GradientProgressDonutComponent, {
  ContentProgressInterface,
} from "../../graphs/gradient-progress-donut.component";
import ContentUserProgressBoilerplate from "../content-user-progress.boilerplate";
import ContentProgressMeta from "../content-progress.meta";
import CollectionModel from "../../../../core/models/content/collection.model";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import Moment from "moment";
import { selectUserProgressFromUserGroupUsers } from "../../../../core/redux/selectors/user-progress/user-progress";

interface Props {
  playbookUid: string;
  collection: CollectionModel;
  size?: "small" | "middle" | "large";
  style?: CSSProperties;
}

const CollectionUserProgressComponent = (props: Props) => {
  const { t } = useTranslation();
  const { playbookUid, collection } = props;
  const history = useHistory();
  const userGroupAcl = useSelector((state) =>
    selectContentACLs(
      state,
      ContentTypesEnum.COLLECTION,
      collection.collectionUid,
    ),
  );

  if (!collection) {
    return null;
  }

  const { data: collectionProgress, ...queryInfo } = useUserProgress(
    playbookUid,
    collection.chapterUid,
    collection.collectionUid,
  );

  const userProgressFromUserGroupUsers =
    selectUserProgressFromUserGroupUsers(collectionProgress);

  return (
    <ContentUserProgressBoilerplate
      size={props.size}
      style={props.style}
      cardStyle={{
        display: "flex",
        flexFlow: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 2rem",
      }}
      title={collection.title}
      description={collection.description}
      progressBar={renderProgressBar()}
      suffix={renderSuffix()}
      suffixAction={renderSuffixAction()}
    />
  );

  function renderProgressBar() {
    if (!collectionProgress) {
      return;
    }

    const selectData =
      userProgressFromUserGroupUsers.length > 0
        ? userProgressFromUserGroupUsers
        : collectionProgress.users;

    const data: ContentProgressInterface[] = [
      {
        state: ContentProgressEnum.PENDING,
        amount: selectData.filter(
          (u) => u.state === ContentProgressEnum.PENDING,
        ).length,
      },
      {
        state: ContentProgressEnum.STARTED,
        amount: selectData.filter(
          (u) => u.state === ContentProgressEnum.STARTED,
        ).length,
      },
      {
        state: ContentProgressEnum.COMPLETED,
        amount: selectData.filter(
          (u) => u.state === ContentProgressEnum.COMPLETED,
        ).length,
      },
    ];

    return (
      <div style={{ paddingRight: "2rem", marginBottom: "-1rem" }}>
        <GradientProgressDonutComponent
          width={75}
          height={75}
          data={data}
          onClick={(state) => {
            if (!collection) {
              return;
            }

            const path = RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_COLLECTION;
            history.push(
              `${path
                .replace(":playbookUid", playbookUid)
                .replace(":chapterUid", collection.chapterUid)
                .replace(
                  ":collectionUid",
                  collection.collectionUid,
                )}?state=${state}`,
            );
          }}
        />
      </div>
    );
  }

  function renderSuffix() {
    if (!collection) {
      return;
    }

    const total = collectionProgress
      ? userProgressFromUserGroupUsers.length > 0
        ? userProgressFromUserGroupUsers.length
        : collectionProgress.users.length
      : 0;

    return (
      <>
        <ContentProgressMeta
          icon={"publish"}
          label={Moment(collection.publishedAt).format("DD-MM-YY")}
        />

        <ContentProgressMeta
          icon={"user"}
          label={
            (queryInfo.isFetched ? total : 0).toString() +
            " " +
            t("containers.users.key_plural")
          }
        />

        {userGroupAcl.length > 0 && (
          <Tooltip
            title={
              Capitalize(t("common.assigned_to")) +
              ":" +
              userGroupAcl.map((g) => " " + g)
            }
          >
            <div>
              <ContentProgressMeta
                label={t("common.assigned")}
                icon={"assigned"}
              />
            </div>
          </Tooltip>
        )}
      </>
    );
  }

  function renderSuffixAction() {
    if (!collection) {
      return;
    }

    const pathname = `/activity/published-content/playbook/${playbookUid}/chapter/${collection.chapterUid}/collection/${collection.collectionUid}`;
    return (
      <Link style={{ fontSize: "1.3rem" }} to={{ pathname }}>
        {Capitalize(t("common.show") + " " + t("common.details"))}
      </Link>
    );
  }
};

export default CollectionUserProgressComponent;
