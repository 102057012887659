import { Dispatch } from "redux";
import { ThunkType } from "../../../types/redux.type";
import { StoreInterface } from "../root.reducer";
import { CreateInvitationInterface } from "../../../interfaces/create-invitation.interface";
import { validate as validateEmail } from "email-validator";
import HttpErrorResponseModel from "../../../models/http-error-response.model";
import ActionUtility from "../../../utils/action.utils";
import InvitationModel from "../../../models/invitation/invitation.model";
import InvitationEffect from "./invitation.effect";

export default class InvitationAction {
  // Get Invitations
  static REQUEST_INVITATIONS = "InvitationAction.REQUEST_INVITATIONS";
  static REQUEST_INVITATIONS_FINISHED =
    "InvitationAction.REQUEST_INVITATIONS_FINISHED";

  // Get one Invitation
  static REQUEST_INVITATION = "InvitationAction.REQUEST_INVITATION";
  static REQUEST_INVITATION_FINISHED =
    "InvitationAction.REQUEST_INVITATION_FINISHED";

  // Create one Invitation
  static REQUEST_CREATE_INVITATION =
    "InvitationAction.REQUEST_CREATE_INVITATION";
  static REQUEST_CREATE_INVITATION_FINISHED =
    "InvitationAction.REQUEST_CREATE_INVITATION_FINISHED";

  // Edit one Invitation
  static REQUEST_EDIT_INVITATION = "InvitationAction.REQUEST_EDIT_INVITATION";
  static REQUEST_EDIT_INVITATION_FINISHED =
    "InvitationAction.REQUEST_EDIT_INVITATION_FINISHED";

  // Edit one Invitation
  static REQUEST_MARK_INVITATIONS_AS_SENT =
    "InvitationAction.REQUEST_MARK_INVITATIONS_AS_SENT";

  // Delete one Invitation
  static REQUEST_DELETE_INVITATION =
    "InvitationAction.REQUEST_DELETE_INVITATION";
  static REQUEST_DELETE_INVITATION_FINISHED =
    "InvitationAction.REQUEST_DELETE_INVITATION_FINISHED";

  /**
   * Get all Invitations
   * @returns Dispatch InvitationEffect.getAllInvitations
   */
  static getAllInvitations(): ThunkType<InvitationModel[]> {
    return ActionUtility.createThunk<InvitationModel[]>(
      InvitationAction.REQUEST_INVITATIONS,
      InvitationEffect.getAllInvitations,
    );
  }

  /**
   * Get one Invitation
   * @param invitationCode string
   * @returns Dispatch InvitationEffect.getInvitation
   */
  static getInvitation(invitationCode: string): ThunkType<InvitationModel> {
    return ActionUtility.createThunk<InvitationModel>(
      InvitationAction.REQUEST_INVITATION,
      InvitationEffect.getInvitation,
      invitationCode,
    );
  }

  /**
   * Create multiple Invitations
   * @param data CreateInvitationInterface[]
   * @param sendAt boolean
   * @returns Dispatch InvitationEffect.createInvitation
   */
  static createInvitations(
    data: CreateInvitationInterface[],
    sendAt?: boolean,
  ) {
    return async (dispatch: Dispatch<any>, getState: () => StoreInterface) => {
      const userEmails: string[] = getState().user.users.map((u) => u.email);
      const userGroups: string[] = getState().group.groups.map((g) => g.title);

      const validateInvitationData =
        InvitationAction.validateCreateInvitationData(
          data,
          userEmails,
          userGroups,
        );

      validateInvitationData
        .then(async (res) => {
          await dispatch(
            ActionUtility.createThunk<InvitationModel>(
              InvitationAction.REQUEST_CREATE_INVITATION,
              InvitationEffect.createInvitations,
              res,
            ),
          );

          await dispatch(InvitationAction.getAllInvitations());

          if (sendAt) {
            await dispatch(InvitationAction.sendInvitationAfterCreate(res));
          }
        })
        .catch((err: HttpErrorResponseModel) => {
          // Create custom error and catch in getErrorMessageInvitation hook
          dispatch({
            type: InvitationAction.REQUEST_CREATE_INVITATION_FINISHED,
            payload: err,
            error: true,
            meta: null,
          });
        });
    };
  }

  /**
   * Edit one Invitation
   * @param invitation InvitationModel
   * @param data Partial<CreateInvitationInterface>
   * @param sendAt boolean
   * @returns Dispatch InvitationEffect.editInvitation
   */
  static editInvitation(
    invitation: InvitationModel,
    data: Partial<CreateInvitationInterface>,
    sendAt?: boolean,
  ) {
    return async (dispatch: Dispatch<any>) => {
      await dispatch(
        ActionUtility.createThunk<InvitationModel>(
          InvitationAction.REQUEST_EDIT_INVITATION,
          InvitationEffect.editInvitation,
          invitation,
          data,
        ),
      );

      if (sendAt) {
        await dispatch(InvitationAction.markInvitationsAsSent([invitation]));
      } else {
        await dispatch(
          InvitationAction.getInvitation(invitation.invitationCode),
        );
      }
    };
  }

  /**
   * Mark array of invitations as sent
   * @param invitations InvitationModel[]
   * @returns Dispatch InvitationEffect.markInvitationsAsSent
   */
  static markInvitationsAsSent(invitations: InvitationModel[]) {
    return async (dispatch: Dispatch<any>) => {
      const data = {
        invitationUids: invitations.map((invitation) => {
          return invitation.invitationUid;
        }),
      };

      await dispatch(
        ActionUtility.createThunk<InvitationModel[]>(
          InvitationAction.REQUEST_MARK_INVITATIONS_AS_SENT,
          InvitationEffect.markInvitationsAsSent,
          data,
        ),
      );

      await dispatch(InvitationAction.getAllInvitations());
    };
  }

  /**
   * Delete one Invitation
   * @param invitation InvitationModel
   * @returns Dispatch InvitationEffect.deleteInvitation
   */
  static deleteInvitation(
    invitation: InvitationModel,
  ): ThunkType<InvitationModel> {
    return ActionUtility.createThunk<InvitationModel>(
      InvitationAction.REQUEST_DELETE_INVITATION,
      InvitationEffect.deleteInvitation,
      invitation,
    );
  }

  /**
   * Send one Invitation after creation
   * @param res CreateInvitationInterface[]
   * @returns -
   */
  private static sendInvitationAfterCreate(res: CreateInvitationInterface[]) {
    return (dispatch: Dispatch<any>, getState: () => StoreInterface) => {
      const invitations: InvitationModel[] = [];

      res.map((r) => {
        const invite = getState().invitation.invitations.find(
          (i) => i.email === r.email,
        );
        if (invite) invitations.push(invite);
      });

      if (invitations.length > 0) {
        dispatch(InvitationAction.markInvitationsAsSent(invitations));
      }
    };
  }

  /**
   * Validate everything inside CreateInvitationInterface[]
   * @param data CreateInvitationInterface[]
   * @param userEmails string[]
   * @param userGroups string[]
   * @returns Promise<CreateInvitationInterface[]>
   */
  private static validateCreateInvitationData(
    data: CreateInvitationInterface[],
    userEmails: string[],
    userGroups: string[],
  ): Promise<CreateInvitationInterface[]> {
    return new Promise<CreateInvitationInterface[]>((resolve, reject) => {
      const res: CreateInvitationInterface[] = [];

      data.forEach((i, index, array) => {
        // 411 - Length Required: Is data available?
        if (
          !i.name ||
          i.name.length === 0 ||
          !i.email ||
          i.email.length === 0
        ) {
          reject({ status: 411 });
        }

        // 412 - Precondition Failed: Is email valid?
        if (!validateEmail(i.email)) {
          reject({ status: 412 });
        }

        // 409 - Conflict: Does user already exist in Cognito (based on email)?
        if (userEmails.includes(i.email)) {
          reject({ status: 409, message: "email" });
        }

        // Only use the userGroups that already exist in Cognito?
        i.userGroups = i.userGroups.filter((g) =>
          userGroups.some((groupTitle) => g === groupTitle),
        );

        res.push(i);

        if (index === array.length - 1) {
          resolve(res);
        }
      });
    });
  }
}
