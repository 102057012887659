import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NotificationTypes from "../../../core/types/notification.types";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { DatePicker } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { useTranslation } from "react-i18next";
import { RoutesNotificationsEnum } from "../../../constants/routes/notifications.route";
import { NotificationStatusEnum } from "../../../core/enums/notification-status.enum";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Capitalize } from "../../../core/utils/helper.utils";
import { selectNotifications } from "../../../core/redux/selectors/notification/notification.selector";
import { getFirstDayOfCurrentWeek } from "../../../core/utils/get-first-day-of-current-week.utils";
import useQuery from "../../../core/hooks/useQuery";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import NotificationsOverviewScreen from "../../screens/notifications/notifications-overview.screen";
import NotificationAction from "../../../core/redux/stores/notification/notification.action";
import Moment from "moment";
import ContentAction from "../../../core/redux/stores/content/content.action";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";

interface StateProps {
  notifications: NotificationTypes[];
  requesting: boolean;
  error: HttpErrorResponseModel;
}

type Props = StateProps;

const NotificationsContainer = (props: Props) => {
  const { t } = useTranslation();
  const { notifications, requesting } = props;
  const query = useQuery();
  const notificationsCount = useSelector(
    (state: StoreInterface) => state.notification.notificationsCount,
  );
  const [notificationStateFilter, setNotificationStateFilter] = useState<
    NotificationStatusEnum | undefined
  >(NotificationStatusEnum.SCHEDULED);
  useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    getFirstDayOfCurrentWeek(new Date()),
  );
  const dispatch = useDispatch();
  const { openModal } = useModal();

  useEffect(() => {
    dispatch(ContentAction.getAllContent());
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(NotificationAction.getScheduledNotifications(startDate));
  }, [startDate]);

  useEffect(() => {
    setStartDate(getFirstDayOfCurrentWeek(new Date()));
  }, [notificationStateFilter]);

  useEffect(() => {
    if (!requesting && loading) {
      setLoading(false);
    }
  }, [requesting]);

  const state: NotificationStatusEnum | null = query.get(
    "state",
  ) as NotificationStatusEnum | null;
  if (state) {
    if (state !== notificationStateFilter) {
      setNotificationStateFilter(state);
    }
  } else {
    if (notificationStateFilter !== undefined) {
      setNotificationStateFilter(undefined);
    }
  }

  const menuItems = () => {
    const notificationCount =
      notifications.length > 0 ? notificationsCount["total"] : 0;
    const menuItems: MenuItemInterface[] = [
      {
        title: `${t(
          "containers.notifications.sidebar",
        )} (${notificationCount})`,
        route: RoutesEnum.NOTIFICATIONS,
      },
    ];

    for (const key in NotificationStatusEnum) {
      let count;
      switch (key) {
        case NotificationStatusEnum.SCHEDULED:
          count =
            notifications.length > 0 ? notificationsCount["scheduled"] : 0;
          break;
        case NotificationStatusEnum.SENT:
          count =
            notifications.length > 0 ? notificationsCount["completed"] : 0;
          break;
      }

      menuItems.push({
        title: `${Capitalize(
          t(`screens.notifications.state.${key}`),
        )} (${count})`,
        route: RoutesNotificationsEnum[key],
      });
    }

    return menuItems;
  };

  const filteredNotifications = (): NotificationTypes[] => {
    if (notifications.length === 0) {
      return [];
    }

    if (!notificationStateFilter) {
      return notifications;
    }

    switch (notificationStateFilter) {
      case NotificationStatusEnum.SCHEDULED:
        return notifications.filter((n) => !n.sentAt);
      case NotificationStatusEnum.SENT:
        return notifications.filter((n) => n.sentAt);
    }
  };

  return (
    <PageLayoutComponent
      menuItems={menuItems()}
      title={"notifications"}
      onPress={() => openModal(ModalNames.CREATE_NOTIFICATION)}
    >
      {renderDatePicker()}

      <NotificationsOverviewScreen
        loading={loading}
        notifications={
          notifications.length === 0 || filteredNotifications().length === 0
            ? []
            : filteredNotifications()
        }
      />
    </PageLayoutComponent>
  );

  function disabledDate(current) {
    switch (notificationStateFilter) {
      case NotificationStatusEnum.SCHEDULED:
        return current && current < Moment().endOf("day");
      case NotificationStatusEnum.SENT:
        return current && current > Moment().endOf("day");
    }
  }

  function renderDatePicker() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "2rem",
        }}
      >
        <DatePicker
          format={"[Week] ww: DD MMM. YYYY"}
          value={Moment(startDate)}
          disabledDate={disabledDate}
          picker={"week"}
          bordered={true}
          onChange={(value: Moment.MomentInput | null) => {
            if (value) {
              const newDate = Moment(value).startOf("week").toDate();
              setStartDate(newDate);
            }
          }}
        />
      </div>
    );
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  notifications: selectNotifications(state),
  requesting: selectRequesting(state, [
    NotificationAction.REQUEST_FETCH_NOTIFICATIONS,
  ]),
  error: selectRawErrors(state, [
    NotificationAction.REQUEST_FETCH_NOTIFICATIONS_FINISHED,
  ]),
});

export default connect(mapStateToProps)(NotificationsContainer);
