import { getLearningPathContentForContentType } from "../../../core/hooks/learning-path/learning-path.api";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import { Modal } from "antd";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import colors from "../../styles/colors";
import ContentLearningPathRelationErrorMessage from "../content-context-actions/modal/content-learning-path-relation-error-message";
import React from "react";
import i18n from "i18next";
import { LearningPathStateEnum } from "../../../core/enums/learning-path-state.enum";

export default function PlaybookLearningPathContext(
  userGroup,
  playbook,
  updatedLearningPaths,
) {
  const checkPlaybookContext = async () => {
    const response = userGroup
      ? await getLearningPathContentForContentType({
          playbookUid: playbook.playbookUid,
          contentType: ContentTypesEnum.PLAYBOOK,
        })
      : null;

    const filterLearningPathByUserGroup =
      userGroup &&
      updatedLearningPaths.filter((path) =>
        path.userGroups.includes(userGroup.title),
      );
    const getAssociatedLearningPath =
      userGroup &&
      filterLearningPathByUserGroup.filter((item) => {
        // Check if the targetContentUid exists in any content object inside the current item
        return item.content.some(
          (contentItem) => contentItem.contentUid === playbook.playbookUid,
        );
      });

    const isInPublishedLearningPath =
      playbook.learningPaths &&
      playbook.learningPaths.filter(
        (path) => path.state === LearningPathStateEnum.POSTED,
      );
    const isContentPublic =
      playbook.learningPaths &&
      playbook.learningPaths.filter((path) => path.userGroups.length === 0);

    const isResponseValid = response && response.length > 0;

    if (
      (isInPublishedLearningPath &&
        isContentPublic.length > 0 &&
        isResponseValid) ||
      (userGroup && getAssociatedLearningPath.length > 0 && isResponseValid)
    ) {
      Modal.error({
        title: i18n.t("screens.modal.learning-path-relation.subtitle"),
        icon: (
          <ContextActionIconComponent
            action={"delete"}
            style={{ color: colors.secondary }}
          />
        ),
        content: (
          <ContentLearningPathRelationErrorMessage
            userGroup={false}
            relations={response}
          />
        ),
      });
      return true;
    }
    return false;
  };
  return { checkPlaybookContext };
}
