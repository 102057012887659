import LearningPathContentModel, {
  LearningPathContentResponse,
} from "./learning-path-content.model";
import { LearningPathTypeEnum } from "../../enums/learning-path-state.enum";

export default class ProgressBasedContentModel extends LearningPathContentModel {
  learningPathType?: LearningPathTypeEnum.PROGRESS_BASED;

  constructor(response: LearningPathContentResponse) {
    super(response);
    this.learningPathType = LearningPathTypeEnum.PROGRESS_BASED;
  }
}
