import { ContentStatesEnum } from "../../enums/content-states.enum";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";
import MediaModel, { MediaResponse } from "../media/media.model";
import SummaryTypesEnum from "../../enums/summary-types.enum";

export interface CollectionSummaryResponse {
  title: string;
  description: string;
  media?: MediaResponse[];
}

export default class CollectionSummaryModel {
  title: string;
  description: string;
  collectionUid: string;
  summaryType: SummaryTypesEnum;
  contentState: ContentStatesEnum;
  media?: MediaModel[];
  getId: string;

  constructor(
    summary: CollectionSummaryResponse,
    collectionUid: string,
    summaryType: SummaryTypesEnum,
    contentState: ContentStatesEnum,
  ) {
    this.title = summary.title;
    this.description = summary.description;
    this.collectionUid = collectionUid;
    this.summaryType = summaryType;
    this.contentState = contentState;

    if (summary.media) {
      this.media = MapResponseToModel(summary.media, MediaModel);
    }

    this.getId = `${this.summaryType}-summary_${this.collectionUid}`;
  }
}
