import React, { PropsWithChildren } from "react";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import PageHeaderComponent from "../page-header/page-header.component";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";

interface Props {
  menuItems: MenuItemInterface[];
  showSider?: boolean;
  //
  title?: string;
  onPress?: () => void;
  secondaryButton?: JSX.Element;
  onSearch?: (searchTerm: string) => void;
  breadCrumbs?: JSX.Element;
  selectEnabled?: JSX.Element;
  customTitle?: string;
  singular?: boolean;
}

const PageLayoutComponent = (props: PropsWithChildren<Props>) => {
  const {
    menuItems,
    title,
    onPress,
    secondaryButton,
    onSearch,
    showSider,
    breadCrumbs,
    children,
    selectEnabled,
    customTitle,
    singular,
  } = props;

  const getSelectedKeys = (): string[] => {
    let key = "";

    menuItems.forEach((menuItem, index) => {
      if (location.href.includes(menuItem.route)) {
        key = index.toString();
      }
    });

    return [key];
  };

  return (
    <>
      {showSider && (
        <Layout.Sider theme={"light"} width={200} className={"Sider"}>
          <Menu selectedKeys={getSelectedKeys()}>
            {menuItems.map((menuItem, index) => {
              return (
                <Menu.Item key={index} icon={menuItem.icon}>
                  <NavLink to={menuItem.route}>{menuItem.title}</NavLink>
                </Menu.Item>
              );
            })}
          </Menu>
        </Layout.Sider>
      )}
      <Layout.Content
        className={
          showSider ? "Content_wrapper" : "Content_wrapper_without_sider"
        }
      >
        <PageHeaderComponent
          title={title}
          customTitle={customTitle}
          onPress={onPress}
          secondaryButton={secondaryButton}
          onSearch={onSearch}
          breadCrumbs={breadCrumbs}
          selectEnabled={selectEnabled}
          singular={singular}
        />
        <Layout.Content className={"Content"}>{children}</Layout.Content>
      </Layout.Content>
    </>
  );
};

PageLayoutComponent.defaultProps = {
  showSider: true,
};

export default PageLayoutComponent;
