import CognitoService from "../services/cognito.service";

export default function getToken() {
  return new Promise<string>((resolve) => {
    const cognitoService = new CognitoService();
    cognitoService.getSession((error, session) => {
      //TODO handle error
      resolve(session.getAccessToken().getJwtToken());
    });
  });
}
