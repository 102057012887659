import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import Picker from "emoji-picker-react";

interface OwnProps {
  text: string;
  setText: (value: string) => void;
}

type Props = OwnProps;

const EmojiPickerField = ({ text, setText }: Props) => {
  const [emojiVisible, setEmojiVisible] = useState<boolean>(false);
  return (
    <>
      <Tooltip title={"Select Emoji"}>
        <Button
          onClick={() => setEmojiVisible(!emojiVisible)}
          icon={<SmileOutlined />}
          type={"text"}
          ghost
        />
      </Tooltip>

      {emojiVisible && (
        <div style={{ position: "absolute", zIndex: 100 }}>
          <Picker
            onEmojiClick={(data) => {
              // @ts-ignore
              setText(text + data.emoji);
              setEmojiVisible(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default EmojiPickerField;
