import React, { useEffect, useState } from "react";
import { StoreInterface } from "../../core/redux/stores/root.reducer";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChapterViaUid,
  selectCollectionViaUid,
  selectContentTrees,
  selectPlaybookViaUid,
} from "../../core/redux/selectors/content/content.selector";
import { RoutesEnum } from "../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContentSidebar } from "../../core/providers/content-sidebar.provider";
import ContentSidebarComponent from "../components/content-sidebar/content-sidebar.component";
import ContentComponent from "../components/content/content.component";
import ContentTypesEnum from "../../core/enums/content-types.enum";
import BulkPublishModal from "../components/modals/bulk-publish.modal";
import Emitter from "../../core/utils/emitter.utils";
import ContentType from "../../core/types/content.type";
import EditPageHeaderComponent from "../components/page-header/edit-page-header.component";
import CollectionModel from "../../core/models/content/collection.model";
import ChapterModel from "../../core/models/content/chapter.model";
import PlaybookModel from "../../core/models/content/playbook.model";
import EditPageUrlParamHandler from "../components/edit-page-url-param-handler/edit-page-url-param-handler";
import DuplicateContentModal from "../components/content-context-actions/modal/duplicate-content.modal";
import CardTypes from "../../core/types/card.types";
import ContentAction from "../../core/redux/stores/content/content.action";
import { extractUrlKeyValue } from "../../core/utils/extractUrlKeyValue";

const EditModeContainer = () => {
  const [t] = useTranslation();
  const {
    reset,
    selectedContent,
    selectedCollection: selectedCollectionFromSidebar,
  } = useContentSidebar();
  const [bulkPublishContent, setBulkPublishContent] = useState<ContentType>();
  const [duplicateContent, setDuplicateContent] = useState<
    ChapterModel | CollectionModel | CardTypes
  >();

  const history = useHistory();
  const playbookDetails = useSelector((state: StoreInterface) =>
    selectContentTrees(state).filter((p) => p.apiKeyClientUid === undefined),
  );
  const playbookUid =
    selectedContent?.contentType === ContentTypesEnum.PLAYBOOK
      ? selectedContent.playbookUid
      : undefined;
  const selectedPlaybook: PlaybookModel | undefined = useSelector(
    (state: StoreInterface) => selectPlaybookViaUid(state, playbookUid),
  );

  const chapterUid =
    selectedContent?.contentType === ContentTypesEnum.CHAPTER
      ? selectedContent.chapterUid
      : undefined;
  const selectedChapter: ChapterModel | undefined = useSelector(
    (state: StoreInterface) => selectChapterViaUid(state, chapterUid),
  );

  const collectionUid =
    selectedContent?.contentType === ContentTypesEnum.COLLECTION
      ? selectedContent.collectionUid
      : undefined;
  const selectedCollection: CollectionModel | undefined = useSelector(
    (state: StoreInterface) => selectCollectionViaUid(state, collectionUid),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ContentAction.getContent(ContentTypesEnum.PLAYBOOK));

    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    Emitter.on("handleBulkPublishContent", (content: ContentType) =>
      setBulkPublishContent(content),
    );
    Emitter.on(
      "handleDuplicateContent",
      (content: ChapterModel | CollectionModel | CardTypes) =>
        setDuplicateContent(content),
    );
  }, []);

  function getContent(): ContentType | undefined {
    if (!selectedContent) {
      return;
    }

    switch (selectedContent.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return selectedPlaybook;
      case ContentTypesEnum.CHAPTER:
        return selectedChapter;
      case ContentTypesEnum.COLLECTION:
        return selectedCollection;
      case ContentTypesEnum.CARD:
        return selectedCollectionFromSidebar;
    }
  }

  const content = getContent();

  return (
    <>
      <EditPageContentDetailFetch />
      <EditPageUrlParamHandler />
      <EditPageHeaderComponent
        title={t("containers.modules.title")}
        onClick={() => history.push(RoutesEnum.PLAYBOOKS)}
      />

      <Row style={{ overflow: "hidden" }}>
        <Col style={{ height: "93vh" }}>
          <ContentSidebarComponent playbookDetails={playbookDetails} />
        </Col>
        <Col
          style={{
            flex: "1 1",
            padding: "2rem",
            height: "93vh",
            overflow: "scroll",
          }}
        >
          {content && <ContentComponent content={content} />}
        </Col>
      </Row>

      <BulkPublishModal
        visible={!!bulkPublishContent}
        content={bulkPublishContent}
        onCancel={() => setBulkPublishContent(undefined)}
      />

      <DuplicateContentModal
        visible={!!duplicateContent}
        content={duplicateContent}
        onCancel={() => setDuplicateContent(undefined)}
      />
    </>
  );
};

const EditPageContentDetailFetch = () => {
  const { selectedContent } = useContentSidebar();
  const [playbookUid, setPlaybookUid] = useState<string>();
  const [collectionUid, setCollectionUid] = useState<string>();

  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = extractUrlKeyValue(window.location.pathname);

    if (urlParams.playbook) {
      setPlaybookUid(urlParams.playbook);
      return;
    }

    if (urlParams.playbook && urlParams.chapter && urlParams.collection) {
      setCollectionUid(urlParams.collection);
      return;
    }
  }, []);

  useEffect(() => {
    if (!selectedContent) return;

    switch (selectedContent.contentType) {
      case ContentTypesEnum.PLAYBOOK:
      case ContentTypesEnum.CHAPTER:
        if (selectedContent.playbookUid === playbookUid) return;
        setPlaybookUid(selectedContent.playbookUid);
        setCollectionUid(undefined);
        break;
      case ContentTypesEnum.COLLECTION:
      case ContentTypesEnum.CARD:
        if (selectedContent.collectionUid === collectionUid) return;
        setCollectionUid(selectedContent.collectionUid);
        break;
    }
  }, [selectedContent]);

  useEffect(() => {
    if (!playbookUid) return;
    dispatch(
      ContentAction.getContentDetail(ContentTypesEnum.PLAYBOOK, playbookUid),
    );
  }, [playbookUid]);

  useEffect(() => {
    if (!collectionUid) return;
    dispatch(
      ContentAction.getContentDetail(
        ContentTypesEnum.COLLECTION,
        collectionUid,
      ),
    );
  }, [collectionUid]);
  return null;
};

export default EditModeContainer;
