import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useForm } from "antd/lib/form/Form";
import { useAuth } from "../../../core/providers/auth.provider";
import { Button, Form, Input, Tooltip, Typography } from "antd";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Logo from "../../components/logo/logo.component";
import styles from "../../components/auth/login.module.css";

const ForgetPasswordScreen = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [email, setEmail] = useState<string>("");
  const { forgetPassword, loading } = useAuth();

  const onFinish = () => {
    const _email = email.trim();

    forgetPassword(_email, {
      onFailure: (err) => {
        switch (err.code) {
          case "InvalidEmailException":
            // Not a valid email address.
            form.setFields([
              {
                name: "email",
                errors: [
                  t("errors.validation.invalid", {
                    item: t("form.items.email.label"),
                  }),
                ],
              },
            ]);
            break;
          default:
            form.setFields([
              {
                name: "email",
                errors: [err.message],
              },
            ]);
            break;
        }
      },
    });
  };

  return (
    <Space size={"middle"} direction={"vertical"} style={{ width: "100%" }}>
      <Logo className={styles.login_logo} width={218} height={66} />

      <Form form={form} onFinish={onFinish}>
        <Form.Item>
          <h2 className={styles.forget_title}>
            {Capitalize(t("screens.auth.forget-password.title"))}
          </h2>
          <Typography.Text>
            {t("screens.auth.forget-password.subtitle")}
          </Typography.Text>
        </Form.Item>

        <Form.Item name={"email"} rules={getRules("email")} hasFeedback={true}>
          <Input
            value={email}
            type={"text"}
            prefix={<UserOutlined />}
            suffix={
              <Tooltip title={t("form.items.email.tooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            }
            placeholder={Capitalize(t("form.items.email.label"))}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            className={"Button"}
            type={"primary"}
            htmlType={"submit"}
            loading={loading}
          >
            {Capitalize(t("common.send"))}
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );

  function getRules(field: string) {
    return [
      {
        required: true,
        message: Capitalize(
          t("errors.required", { item: t(`form.items.${field}.label`) }),
        ),
      },
    ];
  }
};

export default ForgetPasswordScreen;
