import { API_URL } from "../../constants/config/env.config";

const FavouriteEndpoints = {
  /**
   * GET list of cards from a collection where users has bookmarked cards endpoint.
   * @returns API_URL + /favourite-cards/collections/a3ef9d13-e0de-48aa-9429-128b9c7ae0b6
   */
  favouriteCards(collectionUid: string) {
    return `${API_URL}/favourite-cards/collections/${collectionUid}`;
  },
};

export default FavouriteEndpoints;
