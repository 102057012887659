import React, { useState } from "react";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import { useApiKeys } from "../../../core/hooks/api-key/api-key.hook";
import SharedContentOverviewScreen from "../../screens/shared-content/shared-content-overview.screen";
import CreateApiKeyModal from "../../components/modals/create-api-key.modal";

const SharedContentContainer = () => {
  const { data } = useApiKeys();
  const [isCreateApiKeyModalVisible, setIsCreateApiKeyModalVisible] =
    useState<boolean>(false);

  const menuItems = () => {
    const menuItems: MenuItemInterface[] = [];
    return menuItems;
  };

  return (
    <PageLayoutComponent
      showSider={false}
      menuItems={menuItems()}
      title={"shared-content"}
      onPress={onShowCreateApiKeyModal}
    >
      {data && <SharedContentOverviewScreen apiKeys={data} />}

      <CreateApiKeyModal
        visible={isCreateApiKeyModalVisible}
        onCancel={onHideCreateApiKeyModal}
      />
    </PageLayoutComponent>
  );

  function onShowCreateApiKeyModal() {
    setIsCreateApiKeyModalVisible(true);
  }

  function onHideCreateApiKeyModal() {
    setIsCreateApiKeyModalVisible(false);
  }
};

export default SharedContentContainer;
