import ContentEndpoints from "./content.endpoints";
import ContentTypesEnum from "../../../enums/content-types.enum";

export default class CardEndpoints extends ContentEndpoints {
  contentType: ContentTypesEnum.CARD;

  constructor() {
    super(ContentTypesEnum.CARD);
    this.contentType = ContentTypesEnum.CARD;
  }

  /**
   * Used for updating a single content resources and keep progress
   * @param id string
   * @returns API_URL + /cards/1b21d7d3-6788-4383-aa6e-0a9163e462c3/overwrite
   */
  updateContentKeepProgress(id: string) {
    return `${this.getContentPath()}/${id}/overwrite`;
  }
}
