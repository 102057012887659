import ThunkInterface from "../../../interfaces/thunk.interface";
import BaseReducer from "../base/base.reducer";
import SourceAction from "./source.action";
import SourceModel from "../../../models/source/source.model";

export interface SourceReducerInterface {
  sources: SourceModel[];
}

export default class SourceReducer extends BaseReducer<SourceReducerInterface> {
  initialState: SourceReducerInterface = {
    sources: [],
  };

  [SourceAction.REQUEST_SOURCES_FINISHED](
    state: SourceReducerInterface,
    action: ThunkInterface<SourceModel[]>,
  ) {
    return {
      ...state,
      sources: action.payload,
    };
  }

  [SourceAction.REQUEST_SOURCE_FINISHED](
    state: SourceReducerInterface,
    action: ThunkInterface<SourceModel>,
  ) {
    return {
      ...state,
      sources: this.addSingleToArray<SourceModel>(
        action.payload,
        [...(state.sources as SourceModel[])],
        "sourceUid",
      ),
    };
  }
}
