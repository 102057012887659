import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../utils/helper.utils";
import HttpErrorResponseModel from "../models/http-error-response.model";

export default function getErrorMessageInvitation(
  error: HttpErrorResponseModel,
) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (Object.keys(error).length === 0 && error.status === 200) {
      setErrorMessage(undefined);
    }

    switch (error.status) {
      case 409:
        if (error.message === "email") {
          setErrorMessage(
            Capitalize(
              t("errors.warnings.already-exists_x", {
                item: t("containers.users.key"),
              }),
            ),
          );
        } else {
          setErrorMessage(
            Capitalize(
              t("errors.warnings.already-exists_x", {
                item: t("screens.invitation.code"),
              }),
            ),
          );
        }
        break;
      case 411:
        setErrorMessage(Capitalize(t("errors.upload.invalid-data")));
        break;
      case 412:
        setErrorMessage(
          Capitalize(
            t("errors.validation.invalid", {
              item: t("form.items.email.label"),
            }),
          ),
        );
        break;
    }
  }, [error]);

  return { errorMessage };
}
