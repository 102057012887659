import React from "react";
import { Form, Typography } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import CardBasePreviewComponent from "../base-card/card-base-preview.component";
import MediaPreviewComponent from "../../media-types/media-preview.component";
import CollectionSummaryModel from "../../../../core/models/content/collection-summary.model";
import styles from "../card-types.module.css";

interface OwnProps {
  summaryCard: CollectionSummaryModel;
}

type Props = OwnProps;

const SummaryCardPreviewComponent = (props: Props) => {
  const [t] = useTranslation();
  const { summaryCard } = props;

  return (
    <Form
      labelCol={{ span: 6 }}
      layout={"horizontal"}
      labelAlign={"left"}
      className={styles.form_wrapper_horizontal}
    >
      <CardBasePreviewComponent cardContent={summaryCard} />

      <div className={"Form_item_card"}>
        {summaryCard.media && summaryCard.media[0] && (
          <Form.Item
            label={
              <Typography.Text strong>
                {Capitalize(t(`form.card.media.label`))}
              </Typography.Text>
            }
          >
            <MediaPreviewComponent media={summaryCard.media[0]} />
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

export default SummaryCardPreviewComponent;
