import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AppProviders from "./core/providers/app.provider";
import "./constants/config/i18n.config";
import "./constants/config/sentry.config";
import { Toaster } from "react-hot-toast";
import { CLIENT_NAME } from "./constants/config/env.config";
import CommandBar from "./views/components/command-bar/command-bar";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

// Include client name in the document title
document.title = "Primio CMS | " + CLIENT_NAME;

root.render(
  <AppProviders>
    <>
      <Toaster />
      <App />
      <CommandBar />
    </>
  </AppProviders>,
);
