export interface ApiKeySubscriberResponse {
  server: string;
  enabled: boolean;
  createdAt: string;
}

export default class ApiKeySubscriberModel {
  server: string;
  enabled: boolean;
  createdAt: Date;

  constructor(response: ApiKeySubscriberResponse) {
    this.server = response.server;
    this.enabled = response.enabled;
    this.createdAt = new Date(response.createdAt);
  }
}
