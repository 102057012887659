import { ContentProgressEnum } from "../../../../enums/content-progress.enum";

export interface ContentUserProgressResponse {
  userUid: string;
  state: ContentProgressEnum;
}

export default class ContentUserProgressModel {
  userUid: string;
  state: ContentProgressEnum;

  constructor(response: ContentUserProgressResponse) {
    this.userUid = response.userUid;
    this.state = response.state;
  }
}
