export interface IndexAnswerResponse {
  index: number; // answers index
  amount: number; // amount of times the choice has been selected
}

export default class IndexAnswerModel {
  index: number;
  amount: number;

  constructor(response: IndexAnswerResponse) {
    this.index = response.index;
    this.amount = response.amount;
  }
}
