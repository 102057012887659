import React from "react";
import { Typography } from "antd";
import { grey } from "@ant-design/colors";

interface OwnProps {
  title: string;
}

const SectionTitleComponent = ({ title }: OwnProps) => {
  return (
    <div style={{ marginBottom: "1.4rem" }}>
      <Typography.Text
        style={{
          color: grey[6],
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {title}
      </Typography.Text>
    </div>
  );
};

export default SectionTitleComponent;
