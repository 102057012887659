import { CardTypesEnum } from "../../../enums/card-types.enum";
import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import MultipleChoiceCardDataModel, {
  MultipleChoiceCardDataResponse,
} from "../../content/card-types/multiple-choice/multiple-choice-card-data.model";

export interface CreateMultipleCardInterface extends CreateCardInterface {
  data: MultipleChoiceCardDataResponse;
}

export default class CreateMultipleCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.MULTIPLE_CHOICE;
  data: MultipleChoiceCardDataModel;

  constructor(createMultipleCard: CreateMultipleCardInterface) {
    super(createMultipleCard);
    this.cardType = CardTypesEnum.MULTIPLE_CHOICE;
    this.data = new MultipleChoiceCardDataModel(createMultipleCard.data);
  }
}
