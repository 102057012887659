import { EffectType } from "../../../types/redux.type";
import { SourceCreateEditInterface } from "../../../interfaces/source-create-edit.interface";
import EffectUtility from "../../../utils/effect.utils";
import HttpUtility from "../../../utils/http.utils";
import SourceModel from "../../../models/source/source.model";
import SourceEndpoints from "../../../api/source.endpoints";

export default class SourceEffect {
  /**
   * Get all Sources via GET Request
   * @returns Promise SourceModel[]
   */
  static getAllSources(): EffectType<SourceModel[]> {
    const endpoint = SourceEndpoints.overview;
    return EffectUtility.getToModel<SourceModel[]>(SourceModel, endpoint);
  }

  /**
   * Get one Source via GET Request
   * @param sourceUid string
   * @returns Promise SourceModel
   */
  static getSource(sourceUid: string): EffectType<SourceModel> {
    const endpoint = SourceEndpoints.detail(sourceUid);
    return EffectUtility.getToModel<SourceModel>(SourceModel, endpoint);
  }

  /**
   * Create Source via POST Request
   * @param data SourceCreateEditInterface
   * @returns Promise SourceModel
   */
  static createSource(
    data: SourceCreateEditInterface,
  ): EffectType<SourceModel> {
    const endpoint = SourceEndpoints.create;
    return EffectUtility.postToModel<SourceModel>(SourceModel, endpoint, data);
  }

  /**
   * Edit Source via PATCH Request
   * @param source SourceModel
   * @param data SourceCreateEditInterface
   * @returns Promise SourceModel
   */
  static editSource(
    source: SourceModel,
    data: SourceCreateEditInterface,
  ): EffectType<SourceModel> {
    const endpoint = SourceEndpoints.edit(source);
    return EffectUtility.patchToModel<SourceModel>(SourceModel, endpoint, data);
  }

  /**
   * Delete Source via DELETE Request
   * @param source SourceModel
   * @returns Promise HttpRequest
   */
  static deleteSource(source: SourceModel) {
    const endpoint = SourceEndpoints.delete(source);
    return HttpUtility.delete(endpoint);
  }
}
