import React, { useState } from "react";
import { useContentCompletionProvider } from "../../../../core/providers/content-completion.provider";
import { Card, Collapse, Divider } from "antd";
import { ContentProgressEnum } from "../../../../core/enums/content-progress.enum";
import PlaybookCompletedUserProgressModel from "../../../../core/models/user-progress/completed-user-progress/completed-user-progress-type/playbook-completed-user-progress.model";
import ContentCompletionHeaderComponent from "./content-completion-header.component";
import ContentCompletedUserProgressType from "../../../../core/types/content-completed-user-progress.type";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import styles from "../user-detail.module.css";
import { Playbook } from "../../../../core/api/primio/primioSchemas";
import { useGetPlaybookDetails } from "../../../../core/api/primio/primioComponents";

interface Props {
  playbookProgress: PlaybookCompletedUserProgressModel;
  playbook: Playbook;
}

const ModuleCompletionComponent = ({ playbookProgress, playbook }: Props) => {
  const { selectedCollectionUid, setSelectedCollectionUid } =
    useContentCompletionProvider();
  const [expandAll, setExpandAll] = useState<string | string[]>([]);

  const { data: playbookDetail } = useGetPlaybookDetails(
    {
      pathParams: { playbookUid: playbook.playbookUid },
      queryParams: { excludeMedia: true },
    },
    { enabled: expandAll.includes(playbookProgress.playbookUid) },
  );

  return (
    <Card
      className={styles.completion_card}
      bodyStyle={{ padding: "0.8rem 1.6rem" }}
    >
      <Collapse ghost onChange={setExpandAll}>
        <Collapse.Panel
          key={playbookProgress.playbookUid}
          className={"Panel"}
          collapsible={
            playbookProgress.chapters.length > 0 ? "header" : "disabled"
          }
          header={
            <ContentCompletionHeaderComponent
              contentType={ContentTypesEnum.PLAYBOOK}
              title={playbook.title}
              contentProgress={getContentProgressType(playbookProgress)}
              isExpanded={expandAll.includes(playbookProgress.playbookUid)}
              style={{ fontSize: 16, fontWeight: "600" }}
            />
          }
          showArrow={false}
        >
          {playbookDetail &&
            playbookDetail.chapters &&
            playbookProgress.chapters.length > 0 && (
              <div className={styles.completion_wrapper}>
                {playbookProgress.chapters.map((chap) => {
                  const chapter = playbookDetail.chapters?.find(
                    (c) => c.chapterUid === chap.chapterUid,
                  );
                  if (!chapter) return null;
                  return (
                    <div key={chap.chapterUid} style={{ maxWidth: "36rem" }}>
                      <ContentCompletionHeaderComponent
                        contentType={ContentTypesEnum.CHAPTER}
                        title={chapter.title}
                        contentProgress={getContentProgressType(chap)}
                        style={{ fontWeight: "600" }}
                      />

                      {chapter.collections && chap.collections.length > 0 && (
                        <div className={styles.completion_wrapper}>
                          {chap.collections.map((col, index) => {
                            const collection = chapter.collections?.find(
                              (c) => c.collectionUid === col.collectionUid,
                            );
                            if (!collection) return null;
                            return (
                              <div
                                key={col.collectionUid}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setSelectedCollectionUid(
                                    collection.collectionUid,
                                  )
                                }
                              >
                                <>
                                  {index !== 0 && (
                                    <Divider
                                      type={"vertical"}
                                      className={styles.completion_divider}
                                    />
                                  )}

                                  <ContentCompletionHeaderComponent
                                    contentType={ContentTypesEnum.COLLECTION}
                                    title={collection.title}
                                    contentProgress={getContentProgressType(
                                      col,
                                    )}
                                    style={{
                                      color:
                                        selectedCollectionUid ===
                                        col.collectionUid
                                          ? "var(--primary-color)"
                                          : "",
                                    }}
                                  />
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
        </Collapse.Panel>
      </Collapse>
    </Card>
  );

  function getContentProgressType(
    content: ContentCompletedUserProgressType,
  ): ContentProgressEnum {
    if (content.cardsCompleted / content.totalCards === 1) {
      return ContentProgressEnum.COMPLETED;
    } else if (content.cardsCompleted / content.totalCards > 0) {
      return ContentProgressEnum.STARTED;
    } else {
      return ContentProgressEnum.PENDING;
    }
  }
};

export default ModuleCompletionComponent;
