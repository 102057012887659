export default function getDifferencesInArray<Type>(
  current: Type[],
  updated: Type[],
) {
  const added: Type[] = [];
  const removed: Type[] = [];

  updated.forEach((item) => {
    const sItem = JSON.stringify(item);
    const index = current.findIndex((i) => {
      return JSON.stringify(i) === sItem;
    });
    if (index === -1) {
      added.push(item);
    }
  });

  current.forEach((item) => {
    const sItem = JSON.stringify(item);
    const index = updated.findIndex((i) => {
      return JSON.stringify(i) === sItem;
    });
    if (index === -1) {
      removed.push(item);
    }
  });

  return [added, removed];
}
