import React, { CSSProperties } from "react";
import { Col, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";

interface OwnProps {
  text: string;
  title: string;
  icon?: JSX.Element;
  isLoading?: boolean;
}

type Props = OwnProps;

const StatisticsBlockComponent = (props: Props) => {
  const { t } = useTranslation();
  const { text, title, icon, isLoading } = props;

  const style: CSSProperties = {
    fontSize: "3rem",
    marginRight: "1.5rem",
    color: "var(--primary-color)",
  };

  return (
    <Row
      justify={"center"}
      style={{
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        padding: "1.6rem 2.4rem",
        borderRadius: "var(--border-radius)",
        backgroundColor: "var(--white-color)",
        marginRight: "1rem",
        //
        minHeight: "6.5rem",
        maxHeight: "9rem",
      }}
    >
      {icon && <div style={style}>{icon}</div>}

      <Col flex={1}>
        <Typography.Text
          type={"secondary"}
          style={{
            marginBottom: 0,
            fontSize: 12,
            whiteSpace: "nowrap",
          }}
        >
          {Capitalize(t(text))}
        </Typography.Text>

        {isLoading ? (
          <div>
            <Spin style={{ alignSelf: "center", width: "100%" }} />
          </div>
        ) : (
          <Typography.Title
            level={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography.Title>
        )}
      </Col>
    </Row>
  );
};

export default StatisticsBlockComponent;
