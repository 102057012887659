import { RoutesEnum } from "../../constants/routes/app.route";
import UserRoleEnum from "../enums/user-role.enum";
import RoutesPermissionsMap from "./routes-permissions-map";

function getEnumKey(value: RoutesEnum): keyof typeof RoutesEnum | undefined {
  for (const key in RoutesEnum) {
    if (RoutesEnum[key as keyof typeof RoutesEnum] === value) {
      return key as keyof typeof RoutesEnum;
    }
  }
  return undefined;
}

const routePermissionChecker = (route: RoutesEnum, role: UserRoleEnum) => {
  const key = getEnumKey(route);

  if (!key) {
    return true;
  }

  const roles = RoutesPermissionsMap[key];

  if (roles.length === 0) {
    // if there are no Whitelisted UserRoles it's open for all users
    return true;
  }

  return roles.includes(role);
};

export default routePermissionChecker;
