import { Spin, Typography } from "antd";
import React, { CSSProperties } from "react";
import { AntdIconProps } from "../icons/content-icon.component";
import { Capitalize } from "../../../core/utils/helper.utils";
import styles from "./statistics.module.css";

interface OwnProps {
  title: string;
  value: number;
  loading?: boolean;
  titleStyle?: CSSProperties;
  valueStyle?: CSSProperties;
}

type Props = OwnProps & AntdIconProps;

const BlockStatisticsComponent = (props: Props) => {
  const { loading, title, value, titleStyle, valueStyle } = props;
  const defaultValueStyle: CSSProperties = { fontSize: 30, margin: 0 };
  const defaultTitleStyle: CSSProperties = { margin: 0 };

  return (
    <div className={styles.blocks_item}>
      <Typography.Title style={valueStyle ?? defaultValueStyle}>
        {loading ? <Spin size={"small"} /> : value}
      </Typography.Title>
      <Typography.Text ellipsis style={titleStyle ?? defaultTitleStyle}>
        {Capitalize(title)}
      </Typography.Text>
    </div>
  );
};

export default BlockStatisticsComponent;
