import React, { useEffect, useState } from "react";
import PictureQuizCardModel from "../../../../core/models/content/card-types/picture-quiz/picture-quiz-card.model";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import FormQuestionField from "../../form-builder/components/form-question.field";
import CreatePictureQuizCardModel, {
  CreatePictureQuizCardOptionInterface,
} from "../../../../core/models/create-content/card-types/create-picture-quiz-card.model";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import { useDispatch } from "react-redux";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import PictureQuizOptionsField from "./picture-quiz-options.field";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";

interface Props {
  cardContent: PictureQuizCardModel;
}

const CreatePictureQuizCardForm = ({ cardContent }: Props) => {
  const [question, setQuestion] = useState<string>(
    cardContent ? TranslateDefaults(cardContent.data.question) : "",
  );
  const [options, setOptions] = useState<
    CreatePictureQuizCardOptionInterface[]
  >([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setOptions(
      cardContent.data.options
        .sort((a, b) => a.sort - b.sort)
        .map((option) => {
          return {
            sort: option.sort,
            isCorrect: option.isCorrect,
            mediaUid: option.media?.mediaUid ?? "",
          };
        }),
    );
  }, [cardContent]);

  const onSubmit = (
    data: CreateCardInterface,
    pressedButton?: ContentButtonStatesEnum,
  ) => {
    const pictureQuiz = new CreatePictureQuizCardModel({
      ...data,
      title: question,
      cardType: CardTypesEnum.PICTURE_QUIZ,
      collectionUid: cardContent.collectionUid,
      data: {
        question,
        options,
      },
    });

    if (
      pressedButton &&
      cardContent.contentState === ContentStatesEnum.PUBLISHED
    ) {
      switch (pressedButton) {
        case ContentButtonStatesEnum.CREATE:
          dispatch(
            ContentAction.updateContent(
              ContentTypesEnum.CARD,
              cardContent,
              pictureQuiz,
            ),
          );
          break;
        case ContentButtonStatesEnum.UPDATE:
          cardContent.version++;
          dispatch(
            ContentAction.updateAndPublishCard(cardContent, pictureQuiz),
          );
          break;
      }
    } else {
      dispatch(
        ContentAction.updateContent(
          ContentTypesEnum.CARD,
          cardContent,
          pictureQuiz,
        ),
      );
    }
  };

  const handleDisableButton = () => {
    if (options.length <= 1) {
      // if there are too few options
      return true;
    }

    if (!options.some((o) => o.isCorrect)) {
      // if there is no option that's correct
      return true;
    }

    if (options.length > 4) {
      // highly unlikely, but when amount of options if too high
      return true;
    }

    return false;
  };

  return (
    <CreateCardBaseForm
      isOptional
      cardContent={cardContent}
      disabledButton={handleDisableButton()}
      hideFields
      onSubmit={onSubmit}
    >
      <>
        <FormQuestionField question={question} setQuestion={setQuestion} />
        <PictureQuizOptionsField options={options} setOptions={setOptions} />
      </>
    </CreateCardBaseForm>
  );
};

export default CreatePictureQuizCardForm;
