import React from "react";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";

const CommandBar = () => {
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);
  useHotkeys(
    ["control+/", "meta+/"],
    () => openModal(ModalNames.SEARCH_AND_COMMANDS),
    {
      enabled: userRole ? userRole !== UserRoleEnum.GROUP_ADMIN : false,
    },
  );
  const { openModal } = useModal();

  return <></>;
};

export default CommandBar;
