import React, { HTMLAttributes } from "react";
import { Card, Col, Collapse, Divider, Row, Typography } from "antd";
import {
  CardTypesColorEnum,
  CardTypesEnum,
} from "../../../core/enums/card-types.enum";
import { UpOutlined } from "@ant-design/icons";
import CardTypeIconComponent from "../icons/card-type-icon.component";

interface CardBoilerplateProps {
  cardUid: string;
  detail?: JSX.Element;
  expandAll?: string | string[];
  setExpandAll?: (key: string | string[]) => void;
}

type Props = CardBoilerplateProps &
  CardAnswerCollapseHeaderProps &
  HTMLAttributes<HTMLDivElement>;

const CardAnswerBoilerplate = ({
  cardUid,
  detail,
  title,
  suffix,
  cardType,
  ...props
}: Props) => {
  return (
    <Card style={{ border: "none" }} bodyStyle={{ paddingBottom: 0 }}>
      <Collapse
        activeKey={props.expandAll}
        onChange={props.setExpandAll}
        expandIcon={() => null}
        ghost
      >
        <Collapse.Panel
          key={cardUid}
          className={"Panel"}
          showArrow={false}
          collapsible={detail ? "header" : "disabled"}
          header={
            <CardAnswerCollapseHeader
              title={title}
              cardType={cardType}
              suffix={suffix}
              showExpanded={!!detail}
              isExpanded={props.expandAll?.includes(cardUid)}
            />
          }
        >
          <div style={{ padding: "0rem 0.5rem 2rem 0.5rem" }}>
            {detail && (
              <>
                <Divider style={{ marginTop: 0 }} />
                {detail}
              </>
            )}
          </div>
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
};

interface CardAnswerCollapseHeaderProps {
  title: string;
  cardType: CardTypesEnum;
  isExpanded?: boolean;
  showExpanded?: boolean;
  suffix?: JSX.Element;
}

export const CardAnswerCollapseHeader = ({
  title,
  cardType,
  suffix,
  showExpanded,
  isExpanded,
}: CardAnswerCollapseHeaderProps) => {
  const cardTypeColor = {
    "--card-type-color": CardTypesColorEnum[cardType][0].color,
  } as React.CSSProperties;
  const backgroundColor = {
    "--hover-card-type-background-color":
      CardTypesColorEnum[cardType][1].background,
  } as React.CSSProperties;

  return (
    <>
      <Row
        align={"middle"}
        style={{
          display: "flex",
          flexFlow: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Col
          flex={1}
          style={{ display: "flex", alignItems: "center", width: "90%" }}
        >
          <Col style={backgroundColor}>
            <CardTypeIconComponent
              cardType={cardType}
              style={{ ...cardTypeColor, fontSize: "1.7rem", padding: ".8rem" }}
            />
          </Col>
          <Col flex={"2rem"} />
          <Col style={{ maxWidth: "88%" }}>
            <Typography.Title
              ellipsis
              level={4}
              style={{ margin: 0, whiteSpace: "normal" }}
            >
              {title}
            </Typography.Title>
          </Col>
        </Col>
        {showExpanded && (
          <Col>
            <UpOutlined rotate={isExpanded ? 0 : 180} />
          </Col>
        )}
      </Row>
      {suffix && (
        <div>
          <Divider style={{ marginBottom: ".8rem" }} />
          <Row align={"middle"} style={{ paddingBottom: "1rem" }}>
            {suffix && suffix}
          </Row>
        </div>
      )}
    </>
  );
};

export default CardAnswerBoilerplate;
