import * as Sentry from "@sentry/react";

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
export const COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID ||
  fail("COGNITO_USER_POOL_ID not found");
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID ||
  fail("COGNITO_IDENTITY_POOL_ID not found");
export const COGNITO_CLIENT_ID =
  process.env.REACT_APP_COGNITO_CLIENT_ID ||
  fail("COGNITO_CLIENT_ID not found");
export const COGNITO_REGION =
  process.env.REACT_APP_COGNITO_REGION || fail("COGNITO_REGION not found");
export const CLIENT_NAME =
  process.env.REACT_APP_CLIENT_NAME || fail("CLIENT_NAME not found");
export const DEBUG = process.env.REACT_APP_DEBUG === "true";
export const PRODUCTION = process.env.NODE_ENV === "production";
export const VERSION =
  process.env.REACT_APP_VERSION || fail("VERSION not found");
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN || fail("SENTRY_DSN not found");

export const RENDER_GIT_BRANCH =
  process.env.REACT_APP_RENDER_GIT_BRANCH || fail("ENDER_GIT_BRANCH not found");
export const RENDER_GIT_COMMIT =
  process.env.REACT_APP_RENDER_GIT_COMMIT ||
  fail("RENDER_GIT_COMMIT not found");
export const IS_PULL_REQUEST =
  process.env.REACT_APP_IS_PULL_REQUEST || fail("IS_PULL_REQUEST not found");

function fail(message: string) {
  if (DEBUG) {
    console.error("ENV Error: " + message);
  }

  Sentry.captureException("ENV Error: " + message);
  return "";
}
