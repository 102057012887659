import UserModel, { UserResponse } from "../user/user.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.utils";

export interface UserGroupResponse {
  groupType: {
    CreationDate: string;
    Description?: string;
    GroupName: string;
    LastModifiedDate: string;
    Precedence?: number;
    UserPoolId: string;
  };
  users: UserResponse[];
}

export default class UserGroupModel {
  title: string;
  description?: string;
  created_at: Date;
  last_modified_at: Date;
  users: UserModel[] = [];

  constructor(group: UserGroupResponse) {
    this.title = group.groupType.GroupName;
    this.description = group.groupType.Description;
    this.created_at = new Date(group.groupType.CreationDate);
    this.last_modified_at = new Date(group.groupType.LastModifiedDate);
    this.users = MapResponseToModel(group.users, UserModel);
  }
}
