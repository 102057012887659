import React from "react";
import { useSelector } from "react-redux";
import { Empty } from "antd";
import { selectNotificationById } from "../../../core/redux/selectors/notification/notification.selector";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import NotificationsDetailOverviewScreen from "../../screens/notifications/notifications-detail-overview.screen";

const NotificationsDetailContainer = ({ match }: { match: any }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const notification = useSelector((state) =>
    selectNotificationById(state, match.params.uid),
  );

  return (
    <PageLayoutDetailComponent
      title={notification ? notification.data.title : "Oops.."}
      goBack={() => history.push(RoutesEnum.NOTIFICATIONS)}
      container={"notifications"}
    >
      {notification ? (
        <NotificationsDetailOverviewScreen notification={notification} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("errors.general")}
        />
      )}
    </PageLayoutDetailComponent>
  );
};

export default NotificationsDetailContainer;
