import PlaybookUserProgressModel, {
  PlaybookUserProgressResponse,
} from "./user-progress-types/playbook-user-progress.model";

export interface UserProgressResponse {
  [playbookUid: string]: PlaybookUserProgressResponse;
}

export default class UserProgressModel {
  playbooks: PlaybookUserProgressModel[];

  constructor(response: UserProgressResponse) {
    this.playbooks = Object.keys(response).map((playbookUid) => {
      return new PlaybookUserProgressModel(response[playbookUid], playbookUid);
    });
  }
}
