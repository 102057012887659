import { EffectType } from "../../../types/redux.type";
import HttpUtility from "../../../utils/http.utils";
import EffectUtility from "../../../utils/effect.utils";
import MediaModel, { MediaResponse } from "../../../models/media/media.model";
import MediaEndpoints from "../../../api/media.endpoints";

export default class MediaEffect {
  /**
   * Get Media via GET Request
   * @param mediaUid string
   * @returns Promise MediaModel
   */
  static getMedia(mediaUid: string): EffectType<MediaModel> {
    const endpoint = MediaEndpoints.detail(mediaUid);
    return EffectUtility.getToModel<MediaModel>(MediaModel, endpoint);
  }

  /**
   * Create Media via POST Request
   * @param data Partial<MediaResponse>
   * @returns Promise MediaModel
   */
  static createMedia(data: Partial<MediaResponse>): EffectType<MediaModel> {
    const endpoint = MediaEndpoints.create;
    return HttpUtility.post(endpoint, data);
  }
}
