import React from "react";
import Link from "antd/lib/typography/Link";

interface Props {
  link: string;
}

const EmbedLinkMediaComponent = ({ link }: Props) => {
  return (
    <Link href={link} target={"_blank"}>
      {link}
    </Link>
  );
};

export default EmbedLinkMediaComponent;
