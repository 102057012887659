import { Dispatch } from "redux";
import { ActionType, EffectType, ThunkType } from "../types/redux.type";
import ActionInterface from "../interfaces/action.interface";
import HttpErrorResponseModel from "../models/http-error-response.model";
import ThunkInterface from "../interfaces/thunk.interface";

export default class ActionUtility {
  /**
   * @param actionType
   * @param effect
   * @param args
   */
  static createThunk<Type>(
    actionType: string,
    effect: (...args: any[]) => EffectType<Type>,
    ...args: any[]
  ): ThunkType<Type> {
    return async (
      dispatch: Dispatch<ThunkInterface<Type | HttpErrorResponseModel>>,
    ): Promise<Type | HttpErrorResponseModel> => {
      // TODO: Refactor out this ts-ignore
      // @ts-ignore
      dispatch(ActionUtility.createActionHelper<Type>(actionType));

      const model = await effect(...args);
      const isError = model instanceof HttpErrorResponseModel;
      const meta = {
        type: actionType,
        date: new Date(),
        args: args,
      };

      dispatch(
        ActionUtility.createThunkActionHelper<Type>(
          `${actionType}_FINISHED`,
          model,
          isError,
          meta,
        ),
      );

      return model;
    };
  }

  /**
   *
   * @param type
   * @param payload
   * @param error
   * @param meta
   */
  static createAction<Type>(
    type: string,
    payload?: any,
    error = false,
    meta = null,
  ): ActionType<Type> {
    return (dispatch: Dispatch<ActionInterface<Type>>) => {
      return dispatch(
        ActionUtility.createActionHelper<Type>(type, payload, error, meta),
      ).payload;
    };
  }

  /**
   * Helper method to create a Redux compatible object for Thunk Actions
   * @param type
   * @param payload
   * @param error
   * @param meta
   * @private
   */
  private static createThunkActionHelper<Type>(
    type: string,
    payload: Type | HttpErrorResponseModel,
    error = false,
    meta: any = null,
  ) {
    return { type, payload, error, meta };
  }

  /**
   * Helper method to create a Redux compatible object for Normal Actions
   * @param type
   * @param payload
   * @param error
   * @param meta
   * @private
   */
  private static createActionHelper<Type>(
    type: string,
    payload?: Type,
    error = false,
    meta = null,
  ) {
    return { type, payload, error, meta };
  }
}
