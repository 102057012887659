import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Select } from "antd";
import MediaProvidersEnum, {
  AudioProvidersEnum,
  EmbedProvidersEnum,
  ImageProvidersEnum,
  MediaTypesEnum,
  NoMediaTypesEnum,
  VideoProvidersEnum,
} from "../../../core/enums/media-providers.enum";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import MediaModel, {
  MediaResponse,
} from "../../../core/models/media/media.model";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import MediaUploadComponent from "./media-upload.component";
import MediaAction from "../../../core/redux/stores/media/media.action";
import MediaProvidersTypes from "../../../core/types/media-providers.types";

interface Props {
  media?: MediaModel;
  contentType: ContentTypesEnum;
  onChange: (media: MediaModel) => void;
}

const MediaUploadSelectComponent = ({
  contentType,
  onChange,
  media: _media,
}: Props) => {
  const [t] = useTranslation();
  const [media, setMedia] = useState<MediaModel>();
  const [mediaLabel, setMediaLabel] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    setMedia(_media);
  }, [_media]);

  useEffect(() => {
    if (!media) {
      return setMediaLabel(
        MediaTypesEnum.NO_MEDIA + "-" + NoMediaTypesEnum.NO_MEDIA,
      );
    }

    switch (media.mediaType) {
      case MediaTypesEnum.AUDIO:
      case MediaTypesEnum.IMAGE:
      case MediaTypesEnum.VIDEO:
      case MediaTypesEnum.EMBED:
        setMediaLabel(media.mediaType + "-" + media.provider);
        break;
      case MediaTypesEnum.NO_MEDIA:
        setMedia(undefined);
        break;
    }

    onChange(media);
  }, [media]);

  return (
    <Form
      labelCol={{ span: 6 }}
      layout={"horizontal"}
      labelAlign={"left"}
      component={false}
    >
      <Form.Item
        initialValue={mediaLabel}
        label={Capitalize(t("form.card.media.label"))}
      >
        <Select
          value={mediaLabel}
          style={{ width: "40%" }}
          defaultActiveFirstOption={true}
          onSelect={handleSelectMedia}
        >
          {Object.keys(MediaProvidersEnum).map(
            (mediaType: string, index: number) => (
              <Select.OptGroup
                key={index}
                label={Capitalize(t(`content.media-types.${mediaType}.label`))}
              >
                {Object.keys(MediaProvidersEnum[mediaType])
                  .filter((m) => m !== "S3X")
                  .map((value: string) => (
                    <Select.Option
                      key={`${mediaType}-${value}`}
                      value={`${mediaType}-${value}`}
                      title={Capitalize(
                        t(`content.media-types.${mediaType}.${value}`),
                      )}
                      disabled={isDisabled(mediaType)}
                    >
                      {Capitalize(
                        t(`content.media-types.${mediaType}.${value}`),
                      )}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            ),
          )}
        </Select>

        {media && (
          <MediaUploadComponent
            media={media}
            onSubmit={handleOnMediaProvider}
            onUpload={handleOnMediaUpload}
          />
        )}
      </Form.Item>
    </Form>
  );

  function handleSelectMedia(e: string) {
    setMediaLabel(e);

    const mediaElements = e.split("-");
    if (mediaElements.length !== 2) {
      return;
    }

    if (!media) {
      return setMedia({
        contentType: contentType,
        mediaUid: undefined,
        provider: mediaElements[1] as NoMediaTypesEnum,
        mediaType: mediaElements[0] as MediaTypesEnum.NO_MEDIA,
        uri: "",
      });
    }

    switch (media.mediaType) {
      case MediaTypesEnum.IMAGE:
        setMedia({
          ...media,
          contentType: contentType,
          mediaUid: undefined,
          provider: mediaElements[1] as ImageProvidersEnum,
          mediaType: mediaElements[0] as MediaTypesEnum.IMAGE,
          uri: "",
        });
        break;
      case MediaTypesEnum.VIDEO:
        setMedia({
          ...media,
          contentType: contentType,
          mediaUid: undefined,
          provider: mediaElements[1] as VideoProvidersEnum,
          mediaType: mediaElements[0] as MediaTypesEnum.VIDEO,
          uri: "",
        });
        break;
      case MediaTypesEnum.AUDIO:
        setMedia({
          ...media,
          contentType: contentType,
          mediaUid: undefined,
          provider: mediaElements[1] as AudioProvidersEnum,
          mediaType: mediaElements[0] as MediaTypesEnum.AUDIO,
          uri: "",
        });
        break;
      case MediaTypesEnum.EMBED:
        setMedia({
          ...media,
          contentType: contentType,
          mediaUid: undefined,
          provider: mediaElements[1] as EmbedProvidersEnum,
          mediaType: mediaElements[0] as MediaTypesEnum.EMBED,
          uri: "",
        });
        break;
      default:
        break;
    }
  }

  function handleOnMediaUpload(mediaUid: string) {
    if (
      !media ||
      (media.provider !== VideoProvidersEnum.S3 &&
        media.provider !== ImageProvidersEnum.S3)
    ) {
      return;
    }

    setMedia({
      ...media,
      mediaUid: mediaUid,
    });
  }

  function handleOnMediaProvider(
    videoId: string,
    provider: MediaProvidersTypes,
    isValid: boolean,
  ) {
    if (
      !media ||
      media.provider === VideoProvidersEnum.S3 ||
      media.provider === ImageProvidersEnum.S3 ||
      media.provider === NoMediaTypesEnum.NO_MEDIA
    ) {
      return;
    }

    setMedia({
      ...media,
      provider: provider,
      uri: isValid ? videoId : "", //TODO: replace with video title
    });

    if (isValid && videoId !== "") {
      handleUploadMedia(videoId, provider);
    }
  }

  function handleUploadMedia(uri: string, provider: MediaProvidersTypes) {
    if (!media) {
      return;
    }

    const createMedia: Partial<MediaResponse> = {
      provider: provider,
      mediaType: media.mediaType,
      uri: uri,
    };

    dispatch(MediaAction.createMedia(createMedia)).then((res) => {
      setMedia({
        ...media,
        mediaUid: res.data,
        provider: provider,
        uri: uri,
      });
    });
  }

  function isDisabled(mediaType: string) {
    switch (mediaType) {
      case MediaTypesEnum.NO_MEDIA:
      case MediaTypesEnum.IMAGE:
      case MediaTypesEnum.EMBED:
        return false;
    }
  }
};

export default MediaUploadSelectComponent;
