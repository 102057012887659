import { UpdateApiKeyState } from "../interfaces/create-api-key.interface";
import { API_URL } from "../../constants/config/env.config";

const ApiKeyEndpoints = {
  /**
   * GET all Api Keys endpoint.
   * @returns API_URL + /api-keys/
   */
  overview: `${API_URL}/api-keys/`,

  /**
   * GET single Api Keys detail endpoint.
   * @param key string
   * @returns API_URL + /api-keys/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
   */
  detail(key: string) {
    return `${API_URL}/api-keys/${key}`;
  },

  /**
   * POST create Api Key endpoint.
   * @returns API_URL + /api-keys/
   */
  create: `${API_URL}/api-keys/`,

  /**
   * POST update Api Key endpoint.
   * @param key string
   * @returns API_URL + /api-keys/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
   */
  edit(key: string) {
    return `${API_URL}/api-keys/${key}`;
  },

  /**
   * DELETE Api Key endpoint.
   * @param apiKeyUid string
   * @returns API_URL + /api-keys/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
   */
  delete(apiKeyUid: string) {
    return `${API_URL}/api-keys/${apiKeyUid}`;
  },

  /**
   * Update active state Api Key endpoint.
   * @param key string
   * @param active boolean
   * @returns API_URL + /api-keys/xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx/active
   */
  updatedApiKeyState(data: UpdateApiKeyState) {
    return `${API_URL}/api-keys/${data.key}/${
      data.active ? "disable" : "enable"
    }`;
  },
};

export default ApiKeyEndpoints;
