import React, { createContext, PropsWithChildren, useState } from "react";

interface ContentCompletionType {
  selectedCollectionUid?: string;
  setSelectedCollectionUid: (uid: string) => void;
}

const ContentCompletionContext = createContext<ContentCompletionType>(
  {} as ContentCompletionType,
);

function ContentCompletionProvider(props: PropsWithChildren<any>) {
  const [selectedCollectionUid, setSelectedCollectionUid] = useState<string>();

  return (
    <ContentCompletionContext.Provider
      value={{
        selectedCollectionUid,
        setSelectedCollectionUid,
      }}
    >
      {props.children}
    </ContentCompletionContext.Provider>
  );
}

const useContentCompletionProvider = () =>
  React.useContext(ContentCompletionContext);

export { ContentCompletionProvider, useContentCompletionProvider };
