export interface LearningPathContentResponse {
  learningPathUid: string;
  contentType: any;
  contentUid: string;
  sort: number;
}

export default class LearningPathContentModel {
  learningPathUid: string;
  contentType: any;
  contentUid: string;
  sort: number;

  constructor(response: LearningPathContentResponse) {
    this.learningPathUid = response.learningPathUid;
    this.contentType = response.contentType;
    this.contentUid = response.contentUid;
    this.sort = response.sort;
  }
}
