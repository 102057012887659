import { StoreInterface } from "../../stores/root.reducer";
import LearningPathModel from "../../../models/learning-path/learning-path.model";

export const selectLearningPaths = (
  state: StoreInterface,
): LearningPathModel[] => {
  state.learningPath.learningPaths.forEach((c) =>
    c.content.sort((a, b) => a.sort - b.sort),
  );
  return state.learningPath.learningPaths;
};

export const selectLearningPathByUid = (
  state: StoreInterface,
  learningPathUid?: string,
): LearningPathModel | undefined => {
  const learningPaths: LearningPathModel[] = selectLearningPaths(state);
  if (learningPaths.length === 0 || !learningPathUid) {
    return;
  }

  return learningPaths.find((l) => l.learningPathUid === learningPathUid);
};

export const selectUserGroupsFromLearningPathContent = (
  state: StoreInterface,
) => {
  const playbooks = state.content.playbooks;
  const learningPaths = state.learningPath.learningPaths;
  const newLearningPaths: any = [...learningPaths].filter(
    (learningPath) => learningPath.state !== "REVOKED",
  );
  playbooks.forEach((playbook) => {
    newLearningPaths.forEach((path) => {
      path.content.forEach((content) => {
        if (playbook.playbookUid === content.contentUid) {
          path.userGroupAcl = playbook.userGroupAcl;
          return;
        }
      });
    });
  });
  return newLearningPaths;
};
