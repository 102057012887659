import { MapResponseToModel } from "../../../../utils/map-response-to-model.utils";
import SliderOptionModel, { SliderOptionResponse } from "./slider-option.model";

export interface SliderCardDataResponse {
  options: SliderOptionResponse[];
  question: string;
}

export default class SliderCardDataModel {
  options: SliderOptionModel[];
  question: string;

  constructor(sliderCard: SliderCardDataResponse) {
    this.options = MapResponseToModel(sliderCard.options, SliderOptionModel);
    this.question = sliderCard.question;
  }
}
