import { CreateLearningPathDto } from "../api/primio/primioSchemas";

const learningPathTypeLookup: {
  [key: string]: "PROGRESS_BASED" | "SCHEDULE_BASED" | "TIME_BASED" | undefined;
} = {
  Progress: "PROGRESS_BASED",
  Scheduled: "SCHEDULE_BASED",
  Time: "TIME_BASED",
  Open: undefined,
};

const getLearningPathMetadata = (record: string[]) => {
  const [title, description, type = "Open", startTime = "12:00"] = record;

  return {
    title,
    subtitle: "Training", // Hardcoded value because it's hardcoded in the rest of the codebase
    description,
    startTime,
    learningPathType: learningPathTypeLookup[type],
  };
};

const parseCsvToLearningPath = (csv: string[][]): CreateLearningPathDto => {
  const learningPathMetaIndex =
    csv.findIndex((item) => item.includes("Leerpad")) + 2;

  const metadata = getLearningPathMetadata(csv[learningPathMetaIndex]);

  const startOfContentIndex = csv.findIndex((item) => item.includes("Id")) + 1;
  const learningPathContent = csv.slice(startOfContentIndex);

  let scheduledFor: string | undefined;
  if (metadata.learningPathType === "SCHEDULE_BASED") {
    const firstDate = learningPathContent[0][2];
    if (!firstDate) throw new Error("No date found in first content");

    scheduledFor = new Date(`${firstDate} ${metadata.startTime}`).toISOString();
  }

  let cumulativeMinutesAfterStarted = 0;
  const content = learningPathContent
    .filter((item) => !!item[1]) // filter out lines with empty playbookUid
    .map((item, sort) => {
      const playbookUid = item[1].trim();

      if (!playbookUid) {
        throw new Error("No playbookUid found in content");
      }

      let data = {};
      if (
        metadata.learningPathType === "SCHEDULE_BASED" ||
        metadata.learningPathType === "TIME_BASED"
      ) {
        const dayOffset = item[3];
        const notificationTitle = item[5];
        const notificationMessage = item[6];

        if (dayOffset) {
          const thisMinutesAfterStarted = parseInt(dayOffset.trim()) * 24 * 60;
          cumulativeMinutesAfterStarted += thisMinutesAfterStarted;
        }

        data = {
          minutesAfterStarted: cumulativeMinutesAfterStarted,
          notification: {
            title: notificationTitle ? notificationTitle.trim() : "",
            message: notificationMessage ? notificationMessage.trim() : "",
          },
        };
      }

      return {
        contentType: "PLAYBOOK",
        contentUid: playbookUid,
        sort,
        data,
      };
    });

  return {
    title: metadata.title,
    subtitle: metadata.subtitle,
    learningPathType: metadata.learningPathType,
    description: metadata.description,
    content: content as any[],
    scheduledFor,
  };
};

export default parseCsvToLearningPath;
