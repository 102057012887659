import React from "react";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Col } from "antd";
import { useUsersProgressCollectionDetail } from "../../../core/hooks/user-progress/user-progress.hook";
import { useHistory, useParams } from "react-router-dom";
import { useFavouriteCards } from "../../../core/hooks/favourite/favourite.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContentProgressMeta from "../content-user-progress/content-progress.meta";
import MediaSuffixTag from "./tags/media-suffix.tag";
import CardTag from "./tags/card-tag";
import UserProgressCollectionDetailModel from "../../../core/models/user-progress/collection-detail/user-progress-collection-detail.model";
import UserProgressCardDetailModel from "../../../core/models/user-progress/collection-detail/user-progress-card-detail.model";
import UsersFavouriteCardModel from "../../../core/models/favourite/users-favourite-card.model";
import IndexAnswerModel from "../../../core/models/user-progress/collection-detail/answer-types/index-answer.model";
import CardTypes from "../../../core/types/card.types";
import Moment from "moment";
import { useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";
import { selectUserProgressFromUserGroupUsers } from "../../../core/redux/selectors/user-progress/user-progress";

interface OwnProps {
  card: CardTypes;
}

type Props = OwnProps;

const CardAnswerSuffixComponent = ({ card }: Props) => {
  const { t } = useTranslation();
  const params = useParams<{
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
    cardUid: string;
  }>();
  const history = useHistory();
  //
  const { data: col } = useUsersProgressCollectionDetail(card.collectionUid);
  const collectionDetail = col as UserProgressCollectionDetailModel | undefined;
  //
  const { data: progress } = useUsersProgressCollectionDetail(
    card.collectionUid,
    card.cardUid,
  );
  const cardDetail = progress as UserProgressCardDetailModel | undefined;
  //
  const { data: favoCard } = useFavouriteCards(
    card.collectionUid,
    card.cardUid,
  );
  const favouriteCard = favoCard as UsersFavouriteCardModel | undefined;

  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );

  const newUserModel = selectUserProgressFromUserGroupUsers(cardDetail);

  // Note: some cards were published before the publishedAt field was implemented in the database,
  // that's why the publishedAt date of a collection is the closest date we have available (W.N.)
  const date = card.publishedAt
    ? card.publishedAt
    : collectionDetail?.firstPublishedAt
      ? collectionDetail.firstPublishedAt
      : undefined;
  const publish = (
    <ContentProgressMeta
      icon={"publish"}
      label={date ? Moment(date).format("DD-MM-YY") : "-"}
    />
  );
  const views = (
    <ContentProgressMeta
      icon={"views"}
      label={`${
        newUserModel && currentlySelectedUserGroup !== ""
          ? newUserModel.map((user) => user.answers).length
          : cardDetail
            ? cardDetail.cardCompleted
            : 0
      } ${t("common.views")}`}
    />
  );
  const answers = (
    <ContentProgressMeta
      icon={"answers"}
      label={`${
        newUserModel && currentlySelectedUserGroup !== ""
          ? newUserModel.map((user) => user.answers).length
          : cardDetail
            ? cardDetail.answersCount
            : 0
      } ${t("common.answers")}`}
    />
  );

  switch (card.cardType) {
    case CardTypesEnum.KNOWLEDGE_CARD:
      return (
        <>
          {publish}
          {views}
          <MediaSuffixTag card={card} />
          {favouriteCard?.cardUid === card.cardUid && (
            <ContentProgressMeta
              icon={"bookmark"}
              label={`${favouriteCard.userUids.length}x ${t(
                "common.bookmarked",
              )}`}
            />
          )}
        </>
      );
    case CardTypesEnum.QUIZ:
    case CardTypesEnum.PICTURE_QUIZ:
      return (
        <>
          {publish}
          {views}
          {answers}
          {getQuizTag()}
        </>
      );
    case CardTypesEnum.SLIDER:
      return (
        <>
          {publish}
          {views}
          {answers}
          {getSliderTag()}
        </>
      );
    case CardTypesEnum.MULTIPLE_CHOICE:
      return (
        <>
          {publish}
          {views}
        </>
      );
    case CardTypesEnum.QUESTION:
      return (
        <>
          <Col style={{ display: "flex", flex: 1 }}>
            {publish}
            {views}
            {answers}
          </Col>
          <Col>
            <a
              style={{ fontSize: "1.3rem" }}
              onClick={() => {
                history.push(
                  `${RoutesEnum.CARD_DETAIL.replace(
                    ":playbookUid",
                    params.playbookUid,
                  )
                    .replace(":chapterUid", params.chapterUid)
                    .replace(":collectionUid", params.collectionUid)
                    .replace(":cardUid", card.cardUid)}`,
                );
              }}
            >
              {Capitalize(t("common.show") + " " + t("common.details"))}
            </a>
          </Col>
        </>
      );
    default:
      return null;
  }

  function getQuizTag() {
    if (!cardDetail) {
      return;
    }

    if (
      !(
        card.cardType === CardTypesEnum.PICTURE_QUIZ ||
        card.cardType === CardTypesEnum.QUIZ
      )
    ) {
      return;
    }

    const findCorrectFilteredAnswer = newUserModel
      .map((user) => user.answers)
      .map((answer) => {
        return answer.find(
          (value) =>
            value.answer === card.data.options.findIndex((o) => o.isCorrect),
        );
      });

    const findCorrectAnswer = cardDetail.answers.find((answer) => {
      if (answer instanceof IndexAnswerModel) {
        return answer.index === card.data.options.findIndex((o) => o.isCorrect);
      }
    });

    const amountGoodAnswer =
      newUserModel && currentlySelectedUserGroup !== ""
        ? findCorrectFilteredAnswer.length
        : findCorrectAnswer
          ? findCorrectAnswer.amount
          : 0;

    return (
      <CardTag
        type={"correct"}
        label={`${Math.floor(
          (amountGoodAnswer / cardDetail.usersCompleted) * 100,
        )}%`}
      />
    );
  }

  function getSliderTag() {
    if (
      !cardDetail ||
      card.cardType !== CardTypesEnum.SLIDER ||
      cardDetail.answers.length === 0
    ) {
      return;
    }

    const sortedAnswers = cardDetail.answers.sort(
      (a, b) => b.amount - a.amount,
    ) as IndexAnswerModel[];
    const mostPopular = sortedAnswers[0] as IndexAnswerModel;

    if (
      sortedAnswers.length > 1 &&
      sortedAnswers[1].amount >= mostPopular.amount
    ) {
      // Hide tag if more than 1 answer option is most popular...
      return;
    }

    return (
      <CardTag
        type={"popular"}
        label={`${Capitalize(t("common.option"))} ${mostPopular.index + 1}`}
      />
    );
  }
};

export default CardAnswerSuffixComponent;
