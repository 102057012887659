import React, { useEffect, useRef, useState } from "react";
import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../../../../core/enums/learning-path-state.enum";
import { useSelector } from "react-redux";
import CreateLearningPathContentForm, {
  CreateLearningPathContentInterface,
  CreateLearningPathContentRef,
} from "../../forms/create-learning-path-content.form";
import { ContentActionProps } from "../../content-action/content-action.component";
import { Button, Card, Col, Row, Typography } from "antd";
import { selectRequesting } from "../../../../core/redux/selectors/requesting/requesting.selector";
import { BranchesOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { selectLearningPathByUid } from "../../../../core/redux/selectors/learning-path/learning-path.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import PreviewLearningPathContentMetaComponent from "./preview-learning-path-content-meta.component";
import LearningPathDelayComponent from "./learning-path-delay.component";
import ContextActionIconComponent from "../../icons/context-action-icon.component";
import ContentActionsComponent from "../../content/content-actions.component";
import TimeBasedContentModel, {
  TimeBasedContentResponse,
} from "../../../../core/models/learning-path/time-based-content.model";
import ProgressBasedContentModel from "../../../../core/models/learning-path/progress-based-content.model";
import LearningPathModel from "../../../../core/models/learning-path/learning-path.model";
import LearningPathAction from "../../../../core/redux/stores/learning-path/learning-path.action";
import contentStyles from "../../content/content.module.css";
import styles from "../learning-path.module.css";
import { selectPlaybookViaUid } from "../../../../core/redux/selectors/content/content.selector";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import PlaybookModel from "../../../../core/models/content/playbook.model";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import daysToMinutes from "../../../../core/utils/days-to-minutes.utils";
import Moment from "moment";

interface Props {
  content: TimeBasedContentModel | ProgressBasedContentModel;
  onEdit: (learningPath: LearningPathModel) => void;
  minutesOffset?: number;
}

const PreviewLearningPathContentComponent = ({
  content,
  onEdit,
  minutesOffset,
}: Props) => {
  const { t } = useTranslation();
  const learningPath: LearningPathModel | undefined = useSelector((state) =>
    selectLearningPathByUid(state, content.learningPathUid),
  );
  const playbook: PlaybookModel | undefined = useSelector((state) =>
    selectPlaybookViaUid(state, content.contentUid),
  );
  const [isEditVisible, setIsEditVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const createLearningPathContentRef =
    useRef<CreateLearningPathContentRef>(null);
  const requesting = useSelector((state) =>
    selectRequesting(state, [LearningPathAction.REQUEST_EDIT_LEARNING_PATH]),
  );
  const history = useHistory();

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        handleOnCancel();
      }
    }
  }, [requesting]);

  if (!learningPath) {
    return null;
  }

  return (
    <div className={styles.grid_default}>
      {content instanceof TimeBasedContentModel &&
        content.sort !== 1 &&
        content.data?.minutesAfterStarted && (
          <>
            <div className={styles.grid_icon_container}>
              <Button
                shape={"circle"}
                type={"primary"}
                style={{
                  backgroundColor: "var(--blue400-color)",
                  borderColor: "var(--blue400-color)",
                }}
              >
                <FieldTimeOutlined className={styles.timeline_delay_icon} />
              </Button>

              <div>
                <LearningPathDelayComponent
                  learningPathState={learningPath?.state}
                  delay={content.data.minutesAfterStarted}
                  setDelay={handleOnEditDelay}
                />

                {learningPath.learningPathType ===
                  LearningPathTypeEnum.SCHEDULE_BASED && (
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: 20,
                      color: "#7A7A7A",
                    }}
                  >
                    <span>
                      {Capitalize(
                        t(
                          "translations:screens.learning-path.schedules.release-label",
                        ),
                      )}{" "}
                    </span>
                    <strong>
                      {Moment(learningPath.scheduledFor)
                        .add(minutesOffset, "minutes")
                        .add(content.data.minutesAfterStarted, "minutes")
                        .format("D MMMM")}
                    </strong>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

      <div className={styles.grid_icon_container}>
        {renderPreviewIcon()}
        {!isEditVisible ? (
          renderPreview()
        ) : (
          <CreateLearningPathContentForm
            ref={createLearningPathContentRef}
            onOk={handleOnEditContent}
            onCancel={handleOnCancel}
            learningPath={learningPath}
            content={content}
          />
        )}
      </div>
    </div>
  );

  function renderPreviewIcon() {
    return (
      <Button
        shape={"circle"}
        type={"primary"}
        style={{
          backgroundColor: "var(--black-color)",
          borderColor: "var(--black-color)",
          zIndex: 1,
        }}
      >
        <BranchesOutlined />
      </Button>
    );
  }

  function renderPreview() {
    return (
      <div style={{ flex: 1 }}>
        <Card
          className={contentStyles.content_container}
          style={{ border: "none", cursor: "default" }}
        >
          <Row>
            <Col flex={1}>
              {playbook && (
                <>
                  <Typography.Title level={5}>
                    {t(playbook.title)}
                    {playbook.contentState === ContentStatesEnum.DRAFT && (
                      <span style={{ opacity: 0.5 }}>
                        {" "}
                        {Capitalize(
                          t(
                            `translations:content-state.${playbook.contentState}`,
                          ),
                        )}
                      </span>
                    )}
                  </Typography.Title>
                  <Typography.Text>{t(playbook.description)}</Typography.Text>
                </>
              )}
            </Col>

            {learningPath?.state !== LearningPathStateEnum.POSTED && (
              <ContentActionsComponent actions={getActions()} />
            )}
          </Row>
        </Card>

        {content instanceof TimeBasedContentModel &&
          content.data?.notification &&
          content.data?.notification.title !== "" &&
          content.data?.notification.message !== "" && (
            <PreviewLearningPathContentMetaComponent
              notification={content.data.notification}
            />
          )}
      </div>
    );
  }

  function handleOnCancel() {
    setIsEditVisible(false);
    if (createLearningPathContentRef.current) {
      createLearningPathContentRef.current.form.resetFields();
    }
  }

  function handleOnEditContent() {
    if (!(learningPath && createLearningPathContentRef.current)) {
      return;
    }

    createLearningPathContentRef.current.form
      .validateFields()
      .then((res: CreateLearningPathContentInterface) => {
        const index = learningPath.content.findIndex(
          (c) => c.sort === content.sort,
        );

        if (index === -1) {
          return;
        }

        switch (content.learningPathType) {
          case LearningPathTypeEnum.TIME_BASED:
            learningPath.content[index] = {
              ...content,
              contentType: res.content.contentType ?? content.contentType,
              contentUid: res.content.value ?? content.contentUid,
              data: {
                ...content.data,
                notification: {
                  title: res.title ?? content.data.notification.title,
                  message: res.message ?? content.data.notification.message,
                },
              },
            };
            break;
          case LearningPathTypeEnum.PROGRESS_BASED:
            learningPath.content[index] = {
              ...content,
              contentType: res.content.contentType ?? content.contentType,
              contentUid: res.content.value ?? content.contentUid,
            };
            break;
        }

        onEdit(learningPath);
        setIsSubmitting(true);
      });
  }

  function handleOnEditDelay(value: number) {
    if (
      !learningPath ||
      content.learningPathType !== LearningPathTypeEnum.TIME_BASED
    ) {
      return;
    }

    const index = learningPath.content.findIndex(
      (c) => c.sort === content.sort,
    );
    if (index !== -1) {
      learningPath.content[index] = {
        ...content,
        data: {
          ...content.data,
          minutesAfterStarted: daysToMinutes(value),
        },
      } as TimeBasedContentResponse;
    }

    onEdit(learningPath);
  }

  function handleOnDeleteContent() {
    if (!learningPath) {
      return;
    }

    const index = learningPath.content.findIndex(
      (c) => c.sort === content.sort,
    );
    if (index !== -1) {
      learningPath.content.splice(index, 1);
    }

    onEdit(learningPath);
  }

  function getActions(): ContentActionProps[] {
    return [
      {
        icon: <ContextActionIconComponent action={"open"} />,
        label: Capitalize(t("common.manage")),
        onClick: () => {
          if (!playbook) {
            return history.push(RoutesEnum.EDIT_MODE);
          }
          history.push(
            RoutesEnum.EDIT_MODE_PLAYBOOK.replace(
              ":playbookUid",
              playbook?.playbookUid,
            ),
          );
        },
      },
      {
        icon: <ContextActionIconComponent action={"edit"} />,
        label: Capitalize(t("common.edit")),
        onClick: () => setIsEditVisible(true),
      },
      {
        icon: <ContextActionIconComponent action={"delete"} />,
        label: Capitalize(t("common.delete")),
        onClick: handleOnDeleteContent,
      },
    ];
  }
};

export default PreviewLearningPathContentComponent;
