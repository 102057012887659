import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Typography } from "antd";
import PlaybookUserProgressComponent from "../content-user-progress/user-progress-types/playbook-user-progress.component";
import styles from "./user-progress.module.css";
import {
  selectMostRecentPublishedPlaybooks,
  selectPlaybooksFilteredByUserGroup,
} from "../../../core/redux/selectors/content/content.selector";
import { useSelector } from "react-redux";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import {
  selectCurrentlySelectedUserGroup,
  selectUserGroups,
} from "../../../core/redux/selectors/user-group/user-group.selector";

const RecentlyPublishedComponent = () => {
  const { t } = useTranslation();
  const publishedPlaybooks = useSelector(selectMostRecentPublishedPlaybooks);
  const groups: UserGroupModel[] = useSelector(selectUserGroups);
  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );
  const copiedPublishedPlaybooks = [...publishedPlaybooks];
  const mostRecent = copiedPublishedPlaybooks.splice(0, 3);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const selectGroupFromSelected = groups.filter(
    (group) => group.title === selectedGroup,
  );
  const filteredPlaybooks =
    selectedGroup !== ""
      ? selectPlaybooksFilteredByUserGroup(
          mostRecent,
          selectGroupFromSelected.length > 0
            ? selectGroupFromSelected[0]
            : undefined,
        )
      : mostRecent;

  useEffect(() => {
    return setSelectedGroup(currentlySelectedUserGroup);
  }, [currentlySelectedUserGroup]);

  return (
    <div className={styles.pcup_container}>
      <Typography.Text className={styles.pcup_heading}>
        {Capitalize(t("common.recently")) + " " + t("content-state.PUBLISHED")}
      </Typography.Text>
      <div className={styles.pcup_wrapper} style={{ overflow: "auto" }}>
        {filteredPlaybooks && filteredPlaybooks.length > 0 ? (
          filteredPlaybooks.map((playbook, index) => (
            <PlaybookUserProgressComponent
              key={index}
              playbookUid={playbook.playbookUid}
            />
          ))
        ) : (
          <div className={styles.pcup_card_empty}>
            <Typography.Text
              type={"secondary"}
              className={styles.pcup_card_empty_text}
            >
              {t("screens.empty-state.PUBLISHED")}
            </Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentlyPublishedComponent;
