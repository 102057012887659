import React, { CSSProperties } from "react";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { grey } from "@ant-design/colors";
import { useTranslation } from "react-i18next";
import { useUserProgress } from "../../../../core/hooks/user-progress/user-progress.hook";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import ContentUserProgressBoilerplate from "../content-user-progress.boilerplate";
import ContentUserProgressBar from "../content-user-progress.bar";
import ContentProgressMeta from "../content-progress.meta";
import ChapterModel from "../../../../core/models/content/chapter.model";
import Moment from "moment";
import { useSelector } from "react-redux";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import { selectContentACLs } from "../../../../core/redux/selectors/content/content.selector";
import { selectUserProgressFromUserGroupUsers } from "../../../../core/redux/selectors/user-progress/user-progress";

interface Props {
  chapter: ChapterModel;
  size?: "small" | "middle" | "large";
  style?: CSSProperties;
  showAction?: boolean;
}

const ChapterUserProgressComponent = (props: Props) => {
  const { t } = useTranslation();
  const { chapter } = props;
  const history = useHistory();
  const userGroupAcl = useSelector((state) =>
    selectContentACLs(state, ContentTypesEnum.CHAPTER, chapter.chapterUid),
  );

  if (!chapter) {
    return null;
  }

  const { data: chapterProgress, ...queryInfo } = useUserProgress(
    chapter.playbookUid,
    chapter.chapterUid,
  );

  const userProgressFromUserGroupUsers =
    selectUserProgressFromUserGroupUsers(chapterProgress);

  return (
    <ContentUserProgressBoilerplate
      size={props.size}
      style={props.style}
      title={chapter.title}
      description={chapter.description}
      action={renderAction()}
      progressBar={renderProgressBar()}
      suffix={renderSuffix()}
    />
  );

  function renderProgressBar() {
    return (
      <ContentUserProgressBar
        isLoading={queryInfo.isLoading}
        userProgress={
          chapterProgress
            ? userProgressFromUserGroupUsers.length > 0
              ? userProgressFromUserGroupUsers
              : chapterProgress.users
            : []
        }
        onClick={(state) => {
          if (!chapter) {
            return;
          }

          const path = RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_CHAPTER;
          history.push(
            `${path
              .replace(":playbookUid", chapter.playbookUid)
              .replace(":chapterUid", chapter.chapterUid)}?state=${state}`,
          );
        }}
      />
    );
  }

  function renderAction() {
    if (props.showAction === undefined) {
      return;
    }

    if (props.showAction) {
      return <UpOutlined style={{ color: grey[1] }} />;
    } else {
      return <DownOutlined style={{ color: grey[1] }} />;
    }
  }

  function renderSuffix() {
    if (!chapter || !props.showAction) {
      return;
    }

    const total = chapterProgress
      ? userProgressFromUserGroupUsers.length > 0
        ? userProgressFromUserGroupUsers.length
        : chapterProgress.users.length
      : 0;

    return (
      <>
        <ContentProgressMeta
          icon={"publish"}
          label={Moment(chapter.publishedAt).format("DD-MM-YY")}
        />

        <ContentProgressMeta
          icon={"user"}
          label={
            (queryInfo.isFetched ? total : 0).toString() +
            " " +
            t("containers.users.key_plural")
          }
        />

        {userGroupAcl.length > 0 && (
          <Tooltip
            title={
              Capitalize(t("common.assigned_to")) +
              ":" +
              userGroupAcl.map((g) => " " + g)
            }
          >
            <div>
              <ContentProgressMeta
                label={t("common.assigned")}
                icon={"assigned"}
              />
            </div>
          </Tooltip>
        )}
      </>
    );
  }
};

export default ChapterUserProgressComponent;
