import { useEffect, useState } from "react";
import { DateRangeParamsInterface } from "../interfaces/date-range-params.interface";
import { DatePeriodType } from "../enums/date-period.enum";
import { BarGraphData } from "../../views/components/graphs/atoms/bar-graph";
import { DateRange } from "../enums/date-range.enum";
import Moment from "moment";

const thisWeekProps = {
  filter: DatePeriodType.DAY,
  startDate: Moment().startOf("isoWeek").toDate(),
  endDate: Moment().endOf("isoWeek").toDate(),
};
const lastWeekProps = {
  filter: DatePeriodType.DAY,
  startDate: Moment().subtract(1, "week").startOf("isoWeek").toDate(),
  endDate: Moment().subtract(1, "week").endOf("isoWeek").toDate(),
};
const thisMonthProps = {
  filter: DatePeriodType.WEEK,
  startDate: Moment().startOf("month").toDate(),
  endDate: Moment().endOf("month").toDate(),
};
const lastMonthProps = {
  filter: DatePeriodType.WEEK,
  startDate: Moment().subtract(1, "month").startOf("month").toDate(),
  endDate: Moment().subtract(1, "month").endOf("month").toDate(),
};
const allTime = {
  filter: DatePeriodType.ALL_TIME,
};

export default function useDateRangeHelper(range: DateRange) {
  const [props, setProps] =
    useState<DateRangeParamsInterface>(getDateRangeProps);

  function getDateRangeProps() {
    switch (range) {
      case DateRange.THIS_WEEK:
        return thisWeekProps;
      case DateRange.THIS_MONTH:
        return thisMonthProps;
      case DateRange.LAST_WEEK:
        return lastWeekProps;
      case DateRange.LAST_MONTH:
        return lastMonthProps;
      case DateRange.ALL_TIME:
        return allTime;
    }
  }

  function formatData(date: Date, amount: number): BarGraphData {
    let horizontal = Moment(date).format("dd");

    if (range === DateRange.LAST_MONTH || range === DateRange.THIS_MONTH) {
      const moment = Moment(date);
      const endOfMonth = Moment(date).endOf("month");

      horizontal = moment.format("D");
      horizontal += "-";

      const endOfWeekMoment = moment.add(6, "days");

      if (endOfWeekMoment.isBefore(endOfMonth, "day")) {
        horizontal += endOfWeekMoment.format("D");
      } else {
        horizontal += endOfMonth.format("D");
      }
    }

    return {
      horizontal,
      vertical: amount,
    };
  }

  useEffect(() => {
    setProps(getDateRangeProps);
  }, [range]);

  return { props, formatData };
}
