import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CategoryCreateInterface } from "../../../core/interfaces/category-create.interface";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Form, Input } from "antd";
import AppConfig from "../../../constants/config/app.config";
import PlaybooksSelectComponent, {
  SelectedPlaybookUidsType,
} from "./select/playbooks-select.component";
import CharacterCounter from "../form-builder/components/character-counter";
import { Category } from "../../../core/api/primio/primioSchemas";

export interface CreateCategoryFormRef {
  form: FormInstance<CategoryCreateInterface>;
  focusTitle: () => void;
}

interface OwnProps {
  category: Category | undefined;
}

type Props = OwnProps;

const CreateCategoryForm = forwardRef(
  (props: Props, ref: Ref<CreateCategoryFormRef>) => {
    const [t] = useTranslation();
    const { category } = props;
    const [form] = useForm<CategoryCreateInterface>();
    const [title, setTitle] = useState<string>(category ? category.title : "");
    const [playbookUids, setPlaybookUids] = useState<
      SelectedPlaybookUidsType[]
    >(
      category && category.playbooks
        ? category.playbooks.map((p) => ({
            playbookUid: p.playbookUid,
          }))
        : [],
    );
    const titleInput = useRef<typeof Input | any>(null);

    useImperativeHandle(ref, () => ({ form, focusTitle }));

    const titleMaxLength = AppConfig.categoryTitleMaxLength;

    function focusTitle() {
      if (titleInput.current) {
        titleInput.current.focus();
      }
    }

    useEffect(() => {
      form.setFields([{ name: "playbookUids", value: playbookUids }]);
    }, [playbookUids]);

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          initialValue={title}
          name={"title"}
          label={Capitalize(t("form.card.title.label"))}
          rules={getRules()}
        >
          <Input
            ref={titleInput}
            value={title}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={title.length}
                maxLength={titleMaxLength}
              />
            }
            maxLength={titleMaxLength}
            style={{ width: "80%" }}
            autoFocus
            placeholder={t("form.placeholders.type-of_x", {
              item: t("form.card.title.label"),
              field: t("containers.categories.key"),
            })}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          initialValue={playbookUids}
          name={"playbookUids"}
          label={Capitalize(t("containers.modules.key_plural"))}
        >
          <div style={{ width: "80%" }}>
            <PlaybooksSelectComponent
              playbookUids={playbookUids.map((p) => p.playbookUid)}
              onChange={setPlaybookUids}
            />
          </div>
        </Form.Item>
      </Form>
    );

    function getRules() {
      return [
        {
          max: titleMaxLength,
          message: Capitalize(
            t("errors.max-length", {
              field: t("form.card.title.label"),
              amount: titleMaxLength,
            }),
          ),
        },
        {
          required: true,
          message: t("errors.required", {
            item: Capitalize(t(`form.card.title.label`)),
          }),
        },
      ];
    }
  },
);

CreateCategoryForm.displayName = "CreateCategoryForm";

export default CreateCategoryForm;
