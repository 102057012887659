import React, { CSSProperties } from "react";
import { ContentProgressEnum } from "../../../../core/enums/content-progress.enum";
import { Col, Row, Typography } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import ContentProgressStateIconComponent from "../../icons/content-progress-state-icon.component";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";

interface Props {
  title: string;
  contentProgress: ContentProgressEnum;
  isExpanded?: boolean;
  style?: CSSProperties;
  contentType: ContentTypesEnum;
}

const ContentCompletionHeaderComponent = ({
  contentType,
  title,
  contentProgress,
  isExpanded,
  style,
}: Props) => {
  return (
    <Row
      align={"middle"}
      justify={"space-between"}
      style={{ flexWrap: "nowrap", columnGap: ".8rem" }}
    >
      <Col>
        {contentType === ContentTypesEnum.PLAYBOOK ? (
          <ContentProgressStateIconComponent
            contentProgress={contentProgress}
          />
        ) : (
          <ContentProgressStateIconComponent
            contentProgress={contentProgress}
            width={12}
            height={12}
          />
        )}
      </Col>

      <Col style={{ flexGrow: 1, overflow: "hidden" }}>
        <Typography.Text ellipsis style={{ ...style, margin: 0 }}>
          {TranslateDefaults(title)}
        </Typography.Text>
      </Col>

      {isExpanded && (
        <Col>
          <CaretDownOutlined rotate={isExpanded ? 180 : 0} />
        </Col>
      )}
    </Row>
  );
};

export default ContentCompletionHeaderComponent;
