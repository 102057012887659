import React, { CSSProperties } from "react";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Tooltip } from "antd";
import { useUserProgress } from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { Link, useHistory } from "react-router-dom";
import ContentUserProgressBoilerplate from "../content-user-progress.boilerplate";
import ContentUserProgressBar from "../content-user-progress.bar";
import ContentProgressMeta from "../content-progress.meta";
import HeartIconComponent from "../../icons/heart-icon.component";
import Moment from "moment";
import SharedContentMeta from "../../shared-content/shared-content-meta/shared-content-meta";
import { selectUserProgressFromUserGroupUsers } from "../../../../core/redux/selectors/user-progress/user-progress";
import { useGetPlaybook } from "../../../../core/api/primio/primioComponents";

interface Props {
  playbookUid: string;
  size?: "small" | "middle" | "large";
  style?: CSSProperties;
  showAction?: boolean;
  showSuffixAction?: boolean;
}

const PlaybookUserProgressComponent = (props: Props) => {
  const { t } = useTranslation();

  const { playbookUid } = props;
  const { data: playbook } = useGetPlaybook({
    pathParams: { playbookUid },
    queryParams: { excludeMedia: true },
  });
  const { data: userProgress, ...queryInfo } = useUserProgress(playbookUid);
  const userProgressFromUserGroupUsers =
    selectUserProgressFromUserGroupUsers(userProgress);
  const history = useHistory();

  if (!playbook) return null;

  return (
    <ContentUserProgressBoilerplate
      size={props.size}
      style={props.style}
      title={playbook.title}
      description={playbook.description}
      action={renderAction()}
      progressBar={renderProgressBar()}
      suffix={renderSuffix()}
      suffixAction={renderSuffixAction()}
    />
  );

  function renderProgressBar() {
    return (
      <ContentUserProgressBar
        isLoading={queryInfo.isLoading}
        userProgress={
          userProgress
            ? userProgressFromUserGroupUsers.length > 0
              ? userProgressFromUserGroupUsers
              : userProgress.users
            : []
        }
        onClick={(state) => {
          if (!userProgress) {
            return;
          }

          const path = RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_PLAYBOOK;
          history.push(
            `${path.replace(":playbookUid", playbookUid)}?state=${state}`,
          );
        }}
      />
    );
  }

  function renderAction() {
    if (!props.showAction) {
      return;
    }

    return <HeartIconComponent uid={playbookUid} />;
  }

  function renderSuffix() {
    if (!playbook) {
      return;
    }

    const total = userProgress
      ? userProgressFromUserGroupUsers.length > 0
        ? userProgressFromUserGroupUsers.length
        : userProgress.users.length
      : 0;

    return (
      <>
        <ContentProgressMeta
          icon={"publish"}
          label={Moment(playbook.publishedAt).format("DD-MM-YY")}
        />

        <ContentProgressMeta
          icon={"user"}
          label={
            (queryInfo.isFetched ? total : 0).toString() +
            " " +
            t("containers.users.key_plural")
          }
        />

        {playbook.userGroupAcl.length > 0 && (
          <Tooltip
            title={
              Capitalize(t("common.assigned_to")) +
              ":" +
              playbook.userGroupAcl.map((g) => " " + g)
            }
          >
            <div>
              <ContentProgressMeta
                label={t("common.assigned")}
                icon={"assigned"}
              />
            </div>
          </Tooltip>
        )}

        {playbook.apiKeyClientUid && (
          <div>
            <SharedContentMeta apiKeyClientUid={playbook.apiKeyClientUid} />
          </div>
        )}
      </>
    );
  }

  function renderSuffixAction() {
    if (!props.showSuffixAction) {
      return;
    }

    return (
      <Link
        style={{ fontSize: "1.3rem" }}
        to={{
          pathname: `/activity/published-content/playbook/${
            playbookUid as string
          }`,
        }}
      >
        {Capitalize(t("common.show") + " " + t("common.details"))}
      </Link>
    );
  }
};

PlaybookUserProgressComponent.defaultProps = {
  showAction: true,
  showSuffixAction: true,
};

export default PlaybookUserProgressComponent;
