import React from "react";
import { Button, Card, Divider } from "antd";
import { UserStatusEnum } from "../../../../core/enums/user-status.enum";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { connect } from "react-redux";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { selectUsers } from "../../../../core/redux/selectors/user/user.selector";
import { useInvitations } from "../../../../core/hooks/invitation/invitation.hook";
import { useLatestActivities } from "../../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import BlockStatisticsComponent from "../../statistics/block-statistics.component";
import UserModel from "../../../../core/models/user/user.model";
import styles from "./app-metrics.module.css";

interface StateProps {
  users: UserModel[];
}

type Props = StateProps;

const UserStatisticsComponent = ({ users }: Props) => {
  const { t } = useTranslation();
  const { data: invitations, isFetching: invitationsIsFetching } =
    useInvitations();
  const { data: latestActivity, isFetching: latestActivityIsFetching } =
    useLatestActivities();
  const history = useHistory();

  const confirmedUsers: UserModel[] = users.filter(
    (u) => u.user_status === UserStatusEnum.CONFIRMED,
  );
  const unConfirmedUsers: UserModel[] = users.filter(
    (u) => u.user_status !== UserStatusEnum.CONFIRMED,
  );
  const sentInvitations = invitations?.filter((i) => i.sentAt !== undefined);

  return (
    <div style={{ width: "50%" }}>
      <Card className={styles.card_wrapper}>
        <div className={styles.card_container}>
          <BlockStatisticsComponent
            value={
              sentInvitations ? sentInvitations.length : unConfirmedUsers.length
            }
            title={
              t("screens.notifications.state.SENT") +
              " " +
              t("containers.invitations.key_plural")
            }
            loading={invitationsIsFetching && !invitations}
          />
          <Divider type={"vertical"} className={styles.blocks_divider} />
          <BlockStatisticsComponent
            value={confirmedUsers.length}
            title={
              t("common.registered") + " " + t("containers.users.key_plural")
            }
          />
          <Divider type={"vertical"} className={styles.blocks_divider} />
          <BlockStatisticsComponent
            value={latestActivity ? latestActivity.length : 0}
            title={t("translations:common.active_x", {
              item: t("containers.users.key_plural"),
            })}
            loading={latestActivityIsFetching && !latestActivity}
          />
        </div>
      </Card>

      <Button
        type={"text"}
        className={styles.button}
        onClick={() => history.push(RoutesEnum.INVITATIONS + "?state=PENDING")}
      >
        {Capitalize(t("common.show")) +
          " " +
          t("common.all") +
          " " +
          t("containers.invitations.key_plural")}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
});

export default connect(mapStateToProps)(UserStatisticsComponent);
