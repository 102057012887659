import React from "react";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import styles from "./content-action.module.css";

export interface ContentActionProps {
  icon: JSX.Element;
  label: string;
  hideLabel?: boolean;
  onClick?: () => void;
}

const ContentActionComponent = (props: ContentActionProps) => {
  const [t] = useTranslation();
  const parent =
    props.label === Capitalize(t("common.delete"))
      ? styles.parent_delete
      : styles.parent;
  const action =
    props.label === Capitalize(t("common.delete"))
      ? styles.action_delete
      : styles.action;
  const color =
    props.label === Capitalize(t("common.delete"))
      ? "var(--secondary800-color)"
      : "var(--primary-color)";

  return (
    <div className={action}>
      <div className={parent} onClick={props.onClick}>
        {props.icon}
      </div>
      {!props.hideLabel && (
        <Typography.Text className={styles.label} style={{ color }}>
          {props.label}
        </Typography.Text>
      )}
    </div>
  );
};

ContentActionComponent.defaultProps = {
  hideLabel: false,
};

export default ContentActionComponent;
