import VersionedObject from "../interfaces/versioned-object.interface";

const getObjectWithHighestVersion = <Type extends VersionedObject>(
  objects: Type[],
): Type | null => {
  if (objects.length === 0) {
    return null;
  }

  let highestVersionObject = objects[0];

  objects.forEach((currentObject) => {
    if (currentObject.version > highestVersionObject.version) {
      highestVersionObject = currentObject;
    }
  });

  return highestVersionObject;
};

export default getObjectWithHighestVersion;
