import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { selectRequesting } from "../../../../core/redux/selectors/requesting/requesting.selector";
import { PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { TimeBasedContentResponse } from "../../../../core/models/learning-path/time-based-content.model";
import { LearningPathTypeEnum } from "../../../../core/enums/learning-path-state.enum";
import { useSelector } from "react-redux";
import CreateLearningPathContentForm, {
  CreateLearningPathContentInterface,
  CreateLearningPathContentRef,
} from "../../forms/create-learning-path-content.form";
import LearningPathModel from "../../../../core/models/learning-path/learning-path.model";
import LearningPathAction from "../../../../core/redux/stores/learning-path/learning-path.action";
import daysToMinutes from "../../../../core/utils/days-to-minutes.utils";
import ProgressBasedContentModel from "../../../../core/models/learning-path/progress-based-content.model";

interface OwnProps {
  learningPath: LearningPathModel;
  onEdit: (learningPath: LearningPathModel) => void;
  disabled?: boolean;
}

type Props = OwnProps;

const CreateLearningPathContentComponent = (props: Props) => {
  const { t } = useTranslation();
  const { learningPath, onEdit, disabled = false } = props;
  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const createLearningPathContentRef =
    useRef<CreateLearningPathContentRef>(null);
  const requesting = useSelector((state) =>
    selectRequesting(state, [LearningPathAction.REQUEST_EDIT_LEARNING_PATH]),
  );

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        handleOnCancel();
      }
    }
  }, [requesting]);

  return (
    <div style={{ display: "flex" }}>
      {renderCreateIcon()}

      {!isCreateVisible ? (
        renderCreateButton()
      ) : (
        <CreateLearningPathContentForm
          ref={createLearningPathContentRef}
          onOk={handleOnOk}
          onCancel={handleOnCancel}
          learningPath={learningPath}
          userGroups={learningPath.userGroups}
        />
      )}
    </div>
  );

  function renderCreateIcon() {
    if (learningPath.content.length >= 14) {
      return (
        <Button
          shape={"circle"}
          type={"primary"}
          style={{ marginRight: "2rem" }}
        >
          <SmileOutlined />
        </Button>
      );
    }

    return (
      <Button
        shape={"circle"}
        type={"primary"}
        style={{ marginRight: "2rem" }}
        disabled={disabled}
        onClick={() => setIsCreateVisible(true)}
      >
        <PlusOutlined />
      </Button>
    );
  }

  function renderCreateButton() {
    if (learningPath.content.length >= 14) {
      return (
        <Typography.Text>
          {Capitalize(
            "max. " +
              t("common.amount", { item: t("form.items.content.linked") }) +
              " (14)",
          )}
        </Typography.Text>
      );
    }

    return (
      <Button
        type={"text"}
        style={{ color: "var(--primary-color)" }}
        disabled={disabled}
        onClick={() => setIsCreateVisible(true)}
      >
        {learningPath.content.length === 0
          ? Capitalize(
              t("common.add-to", { item: t("containers.learning-path.key") }),
            )
          : Capitalize(t("common.add-content"))}
      </Button>
    );
  }

  function handleOnCancel() {
    setIsCreateVisible(false);
    if (createLearningPathContentRef.current) {
      createLearningPathContentRef.current.form.resetFields();
    }
  }

  function handleOnOk() {
    if (createLearningPathContentRef.current) {
      createLearningPathContentRef.current.form
        .validateFields()
        .then((res: CreateLearningPathContentInterface) => {
          let minutesToAdd = 0;

          switch (learningPath.learningPathType) {
            case LearningPathTypeEnum.TIME_BASED:
            case LearningPathTypeEnum.SCHEDULE_BASED:
              learningPath.content.map((c) => {
                if (c.learningPathType === LearningPathTypeEnum.TIME_BASED) {
                  minutesToAdd += c.data.minutesAfterStarted;
                }
              });

              // TimeBased LearningPaths start immediately, so don't display or create a delay
              learningPath.content = [
                ...learningPath.content,
                {
                  learningPathUid: learningPath.learningPathUid,
                  contentType: res.content.contentType,
                  contentUid: res.content.value,
                  sort: learningPath.content.length + 1,
                  data: {
                    minutesAfterStarted:
                      minutesToAdd +
                      daysToMinutes(
                        learningPath.content.length === 0 ? 0 : 7, //set to 1 week as default
                      ),
                    notification: {
                      title: res.title || "",
                      message: res.message || "",
                    },
                  },
                },
              ] as TimeBasedContentResponse[];
              break;
            case LearningPathTypeEnum.PROGRESS_BASED:
              learningPath.content = [
                ...learningPath.content,
                {
                  learningPathUid: learningPath.learningPathUid,
                  contentType: res.content.contentType,
                  contentUid: res.content.value,
                  sort: learningPath.content.length + 1,
                },
              ] as ProgressBasedContentModel[];
              break;
          }

          onEdit(learningPath);
          setIsSubmitting(true);
        });
    }
  }
};

export default CreateLearningPathContentComponent;
