import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createApiKeyClientMutation,
  createApiKeyMutation,
  deleteApiKeyClientMutation,
  deleteApiKeyMutation,
  getApiKey,
  getApiKeyClient,
  getApiKeyClients,
  getApiKeys,
  updateApiKeyMutation,
  updateApiKeyStateMutation,
} from "./api-key.api";
import {
  CreateApiKeyInterface,
  EditApiKeyInterface,
  UpdateApiKeyState,
} from "../../interfaces/create-api-key.interface";
import HttpErrorResponseModel from "../../models/http-error-response.model";
import ApiKeyModel from "../../models/api-key/api-key.model";
import ApiKeyClientModel from "../../models/api-key/api-key-client.model";
import { Modal } from "antd";
import { AxiosResponse } from "axios";

export const useApiKeys = () =>
  useQuery<any, HttpErrorResponseModel, ApiKeyModel[]>(["apiKey"], getApiKeys);

export const useApiKey = (key: string) =>
  useQuery<any, HttpErrorResponseModel, ApiKeyModel>(["apiKey", key], () =>
    getApiKey(key),
  );

export const useCreateApiKeyMutation = () =>
  useMutation<any, HttpErrorResponseModel, CreateApiKeyInterface>({
    mutationFn: createApiKeyMutation,
  });

export const useUpdateApiKeyMutation = (key: string) => {
  return useMutation<any, HttpErrorResponseModel, any>(
    ["apiKey"],
    (data: EditApiKeyInterface) => updateApiKeyMutation(key, data),
  );
};

/**
 * Delete Api Key
 * @returns -
 */
export const useDeleteApiKeyMutation = () =>
  useMutation<any, HttpErrorResponseModel, string>({
    mutationFn: deleteApiKeyMutation,
  });

export const useApiKeyClients = () =>
  useQuery<any, HttpErrorResponseModel, ApiKeyClientModel[]>(
    ["apiKeyClient"],
    getApiKeyClients,
  );

export const useApiKeyClient = (key: string) =>
  useQuery<any, HttpErrorResponseModel, ApiKeyClientModel>(
    ["apiKeyClient", key],
    () => getApiKeyClient(key),
  );

export const useCreateApiKeyClientMutation = () =>
  useMutation({
    mutationFn: createApiKeyClientMutation,
  });

export const useUpdateApiKeyStateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<void>,
    HttpErrorResponseModel,
    UpdateApiKeyState
  >({
    mutationFn: updateApiKeyStateMutation,
    onSuccess: (data, variables) => {
      void queryClient.invalidateQueries(["apiKey", variables.key]);
    },
    onError: (error) => {
      Modal.error({
        content: error.message,
      });
    },
  });
};

export const useDeleteApiKeyClientMutation = () =>
  useMutation<string, HttpErrorResponseModel, any>({
    mutationFn: deleteApiKeyClientMutation,
  });
