import { useMutation, useQuery } from "@tanstack/react-query";
import qrCodeApi from "./qr-code.api";
import { queryClient } from "../../providers/app.provider";
import QrCodeModel from "../../models/qr-code/qr-code.model";
import { ContentStatesEnum } from "../../enums/content-states.enum";
import { v4 as uuidv4 } from "uuid";

const key = "qr-codes";

export const useQrCodes = () => useQuery([key], qrCodeApi.getAll);

export const useQrCode = (qrCodeUid: string) =>
  useQuery([key, qrCodeUid], () => qrCodeApi.get(qrCodeUid));

export const useCreateQrCode = () =>
  useMutation({
    mutationFn: qrCodeApi.create,
    onMutate: async (qrCode) => {
      await queryClient.cancelQueries([key]);
      let previousQrCodes: QrCodeModel[] | undefined =
        await queryClient.getQueryData([key]);

      if (!previousQrCodes) {
        previousQrCodes = [];
      }

      const optimisticQrCode = new QrCodeModel({
        description: qrCode.description ?? "Test",
        data: qrCode.data ?? {
          playbookUid: "",
        },
        qrCodeUid: uuidv4(),
        isPublic: qrCode.isPublic ?? false,
        openInApp: qrCode.openInApp ?? false,
        createdAt: qrCode.createdAt ?? new Date().toString(),
        contentState: qrCode.contentState ?? ContentStatesEnum.DRAFT,
        contentRestrictive: qrCode.contentRestrictive ?? false,
      });

      queryClient.setQueryData([key], [...previousQrCodes, optimisticQrCode]);

      return { optimisticQrCode };
    },
    onSuccess: async (data, variables, context: any) => {
      let previousQrCodes: QrCodeModel[] | undefined =
        await queryClient.getQueryData([key]);

      if (!previousQrCodes) {
        previousQrCodes = [context.optimisticQrCode];
      }

      queryClient.setQueryData(
        [key],
        previousQrCodes.map((qrCode) =>
          qrCode.qrCodeUid === context.optimisticQrCode.qrCodeUid
            ? { ...qrCode, qrCodeUid: data }
            : qrCode,
        ),
      );
    },
    onError: async (error, variables, context) => {
      const previousQrCodes: QrCodeModel[] | undefined =
        await queryClient.getQueryData([key]);

      queryClient.setQueryData(
        [key],
        previousQrCodes?.filter(
          (qrCode) => qrCode.qrCodeUid !== context.optimisticQrCode.qrCodeUid,
        ),
      );
    },
  });

export const useEditQrCode = () =>
  useMutation({
    mutationFn: qrCodeApi.edit,
    onMutate: async (variables) => {
      const { qrCode, qrCodeUid } = variables;
      await queryClient.cancelQueries([key]);
      let previousQrCodes: QrCodeModel[] | undefined =
        await queryClient.getQueryData([key]);

      if (!previousQrCodes) {
        previousQrCodes = [];
      }

      const previousQrCode = previousQrCodes.find(
        (q) => q.qrCodeUid === qrCodeUid,
      );

      const optimisticQrCode = new QrCodeModel({
        qrCodeUid,
        description: qrCode.description ?? "Test",
        data: qrCode.data ?? {
          playbookUid: "",
        },
        isPublic: qrCode.isPublic ?? false,
        openInApp: qrCode.openInApp ?? false,
        createdAt: qrCode.createdAt ?? new Date().toString(),
        contentState: qrCode.contentState ?? ContentStatesEnum.DRAFT,
        contentRestrictive: qrCode.contentRestrictive ?? false,
      });

      queryClient.setQueryData(
        [key],
        previousQrCodes.map((qrCode) =>
          qrCode.qrCodeUid === qrCodeUid ? optimisticQrCode : qrCode,
        ),
      );

      return { previousQrCode };
    },
    onError: async (error, variables, context: any) => {
      const previousQrCodes: QrCodeModel[] | undefined =
        await queryClient.getQueryData([key]);

      queryClient.setQueryData(
        [key],
        previousQrCodes?.map((qrCode) =>
          qrCode.qrCodeUid === context.previousQrCode.qrCodeUid
            ? context.previousQrCode
            : qrCode,
        ),
      );
    },
  });

export const useDeleteQrCode = () =>
  useMutation({
    mutationFn: qrCodeApi.delete,
    onMutate: async (qrCodeUid) => {
      await queryClient.cancelQueries([key]);
      const previousQrCodes: QrCodeModel[] | undefined =
        await queryClient.getQueryData([key]);

      queryClient.setQueryData(
        [key],
        previousQrCodes?.filter((p) => p.qrCodeUid !== qrCodeUid),
      );

      return { previousQrCodes };
    },
    onError: (err, deletedQrCodeUid, context: any) =>
      queryClient.setQueryData([key], context.previousQrCodes),
    onSettled: () => queryClient.invalidateQueries([key]),
  });

export const usePublishQrCode = () =>
  useMutation({
    mutationFn: qrCodeApi.publish,
    onSettled: () => queryClient.invalidateQueries([key]),
  });

export const useDraftQrCode = () =>
  useMutation({
    mutationFn: qrCodeApi.draft,
    onSettled: () => queryClient.invalidateQueries([key]),
  });
