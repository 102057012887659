import React from "react";
import { Layout, Result } from "antd";
import { useTranslation } from "react-i18next";

const NotFoundScreen = () => {
  const { t } = useTranslation();

  return (
    <Layout.Content data-testid="not-found-screen">
      <Result subTitle={t("errors.status.404")} status={404} title={404} />
    </Layout.Content>
  );
};

export default NotFoundScreen;
