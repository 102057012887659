import { FilterValue } from "antd/es/table/interface";
import { SorterResult } from "antd/lib/table/interface";
import { Playbook } from "../../../../core/api/primio/primioSchemas";
import { ColumnsType } from "antd/lib/table";
import { Capitalize } from "../../../../core/utils/helper.utils";
import i18next from "i18next";
import { Tag, Typography } from "antd";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import ContentState from "../../../components/content-state/content-state";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import React from "react";
import {
  useGetApiKeyClients,
  useGetCategories,
  useGetUserGroups,
} from "../../../../core/api/primio/primioComponents";
import { GroupTags } from "../../../components/group-tag";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../../core/redux/selectors/user/user.selector";
import UserRoleEnum from "../../../../core/enums/user-role.enum";

export default function useCreatePlaybookColumnType() {
  const { data: categories = [] } = useGetCategories({});
  const { data: userGroups = [] } = useGetUserGroups({
    queryParams: { shouldExcludeUsers: true },
  });
  const { data: apiKeyClients = [] } = useGetApiKeyClients({});
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);

  const create = (
    filteredInfo: Record<string, FilterValue | null>,
    sortedInfo: SorterResult<Playbook>,
  ): ColumnsType<Playbook> => {
    const columns: ColumnsType<Playbook> = [
      {
        key: "title",
        title: Capitalize(i18next.t("form.items.name.label")),
        sortOrder: sortedInfo.columnKey === "title" ? sortedInfo.order : null,
        sorter: (a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (b.title > a.title) {
            return -1;
          }
          return 0;
        },
        render: function renderText(_, data) {
          return <Typography.Text>{data.title}</Typography.Text>;
        },
      },
      {
        key: "contentState",
        title: Capitalize(i18next.t("content-state.state")),
        filteredValue: filteredInfo.contentState || null,
        filters: Object.entries(ContentStatesEnum)
          .filter((i) => i[1] !== "DELETED")
          .map(([key, value]) => ({
            text: Capitalize(i18next.t(`translations:content-state.${key}`)),
            value: value,
          })),
        sortOrder:
          sortedInfo.columnKey === "contentState" ? sortedInfo.order : null,
        sorter: (a, b) => {
          const code = (contentState: ContentStatesEnum) => {
            switch (contentState) {
              case ContentStatesEnum.PUBLISHED:
                return 2;
              case ContentStatesEnum.DRAFT:
                return 1;
              default:
                return 0;
            }
          };

          return (
            code(a.contentState as ContentStatesEnum) -
            code(b.contentState as ContentStatesEnum)
          );
        },
        render: function renderText(_, data) {
          return (
            <ContentState
              contentState={data.contentState as ContentStatesEnum}
            />
          );
        },
      },
      {
        key: "categories",
        title: Capitalize(i18next.t("containers.categories.key_plural")),
        filteredValue: filteredInfo.categories || null,
        filters: categories.map((category) => ({
          text: category.title,
          value: category.categoryUid,
        })),
        width: 250,
        render: function renderText(_, data: Playbook) {
          if (!data.categories) {
            return null;
          }
          return data.categories.map((c, index) => (
            <Link
              key={index}
              to={RoutesEnum.CATEGORIES_DETAIL.replace(":uid", c.categoryUid)}
            >
              <Tag color={"purple"}>{c.title}</Tag>
            </Link>
          ));
        },
      },
      {
        key: "userGroupAcl",
        title: Capitalize(i18next.t("containers.user-groups.key_plural")),
        filteredValue: filteredInfo.userGroupAcl || null,
        filters: userGroups.map((group) => ({
          text: group.groupType.GroupName,
          value: group.groupType.GroupName,
        })),
        width: 250,
        render: (_, data) => <GroupTags groupNames={data.userGroupAcl} />,
      },
      {
        key: "apiKeyClientUid",
        title: Capitalize(i18next.t("common.source")),
        sortOrder:
          sortedInfo.columnKey === "apiKeyClientUid" ? sortedInfo.order : null,
        sorter: (a, b) => {
          if (!a.apiKeyClientUid || !b.apiKeyClientUid) {
            return -1;
          }
          if (a.apiKeyClientUid > b.apiKeyClientUid) {
            return 1;
          }
          if (b.apiKeyClientUid > a.apiKeyClientUid) {
            return -1;
          }
          return 0;
        },
        render: function renderText(_, data: Playbook) {
          const server = apiKeyClients.find(
            (a) => a.apiKeyClientUid === data.apiKeyClientUid,
          );

          if (!server) {
            return;
          }

          const displayName = server.server.split(".")[1];

          return <Typography.Text>{Capitalize(displayName)}</Typography.Text>;
        },
      },
      {
        key: "action",
        render: function showActions(_, data) {
          if (data.apiKeyClientUid) {
            return null;
          }
          return (
            <Link
              to={RoutesEnum.EDIT_MODE_PLAYBOOK.replace(
                ":playbookUid",
                data.playbookUid,
              )}
            >
              {Capitalize(
                i18next.t("common.edit_x", {
                  name: i18next.t("containers.modules.key"),
                }),
              )}
            </Link>
          );
        },
      },
    ];

    if (
      userRole === UserRoleEnum.PRIMIO_SUPPORT ||
      userRole === UserRoleEnum.SYS_ADMIN
    ) {
      columns.unshift({
        key: "playbookUid",
        title: "Uid",
        width: 100,
        render: (playbook: Playbook) => (
          <div style={{ display: "flex" }}>
            <Typography.Text
              title={playbook.playbookUid}
              style={{
                width: 75,
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {playbook.playbookUid}
            </Typography.Text>
            <Typography.Text copyable={{ text: playbook.playbookUid }} />
          </div>
        ),
      });
    }

    return columns;
  };

  return { create };
}
