import ThunkInterface from "../../../interfaces/thunk.interface";
import BaseReducer from "../base/base.reducer";
import UserGroupModel from "../../../models/user-group/user-group.model";
import UserGroupAction from "./user-group.action";

export interface UserGroupReducerInterface {
  groups: UserGroupModel[];
  selectedGroup: string;
}

export default class UserGroupReducer extends BaseReducer<UserGroupReducerInterface> {
  initialState: UserGroupReducerInterface = {
    groups: [],
    selectedGroup: "",
  };

  [UserGroupAction.REQUEST_USER_GROUPS_FINISHED](
    state: UserGroupReducerInterface,
    action: ThunkInterface<UserGroupModel[]>,
  ) {
    return {
      ...state,
      groups: action.payload,
    };
  }

  [UserGroupAction.REQUEST_USER_GROUP_FINISHED](
    state: UserGroupReducerInterface,
    action: ThunkInterface<UserGroupModel>,
  ) {
    return {
      ...state,
      groups: this.addSingleToArray<UserGroupModel>(
        action.payload,
        [...(state.groups as UserGroupModel[])],
        "title",
      ),
    };
  }

  [UserGroupAction.SET_SELECTED_USER_GROUP](
    state: UserGroupReducerInterface,
    action: ThunkInterface<string>,
  ) {
    return {
      ...state,
      selectedGroup: action.payload,
    };
  }
}
