import React from "react";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import { Button, Card, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useHistory } from "react-router-dom";
import BlockStatisticsComponent from "../../statistics/block-statistics.component";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import styles from "./app-metrics.module.css";
import {
  useGetLearningPaths,
  useGetPlaybooks,
} from "../../../../core/api/primio/primioComponents";

const ContentStatisticsComponent = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { data: playbooks = [], isFetching: isFetchingPlaybooks } =
    useGetPlaybooks({ queryParams: { excludeTrainingPlaybooks: true } });
  const { data: learningPaths = [], isFetching: isFetchingLearningPaths } =
    useGetLearningPaths({});

  return (
    <div style={{ marginRight: "2rem", width: "50%" }}>
      <Card className={styles.card_wrapper}>
        <div className={styles.card_container}>
          <BlockStatisticsComponent
            value={learningPaths.length}
            title={t("translations:content.learning-path.key", {
              count: learningPaths.length,
            })}
            loading={isFetchingLearningPaths && learningPaths.length === 0}
          />

          <Divider type={"vertical"} className={styles.blocks_divider} />

          <BlockStatisticsComponent
            value={playbooks.length}
            title={t(`content.${ContentTypesEnum.PLAYBOOK.toLowerCase()}.key`, {
              count: playbooks.length,
            })}
            loading={isFetchingPlaybooks && playbooks.length === 0}
          />
        </div>
      </Card>

      <Button
        type={"text"}
        className={styles.button}
        onClick={() => history.push(RoutesEnum.PUBLISHED_CONTENT)}
      >
        {Capitalize(t("common.show")) +
          " " +
          t("containers.published-content.title")}
      </Button>
    </div>
  );
};

export default ContentStatisticsComponent;
