export default function insert<Type>(
  arr: Array<Type>,
  index: number,
  newItem: Type,
) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
}
