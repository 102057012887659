import React from "react";
import ContentDetailType from "../../../../core/types/content-detail.type";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";

interface Props {
  content: ContentDetailType;
  children: JSX.Element;
}

const DroppableComponent = (props: Props) => {
  const { content } = props;
  return (
    <Droppable
      droppableId={content.getId}
      type={`droppableSubItem-${content.getId}`}
    >
      {(provided: DroppableProvided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableComponent;
