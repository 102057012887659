import React from "react";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { useSelector } from "react-redux";
import { selectRequesting } from "../../../../core/redux/selectors/requesting/requesting.selector";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import ContentDetailType from "../../../../core/types/content-detail.type";

const getItemStyle = (isDragging, draggableStyle) => ({
  cursor: "pointer",
  ...draggableStyle,
});

interface Props {
  index: number;
  content: ContentDetailType;
  children: JSX.Element;
  enableDragAndDrop?: boolean;
}

const DraggableComponent = ({
  index,
  content,
  children,
  enableDragAndDrop = true,
}: Props) => {
  const isSorting = useSelector((state: StoreInterface) =>
    selectRequesting(state, [ContentAction.REQUEST_SORT_CONTENT]),
  );

  return (
    <Draggable
      key={content.getId}
      isDragDisabled={isSorting || !enableDragAndDrop}
      draggableId={content.getId}
      index={index}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableComponent;
