import { ContentStatesEnum } from "../../enums/content-states.enum";
import ContentTypesEnum from "../../enums/content-types.enum";

export interface QrCodeData {
  playbookUid: string;
  chapterUid?: string;
  collectionUid?: string;
  cardUid?: string;
}

export interface PlaybookQrCodeData {
  contentType: ContentTypesEnum.PLAYBOOK;
  playbookUid: string;
}

export interface ChapterQrCodeData {
  contentType: ContentTypesEnum.CHAPTER;
  playbookUid: string;
  chapterUid: string;
}

export interface CollectionQrCodeData {
  contentType: ContentTypesEnum.COLLECTION;
  playbookUid: string;
  chapterUid: string;
  collectionUid: string;
}

export interface CardQrCodeData {
  contentType: ContentTypesEnum.CARD;
  playbookUid: string;
  chapterUid: string;
  collectionUid: string;
  cardUid: string;
}

export interface QrCodeResponse {
  qrCodeUid: string;
  createdAt: string;
  description: string;
  contentRestrictive: boolean;
  isPublic: boolean;
  openInApp: boolean;
  publishNow?: boolean;
  contentState: ContentStatesEnum;
  data: QrCodeData;
}

export default class QrCodeModel {
  qrCodeUid: string;
  createdAt: Date;
  description: string;
  contentRestrictive: boolean;
  isPublic: boolean;
  openInApp: boolean;
  publishNow?: boolean;
  contentState: ContentStatesEnum;
  data:
    | PlaybookQrCodeData
    | ChapterQrCodeData
    | CollectionQrCodeData
    | CardQrCodeData;

  constructor(qrCode: QrCodeResponse) {
    this.qrCodeUid = qrCode.qrCodeUid;
    this.createdAt = new Date(qrCode.createdAt);
    this.description = qrCode.description;
    this.contentRestrictive = qrCode.contentRestrictive;
    this.isPublic = qrCode.isPublic;
    this.openInApp = qrCode.openInApp;
    this.publishNow = qrCode.publishNow;
    this.contentState = qrCode.contentState;

    if (qrCode.data.chapterUid) {
      if (qrCode.data.collectionUid) {
        if (qrCode.data.cardUid) {
          this.data = {
            contentType: ContentTypesEnum.CARD,
            playbookUid: qrCode.data.playbookUid,
            chapterUid: qrCode.data.chapterUid,
            collectionUid: qrCode.data.collectionUid,
            cardUid: qrCode.data.cardUid,
          } as CardQrCodeData;
        } else {
          this.data = {
            contentType: ContentTypesEnum.COLLECTION,
            playbookUid: qrCode.data.playbookUid,
            chapterUid: qrCode.data.chapterUid,
            collectionUid: qrCode.data.collectionUid,
          } as CollectionQrCodeData;
        }
      } else {
        this.data = {
          contentType: ContentTypesEnum.CHAPTER,
          playbookUid: qrCode.data.playbookUid,
          chapterUid: qrCode.data.chapterUid,
        } as ChapterQrCodeData;
      }
    } else {
      this.data = {
        contentType: ContentTypesEnum.PLAYBOOK,
        playbookUid: qrCode.data.playbookUid,
      } as PlaybookQrCodeData;
    }
  }
}
