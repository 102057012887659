import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import SearchMediaModal from "../../modals/search-media.modal";
import styles from "../../media-types/media.module.css";

type Props = {
  visible: boolean;
  onSubmit: (url: string) => void;
  onCancel: () => void;
};

const GiphySearchModal = ({ visible, onSubmit, onCancel }: Props) => {
  const [t] = useTranslation();
  const [url, setUrl] = useState<string>("");
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (url) {
      onSubmit(url);
    }
  }, [url]);

  return (
    <SearchMediaModal
      title={"GIF"}
      visible={visible}
      onSearch={(query: string) => onSearchGiphy(query)}
      onCancel={onCancel}
    >
      {renderData()}
    </SearchMediaModal>
  );

  function onSearchGiphy(query: string) {
    const clientId = "RX3BA9CnDuRSf9FtElEPjxYgTFOJK1gG";
    const url = `https://api.giphy.com/v1/gifs/search?q=${query}&limit=5&api_key=${clientId}`;

    fetch(url)
      .then((e) => {
        return e.json();
      })
      .then((data) => setData(data.data));
  }

  function renderData() {
    return (
      <div className={styles.search_modal_wrapper}>
        {data.length > 0 ? (
          data.map((gif, index) => {
            return (
              <div
                key={index}
                className={styles.search_modal_data_wrapper}
                onClick={() => {
                  setUrl(gif.embed_url);
                  onCancel();
                }}
              >
                <iframe
                  id={gif.id}
                  title={gif.title}
                  className={styles.giphy_media}
                  width={200}
                  src={gif.embed_url}
                  frameBorder={"0"}
                  allowFullScreen
                />
              </div>
            );
          })
        ) : (
          <div className={styles.unsplash_modal_empty}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={Capitalize(
                t("form.placeholders.use-keywords_x", { item: "GIF" }),
              )}
            />
          </div>
        )}
      </div>
    );
  }
};

export default GiphySearchModal;
