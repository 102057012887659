import React from "react";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Capitalize } from "../../../core/utils/helper.utils";
import PageLayoutDetailComponent from "../../components/page-layout/page-layout-detail.component";
import UserActivityOverviewScreen from "../../screens/user-progress/user-activity-overview.screen";

const UserActivityContainer = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <PageLayoutDetailComponent
      container={"activity"}
      title={
        Capitalize(t("containers.users.key_plural")) +
        " " +
        t("containers.activity.key")
      }
      goBack={() => history.push(RoutesEnum.ACTIVITY)}
    >
      <UserActivityOverviewScreen />
    </PageLayoutDetailComponent>
  );
};

export default UserActivityContainer;
