import InvitationModel from "../models/invitation/invitation.model";
import { API_URL } from "../../constants/config/env.config";

const InvitationEndpoints = {
  /**
   * GET all Invitations endpoint.
   * @returns API_URL + /users-invitations
   */
  overview: `${API_URL}/user-invitations`,

  /**
   * GET one Invitation endpoint.
   * @param invitationCode string
   * @returns API_URL + /users-invitations/I23zWE
   */
  detail(invitationCode: string) {
    return `${API_URL}/user-invitations/${invitationCode}`;
  },

  /**
   * POST Invitation bulk endpoint.
   * @returns API_URL + /users-invitations
   */
  createBulk: `${API_URL}/user-invitations/bulk`,

  /**
   * PATCH Invitation endpoint.
   * @param invitation InvitationModel
   * @returns API_URL + /users-invitations/79708f4b-af77-4b23-b720-599ec4c6949f
   */
  edit(invitation: InvitationModel) {
    return `${API_URL}/user-invitations/${invitation.invitationUid}`;
  },

  /**
   * POST endpoint to mark Invitations as sent.
   * @returns API_URL + /users-invitations/send
   */
  sending: `${API_URL}/user-invitations/send`,

  /**
   * DELETE one Invitation endpoint.
   * @param invitation InvitationModel
   * @returns API_URL + /users-invitations/79708f4b-af77-4b23-b720-599ec4c6949f
   */
  delete(invitation: InvitationModel) {
    return `${API_URL}/user-invitations/${invitation.invitationUid}`;
  },
};

export default InvitationEndpoints;
