import MediaModel, { MediaResponse } from "../../../media/media.model";

export interface PictureQuizOptionResponse {
  sort: number;
  isCorrect: boolean;
  media?: MediaResponse;
}

export default class PictureQuizOptionModel {
  sort: number;
  isCorrect: boolean;
  media?: MediaModel;

  constructor(response: PictureQuizOptionResponse) {
    this.sort = response.sort;
    this.isCorrect = response.isCorrect;

    if (response.media) {
      this.media = new MediaModel(response.media);
    }
  }
}
