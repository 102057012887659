import React from "react";
import { ContentActionProps } from "../content-action/content-action.component";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import { selectContentTree } from "../../../core/redux/selectors/content/content.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import PlaybookPreviewComponent from "../playbook-preview/playbook-preview.component";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ChapterDetailModel from "../../../core/models/content/chapter-detail.model";
import CollectionDetailModel from "../../../core/models/content/collection-detail.model";
import PlaybookDetailModel from "../../../core/models/content/playbook-detail.model";
import ContentAction from "../../../core/redux/stores/content/content.action";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import CollectionCardListComponent from "../collection-card-list/collection-card-list.component";
import ContentMetaComponent from "./content-meta.component";
import ContentBoilerplate from "./content.boilerplate";
import AppConfig from "../../../constants/config/app.config";
import ContentType from "../../../core/types/content.type";
import styles from "./content.module.css";

export interface Props {
  content: ContentType;
  onClickShow?: () => void;
}

const ContentComponent = (props: Props) => {
  const [t] = useTranslation();
  const { content, onClickShow } = props;
  const { onClick, isSelected } = useContentSidebar();
  const contentDetail:
    | PlaybookDetailModel
    | ChapterDetailModel
    | CollectionDetailModel
    | undefined = useSelector((state) => selectContentTree(state, content));
  const dispatch = useDispatch();

  const requesting = useSelector((state) =>
    selectRequesting(state, [
      ContentAction.REQUEST_PLAYBOOKS,
      ContentAction.REQUEST_CHAPTERS,
      ContentAction.REQUEST_COLLECTIONS,
      ContentAction.REQUEST_CARDS,
    ]),
  );

  const getTextProps = (type: "title" | "description") => {
    return {
      tooltip: Capitalize(
        t("common.edit_x", { name: t(`form.card.${type}.label`) }),
      ),
      maxLength:
        type === "title"
          ? AppConfig.contentTitleMaxLength
          : AppConfig.contentDescriptionMaxLength,
      onSubmit: (e: string) => {
        dispatch(
          ContentAction.updateContent(content.contentType, content, {
            [type]: e,
          }),
        );
      },
    };
  };

  const getCategoryLabels = (): string[] | undefined => {
    if (content.contentType !== ContentTypesEnum.PLAYBOOK) {
      return;
    }

    if (content.categories && content.categories.length > 0) {
      return content.categories.map((category) => category.title);
    }
  };

  const getUserGroupAcl = (): string[] | undefined => {
    if (content.contentType !== ContentTypesEnum.PLAYBOOK) {
      return;
    }

    if (content.userGroupAcl && content.userGroupAcl.length > 0) {
      return content.userGroupAcl;
    }
  };

  const className =
    content.contentType === ContentTypesEnum.CARD && isSelected(content.getId)
      ? styles.content_container_selected
      : styles.content_container;

  return (
    <>
      <ContentBoilerplate
        className={className}
        title={TranslateDefaults(content.title)}
        description={TranslateDefaults(content.description)}
        titleProps={getTextProps("title")}
        descriptionProps={getTextProps("description")}
        isPublished={content.contentState === ContentStatesEnum.PUBLISHED}
        categoryLabels={getCategoryLabels()}
        userGroupAcl={getUserGroupAcl()}
        preview={preview()}
        suffix={suffix()}
        actions={getActions()}
        onClick={() => !requesting && onClick(content.getId)}
        createdAt={content.createdAt}
        publishedAt={content.publishedAt}
      />
      {contentDetail?.contentType === ContentTypesEnum.COLLECTION && (
        <CollectionCardListComponent collectionDetail={contentDetail} />
      )}
    </>
  );

  function preview() {
    if (content.contentType !== ContentTypesEnum.PLAYBOOK) {
      return;
    }

    return (
      <>
        <Col>
          <PlaybookPreviewComponent playbook={content} />
        </Col>
        <Col flex="3rem" />
      </>
    );
  }

  function suffix() {
    if (!contentDetail) {
      return;
    }

    return (
      <>
        <Divider style={{ margin: 0 }} />
        <div>
          <ContentMetaComponent contentDetail={contentDetail} />
        </div>
      </>
    );
  }

  function getActions(): ContentActionProps[] {
    const actions: ContentActionProps[] = [];

    if (
      content.contentType === ContentTypesEnum.CARD ||
      !onClickShow ||
      requesting
    ) {
      return actions;
    }

    actions.push({
      icon: <ContextActionIconComponent action={"open"} />,
      label: Capitalize(t("common.open")),
      onClick: onClickShow,
    });

    return actions;
  }
};

export default ContentComponent;
