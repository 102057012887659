import React from "react";
import { BaseLearningPathContent } from "../../../core/api/primio/primioSchemas";
import { useGetPlaybook } from "../../../core/api/primio/primioComponents";
import { useLearningPath } from "../../../core/providers/learning-path.provider";
import { Draggable } from "react-beautiful-dnd";
import { Card, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const LearningPathContentListItem = ({
  content,
  index,
}: {
  content: BaseLearningPathContent;
  index: number;
}) => {
  const { data: playbook, isFetching } = useGetPlaybook({
    pathParams: { playbookUid: content.contentUid },
  });
  const { removeContent, learningPath } = useLearningPath();

  if (isFetching && !playbook) return <p>Loading...</p>;

  if (!playbook) {
    return <p>Module not found</p>;
  }

  return (
    <Draggable
      key={content.contentUid}
      draggableId={content.contentUid}
      index={index}
      isDragDisabled={learningPath.state === "POSTED"}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            marginBottom: 10,
          }}
        >
          <Card hoverable>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <Card.Meta
                  title={playbook.title}
                  description={playbook.description}
                />
              </div>
              {learningPath.state === "CREATED" && (
                <Tooltip title="Remove">
                  <DeleteOutlined
                    onClick={() => removeContent(content.contentUid)}
                    key="delete"
                    style={{
                      color: "var(--error-color)",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default LearningPathContentListItem;
