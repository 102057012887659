import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { Capitalize } from "../../../../core/utils/helper.utils";

export interface Props {
  index: number;
  value: string;
  pathSnippets: string[];
}

const BreadcrumbItem = ({ index, value, pathSnippets }: Props) => {
  const { t } = useTranslation();
  const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
  return (
    <Breadcrumb.Item key={url}>
      <Link to={url}>{Capitalize(t(`containers.${value}.title`))}</Link>
    </Breadcrumb.Item>
  );
};

export default BreadcrumbItem;
