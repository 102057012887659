import React from "react";
import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";
import BreadcrumbItem from "./breadcrumb-item";
import ContentBreadcrumb from "./content-breadcrumb";

const PageHeaderBreadcrumbsComponent = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  if (pathSnippets.length === 0) {
    return null;
  }

  if (pathSnippets.includes("playbook")) {
    return <ContentBreadcrumb pathSnippets={pathSnippets} />;
  }

  return (
    <Breadcrumb>
      {pathSnippets.map((value, index) => (
        <BreadcrumbItem
          key={index}
          value={value}
          index={index}
          pathSnippets={pathSnippets}
        />
      ))}
    </Breadcrumb>
  );
};

export default PageHeaderBreadcrumbsComponent;
