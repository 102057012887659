import { UserCreateInterface } from "../../../interfaces/user-create.interface";
import { ThunkType } from "../../../types/redux.type";
import { Dispatch } from "redux";
import ActionUtility from "../../../utils/action.utils";
import UserEffect from "./user.effect";
import UserModel from "../../../models/user/user.model";
import HttpErrorResponseModel from "../../../models/http-error-response.model";

export default class UserAction {
  // Get Users
  static REQUEST_USERS = "UserAction.REQUEST_USERS";
  static REQUEST_USERS_FINISHED = "UserAction.REQUEST_USERS_FINISHED";

  // Get User (with UserGroups)
  static REQUEST_GROUPS_FOR_USER = "UserAction.REQUEST_GROUPS_FOR_USER";

  // Create User - NOT USED FOR NOW
  static REQUEST_CREATE_USER = "UserAction.REQUEST_CREATE_USER";
  static REQUEST_CREATE_USER_FINISHED =
    "UserAction.REQUEST_CREATE_USER_FINISHED";

  static REQUEST_USER = "UserAction.REQUEST_USER";
  static REQUEST_USER_FINISHED = "UserAction.REQUEST_USER_FINISHED";

  /**
   * Get current User
   * @returns Dispatch UserEffect.getUser
   */
  static getCurrentUser() {
    return async (dispatch: Dispatch<any>) => {
      dispatch(ActionUtility.createAction(UserAction.REQUEST_USER));

      const user = await UserEffect.getCurrentUser();

      if (user instanceof HttpErrorResponseModel) {
        return;
      }

      user.userGroups = await UserEffect.getGroupsForUser(user.username);

      dispatch(
        ActionUtility.createAction(UserAction.REQUEST_USER_FINISHED, user),
      );
    };
  }

  static clearCurrentUser() {
    return ActionUtility.createAction(UserAction.REQUEST_USER_FINISHED);
  }

  /**
   * Get all Users
   * @returns Dispatch UserEffect.getAllUsers
   */
  static getAllUsers(): ThunkType<UserModel[]> {
    return ActionUtility.createThunk<UserModel[]>(
      UserAction.REQUEST_USERS,
      UserEffect.getAllUsers,
    );
  }

  /**
   * Get User (with UserGroups)
   * @param user UserModel
   * @returns Dispatch UserEffect.getUserWithGroups
   */
  static getUserWithGroups(user: UserModel): ThunkType<UserModel> {
    return ActionUtility.createThunk<UserModel>(
      UserAction.REQUEST_GROUPS_FOR_USER,
      UserEffect.getUserWithGroups,
      user,
    );
  }

  /**
   * Create User
   * @param data UserCreateInterface
   * @returns Dispatch UserEffect.getAllUsers
   */
  static createUser(data: UserCreateInterface) {
    return async (dispatch: Dispatch<any>) => {
      // Note: we don't catch the create user endpoint response because we get timestamp instead of user model
      await dispatch(
        ActionUtility.createThunk<UserModel>(
          UserAction.REQUEST_CREATE_USER,
          UserEffect.createUser,
          data,
        ),
      );

      await dispatch(UserAction.getAllUsers());
    };
  }
}
