import { API_URL } from "../../../constants/config/env.config";

const TrackPlaybooksEndpoint = {
  /**
   * GET the tracked playbooks endpoint.
   * @returns API_URL + /track-playbooks
   */
  overview: `${API_URL}/track-playbooks`,
};

export default TrackPlaybooksEndpoint;
