import ContentCompletedUserProgressModel from "./content-completed-user-progress.model";

export interface CardCompletedUserProgressResponse {
  versions: ContentCompletedUserProgressModel[];
}

export default class CardCompletedUserProgressModel {
  cardUid: string;
  versions: ContentCompletedUserProgressModel[];

  constructor(cardUid: string, response: CardCompletedUserProgressResponse) {
    this.cardUid = cardUid;
    this.versions = Object.entries(response).map(([version, value]) => {
      return new ContentCompletedUserProgressModel(version, value);
    });
  }
}
