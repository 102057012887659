import React, { CSSProperties } from "react";
import { Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import ProgressMetaIconComponent from "../icons/progress-meta-icon.component";
import ContentProgressMetaType from "../../../core/types/content-progress-meta.type";

interface OwnProps {
  label: string;
  icon?: ContentProgressMetaType;
  style?: CSSProperties;
  displayMode?: string;
}

type Props = OwnProps;

const ContentProgressMeta = ({ icon, label, style, displayMode }: Props) => {
  return (
    <div style={{ marginRight: "1rem" }}>
      {icon && <ProgressMetaIconComponent icon={icon} style={style} />}
      <Typography.Text type={"secondary"} style={{ fontSize: "1.3rem" }}>
        {label === "Image" || label === "Afbeelding"
          ? // @ts-ignore
            `${Capitalize(displayMode?.toLowerCase())} `
          : null}
        {Capitalize(label)}
      </Typography.Text>
    </div>
  );
};

export default ContentProgressMeta;
