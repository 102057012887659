import { Dispatch } from "react";
import { connect } from "react-redux";
import CreateContentButtonComponent, {
  OwnProps,
} from "./create-content-button.component";
import ContentAction from "../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import CreatePlaybookModel from "../../../core/models/create-content/create-playbook.model";
import CreateChapterModel from "../../../core/models/create-content/create-chapter.model";
import CreateCollectionModel from "../../../core/models/create-content/create-collection.model";
import ContentType from "../../../core/types/content.type";

export interface DispatchProps {
  createPlaybook: (data: CreatePlaybookModel) => void;
  createChapter: (data: CreateChapterModel) => void;
  createCollection: (data: CreateCollectionModel) => void;
  renameContent: (content: ContentType, data: Partial<ContentType>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
  createPlaybook: (data: CreatePlaybookModel) =>
    dispatch(ContentAction.createContent(ContentTypesEnum.PLAYBOOK, data)),
  createChapter: (data: CreateChapterModel) =>
    dispatch(ContentAction.createContent(ContentTypesEnum.CHAPTER, data)),
  createCollection: (data: CreateCollectionModel) =>
    dispatch(ContentAction.createContent(ContentTypesEnum.COLLECTION, data)),
  renameContent: (content: ContentType, data: Partial<ContentType>) =>
    dispatch(ContentAction.updateContent(content.contentType, content, data)),
});

type MergedProps = OwnProps | DispatchProps;

const mergeProps = (
  mapProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps,
): MergedProps => ({
  ...ownProps,
  ...mapProps,
  ...dispatchProps,
  onRename: (content: ContentType, data: Partial<ContentType>) => {
    const { renameContent } = dispatchProps;
    return renameContent(content, data);
  },
  onSubmit: (title: string) => {
    const { parentContent } = ownProps;
    const { createPlaybook, createChapter, createCollection } = dispatchProps;

    if (!parentContent) {
      createPlaybook(
        new CreatePlaybookModel({
          title: title,
          description: "",
          mediaUids: [],
        }),
      );
      return;
    }

    switch (parentContent.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        createChapter(
          new CreateChapterModel({
            title: title,
            description: "",
            playbookUid: parentContent.playbookUid,
          }),
        );
        break;
      case ContentTypesEnum.CHAPTER:
        createCollection(
          new CreateCollectionModel({
            title: title,
            description: "",
            chapterUid: parentContent.chapterUid,
            startSummary: {
              title: "translations:defaults.start-summary.title",
              description: "translations:defaults.start-summary.description",
              mediaUids: [],
            },
            endSummary: {
              title: "translations:defaults.end-summary.title",
              description: "translations:defaults.end-summary.description",
              mediaUids: [],
            },
          }),
        );
        break;
      case ContentTypesEnum.COLLECTION:
        break;
    }
  },
});

const CreateContentButtonConnected = connect(
  null,
  mapDispatchToProps,
  mergeProps,
  { forwardRef: true },
)(CreateContentButtonComponent);

export default CreateContentButtonConnected;
