import React, { CSSProperties } from "react";
import {
  AudioOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  ExportOutlined,
  EyeOutlined,
  PictureOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import ContentProgressMetaType from "../../../core/types/content-progress-meta.type";

interface OwnProps {
  icon: ContentProgressMetaType;
  style?: CSSProperties;
}

type Props = OwnProps;

const ProgressMetaIconComponent = ({ icon, style }: Props) => {
  const iconStyle = {
    color: "rgba(0,0,0,0.45)",
    padding: "0 .5rem",
  };

  switch (icon) {
    case "publish":
      return <RocketOutlined style={style ? style : iconStyle} />;
    case "user":
      return <UserOutlined style={style ? style : iconStyle} />;
    case "views":
      return <EyeOutlined style={style ? style : iconStyle} />;
    case "assigned":
      return <TeamOutlined style={style ? style : iconStyle} />;
    case "shared":
      return <ExportOutlined style={style ? style : iconStyle} />;
    case "answers":
      return <CommentOutlined style={style ? style : iconStyle} />;
    case "timeSpent":
      return <ClockCircleOutlined style={style ? style : iconStyle} />;
    case "bookmark":
      return (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/bookmark.svg`}
          alt={"bookmark"}
          style={
            style
              ? style
              : {
                  margin: "0 .5rem",
                  opacity: 0.7,
                  height: 16,
                  width: 13,
                }
          }
        />
      );
    case "image":
      return <PictureOutlined style={style ? style : iconStyle} />;
    case "video":
      return <VideoCameraOutlined style={style ? style : iconStyle} />;
    case "audio":
      return <AudioOutlined style={style ? style : iconStyle} />;
    default:
      return null;
  }
};

export default ProgressMetaIconComponent;
