import ContentTypesEnum from "../../../enums/content-types.enum";
import { API_URL } from "../../../../constants/config/env.config";

export default class ContentEndpoints {
  contentType: ContentTypesEnum;

  constructor(contentType: ContentTypesEnum) {
    this.contentType = contentType;
  }

  /**
   * Used for getting all content (of this resource type) and saving a new content resource
   * @returns API_URL + /playbooks/
   */
  index = () => this.getContentPath();

  /**
   * Used for getting, updating or deleting a single content resources
   * @param id string
   * @returns API_URL + /playbooks/1b21d7d3-6788-4383-aa6e-0a9163e462c3
   */
  detail(id: string) {
    return `${this.getContentPath()}/${id}`;
  }

  /**
   * Used for sorting a content
   * @returns API_URL + /playbooks/sort
   */
  sort() {
    return `${this.getContentPath()}/sort`;
  }

  /**
   * Used for duplicating a single content resources
   * @param id string
   * @returns API_URL + /playbooks/1b21d7d3-6788-4383-aa6e-0a9163e462c3/duplicate
   */
  duplicate(id: string) {
    return `${this.getContentPath()}/${id}/duplicate`;
  }

  /**
   * Used for setting the ContentState to Published
   * @param id string
   * @returns API_URL + /playbooks/1b21d7d3-6788-4383-aa6e-0a9163e462c3/publish
   */
  publish(id: string) {
    return `${this.getContentPath()}/${id}/publish`;
  }

  /**
   * Used for setting the ContentState to Published - CARDS ONLY
   * @param id string
   * @param version number
   * @returns API_URL + /cards/published/a9a44a73-af7c-4626-b53b-190e2331a5ef/versions/1
   */
  publishCard(id: string, version: number) {
    return `${this.getContentPath()}/published/${id}/versions/${version}`;
  }

  /**
   * Used for setting the ContentState to bulk Published - for cards
   * @returns API_URL + /published/
   */
  bulkPublishCards() {
    return `${this.getContentPath()}/published/`;
  }

  /**
   * Used for setting the ContentState to Draft
   * @param id string
   * @returns API_URL + /playbooks/1b21d7d3-6788-4383-aa6e-0a9163e462c3/draft
   */
  draft(id: string) {
    return `${this.getContentPath()}/${id}/draft`;
  }

  getContentPath() {
    switch (this.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return `${API_URL}/playbooks`;
      case ContentTypesEnum.CHAPTER:
        return `${API_URL}/chapters`;
      case ContentTypesEnum.COLLECTION:
        return `${API_URL}/collections`;
      case ContentTypesEnum.CARD:
        return `${API_URL}/cards`;
    }
  }
}
