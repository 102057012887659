import ThunkInterface from "../../../interfaces/thunk.interface";
import BaseReducer from "../base/base.reducer";
import MediaModel from "../../../models/media/media.model";
import MediaAction from "./media.action";

export interface MediaReducerInterface {
  media: MediaModel[] | undefined;
}

export default class MediaReducer extends BaseReducer<MediaReducerInterface> {
  initialState: MediaReducerInterface = {
    media: undefined,
  };

  [MediaAction.REQUEST_MEDIA_FINISHED](
    state: MediaReducerInterface,
    action: ThunkInterface<MediaModel[]>,
  ) {
    return {
      ...state,
      media: action.payload,
    };
  }
}
