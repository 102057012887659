import { ContentProgressEnum } from "../../../../enums/content-progress.enum";
import ContentUserProgressModel from "./content-user-progress.model";

export interface CollectionUserProgressResponse {
  [userUid: string]: ContentProgressEnum;
}

export default class CollectionUserProgressModel {
  chapterUid: string;
  collectionUid: string;
  users: ContentUserProgressModel[];

  constructor(
    response: CollectionUserProgressResponse,
    chapterUid: string,
    collectionUid: string,
  ) {
    this.chapterUid = chapterUid;
    this.collectionUid = collectionUid;

    this.users = Object.entries(response).map(([userUid, state]) => {
      return new ContentUserProgressModel({ userUid, state });
    });
  }
}
