import React, { PropsWithChildren } from "react";
import { Image, Popover } from "antd";

interface Props {
  src: string;
}

const PopoverImage = ({ children, src }: PropsWithChildren<Props>) => {
  return (
    <Popover
      content={
        <div style={{ height: 250, borderRadius: 6, overflow: "hidden" }}>
          <Image height={250} width={250} preview={false} src={src} />
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default PopoverImage;
